export const columns = [
    
    {
        header: "Nombre Secciones",
      name: "nombre_secciones",
      maxWidth: 1500, defaultFlex: 1
    },
    
  
  ];

  export const filterValue = [
   
    { name: 'nombre_secciones', operator: 'startsWith', type: 'string', value: '' },
    
    
  ];