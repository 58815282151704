import React from 'react';
import { Modal } from 'antd';
import './modalStyles.css'; // Importa tus estilos CSS

export function ModalBasic(props) {
  const { show, title, children, onClose } = props;

  return (
    <Modal
      visible={show}
      onCancel={onClose}
      footer={null}
      width="60%"
      title={title}
      destroyOnClose={true}
      className="custom-modal" // Aplica la clase personalizada
    >
      {children}
    </Modal>
  );
}
