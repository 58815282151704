import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import EarningCard from "../../../sections/overview-dashboard/EarningCard";
import PopularCard from "../../../sections/overview-dashboard/PopularCard";
import TotalIncomeDarkCard from "../../../sections/overview-dashboard/TotalIncomeDarkCard";
import TotalIncomeLightCard from "../../../sections/overview-dashboard/TotalIncomeLightCard";
import TotalOrderLineChartCard from "../../../sections/overview-dashboard/TotalOrderLineChartCard";
import Card from "../../../sections/overview-dashboard/Card";
import Cardtwo from "../../../sections/overview-dashboard/Cardtwo";
import Cardthree from "../../../sections/overview-dashboard/Cardthree";
import Cardfour from "../../../sections/overview-dashboard/Cardfour";
import Cardfive from "../../../sections/overview-dashboard/Cardfive"
import Cardsix from "../../../sections/overview-dashboard/Cardsix"
import { useGraficas } from "../../../hooks";
import { gridSpacing } from '../../../store/constant';
import { Calendar } from '../../../vistas/modules/calendar'
import { Loader } from "semantic-ui-react";
import { Carousel } from "antd";

export function Dashboard() {

const [isLoading, setLoading] = useState(true);
useEffect(() => {
  setLoading(false);
}, []);

const {
  loading,
  Operador,
  getOperador,
  Cliente,
  getCliente,
  ORecibo,
  getOrdenRecibo,
  ODespacho,
  getOrdenDespacho,
  Conductor,
  getConductor,
  Proveedor,
  getProveedor,
  Producto,
  getProducto,
  Planta,
  getPlanta,
  Transportadora,
  getTransportadora,
  Turnos,
  getTurnos,
  } = useGraficas();

useEffect(() => {
  getOrdenRecibo();
  getOperador();
  getCliente();
  getOrdenDespacho();
  getConductor();
  getProveedor();
  getProducto();
  getTransportadora();
  getTurnos();
  getPlanta();
}, []);

return (
  <>
  {loading ? (
    <Loader active inline="centered" />
    ) : (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
        <Grid item lg={4} md={12} sm={6} xs={12} >
          <Carousel autoplay>
            <div >
            {Operador?.results && Operador?.results.length > 0 && (
              <EarningCard maxWidth="xl" isLoading={isLoading}
              positive
              value={Operador.results.length}
            />
            )}  
            </div>
            <div>
            {Cliente?.results && Cliente?.results.length > 0 && (
              <TotalOrderLineChartCard maxWidth="xl" isLoading={isLoading}
              positive
              valueA={Cliente.results.length}
              valueY={Cliente.results.length}
            />
            )}            
            </div>
            <div>
              {Conductor?.results && Conductor?.results.length > 0 && (
                <Card maxWidth="xl" isLoading={isLoading}
                positive
                value={Conductor.results.length}
                />
              )}
            </div>
            <div>
            {Proveedor?.results && Proveedor?.results.length > 0 && (
                <Cardtwo maxWidth="xl" isLoading={isLoading}
                positive
                value={Proveedor.results.length}
                />
              )}
            </div>
          </Carousel>
          </Grid>
          <Grid item lg={4} md={12} sm={6} xs={12}>
          <Carousel autoplay>
            <div>
              {Producto?.results && Producto?.results.length > 0 && (
                  <Cardthree maxWidth="xl" isLoading={isLoading}
                  positive
                  value={Producto.results.length}
                  />
                )}
            </div>
            <div>
             {Turnos?.results && Turnos?.results.length > 0 && (
                  <Cardfive maxWidth="xl" isLoading={isLoading}
                  positive
                  value={Turnos.results.length}
                  />
                )}
            </div>    
            <div>
              {Transportadora?.results && Transportadora?.results.length > 0 && (
                  <Cardfour maxWidth="xl" isLoading={isLoading}
                  positive
                  value={Transportadora.results.length}
                  />
                )}
            </div>
            <div>
            {Planta?.results && Planta?.results.length > 0 && (
                  <Cardsix maxWidth="xl" isLoading={isLoading}
                  positive
                  value={Planta.results.length}
                  />
                )}
            </div>
          </Carousel>
          </Grid>
          <Grid item lg={4} md={12} sm={12} xs={12}>
            <Grid container spacing={1.12}>
              <Grid item sm={6} xs={12} md={6} lg={12}>
                {ORecibo?.results && ORecibo?.results.length > 0 && (
                <TotalIncomeDarkCard maxWidth="xl" isLoading={isLoading}
                positive
                value={ORecibo.results.length}
                />
              )}
              </Grid>
              <Grid item sm={6} xs={12} md={6} lg={12}>
              {ODespacho?.results && ODespacho?.results.length > 0 && (
                <TotalIncomeLightCard maxWidth="xl" isLoading={isLoading}
                positive
                value={ODespacho.results.length}
                />
              )}
              </Grid>
            </Grid>
          </Grid>
{/*           <Grid item lg={4} md={12} sm={6} xs={12}>
            <Carousel autoplay dotPosition="right">
              <div>
              {ORecibo?.results && ORecibo?.results.length > 0 && (
                <TotalIncomeDarkCard maxWidth="xl" isLoading={isLoading}
                positive
                value={ORecibo.results.length}
                />
              )}
              </div>
              <div>
              {ODespacho?.results && ODespacho?.results.length > 0 && (
                <TotalIncomeLightCard maxWidth="xl" isLoading={isLoading}
                positive
                value={ODespacho.results.length}
                />
              )}
              </div>
              <Carousel autoplay dotPosition="right">
              <div>
              {ORecibo?.results && ORecibo?.results.length > 0 && (
                <TotalIncomeDarkCard maxWidth="xl" isLoading={isLoading}
                positive
                value={ORecibo.results.length}
                />
              )}
              </div>
              <div>
              {ODespacho?.results && ODespacho?.results.length > 0 && (
                <TotalIncomeLightCard maxWidth="xl" isLoading={isLoading}
                positive
                value={ODespacho.results.length}
                />
              )}
              </div>
              </Carousel>
              </Carousel>
          </Grid> */}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12} md={8}>
            <center><h2><strong>Programación de ordenes</strong></h2></center>            
          <Calendar />
          </Grid>
          <Grid item xs={12} md={4}>
            <PopularCard isLoading={isLoading} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
        )}
    </>
  );  
}