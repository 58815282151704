import { useState } from "react";
import {
  getConciliacionesApi,
  addConciliacionesApi,
  updateConciliacionesApi,
  deleteConciliacionesApi,
  ultimasConciliacionesApi,
  ConciliacionesSum,
  ConciliacionesSumTotal,
  Conciliacionescierre
} from "../../api/A_Conciliaciones/conciliaciones";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "..";

export function useConciliaciones() {
  const { auth, logout } = useAuth();
  const { unauthorized, SessionExpired, errores, unauthorizedModal } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    Conciliaciones: null,
    ConciliacionesDB: null,
    ConciliacionesSuma: null,
    ConciliacionesTotSum: null,
    ConciliacionesCierre: null
  });

  const { loading, error, Conciliaciones, ConciliacionesDB, ConciliacionesUlti,ConciliacionesSuma,ConciliacionesTotSum,ConciliacionesCierre } = state;


  const handleResponse = (response) => {
    if (response.status === 401) {
      SessionExpired();
      logout();
    } else if (response.status === 403) {
      unauthorized();
    }
  };

  const getConciliacionesDB = async () => {
    try {
      const [result, response] = await getConciliacionesApi(auth.token, auth.me.tenant);
      setState((prevState) => ({ ...prevState, ConciliacionesDB: result.results }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  const getConciliaciones = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getConciliacionesApi(auth.token, auth.me.tenant,id);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, Conciliaciones: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const getConciliacionesUlti = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await ultimasConciliacionesApi(auth.token, auth.me.tenant,id);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, ConciliacionesUlti: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };
  const getConciliacionesSum = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await ConciliacionesSum(auth.token, auth.me.tenant,id);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, ConciliacionesSuma: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };
  const getConciliacionesSumaTotal = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await ConciliacionesSumTotal(auth.token, auth.me.tenant,id);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, ConciliacionesTotSum: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const getConciliacionesCierre= async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await Conciliacionescierre(auth.token, auth.me.tenant,id);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, ConciliacionesCierre: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addConciliaciones = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addConciliacionesApi(data, auth.token, auth.me.tenant);
      if (response.status === 400) {
        handleResponse(result);
      }
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateConciliaciones = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateConciliacionesApi(id, data, auth.token, auth.me.tenant);
      if (response.status === 400) {
        handleResponse(result);
      }
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteConciliaciones = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteConciliacionesApi(id, auth.token, auth.me.tenant);
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    ConciliacionesDB,
    Conciliaciones,
    ConciliacionesUlti,
    ConciliacionesSuma,
    ConciliacionesTotSum,
    ConciliacionesCierre,
    getConciliacionesCierre,
    getConciliacionesDB,
    getConciliaciones,
    addConciliaciones,
    updateConciliaciones,
    deleteConciliaciones,
    getConciliacionesUlti,
    getConciliacionesSum,
    getConciliacionesSumaTotal
  };
}
