import React, { useState } from "react";

import {
  AddEditOrdenes,
  VistaTransacciones,
} from "../../../../Components/Client";
import {ExcelUploader } from "../../../../Components/Comons";
import { useOrdenes, useDocumentosGut, useAuth } from "../../../../hooks";
import Swal from "sweetalert2"
import { ImpresionT } from "../../../../Components/Client/OrdenVenta/Impresiones/ImpresionT";
import { BASE_API } from "../../../../utils/constants";
import { IconButton } from "@material-ui/core";
import moment from 'moment';
import PostAddIcon from '@mui/icons-material/PostAdd';
import AdfScannerIcon from '@mui/icons-material/AdfScanner';
import Tooltip from '@material-ui/core/Tooltip';
import { CheckCircle, Cancel } from '@mui/icons-material';

export  function Funciones() {
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const { auth } = useAuth();
  const { GenerarDocumentosGut} = useDocumentosGut();
  const openCloseModal = () => setShowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);
  const {  deleteOrdenes } = useOrdenes();

  

    const modalContent = (
        <ExcelUploader 
          onClose={openCloseModal}
          onRefetch={onRefetch}
        />
    );
  
  
  
    
  const addMultipleOrdenes = () => {
    setTitleModal("Cargue de multiples ordenes");
  
    setContentModal(
      <>
     {modalContent}
      </>
      
    );
    openCloseModal();
  };
  
    /**La función establece el título y el contenido del modal, además de abrirlo. 
     * El título establecido es "Nuevo ..." y el contenido es un componente "AddEditComponent", 
     * que se encarga de agregar un nuevo dato. El componente cuenta con dos props, "onClose" y "onRefetch". 
     * "onClose" es una función que cierra el modal, y "onRefetch" es una función que recarga los datos de la tabla después de agregar un nuevo dato. */
  
    const addOrdenes = () => {
      setTitleModal("Nueva Orden");
  
      setContentModal(
        <AddEditOrdenes onClose={openCloseModal} onRefetch={onRefetch} />
      );
      openCloseModal();
    };
  /** La función  es similar a la función a la anterior, con la diferencia de que establece el título "Actualizar..." y además recibe un parámetro "data",
   *  que contiene los datos del dato a actualizar. El contenido del modal es el mismo componente "AddEditComponent" que antes, pero en este caso, 
   * el componente recibe la propiedad  con los datos a actualizar. El componente cuenta con dos props, "onClose" y "onRefetch". 
   * "onClose" es una función que cierra el modal, y "onRefetch" es una función que recarga los datos de la tabla después de actualizar un dato. */
  
    const updateOrdenes = (data) => {
      setTitleModal("Actualizar Ordenes");
      setContentModal(
        <AddEditOrdenes
          onClose={openCloseModal}
          onRefetch={onRefetch}
          Ordenes={data}
        />
      );
      openCloseModal();
    };
  
    const vistaTransacciones = (data) => {
      
      setTitleModal("Lista de transacciones");
      setContentModal(
        <VistaTransacciones
          onClose={openCloseModal}
          onRefetch={onRefetch}
          Transacciones={data}
        />
      );
      openCloseModal();
    };
  
    const ImpresionTiquet = (data) =>{
     
      
      setTitleModal("Lista de transacciones");
      setContentModal(
        <ImpresionT
          onClose={openCloseModal}
          onRefetch={onRefetch}
          data={data}
        
        />
      );
      openCloseModal();
    }
  
  
    const CargaConductorCamara = () => {
      const url = `${BASE_API}/${auth.me.tenant}/acceso/delete/`;
      const data = { tenant: 'syz' };
    
      fetch(url, {
        method: 'POST',
        headers: { 
          Authorization: `Bearer ${auth.token}`,
          'Content-Type': 'application/json' },
           body: JSON.stringify(data),
      })
        .then(response => {
          if (response.ok) {
            return response.json(); // convertir la respuesta en formato JSON
          } else {
            throw new Error('La solicitud ha fallado.');
          }
        })
        .then(data => {
          Swal.fire({
            icon: 'success',
            title: 'Datos cargados',
            text: data.message, // usar el mensaje de la respuesta como texto de la alerta
          });
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'cargar de datos',
            text: '', // usar el mensaje de error como texto de la alerta
          });
        });
    };
  
    
    
    ///Ejecucion de modal de visualizacion
  
  /** La función "onDelete" es una función que utiliza la librería SweetAlert para mostrar una alerta de confirmación al usuario 
   * antes de eliminar un dato . La función recibe como parámetro "data", que contiene los datos  a eliminar. 
   * Cuando el usuario confirma la eliminación, se llama a la función "delete" pasando como parámetro el id de los datos 
   * a eliminar y se ejecuta la función "onRefetch" para actualizar los datos de la tabla. En caso de cancelar, se muestra un mensaje de error. */
  
    const onDeleteOrdenes = async (data) => {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      })
      
      swalWithBootstrapButtons.fire({
        title: 'Estas seguro?',
        text: "No podras revertir esta accion!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminar!',
        cancelButtonText: 'No, cancelar!',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          deleteOrdenes(data.id_orden);
          onRefetch();
          swalWithBootstrapButtons.fire(
            'Eliminado!',
            'El dato a sido eliminado.',
            'success'
          )
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            'Cancelado',
            'Operacion cancelada',
            'error'
          )
        }
      })
    };
    const columns = [
      
      
        {
          name: 'time_created',
          header: 'Fecha creación',
          group: "general",
          defaultFlex: 1,
          minWidth: 150,
          render: ({ value, cellProps }) => {
            return moment(value).format('MM-DD-YYYY')
          }
        },
        {
          header: "Numero de orden",
          name: "n_orden",
          group: "general",
          maxWidth: 1000, defaultFlex: 1
        },
      
        {
          header: "Autorizado",
          name: "v_auth", 
          group: "general",
          maxWidth: 300, defaultFlex: 1,
           render: (rowData) => (
            rowData.data.v_auth ? (
              <CheckCircle color="primary" />
            ) : (
              <Cancel color="error" />
            )
          )
        },   
        {
          header: "Cliente",
          name: "cliente_nombre",
          maxWidth: 1000, defaultFlex: 1
        },
        {
          header: "Tanque",
          name: "tanque_nombre",
          group: 'producto',
          maxWidth: 1000, defaultFlex: 1
        },
        
        {
          header: "Producto",
          name: "producto_nombre",
          group: 'producto',
          maxWidth: 1000, defaultFlex: 1
        },
        {
          header: "BSW",
          name: "bsw",
          group: 'producto',
          maxWidth: 90, defaultFlex: 1,
        },
        {
          header: "API",
          name: "api",
          group: 'producto',
          maxWidth: 90, defaultFlex: 1,
        },
        {
          header: "Entregado",
          name: "v_entregado",
          group: 'producto',
          maxWidth: 90,
          defaultFlex: 1,
        },
        {
          header: "Restante",
          name: "v_restante",
          group: 'producto',
          maxWidth: 90,
          defaultFlex: 1,
          
        },

        {
          header: "Autorizado",
          name: "tiempo_auth",
          
          maxWidth: 1000, defaultFlex: 1

        },
      
        
        {
          header: "Generar",
          id: "gut",
          render: ({ data }) => (
            <>
             <Tooltip title="IMPRESION DE TIQUET" arrow>
                <IconButton onClick={() => ImpresionTiquet(data)}>
                  <AdfScannerIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="GENERAR GUT" arrow>
                <IconButton onClick={() => GenerarDocumentosGut(data)}>
                  <PostAddIcon />
                </IconButton>
              </Tooltip>
            </>
          ),
          maxWidth: 170,
          defaultFlex: 1
        },
        
      ];

      const extraColumns = [
        {
          name: 'time_created',
          header: 'Fecha creación',
          group: 'general',
          defaultFlex: 1,
          minWidth: 150,
          render: ({ value, cellProps }) => {
            return moment(value).format('MM-DD-YYYY')
          }
        },
        {
          header: "Numero de orden",
          name: "n_orden",
          group: 'general',
          maxWidth: 1000, defaultFlex: 1
        },
        {
          header: "Estado",
          group: 'general',
          name: "estado_orden",
          maxWidth: 1000, defaultFlex: 1
        },
        {
          header: "Vehículo",
          name: "vehiculo_nombre",
          group: 'transporte',
          maxWidth: 1000, defaultFlex: 1
        },
        {
          header: "Conductor",
          name: "conductor_nombre",
          group: 'transporte',
          maxWidth: 1000, defaultFlex: 1
        },
        {
          header: "Remolque",
          name: "remolque_nombre",
          group: 'transporte',
          maxWidth: 1000, defaultFlex: 1
        },
        {
          header: "Transportadora",
          name: "transportadora_nombre",
          group: 'transporte',
          maxWidth: 1000, defaultFlex: 1
        },

        
        {
          header: "Sellos",
          name: "sellos",
          maxWidth: 1000, defaultFlex: 1
        },
        {
          header: "N. Factura",
          name: "n_factura",
          maxWidth: 1000, defaultFlex: 1
        },
        {
          header: "Observaciónes",
          name: "observacion_orden",
          maxWidth: 1000, defaultFlex: 1
        },
      ];
    return {
        columns,
        addMultipleOrdenes,
        addOrdenes,
        updateOrdenes,
        CargaConductorCamara,
        onDeleteOrdenes,
        vistaTransacciones,
        openCloseModal,
        extraColumns,
        refetch,
        contentModal,
        titleModal,
        showModal,

      };
}
