import { useFormik, FieldArray, Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";
//import { Button, Form, Input, Segment, Label } from "semantic-ui-react";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';

import * as Yup from "yup";
import { useFracciones, useAuth } from "../../../../hooks";
import { alertas } from "../../../Comons/Alertas/alertas";

export function AddEditFracciones(props) {
  /*
  Componente

  -componente de visualizacion del modal de edicion y creacion para Fracciones
  
  -se crea y valida mediante formik y yup
  -Se obtienen datos para dropdown mediante hooks
  -se obtiene los datos (para edicion) y funciones correspondientes mediante props desde su PageAdmin
   */
  const { Successful } = alertas();
  const { onClose, onRefetch, Fracciones } = props;
  const { addFracciones, updateFracciones } = useFracciones();

  const { auth } = useAuth();

  //Con esto validamos que se muestren las unidades de medidas asignadas
  const renderUnidadLongitud = () => {
    if (auth.me?.SistemasMedicion && auth.me?.SistemasMedicion.longitud) {
      return `${auth.me.SistemasMedicion.longitud} `;
    }
    return "No especificado";
  };

  const renderUnidadVolumen = () => {
    if (auth.me?.SistemasMedicion && auth.me?.SistemasMedicion.volumen) {
      return `${auth.me.SistemasMedicion.volumen} `;
    }
    return "No especificado";
  };

  const renderUnidadmin = () => {
    if (auth.me?.SistemasMedicion && auth.me?.SistemasMedicion.longitud) {
      if (auth.me?.SistemasMedicion.longitud === "cm") {
        return `mm`;
      } else if (auth.me?.SistemasMedicion.longitud === "ft") {
        return `in`;
      }
    }
    return "No especificado";
  };

  const formik = useFormik({
    // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
    initialValues: initialValues(Fracciones),
    // Establece la validación del esquema utilizando Yup, si se proporciona un objeto  se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
    validationSchema: Yup.object(Fracciones ? updateSchame() : newSchame()),
    // Desactiva la validación al cambiar los valores del formulario
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        // Si se proporciona un objeto se actualiza, de lo contrario se crea uno nuevo
        if (Fracciones)
          await updateFracciones(Fracciones.id_fracciones, formValue);
        else await addFracciones(formValue);
        Successful()
        onRefetch();
        onClose();
      } catch (error) {
        // Muestra un mensaje de error si ocurre algún problema
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });

  const divStyle = {
    textAlign: "left",
  };

  return (
    <>
      <Formik initialValues={initialValues()}>
        <form className="add-edit-secciones-form" onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h5" gutterBottom>
                Fracciones
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              {formik.values.data_fracciones.slice(0, 9).map((variable, index) => (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={3.5}>
                      <TextField
                        fullWidth
                        required
                        label={`nivel ${index + 1}` + " " + renderUnidadLongitud() + "/" + renderUnidadmin()}
                        name={`data_fracciones[${index}].nivel`}
                        placeholder={"nivel " + " " + renderUnidadLongitud() + "/" + renderUnidadmin()}
                        value={variable.nivel}
                        onChange={formik.handleChange}
                        error={formik.errors[`data_fracciones[${index}].nivel`]}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3.5}>
                      <TextField
                        fullWidth
                        required
                        label={`Valor de variable ${index + 1}` + " " + renderUnidadLongitud() + "/" + renderUnidadVolumen()}
                        name={`data_fracciones[${index}].volumenP`}
                        placeholder={"Volumen " + " " + renderUnidadLongitud() + "/" + renderUnidadVolumen()}
                        value={variable.volumenP}
                        onChange={formik.handleChange}
                        error={formik.errors[`data_fracciones[${index}].volumenP`]}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3.5}>
                      <TextField
                        fullWidth
                        required
                        label={`Valor de variable ${index + 1}` + " " + renderUnidadmin() + "/" + renderUnidadVolumen()}
                        name={`data_fracciones[${index}].volumenS`}
                        placeholder={"Volumen " + renderUnidadmin() + "/" + renderUnidadVolumen()}
                        value={variable.volumenS}
                        onChange={formik.handleChange}
                        error={formik.errors[`data_fracciones[${index}].volumenS`]}
                      />
                    </Grid>
                    <Grid item xs={12} sm={1}>
                      <Tooltip title="Eliminar" arrow>
                        <Button
                          variant="outlined"
                          color="secondary"
                          style={{
                            height: '100%', display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '0',
                            margin: '0',
                            color: 'rgba(252, 0, 0, 0.8)',
                            border: '1px solid rgba(252, 0, 0, 0.8)',
                          }}
                          onClick={() =>
                            formik.setFieldValue(
                              "data_fracciones",
                              formik.values.data_fracciones.filter((_, i) => i !== index)
                            )
                          }
                        >
                          <DeleteIcon style={{ margin: '0' }} />
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <br />
                </>
              ))}
              <Tooltip title="Agregar" arrow>
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  style={{
                    color: 'rgb(25, 118, 210)',
                    border: '1px solid rgb(25, 118, 210)',
                    '&:hover': {
                      color: 'rgba(221, 223, 221, 0.62)',
                      border: '1px solid rgba(221, 223, 221, 0.62)',
                    }
                  }}
                  onClick={() =>
                    formik.setFieldValue("data_fracciones", [
                      ...formik.values.data_fracciones,
                      { nivel: "", Volumen: "", Incremento: "" },
                    ])
                  }
                >
                  <AddIcon />
                </Button>
              </Tooltip>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Button type="submit" variant="contained" color="error" className="btn btn-danger" fullWidth>
                {Fracciones ? "Actualizar" : "Crear"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Formik>
    </>
  );
}



function initialValues(data) {
  return {

    data_fracciones: data?.data_fracciones || [],
  };
}

function newSchame() {
  return {
    // nivel: Yup.number().required(true),
    // volumencm: Yup.number().required(true),

  };
}

function updateSchame() {
  return {
    // nivel: Yup.number().required(true),


  };
}
