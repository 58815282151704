import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "../";
import { getDataImpresionDescargaderApi } from "../../api/impresiones/impresionesD";
import { useState } from 'react';

export function useImpresionesDescargadero() {
  const { auth, logout } = useAuth();
  const { unauthorized, SessionExpired } = alertas();

  const [state, setState] = useState({
    error: null,
    ImpresionesDescargadero: null,
  });

  const { error, ImpresionesDescargadero } = state;

  const getImpresionesDescargadero = async (id) => {
    try {
      const [result, response] = await getDataImpresionDescargaderApi(id, auth.token, auth.me.tenant);

      if (response.status === 401) {
        SessionExpired();
        logout();
      } else if (response.status === 403) {
        unauthorized();
      }

      setState((prevState) => ({
        ...prevState,
        ImpresionesDescargadero: result,
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        error: error,
      }));
    }
  };

  return {
    error,
    ImpresionesDescargadero,
    getImpresionesDescargadero,
  };
}
