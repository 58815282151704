export const PermOverFill = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 424.14 628.16">
    <g id="Capa_1" data-name="Capa 1">
      <path
        fill="#fff"
        d="M423.6,316.26c0-75.16,.19-150.32-.06-225.48-.2-56.97-31.25-89.46-88.28-90.1-81.89-.92-163.8-.91-245.68,0C32.76,1.31,.61,33.77,.4,90.57c-.54,149.28-.52,298.56-.01,447.83,.19,57.33,32.46,88.91,88.92,89.04,80.86,.18,161.77-1.7,242.56,.66,54.1,1.58,93.83-34.97,92.22-92.64-2.04-73.02-.49-146.13-.49-219.21Z"
      />
      <path
        fill="#080809"
        d="M423.6,316.26c0,73.07-1.55,146.19,.49,219.21,1.61,57.66-38.12,94.22-92.22,92.64-80.79-2.36-161.7-.48-242.56-.66-56.46-.13-88.73-31.71-88.92-89.04C-.12,389.13-.14,239.85,.4,90.57,.61,33.77,32.76,1.31,89.57,.68c81.89-.9,163.8-.91,245.68,0,57.03,.64,88.09,33.12,88.28,90.1,.26,75.16,.06,150.32,.06,225.48Zm-18.95,.37c0-76.2,.18-152.4-.07-228.6-.14-44.05-22.76-67.96-66.52-68.32-83.96-.7-167.93-.69-251.89,0-42.6,.35-66.76,25.41-66.86,68.8-.35,150.31,.46,300.63-.51,450.94-.28,43.42,30.96,70.75,69.27,69.7,82.87-2.27,165.84-.58,248.76-.74,45.88-.09,67.69-22.45,67.79-69.43,.16-74.11,.04-148.22,.04-222.34Z"
      />
      <path
        fill="#fbfbfb"
        d="M404.65,316.63c0,74.11,.12,148.23-.04,222.34-.1,46.98-21.91,69.34-67.79,69.43-82.93,.16-165.9-1.53-248.76,.74-38.31,1.05-69.55-26.28-69.27-69.7,.97-150.31,.16-300.62,.51-450.94,.1-43.39,24.26-68.46,66.86-68.8,83.96-.68,167.93-.69,251.89,0,43.76,.36,66.38,24.27,66.52,68.32,.25,76.2,.07,152.4,.07,228.6Zm-30.37,107.02c0-33.39,.34-66.79-.09-100.18-.49-37.98-21.52-60-59.76-60.44-66.31-.77-132.65-.79-198.96,.12-36.96,.5-58.99,23.69-59.34,61.47-.6,65.74-.67,131.49,.04,197.23,.38,35.55,22.56,60.24,57.45,60.98,68.37,1.45,136.81,1.54,205.17-.08,34.66-.82,55.04-25.8,55.42-62.04,.34-32.35,.07-64.7,.07-97.05Z"
      />
      <path
        fill="#0c0a0b"
        d="M374.28,423.65c0,32.35,.27,64.7-.07,97.05-.39,36.24-20.76,61.22-55.42,62.04-68.36,1.62-136.8,1.53-205.17,.08-34.89-.74-57.07-25.43-57.45-60.98-.71-65.74-.64-131.49-.04-197.23,.35-37.78,22.37-60.96,59.34-61.47,66.31-.9,132.65-.88,198.96-.12,38.24,.44,59.27,22.46,59.76,60.44,.43,33.39,.09,66.79,.09,100.18Zm-18.06,3.07c0-31.28-.96-62.6,.26-93.83,1.36-34.79-5.62-52.48-50.66-54.99-60.9-3.41-122.17-2.1-183.2-.24-34.02,1.04-48.27,18.34-48.43,51.64-.31,62.56-.32,125.12,.01,187.68,.18,34.14,13.03,47.63,46.43,47.9,64.19,.51,128.39,.45,192.59,.02,30.33-.2,42.41-12.94,42.89-44.34,.48-31.27,.1-62.56,.12-93.84Z"
      />
      <path
        fill="#7e3f40"
        d="M356.21,426.72c0,31.28,.36,62.57-.11,93.84-.48,31.39-12.56,44.14-42.89,44.34-64.19,.42-128.4,.48-192.59-.02-33.41-.26-46.25-13.76-46.43-47.9-.34-62.56-.32-125.12-.01-187.68,.16-33.3,14.41-50.6,48.43-51.64,61.03-1.86,122.31-3.17,183.2,.24,45.04,2.52,52.02,20.21,50.66,54.99-1.22,31.23-.26,62.55-.26,93.83Zm-201.88-1.74c-3.28,6.15-10.62,13.56-9.18,18.24,8.12,26.4,15.19,53.98,28.73,77.61,14.18,24.76,49.42,31.88,70.59,18.08,19.21-12.52,35.77-28.23,36.01-54.2-.2-21.17-.39-42.34-.59-63.51,1.84-5.18,6.54-11.44,5.07-15.37-14.14-37.78-38.02-69.04-66.21-101.59-14.57,20.85-26.61,38.51-39.12,55.84-14.06,19.47-26.42,39.48-25.31,64.89Z"
      />
    </g>
    <g id="Capa_2" data-name="Capa 2">
      <path
        fill="#fff"
        stroke="#1d1d1b"
        strokeMiterlimit={10}
        strokeWidth={14}
        d="M218.76,304.26c-17.78,23.13-31.43,41.03-38.68,50.57-1.2,1.58-4.4,5.8-8.36,11.57-3.85,5.61-7.24,10.54-10.93,17.36-1.09,2.02-4.38,8.2-7.71,16.71,0,0-4.56,11.65-7.07,23.14-3.31,15.2-7.56,92.07,39.21,115.07,30.23,14.87,74.05,3.42,94.5-21.21,33.66-40.55,14.59-129.55-60.96-213.21Z"
      />
    </g>
  </svg>
);
