

  export const filterValue = [
    { name: 'placa_veh', operator: 'startsWith', type: 'string', value: '' },
    { name: 'marca', operator: 'startsWith', type: 'string', value: '' },
    { name: 'modelo', operator: 'startsWith', type: 'string', value: '' },
    { name: 'capacidad', operator: 'startsWith', type: 'string', value: '' },
    { name: 'data_trans.nombre_transportadora', operator: 'startsWith', type: 'string', value: '' },
    { name: 'razon_social', operator: 'startsWith', type: 'string', value: '' },
    { name: 'operativo', operator: 'startsWith', type: 'string', value: '' },
    { name: 'vehicle_type', operator: 'startsWith', type: 'string', value: '' },
    { name: 'conductor', operator: 'startsWith', type: 'string', value: '' },
    { name: 'transportadora', operator: 'startsWith', type: 'string', value: '' }
  ];
  