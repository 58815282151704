import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { BASE_API_PUBLIC } from '../../utils/constants';

export function RenovarLicencias() {
  const [token, setToken] = useState(''); // estado local para guardar el token ingresado por el usuario
  const [message, setMessage] = useState(''); // estado local para mostrar mensaje de éxito o error
  const navigate = useNavigate(); // instancia de useHistory para redirigir al usuario después de renovar la licencia

  const handleRenewal = () => {
    const url = `${BASE_API_PUBLIC}/tokens/actualizar/licencia/`;

    // Validar si el token es un JWT
    if (!/^[\w-]+\.[\w-]+\.[\w-]+$/.test(token)) {
      setMessage('El token ingresado no es un JSON Web Token válido');
      return;
    }

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token }),
    })
      .then(response => {
        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'La licencia se renovó con éxito',
          }).then(() => {
            navigate('/');
          });
        } else {
          throw new Error('Hubo un error al renovar la licencia');
        }
      })
      .catch(error => {
        setMessage(error.message);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message,
        });
      });
  };

  return (
    <div
      className="justify-content-center d-flex align-items-center"
      style={{ backgroundImage: "https://ii.ct-stc.com/1/logos/empresas/2018/07/10/syz-colombia-sas-B6C7BAF49F7AA1CB190711369thumbnail.jpg", backgroundSize: "cover", height: "100vh" }}
    >
      <div
        className="alert alert-danger"
        role="alert"
        style={{ width: "50%", textAlign: "center", backgroundColor: "rgba(255, 255, 255, 0.8)", padding: "50px" }}
      >
        <div className="form-group">
          <label htmlFor="token">Ingrese el token:</label>
          <input type="text" className="form-control" id="token" value={token} onChange={(event) => setToken(event.target.value)} />
        </div>
        <br/>
        <button className="btn btn-danger" onClick={handleRenewal}>Renovar Licencia</button>
        {message && <p>{message}</p>}
      </div>
    </div>
  );
}
