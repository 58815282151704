import { useState } from "react";
import { getRemolquesApi, addRemolquesApi, updateRemolquesApi, deleteRemolquesApi } from "../../api/A_Remolques/remolques";
import { useNavigate } from "react-router-dom";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "..";

export function useRemolques() {
  const { auth, logout } = useAuth();
  const navigate = useNavigate();
  const { unauthorized, SessionExpired, errores, unauthorizedModal } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    Remolques: null,
    RemolquesDB: null,
  });

  const { loading, error, Remolques, RemolquesDB } = state;

  const handleResponse = (response) => {
    if (response.status === 401) {
      SessionExpired();
      logout();
    } else if (response.status === 403) {
      unauthorized();
      navigate("/");
    }
  };

  const getRemolquesDB = async () => {
    try {
      const [result, response] = await getRemolquesApi(auth.token, auth.me.tenant);
      setState((prevState) => ({ ...prevState, RemolquesDB: result.results }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  const getRemolques = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getRemolquesApi(auth.token, auth.me.tenant);
      if (response.status === 401) {
        SessionExpired();
        logout();
      }
      if (response.status === 403) {
        unauthorized();
        return navigate("/");
      }
      setState((prevState) => ({ ...prevState, loading: false, Remolques: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addRemolques = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addRemolquesApi(data, auth.token, auth.me.tenant);
      if (response.status === 400) {
        if (result?.placa_remolq) {
          throw new Error("El numero de placa del remolque ya cuenta con un registro activo");
        } else {
          errores();
        }
      }
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateRemolques = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateRemolquesApi(id, data, auth.token, auth.me.tenant);
      if (response.status === 400) {
        if (result?.placa_remolq) {
          throw new Error("El numero de placa del remolque ya cuenta con un registro activo");
        } else {
          errores();
        }
      }
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteRemolques = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteRemolquesApi(id, auth.token, auth.me.tenant);
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    RemolquesDB,
    Remolques,
    getRemolquesDB,
    getRemolques,
    addRemolques,
    updateRemolques,
    deleteRemolques,
  };
}
