import styled from "styled-components";

export const ContainerDetail = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  @media (max-width: 780px) {
    grid-template-columns: 1fr;
  }
`;

export const ContainerTable = styled.div`
  height: 100%;
  width: 100%;

  .ant-table-thead {
    > tr {
      .ant-table-cell {
        background-color: ${({ theme }) => theme.primary.black};
        color: ${({ theme }) => theme.primary.gray2};

        &:last-child {
          text-align: center;
        }
      }
    }
  }

  .ant-table-cell {
    padding: 20 !important;
    font-size: 14px;
    text-align: center;

    > p {
      margin: 0 !important;
    }

    &:first-child {
      text-align: end;
      font-weight: bold;
    }
  }

  .ant-table-wrapper {
    margin-top: 20px;
  }
`;

export const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  gap: 4px;
  svg {
    font-size: 32px;
    cursor: pointer;
    fill: ${({ theme }) => theme.primary.gray3};
    &:hover {
      font-size: 34px;
      transition: 0.3s ease-in-out;
    }
  }
`;

export const Title = styled.h1`
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 50px;

  > button {
    width: 400px !important;
    background: ${({ theme }) => theme.primary.gradient} !important;
    font-weight: bold !important;
    color: ${({ theme }) => theme.primary.white} !important;
    &:hover {
      opacity: 0.7 !important;
      border: none !important;
      transition: 0.3s ease-in-out;
      color: ${({ theme }) => theme.primary.black} !important;
    }
  }
`;
