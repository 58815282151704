import styled from "styled-components";

export const Text = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "12px")};
  font-weight: 700;
  ${({ uppercase }) => uppercase && `text-transform: uppercase;`}
  ${({ color }) => color && `color: ${color};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "left")};
`;

export const ContainerTable = styled.div`
  .ant-table-cell {
    padding: 4px 0 !important;
    background-color: ${({ theme }) => theme.primary.red};
    color: ${({ theme }) => theme.primary.yellow};
  }

  .ant-table-cell:nth-child(1) {
    background-color: ${({ theme }) => theme.primary.gray5};
    color: ${({ theme }) => theme.primary.black};
  }
  .ant-table-cell > p {
    margin: 0 !important;
  }

  .ant-table-wrapper {
    margin-top: 20px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.primary.gray6};
    border-color: ${({ theme }) => theme.primary.gray6} !important;
  }
  .ant-checkbox-inner {
    &::after {
      background-color: ${({ theme }) => theme.primary.gray6};
    }
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;

  > button {
    width: 400px !important;
    background: ${({ theme }) => theme.primary.gradient} !important;
    font-weight: bold !important;
    color: ${({ theme }) => theme.primary.white} !important;
    &:hover {
      opacity: 0.7 !important;
      border: none !important;
      transition: 0.3s ease-in-out;
      color: ${({ theme }) => theme.primary.black} !important;
    }
  }
`;
