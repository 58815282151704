import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import { useFormik } from "formik";
import React from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useUrl } from "../../../hooks";
import { alertas } from "../../Comons/Alertas/alertas";

export function AddEditUrl(props) {
  const { onClose, onRefetch, Datos } = props;

  const { updateUrl } = useUrl();
  const { Successful } = alertas();

  const formik = useFormik({
    initialValues: initialValues(Datos),
    validationSchema: Yup.object(newSchema()),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        await updateUrl(Datos.id, formValue);
        Successful();
        onRefetch();
        onClose();
      } catch (error) {
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });

  return (
    <Box
      component="form"
      className="add-edit-secciones-form"
      onSubmit={formik.handleSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            name="nombre"
            label="Nombre"
            fullWidth
            value={formik.values.nombre}
            onChange={formik.handleChange}
            error={formik.touched.nombre && Boolean(formik.errors.nombre)}
            helperText={formik.touched.nombre && formik.errors.nombre}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="url_local"
            label="Url Local"
            fullWidth
            value={formik.values.url_local}
            onChange={formik.handleChange}
            error={formik.touched.url_local && Boolean(formik.errors.url_local)}
            helperText={formik.touched.url_local && formik.errors.url_local}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Button type="submit" variant="contained" color="error" className="btn btn-danger" fullWidth>
            {Datos ? "Actualizar" : "Crear"}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

function initialValues(data) {
  return {
    nombre: data?.nombre || "",
    url_local: data?.url_local || "",
  };
}

function newSchema() {
  return {
    nombre: Yup.string().required("Este campo es obligatorio"),
    url_local: Yup.string().required("Este campo es obligatorio"),
  };
}
