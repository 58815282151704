

  export const filterValue = [

    { name: 'N_turno', operator: 'contains', type: 'string', value: '' },
    { name: 'orden', operator: 'contains', type: 'string', value: '' },
    { name: 'tipo_turno', operator: 'contains', type: 'string', value: '' },
    { name: 'n_placa', operator: 'contains', type: 'string', value: '' },
    { name: 'cond_info', operator: 'contains', type: 'string', value: '' },
    { name: 'bahia', operator: 'contains', type: 'string', value: '' },
    { name: 'estado_t', operator: 'contains', type: 'string', value: '' },
    { name: 'proceso', operator: 'contains', type: 'string', value: '' },
   
  ];