export const groups = [
 
  { name: 'v_final', header: 'VOLUMEN FINAL' },
  { name: 'producto', header: 'FISICO' },

]

export const columns = [
  {
    header: "Fecha de cierre",
    name: "fecha_cierre",
    maxWidth: 1000,
    defaultFlex: 1,
  },
 
    {
      header: "Tanque",
      name: "tanque_nombre",
      maxWidth: 1000,
      defaultFlex: 1,
    },
    {
      header: "Recibo",
      name: "recibido",
      maxWidth: 1000,
      defaultFlex: 1,
    },
    {
      header: "Despacho",
      name: "despachado",
      maxWidth: 1000,
      defaultFlex: 1,
    },
    {
      header: "Volumen",
      name: "volumen_final",
      group: 'v_final',
      maxWidth: 1000,
      defaultFlex: 1,
    },
    {
      header: "Costo $",
      group: 'v_final',
      maxWidth: 1000,
      defaultFlex: 1,
    },
    {
      header: "Volumen",
      group: 'producto',
      name: "volumen_esperado",
      maxWidth: 1000,
      defaultFlex: 1,
    },
    {
      header: "Costo $",
      group: 'producto',
      maxWidth: 1000,
      defaultFlex: 1,
    },
    {
      header: "Diferencia",
      name: "diferencia",
      maxWidth: 1000,
      defaultFlex: 1,
    },
   
  ];

  
  export const filterValue = [
    {
      name: "tanque_nombre",
      operator: "before",
      type: "string",
      value: "",
    },
    {
      name: "volumen_final",
      operator: "before",
      type: "string",
      value: "",
    },
  
    { name: "volumen_esperado", operator: "startsWith", type: "string", value: "" },
    { name: "diferencia", operator: "startsWith", type: "string", value: "" },
    { name: "fecha_cierre", operator: "startsWith", type: "string", value: "" },
    
  ];
  