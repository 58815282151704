import 'chartjs-adapter-date-fns';
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { useConsumo } from "../../../hooks";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    Accordion,
    AccordionDetails, AccordionSummary,
    Box,
    Button,
    Card, CardContent,
    Container, Grid, Typography
} from "@mui/material";

import { Tab, Tabs } from '@material-ui/core';
import { Bar, Doughnut } from 'react-chartjs-2';
import { Divider, Loader } from "semantic-ui-react";
import { DataGridNoOpt } from "../../../Components/Comons/TablaBasic";
import { useAuth, useTanques } from "../../../hooks";
import { filterReportTable, reportTableColumns } from "./ConsumoFormat";
import { Funciones } from "./Funciones";

import { Line } from 'react-chartjs-2';

import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    TimeScale,
    Title,
    Tooltip,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ArcElement,
    LineElement,
    TimeScale,
);

export function ReportesMain() {
    const { auth } = useAuth()
    const { loading, Consumo, getConsumo, ConsumoByMonth, getConsumoByMonth } = useConsumo()
    const { TanquesCI, getTanquesCI } = useTanques()
    const [selectedDataByMonth, setSelectedDataByMonth] = useState(null)
    const [selectedTankData, setSelectedTankData] = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    const [groupedDataByRange, setGroupedDataByRange] = useState([]);
    const [transformData, setTransformData] = useState([])
    const [lastDate, setLastDate] = useState(null)
    const [selectedData, setSelectedData] = useState('Last 7 days')
    const [showLast30Days, setShowLast30Days] = useState(true);
    const [groupedByPlateData, setGroupedByPlateData] = useState([])
    const predefinedColors = ['blue', 'red', 'green', 'purple', 'orange'];
    const [misOptions, setMisOptions] = useState({})
    const [misOptions2, setMisOptions2] = useState({})
    const [expandedAccordion, setExpandedAccordion] = useState(null);

    const handleCloseCardDataByMonth = () => {
        setSelectedDataByMonth(null)
    }

    const handleCloseCardTankData = () => {
        setSelectedTankData(null)
    }

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpandedAccordion(isExpanded ? panel : null);
    };

    const [consumoByMonthChart, setConsumoByMonthChart] = useState({
        labels: [],
        datasets: [{
            label: 'Consumo en el mes',
            data: [],
            backgroundColor: 'rgba(0, 0, 0, 1)'
        }]
    })

    const [consumoByPlateChart, setConsumoByPlateChart] = useState({
        labels: [],
        datasets: [{
            data: []
        }]
    })

    const {
        refetch,
    } = Funciones();

    useEffect(() => {
        getConsumo()
        getConsumoByMonth()
        getTanquesCI()
    }, [refetch])

    useEffect(() => {
        if (Consumo && ConsumoByMonth && TanquesCI) {

            // agrupa los datos de la respuesta por fecha y suma la cantidad de consumo por cada día
            const groupedData = Consumo.reduce((acc, item) => {
                const dateKey = moment(item.fecha_crea).format('YYYY-MM-DD');
                const combustibleKey = `${dateKey}-${item.tipo_combustible}`;
                if (!acc[combustibleKey]) {
                    acc[combustibleKey] = {
                        fecha_crea: dateKey,
                        sum_cant_consumo: 0,
                        tipo_combustible: item.tipo_combustible
                    };
                }
                acc[combustibleKey].sum_cant_consumo += parseInt(item.cant_consumo, 10);
                return acc;
            }, {});

            // obtención de la última fecha en los datos de la respuesta
            const lastDateFromResponse = Consumo.reduce((acc, item) => {
                const itemDate = moment(item.fecha_crea);
                if (!acc || itemDate.isAfter(acc)) {
                    return itemDate;
                }
                return acc;
            }, null);

            // establece una constante con los datos
            setLastDate(lastDateFromResponse);

            // Conversión del objeto groupedData a array 
            const groupedDataArray = Object.values(groupedData);

            // Agrupación de los datos en un rango de siete y treinta días
            // const today = moment();
            const dateRanges = [
                { label: 'Last 7 days', range: 7 },
                { label: 'Last 30 days', range: 30 },
            ];

            // agrupa los datos por el rango establecido y los pone en un arreglo
            const groupedDataByRangeC = dateRanges.map(({ label, range }) => {
                const startDate = lastDateFromResponse.clone().subtract(range, 'days').startOf('day');
                const endDate = lastDateFromResponse.clone().endOf('day');

                const filteredData = groupedDataArray.filter(
                    (item) => moment(item.fecha_crea).isBetween(startDate, endDate, null, '[]')
                );

                return {
                    label,
                    data: filteredData,
                };
            });

            // Agrupa los datos por tipo_combustible por cada "label"
            const transformedData = groupedDataByRangeC.map(({ label, data }) => {
                const groupedData = data.reduce((acc, curr) => {
                    const { tipo_combustible, ...rest } = curr;

                    if (!acc[tipo_combustible]) {
                        acc[tipo_combustible] = [];
                    }

                    acc[tipo_combustible].push({ tipo_combustible, ...rest });
                    return acc;
                }, {});

                // Ordena los valores por fecha
                Object.values(groupedData).forEach((array) => {
                    array.sort((a, b) => new Date(a.fecha_crea) - new Date(b.fecha_crea));
                });

                return {
                    [label]: groupedData
                };
            });

            setTransformData(transformedData)
            setGroupedDataByRange(groupedDataByRangeC);

            // consumo total por placa de vehículo y máquina
            const grupedByPlateData = {}

            // agrupa los datos por placa y tipo de combustible
            Consumo.forEach((item) => {
                const key = `${item.placa_obj}-${item.tipo_combustible}`;
                if (grupedByPlateData[key]) {
                    grupedByPlateData[key].sum_cant_consumo += parseInt(item.cant_consumo, 10);
                    grupedByPlateData[key].data.push(item)
                } else {
                    grupedByPlateData[key] = {
                        placa_obj: item.placa_obj,
                        tipo_combustible: item.tipo_combustible,
                        sum_cant_consumo: parseInt(item.cant_consumo, 10),
                        data: [item]
                    };
                }
            });

            // Convierte el objeto a un arreglo
            const groupedByPlateDataArray = Object.values(grupedByPlateData);

            const maxConsumoByPlate = groupedByPlateDataArray.reduce(
                (maxValue, item) => Math.max(maxValue, item.sum_cant_consumo),
                0
            );

            // Establece la variable global para que sea accedida en la gráfica
            setGroupedByPlateData(groupedByPlateDataArray);

            setConsumoByPlateChart((prevData) => ({
                ...prevData,
                labels: groupedByPlateData.map((item) => item.placa_obj),
                datasets: [
                    {
                        label: "Consumo total por vehículo/máquina",
                        data: groupedByPlateData.map((item) => item.sum_cant_consumo),
                        backgroundColor: "rgba(92, 90, 90, 1)",
                        borderColor: "rgba(0, 0, 0, 1)",
                        borderWidth: 2,
                    },
                ],
            }))

            // opciones de consumo vs placa
            setMisOptions((prevOptions) => ({
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                            stepSize: 10,
                        },
                    },
                },
                plugins: {
                    scales: {
                        x: {
                            title: {
                                display: true,
                                text: 'Placa',
                            }
                        },
                        y: {
                            max: maxConsumoByPlate + 3,
                            title: {
                                display: true,
                                text: 'Consumo total en Galones',
                            }
                        },
                    },
                    tooltip: {
                        callbacks: {
                            title: function (context) {
                                const index = context[0].dataIndex;
                                return `Conductor: ${Consumo[index]?.conductor?.nombre} ${Consumo[index]?.conductor?.apellidos}\nCombustible: ${Consumo[index]?.tipo_combustible}`
                            },
                        },
                    },
                }
            }));

            // consumo por mes
            const consumoByMonthData = ConsumoByMonth.map((item) => {
                const monthKey = Object.keys(item)[0];
                return item[monthKey].tot_consumo_month;
            });
            const maxConsumoByMonth = Math.max(...consumoByMonthData);
            const monthLabels = ConsumoByMonth.map((item) => Object.keys(item)[0]);

            setConsumoByMonthChart((prevData) => ({
                ...prevData,
                labels: monthLabels,
                datasets: [
                    {
                        ...prevData.datasets[0],
                        data: consumoByMonthData,
                        backgroundColor: "rgba(92, 90, 90, 1)",
                        borderColor: "rgba(0, 0, 0, 1)",
                        borderWidth: 2
                    }
                ]
            }));

            // opciones de consumo por mes
            setMisOptions2((prevOptions) => ({
                responsive: true,
                animation: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        callbacks: {
                            title: function (context) {
                                const index = context[0].dataIndex;
                                const selectedConsumoByMonth = ConsumoByMonth[index];
                                const monthLabel = Object.keys(selectedConsumoByMonth)[0];

                                const selectedConsumoByMonthData = selectedConsumoByMonth[monthLabel].data

                                const modifiedData = selectedConsumoByMonthData ? selectedConsumoByMonthData.map((item) => ({
                                    ...item,
                                    tanque: item.tank.nombre_tanque,
                                    desplazamiento: item.veh_or_maq.desplazamiento,
                                    nombre: item.conductor.nombre + " " + item.conductor.apellidos,
                                    cant_tot_combustible: item.veh_or_maq.cant_tot_combustible
                                })) : [];

                                setSelectedDataByMonth(modifiedData);
                            },
                        },
                    },
                },
                scales: {
                    y: {
                        max: maxConsumoByMonth + 3,
                    },
                },
            }));
        }
    }, [Consumo, ConsumoByMonth, TanquesCI]);

    // datos de la gráfica del tanque
    const tankChartData = TanquesCI?.map((tanque) => ({
        labels: [`Actual / total: ${tanque.cap_consumo_int_act} ${auth.me.SistemasMedicion.volumen} / ${tanque.cap_consumo_int} ${auth.me.SistemasMedicion.volumen}`,
        `Diferencia: ${parseFloat(tanque.cap_consumo_int) - parseFloat(tanque.cap_consumo_int_act)} ${auth.me.SistemasMedicion.volumen}`],
        datasets: [
            {
                data: [parseFloat(tanque.cap_consumo_int_act), parseFloat(tanque.cap_consumo_int) -
                    parseFloat(tanque.cap_consumo_int_act)],
                backgroundColor: [
                    '#ed4a3e',
                    '#5e5d5c',
                ],
            },
        ],
    }));

    // opciones de la gráfica de consumo total vs fecha
    const chartOptions = {
        scales: {
            x: {
                type: 'time',
                time: {
                    tooltipFormat: 'MMM do',
                    unit: 'day',
                    displayFormats: {
                        day: 'MMM do ',
                        month: 'MMM do ',
                        year: 'MMM do ',
                    },
                },
                ticks: {
                    maxTicksLimit: 15, // número máximo de etiquetas en el eje x
                },
                title: {
                    display: true,
                    text: 'Fecha de registro',
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Total de consumo en Galones',
                },
            },
        },
    };

    // selecciona los datos de la gráfica clickeada para que se muestren en la tabla
    const handleCardTankDataClick = (id_tanque) => {
        const filteredConsumo = Consumo.filter((consumo) => consumo.id_tanque === id_tanque);

        const modifiedData = filteredConsumo ? filteredConsumo.map((item) => ({
            ...item,
            tanque: item.tank.nombre_tanque,
            desplazamiento: item.veh_or_maq.desplazamiento,
            nombre: item.conductor.nombre + " " + item.conductor.apellidos,
            cant_tot_combustible: item.veh_or_maq.cant_tot_combustible
        })) : [];

        setSelectedTankData(modifiedData);
    };

    const handleLineChartDataChange = () => {
        if (showLast30Days) {
            handleLast30DaysClick(); // Call the function for the last 30 days
        } else {
            handleLast7DaysClick(); // Call the function for the last 7 days
        }
        setShowLast30Days((prev) => !prev);
    }

    const handleLast7DaysClick = () => {
        setSelectedData('Last 7 days');
    };

    const handleLast30DaysClick = () => {
        setSelectedData('Last 30 days');
    };

    const getLineChartData = () => {
        const selectedDataObject = transformData.find((data) => data[selectedData]);
        if (selectedDataObject) {
            return Object.entries(selectedDataObject[selectedData]).map(([tipo_combustible, data], index) => ({
                label: tipo_combustible,
                data: data.map((item) => ({
                    x: item.fecha_crea,
                    y: item.sum_cant_consumo,
                })),
                borderColor: predefinedColors[index % predefinedColors.length]
            }));
        }
        return [];
    };



    return (
        <>
            {loading ? (
                <Loader active inline="centered" />
            ) : (
                <>
                    <h1>Resumen de consumo en el tiempo</h1>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={7} md={10}>
                            <Card sx={{ marginBottom: '1rem' }}>
                                <CardContent>
                                    <Button onClick={handleLineChartDataChange}>
                                        {showLast30Days ? 'últimos 30 días' : 'Últimos 7 días'}
                                    </Button>
                                    <Line
                                        data={{
                                            datasets: getLineChartData(),
                                        }}
                                        options={chartOptions}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <Tabs value={activeTab} onChange={handleTabChange}>
                        <Tab label="Tanques" />
                        <Tab label="Fecha" />
                        <Tab label="Vehículo" />
                    </Tabs>

                    {activeTab === 0 && (
                        <>
                            <h1>Tanques de consumo interno</h1>
                            <Grid container spacing={2}>
                                {TanquesCI?.map((tanque, index) => (
                                    <Grid item xs={12} sm={6} md={4} key={tanque.id_tanque}>
                                        <Card sx={{ marginBottom: '1rem' }} onClick={() => handleCardTankDataClick(tanque.id_tanque)}>
                                            <CardContent>
                                                <Typography variant="h6" component="div" gutterBottom>
                                                    {tanque.nombre_tanque}
                                                </Typography>
                                                <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                                                    <p>Cód: {tanque.codigo_tanque}</p>
                                                </Typography>
                                                <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                                                    <p>Combustible: {tanque.producto.nombre}</p>
                                                </Typography>
                                                <Typography variant="body2">
                                                    <Doughnut data={tankChartData[index]} />
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                            <Grid>
                                {/* data from cliked card */}
                                {selectedTankData && (
                                    <>
                                        <Card>
                                            <CardContent>
                                                <Button variant="contained" onClick={handleCloseCardTankData}>Cerrar</Button>
                                                <DataGridNoOpt
                                                    data={selectedTankData}
                                                    title="Consumo por tanque 2"
                                                    id="id_table"
                                                    filterValue={filterReportTable}
                                                    columns={reportTableColumns}
                                                />
                                            </CardContent>
                                        </Card>
                                    </>
                                )}
                            </Grid>
                        </>
                    )}

                    {activeTab === 1 && (
                        <>
                            <h1>Consumo por mes</h1>
                            <Box component="main" sx={{ flexGrow: 1, py: 8, }}>
                                <Container maxWidth="xl">
                                    <Grid container spacing={3} justifyContent="center" alignItems="center">
                                        <Grid xs={12} lg={8}>
                                            <Box sx={{ width: "100%" }}>
                                                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                                    {/* Graphic */}
                                                    <Bar data={consumoByMonthChart} options={misOptions2} />
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </Box>
                            <Grid xs={12} lg={4}>
                                {selectedDataByMonth && (
                                    <Card>
                                        <CardContent>
                                            <Button variant="contained" onClick={handleCloseCardDataByMonth}>Cerrar</Button>
                                            <DataGridNoOpt
                                                data={selectedDataByMonth}
                                                title="Consumo mensual"
                                                id="id_table"
                                                filterValue={filterReportTable}
                                                columns={reportTableColumns}
                                            />
                                        </CardContent>
                                    </Card>
                                )}
                            </Grid>
                        </>
                    )}

                    {activeTab === 2 && (
                        <>
                            <div><h1>Consumo vs placa de vehículo</h1></div>
                            <Box component="main" sx={{ flexGrow: 1, py: 8, }}>
                                <Container maxWidth="xl">
                                    <Grid container spacing={3} justifyContent="center" alignItems="center">
                                        <Grid xs={12} lg={8}>
                                            <Box sx={{ width: "100%" }}>
                                                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                                    {groupedByPlateData.length < 0 ? (
                                                        <Loader active inline="centered" />
                                                    ) : groupedByPlateData.length > 0 && (
                                                        <div style={{ height: "400px", width: "600px" }}>
                                                            <Bar
                                                                data={consumoByPlateChart} options={misOptions}
                                                            />
                                                        </div>
                                                    )}
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Divider></Divider>
                                    <Grid>
                                        {groupedByPlateData && groupedByPlateData?.map((item, index) => (
                                            <div key={index}>
                                                <Accordion expanded={expandedAccordion === index} onChange={handleAccordionChange(index)}>
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                        <Typography variant="h6">
                                                            {
                                                                groupedByPlateData?.length > 0 && (
                                                                    <>
                                                                        <div>
                                                                            {item.placa_obj} | {item.data[0].tipo_obj} | {item.data[0].veh_or_maq?.marca}
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        {expandedAccordion === index && (
                                                            <div>
                                                                <DataGridNoOpt
                                                                    key={index}
                                                                    data={item.data.map((dataItem) => ({
                                                                        ...dataItem,
                                                                        tanque: dataItem.tank?.nombre_tanque,
                                                                        desplazamiento: dataItem.veh_or_maq?.desplazamiento,
                                                                        nombre: dataItem.conductor?.nombre + " " + dataItem.conductor?.apellidos,
                                                                        cant_tot_combustible: dataItem.veh_or_maq?.cant_tot_combustible,
                                                                    }))}
                                                                    title="Consumo total por placa"
                                                                    id="id_table"
                                                                    filterValue={filterReportTable}
                                                                    columns={reportTableColumns}
                                                                />
                                                            </div>
                                                        )}
                                                    </AccordionDetails>
                                                </Accordion>
                                                <br></br>
                                            </div>
                                        ))}
                                    </Grid>
                                </Container>
                            </Box>
                        </>
                    )}
                </>
            )}
        </>
    );
}
