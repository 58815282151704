import { useState, useCallback, useEffect } from 'react'
import { useTanques } from '../../../hooks';
import { GraySwitch } from "./styles";

import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TableBody from "@mui/material/TableBody"
import Switch from "@mui/material/Switch"

export function ControlMedicion() {
  const [refetch, setRefetch] = useState(false);
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);
  const { loading, tanques, getTanques, updatePartialTanques } = useTanques();

  useEffect(() => {
    getTanques();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const handleSwitchChange = (id_tanque, newValue) => {
    console.log(id_tanque, newValue);
    // updatePartialTanques(id_tanque, { tipo_medicion: newValue ? "2" : "1" })
    //   .then(() => {
    //     onRefetch(); // Llama a onRefetch después de que la promesa se haya resuelto
    //   })
    //   .catch((error) => {
    //     console.error("Error al actualizar el tipo de medición:", error);
    //   });
  };

  return (
    <Box>
      <Typography variant="h4">Control de Medicion</Typography>
      {tanques?.results && tanques?.results.length > 0 ? ( // Verificación de cortocircuito para validar la existencia de tanques
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nombre Tanque</TableCell>
                <TableCell align="center">Tipo Medicion</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tanques?.results.map((tanque) => (
                <TableRow key={tanque.id_tanque}>
                  <TableCell>{tanque.nombre_tanque}</TableCell>
                  <TableCell align="center">
                    <GraySwitch
                      checked={tanque.tipo_medicion === "2"}
                      onChange={(event) =>
                        {
                          console.log(event.target.checked)
                          handleSwitchChange(tanque.id_tanque, event.target.checked)
                        }
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body1">No hay datos de tanques disponibles.</Typography>
      )}
    </Box>
  );
}

