export const columns = [
    {
      name: "nombre",
      header: "Nombre",
      maxWidth: 1000, defaultFlex: 1
    },
    {
      name: "CTL_RT",
      header: "CTL_RT",
      maxWidth: 1000, defaultFlex: 1,
      type: "number",
    },
    {
      name: "CPL_RT",
      header: "CPL_RT",
      maxWidth: 1000, defaultFlex: 1,
      type: "number",
      
    },
    {
      name: "CTPL_RT",
      header: "CTPL_RT",
      maxWidth: 1000, defaultFlex: 1,
      type: "number",
      
    },
    {
      name: "IV_DEV",
      header: "IV_DEV",
      maxWidth: 1000, defaultFlex: 1
      ,type: "number",
      
    },
    {
      name: "GOV_RT",
      header: "GOV_RT",
      maxWidth: 1000, defaultFlex: 1,
      type: "number",
      
    },
    {
      name: "GSV_RT",
      header: "GSV_RT",
      maxWidth: 1000, defaultFlex: 1,
      type: "number",
      
    },
    {
      name: "NSV_RT",
      header: "NSV_RT",
      maxWidth: 1000, defaultFlex: 1,
      type: "number",
    },
    {
        name: "TEMP_RT",
        header: "TEMP_RT",
        maxWidth: 1000, defaultFlex: 1
      },
      {
        name: "TEMP_AV",
        header: "TEMP_AV",
        maxWidth: 1000, defaultFlex: 1,
        type: "number",
      },
      {
        name: "BSW_RT",
        header: "BSW_RT",
        maxWidth: 1000, defaultFlex: 1,
        type: "number",
        
      },
      {
        name: "PRES_RT",
        header: "PRES_RT",
        maxWidth: 1000, defaultFlex: 1,
        type: "number",
        
      },
      {
        name: "PRES_AV",
        header: "PRES_AV",
        maxWidth: 1000, defaultFlex: 1
        ,type: "number",
        
      },
      {
        name: "GAPI_RT",
        header: "GAPI_RT",
        maxWidth: 1000, defaultFlex: 1,
        type: "number",
        
      },
      {
        name: "GAPI_AV",
        header: "GAPI_AV",
        maxWidth: 1000, defaultFlex: 1,
        type: "number",
        
      },
      {
        name: "DENS_RT",
        header: "DENS_RT",
        maxWidth: 1000, defaultFlex: 1,
        type: "number",
      },
      {
        name: "DENS_AV",
        header: "DENS_AV",
        maxWidth: 1000, defaultFlex: 1
        ,type: "number",
        
      },
      {
        name: "DENR_AV",
        header: "DENR_AV",
        maxWidth: 1000, defaultFlex: 1,
        type: "number",
        
      },
     
  ];

  export const filterValue = [
    { name: 'nombre', operator: 'startsWith', type: 'string', value: '' },
    
  ];