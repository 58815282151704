import { BASE_API } from "../../utils/constants";
import { makeRequest } from "../Request";
const BASE_URL = `${BASE_API}/`;

export async function getConsumoApi(token, schema) {
    const url = `${BASE_URL}${schema}/consumo/consumoadm`;
    return makeRequest(url, "GET", token);
}

export async function getConsumoByMonthApi(token, schema) {
    const url = `${BASE_URL}${schema}/consumo/consumomonth`;
    return makeRequest(url, "GET", token);
}

export async function getAvgConsumoLastMonthApi(token, schema) {
    const url = `${BASE_URL}${schema}/consumo/consumoavg`;
    return makeRequest(url, "GET", token);
}

export async function postConsumoApi(data, token, schema,) {
    const url = `${BASE_URL}${schema}/consumo/consumoadm/`
    return makeRequest(url, "POST", token, data)
}

export async function updateConsumoApi(id_table, data, token, schema) {
    const url = `${BASE_URL}${schema}/consumo/consumoadm/${id_table}/`;
    return makeRequest(url, "PUT", token, data);
}

export async function deleteConsumoApi(id_table, token, schema) {
    const url = `${BASE_URL}${schema}/consumo/consumoadm/${id_table}/`;
    return makeRequest(url, "DELETE", token);
}

export async function getConsumoInternoApi(token, schema) {
    const url = `${BASE_URL}${schema}/consumo/consumointerno`;
    return makeRequest(url, "GET", token);
}

export async function postConsumoInternoApi(data, token, schema,) {
    const url = `${BASE_URL}${schema}/consumo/consumointerno/`
    return makeRequest(url, "POST", token, data)
}

export async function updateConsumoInternoApi(id_consumo_int, data, token, schema) {
    const url = `${BASE_URL}${schema}/consumo/consumointerno/${id_consumo_int}/`;
    return makeRequest(url, "PUT", token, data);
}

export async function deleteConsumoInternoApi(id_consumo_int, token, schema) {
    const url = `${BASE_URL}${schema}/consumo/consumointerno/${id_consumo_int}/`;
    return makeRequest(url, "DELETE", token);
}

export async function getDataFromTCSApi(token, schema) {
    const url = `${BASE_URL}${schema}/consumo/datafromtcs`;
    return makeRequest(url, "GET", token);
}

export async function postDataWTCSapi(data, token, schema) {
    const url = `${BASE_URL}${schema}/consumo/consumoadm/beginTcsOp/`
    return makeRequest(url, "POST", token, data)
}

export async function getTicketDataApi(token, schema) {
    const url = `${BASE_URL}${schema}/consumo/tcsticket`
    return makeRequest(url, "GET", token)
}

export async function getOrdenesSuministroApi(token, schema) {
    const url = `${BASE_URL}${schema}/consumo/ordenessuministro`;
    return makeRequest(url, "GET", token);
}

export async function postOrdenesSuministroApi(data, token, schema,) {
    const url = `${BASE_URL}${schema}/consumo/ordenessuministro/`
    return makeRequest(url, "POST", token, data)
}

export async function updateOrdenesSuministroApi(id_orden, data, token, schema) {
    const url = `${BASE_URL}${schema}/consumo/ordenessuministro/${id_orden}/`;
    return makeRequest(url, "PUT", token, data);
}

export async function deleteOrdenesSuministroApi(id_orden, token, schema) {
    const url = `${BASE_URL}${schema}/consumo/ordenessuministro/${id_orden}/`;
    return makeRequest(url, "DELETE", token);
}

// emisiones
export async function getEmisionesApi(token, schema) {
    const url = `${BASE_URL}${schema}/consumo/emisiones`;
    return makeRequest(url, "GET", token);
}

export async function postEmisionesApi(data, token, schema,) {
    const url = `${BASE_URL}${schema}/consumo/emisiones/`
    return makeRequest(url, "POST", token, data)
}

export async function updateEmisionesApi(id_table, data, token, schema) {
    const url = `${BASE_URL}${schema}/consumo/emisiones/${id_table}/`;
    return makeRequest(url, "PUT", token, data);
}

export async function deleteEmisionesApi(id_table, token, schema) {
    const url = `${BASE_URL}${schema}/consumo/emisiones/${id_table}/`;
    return makeRequest(url, "DELETE", token);
}

export async function getExtraDataApi(token, schema) {
    const url = `${BASE_URL}${schema}/consumo/extradata`
    return makeRequest(url, "GET", token)
}
