import styled from "styled-components";

export const ContainerIcons = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerIcon = styled.div`
  display: flex;
  gap: 4px;
  svg {
    width: ${({ width }) => (width ? `${width}px` : "24px")};
    height: ${({ height }) => (height ? `${height}px` : "36px")};
  }
`;

export const ContainerCircleIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
