import { IconButton, Tooltip } from "@material-ui/core";
import { SaveAlt } from "@material-ui/icons";
import QRCode from 'qrcode';
import React, { useState, useCallback } from "react";
import { AddEditTurnos } from "../../../Components/Client";
import { useAuth, } from "../../../hooks";
import { BASE_API } from "../../../utils/constants";

export function Funciones() {

  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const { auth } = useAuth();
  const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);

  const handleAdd = useCallback(() => {
    setTitleModal("Nuevo turno");
    setContentModal(
      <AddEditTurnos
        onClose={openCloseModal}
        onRefetch={onRefetch}
      />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const generateQRCode = async (rowData) => {
    const { N_turno, orden, bahia, proceso_t, n_placa, cond_info } = rowData;
    const url = `${BASE_API}/${auth.me.tenant}/documentos/qr/`;
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `n_orden=${orden}&n_turno=${N_turno}&n_proceso=${proceso_t}`,
    });
    const data = await response.json();

    const qrCanvas = document.createElement('canvas');
    qrCanvas.height = 600; // aumenta la altura del espacio definido, pero no sirve
    qrCanvas.width = 200

    // Dibuja el código QR en el espacio definido, la margen es lo que permite que el texto
    // se ubique debajo del qr
    await QRCode.toCanvas(qrCanvas, data.qr_content, { margin: 15, scale: 10 });

    // Pone el texto en el espacio definido
    const ctx = qrCanvas.getContext('2d');
    ctx.font = '25px arial'; // tamaño de la fuente y el estilo
    ctx.textAlign = 'center'; // centra el texto

    // ubica de forma horizontal el texto en la mitad del espacio y
    // ubica de forma vertical el texto
    ctx.fillText(`Conductor: ${cond_info}`, qrCanvas.width / 2, qrCanvas.height - 120);
    ctx.fillText(`Orden: ${orden}`, qrCanvas.width / 2, qrCanvas.height - 90)
    ctx.fillText(`Placa: ${n_placa}`, qrCanvas.width / 2, qrCanvas.height - 60);
    ctx.fillText(`Turno: ${data.n_turno}`, qrCanvas.width / 2, qrCanvas.height - 30);
    ctx.fillText(`Bahia: ${bahia}`, qrCanvas.width / 2, qrCanvas.height - 1)

    const qrImg = qrCanvas.toDataURL();
    const qrContainer = document.createElement('div');
    qrContainer.style.display = 'flex';
    qrContainer.style.flexDirection = 'column';
    qrContainer.style.alignItems = 'center';
    const qrWrapper = document.createElement('div');
    qrWrapper.style.maxWidth = '300px';
    qrWrapper.innerHTML = `
    <img src="${qrImg}" alt="QR code" />
    <span style="font-size: 14px;">Turno: ${data.n_turno} Bahia: ${data.n_bahia}</span>
  `;
    qrContainer.appendChild(qrWrapper);
    const downloadLink = document.createElement('a');
    downloadLink.href = qrWrapper.querySelector('img').src;
    downloadLink.download = `qr-code${rowData.orden}.png`;
    downloadLink.click();
  };
  const columns = [
    // {
    //   header: 'ID',
    //   name: 'idIncremental',
    //   maxWidth: 1000, defaultFlex: 1

    // },
    {
      header: 'Numero de turno',
      name: 'N_turno',
      maxWidth: 1000, defaultFlex: 1
    },
    {
      header: 'Numero de orden',
      name: 'orden',
      maxWidth: 1000, defaultFlex: 1
    },
    {
      header: 'Tipo turno',
      name: 'tipo_turno',
      maxWidth: 1000, defaultFlex: 1
    },
    {
      header: 'Placa',
      name: 'n_placa',
      maxWidth: 1000, defaultFlex: 1
    },
    {
      header: 'Conductor',
      name: 'cond_info',
      maxWidth: 1000, defaultFlex: 1
    },
    {
      header: 'Bahia',
      name: 'bahia',
      maxWidth: 1000, defaultFlex: 1
    },
    {
      header: 'Estado',
      name: 'estado_t',
      maxWidth: 1000, defaultFlex: 1
    },
    {
      header: 'Proceso',
      name: 'proceso',
      maxWidth: 1000, defaultFlex: 1
    },
    {
      header: 'QR',
      id: 'qr',
      render: ({ data }) => (
        <>
          <Tooltip title="Generar QR" arrow>
            <IconButton onClick={() => generateQRCode(data)}>
              <SaveAlt />
            </IconButton>
          </Tooltip>
        </>
      ),
      maxWidth: 1000, defaultFlex: 1
    },
  ];



  return {
    handleAdd,
    columns,
    refetch,
    contentModal,
    titleModal,
    showModal,
    openCloseModal,
  };
}
