import { useFormik } from "formik";
import React, { useEffect, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import { FormControl, InputLabel, Select, Autocomplete } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'

import { alertas } from "../../Comons/Alertas/alertas";
import * as Yup from "yup";

import { toast } from "react-toastify";

import { useAccesos } from "../../../hooks";
import { Button } from '@material-ui/core'

const UserTypes = [
    { key: 'normal', value: 'normal', text: 'Usuario normal' },
    { key: 'visitor', value: 'visitor', text: 'Visitante' },
    { key: 'blackList', value: 'blackList', text: 'Usuario en la lista negra' },
]

const Fingers = [
    { key: 1, value: 1, text: 'Dedo 1' },
    { key: 2, value: 2, text: 'Dedo 2' },
    { key: 3, value: 3, text: 'Dedo 3' },
    { key: 4, value: 4, text: 'Dedo 4' },
    { key: 5, value: 5, text: 'Dedo 5' },
    { key: 6, value: 6, text: 'Dedo 6' },
    { key: 7, value: 7, text: 'Dedo 7' },
    { key: 8, value: 8, text: 'Dedo 8' },
    { key: 9, value: 9, text: 'Dedo 9' },
    { key: 10, value: 10, text: 'Dedo 10' },
]

export function AddEditHuellas(props) {
    const { onRefetch, onClose, Conductor } = props;
    const { bioData, getPersonDataFromBiometric, loadingConfigT, postPersonDataToBiometric } = useAccesos()
    const [valuesSet, setValuesSet] = useState(false);
    const [fcImg, setFcImg] = useState(false);
    const [fpCheck, setFpCheck] = useState(false)
    const [loading, setLoading] = useState(false);
    const { Successful } = alertas();

    const [errors, setErrors] = React.useState({
        userType: false
    });

    useEffect(() => {
        getPersonDataFromBiometric(Conductor.id_conductor)
    }, [])

    const handleFpCheck = (event) => {
        setFpCheck(event.target.checked)
    }

    const handleFcImgCheck = (event) => {
        if (!Conductor?.foto) {
            toast.warning("¡El conductor no tiene imagen!", {
                position: "top-center",
            })
        } else {
            setFcImg(event.target.checked)
        }
    }

    const formik = useFormik({
        initialValues: {
            // si no hay datos en el biométrico entonces toma los datos del Conductor para el nombre
            name: bioData?.name ? bioData?.name : Conductor?.nombre + " " + Conductor?.apellidos,
            userType: "",
            beginTime: null,
            endTime: null,
            enable: false,
        },
        // validationSchema: Yup.object(bioData ? updateScheme() : newScheme()),
        validateOnChange: false,
        onSubmit: async (formValue) => {
            try {
                const dataToSend = {}

                // construye un diccionario con los datos a enviar (id, fecha de inicio y fin del acceso, ...)
                dataToSend['UserInfo'] = {
                    'UserInfo': {
                        "employeeNo": Conductor.id_conductor.toString(),
                        "name": formValue.name,
                        "userType": formValue.userType,
                        "Valid": {
                            "enable": true,
                            "beginTime": formValue.beginTime,
                            "endTime": formValue.endTime,
                        }
                    }
                }

                // añade a la estrucctura anterior un diccionario con la verificación para que se registre la huella
                dataToSend['fingerPrint'] = {
                    'fingerPrint': {
                        "useFP": fpCheck,
                        "finger": formValue.finger
                    }
                }

                // añade a la estructura inicial un diccionario con la verificación de que se agregue la imagen y la url de la imagen
                dataToSend['faceImg'] = {
                    'faceImg': {
                        "useFcImg": fcImg,
                        "img_url": Conductor?.foto
                    }
                }

                // indica si se está actualizando el registro o se está creando
                if (bioData && bioData.length > 0) {
                    dataToSend['action'] = {
                        'action': 1
                    }
                } else {
                    dataToSend['action'] = {
                        'action': 0
                    }
                }

                // establece la variable para que se muestre que está cargando
                setLoading(true)

                // realiza la petición para crear todo en el biométrico
                await postPersonDataToBiometric(dataToSend)

                Successful();
                onRefetch();
                onClose();
            } catch (error) {
                // Muestra un mensaje de error si ocurre algún problema
                if (error?.message) {
                    toast.error(error.message, {
                        position: "top-center",
                    });
                } else {
                    onClose();
                }
            } finally {
                setLoading(false); // cambia el estado de la constante loading cuando finaliza la petición
            }
        },
    })

    // verifica que los datos del dispositivo hayan cargado si hay datos qué mostrar
    useEffect(() => {
        if (!valuesSet && bioData && bioData.length > 0) {
            formik.setValues(initialValues(bioData[0], Conductor));
            setValuesSet(true)
        }
    }, [bioData, formik])

    const handleAutocompleteChange = (name, value) => {
        formik.setFieldValue(name, value?.value || '');
        setErrors((prevErrors) => ({ ...prevErrors, [name]: !value }));
    };

    return (
        <>
            {loading ? (
                <>
                    {/* Muestra un spinner mientras se ejecuta la petición */}
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <h3>Agregando información del conductor al dispositivo</h3>
                        <CircularProgress />
                    </div>
                </>
            ) :
                <>
                    {!bioData || loadingConfigT ? (
                        <>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                                <CircularProgress />
                            </div>
                        </>
                    ) :
                        <>
                            <form className="add-edit-secciones-form" onSubmit={formik.handleSubmit}>
                                <Grid container spacing={3}>
                                    {/* fila 1*/}
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Nombre"
                                            name="name"
                                            id="name"
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Autocomplete
                                            id="usuariolb"
                                            options={UserTypes}
                                            getOptionLabel={(option) => option.text || ''}
                                            value={UserTypes.find((option) => option.value === formik.values.userType) || null}
                                            onChange={(_, value) => handleAutocompleteChange('userType', value)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Tipo de usuario"
                                                    variant="outlined"
                                                    error={errors.userType}
                                                    helperText={errors.userType ? 'Debe seleccionar el tipo de usuario' : ''}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    {/* fila 2 */}
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Fecha de inicio para el acceso"
                                            name="beginTime"
                                            value={formik.values.beginTime}
                                            onChange={formik.handleChange}
                                            type="datetime-local"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Fecha de fin para el acceso"
                                            name="endTime"
                                            value={formik.values.endTime}
                                            onChange={formik.handleChange}
                                            type="datetime-local"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>

                                    {/* fila 3 */}
                                    <Grid item xs={12} sm={6}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox checked={fcImg} onChange={handleFcImgCheck} />} label="¿Registrar foto?" />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox checked={fpCheck} onChange={handleFpCheck} />} label="¿Registrar huella?" />
                                        </FormGroup>
                                    </Grid>

                                    {/* fila 4 */}
                                    <Grid item xs={12} sm={12}>
                                        <FormControl fullWidth hidden={!fpCheck}>
                                            <InputLabel id="fingerlb">Selección de dedo</InputLabel>
                                            <Select
                                                labelId="fingerlb"
                                                id="finger"
                                                label="Selección de dedo"
                                                fullWidth
                                                value={formik.values?.finger}
                                                onChange={(event) => {
                                                    formik.setFieldValue('finger', event.target.value)
                                                }}
                                                error={formik.errors.userType}
                                            >
                                                {Fingers.map((option) => (
                                                    <MenuItem key={option.key} value={option.value}>
                                                        {option.text}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    {/* fila 5 */}
                                    <Grid item xs={12} sm={6}>
                                        <Card>
                                            <CardContent>
                                                <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                    Número de huellas registradas en el dispositivo
                                                </Typography>
                                                <Typography variant="h5" component="div" style={{ textAlign: "right" }}>
                                                    {bioData && bioData.length > 0 ? bioData[0].numOfFP : 0}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Card>
                                            <CardContent>
                                                <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                                    Número de fotografías del rostro en el dispositivo
                                                </Typography>
                                                <Typography variant="h5" component="div" style={{ textAlign: "right" }}>
                                                    {bioData && bioData.length > 0 ? bioData[0].numOfFace : 0}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Button type="submit" variant="contained" className="btn btn-danger" fullWidth>Actualizar</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </>
                    }
                </>
            }
        </>
    )
}

function initialValues(data) {
    return {
        name: data?.name || "",
        userType: data?.userType || "",
        beginTime: data?.Valid?.beginTime || null,
        endTime: data?.Valid?.endTime || null,
        finger: 1,
    }
}

function newScheme() {
    return {
        name: Yup.string(),
        userType: Yup.string(),
        beginTime: Yup.date(),
        endTime: Yup.date(),
        finger: Yup.number(),
    }
}

function updateScheme() {
    return {
        name: Yup.string(),
        userType: Yup.string(),
        beginTime: Yup.date(),
        endTime: Yup.date(),
        finger: Yup.number(),
    }
}