import React, { useEffect, useState } from "react";
import { Tab } from 'semantic-ui-react'
// import Tab from "@mui/material/Tab"
// import Tabs from "@mui/material/Tabs"

import { TanquesAdmin, ProductoTanqueAdmin } from "../index"

export function GroupedTanques() {
    const panes = [
        {
            menuItem: { key: 'GruposPermisosAdmin', content: <>Tanques</> },
            render: () => <Tab.Pane><TanquesAdmin /></Tab.Pane>,
        },
        {
            menuItem: { key: 'ParametrizacionDecimalesComputador', content: <>Relación tanque-producto</> },
            render: () => <Tab.Pane><ProductoTanqueAdmin /></Tab.Pane>,
        },
    ]

    return (
        <>
            <Tab panes={panes} />
        </>
    )
}