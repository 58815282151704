import React, { useEffect } from "react";
import { HeaderPage } from "../../../Components/Client";
import { DataGridBasic, ModalBasic, DataGridBasicVeh } from "../../../Components/Comons";
import { useVehiculos } from "../../../hooks";
import { Funciones } from "./Funciones";
import { filterValue } from "./VehiculosFormat";
import { Tabs, Tab } from '@material-ui/core';
import { useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';

export function VehiculosAdmin() {
  const { loading, Vehiculos, getVehiculos, getVehTractoTanque, VehTractoTanque } = useVehiculos();
  const {
    refetch,
    contentModal,
    titleModal,
    showModal,
    columns,
    columnsToTractoTanque,
    handleAdd,
    handleDelete,
    handleUpdate,
    openCloseModal,
    documentosUpload,
    handleAddChecklist,
    handleViewChecklist,
    handleViewChecklistHistoric
  } = Funciones();

  const [activeTab, setActiveTab] = useState(0);

  const MemoizedModalBasic = React.memo(ModalBasic);

  const modifiedData = Vehiculos && Vehiculos.results ? Vehiculos.results.map((item) => ({
    ...item,
    conductor: item.conductor.nombre + " " + item.conductor.apellidos,
  })) : [];

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([getVehiculos(), getVehTractoTanque()]);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  return (
    <>
      <HeaderPage
        title="Vehiculos"
        btnTitle="Nuevo vehículo"
        icono="plus square icon"
        btnClick={handleAdd}
      />
      
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <DataGridBasicVeh
          data={modifiedData}
          title="Vehiculos"
          id="id_myemp"
          filterValue={filterValue}
          columns={columns}
          updateData={handleUpdate}
          onDeleteData={handleDelete}
          documentosUpload={documentosUpload}
        />
      )}

      {showModal && (
        <MemoizedModalBasic
          show={showModal}
          onClose={openCloseModal}
          title={titleModal}
          children={contentModal}
        />
      )}
      {/* <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label="Vehículos" />
        <Tab label="Inspección de vehículos" />
      </Tabs>

      {activeTab === 0 && (
        
      )}

      {activeTab === 1 && (
        <>
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <CircularProgress />
            </div>
          ) : (
            <>
              {VehTractoTanque ? (
                <DataGridBasic
                  data={VehTractoTanque}
                  title="Vehiculos tracto camiones y carro tanques"
                  id="id_veh_tracto_tanque"
                  filterValue={filterValue}
                  columns={columnsToTractoTanque}
                  vehChecklist={handleAddChecklist}
                  viewVehChecklist={handleViewChecklist}
                  viewVehChecklistHistory={handleViewChecklistHistoric}
                />
              ) : (
                <div>No hay vehículos con esas características</div>
              )}
            </>
          )}

          {showModal && (
            <MemoizedModalBasic
              show={showModal}
              onClose={openCloseModal}
              title={titleModal}
              children={contentModal}
            />
          )}
        </>
      )} */}
    </>
  )
}

