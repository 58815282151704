import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  top: 65px;
  right: 205px;
  z-index: 5;
`;

export const ContainerIcon = styled.div`
  width: 73px;
  display: flex;
`;

export const Barra = styled.div`
  width: 30px;
  height: 17px;
  background: ${({ theme }) => theme.primary.gradient};
  position: relative;
  left: 6px;
`;

export const TankMed = styled.div`
  position: relative;
  width: 32px;
  height: 44px;
  right: 31px;
  top: 12px;
  z-index: 6;
  border-radius: 2px;
  background: ${({ theme }) => theme.primary.gradient} !important;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: ${({ percent }) => (percent ? `${percent}%` : "0%")};
    background: ${({ theme }) => theme.primary.black};
  }
`;

export const SpanStyle = styled.span`
  position: relative;
  top: 0px;
  right: 52px;
  z-index: 20;
  color: ${({ theme }) => theme.primary.white};
  font-weight: 700;
  font-size: 10px;
`;
