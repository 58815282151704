export const columns = [
    
    {
      header: "Nombre asociado",
      name: "nombre",
      maxWidth: 1000, defaultFlex: 1
    },
    {
      header: "Numero de bahias",
      name: "N_bahias",
      maxWidth: 1000, defaultFlex: 1
    },
    {
      header: "Tipo de operacion",
      name: "tipo_operacion",
      maxWidth: 1000, defaultFlex: 1
    },
    
  
  ];

  export const filterValue = [
    { name: 'nombre', operator: 'startsWith', type: 'string', value: '' },
    { name: 'N_bahias', operator: 'startsWith', type: 'string', value: '' },
    { name: 'tipo_operacion', operator: 'startsWith', type: 'string', value: '' },
    
    
  ];