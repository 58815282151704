export const columns = [
  // {
  //   header: "ID",
  //   name: "id_transaccion",
  //   maxWidth: 1000, defaultFlex: 1
  // },
  {
    header: "Fecha creación",
    name: "time_created",
    maxWidth: 1000,
    defaultFlex: 1,
  },
  {
    header: "Numero de orden",
    name: "n_orden",
    maxWidth: 1000,
    defaultFlex: 1,
  },
  {
    header: "Volumen restante",
    name: "v_restante",
    maxWidth: 1000,
    defaultFlex: 1,
    render: (data) => {
      let numero = parseFloat(17029);
      return (
        <p className={data.v_restante < 0 ? "negative" : "positive"}>
          {numero.toFixed(2)}
        </p>
      );
    },
  },
  {
    header: "Volumen entregado",
    name: "v_entregado",
    maxWidth: 1000,
    defaultFlex: 1,
  },
  {
    header: "GSV",
    name: "vol_gsv",
    maxWidth: 1000,
    defaultFlex: 1,
  },
  {
    header: "GOV",
    name: "vol_gov",
    maxWidth: 1000,
    defaultFlex: 1,
  },
  {
    header: "Operador de bahía",
    name: "operador_bahia",
    maxWidth: 1000,
    defaultFlex: 1,
  },
  {
    header: "numero de bahía",
    name: "num_bahia",
    maxWidth: 1000,
    defaultFlex: 1,
  },
  {
    header: "Presión promedio",
    name: "pres_promedio",
    maxWidth: 1000,
    defaultFlex: 1,
  },
  {
    header: "Temperatura promedio",
    name: "temp_promedio",
    maxWidth: 1000,
    defaultFlex: 1,
  },
  {
    header: "Densidad promedio",
    name: "densidad_promedio",
    maxWidth: 1000,
    defaultFlex: 1,
  },
  {
    header: "MeterFactor",
    name: "meter_factor",
    maxWidth: 1000,
    defaultFlex: 1,
  },
  {
    header: "CTL",
    name: "ctl",
    maxWidth: 1000,
    defaultFlex: 1,
  },
  {
    header: "CPL",
    name: "cpl",
    maxWidth: 1000,
    defaultFlex: 1,
  },
  {
    header: "CTPL",
    name: "ctpl",
    maxWidth: 1000,
    defaultFlex: 1,
  },
  {
    header: "BSW",
    name: "bsw",
    maxWidth: 1000,
    defaultFlex: 1,
  },
  {
    header: "Gravedad API",
    name: "gravedad_api",
    maxWidth: 1000,
    defaultFlex: 1,
  },
  {
    header: "Numero de transaccion",
    name: "num_transaccion",
  },
  {
    header: "Totalizer inicio",
    name: "totalizer_init",
    maxWidth: 1000,
    defaultFlex: 1,
  },
  {
    header: "Totalizer final",
    name: "totalizer_end",
    maxWidth: 1000,
    defaultFlex: 1,
  },
  {
    header: "Fecha inicio de cargue",
    name: "fecha_initcargue",
    maxWidth: 1000,
    defaultFlex: 1,
  },
  {
    header: "Fecha de final del cargue",
    name: "fecha_endcargue",
    maxWidth: 1000,
    defaultFlex: 1,
  },
  {
    header: "NSV",
    name: "nsv",
    maxWidth: 1000,
    defaultFlex: 1,
  },
];

export const filterValue = [
  {
    name: "time_created",
    operator: "before",
    type: "date",
    value: "",
  },
  { name: "n_orden", operator: "startsWith", type: "string", value: "" },
  { name: "v_restante", operator: "startsWith", type: "string", value: "" },
  { name: "v_entregado", operator: "startsWith", type: "string", value: "" },
  { name: "vol_gsv", operator: "startsWith", type: "string", value: "" },
  { name: "vol_gov", operator: "startsWith", type: "string", value: "" },
  { name: "operador_bahia", operator: "startsWith", type: "string", value: "" },
  { name: "num_bahia", operator: "startsWith", type: "string", value: "" },
  { name: "pres_promedio", operator: "startsWith", type: "string", value: "" },
  { name: "temp_promedio", operator: "startsWith", type: "string", value: "" },
  {
    name: "densidad_promedio",
    operator: "startsWith",
    type: "string",
    value: "",
  },
  { name: "meter_factor", operator: "startsWith", type: "string", value: "" },
  { name: "ctl", operator: "startsWith", type: "string", value: "" },
  { name: "cpl", operator: "startsWith", type: "string", value: "" },
  { name: "bsw", operator: "startsWith", type: "string", value: "" },
  { name: "gravedad_api", operator: "startsWith", type: "string", value: "" },
  {
    name: "num_transaccion",
    operator: "startsWith",
    type: "string",
    value: "",
  },
  { name: "totalizer_init", operator: "startsWith", type: "string", value: "" },
  { name: "totalizer_end", operator: "startsWith", type: "string", value: "" },
  {
    name: "fecha_initcargue",
    operator: "before",
    type: "date",
    value: "",
  },
  {
    name: "fecha_endcargue",
    operator: "before",
    type: "date",
    value: "",
  },

  { name: "nsv", operator: "startsWith", type: "string", value: "" },
];
