import React, { useState } from "react";



import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormGroup from "@mui/material/FormGroup"
import Grid from "@mui/material/Grid"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

import Autocomplete from "@mui/material/Autocomplete";
import { DataGrid } from "@mui/x-data-grid";

import { GrayButton, GraySwitch } from "./styles";

export function TablaControl(props) {
  const {
    documentos,
    addDocumentos,
    updateDocumentos,
    deleteDocumentos,
    suggestions,
    onRefetch,
  } = props;

  const [modalOpen, setModalOpen] = useState(false);
  const [nombreDocumento, setNombreDocumento] = useState("");
  const [obligatorio, setObligatorio] = useState(false);
  const [restrictivo, setRestrictivo] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCreate = async () => {
    try {
      console.log(nombreDocumento)
      await addDocumentos({
        nombre_documento: nombreDocumento, // Utilizar el valor de nombreDocumento
        obligatorio,
        restrictivo,
      });
      setModalOpen(false);
      setNombreDocumento("");
      setObligatorio(false);
      setRestrictivo(false);
      onRefetch();
    } catch (error) {
      console.error("Error al crear el documento", error);
    }
  };

  const handleUpdateSwitch = async (id, value, field) => {
    try {
      await updateDocumentos(id, { [field]: value });
      onRefetch();
    } catch (error) {
      console.error("Error al actualizar el documento", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteDocumentos(id);
      onRefetch();
    } catch (error) {
      console.error("Error al eliminar el documento", error);
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70, flex: 1 },
    {
      field: "nombre_documento",
      headerName: "Nombre de Documento",
      width: 200,
      flex: 1,
    },
    {
      field: "obligatorio",
      headerName: "Obligatorio",
      width: 130,
      flex: 1,
      renderCell: (params) => (
        <GraySwitch
          checked={params.value}
          onChange={(e) =>
            handleUpdateSwitch(params.row.id, e.target.checked, "obligatorio")
          }
          color="primary"
        />
      ),
    },
    {
      field: "restrictivo",
      headerName: "Restrictivo",
      width: 130,
      flex: 1,
      renderCell: (params) => (
        <GraySwitch
          checked={params.value}
          onChange={(e) =>
            handleUpdateSwitch(params.row.id, e.target.checked, "restrictivo")
          }
        />
      ),
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 130,
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="error"
          size="small"
          onClick={() => handleDelete(params.row.id)}
        >
          Eliminar
        </Button>
      ),
    },
  ];

  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <Stack direction="row" spacing={2} marginTop={2}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <GrayButton fullWidth variant="contained" onClick={handleOpenModal}>
              Crear
            </GrayButton>
          </Grid>
        </Grid>
      </Stack>
      <br />
      {documentos && documentos.length > 0 ? (
        <DataGrid rows={documentos} columns={columns} pageSize={5} />
      ) : (
        <Typography variant="body1">No hay datos disponibles</Typography>
      )}

      <Dialog open={modalOpen} onClose={handleCloseModal}>
        <DialogTitle>Crear nuevo documento</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ width: "100%" }}>
            <Grid item sx={{ width: "100%" }}>
              <Autocomplete
                options={suggestions}
                value={nombreDocumento}
                onChange={(event, newValue) => {
                  setNombreDocumento(newValue || "")
                }}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Nombre de Documento"
                    fullWidth
                    margin="normal"
                    value={nombreDocumento}
                    onChange={(event) => setNombreDocumento(event.target.value)}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <FormGroup>
                <FormControlLabel
                  control={
                    <GraySwitch
                      checked={obligatorio}
                      onChange={(e) => setObligatorio(e.target.checked)}
                      color="warning"
                    />
                  }
                  label="Obligatorio"
                />
              </FormGroup>
            </Grid>
            <Grid item>
              <FormGroup>
                <FormControlLabel
                  control={
                    <GraySwitch
                      checked={restrictivo}
                      onChange={(e) => setRestrictivo(e.target.checked)}
                      color="warning"
                    />
                  }
                  label="Restrictivo"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancelar</Button>
          <Button onClick={handleCreate} variant="contained" color="primary">
            Crear
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
