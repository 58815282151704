export const columns = [
    {
        header: "Fecha de creación",
        name: "fecha_crea",
        maxWidth: 1000, defaultFlex: 1
    },
    {
        header: "Id del grupo",
        name: "id_ver_group_char",
        maxWidth: 1000, defaultFlex: 1
    },
    {
        header: "¿Es formulario activo?",
        name: "active_y_n",
        maxWidth: 1000, defaultFlex: 1
    }
]

export const filterValue = [
    { name: 'fecha_crea', operator: 'startsWith', type: 'string', value: '' },
    { name: 'id_ver_group_char', operator: 'startsWith', type: 'string', value: '' },
    { name: 'active_y_n', operator: 'startsWith', type: 'string', value: '' },
]