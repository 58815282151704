import React, { useEffect, useState } from "react";

import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions"
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer";
import Pagination from "@mui/material/Pagination"
import Paper from "@mui/material/Paper"
import Button from "@mui/material/Button"

import { HeaderPage } from "../../../Components/Client";
import { useControladores, useConsumo } from "../../../hooks";
import CircularProgress from '@mui/material/CircularProgress';
import { Funciones } from "./Funciones";
import tcs from "../../../assets/controladores/tcs-3000.png"
import { ASGI_API } from '../../../vistas/utils/constants'

export function SuministroStatus() {
    const { loading, CtrlRegCom, getControladorRegComb } = useControladores();
    const { extraData, getExtraData } = useConsumo();
    const [webSocketData, setWebSocketData] = useState({});

    const { refetch } = Funciones();

    const [selectedCtrl, setSelectedCtrl] = useState() // constante para almacenar el controlador de la página en la que se encuentr

    const [expanded, setExpanded] = useState({}); // constante que controla el estado cuando el cabezote de la tarjeta principal es pulsado

    const [currentPage, setCurrentPage] = useState(1); // establece la página actual
    const elementsPerPage = 1; // establece los elementos por página

    const indexOfLastElement = currentPage * elementsPerPage; // establece el índice del último elemento que se va a mostrar en el componente
    const indexOfFirstElement = indexOfLastElement - elementsPerPage; // establece el el índice del primer elemento que se va a mostrar
    const currentElements = CtrlRegCom?.slice(indexOfFirstElement, indexOfLastElement); // divide el arreglo de acuerdo a la cantidad de elementos posibles para visualizar

    // hace lo mismo que lo anterior, pero con el arreglo de los datos extras para mostrar los datos del la entrega (delivery_data)
    const [currentPage2, setCurrentPage2] = useState(1);
    const elementsPerPage2 = 1;

    const indexOfLastElement2 = currentPage2 * elementsPerPage2;
    const indexOfFirstElement2 = indexOfLastElement2 - elementsPerPage2;
    const currentElements2 = extraData?.filter(c => c.id_controlador == selectedCtrl?.id_controlador)?.slice(indexOfFirstElement2, indexOfLastElement2)

    // hace lo mismo que lo anterior, pero con el arreglo de los datos extras para mostrar los datos del inventario (invetory_data)
    const [currentPage3, setCurrentPage3] = useState(1);
    const elementsPerPage3 = 1;

    const indexOfLastElement3 = currentPage3 * elementsPerPage3;
    const indexOfFirstElement3 = indexOfLastElement3 - elementsPerPage3;
    const currentElements3 = extraData?.filter(c => c.id_controlador == selectedCtrl?.id_controlador)?.slice(indexOfFirstElement3, indexOfLastElement3)

    useEffect(() => {
        try {
            getControladorRegComb()
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [refetch])

    useEffect(() => {
        getExtraData()
    }, [])

    useEffect(() => {
        let ws = connectToWebSocket();

        return () => {
            ws.close() // Cierre la conexión WebSocket cuando el componente se desmonte o cambie
        }
    }, [CtrlRegCom]);

    // función para controlar el cambio de página del arreglo principal
    // establece la página actual
    // establece el item (controlador) de esa página
    const handleChangePage = (event, newPage, item) => {
        setCurrentPage(newPage);
        setSelectedCtrl(item)
    };

    //hace lo mismo para el arreglo con los datos extra (delivery_data)
    const handleChangePage2 = (event, newPage) => {
        setCurrentPage2(newPage);
    };

    // hace lo mismo para el arreglo con los datos extra (inventory_data)
    const handleChangePage3 = (event, newPage) => {
        setCurrentPage3(newPage);
    };

    // función que controla si se expande o se contrae las tablas cuando se le da click al cabezote de la tarjeta
    const handleExpandClick = (index, item) => {
        // cuando el componente carga por primera vez no hay ctrl seleccionado, así que cuando se le de click al cabezote de la tarjeta
        // la función establecerá el ctrl de dicha tarjeta para las tablas de los datos extras carguen correctamente
        // luego la función handleChangePage se encargará de actualizar el ctrl seleccionado
        if (selectedCtrl === undefined) {
            setSelectedCtrl(item)
        }

        // función para controlar si se expande/contrae la parte de las tablas cuando el cabezote de la tabla principal se clickeé
        // prevExpanded es un objeto de esta forma {0: false}, por eso se pasa el operador spread (...prevExpanded) para que se pase
        // como argumentos individuales de acuerdo al índice, es decir a la tabla seleccionada, y expandir o cerrar la zona de las tablas
        setExpanded(prevExpanded => ({
            ...prevExpanded,
            [index]: !prevExpanded[index],
        }));
    };

    const connectToWebSocket = () => {

        // url del web socket
        const ws = new WebSocket(`${ASGI_API}/data/suministro_status_data`);

        // abre la conexión
        ws.onopen = () => {
            console.log('WebSocket connected.');
        };

        // obtiene los datos provenientes de la petición del web socket
        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);

            setWebSocketData(prevData => ({ ...prevData, [data.id_controlador]: data }));
        };

        // cierra la conexión
        ws.onclose = () => {
            console.log('WebSocket disconnected. Intentando reconectar...');
            setTimeout(connectToWebSocket, 3000); // intenta reconexión después de 3 segundos
        };

        return ws
    }

    return (
        <>
            {loading ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                    </div>
                </>
            ) : (
                <>
                    <HeaderPage title="Estado de la operación de Suministro" />

                    <br />

                    <Grid container spacing={3}>
                        {currentElements?.map((c, index) => (
                            <>
                                <Grid item xs={12} sm={12} key={c.id_controlador}>
                                    <Card>
                                        <CardContent>
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid item xs={12} sm={4}>
                                                    <img src={tcs} alt={c.modelo} style={{ maxWidth: "100%", height: "auto" }} />
                                                </Grid>
                                                <Grid item xs={12} sm={8}>
                                                    <Card>
                                                        <CardHeader
                                                            onClick={() => handleExpandClick(index, c)}
                                                            title={c.modelo}
                                                            subheader={
                                                                webSocketData[c.id_controlador] ? // Check if WebSocket data exists for the current element
                                                                    <>
                                                                        Última conexión: {webSocketData[c.id_controlador].last_connection}<br />
                                                                        Última actualización: {webSocketData[c.id_controlador].last_update}
                                                                    </> :
                                                                    "No data available"
                                                            }
                                                            sx={{
                                                                backgroundColor: "#cd312c",
                                                                '& .MuiTypography-root': {
                                                                    color: 'white',
                                                                },
                                                                '&:hover': {
                                                                    cursor: 'pointer',
                                                                }
                                                            }}
                                                        />
                                                        <CardContent>
                                                            <Table>
                                                                <TableBody>
                                                                    <TableRow>
                                                                        <TableCell sx={{ backgroundColor: "#ccc6ba" }}><Typography variant="h6">Modelo</Typography></TableCell>
                                                                        <TableCell><Typography variant="h6">{c.modelo}</Typography></TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell sx={{ backgroundColor: "#ccc6ba" }}><Typography variant="h6">Marca</Typography></TableCell>
                                                                        <TableCell><Typography variant="h6">{c.marca}</Typography></TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell sx={{ backgroundColor: "#ccc6ba" }}><Typography variant="h6">Serial</Typography></TableCell>
                                                                        <TableCell><Typography variant="h6">{c.serial_equipo}</Typography></TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell sx={{ backgroundColor: "#ccc6ba" }}><Typography variant="h6">Vehículo relacionado</Typography></TableCell>
                                                                        <TableCell><Typography variant="h6">{c.vehicle.placa}</Typography></TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell sx={{ backgroundColor: "#ccc6ba" }}><Typography variant="h6">Coductor del vehículo</Typography></TableCell>
                                                                        <TableCell><Typography variant="h6">{c.vehicle.conductor.nombre + " " + c.vehicle.conductor.apellidos}</Typography></TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                {expanded[index] && (
                                    <>
                                        <Grid item xs={12} sm={12}>
                                            {currentElements2?.map((item, index2) => (item.id_controlador === c.id_controlador ? (
                                                <>
                                                    <Card key={index2}>
                                                        <CardHeader
                                                            title={item.delivery_data ? "Datos de la entrega" : "No hay datos"}
                                                            subheader={
                                                                item?.datetime_stamp ? "Fecha: " + item.datetime_stamp : "No hay datos"
                                                            }
                                                            sx={{
                                                                backgroundColor: "#cd312c",
                                                                '& .MuiTypography-root': {
                                                                    color: 'white',
                                                                },
                                                            }}
                                                        />
                                                        <CardContent>
                                                            <Grid item style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                                                <TableContainer component={Paper}>
                                                                    <Table>
                                                                        <TableBody>
                                                                            {Object.entries(item.delivery_data || {}).map(([key, value]) => (
                                                                                <TableRow key={key}>
                                                                                    <TableCell component="th" scope="row">
                                                                                        {key}
                                                                                    </TableCell>
                                                                                    <TableCell align="right">{value}</TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </Grid>
                                                        </CardContent>
                                                        <CardActions>
                                                            <Pagination
                                                                count={Math.ceil((extraData?.filter((e) => e.id_controlador === c.id_controlador)).length / elementsPerPage2)}
                                                                page={currentPage2}
                                                                onChange={handleChangePage2}
                                                                color="primary"
                                                                style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
                                                            />
                                                        </CardActions>
                                                    </Card>
                                                    <br></br>
                                                </>
                                            ) : (
                                                <></>
                                            )))}
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            {currentElements3?.map((item, index2) => (
                                                <>
                                                    <Card key={index2}>
                                                        <CardHeader
                                                            title={item.inventory_data ? "Datos del inventario" : "No hay datos"}
                                                            subheader={
                                                                item?.datetime_stamp ? "Fecha: " + item.datetime_stamp : "No hay datos"
                                                            }
                                                            sx={{
                                                                backgroundColor: "#cd312c",
                                                                '& .MuiTypography-root': {
                                                                    color: 'white',
                                                                },
                                                            }}
                                                        />
                                                        <CardContent>
                                                            <Grid item style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                                                <TableContainer component={Paper}>
                                                                    <Table>
                                                                        <TableBody>
                                                                            {Object.entries(item.inventory_data || {}).map(([key, value]) => (
                                                                                <TableRow key={key}>
                                                                                    <TableCell component="th" scope="row">
                                                                                        {key}
                                                                                    </TableCell>
                                                                                    <TableCell align="right">{value}</TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </Grid>
                                                        </CardContent>
                                                        <CardActions>
                                                            <Pagination
                                                                count={Math.ceil((extraData?.filter((e) => e.id_controlador === c.id_controlador)).length / elementsPerPage3)}
                                                                page={currentPage3}
                                                                onChange={handleChangePage3}
                                                                color="primary"
                                                                style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
                                                            />
                                                        </CardActions>
                                                    </Card>
                                                </>
                                            ))}
                                        </Grid>
                                    </>
                                )}
                            </>
                        ))}
                    </Grid>
                    <Pagination
                        count={Math.ceil(CtrlRegCom.length / elementsPerPage)}
                        page={currentPage}
                        onChange={(event, currentPage) => {
                            handleChangePage(event, currentPage, CtrlRegCom[currentPage - 1]);
                        }}
                        color="primary"
                        style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
                    />
                </>
            )}
        </>
    )
}