// TableAlert component detail information cargadero y descargero

import { Table, Button } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  dateNow,
  readAlarmsBay,
  readAlarmsComponent,
  readAlarmsMeter,
  readAlarmsPreset,
} from "../../utils/constants";
import { ContainerButtons, ContainerTable, Text } from "./styles";

export const TableAlert = ({ data }) => {
  const [dataTable, setDataTable] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const columns = useMemo(
    () => [
      {
        title: "Fecha y Hora",
        dataIndex: "fecha",
        key: "fecha",
        width: 140,
        render: (text) => (
          <Text margin={0} textAlign={"center"} fontSize={14}>
            {text}
          </Text>
        ),
      },
      {
        title: "Evento",
        dataIndex: "evento",
        key: "evento",
        render: (text) => (
          <Text margin={0} textAlign={"center"} fontSize={14}>
            {text}
          </Text>
        ),
      },
    ],[]
  );
 //
 const dataFind = useCallback(({ data, alarms, number }) => {
  const dataArray = [];
  const alarmKeys = Object.keys(data ?? {});
  
  alarmKeys.forEach((alarmKey, index) => {
    const alarmData = data[alarmKey];
    const activeAlarms = Object.entries(alarmData).filter(([, value]) => value === 1);
    
    activeAlarms.forEach(([alarmCode]) => {
      dataArray.push({
        id: `${alarmKey}-${alarmCode}-${Math.random()}`, // Unique ID combining alarm key and code
        key: `${alarmKey}-${alarmCode}-${index + 1}`, // Unique key for table row
        number: number + index,
        fecha: dateNow,
        evento: alarms[alarmCode] || "Unknown Alarm", // Use alarm description or default if not found
      });
    });
  });
  
  return dataArray;
}, []);



  

  useEffect(() => {
    const dataArray = [];

    const present = dataFind({
      data: data?.read_alarm_preset,
      alarms: readAlarmsPreset,
      number: 1,
    });
    

    const component = dataFind({
      data: data?.read_alarms_component,
      alarms: readAlarmsComponent,
      number: present.length,
    });

    const meter = dataFind({
      data: data?.read_alarms_meter,
      alarms: readAlarmsMeter,
      number: present.length + component.length,
    });

    const bay = dataFind({
      data: data?.read_alarms_bay,
      alarms: readAlarmsBay,
      number: present.length + component.length + meter.length,
    });

    dataArray.push(...present, ...component, ...meter, ...bay);

    setDataTable([...dataTable, ...dataArray]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dataFind]);

  const handleDeleteSelected = useCallback(() => {
    const newData = dataTable.filter(
      (item) => !selectedRowKeys.includes(item.key)
    );
    setDataTable(newData);
    setSelectedRowKeys([]);
  }, [dataTable, selectedRowKeys]);

  const handleDeleteAll = useCallback(() => {
    setDataTable([]);
  }, []);

  return (
    <ContainerTable>
      <Table
        rowSelection={{
          selectedRowKeys,
          onChange: (selectedRowKeys) => {
            setSelectedRowKeys(selectedRowKeys);
          },
        }}
        columns={columns}
        dataSource={dataTable}
        pagination={false}
        scroll={{
          y: 210,
        }}
        bordered
      />
      <ContainerButtons>
        <Button onClick={handleDeleteSelected}>ACK Selección</Button>
        <Button onClick={handleDeleteAll}>ACK Todas</Button>
      </ContainerButtons>
    </ContainerTable>
  );
};
