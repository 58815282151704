import React, { useEffect, useState, useCallback } from "react";
import Swal from "sweetalert2"
import { AddEditOrdenesSuministros } from "../../../Components/Client"
import { useConsumo } from "../../../hooks";

export function Funciones() {
    const [showModal, setShowModal] = useState(false);
    const [titleModal, setTitleModal] = useState(null);
    const [contentModal, setContentModal] = useState(null);
    const [refetch, setRefetch] = useState(false);

    const { deleteOrdenesSuministro } = useConsumo()

    const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
    const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);

    const handleAdd = useCallback(() => {
        setTitleModal("Nueva orden de suministro");
        setContentModal(
            <AddEditOrdenesSuministros
                onClose={openCloseModal}
                onRefetch={onRefetch}
            />
        );
        openCloseModal();
    }, [openCloseModal, onRefetch]);

    const handleUpdate = useCallback((data) => {
        setTitleModal("Actualizar orden de suministro");
        setContentModal(
            <AddEditOrdenesSuministros
                onClose={openCloseModal}
                onRefetch={onRefetch}
                ordenesSuministro={data}
            />
        );
        openCloseModal();
    }, [openCloseModal, onRefetch]);

    const handleDelete = useCallback(async (data) => {
        const result = await Swal.fire({
            title: "¿Estás seguro?",
            text: "No podrás revertir esta acción!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "No, cancelar!",
            reverseButtons: true,
        });

        if (result.isConfirmed) {
            await deleteOrdenesSuministro(data.id_orden);
            Swal.fire("Eliminado!", "El dato ha sido eliminado.", "success");
            onRefetch();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Cancelado", "Operación cancelada", "error");
        }
    }, [deleteOrdenesSuministro, onRefetch]);

    return {
        refetch,
        contentModal,
        titleModal,
        showModal,
        openCloseModal,
        handleAdd,
        handleUpdate,
        handleDelete
    }
}