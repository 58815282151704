import { useFormik } from "formik";
import React, { useEffect, useState } from "react";

import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Select,
  TextArea,
} from "semantic-ui-react";
import Swal from "sweetalert2";
import * as Yup from "yup";
import {
  useCliente,
  useConductor,
  useMezclas,
  useOrdenes,
  usePlantas,
  useProductos,
  useRemolques,
  useTanques,
  useTransportadora,
  useVehiculos,
} from "../../../hooks";

import {
  TransportadoraSelect,
  VehiculosSelect,
  clienteSelect,
  condNombApeSelect,
  mezclaCodigoSelect,
  productoCodigoSelect,
  remolqueSelect,
  tanqueSelect,
} from "../../../Components/Comons";

import { alertas } from "../../../Components/Comons/Alertas/alertas";

export function AddEdit(props) {
  /*
  Componente

  -componente de visualizacion del modal de edicion y creacion para Ordenes
  
  -se crea y valida mediante formik y yup
  -Se obtienen datos para dropdown mediante hooks
  -se obtiene los datos (para edicion) y funciones correspondientes mediante props desde su PageAdmin
   */
  const { Successful } = alertas();
  const { onClose, onRefetch, Ordenes } = props;
  const { addOrdenes, updateOrdenes } = useOrdenes();
  const [tablasFormatC, setTablasTFormatC] = useState([]);
  const [tablasFormatT, setTablasTFormatT] = useState([]);
  const [tablasFormatV, setTablasTFormatV] = useState([]);
  const [tablasFormatCDR, setTablasTFormatCDR] = useState([]);
  const [tablasFormatR, setTablasTFormatR] = useState([]);
  const [tablasFormatP, setTablasTFormatP] = useState([]);
  const [tablasFormatMezc, setTablasTFormatMezc] = useState([]);
  const [datosTanques, setDatosTanques] = useState([]);
  const { tanquesDB, getTanquesDB } = useTanques();
  const { ClienteDB, getClienteDB } = useCliente();
  const { TransportadoraDB, getTransportadoraDB } = useTransportadora();
  const { VehiculosDB, getVehiculosDB } = useVehiculos();
  const { ConductorDB, getConductorDB } = useConductor();
  const { RemolquesDB, getRemolquesDB } = useRemolques();
  const { ProductoDB, getProductoDB } = useProductos();
  const { MezclasDB, getMezclasDB } = useMezclas();
  const [capacidad, setCapacidad] = useState(1000000000000);

  const { PlantasDB, getPlantasDB } = usePlantas();
  const [tablasFormatPl, setTablasTFormatPl] = useState([]);
  const [clientesID, setClientesID] = useState([]);

  useEffect(() => {
    getClienteDB();
    getTransportadoraDB(true, Ordenes?.id_transportadora);
    getVehiculosDB(true, Ordenes?.id_vehiculo);
    getConductorDB(true, Ordenes?.id_conductor);
    getRemolquesDB();
    getProductoDB();
    getTanquesDB();
    getMezclasDB();
    getPlantasDB();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTablasTFormatC(clienteSelect(ClienteDB));
    setTablasTFormatT(TransportadoraSelect(TransportadoraDB));
    setTablasTFormatV(VehiculosSelect(VehiculosDB));
    setTablasTFormatCDR(condNombApeSelect(ConductorDB));
    setTablasTFormatR(remolqueSelect(RemolquesDB));
    setTablasTFormatP(productoCodigoSelect(ProductoDB));
    setDatosTanques(tanqueSelect(tanquesDB));
    setTablasTFormatMezc(mezclaCodigoSelect(MezclasDB));
  }, [
    ClienteDB,
    TransportadoraDB,
    VehiculosDB,
    ConductorDB,
    RemolquesDB,
    ProductoDB,
    tanquesDB,
    MezclasDB,
  ]);
  useEffect(() => {
    setTablasTFormatPl(formatDropdownDataPl(PlantasDB, clientesID, Ordenes));
  }, [PlantasDB, clientesID, Ordenes]);

  const formik = useFormik({
    // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
    initialValues: initialValues(Ordenes),
    // Establece la validación del esquema utilizando Yup, si se proporciona un objeto  se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
    validationSchema: Yup.object(Ordenes ? updateSchame() : newSchame()),
    // Desactiva la validación al cambiar los valores del formulario
    validateOnChange: false,
    onSubmit: async (formValue) => {
      formValue.api = parseInt(api.replace(/[.,]/g, ""), 10);
      formValue.bsw = parseInt(bsw.replace(/[.,]/g, ""), 10);
      formValue.producto_nombre = n_producto;

      try {
        // Si se proporciona un objeto se actualiza, de lo contrario se crea uno nuevo
        if (Ordenes) {
          if (formValue.v_volumen !== Ordenes.v_volumen) {
            formValue.v_volumen = formValue.v_volumen * 100;
            formValue.v_restante = formValue.v_volumen;
          }
          await updateOrdenes(Ordenes.id_orden, formValue);
        } else {
          formValue.v_volumen = formValue.v_volumen * 100;
          formValue.v_restante = formValue.v_volumen;
          await addOrdenes(formValue);
        }

        Successful();
        onRefetch();
        onClose();
      } catch (error) {
        // Muestra un mensaje de error si ocurre algún problema
        if (error?.message) {
          if (formValue.v_volumen !== initialValues(Ordenes).v_volumen) {
            formValue.v_volumen = formValue.v_volumen / 100;
          }
          Swal.fire({
            icon: "warning",
            title: "Advertencia",
            text: error.message,
          });
        } else {
          onClose();
        }
      }
    },
  });

  let api = "";
  let bsw = "";
  let n_producto = "";
  let remolques = "";
  let clientes = "";

  if (formik?.values && tablasFormatP.length !== 0) {
    const apiV = tablasFormatP.find(
      (t) => t.extra.codigo_producto === formik?.values.v_producto
    );

    if (apiV && apiV.extra.api_bsw.length > 0) {
      n_producto = apiV.extra.nombre;
      const lastEntry = apiV.extra.api_bsw[apiV.extra.api_bsw.length - 1];
      api = lastEntry?.api;
      bsw = lastEntry?.bsw;

      // Utiliza los valores de api y bsw como necesites
    }
  }

  //PARA REMOLQUES
  if (formik?.values && tablasFormatR.length != 0) {
    const remolque = tablasFormatR.find(
      (t) => t.extra.id_remolque === formik?.values.id_remolque
    );
    remolques = remolque?.extra.compartimientos;
  }

  const divStyle = {
    textAlign: "left",
  };
  const checkStyle = {
    textAlign: "left",
    fontWeight: "bold",
    fontSize: "medium",
  };

  useEffect(() => {
    if (Ordenes) {
      const cliente = tablasFormatC.find(
        (t) => t.extra.id_cliente === parseInt(Ordenes.id_cliente)
      );
      const plantas = cliente?.extra.plantas || [];
      setClientesID(plantas);
      formik.setFieldValue("id_cliente", parseInt(Ordenes.id_cliente));
      formik.setFieldValue("planta", ""); // Limpiar el valor de la planta seleccionada
    }
  }, [Ordenes, tablasFormatC]);
  const handleClienteChange = (_, data) => {
    const clienteID = data.value;
    const cliente = tablasFormatC.find((t) => t.extra.id_cliente === clienteID);
    const plantas = cliente?.extra.plantas || [];
    setClientesID(plantas);
    formik.setFieldValue("id_cliente", clienteID);
    formik.setFieldValue("planta", ""); // Limpiar el valor de la planta seleccionada
  };

  return (
    <Form
      className="add-edit-secciones-form"
      style={divStyle}
      onSubmit={formik.handleSubmit}
    >
      <Form.Group widths="equal">
        <Form.Field
          control={Input}
          label="Factura o remision"
          name="n_factura"
          placeholder="Factura o remision"
          value={formik.values.n_factura}
          onChange={formik.handleChange}
          error={formik.errors.n_factura}
        />

        <Form.Field
          required
          disabled
          control={Select}
          label="Estado de orden"
          name="estado_orden"
          placeholder="Selecciona"
          fluid
          selection
          search
          options={Estado}
          value={formik.values.estado_orden}
          error={formik.errors.estado_orden}
          onChange={(_, data) =>
            formik.setFieldValue("estado_orden", data.value)
          }
        />
        <Form.Field
          fluid
          control={Input}
          label="Numero GUT"
          name="nmro_gut"
          placeholder="Numero de GUT"
          value={formik.values.nmro_gut}
          error={formik.errors.nmro_gut}
          onChange={formik.handleChange}
        />

        <Form.Field
          required
          disabled
          control={Select}
          label="Tanque"
          name="tanque"
          placeholder="Selecciona"
          fluid
          selection
          search
          options={datosTanques}
          value={formik.values.tanque}
          error={formik.errors.tanque}
          onChange={(_, data) => formik.setFieldValue("tanque", data.value)}
        />
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Field
          required
          disabled
          control={Select}
          label="Producto"
          placeholder="Producto"
          fluid
          selection
          search
          options={tablasFormatP}
          value={formik.values.v_producto}
          error={formik.errors.v_producto}
          onChange={(_, data) => formik.setFieldValue("v_producto", data.value)}
        />

        {api ? (
          <Form.Field
            // style={campoStyle}
            readOnly
            control={Input}
            fluid
            name="api"
            label="API"
            placeholder="API"
            value={api}
          />
        ) : (
          ""
        )}

        {bsw ? (
          <Form.Field
            // style={campoStyle}
            readOnly
            control={Input}
            fluid
            name="bsw"
            label="BSW"
            placeholder="BSW"
            value={bsw}
          />
        ) : (
          ""
        )}
        <Form.Field
          required
          disabled
          fluid
          control={Input}
          label="Volumen (gal)"
          name="v_volumen"
          placeholder="Volumen (gal)"
          value={formik.values.v_volumen}
          onChange={formik.handleChange}
          error={formik.errors.v_volumen}
          type="number"
        />
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Field
          disabled
          required
          control={Select}
          label="Cliente asociado"
          placeholder="Selecciona"
          fluid
          selection
          search
          options={tablasFormatC}
          value={formik.values.id_cliente}
          error={formik.errors.id_cliente}
          onChange={handleClienteChange}
        />

        <Form.Field
          disabled
          required
          control={Select}
          label="Planta"
          placeholder="Selecciona"
          fluid
          selection
          search
          options={tablasFormatPl}
          value={formik.values.id_destino}
          error={formik.errors.id_destino}
          onChange={(_, data) => formik.setFieldValue("id_destino", data.value)}
        />

        <Form.Field
          required
          disabled
          control={Select}
          label="Transportadora asociada"
          placeholder="Selecciona"
          fluid
          selection
          search
          options={tablasFormatT}
          value={formik.values.id_transportadora}
          error={formik.errors.id_transportadora}
          onChange={(_, data) =>
            formik.setFieldValue("id_transportadora", data.value)
          }
        />

        <Form.Field
          required
          disabled
          control={Select}
          label="Vehículo asociado"
          placeholder="Selecciona"
          fluid
          selection
          search
          options={tablasFormatV}
          value={formik.values.id_vehiculo}
          error={formik.errors.id_vehiculo}
          onChange={(_, data) =>
            formik.setFieldValue("id_vehiculo", data.value)
          }
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          required
          disabled
          control={Select}
          label="Conductor asociado"
          placeholder="Selecciona"
          fluid
          selection
          search
          options={tablasFormatCDR}
          value={formik.values.id_conductor}
          error={formik.errors.id_conductor}
          onChange={(_, data) =>
            formik.setFieldValue("id_conductor", data.value)
          }
        />
        <Form.Field
          required
          disabled
          control={Select}
          label="Remolque asociado"
          placeholder="Selecciona"
          fluid
          selection
          search
          options={tablasFormatR}
          value={formik.values.id_remolque}
          error={formik.errors.id_remolque}
          onChange={(_, data) =>
            formik.setFieldValue("id_remolque", data.value)
          }
        />

        {remolques && remolques.length > 0 && (
          <Form.Select
            disabled
            label="Selecciona un compartimiento"
            options={remolques.map((c) => ({
              key: c.producto,
              text: `${c.producto} - ${c.capacidad} (bbl)`,
              value: c.capacidad,
            }))}
            placeholder="Selecciona un compartimiento"
            // value={formik?.values?.id_compartimiento}
            onChange={(e, { value }) => setCapacidad(value)}
          />
        )}
      </Form.Group>
      
      <Form.Field
        disabled
        control={TextArea}
        label="Observaciones de la orden"
        name="observacion_orden"
        placeholder="Observación de la orden"
        value={formik.values.observacion_orden}
        onChange={formik.handleChange}
        error={formik.errors.observacion_orden}
      />

      <Form.Group widths="equal">
        <Divider />

        <Form.Field
          disabled
          control={Checkbox}
          label={
            <label style={checkStyle}>
              <strong>Autorizar la orden</strong>
            </label>
          }
          name="v_auth"
          checked={formik.values.v_auth === 1}
          onChange={(_, data) =>
            formik.setFieldValue("v_auth", data.checked ? 1 : 0)
          }
          error={formik.errors.v_auth}
        />

        <Form.Field
          disabled
          required
          fluid
          control={Input}
          label="Fecha de vencimiento de la orden"
          name="tiempo_auth"
          placeholder="vencimiento"
          value={formik.values.tiempo_auth}
          onChange={formik.handleChange}
          error={formik.errors.tiempo_auth}
          type="datetime-local"
        />
      </Form.Group>

      <Form.Field
        control={Input}
        label="Sellos"
        name="sellos"
        placeholder="Sellos"
        value={formik.values.sellos}
        onChange={formik.handleChange}
        error={formik.errors.sellos}
      />
      <Button
        type="submit"
        // primary
        className="btn btn-danger"
        fluid
        content={Ordenes ? "Actualizar" : "Crear"}
      />
    </Form>
  );
}

function formatDropdownDataPl(data, clientesID, Ordenes) {
  console.log("data", data);
  console.log("cliente", clientesID);
  if (!data || !clientesID) {
    return [];
  }

  let filteredData = data.filter((item) => clientesID.includes(item.id_myemp));

  if (Ordenes && Ordenes.id_cliente) {
    const plantaSeleccionada = data.find(
      (item) => item.id_myemp === parseInt(Ordenes.id_destino)
    );
    if (plantaSeleccionada && !filteredData.includes(plantaSeleccionada)) {
      filteredData.push(plantaSeleccionada);
    }
  }

  return filteredData.map((item) => ({
    key: item.id_myemp,
    text: item.razon_social,
    value: item.id_myemp,
    extra: item,
  }));
}

const Estado = [
  {
    key: "Abierta",
    text: "Abierta",
    value: "Abierta",
  },
];

function initialValues(data) {
  return {
    v_auth: data?.v_auth ? 1 : 0,
    v_producto: data?.v_producto || "",
    v_volumen: data?.v_restante / 100 || "",
    v_restante: data?.v_restante || "",
    estado_orden: data?.estado_orden || "",
    observacion_orden: data?.observacion_orden || "",
    tanque: parseInt(data?.tanque) || "",
    id_transportadora: parseInt(data?.id_transportadora) || "",
    id_vehiculo: parseInt(data?.id_vehiculo) || "",
    id_conductor: parseInt(data?.id_conductor) || "",
    // id_destino: data?.id_destino || "",
    api: data?.api || "",
    bsw: data?.bsw || "",
    id_cliente: data && data.id_cliente ? parseInt(data.id_cliente) : "",
    id_destino: parseInt(data?.id_destino) || "",
    producto_nombre: data?.producto_nombre || "",
    tiempo_auth: data?.tiempo_auth || "",
    // id_origen: parseInt(data?.id_origen) || "",
    id_remolque: parseInt(data?.id_remolque) || "",
    sellos: data?.sellos || "",
    n_factura: data?.n_factura || "",
    nmro_gut: data?.nmro_gut || "",
  };
}

function newSchame() {
  return {
    v_auth: Yup.bool().required(true),
    v_producto: Yup.number().required(true),
    v_volumen: Yup.number().required(true),
    v_restante: Yup.number(),
    estado_orden: Yup.string().required(true),
    observacion_orden: Yup.string(),
    tanque: Yup.number().required(true),
    id_cliente: Yup.number().required(true),
    id_transportadora: Yup.number().required(true),
    id_vehiculo: Yup.string().required(true),
    id_conductor: Yup.number().required(true),
    id_destino: Yup.number().required(true),
    // id_origen: Yup.number().required(true),
    id_remolque: Yup.string().required(true),
    sellos: Yup.string(),
    n_factura: Yup.string(),
  };
}

function updateSchame() {
  return {
    v_auth: Yup.bool().required(true),
    v_producto: Yup.number().required(true),
    v_volumen: Yup.number().required(true),
    v_restante: Yup.number(),
    estado_orden: Yup.string().required(true),
    observacion_orden: Yup.string(),
    tanque: Yup.number().required(true),
    id_cliente: Yup.number().required(true),
    id_transportadora: Yup.number().required(true),
    id_vehiculo: Yup.string().required(true),
    id_conductor: Yup.number().required(true),
    id_destino: Yup.number().required(true),
    // id_origen: Yup.number().required(true),
    id_remolque: Yup.string().required(true),
    sellos: Yup.string(),
  };
}
