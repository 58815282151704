
import { BASE_API } from "../../utils/constants";
import { makeRequest,makeRequestFormData } from "../Request";
const BASE_URL = `${BASE_API}/`;
  
  // Función para obtener los datos 
  export async function  getDocumentosVehiculosApi(token, schema, id) {
    
    let url = `${BASE_URL}${schema}/vehiculos/documentos`;
    if (id) {
      
      url += `?vehiculo=${id}`;
      
    }
    
    return makeRequest(url, "GET", token);
  }
  
  // Función para agregar un nuevo dato
  export async function addDocumentosVehiculosApi(data, token,schema) {
    const formData = new FormData();
  
    formData.append("archivo", data.archivo);
    formData.append("numero_documento", data.numero_documento);
    formData.append("fecha_vencimiento", data.fecha_vencimiento);
  

    const url = `${BASE_URL}${schema}/vehiculos/documentos/`;
    return makeRequestFormData(url, "POST", token,  formData);
  }
  
  // Función para actualizar los datos 
  export async function updateDocumentosApi(id, data, token,schema) {
    const formData = new FormData();
   
    if (data.archivo) {
      formData.append("archivo", data.archivo);
    }
    formData.append("numero_documento", data.numero_documento);
    formData.append("fecha_vencimiento", data.fecha_vencimiento);
   

    const url = `${BASE_URL}${schema}/vehiculos/documentos/${id}/`;
    return makeRequestFormData(url, "PATCH", token, formData);
  }
  
  // Función para eliminar 
  export async function deleteDocumentosVehiculosApi(id, token,schema) {
    const url = `${BASE_URL}${schema}/vehiculos/documentos/${id}/`;
    return makeRequest(url, "DELETE", token);
  }