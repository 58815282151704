// Description: Componente que muestra la imagen del tanque y el nivel de llenado

import React, { useCallback, useMemo } from "react";
import { Tank } from "../../../../components/icons/tank";
import {
  Container,
  ContainerFooter,
  ContainerImageTank,
  ContainerOval,
  ContainerOvalText,
  ContainerTank,
  Oval,
  Percent,
} from "./styles";
import { theme } from "../../../../theme";

export const ImageTank = ({
  data,
  heightTank,
  widthOval,
  heightOval,
  footer,
  leftContainerOval,
  bottomContainerOval,
  rightPercent,
  bottomPercent,
  fontSizePercent,
  detail,
}) => {
  const alarmNivelMajor = useCallback(
    (alamr) => {
      if (data.nivel_prod >= alamr) {
        return true;
      }
      return false;
    },
    [data]
  );
  const alarmNivelMinor = useCallback(
    (alamr) => {
      if (data.nivel_prod <= alamr) {
        return true;
      }
      return false;
    },
    [data]
  );

  const niivelProd = useMemo(() => Number(data?.nivel_prod).toFixed(), [data]);

  return (
    <div>
      <ContainerImageTank height={heightTank}>
        <Container
          leftContainerOval={leftContainerOval}
          bottomContainerOval={bottomContainerOval}
        >
          <ContainerOvalText>
            <ContainerOval detail={detail}>
              <Oval
                width={widthOval}
                height={heightOval}
                bg={
                  alarmNivelMajor(data?.alarmas?.p_nivel_aa) &&
                  theme.default.primary.red
                }
              ></Oval>
              <p>HH</p>
            </ContainerOval>
            <ContainerOval detail={detail}>
              <Oval
                width={widthOval}
                height={heightOval}
                bg={
                  alarmNivelMajor(data?.alarmas?.p_nivel_a) &&
                  theme.default.primary.yellow
                }
              ></Oval>
              <p>HIGH</p>
            </ContainerOval>
          </ContainerOvalText>
          <ContainerOvalText style={{ marginTop: "12px" }}>
            <ContainerOvalText>
              <ContainerOval detail={detail}>
                <Oval
                  width={widthOval}
                  height={heightOval}
                  bg={
                    alarmNivelMinor(data?.alarmas?.p_nivel_b) &&
                    theme.default.primary.yellow
                  }
                ></Oval>
                <p>LOW</p>
              </ContainerOval>
              <ContainerOval detail={detail}>
                <Oval
                  width={widthOval}
                  height={heightOval}
                  bg={
                    alarmNivelMinor(data?.alarmas?.p_nivel_bb) &&
                    theme.default.primary.red
                  }
                ></Oval>
                <p>LL</p>
              </ContainerOval>
            </ContainerOvalText>
          </ContainerOvalText>
        </Container>
        <Tank />
        <Percent
          rightPercent={rightPercent}
          bottomPercent={bottomPercent}
          fontSizePercent={fontSizePercent}
        >{`${niivelProd !== "NaN" ? niivelProd : 0}%`}</Percent>
        <ContainerTank percent={niivelProd} detail={detail}></ContainerTank>
      </ContainerImageTank>
      {footer && (
        <ContainerFooter>
          <div>
            <p>Activo</p>
            <Oval
              bg={
                data?.activo
                  ? theme.default.primary.green
                  : theme.default.primary.gray7
              }
            ></Oval>
          </div>
          <div>
            <p>Recibo</p>
            <Oval
              bg={
                data?.recibo
                  ? theme.default.primary.green
                  : theme.default.primary.gray7
              }
            ></Oval>
          </div>
          <div>
            <p>Despacho</p>
            <Oval
              bg={
                data?.despacho
                  ? theme.default.primary.green
                  : theme.default.primary.gray7
              }
            ></Oval>
          </div>
        </ContainerFooter>
      )}
    </div>
  );
};
