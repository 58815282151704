import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { Autocomplete } from "@mui/material"
import TextField from "@mui/material/TextField"
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import Typography from '@mui/material/Typography';
import Paper from "@mui/material/Paper"
import Container from "@mui/material/Container"

import * as Yup from "yup";
import { useControladores, usePlantas } from "../../../hooks"
import { plantaSelect } from "../../Comons";
import { alertas } from "../../Comons/Alertas/alertas"

export function AddEditOtrosDisp(props) {
    const { Successful } = alertas();
    const { onClose, onRefetch, Controladores } = props;
    const { addControladores, updateControladores } = useControladores();
    const { PlantasDB, getPlantasDB } = usePlantas();

    const [tablasFormatP, setTablasTFormatP] = useState([]);

    const [error, setError] = React.useState(false);
    const [error1, setError1] = React.useState(false);
    const [error2, setError2] = React.useState(false);

    useEffect(() => {
        getPlantasDB()
    }, [])

    useEffect(() => {
        setTablasTFormatP(plantaSelect(PlantasDB));
    }, [PlantasDB]);

    const tipoOptions = [
        { key: 'cam', value: 'cam', text: 'Cámara' },
        { key: 'bio_cam', value: 'bio_cam', text: 'Biométrico' }
    ]

    const tipoProceso = [
        { key: "1", value: "1", text: 'Entrada' },
        { key: "2", value: "2", text: 'Salida' },
    ]

    const formik = useFormik({
        // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
        initialValues: initialValues(Controladores),
        // Establece la validación del esquema utilizando Yup, si se proporciona un objeto GruposPermisos se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
        validationSchema: Yup.object(Controladores ? updateSchame() : newSchame()),
        // Desactiva la validación al cambiar los valores del formulario
        validateOnChange: false,
        onSubmit: async (formValue) => {
            try {

                formValue.used_for_access = true
                formValue.ubicacion_id.bahias_id = null

                // Si se proporciona un objeto Controladores se actualiza, de lo contrario se crea uno nuevo
                if (Controladores)
                  await updateControladores(Controladores.id_controlador, formValue);
                else await addControladores(formValue);

                // Llama a una función Successful si se completa correctamente
                Successful();
                // Llama a la función onRefetch para actualizar la lista de GruposPermisos
                onRefetch();
                // Llama a la función onClose para cerrar el formulario
                onClose();
            } catch (error) {
                if (error?.message) {
                    // Muestra un mensaje de error si ocurre algún problema
                    toast.error(error.message, {
                        position: "top-center",
                    });
                } else {
                    onClose();
                }
            }
        },
    });

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>
                    {/* fila 1 */}
                    <Grid item xs={12} sm={4}>
                        <Autocomplete
                            id="tipo"
                            options={tipoOptions}
                            getOptionLabel={(option) => option.text || ''}
                            value={tipoOptions.find((option) => option.value === formik.values.tipo) || null}
                            onChange={(_, value) => {
                                formik.setFieldValue('tipo', value?.value || '');
                                setError(!value || !value.value); // revisa si la selección está vacía y establece el error de acuerdo a eso
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Tipo de dispositivo"
                                    variant="outlined"
                                    error={error}
                                    helperText={error ? 'La selección es necesaria' : ''}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Autocomplete
                            id="proceso"
                            options={tipoProceso}
                            getOptionLabel={(option) => option.text || ''}
                            value={tipoProceso.find((option) => option.value === formik.values.proceso) || null}
                            onChange={(_, value) => {
                                formik.setFieldValue('proceso', value?.value || '');
                                setError2(!value || !value.value); // revisa si la selección está vacía y establece el error de acuerdo a eso
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Proceso"
                                    variant="outlined"
                                    error={error2}
                                    helperText={error2 ? 'La selección es necesaria' : ''}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField 
                            required
                            fullWidth
                            label="Portería del dispositivo"
                            name="porteria"
                            value={formik.values.porteria}
                            onChange={formik.handleChange}
                            error={formik.errors.porteria}
                        /> 
                    </Grid>

                    {/* fila 2 */}
                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            label="Modelo"
                            name="modelo"
                            value={formik.values.modelo}
                            onChange={formik.handleChange}
                            error={formik.errors.modelo}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            label="Marca"
                            name="marca"
                            value={formik.values.marca}
                            onChange={formik.handleChange}
                            error={formik.errors.marca}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            label="Ip"
                            name="ip"
                            value={formik.values.ip}
                            onChange={formik.handleChange}
                            error={formik.errors.ip}
                        />
                    </Grid>

                    {/* fila 3 */}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Serial equipo"
                            name="serial_equipo"
                            value={formik.values.serial_equipo}
                            onChange={formik.handleChange}
                            error={formik.errors.serial_equipo}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Serial SYZ"
                            name="serial_syz"
                            value={formik.values.serial_syz}
                            onChange={formik.handleChange}
                            error={formik.errors.serial_syz}
                        />
                    </Grid>


                    {/* fila 3 */}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            label="Usuario"
                            name="usuario"
                            value={formik.values.usuario}
                            onChange={formik.handleChange}
                            error={formik.errors.usuario}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            label="Contraseña"
                            name="contraseña"
                            value={formik.values.contraseña}
                            onChange={formik.handleChange}
                            error={formik.errors.contraseña}
                        />
                    </Grid>

                    {/* fila 4 */}
                    <Grid item xs={12} sm={12}>
                        <Typography variant="h6">Ubicacion controlador</Typography>

                        <Container maxWidth="lg">
                            <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            label="Tag"
                                            name="ubicacion_id.tag"
                                            value={formik.values.ubicacion_id.tag}
                                            onChange={formik.handleChange}
                                            error={formik.errors.ubicacion_id?.tag}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Autocomplete
                                            id="planta"
                                            options={tablasFormatP}
                                            getOptionLabel={(option) => option.text || ''}
                                            value={tablasFormatP.find((option) => option.value === formik.values.ubicacion_id.planta_id) || null}
                                            onChange={(_, value) => {
                                                formik.setFieldValue("ubicacion_id.planta_id", value?.value || "");
                                                setError1(!value || !value.value); // Check if selection is empty and set error accordingly
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    required
                                                    label="Planta"
                                                    variant="outlined"
                                                    error={error1}
                                                    helperText={error1 ? 'Es necesario una selección' : ''}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Observaciones"
                                            name="ubicacion_id.observaciones"
                                            value={formik.values.ubicacion_id.observaciones}
                                            onChange={formik.handleChange}
                                            error={formik.errors.ubicacion_id?.observaciones}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Container>
                    </Grid>

                    {/* fila  */}
                    <Grid item xs={12} sm={12}>
                        <Button type="submit" className="btn btn-danger" variant="contained" color="error" fullWidth>{Controladores ? "Actualizar" : "Crear"}</Button>
                    </Grid>
                </Grid>
            </form >
        </>
    )
}

function initialValues(data) {
    return {
        modelo: data?.modelo || "",
        id_mapping: data?.id_mapping || "",
        ubicacion_id: {
            tag: data?.ubicacion_id.tag || "",
            planta_id: data?.ubicacion_id.planta_id || "",
            bahias_id: data?.ubicacion_id.bahias_id || "",
            observaciones: data?.ubicacion_id.observaciones || "",
        },
        marca: data?.marca || "",
        ip: data?.ip || "",
        serial_equipo: data?.serial_equipo || "",
        serial_syz: data?.serial_syz || "",
        usa_mapeo: data?.usa_mapeo || false,
        tipo: data?.tipo || "",
        estado: data?.estado || false,
        usa_brazos: data?.usa_brazos || false,
        arms: data?.arms || [],
        porteria: data?.porteria || "",
        proceso: data?.proceso || "",
        usuario: data?.usuario || "",
        contraseña: data?.contraseña || "",
        used_for_access: data?.used_for_access || false,
    };
}

function newSchame() {
    return {
        modelo: Yup.string(),
        ubicacion_id: Yup.object().shape({
            tag: Yup.string(),
            planta_id: Yup.string(),
            bahias_id: Yup.string(),
            observaciones: Yup.string(),
        }),
        marca: Yup.string(),
        ip: Yup.string(),
        // .matches(
        //   /^(([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6}|:)|:((:[0-9a-fA-F]{1,4}){1,7}|:))$|^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
        //   "Ingrese una dirección IP válida"
        // ),
        serial_equipo: Yup.string(),
        serial_syz: Yup.string(),
        usa_mapeo: Yup.bool(),
        tipo: Yup.string(),
        porteria: Yup.number(),
        proceso: Yup.string(),
        usuario: Yup.string(),
        contraseña: Yup.string(),
        used_for_access: Yup.bool(),
    };
}

function updateSchame() {
    return {
        modelo: Yup.string(),
        ubicacion_id: Yup.object().shape({
            tag: Yup.string(),
            planta_id: Yup.string(),
            bahias_id: Yup.string(),
            observaciones: Yup.string(),
        }),
        marca: Yup.string(),
        ip: Yup.string(),
        // .matches(
        //   /^(([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6}|:)|:((:[0-9a-fA-F]{1,4}){1,7}|:))$|^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
        //   "Ingrese una dirección IP válida"
        // ),
        serial_equipo: Yup.string(),
        serial_syz: Yup.string(),
        usa_mapeo: Yup.bool(),
        tipo: Yup.string(),
        porteria: Yup.number(),
        proceso: Yup.string(),
        usuario: Yup.string(),
        contraseña: Yup.string(),
        used_for_access: Yup.bool(),
    };
}