
import React from 'react';
import {Navigation} from "./routes";
import { ToastContainer } from "react-toastify";
// import "./App.scss";
import { AuthProvider } from "./context";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons'



library.add(fab, faCheckSquare, faCoffee)


export default function App() {
  return (
    <>
    {/* El AuthProvider esta en los contextos y envuelve toda la aplicacion para validar los login */}
    <AuthProvider>
    <Navigation />

    
    <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
      

    </AuthProvider>
    

    </>
  )
}