import React, { useState } from "react";

import { IconButton } from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import { Cancel, CheckCircle } from '@mui/icons-material';
import AdfScannerIcon from '@mui/icons-material/AdfScanner';
import PostAddIcon from '@mui/icons-material/PostAdd';
import moment from 'moment';
import {
  VistaTransacciones,
} from "../../Components/Client";
import { ImpresionT } from "../../Components/Client/OrdenVenta/Impresiones/ImpresionT";
import { useAuth, useDocumentosGut, useOrdenes } from "../../hooks";
import { AddEdit } from "./Components/AddEdit";

export  function Funciones() {
    
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const { auth } = useAuth();
  const { GenerarDocumentosGut} = useDocumentosGut();
  const openCloseModal = () => setShowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);
  const {  deleteOrdenes } = useOrdenes();

  
    const updateOrdenes = (data) => {
      setTitleModal("Actualizar Ordenes");
      setContentModal(
        <AddEdit
          onClose={openCloseModal}
          onRefetch={onRefetch}
          Ordenes={data}
        />
      );
      openCloseModal();
    };
  
    const vistaTransacciones = (data) => {
      
      setTitleModal("Lista de transacciones");
      setContentModal(
        <VistaTransacciones
          onClose={openCloseModal}
          onRefetch={onRefetch}
          Transacciones={data}
        />
      );
      openCloseModal();
    };
  
    const ImpresionTiquet = (data) =>{
     
      
      setTitleModal("Lista de transacciones");
      setContentModal(
        <ImpresionT
          onClose={openCloseModal}
          onRefetch={onRefetch}
          data={data}
        
        />
      );
      openCloseModal();
    }
  
  
    
    const columns = [
      
      
        {
          name: 'time_created',
          header: 'Fecha creación',
          group: "general",
          defaultFlex: 1,
          minWidth: 150,
          render: ({ value, cellProps }) => {
            return moment(value).format('MM-DD-YYYY')
          }
        },
        {
          header: "Numero de orden",
          name: "n_orden",
          group: "general",
          maxWidth: 1000, defaultFlex: 1
        },
      
        {
          header: "Autorizado",
          name: "v_auth", 
          group: "general",
          maxWidth: 300, defaultFlex: 1,
           render: (rowData) => (
            rowData.data.v_auth ? (
              <CheckCircle color="primary" />
            ) : (
              <Cancel color="error" />
            )
          )
        },   
        {
          header: "Cliente",
          name: "cliente_nombre",
          maxWidth: 1000, defaultFlex: 1
        },
        {
          header: "Tanque",
          name: "tanque_nombre",
          group: 'producto',
          maxWidth: 1000, defaultFlex: 1
        },
        
        {
          header: "Producto",
          name: "producto_nombre",
          group: 'producto',
          maxWidth: 1000, defaultFlex: 1
        },
        {
          header: "BSW",
          name: "bsw",
          group: 'producto',
          maxWidth: 90, defaultFlex: 1,
        },
        {
          header: "API",
          name: "api",
          group: 'producto',
          maxWidth: 90, defaultFlex: 1,
        },
        {
          header: "Entregado",
          name: "v_entregado",
          group: 'producto',
          maxWidth: 90,
          defaultFlex: 1,
        },
        {
          header: "Restante",
          name: "v_restante",
          group: 'producto',
          maxWidth: 90,
          defaultFlex: 1,
          
        },

        {
          header: "Autorizado",
          name: "tiempo_auth",
          
          maxWidth: 1000, defaultFlex: 1

        },
      
        
        {
          header: "Generar",
          id: "gut",
          render: ({ data }) => (
            <>
             <Tooltip title="IMPRESION DE TIQUET" arrow>
                <IconButton onClick={() => ImpresionTiquet(data)}>
                  <AdfScannerIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="GENERAR GUT" arrow>
                <IconButton onClick={() => GenerarDocumentosGut(data)}>
                  <PostAddIcon />
                </IconButton>
              </Tooltip>
            </>
          ),
          maxWidth: 170,
          defaultFlex: 1
        },
        
      ];

      const extraColumns = [
        {
          name: 'time_created',
          header: 'Fecha creación',
          group: 'general',
          defaultFlex: 1,
          minWidth: 150,
          render: ({ value, cellProps }) => {
            return moment(value).format('MM-DD-YYYY')
          }
        },
        {
          header: "Numero de orden",
          name: "n_orden",
          group: 'general',
          maxWidth: 1000, defaultFlex: 1
        },
        {
          header: "Estado",
          group: 'general',
          name: "estado_orden",
          maxWidth: 1000, defaultFlex: 1
        },
        {
          header: "Vehículo",
          name: "vehiculo_nombre",
          group: 'transporte',
          maxWidth: 1000, defaultFlex: 1
        },
        {
          header: "Conductor",
          name: "conductor_nombre",
          group: 'transporte',
          maxWidth: 1000, defaultFlex: 1
        },
        {
          header: "Remolque",
          name: "remolque_nombre",
          group: 'transporte',
          maxWidth: 1000, defaultFlex: 1
        },
        {
          header: "Transportadora",
          name: "transportadora_nombre",
          group: 'transporte',
          maxWidth: 1000, defaultFlex: 1
        },

        
        {
          header: "Sellos",
          name: "sellos",
          maxWidth: 1000, defaultFlex: 1
        },
        {
          header: "N. Factura",
          name: "n_factura",
          maxWidth: 1000, defaultFlex: 1
        },
        {
          header: "Observaciónes",
          name: "observacion_orden",
          maxWidth: 1000, defaultFlex: 1
        },
      ];
    return {
        columns,
        updateOrdenes,
        vistaTransacciones,
        openCloseModal,
        extraColumns,
        refetch,
        contentModal,
        titleModal,
        showModal,

      };
}
