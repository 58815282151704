import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import ReactApexChart from 'react-apexcharts';
import { groupBy } from 'lodash';
import { format } from 'date-fns';

const ReportAreaChart = ({ data }) => {
  const theme = useTheme();
  const { secondary } = theme.palette.text;
  const line = theme.palette.divider;
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({
    chart: {
      animations: {
        enabled: true,
        easing: 'linear',
        dynamicAnimation: {
          speed: 1000,
        },
      },
    },
    xaxis: {
      type: 'datetime',
      
    },
    yaxis: {
      title: {
        text: 'Galones',
      },
    },
    markers: {
      size: 5, // Tamaño de los marcadores en la gráfica
    },
  });

  useEffect(() => {
    if (data && data.length > 0) {
      const processedSeries = data.map(item => {
        const seriesData = item.data.map(dataPoint => ({
          x: new Date(dataPoint.x).getTime(),
          y: dataPoint.y,
        }));

        return {
          name: item.label,
          data: seriesData,
          borderColor: item.borderColor,
        };
      });

      setSeries(processedSeries);
    }
  }, [data]);
  return (
    <ReactApexChart options={options} series={series} type="line" height={420} />
  );

};

export default ReportAreaChart;
