
import PropTypes from 'prop-types';
import ArrowRightIcon from '@heroicons/react/24/solid/ArrowRightIcon';
import EllipsisVerticalIcon from '@heroicons/react/24/solid/EllipsisVerticalIcon';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  SvgIcon
} from '@mui/material';

export const OverviewLatestProducts = (props) => {
  const { products = [], sx } = props;
  if (!products || products.length === 0) {
    return <div>No products to display</div>;
  }

  return (
    <Card sx={sx}>
    <CardHeader title="Total entregado por producto" />
    {products.map((product, index) => (
      <ListItem divider={index < products.length - 1} key={product.producto}>
        <ListItemText
          primary={product.producto}
          primaryTypographyProps={{ variant: 'subtitle1' }}
          secondary={`${product.total} Gal`}
          secondaryTypographyProps={{ variant: 'body1' }}
          sx={{ minWidth: 200 }}
        />
      </ListItem>
    ))}
  
      <Divider />
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button
          color="inherit"
          endIcon={(
            <SvgIcon fontSize="small">
              <ArrowRightIcon />
            </SvgIcon>
          )}
          size="small"
          variant="text"
        >
          View all
        </Button>
      </CardActions>
    </Card>
  );
};

OverviewLatestProducts.propTypes = {
  products: PropTypes.array,
  sx: PropTypes.object
};
