
import React, { useEffect } from "react";
import { Loader } from "semantic-ui-react";
import { HeaderPage } from "../../../../Components/Client";
import { DataGridBasic, ModalBasic } from "../../../../Components/Comons";
import { useProductoTanque } from "../../../../hooks";
import { columns, filterValue } from "./Format";
import { Funciones } from "./Funciones";
import CircularProgress from '@mui/material/CircularProgress';

export function ProductoTanqueAdmin() {
  const { loading, ProductoTanque, getProductoTanque } = useProductoTanque();

  const {
    refetch,
    contentModal,
    titleModal,
    showModal,
    handleAdd,
    handleDelete,
    handleUpdate,
    handleViews,
    openCloseModal,
  } = Funciones();

  useEffect(() => {
    getProductoTanque();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const MemoizedModalBasic = React.memo(ModalBasic);

  const modifiedData = ProductoTanque ? ProductoTanque?.results?.map((item) => ({
    ...item,
    nombre_producto: item.data_productos.nombre,
    nombre_tanque: item.data_tanques.nombre_tanque
  })) : [];

  return (
    <>
      <HeaderPage
        title="Productos tanques"
        btnTitle="Nueva relacion"
        icono="plus square icon"
        btnClick={handleAdd}
      />
      {loading && !modifiedData ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <DataGridBasic
          data={modifiedData}
          title="Producto base"
          id="id"
          filterValue={filterValue}
          columns={columns}
          updateData={handleUpdate}
          onDeleteData={handleDelete}
          visualizacionData={handleViews}
        />
      )}

      {showModal && (
        <MemoizedModalBasic
          show={showModal}
          onClose={openCloseModal}
          title={titleModal}
          children={contentModal}
        />
      )}
    </>
  );
}
