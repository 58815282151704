export const columns = [
  {
    header: "Fecha",
    name: "fecha_captura",
    maxWidth: 1000,
    defaultFlex: 1,
  },
  {
    header: "Tanque",
    name: "tanque_nombre",
    maxWidth: 1000,
    defaultFlex: 1,
  },
  {
    header: "C. expansión térmica",
    name: "coeficiente_expansion_termica",
    maxWidth: 1000,
    defaultFlex: 1,
  },
  {
    header: "TSH",
    name: "tsh",
    maxWidth: 1000,
    defaultFlex: 1,
  },
  {
    header: "TOV",
    name: "tov",
    maxWidth: 1000,
    defaultFlex: 1,
  },
  {
    header: "FW",
    name: "fw",
    maxWidth: 1000,
    defaultFlex: 1,
  },
  {
    header: "CTSh",
    name: "CTSh",
    maxWidth: 1000,
    defaultFlex: 1,
  },
  {
    header: "GSV",
    name: "gsv",
    maxWidth: 1000,
    defaultFlex: 1,
  },
  {
    header: "CSW",
    name: "csw",
    maxWidth: 1000,
    defaultFlex: 1,
  },
  {
    header: "FRA",
    name: "fra",
    maxWidth: 1000,
    defaultFlex: 1,
  },

  {
    header: "GOV",
    name: "gov",
    maxWidth: 1000,
    defaultFlex: 1,
  },
  {
    header: "NSV",
    name: "nsv",
    maxWidth: 1000,
    defaultFlex: 1,
  },
];

export const filterValue = [
  {
    name: "tanque_nombre",
    operator: "before",
    type: "date",
    value: "",
  },
  {
    name: "coeficiente_expansion_termica",
    operator: "before",
    type: "date",
    value: "",
  },

  { name: "tsh", operator: "startsWith", type: "string", value: "" },
  { name: "tov", operator: "startsWith", type: "string", value: "" },
  { name: "fw", operator: "startsWith", type: "string", value: "" },
  { name: "CTSh", operator: "startsWith", type: "string", value: "" },
  { name: "gsv", operator: "startsWith", type: "string", value: "" },
  { name: "csw", operator: "startsWith", type: "string", value: "" },
  { name: "fra", operator: "startsWith", type: "string", value: "" },
  { name: "gov", operator: "startsWith", type: "string", value: "" },
  { name: "nsv", operator: "startsWith", type: "string", value: "" },
];
