import { useFormik } from "formik";
import { map } from "lodash";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { tanqueSelectExtOpts, VehiculosSelectExtOpts, MaquinasSelectExtOpts } from "../../../Components/Comons/Select/select-options"
import Swal from 'sweetalert2';
import {
    Button,
    Form,
    Input,
    Select,
    TextArea,
    Segment,
    Label,
    Loader
} from "semantic-ui-react";

import * as Yup from "yup";
import { useControladores, useTanques, useVehiculos, useMaquinas } from "../../../hooks";
import { alertas } from "../../Comons/Alertas/alertas";

export function AssignControlador(props) {
    const { tanquesDB, getTanquesDB } = useTanques()
    const { TanquesCI, getTanquesCI } = useTanques()
    const { Maquinas, getMaquinas } = useMaquinas()
    const { VehCarroTanque, getVehCarroTanque } = useVehiculos()
    const { Successful } = alertas();
    const { onClose, onRefetch, Controladores } = props;
    const { patchControladores } = useControladores()
    const [tanqueData, setTanqueData] = useState([])
    const [vehiculoData, setVehiculoData] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);

        Promise.all([getTanquesDB(), getTanquesCI(), getVehCarroTanque(), getMaquinas()])
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    }, [])

    useEffect(() => {
        setLoading(true);

        if (Controladores.tipo === "comp_flujo") {
            setTanqueData(tanqueSelectExtOpts(tanquesDB));
        } else {
            setTanqueData(tanqueSelectExtOpts(TanquesCI));
        }

        setVehiculoData(MaquinasSelectExtOpts(Maquinas));

        setLoading(false);
    }, [TanquesCI, tanquesDB, VehCarroTanque, Maquinas])

    const formik = useFormik({
        // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
        initialValues: initialValues(Controladores),
        // Establece la validación del esquema utilizando Yup, si se proporciona un objeto GruposPermisos se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
        validationSchema: Yup.object(Controladores ? updateScheme() : newScheme()),
        // Desactiva la validación al cambiar los valores del formulario
        validateOnChange: false,
        onSubmit: async (formValue) => {
            try {
                console.log(formValue)

                if (formValue.relacionTanque != "" && formValue.relacionVehiculo != "") {
                    Swal.fire({
                        title: 'Error',
                        text: 'No se puede relacionar un controlador a un tanque y carro tanque al mismo tiempo',
                        icon: 'error',
                    });
                }

                if (formValue.relacionVehiculo != 0) {
                    formValue.relacionTanque = null
                } else {
                    formValue.relacionVehiculo = null
                }

                console.log(formValue)

                // Si se proporciona un objeto Controladores se actualiza, de lo contrario se crea uno nuevo
                await patchControladores(Controladores.id_controlador, formValue);

                // Llama a una función Successful si se completa correctamente
                Successful();
                // Llama a la función onRefetch para actualizar la lista de GruposPermisos
                onRefetch();
                // Llama a la función onClose para cerrar el formulario
                onClose();
            } catch (error) {
                if (error?.message) {
                    // Muestra un mensaje de error si ocurre algún problema
                    toast.error(error.message, {
                        position: "top-center",
                    });
                } else {
                    onClose();
                }
            }
        },
    });

    return (
        <>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <>
                    <h4>Recuerde que una máquina solo puede ser relacionada con un tanque o un vehículo, no ambos</h4>
                    <Form className="add-edit-persona-form" onSubmit={formik.handleSubmit}>
                        <Form.Group widths="equal">
                            <Form.Field
                                fluid
                                control={Select}
                                label={Controladores.tipo == "reg_comb" ? "Tanque consumo interno" : "Tanque"}
                                placeholder="Seleccionar tanque"
                                selection
                                search
                                name="relacionTanque"
                                options={tanqueData}
                                value={formik.values.relacionTanque}
                                onChange={(_, data) => {
                                    formik.setFieldValue('relacionTanque', data.value);
                                }}
                                error={formik.errors.relacionTanque}
                            />
                            <Form.Field
                                fluid
                                control={Select}
                                label={"Carro tanque"}
                                placeholder="Seleccionar carro tanque"
                                selection
                                search
                                name="relacionVehiculo"
                                options={vehiculoData}
                                value={formik.values.relacionVehiculo}
                                onChange={(_, data) => {
                                    formik.setFieldValue('relacionVehiculo', data.value);
                                }}
                                error={formik.errors.relacionVehiculo}
                            />
                        </Form.Group>
                        <Button
                            type="submit"
                            // primary
                            className="btn btn-danger"
                            fluid
                            content={Controladores ? "Actualizar" : "Crear"}
                        />
                    </Form>
                </>
            )}
        </>
    );
}

function initialValues(data) {
    return {
        relacionTanque: data?.relacionTanque || 0,
        relacionVehiculo: data?.relacionVehiculo || 0,
    };
}

function newScheme() {
    return {
        relacionTanque: Yup.string(),
        relacionVehiculo: Yup.string(),
    };
}

function updateScheme() {
    return {
        relacionTanque: Yup.string(),
        relacionVehiculo: Yup.string(),
    };
}