import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { useVehiculos, useTcs3000 } from "../../../hooks";
import { Loader } from "semantic-ui-react";
import Swal from "sweetalert2"
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

export function AddEditPlatesInTcs(props) {
    const { Vehiculos, getVehiculos } = useVehiculos()
    const { tcs_plates, get_plates_in_tcs, save_plates_in_db, response } = useTcs3000()

    const { onClose, onRefetch, Controladores } = props

    const [left, setLeft] = useState([])

    const [checked, setChecked] = useState([]);
    //const [left, setLeft] = React.useState([0, 1, 2, 3]);
    const [right, setRight] = useState([]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };

    const customList = (items) => (
        <Paper sx={{ width: 200, height: 230, overflow: 'auto' }}>
            <List dense component="div" role="list">
                {items.map((value) => {
                    const labelId = `transfer-list-item-${value}-label`;

                    return (
                        <ListItem
                            key={value}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            {/* Display the "placa_veh" value */}
                            <ListItemText id={labelId} primary={value} />
                        </ListItem>
                    );
                })}
            </List>
        </Paper>
    );

    useEffect(() => {
        getVehiculos()
        get_plates_in_tcs(Controladores.id_controlador)
    }, [])

    useEffect(() => {
        if (Vehiculos && tcs_plates) {
            const placaVehValues = Vehiculos.results.map((vehicle) => vehicle.placa_veh);
            const tcsPlateValues = tcs_plates.map((plate) => plate.plate)
            setLeft(placaVehValues);
            setRight(tcsPlateValues)
        }
    }, [Vehiculos])

    if (!Vehiculos) {
        return <Loader active inline="centered" />
    }

    const handleSubmit = () => {
        const selectedValues = {
            ip: Controladores.ip,
            tcs_model: Controladores.id_controlador,
            plates: right
        }

        console.log(selectedValues)

        try {
            save_plates_in_db(selectedValues)

            onRefetch();
            // Llama a la función onClose para cerrar el formulario
            onClose();

            Swal.fire({
                title: "Operación realizada",
                text: "Los datos fueron actualizados",
                icon: "success",
            });
        } catch (error) {
            Swal.fire({
                title: "Operación no realizada",
                text: "Hubo un problema",
                icon: "error",
            });
        }
    }

    return (
        <>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12} sm={6} container justifyContent="center">
                    <h3>Placas en SYZAPI</h3>
                </Grid>
                <Grid item xs={12} sm={6} container justifyContent="center">
                    <h3>Placas en TCS3000</h3>
                </Grid>
                <Grid item style={{ marginRight: '60px' }}>{customList(left)}</Grid>
                <Grid item>
                    <Grid container direction="column" alignItems="center">
                        <Button
                            sx={{
                                my: 0.5, borderColor: 'gray', // Set the outline color
                                color: 'gray',
                            }}
                            variant="outlined"
                            size="small"
                            onClick={handleAllRight}
                            disabled={left.length === 0}
                            aria-label="move all right"
                        >
                            <KeyboardDoubleArrowRightIcon />
                        </Button>
                        <Button
                            sx={{
                                my: 0.5, borderColor: 'gray', // Set the outline color
                                color: 'gray',
                            }}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0}
                            aria-label="move selected right"
                        >
                            <KeyboardArrowRightIcon />
                        </Button>
                        <Button
                            sx={{
                                my: 0.5, borderColor: 'gray', // Set the outline color
                                color: 'gray',
                            }}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0}
                            aria-label="move selected left"
                        >
                            <KeyboardArrowLeftIcon />
                        </Button>
                        <Button
                            sx={{
                                my: 0.5, borderColor: 'gray', // Set the outline color
                                color: 'gray',
                            }}
                            variant="outlined"
                            size="small"
                            onClick={handleAllLeft}
                            disabled={right.length === 0}
                            aria-label="move all left"
                        >
                            <KeyboardDoubleArrowLeftIcon />
                        </Button>
                    </Grid>
                </Grid>
                <Grid item style={{ marginLeft: '60px' }}>{customList(right)}</Grid>

                <Grid item xs={12} sm={12}>
                    <Button fullWidth variant="contained" color="error" size="small" onClick={handleSubmit} aria-label="move all left">
                        Guardar
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}