import { useState } from "react";
import { useAuth } from "../";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { getDocumentosTransportadoraApi, updateDocumentosApi } from "../../api/A_Transportadora/documentosTransportadora";

export  function useDocumentosTransportadora() {
  
  const { auth } = useAuth();
  
  const { unauthorizedModal } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    DocumentosTransportadora: null,
  });

  const { loading, error, DocumentosTransportadora} = state;

  const getDocumentosTransportadora = async (data) => {
    try {
      
      setState((prevState) => ({ ...prevState, loading: true }));
      
      const [result, response] = await getDocumentosTransportadoraApi(auth.token, auth.me.tenant, data);

      setState((prevState) => ({ ...prevState, loading: false, DocumentosTransportadora: result.results }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };


  const updateDocumentosTransportadora = async (id, data) => {
    console.log(data)
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateDocumentosApi(id, data, auth.token, auth.me.tenant);

      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }

      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  
  return {
    loading,
    error,
    DocumentosTransportadora,
    getDocumentosTransportadora,
    updateDocumentosTransportadora,
  };
}
