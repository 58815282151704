
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import {
  addProductoBaseApi,
  deleteProductoBaseApi,
  getProductoBaseApi,
  updateProductoBaseApi
} from "../../api/A_Productos/productos";

export function useProductoBase() {
  const { auth, logout } = useAuth();
  const navigate = useNavigate();
  const { unauthorized, SessionExpired, errores, unauthorizedModal } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    ProductoBase: null,
    ProductoBaseDB: null,
  });

  const { loading, error, ProductoBase, ProductoBaseDB } = state;

  const handleResponse = (response) => {
    if (response.status === 401) {
      SessionExpired();
      logout();
    } else if (response.status === 403) {
      unauthorized();
      navigate("/");
    }
  };

  const getProductoBaseDB = async () => {
    try {
      const [result, response] = await getProductoBaseApi(auth.token, auth.me.tenant);
      setState((prevState) => ({ ...prevState, ProductoBaseDB: result.results }));
      
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  const getProductoBase = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getProductoBaseApi(auth.token, auth.me.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, ProductoBase: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addProductoBase = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addProductoBaseApi(data, auth.token, auth.me.tenant);
      if (response.status === 400) {
        errores();
      } else if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };


  const updateProductoBase = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateProductoBaseApi(id, data, auth.token, auth.me.tenant);
      if (response.status === 400) {
        errores();
      } else if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteProductoBase = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteProductoBaseApi(id, auth.token, auth.me.tenant);
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    ProductoBaseDB,
    ProductoBase,
    getProductoBaseDB,
    getProductoBase,
    addProductoBase,
    updateProductoBase,
    deleteProductoBase,
  };
}
