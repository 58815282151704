import Avatar from '@mui/material/Avatar';
export const columns = [
    // {
    //   header: 'ID',
    //   name: 'id_usuario',
    //   maxWidth: 1000, defaultFlex: 1
    // },
    // { header: 'Foto', name: 'foto', render: ({ data }) => {
    //     return (
    //       <>
    //         <Avatar alt="nn" src={data.foto} />
    //       </>
    //     )
    //   }, 
    //   maxWidth: 70, defaultFlex: 1
    // },
    {
      header: 'Nombre',
      name: 'nombres',
      maxWidth: 1000, defaultFlex: 1
  
    }, {
      header: 'Apellidos',
      name: 'apellidos',
      maxWidth: 1000, defaultFlex: 1
  
    }, {
      header: 'Identificación',
      name: 'identificacion_pers',
      maxWidth: 1000, defaultFlex: 1
  
  
    }, {
      header: 'Email',
      name: 'email',
      maxWidth: 1000, defaultFlex: 1
  
    }, {
  
      header: 'Celular',
      name: 'celular',
      maxWidth: 1000, defaultFlex: 1
  
    },
  
    
  ]


  export const filterValue = [

    { name: 'nombres', operator: 'startsWith', type: 'string', value: '' },
    { name: 'apellidos', operator: 'startsWith', type: 'string', value: '' },
    { name: 'identificacion_pers', operator: 'startsWith', type: 'string', value: '' },
    { name: 'email', operator: 'startsWith', type: 'string', value: '' },
    { name: 'celular', operator: 'startsWith', type: 'string', value: '' },
   
  ];