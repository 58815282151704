import React from "react";

import { Main } from "./containers/Main/Loadable";

export function Calendar() {
  return (
    <>
      <Main />
    </>
  );
}
