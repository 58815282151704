import { map } from "lodash";

export const conductoresSelect = (data) => {
  return map(data, (item) => ({
    key: item.id_conductor,
    text: item.nombre + " " + item.apellidos,
    value: item.id_conductor,
    extra: item.fecha_vencimineto_licencia,
  }));

};

export const condNombApeSelect = (data) => {
  const selectOptions = map(data, (item) => ({
    key: item.id_conductor,
    text: item.nombre + " " + item.apellidos,
    value: item.id_conductor,
    extra: item.fecha_vencimineto_licencia,
  }));

  selectOptions.unshift({
    key: 0,
    text: "Ninguno",
    value: 0,
    extra: null,
  });

  return selectOptions;
}

export const tanqueSelect = (data) => {
  return map(data, (item) => ({
    key: item.id_tanque,
    text: item.nombre_tanque,
    value: item.id_tanque,
  }));
}

export const tanqueSelectExtOpts = (data) => {
  const selectOptions = map(data, (item) => ({
    key: item.id_tanque,
    text: item.nombre_tanque,
    value: item.id_tanque,
  }));

  selectOptions.unshift({
    key: 0,
    text: "Ninguno",
    value: 0,
    extra: null,
  });

  return selectOptions;
}

export const tanqueSelectWProd = (data) => {
  return map(data, (item) => ({
    key: item.data_tanques.id_tanque,
    text: item.data_tanques.nombre_tanque,
    value: item.data_tanques.id_tanque,
    prod_id: item.data_productos.id,
    producto: item.data_productos.nombre,
    cap_tot: item.data_tanques.cap_consumo_int,
    cap_act: item.data_tanques.cap_consumo_int_act,
  }))
}

export const tcsSelect = (data) => {
  return map(data, (item) => ({
    key: item.id_controlador,
    text: item.modelo,
    value: item.id_controlador,
    relacionTanque: item.relacionTanque,
    relacionVehiculo: item.relacionVehiculo,
    vehicle: item.vehicle.placa_veh,
    tank: item.tank.nombre
  }))
}

export const tcsProdSelect = (data) => {
  return map(data, (item) => ({
    key: item.prod_id,
    text: item.prod_name,
    value: item.prod_id
  }))
}

export const clienteSelect = (data) => {
  return map(data, (item) => ({
    key: item.id_cliente,
    text: item.razon_social,
    value: item.id_cliente,
    extra: item,
  }));
}

export const TransportadoraSelect = (data) => {
  return map(data, (item) => ({
    key: item.id_transportadora,
    text: item.nombre_transportadora,
    value: item.id_transportadora,
    extra: item,
  }));
}

export const VehiculosSelectExtOpts = (data) => {
  const selectOptions =  map(data, (item) => ({
    key: item.id_vehiculo,
    text: item.placa_veh,
    value: item.id_vehiculo,
    extra: item,
  }));

  selectOptions.unshift({
    key: 0,
    text: "Ninguno",
    value: 0,
    extra: null,
  });

  return selectOptions;
}

export const MaquinasSelectExtOpts = (data) => {
  const selectOptions =  map(data, (item) => ({
    key: item.id_maquina,
    text: item.placa,
    value: item.id_maquina,
    extra: item,
  }));

  selectOptions.unshift({
    key: 0,
    text: "Ninguno",
    value: 0,
    extra: null,
  });

  return selectOptions;
}

export const VehiculosSelect = (data) => {
  return map(data, (item) => ({
    key: item.id_vehiculo,
    text: item.placa_veh,
    value: item.id_vehiculo,
    extra: item,
  }));
}

export const remolqueSelect = (data) => {
  return map(data, (item) => ({
    key: item.id_remolque,
    text: item.placa_remolq,
    value: item.id_remolque,
    extra: item,
  }));
}

export const productoCodigoSelect = (data) => {
  return map(data, (item) => ({
    key: item.id,
    text: item.nombre,
    value: item.codigo_producto,
    extra: item,
  }));
}

export const productoIdSelect = (data) => {
  return map(data, (item) => ({
    key: item.id,
    text: item.nombre,
    value: item.id,
  }));
}

export const mezclaCodigoSelect = (data) => {
  return map(data, (item) => ({
    key: item.id_mezclas,
    text: item.nombre,
    value: item.codigo,
    extra: item,
  }));
}

export const plantaSelect = (data) => {
  return map(data, (item) => ({
    key: item.id_myemp,
    text: item.razon_social,
    value: item.id_myemp,
    extra: item,
  }));
}

export const mapeoSelect = (data) => {
  return map(data, (item) => ({
    key: item.id_mapping,
    text: item.nombre_mapeo,
    value: item.id_mapping,
  }));
}

export const bahiasSelect = (data) => {
  return map(data, (item) => ({
    key: item.id_bahias,
    text: item.nombre,
    value: item.id_bahias,
  }));
}

export const fraccionesSelect = (data) => {
  return map(data, (item) => ({
    key: item.id_fracciones,
    text: item.id_fracciones,
    value: item.id_fracciones,
  }));
}


export const seccionesSelect = (data) => {
  return map(data, (item) => ({
    key: item.id_secciones,
    text: item.nombre_secciones,
    value: item.id_secciones,
  }));
}


export const usuariosSelect = (data) => {
  return map(data, (item) => ({
    key: item.id_usuario,
    text: item.nombres,
    value: item.id_usuario,
  }));
}

export const aforoSelect = (data) => {
  return map(data, (item) => ({
    key: item.id_aforo,
    text: item.nombre_tabla,
    value: item.id_aforo,
  }));
}

export const operadoresSelect = (data) => {
  return map(data, (item) => ({
    key: item.id_operador,
    text: item.nombre + " " + item.apellidos,
    value: item.id_operador
  }))
}

export const conductoresSelectNomApe = (data) => {
  return map(data, (item) => ({
    key: item.id_conductor,
    text: item.nombre + " " + item.apellidos,
    value: item.id_conductor
  }))
}

export const proveedorSelect = (data) => {
  return map(data, (item) => ({
    key: item.id_proveedor,
    text: item.nombre_proveedor,
    value: item.id_proveedor
  }))
}

export const ProductoTanqueSelect = (data) => {
  return map(data, (item) => ({
    key: item.id,
    text: item.nombre,
    value: item.id,
    extra: item,
  }))
}