import React, { useCallback, useState } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import { Box, IconButton, makeStyles } from "@material-ui/core";
import "./stylesTabla.css";
import * as XLSX from "xlsx";
import DownloadIcon from "@mui/icons-material/Download";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import filter from "@inovua/reactdatagrid-community/filter";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Divider,
} from "@mui/material";

const gridStyle = { minHeight: 720, marginTop: 10 };

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: "20px",
    backgroundColor: "#fff",
    borderRadius: 10,
    boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.2)",
    padding: theme.spacing(2),
  },
  dialogContent: {
    width: '100%', // Adjust the width as needed
    margin: 'auto',
  },
}));

export function DataGridNoOptCons(props) {
  const [filterData, setFilterData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
 

  const handleRowClick = (row) => {
    setSelectedRow(row === selectedRow ? null : row);
    setIsModalOpen(true);
  };

  const {
    data,
    columns,
    id,
    filterValue,
    cargaMasiva,
    cargaFotos,
    groups
  } = props;
  const classes = useStyles();

  const onFilterValueChange = useCallback((filterValue) => {
    const filteredData = filter(data, filterValue);
    setFilterData(filteredData);
  }, []);

  const exportToExcel = () => {
    if (filterData.length > 0) {
      const worksheet = XLSX.utils.json_to_sheet(filterData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "data.xlsx");
    } else {
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "data.xlsx");
    }
  };

  const newColumns = [
    ...columns
  ];

  return (
    <Box className={classes.tableContainer}>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton onClick={exportToExcel} style={{ marginRight: '8px', fontSize: '1.2rem' }}>
          <DownloadIcon />
        </IconButton>
        {cargaMasiva && (
          <IconButton onClick={cargaMasiva} style={{ marginRight: '8px', fontSize: '1.2rem' }}>
            <DriveFolderUploadIcon />
          </IconButton>
        )}
        {cargaFotos && (
          <IconButton onClick={cargaFotos} style={{ fontSize: '1.2rem' }}>
            <CameraswitchIcon />
          </IconButton>
        )}
      </div>

      <ReactDataGrid
        idProperty={id}
        columns={newColumns}
        dataSource={data}
        style={gridStyle}
        groups={groups}
        className="custom-data-grid"
        pagination
        paginationPageSize={10}
        paginationToolbarLabels={{
          prev: "Anterior",
          next: "Siguiente",
          page: "Página",
          of: "de",
          rows: "filas",
        }}
        defaultFilterValue={filterValue}
        onFilterValueChange={onFilterValueChange}
        onDataSourceChange={({ skip, limit }) => {
          console.log(`Pagina actual: ${skip / limit + 1}`);
        }}
        onRowClick={handleRowClick} // Add the onRowClick event handler
        rowSelection={{
          showCheckbox: false,
          selectBy: {
            isSelectedKey: "isSelected",
          },
        }}
      />
      {selectedRow && (
        <div className={classes.rowDetails}>
          {/* Modal with the details of the selected row */}
          <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} classes={{ paper: classes.dialogContent }}>
            <DialogTitle>Detalle del vehículo {selectedRow.data.placa_obj}</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <strong>Fecha de registro:</strong>
                  <p>{new Date(selectedRow.data.fecha_crea).toLocaleDateString()}</p>
                </Grid>
                <Grid item xs={4}>
                  <strong>Placa:</strong>
                  <p>{selectedRow.data.placa_obj}</p>
                </Grid>
                <Grid item xs={4}>
                  <strong>Vehículo/Máquina:</strong>
                  <p>{selectedRow.data.tipo_obj}</p>
                </Grid>
              </Grid>
              <Divider></Divider>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <strong>Tanque:</strong>
                  <p>{selectedRow.data.tank.nombre_tanque}</p>
                </Grid>
                <Grid item xs={4}>
                  <strong>Cantidad consumo:</strong>
                  <p>{selectedRow.data.cant_consumo} Gal</p>
                </Grid>
                <Grid item xs={4}>
                  <strong>Capacidad tanque:</strong>
                  <p>{selectedRow.data.veh_or_maq.cant_tot_combustible} Gal</p>
                </Grid>
              </Grid>
              <Divider></Divider>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <strong>Operador/Conductor:</strong>
                  <p>
                    {
                      selectedRow.data.veh_or_maq.conductor.nombre +
                      " " +
                      selectedRow.data.veh_or_maq.conductor.apellidos
                    }
                  </p>
                </Grid>
                <Grid item xs={4}>
                  <strong>Documento:</strong>
                  <p>{selectedRow.data.veh_or_maq.conductor.n_documento}</p>
                </Grid>
                <Grid item xs={4}>
                  <strong>Licencia:</strong>
                  <p>{selectedRow.data.veh_or_maq.conductor.n_licencia}</p>
                </Grid>
              </Grid>
              <Divider></Divider>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <strong>Km:</strong>
                  <p>{selectedRow.data.trabajo_act}</p>
                </Grid>
                <Grid item xs={4}>
                  <strong>Hr:</strong>
                  <p>{selectedRow.data.hrs_uso}</p>
                </Grid>
                <Grid item xs={4}>
                  <strong>Desplazamiento:</strong>
                  <p>{selectedRow.data.veh_or_maq.desplazamiento}</p>
                </Grid>
              </Grid>
              <Divider></Divider>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <strong>Operador:</strong>
                  <p>{selectedRow.data.operador.nombres + " " + selectedRow.data.operador.apellidos}</p>
                </Grid>
                <Grid item xs={4}>
                  <strong>Documento:</strong>
                  <p>{selectedRow.data.operador.identificacion_pers}</p>
                </Grid>
                <Grid item xs={4}>
                  <strong>Tipo de operación:</strong>
                  <p>{selectedRow.data.tipo_operacion}</p>
                </Grid>
              </Grid>
              <Divider></Divider>

            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={() => setIsModalOpen(false)}>Cerrar</Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </Box>
  );
}
