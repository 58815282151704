import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { loginDjango } from "../../../api/user";
import { useAuth } from "../../../hooks";
import { Navigate } from 'react-router-dom';

export function LoginForm() {
    /*
  Componente

  -componente de formulario para login 
  
 
  se valida que el usuario sea el correcto
   */
  const { login } = useAuth();

  const captcha = useRef(null)
  const [tokenRc, setToken] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const formik = useFormik({
    // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
    initialValues: initialValues(),
    // Establece la validación del esquema utilizando Yup, si se proporciona un objeto  se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (formValue) => {
      try {
         // Si hace una apeticion en caso de que retone valores se envia a la funcion login
        const response = await loginDjango(formValue);
        const { token } = response;
        login(token);
        setRedirect(true);
        
        
      } catch (error) {
        /*
        capturamos el error si el email o la contraseña son incorrectos y lo mostramos con toastify
        */ 
        toast.error(error.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          
          });
      }
    },
  });

  function onChange(value) {
    setToken(value);
  }

  if (redirect) {
    return <Navigate to="/" />;
  }
  
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="row g-3">
        <div className="col-3 ">
          <label> Email</label>
        </div>

        <div className="col-12 col-sm-11">
          <div className="input-group mb-3"></div>
        <input
          name="email"
          placeholder="Correo Electronico"
          type="email"
          required
          className={"form-control"}
          value={formik.values.email}
          onChange={formik.handleChange}
        />
      </div>
      </div>
      

      <div className="row g-3 ">
        <div className="col-3">
          <label> Password</label>
        </div>

        <div className="col-12 col-sm-11">
          <div className="input-group mb-3">
            <input
              name="password"
              required
              // en esta parte cuando cambia el estado visible mostrara la contraseña o no y quien la actualiza es setVisible
              // type={visible ? "text" : "password"}
              type="password"
              className={"form-control"}
              value={formik.values.password}
              onChange={formik.handleChange}
            />
            <br />
            <br />
           
            
            </div>
            </div>

            <div className="col-12 col-sm-11">
          <div className="input-group mb-3">
            <ReCAPTCHA
            ref={captcha}
              sitekey="6Le3RnsiAAAAAA3yF7tEaf1JSxi54OUB819tq_OL"
              onChange={onChange}
            />
            </div>
            <span
              className="input-group-text position-relative"
              // En esta parte cada vez que se da click se activara setVisible y este actualizara a visible dando true o false cada vez que se de click
              // onClick={toggleIcon}
              // style={{ cursor: "pointer", width: "40px" }}
            >
              {/* <FontAwesomeIcon
                                // cada vez que el estado de visible cambie de true mostrara un icono con un ojo y cuando sea false mostrara un icono de ojo con una linea intermedia
                                icon={visible ? faEyeSlash : faEye}
                                style={{
                                    top: "10px",
                                    bottom: "0px",
                                    right: "0px",
                                    left: "3px",
                                }}
                            /> */}
            </span>
          
        </div>
      </div>
      <div className="row g-3">
        <div className="col-12 col-sm-11">{/* prueba_captcha */}</div>
      </div>
      <div className="row g-3 align-items-stat">
        <div className="col">
          <div className="d-grid gap-2 col-5 col-sm-6 col-md-7 p-3 float-right">
          { tokenRc ? <button
              className="btn btn-danger rounded-pill float-right" 
              type="submit"
              
              // en esta parte se dara click y se activara la funcion y se capturara los datos
            >
              ingresar
            </button> :  <button
              className="btn btn-dark rounded-pill float-right" 
              type="submit"
              
              // en esta parte se dara click y se activara la funcion y se capturara los datos
            >
              ingresar
            </button> }
            

           


          </div>
        </div>

        <div className="col">
          <div className="d-grid gap-2 float-right">
              
            <a className="nav-link text-danger text-center" href="/reset_passsword">
            Olvide mis credenciales
            </a>
          </div>
        </div>
      </div>
    </form>
  );
}

function initialValues() {
  return {
    email: "",
    password: "",
  };
}
function validationSchema() {
  return {
    email: Yup.string().email(true).required(true),
    password: Yup.string().required(true),
  };
}
