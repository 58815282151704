import React, { useEffect, useState } from "react";
import { Loader } from "semantic-ui-react";
import {
    AddEditUrl,
  HeaderPage,
} from "../../../Components/Client";
import { DataGridBasic, ModalBasic } from "../../../Components/Comons";
import { useUrl } from "../../../hooks";
import { columns, filterValue } from "./UrlFormat";

export function Url() {
    const [showModal, setShowModal] = useState(false);
    const [titleModal, setTitleModal] = useState(null);
    const [contentModal, setContentModal] = useState(null);
    const [refetch, setRefetch] = useState(false);
  
    const { loading, Url, getUrl } =useUrl();
    useEffect(() => {
        getUrl();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetch]);
  
    const openCloseModal = () => setShowModal((prev) => !prev);
    const onRefetch = () => setRefetch((prev) => !prev);
  
    const updateDatos = (data) => {
      setTitleModal("Actualizar parametrización");
      setContentModal(
        <AddEditUrl
          onClose={openCloseModal}
          onRefetch={onRefetch}
          Datos={data}
        />
      );
      openCloseModal();
    };
  
    return (
      <>
        <HeaderPage title="Configuración Url" icono="plus square icon" />
        {loading ? (
          <Loader active inline="centered">
            Cargando...
          </Loader>
        ) : (
          <DataGridBasic
            data={Url}
            columns={columns}
            id="id"
            filterValue={filterValue}
            title="Parametrización de Url"
            updateData={updateDatos}
          />
        )}
  
        <ModalBasic
          show={showModal}
          onClose={openCloseModal}
          title={titleModal}
          children={contentModal}
          
        />
      </>
    );
  }
