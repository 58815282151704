import { useFormik } from "formik";
import { map } from "lodash";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Dropdown, Form,Label,Segment, } from "semantic-ui-react";
import * as Yup from "yup";
import { useSistemaMedicion } from "../../../hooks";
import { alertas } from "../../Comons/Alertas/alertas";
export function AddEditSistemaMedicion(props) {
   /*
  Componente

  -componente de visualizacion del modal de edicion y creacion para Sistema de medicion
  
  -se crea y valida mediante formik y yup
  -Se obtienen datos para dropdown mediante codigo en el componente
  -se obtiene los datos (para edicion) y funciones correspondientes mediante props desde su PageAdmin
   */
  const { onClose, onRefetch, sistemaMedicion } = props;
  const { Successful } = alertas();
  const { updateSistemaMedicion } = useSistemaMedicion();

  const formik = useFormik({
    initialValues: initialValues(sistemaMedicion),
    validationSchema: Yup.object(sistemaMedicion ? updateSchame() : false),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        if (sistemaMedicion)
          await updateSistemaMedicion(sistemaMedicion.id_sistema, formValue);
        else await true;
        Successful()
        onRefetch();
        onClose();
      } catch (error) {
        toast.error(error.message, {
          position: "top-center",
          });
      }
    },
  });
  return (
    <Form className="add-edit-secciones-form" onSubmit={formik.handleSubmit}>
      <Segment padded>
        <Label attached="top">Unidad de medida para Longitud</Label>
      <Dropdown
      required
        placeholder="Unidad de medida para longitud"
        fluid
        selection
        search
        options={LongitudOptions}
        value={formik.values.longitud}
        error={formik.errors.longitud}
        onChange={(_, data) => formik.setFieldValue("longitud", data.value)}
      />
      </Segment>
<br/>
<Segment padded>
        <Label attached="top">Unidad de Medida para Volumen</Label>
      <Dropdown
      required
        placeholder="Unidad de medida para Volumen"
        fluid
        selection
        search
        options={VolumenOptions}
        value={formik.values.volumen}
        error={formik.errors.volumen}
        onChange={(_, data) => formik.setFieldValue("volumen", data.value)}
      />
      </Segment>
<br/>
<Segment padded>
        <Label attached="top">Unidad de Medida para Temperatura</Label>
      <Dropdown
      required
        placeholder="Unidad de medida para temperatura"
        fluid
        selection
        search
        options={TemperaturaOptions}
        value={formik.values.temperatura}
        error={formik.errors.temperatura}
        onChange={(_, data) => formik.setFieldValue("temperatura", data.value)}
      />
      </Segment>
<br/>
      <Button
        type="submit"
        // primary
        className="btn btn-danger"
        fluid
        content={sistemaMedicion ? "Actualizar" : "Crear"}
      />
    </Form>
  );
}

const LongitudOptions = [
  {
    key: "m",
    text: "Metro(m)",
    value: "m",
  },
  {
    key: "cm",
    text: "Centimetro(cm)",
    value: "cm",
  },
  {
    key: "yd",
    text: "yarda(yd)",
    value: "yd",
  },
  {
    key: "ft",
    text: "pie(ft)",
    value: "ft",
  },
  
];
const VolumenOptions = [
  {
    key: "Gal",
    text: "Galones(Gal)",
    value: "Gal",
  },
  {
    key: "m3",
    text: "Metros cubicos(m3)",
    value: "m3",
  },
  {
    key: "bbl",
    text: "Barriles (bbl)",
    value: "bbl",
  },
  {
    key: "l",
    text: "Litros (l)",
    value: "l",
  },
];
const TemperaturaOptions = [
  {
    key: "°C",
    text: "Grados Celsius (°C)",
    value: "°C",
  },
  {
    key: "°F",
    text: "Grados Fahrenheit (°F)",
    value: "°F",
  },
  {
    key: "K",
    text: "Kelvin (K)",
    value: "K",
  }
];

function initialValues(data) {
  return {
    longitud: data?.longitud || "",
    volumen: data?.volumen || "",
    temperatura: data?.temperatura || "",
  };
}
function updateSchame() {
  return {
    longitud: Yup.string().required(true),
    volumen: Yup.string().required(true),
    temperatura: Yup.string().required(true),
  };
}
