import { useDocumentosTransportadora } from "../../../hooks";
import React, { useCallback, useState, useEffect } from "react";
import { DocumentosBase } from "../../Comons";

export function DocumentTransportadora(props) {

  const { data } = props;
  const [refetch, setRefetch] = useState(false);
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);
  const {

    DocumentosTransportadora,
    getDocumentosTransportadora,
    updateDocumentosTransportadora,

  } = useDocumentosTransportadora();

  useEffect(() => {
    getDocumentosTransportadora(data);
  }, [refetch]);

  return (
    <>
      <DocumentosBase update={updateDocumentosTransportadora} documentos={DocumentosTransportadora} onRefetch={onRefetch} />
    </>
  );
}
