// import {AdminLayout, ClientLayout} from "../layouts";
// import {LoginAdmin, VistaDeControl} from "../pages/Admin"

const routesAdmin =[
// {
//     path:"/admin", 
//     /*La ruta para acceder al panel de administrador */
//     layout: AdminLayout, 
//     /*Se utiliza el layout específico para la página de administrador */
//     component:LoginAdmin 
//     /* El componente que se renderiza en la página es el formulario de inicio de sesión para administradores */
// },
// {
//     path:"/control", 
//     /*La ruta para acceder al panel de administrador */
//     layout: ClientLayout, 
//     /*Se utiliza el layout específico para la página de administrador */
//     component:VistaDeControl
//     /* El componente que se renderiza en la página es el formulario de inicio de sesión para administradores */
// },
]
export default routesAdmin;