import React, { useEffect, useState } from "react";
import {
  HeaderPage
} from "../../../../Components/Client";
import { DataGridBasic, ModalBasic } from "../../../../Components/Comons";
import { useOrdenesDescargadero } from "../../../../hooks";
import { filterValue, groups } from "./OrdenesDescargaderoFormat";
import { Funciones } from "./Funciones";

import CircularProgress from '@mui/material/CircularProgress';

export function OrdenDescargaderoAdmin() {
  const { loading, OrdenesDescargadero, getOrdenesDescargadero } = useOrdenesDescargadero();
  const [showExtraColumns, setShowExtraColumns] = useState(false); // Estado para mostrar las columnas adicionales
  const {
    refetch,
    contentModal,
    titleModal,
    showModal,
    columns,
    extraColumns,
    handleAdd,
    handleDelete,
    handleUpdate,
    handleViews,
    openCloseModal,
  } = Funciones();
  useEffect(() => {
    getOrdenesDescargadero();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const handleToggleColumns = () => {
    // Cambiar el estado showExtraColumns al hacer clic en el botón de recargar o reorganizar
    setShowExtraColumns((prevShowExtraColumns) => !prevShowExtraColumns);
  };

  const MemoizedModalBasic = React.memo(ModalBasic);
  return (
    <>
      <HeaderPage
        title="Órdenes descargadero"
        btnTitle="Nueva orden descargue"
        icono="plus square icon"
        btnClick={handleAdd}
      />

      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <DataGridBasic
          data={OrdenesDescargadero?.results}
          title="Ordenes descargadero"
          id="id_orden"
          groups={groups}
          filterValue={filterValue}
          cambioTabla={handleToggleColumns}
          columns={showExtraColumns ? extraColumns : columns} // Selecciona las columnas a mostrar
          updateData={handleUpdate}
          onDeleteData={handleDelete}
          visualizacionData={handleViews}
        />
      )}
      
      {showModal && (
        <MemoizedModalBasic
          show={showModal}
          onClose={openCloseModal}
          title={titleModal}
          children={contentModal}
        />
      )}
    </>
  )
}


