import { useState } from "react";
import {
  getUrlApi,
  updateUrlApi
} from "../../api/A_Parametrizaciones/UrlLocal";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "..";


export  function useUrl() {
    const { auth, logout } = useAuth();
    const { unauthorized, SessionExpired } = alertas();
  
    const [state, setState] = useState({
      loading: true,
      error: null,
      Url: null,
    });
  
    const { loading, error, Url } = state;
  
    const getUrl = async () => {
      try {
        setState((prevState) => ({ ...prevState, loading: true }));
        const [result, response] = await getUrlApi(auth.token, auth.me.tenant);
        if (response.status === 401) {
          SessionExpired();
          logout();
        }
        if (response.status === 403) {
          unauthorized();
        }
        setState((prevState) => ({ ...prevState, loading: false, Url: result }));
      } catch (error) {
        setState((prevState) => ({ ...prevState, loading: false, error }));
      }
    };
  
  
    const updateUrl = async (id, data) => {
      try {
        setState((prevState) => ({ ...prevState, loading: true }));
        await updateUrlApi(id, data, auth.token, auth.me.tenant);
        setState((prevState) => ({ ...prevState, loading: false }));
      } catch (error) {
        setState((prevState) => ({ ...prevState, loading: false, error }));
        throw error;
      }
    };
  
    
  
    return {
      loading,
      error,
      Url,
      getUrl,
      updateUrl,
    };
  }
