export const PermGrounding = () => {
  return (
    <svg
      id="Capa_1"
      data-name="Capa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 456.15 675.76"
    >
      <path
        fill="#fff"
        d="M456.07,339.66c0-80.92,.24-161.85-.08-242.77-.24-59.65-33.29-95.11-92.63-95.97C274.09-.37,184.77-.23,95.5,.87,34.73,1.61,.6,37.01,.41,98.56-.11,259.28-.18,420,.44,580.72c.24,61.31,35.33,94.82,96.65,94.93,87.06,.16,174.11,.12,261.17,.02,65.21-.08,97.67-33.26,97.77-99.98,.13-78.67,.03-157.35,.03-236.02Z"
      />
      <path
        fill="#080809"
        d="M456.07,339.66c0,78.67,.1,157.35-.03,236.02-.11,66.71-32.57,99.9-97.77,99.98-87.06,.11-174.11,.14-261.17-.02-61.31-.11-96.41-33.63-96.65-94.93C-.18,420-.11,259.28,.41,98.56,.6,37.01,34.73,1.61,95.5,.87c89.28-1.09,178.59-1.24,267.86,.06,59.33,.86,92.39,36.32,92.63,95.97,.32,80.92,.08,161.84,.08,242.77Zm-20.4,1.87c0-80.92,.09-161.84-.03-242.75-.08-52.23-23.53-77.22-74.49-77.54-88.16-.55-176.34-.49-264.5-.02-50.13,.26-75.83,26.4-75.91,77.02-.27,160.71-.29,321.42,.02,482.13,.09,49.99,24.77,74.67,73.52,74.77,89.28,.18,178.62-1.77,267.83,.8,46.24,1.33,75.59-34.77,74.32-75.05-2.53-79.71-.75-159.56-.75-239.36Z"
      />
      <path
        fill="#fbfbfb"
        d="M435.67,341.53c0,79.79-1.78,159.65,.75,239.36,1.28,40.28-28.08,76.38-74.32,75.05-89.21-2.57-178.55-.62-267.83-.8-48.75-.1-73.42-24.78-73.52-74.77-.3-160.71-.28-321.42-.02-482.13,.08-50.61,25.78-76.75,75.91-77.02,88.17-.47,176.34-.53,264.5,.02,50.96,.32,74.41,25.3,74.49,77.54,.12,80.92,.03,161.84,.03,242.75Zm-202.7,280.84c33.45,0,66.91,.11,100.36-.03,48.35-.2,69.49-20.96,69.65-68.89,.21-66.25,.17-132.5,.02-198.76-.12-49.57-21.41-71.34-71.17-71.7-64.67-.46-129.38,1.12-194.02-.56-42.5-1.11-74.31,19.9-74.66,73.81-.41,64-.16,128.01-.06,192.02,0,5.58,.67,11.19,1.48,16.72,5.63,38.59,26.07,56.91,64.7,57.3,34.57,.35,69.14,.07,103.7,.08Z"
      />
      <path
        fill="#0d0e0e"
        d="M232.96,622.37c-34.57,0-69.14,.27-103.7-.07-38.63-.39-59.08-18.71-64.7-57.3-.81-5.53-1.47-11.15-1.48-16.72-.09-64.01-.35-128.01,.06-192.02,.35-53.91,32.16-74.92,74.66-73.81,64.63,1.69,129.34,.1,194.02,.56,49.75,.36,71.05,22.13,71.17,71.7,.16,66.25,.2,132.5-.02,198.76-.15,47.92-21.3,68.69-69.65,68.89-33.45,.14-66.91,.03-100.36,.02Zm.65-19.77c35.72,0,71.46,.57,107.17-.18,28.89-.61,42-12.51,42.29-40.47,.78-73.06,.35-146.14,.15-219.21-.06-20.57-10.24-34.23-30.36-38.65-12.93-2.84-26.23-5.19-39.41-5.41-41.29-.68-82.61-.55-123.91-.13-21.1,.22-42.52-.29-63.2,3.13-33.39,5.52-42.93,17.66-45.06,51.75-.91,14.56-.2,29.22-.2,43.83,0,50.59-.72,101.19,.25,151.76,.76,40.03,14.31,53.21,51.82,53.53,33.49,.29,66.98,.06,100.47,.05Z"
      />
      <path
        fill="#5a6850"
        d="M233.61,602.61c-33.49,0-66.98,.23-100.47-.05-37.51-.32-51.06-13.51-51.82-53.53-.97-50.57-.25-101.17-.25-151.76,0-14.61-.71-29.28,.2-43.83,2.13-34.09,11.67-46.23,45.06-51.75,20.69-3.42,42.1-2.91,63.2-3.13,41.3-.43,82.62-.56,123.91,.13,13.18,.22,26.48,2.57,39.41,5.41,20.12,4.42,30.31,18.08,30.36,38.65,.2,73.07,.64,146.15-.15,219.21-.3,27.96-13.41,39.86-42.29,40.47-35.71,.75-71.44,.18-107.17,.19Zm-12.35-217.32c-29.02,0-54.38-.25-79.72,.13-9.21,.14-22.04-3.95-22.99,11.6-.95,15.59,11.28,13.65,21.08,13.66,61.19,.08,122.37,.08,183.56,0,9.8-.01,21.91,1.99,22.26-13.02,.39-16.42-12.89-12.05-21.94-12.21-25.12-.45-50.24-.16-76.91-.16,0-22.75,.98-40.72-.58-58.45-.49-5.53-7.84-10.45-12.05-15.65-4.24,5.08-11.66,9.88-12.14,15.3-1.56,17.73-.58,35.69-.58,58.81Zm10.3,61.11c-19.92,0-39.9-.85-59.72,.56-5.35,.38-10.21,7.64-15.3,11.74,4.43,3.97,8.8,11.31,13.3,11.4,42.03,.78,84.08,.67,126.1-.36,4.05-.1,7.92-8.06,11.87-12.37-4.39-3.64-8.63-10.19-13.2-10.43-20.97-1.13-42.03-.5-63.05-.54Zm1.98,61.08c0,.07,0,.13,0,.2-9.96,0-20.07-1-29.79,.55-3.29,.53-7.01,7.23-7.77,11.59-.43,2.52,4.54,8.52,7.29,8.65,18.76,.91,37.61,1.22,56.36,.33,3.51-.17,9.25-6.6,9.34-10.26,.09-3.61-5.22-9.86-8.96-10.56-8.56-1.59-17.62-.5-26.48-.5Z"
      />
      <path
        fill="#e2e5e1"
        d="M221.26,385.28c0-23.13-.97-41.08,.58-58.81,.48-5.42,7.9-10.22,12.14-15.3,4.21,5.2,11.56,10.12,12.05,15.65,1.56,17.74,.58,35.7,.58,58.45,26.66,0,51.79-.3,76.91,.16,9.05,.16,22.32-4.21,21.94,12.21-.35,15.01-12.46,13.01-22.26,13.02-61.19,.09-122.37,.09-183.56,0-9.81-.01-22.04,1.93-21.08-13.66,.95-15.55,13.78-11.45,22.99-11.6,25.34-.39,50.7-.13,79.72-.13Z"
      />
      <path
        fill="#f2f3f2"
        d="M231.55,446.42c21.03,.02,42.09-.61,63.05,.52,4.57,.25,8.81,6.79,13.2,10.43-3.95,4.31-7.82,12.27-11.87,12.37-42.02,1.02-84.08,1.13-126.1,.36-4.5-.08-8.87-7.42-13.3-11.4,5.09-4.1,9.95-11.35,15.3-11.74,19.82-1.41,39.8-.56,59.72-.54Z"
      />
      <path
        fill="#f3f3f1"
        d="M233.54,507.48c8.86,0,17.91-1.09,26.48,.5,3.75,.7,9.06,6.95,8.96,10.56-.1,3.66-5.84,10.1-9.34,10.26-18.75,.9-37.6,.58-56.36-.33-2.75-.13-7.73-6.13-7.29-8.65,.75-4.36,4.47-11.07,7.77-11.59,9.71-1.55,19.83-.55,29.79-.55,0-.07,0-.13,0-.2Z"
      />
    </svg>
  );
};
