export const groups = [
  { name: 'transporte', header: 'Transporte' },
  { name: 'general', header: 'General' },
  { name: 'producto', header: 'Producto' },
]

export const filterValue = [
    {
        name: 'time_created',
        operator: 'contains',
        type: 'string',
        value: ''
      },
      { name: 'n_orden', operator: 'startsWith', type: 'string', value: '' },
    { name: 'v_auth', operator: 'startsWith', type: 'string', value: '' },
    { name: 'estado_orden', operator: 'startsWith', type: 'string', value: '' },
    { name: 'observacion_orden', operator: 'startsWith', type: 'string', value: '' },
    { name: 'cliente_nombre', operator: 'startsWith', type: 'string', value: '' },
    { name: 'transportadora_nombre', operator: 'startsWith', type: 'string', value: '' },
    { name: 'vehiculo_nombre', operator: 'startsWith', type: 'string', value: '' },
    { name: 'conductor_nombre', operator: 'startsWith', type: 'string', value: '' },
    { name: 'remolque_nombre', operator: 'startsWith', type: 'string', value: '' },
    {
        name: 'fecha_initcargue',
        operator: 'before',
        type: 'date',
        value: ''
      },
      {
        name: 'fecha_endcargue',
        operator: 'before',
        type: 'date',
        value: ''
      },
      {
        name: 'fecha_auth',
        operator: 'before',
        type: 'date',
        value: ''
      },
    { name: 'bsw', operator: 'startsWith', type: 'string', value: '' },
    { name: 'api', operator: 'startsWith', type: 'string', value: '' },
    
  ];