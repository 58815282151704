import React, { useState } from 'react';
import { useProductos } from '../../../hooks';
import { useFormik } from 'formik';
import {
  Button,
  Typography,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Alert } from '@mui/material';
import * as Yup from 'yup';

export function GetProductos(props) {
  const { data } = props;
  const { onClose, onRefetch, obtenerProducto } = useProductos();

  const [showWarning, setShowWarning] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const formik = useFormik({
    initialValues: initialValues(data),

    validateOnChange: false,
    onSubmit: async (formValue) => {
      // Realiza la lógica para eliminar los productos anteriores aquí
      // Puedes mostrar otra alerta exitosa después de eliminar los productos anteriores si es necesario
      setOpenSnackbar(true);

      formValue.ip = data.ip;
      formValue.tipo = 1;
      try {
        obtenerProducto(formValue);
        onRefetch();
        onClose();
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleObtenerProductos = () => {
    setShowWarning(true);
  };

  const handleConfirmSubmit = () => {
    setShowWarning(false); // Oculta la advertencia
    setOpenDialog(false); // Cierra el diálogo
    formik.submitForm(); // Ejecuta el onSubmit
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <div>
      <Typography variant="body1">
        <strong>IP:</strong> {data.ip}
      </Typography>
      <Typography variant="body1">
        <strong>Modelo:</strong> {data.modelo}
      </Typography>
  
      <Button
        variant="contained"
        style={{
          backgroundColor: '#081926',
          color: '#FFFFFF',
          marginTop: '20px', // Añadido para mejorar el espaciado
        }}
        onClick={handleObtenerProductos}
      >
        Obtener Productos
      </Button>
  
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          Operación completada con éxito.
        </Alert>
      </Snackbar>
  
      <Dialog open={showWarning} onClose={() => setShowWarning(false)}>
        <DialogTitle>Advertencia</DialogTitle>
        <DialogContent>
          <Alert severity="warning" sx={{ backgroundColor: '#CC2C2B', color: '#FFFFFF' }}>
            <strong>Advertencia:</strong> Los productos anteriores registrados para este computador serán eliminados.
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowWarning(false)} style={{ color: '#424242' }}>
            Cancelar
          </Button>
          <Button onClick={handleConfirmSubmit} style={{ color: '#FF0000' }}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function initialValues(data) {
  return {
    ip: data?.id || '',
    tipo: '',
  };
}
