




  export const filterValue = [
    {
      name: "id_conductor",
      operator: "startsWith",
      type: "string",
      value: "",
    },
    {
      name: "nombre",
      operator: "startsWith",
      type: "string",
      value: "",
    },
    {
      name: "apellidos",
      operator: "startsWith",
      type: "string",
      value: "",
    },
    {
      name: "email",
      operator: "startsWith",
      type: "string",
      value: "",
    },
    {
      name: "n_documento",
      operator: "startsWith",
      type: "string",
      value: "",
    },
    {
      name: "n_licencia",
      operator: "startsWith",
      type: "string",
      value: "",
    },
    {
      name: "fecha_vencimineto_licencia",
      operator: "before",
      type: "date",
      value: "",
    },
    {
      name: "celular",
      operator: "startsWith",
      type: "string",
      value: "",
    },
  ];
  