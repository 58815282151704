import React, { useCallback } from "react";
import { Circle } from "../common";
import { Perm3 } from "../icons/perm_3";
import { Perm4 } from "../icons/perm_4";
import { PermEmergencyStop } from "../icons/perm_emergency_stop";
import { PermGrounding } from "../icons/perm_grounding";
import { PermOverFill } from "../icons/perm_overfill";
import { PermWhiteAlert } from "../icons/perm_white_alert";
import { ContainerCircleIcon, ContainerIcon, ContainerIcons } from "./styles";
import { theme } from "../../theme";

export const IconAlert = ({ data, top, size, width, height }) => {
  const perSW = useCallback((perm) => {
    if (perm === 0) {
      return theme.default.primary.green;
    } else if (perm === 1) {
      return theme.default.primary.red;
    } else {
      return theme.default.primary.gray4;
    }
  }, []);

  const renderCircleIcon = (permissionKey) => {
    const permissionValue = data?.read_permiss?.[permissionKey];

    if (permissionValue !== undefined) {
      const IconComponent = getIconComponent(permissionKey);
      
      return (
        <ContainerCircleIcon key={permissionKey}>
          <Circle
            relative
            top={top}
            color={perSW(permissionValue)}
            size={size}
          />
          {IconComponent && <IconComponent />}
        </ContainerCircleIcon>
      );
    }

    return null;
  };

  const getIconComponent = (permissionKey) => {
    switch (permissionKey) {
      case "sobrellenado":
        return PermOverFill;
      case "parada de emergencia":
        return PermEmergencyStop;
      case "tierra":
        return PermGrounding;
      case "hombre_muerto":
        return Perm3;
      case "fire&gas":
        return Perm4;
      case "scada":
        return PermWhiteAlert;
      // Add more cases for other keys if needed
      default:
        return PermWhiteAlert;
    }
  };

  return (
    <ContainerIcon width={width} height={height}>
      <ContainerIcons>
        {renderCircleIcon("sobrellenado")}
        {renderCircleIcon("parada de emergencia")}
        {renderCircleIcon("tierra")}
      </ContainerIcons>
      <ContainerIcons>
        {renderCircleIcon("hombre_muerto")}
        {renderCircleIcon("fire&gas")}
        {renderCircleIcon("scada")}
      </ContainerIcons>
      <ContainerIcons>
        {renderCircleIcon("Valvula")}
        {renderCircleIcon("Shutdown")}
      </ContainerIcons>
    </ContainerIcon>
  );
};