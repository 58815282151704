export const PermWhiteAlert = () => {
  return (
    <svg
      id="Capa_1"
      data-name="Capa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 442.1 655.44"
    >
      <path
        fill="#fff"
        d="M441.39,327.85c0-76.35-2.04-152.76,.63-229.01C444.26,34.73,400.98-1.24,344.34,.03c-81.02,1.82-162.11,.33-243.17,.5C31.66,.68,.14,32.3,.09,101.98c-.11,151.6-.12,303.2,0,454.8,.05,66.87,31.01,98.45,96.53,98.57,84.3,.16,168.6,.05,252.91,.04,59.39-.01,91.66-32.14,91.81-91.96,.2-78.53,.05-157.05,.05-235.58Z"
      />
      <path
        fill="#040404"
        d="M441.39,327.85c0,78.53,.15,157.05-.05,235.58-.15,59.82-32.43,91.94-91.81,91.96-84.3,.02-168.6,.13-252.91-.04C31.1,655.23,.15,623.65,.09,556.78-.03,405.18-.03,253.58,.09,101.98,.14,32.3,31.66,.68,101.17,.54,182.23,.36,263.32,1.85,344.34,.03c56.64-1.27,99.93,34.69,97.68,98.8-2.67,76.25-.63,152.67-.63,229.01Zm-20,1.94h-.28c0-78.49,.02-156.99,0-235.48-.02-46.94-22.17-72.68-67.45-73.27-88.57-1.15-177.18-1.13-265.76,.02-42.9,.56-67.29,27.08-67.42,70.89-.46,156.99-.54,313.98-.29,470.97,.08,48.18,24.41,72.21,71.68,72.31,85.35,.18,170.75-1.76,256.02,.79,44.68,1.34,75.38-30.24,74.12-74.02-2.22-77.34-.62-154.79-.62-232.19Z"
      />
      <path
        fill="#f9f9f9"
        d="M421.39,329.79c0,77.4-1.6,154.85,.62,232.19,1.26,43.78-29.44,75.35-74.12,74.02-85.27-2.55-170.67-.61-256.02-.79-47.28-.1-71.6-24.13-71.68-72.31-.25-156.99-.17-313.98,.29-470.97,.13-43.8,24.52-70.33,67.42-70.89,88.57-1.15,177.18-1.17,265.76-.02,45.28,.59,67.43,26.33,67.45,73.27,.03,78.49,0,156.99,0,235.48h.28Zm-31.25,112.24h.15c0-34.89,.45-69.78-.12-104.65-.63-38.53-21.24-62.09-59.3-62.87-71.26-1.45-142.59-1.41-213.84,.13-35.66,.77-58.21,25.09-58.65,60.78-.87,69.76-.9,139.55,.01,209.3,.51,38.49,25.1,63.48,63.19,64.02,69.13,.98,138.29,1,207.41,0,36.74-.53,60.1-25.08,60.98-62.06,.83-34.86,.18-69.77,.18-104.65Z"
      />
      <path
        fill="#121210"
        d="M390.14,442.03c0,34.88,.65,69.79-.18,104.65-.88,36.98-24.24,61.52-60.98,62.06-69.13,1-138.29,.99-207.41,0-38.09-.54-62.68-25.53-63.19-64.02-.92-69.76-.89-139.54-.01-209.3,.45-35.68,22.99-60.01,58.65-60.78,71.25-1.54,142.58-1.59,213.84-.13,38.06,.78,58.68,24.34,59.3,62.87,.57,34.88,.12,69.77,.12,104.65h-.15Zm-165.25,149.39c32.39,0,64.79,.19,97.18-.05,38.08-.28,53.28-15.04,53.47-52.79,.32-64.29,.38-128.58-.08-192.87-.27-37.87-18.17-56.39-55.18-56.71-63.71-.55-127.42-.65-191.13,.04-35.98,.39-54.74,17.52-55.54,53.69-1.47,66.44-1.3,132.94-.3,199.39,.5,33.39,17.68,48.76,51.16,49.23,33.47,.47,66.95,.1,100.42,.07Z"
      />
      <path
        fill="#fdfdfc"
        d="M224.89,591.43c-33.47,.02-66.95,.38-100.42-.09-33.48-.47-50.66-15.84-51.16-49.23-1-66.45-1.17-132.96,.3-199.39,.8-36.17,19.56-53.3,55.54-53.69,63.7-.69,127.42-.59,191.13-.04,37.01,.32,54.91,18.84,55.18,56.71,.46,64.29,.4,128.58,.08,192.87-.19,37.76-15.39,52.51-53.47,52.79-32.39,.24-64.79,.05-97.18,.07Z"
      />
    </svg>
  );
};
