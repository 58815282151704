import React, { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { Funciones } from "./Funciones";
import { useEmisiones } from "../../../hooks"
import { DataGridBasic, ModalBasic } from "../../../Components/Comons";
import { HeaderPage } from "../../../Components/Client";
import { columns, filterValue } from "./EmisionesFormat"

export function EmisionesAdmin() {
    const { Emisiones, getEmisiones, loading } = useEmisiones()

    const { refetch, contentModal, titleModal, showModal, handleAdd, openCloseModal, handleDelete, handleUpdate } = Funciones();

    useEffect(() => {
        getEmisiones();
    }, [refetch])
    
    const modifiedData = Emisiones ? Emisiones?.map((item) => ({
        ...item,
        plantaOrigenNombre: item.plantaOrigenData.razon_social,
        plantaDestinoNombre: item.plantaDestinoData.razon_social,
    })) : []

    const MemoizedModalBasic = React.memo(ModalBasic);
    
    return (
        <>
            <HeaderPage
                title="Registro de emisiones"
                btnTitle="Nuevo registro"
                icono="plus square icon"
                btnClick={handleAdd}
            />

            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <DataGridBasic
                        data={modifiedData}
                        title="Emisiones"
                        id="id_registro"
                        filterValue={filterValue}
                        columns={columns}
                        updateData={handleUpdate}
                        onDeleteData={handleDelete}
                    />
                </>
            )}

            {showModal && (
                <MemoizedModalBasic
                    show={showModal}
                    onClose={openCloseModal}
                    title={titleModal}
                    children={contentModal}
                />
            )}
        </>
    )
}