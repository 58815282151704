import React, { useEffect, useState, useCallback } from "react";
import { useScript } from "../../../hooks";
import { makeStyles } from '@material-ui/core/styles';

import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"

import AddIcon from '@material-ui/icons/Add';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#FFFFFF',
        borderRadius: 10,
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    },
    title: {

    },
}));

export function ButtonScript() {
    const [refetch, setRefetch] = useState(false);
    const [gvAutoOrder, setGvAutoOrder] = useState(false);
    const [gvScriptAuto, setGvScriptAuto] = useState(false);
    const [scriptData, setScriptData] = useState(null); // Variable para almacenar los datos del script

    const { loading, Script, getScript, updateScript } = useScript();

    useEffect(() => {
        getScript();
    }, [refetch]);

    useEffect(() => {
        if (Script && Script.length > 0) {
            // Si Script está definido y tiene al menos un elemento en el arreglo
            const script = Script[0]; // Obtiene el primer elemento del arreglo
            setScriptData(script); // Almacena los datos del script en la variable scriptData
            setGvAutoOrder(script.gv_auto_order);
            setGvScriptAuto(script.gv_script_auto);
        }
    }, [Script]);

    const handleGvAutoOrderToggle = async () => {
        setGvAutoOrder(!gvAutoOrder);
        if (scriptData) {
            await updateScript(scriptData.id, { gv_auto_order: !gvAutoOrder });
            setRefetch(!refetch);
        }
    };

    const handleGvScriptAutoToggle = async () => {
        setGvScriptAuto(!gvScriptAuto);
        if (scriptData) {
            await updateScript(scriptData.id, { gv_script_auto: !gvScriptAuto });
            setRefetch(!refetch);
        }
    };

    const classes = useStyles();

    return (
        <Box className={classes.root} marginTop={1}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Button
                        variant="contained" className="btn btn-danger"
                        startIcon={gvScriptAuto ? <ToggleOnIcon style={{ color: 'black' }} /> : <ToggleOffIcon style={{ color: 'black' }} />}
                        style={{ 
                            backgroundColor: gvScriptAuto ? 'rgba(0, 0, 0, 0.7)' : 'rgba(224, 224, 224, 0.7)', 
                            color: gvAutoOrder ? 'white' : 'black'  
                        }}
                        onClick={handleGvScriptAutoToggle}
                        fullWidth
                    >
                        {gvScriptAuto ? 'Desactivar script auto' : 'Activar script auto'}
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Button variant="contained" fullWidth className="btn btn-danger"
                        startIcon={gvAutoOrder ? <ToggleOnIcon style={{ color: 'black' }} /> : <ToggleOffIcon style={{ color: 'black' }} />}
                        style={{ 
                            backgroundColor: gvAutoOrder ? 'rgb(208, 48, 43)' : 'rgba(224, 224, 224, 0.7)', 
                            color: gvAutoOrder ? 'white' : 'black' 
                        }}
                        onClick={handleGvAutoOrderToggle}>
                        {gvAutoOrder ? 'Desactivar auto order' : 'Activar auto order'}
                    </Button>
                </Grid>
            </Grid>
        </Box>

        // <div>
        //     <br />
        //     <Box className={classes.root}>
        //         <Typography variant="h4" className={classes.title}>

        //         </Typography>
        //         <Button
        //             variant="contained"

        //             className="btn btn-danger"
        //             startIcon={gvScriptAuto ? <ToggleOnIcon /> : <ToggleOffIcon />}
        //             style={{ backgroundColor: gvScriptAuto ? 'rgba(0, 0, 0, 0.7)' : 'rgba(224, 224, 224, 0.7)' }}
        //             onClick={handleGvScriptAutoToggle}
        //         >
        //             {gvScriptAuto ? 'Desactivar script auto' : 'Activar script auto'}
        //         </Button>
        //         <Button
        //             variant="contained"

        //             className="btn btn-danger"
        //             startIcon={gvAutoOrder ? <ToggleOnIcon /> : <ToggleOffIcon />}
        //             style={{ backgroundColor: gvAutoOrder ? 'rgba(0, 0, 0, 0.7)' : 'rgba(224, 224, 224, 0.7)' }}
        //             onClick={handleGvAutoOrderToggle}>
        //             {gvAutoOrder ? 'Desactivar auto order' : 'Activar auto order'}
        //         </Button>
        //     </Box>
        // </div>
    );
}
