import React, { useCallback, useState } from "react";
import Swal from "sweetalert2";
import { AddEditMapeos } from "../../../Components/Client";
import { useMapeos } from "../../../hooks";

export function Funciones() {
  const { deleteMapeos } = useMapeos();
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);

  const handleAdd = useCallback(() => {
    setTitleModal("Nueva planta");
    setContentModal(
      <AddEditMapeos onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const handleUpdate = useCallback(
    (data) => {
      setTitleModal("Actualizar planta");
      setContentModal(
        <AddEditMapeos
          onClose={openCloseModal}
          onRefetch={onRefetch}
          Mapeos={data}
        />
      );
      openCloseModal();
    },
    [openCloseModal, onRefetch]
  );

  const handleDelete = useCallback(
    async (data) => {
      const result = await Swal.fire({
        title: "Estas seguro?",
        text: "No podrás revertir esta acción!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, eliminar!",
        cancelButtonText: "No, cancelar!",
        reverseButtons: true,
      });

      if (result.isConfirmed) {
        await deleteMapeos(data.id);
        Swal.fire("Eliminado!", "El dato ha sido eliminado.", "success");
        onRefetch();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelado", "Operación cancelada", "error");
      }
    },
    [deleteMapeos, onRefetch]
  );

  return {
    handleAdd,
    handleDelete,
    handleUpdate,
    openCloseModal,
    refetch,
    contentModal,
    titleModal,
    showModal,
  };
}
