import React, { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { HeaderPage } from "../../../Components/Client";
import { DataGridBasic, ModalBasic } from "../../../Components/Comons";
import { useAditivos } from "../../../hooks";
import { columns, filterValue } from "./AditivosFormat";
import { FuncionesA } from "./FuncionesA";

export function AditivosAdmin() {
  const { loading, Aditivos, getAditivos } = useAditivos();
  const {
    refetch,
    contentModal,
    titleModal,
    showModal,
    handleAdd,
    handleDelete,
    handleUpdate,
    openCloseModal,
  } = FuncionesA();

  useEffect(() => {
    getAditivos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const MemoizedModalBasic = React.memo(ModalBasic);

  return (
    <>
      <HeaderPage title="Aditivos" btnTitle="Nuevo aditivo" icono="plus square icon" btnClick={handleAdd} />

      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <DataGridBasic
          data={Aditivos.results}
          title="Aditivos"
          id="id"
          filterValue={filterValue}
          columns={columns}
          updateData={handleUpdate}
          onDeleteData={handleDelete}
        />
      )}

      {showModal && (
        <MemoizedModalBasic
          show={showModal}
          onClose={openCloseModal}
          title={titleModal}
          children={contentModal}
        />
      )}

    </>
  )
}
//Columnas que se envian en forma de props al componente tabla basic para representer sus campos


