import React, { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';

import { ModalBasic } from "../../../../Components/Comons";
import { useProductoMezcla } from "../../../../hooks";

import { Funciones } from "./Funciones";
import { GraficaProductos } from "../../../../Components/Client";

export function ProductosMezclaAdmin() {
  const { loading, ProductoMezcla, getProductoMezcla } = useProductoMezcla();

  const {
    refetch,
    contentModal,
    titleModal,
    showModal,
    handleAdd,
    handleDelete,
    handleUpdate,
    openCloseModal,
  } = Funciones();

  useEffect(() => {
    getProductoMezcla();
  }, [refetch]);

  const MemoizedModalBasic = React.memo(ModalBasic);

  return (
    <>

      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <GraficaProductos
            agregar={handleAdd}
            actualizar={handleUpdate}
            eliminar={handleDelete}
            data={ProductoMezcla?.results}
          />

          {showModal && (
            <MemoizedModalBasic
              show={showModal}
              onClose={openCloseModal}
              title={titleModal}
              children={contentModal}
            />
          )}
        </>
      )}
    </>
  );
}
