import ReactDataGrid from "@inovua/reactdatagrid-community";
import filter from "@inovua/reactdatagrid-community/filter";
import "@inovua/reactdatagrid-community/index.css";
import { Box, IconButton, makeStyles } from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import ChecklistIcon from '@mui/icons-material/Checklist';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DownloadIcon from "@mui/icons-material/Download";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import GridViewIcon from '@mui/icons-material/GridView';
import HistoryIcon from '@mui/icons-material/History';
import DataArrayIcon from '@mui/icons-material/DataArray';
import HttpsIcon from '@mui/icons-material/Https';
import React, { useCallback, useState } from "react";
import * as XLSX from "xlsx";
import { useVerificaciones } from "../../../hooks";
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import AddIcon from '@mui/icons-material/Add';
import "./stylesTabla.css";
import PreviewIcon from '@mui/icons-material/Preview';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import GradingIcon from '@mui/icons-material/Grading';
import AdfScannerIcon from "@mui/icons-material/AdfScanner";

const gridStyle = { minHeight: 720, marginTop: 10 };

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: "20px",
    backgroundColor: "#fff",
    borderRadius: 10,
    boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.2)",
    padding: theme.spacing(2),
  },
}));

export function DataGridBasic(props) {

  const [filterData, setFilterData] = useState([]);
  const { addBaseVer, addBaseVehVer } = useVerificaciones()

  const {
    data,
    columns,
    updateData,
    onDeleteData,
    visualizacionData,
    cambioTabla,
    id,
    filterValue,
    cargaMasiva,
    cargaFotos,
    gruposPermisos,
    vehChecklist,
    viewVehChecklist,
    viewVehChecklistHistory,
    copyData,
    copyDataVeh,
    tcsProdUpdt,
    groups,
    assignTo,
    showTicket,
    handleAddEditBiometricData,
    handleDataVisualizer,
    ExcelColumns,
    mapeoData,
    handlePermisos,
    handleDocsUpload,
  } = props;

  const classes = useStyles();

  const onFilterValueChange = useCallback((filterValue) => {
    const filteredData = filter(data, filterValue);
    setFilterData(filteredData);

  }, []);

  const exportToExcel = () => {
    const columnsToExport = ExcelColumns ? ExcelColumns.map(column => column.name) : columns.map(column => column.name);
    const dataToExport = filterData.length > 0 ? filterData : data;

    const filteredData = dataToExport.map(item => {
      const newItem = {};
      columnsToExport.forEach(columnName => {
        // Verificar si la columna es "v_restante" y realizar la operación especial
        if (columnName === 'v_restante') {
          newItem[columnName] = item[columnName] / 100; // Dividir por 100 para quitar dos ceros
        } else {
          newItem[columnName] = item[columnName];
        }
      });
      return newItem;
    });

    if (filteredData.length > 0) {
      const worksheet = XLSX.utils.json_to_sheet(filteredData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "data.xlsx");
    } else {
      console.log('No hay datos para exportar');
    }
  };

  const newColumns = [
    ...columns,

    {
      header: "Opciones",
      id: "actions",

      render: ({ data }) => (
        <>
          {showTicket && (
            <Tooltip title="Ver tiquete generado" arrow>
              <IconButton style={{ padding: '2px' }} onClick={() => showTicket(data)}>
                <PreviewIcon />
              </IconButton>
            </Tooltip>
          )}
          {gruposPermisos && (
            <Tooltip title="Permisos" arrow>
              <IconButton onClick={() => gruposPermisos(data)}>
                <HttpsIcon />
              </IconButton>
            </Tooltip>
          )}
          {visualizacionData && (
            <Tooltip title="Visualizar datos" arrow>
              <IconButton style={{ padding: '2px' }} onClick={() => visualizacionData(data)}>
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
          )}
          {updateData && (
            <Tooltip title="Actualizar datos" arrow>
              <IconButton style={{ padding: '2px' }} onClick={() => updateData(data)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {onDeleteData && (
            <Tooltip title="Eliminar datos" arrow>
              <IconButton style={{ padding: '2px' }} onClick={() => onDeleteData(data)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
          {vehChecklist && (
            <Tooltip title="Realizar inspección" arrow>
              <IconButton style={{ padding: '2px' }} onClick={() => { vehChecklist(data) }}>
                <DoneAllIcon />
              </IconButton>
            </Tooltip>
          )}
          {mapeoData && (
            <Tooltip title="Revisar mapeo" arrow>
              <IconButton style={{ padding: '2px' }} onClick={() => {mapeoData(data)}}>
                <DataArrayIcon />
              </IconButton>
            </Tooltip>
          )}
          {viewVehChecklist && (
            <Tooltip title="Ver inspección actual" arrow>
              <IconButton style={{ padding: '2px' }} onClick={() => viewVehChecklist(data)}>
                <ChecklistIcon />
              </IconButton>
            </Tooltip>
          )}
          {viewVehChecklistHistory && (
            <Tooltip title="Ver historial de inspecciones" arrow>
              <IconButton style={{ padding: '2px' }} onClick={() => viewVehChecklistHistory(data)}>
                <HistoryIcon />
              </IconButton>
            </Tooltip>
          )}
          {copyData && (
            <Tooltip title="Crear nuevo formulario a partir de este" arrow>
              <IconButton onClick={() => {
                data.active = false
                addBaseVer(data)
                window.location.reload()
              }}>
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          )}
          {copyDataVeh && (
            <Tooltip title="Crear nuevo formulario a partir de este" arrow>
              <IconButton onClick={() => {
                data.active = false
                addBaseVehVer(data)
                window.location.reload()
              }}>
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          )}
          {tcsProdUpdt && (
            <Tooltip title="Traer productos de la TCS3000" arrow>
              <IconButton style={{ padding: '2px' }} onClick={() => tcsProdUpdt(data.id_controlador, data)}>
                <BrowserUpdatedIcon />
              </IconButton>
            </Tooltip>
          )}
          {assignTo && (
            <Tooltip title="Asignar controlador a un tanque o vehículo" arrow>
              <IconButton style={{ padding: '2px' }} onClick={() => assignTo(data)}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          )}
          {handleAddEditBiometricData && (
            <Tooltip title="Agregar conductor o editar información en el dispositivo biométrico" arrow>
              <IconButton style={{ padding: '2px' }} onClick={() => handleAddEditBiometricData(data)}>
                <GroupAddIcon />
              </IconButton>
            </Tooltip>
          )}
          {handleDataVisualizer && (
            <Tooltip title="Visualizar los datos del sensor" arrow>
              <IconButton style={{ padding: '2px' }} onClick={() => handleDataVisualizer(data)}>
                <EqualizerIcon />
              </IconButton>
            </Tooltip>
          )}
          {handlePermisos && (
            <Tooltip title="Permisos" arrow>
              <IconButton style={{ padding: '2px' }} onClick={() => handlePermisos(data)}>
                <HttpsIcon />
              </IconButton>
            </Tooltip>
          )}
          {handleDocsUpload && (
            <Tooltip title="Documentos" arrow>
              <IconButton style={{ padding: '2px' }} onClick={() => handleDocsUpload(data.id_operador)}>
                <AdfScannerIcon />
              </IconButton>
            </Tooltip>
          )}
        </>
      ),
      width: 130, // anchura fija para la columna
    },
  ];


  return (
    <Box className={classes.tableContainer}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        {cambioTabla && (
          <Tooltip title="Recargar o reorganizar columnas" arrow>
            <IconButton onClick={cambioTabla} style={{ marginRight: '8px', fontSize: '1.2rem' }}>
              <GridViewIcon /> {/* Icono de recargar (cambia ReloadIcon por el nombre del icono que desees utilizar) */}
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Descargar excel" arrow>
          <IconButton onClick={exportToExcel} style={{ marginRight: '8px', fontSize: '1.2rem' }}>
            <DownloadIcon />
          </IconButton>
        </Tooltip>
        {cargaMasiva && (
          <Tooltip title="Cargue masivo" arrow>
            <IconButton onClick={cargaMasiva} style={{ marginRight: '8px', fontSize: '1.2rem' }}>
              <DriveFolderUploadIcon />
            </IconButton>
          </Tooltip>
        )}
        {cargaFotos && (
          <IconButton onClick={cargaFotos} style={{ fontSize: '1.2rem' }}>
            <CameraswitchIcon />
          </IconButton>
        )}
      </div>

      <ReactDataGrid
        idProperty={id}
        columns={newColumns}
        dataSource={data}
        style={gridStyle}
        groups={groups}
        className="custom-data-grid"
        pagination
        paginationPageSize={10}
        paginationToolbarLabels={{
          prev: "Anterior",
          next: "Siguiente",
          page: "Página",
          of: "de",
          rows: "filas",
        }}
        defaultFilterValue={filterValue}
        onFilterValueChange={onFilterValueChange}
        onDataSourceChange={({ skip, limit }) => {
          console.log(`Pagina actual: ${skip / limit + 1}`);
        }}
      />
    </Box>
  );
}
