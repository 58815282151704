import { useFormik, Formik } from "formik";

import { map } from "lodash";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";

import * as Yup from "yup";
import { useRemolques, useVehiculos, useProductos } from "../../../hooks";
import { alertas } from "../../Comons/Alertas/alertas";
import { VehiculosSelect, productoIdSelect } from "../../Comons";

import { FormControl, InputLabel, Select, FormControlLabel, Typography, Autocomplete } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import FormHelperText from '@mui/material/FormHelperText';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';

export function AddEditRemolques(props) {
  /*
  Componente

  -componente de visualizacion del modal de edicion y creacion para Remolques
  
  -se crea y valida mediante formik y yup
  -Se obtienen datos para dropdown mediante hooks
  -se obtiene los datos (para edicion) y funciones correspondientes mediante props desde su PageAdmin
   */
  const { Successful } = alertas();
  const { onClose, onRefetch, Remolques } = props;
  const { addRemolques, updateRemolques } = useRemolques();
  const { VehiculosDB, getVehiculosDB } = useVehiculos();
  const { ProductoDB, getProductoDB } = useProductos();
  const [tablasFormatV, setTablasTFormatT] = useState([]);
  const [Productos, setProductos] = useState([]);

  const [errors, setErrors] = React.useState({
    id_vehiculo: false,
    'compartimientos.producto': false,
  });

  useEffect(() => {
    getVehiculosDB();
    getProductoDB();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTablasTFormatT(VehiculosSelect(VehiculosDB));
    setProductos(productoIdSelect(ProductoDB));
  }, [VehiculosDB, ProductoDB]);

  const formik = useFormik({
    // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
    initialValues: initialValues(Remolques),
    // Establece la validación del esquema utilizando Yup, si se proporciona un objeto  se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
    validationSchema: Yup.object(Remolques ? updateSchame() : newSchame()),
    // Desactiva la validación al cambiar los valores del formulario
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        // Si se proporciona un objeto se actualiza, de lo contrario se crea uno nuevo
        if (Remolques) await updateRemolques(Remolques.id_remolque, formValue);
        else await addRemolques(formValue);
        Successful();
        onRefetch();
        onClose();
      } catch (error) {
        // Muestra un mensaje de error si ocurre algún problema
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });

  const divStyle = {
    textAlign: "left",
  };

  const handleAutocompleteChange = (name, value) => {
    formik.setFieldValue(name, value?.value || '');
    setErrors((prevErrors) => ({ ...prevErrors, [name]: !value }));
  };

  return (
    <>
      <Formik initialValues={initialValues()}>
        <form className="add-edit-secciones-form" style={divStyle} onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            {/* fila 1 */}
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                required
                label="Número de placa remolque"
                name="placa_remolq"
                placeholder="Placa del remolque"
                value={formik.values.placa_remolq}
                onChange={formik.handleChange}
                error={formik.errors.placa_remolq}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                required
                label="Capacidad total del remolque"
                name="capacidad"
                placeholder="Capacidad"
                value={formik.values.capacidad}
                onChange={formik.handleChange}
                error={formik.errors.capacidad}
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                id="veh"
                name="id_vehiculo"
                label="Vehículo asociado"
                fullWidth
                value={tablasFormatV.find((option) => option.value === formik.values.id_vehiculo) || null}
                onChange={(event, value) => handleAutocompleteChange('id_vehiculo', value)}
                options={tablasFormatV || []}
                getOptionLabel={(option) => option.text || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Vehículo asociado"
                    error={errors.id_vehiculo}
                    helperText={errors.id_vehiculo ? 'Debe seleccionar un vehículo' : ''}
                    variant="outlined"
                  />
                )}
              />

              {/* <FormControl fullWidth required>
                <InputLabel id="vehlb">Vehículo asociado</InputLabel>
                <Select
                  labelId="vehlb"
                  id="veh"
                  label="Vehículo asociado"
                  fullWidth
                  value={formik.values.id_vehiculo}
                  onChange={(event) => {
                    formik.setFieldValue("tipo_veh", event.target.value);
                  }}
                  error={formik.errors.id_vehiculo}
                >
                  {tablasFormatV.map((opt) => (
                    <MenuItem key={opt.key} value={opt.value}>
                      {opt.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
            </Grid>

            {/* fila 2 */}
            <Grid item xs={12} sm={12}>
              <div>
                <Typography variant="h6">Compartimentos</Typography>
              </div>
              <br />
              {formik.values.compartimientos.map((compartimineto, index) => (
                <div key={index}>
                  <Grid container spacing={3}>
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        required
                        label={`Capacidad compartimiento ${index + 1}`}
                        name={`compartimientos[${index}].capacidad`}
                        placeholder={"Capacidad"}
                        value={compartimineto.capacidad}
                        onChange={formik.handleChange}
                        error={formik.errors[`compartimientos[${index}].capacidad`]}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <Autocomplete
                        id={`prodc${index}`}
                        options={Productos}
                        getOptionLabel={(option) => option.text || ''}
                        value={Productos.find((option) => option.value === compartimineto.producto) || null}
                        onChange={(event, value) => {
                          formik.setFieldValue(
                            `compartimientos[${index}].producto`, value?.value || ''
                          );

                          setErrors((prevErrors) => ({ ...prevErrors, [`compartimientos${index}.producto`]: !value }));
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={"Combustible del compartimento " + (index + 1)}
                            variant="outlined"
                            error={errors[`compartimientos${index}.producto`]}
                            helperText={errors[`compartimientos${index}.producto`] ? 'Debe seleccionar el tipo de combustible' : ''}
                          />
                        )}
                      />
                      {/* <FormControl fullWidth required>
                        <InputLabel id="prodclb">{`Producto compartimiento ${index + 1}`}</InputLabel>
                        <Select
                          fullWidth
                          labelId="prodclb"
                          id="prodc"
                          label={`Producto compartimiento ${index + 1}`}
                          value={compartimineto.producto}
                          error={formik.errors[`compartimientos[${index}].producto`]}
                          onChange={(event) =>
                            formik.setFieldValue(
                              `compartimientos[${index}].producto`, event.target.value
                            )
                          }
                        >
                          {Productos.map((opt) => (
                            <MenuItem key={opt.key} value={opt.value}>
                              {opt.text}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl> */}
                    </Grid>

                    <Grid item xs={1}>
                      <Tooltip title="Eliminar variable" arrow>
                        <Button
                          variant="outlined"
                          color="secondary"
                          style={{
                            height: '100%', display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '0',
                            margin: '0',
                            color: 'rgba(252, 0, 0, 0.8)',
                            border: '1px solid rgba(252, 0, 0, 0.8)',
                          }}
                          onClick={() =>
                            formik.setFieldValue(
                              "compartimientos",
                              formik.values.compartimientos.filter(
                                (_, i) => i !== index
                              )
                            )
                          }
                        >
                          <DeleteIcon style={{ margin: '0' }} />
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <br />
                </div>
              ))}
              <br></br>
              <Tooltip title="Agregar compartimento" arrow>
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  style={{
                    color: 'rgb(25, 118, 210)',
                    border: '1px solid rgb(25, 118, 210)',
                    '&:hover': {
                      color: 'rgba(221, 223, 221, 0.62)',
                      border: '1px solid rgba(221, 223, 221, 0.62)',
                    }
                  }}
                  onClick={() =>
                    formik.setFieldValue("compartimientos", [
                      ...formik.values.compartimientos,
                      { capacidad: "", producto: "" },
                    ])
                  }
                >
                  <AddIcon />
                </Button>
              </Tooltip>
            </Grid>

            {/* fila 3 */}
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Descripción"
                name="descrip"
                placeholder="Descripción opcional"
                value={formik.values.descrip}
                onChange={formik.handleChange}
                error={formik.errors.descrip}
              />
            </Grid>

            {/* fila 4 */}
            <Grid item xs={12} sm={12}>
              <div>
                <Typography variant="h6">Documentos extras</Typography>
              </div>
              <br></br>
              {formik.values.documentos.map((variable, index) => (
                <div key={index}>
                  <Grid container spacing={3}>
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        label={`tipo ${index + 1}`}
                        name={`documentos[${index}].tipo`}
                        placeholder={"tipo "}
                        value={variable.tipo}
                        onChange={formik.handleChange}
                        error={formik.errors[`documentos[${index}].tipo`]}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        label={`Numero de documento ${index + 1}`}
                        name={`documentos[${index}].numeroDoc`}
                        placeholder={"Volumen"}
                        value={variable.numeroDoc}
                        onChange={formik.handleChange}
                        error={formik.errors[`documentos[${index}].numeroDoc`]}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Tooltip title="Eliminar variable" arrow>
                        <Button
                          variant="outlined"
                          color="secondary"
                          style={{
                            height: '100%', display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '0',
                            margin: '0',
                            color: 'rgba(252, 0, 0, 0.8)',
                            border: '1px solid rgba(252, 0, 0, 0.8)',
                          }}
                          onClick={() =>
                            formik.setFieldValue(
                              "documentos",
                              formik.values.documentos.filter((_, i) => i !== index)
                            )
                          }
                        >
                          <DeleteIcon style={{ margin: '0' }} />
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <br />
                </div>
              ))}
              <br></br>
              <Tooltip title="Agregar documento" arrow>
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  style={{
                    color: 'rgb(25, 118, 210)',
                    border: '1px solid rgb(25, 118, 210)',
                    '&:hover': {
                      color: 'rgba(221, 223, 221, 0.62)',
                      border: '1px solid rgba(221, 223, 221, 0.62)',
                    }
                  }}
                  onClick={() =>
                    formik.setFieldValue("documentos", [
                      ...formik.values.documentos,
                      { tipo: "", numeroDoc: "" },
                    ])
                  }
                >
                  <AddIcon />
                </Button>
              </Tooltip>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Button type="submit" variant="contained" color="error" className="btn btn-danger" fullWidth>
                {Remolques ? "Actualizar" : "Crear"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Formik>
    </>
  );
}


function initialValues(data) {
  return {
    placa_remolq: data?.placa_remolq || "",
    capacidad: data?.capacidad || "",
    compartimientos: data?.compartimientos || [],
    descrip: data?.descrip || "",
    documentos: data?.documentos || [],
    id_vehiculo: data?.id_vehiculo || "",
  };
}

function newSchame() {
  return {
    placa_remolq: Yup.string().required(true),
    capacidad: Yup.number().required(true),
    // compartimientos: Yup.number().required(true),
    // documentos: Yup.object({
    //   tipo: Yup.string().required(true),
    //   numeroDoc: Yup.number().required(true),
    // }),
    id_vehiculo: Yup.number().required(true),
    descrip: Yup.string(),
  };
}

function updateSchame() {
  return {
    placa_remolq: Yup.string().required(true),
    capacidad: Yup.number().required(true),
    // compartimientos: Yup.number().required(true),
    // documentos: Yup.object({
    //   tipo: Yup.string().required(true),
    //   numeroDoc: Yup.number().required(true),
    // }),
    id_vehiculo: Yup.number().required(true),
    descrip: Yup.string(),
  };
}
