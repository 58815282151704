import { BASE_API } from "../../utils/constants";
import { makeRequest } from "../Request";
const BASE_URL = `${BASE_API}/`;

// Función para obtener los datos 
export async function getProveedoresApi(token, schema) {
  const url = `${BASE_URL}${schema}/proveedores/proveedoresA`;
  return makeRequest(url, "GET", token);
}

// Función para agregar un nuevo dato
export async function addProveedoresApi(data, token, schema) {
  const url = `${BASE_URL}${schema}/proveedores/proveedoresA/`;
  return makeRequest(url, "POST", token, data);
}

// Función para actualizar los datos 
export async function updateProveedoresApi(id, data, token, schema) {
  const url = `${BASE_URL}${schema}/proveedores/proveedoresA/${id}/`;
  return makeRequest(url, "PUT", token, data);
}

// Función para eliminar 
export async function deleteProveedoresApi(id, token, schema) {
  const url = `${BASE_URL}${schema}/proveedores/proveedoresA/${id}/`;
  return makeRequest(url, "DELETE", token);
}