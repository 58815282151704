import { useState } from "react";
import {
  getAsignacionTanquesApi,
  addAsignacionTanquesApi,
  updateAsignacionTanquesApi,
  deleteAsignacionTanquesApi,
} from "../../api/tanques";

import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "..";

export function useAsignaciones() {
  const [state, setState] = useState({
    loading: true,
    error: null,
    tanques: null,
  });

  const { loading, error, tanques } = state;

  const { auth, logout } = useAuth();
  const { unauthorized, SessionExpired, errores, unauthorizedModal } = alertas();

  const handleResponse = (response) => {
    if (response.status === 401) {
      SessionExpired();
      logout();
    } else if (response.status === 403) {
      unauthorized();
    }
  };

  const getAsignacionesTanques = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getAsignacionTanquesApi(auth.token, auth.me.tenant, id);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, tanques: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addAsignacionesTanques = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addAsignacionTanquesApi(data, auth.token, auth.me.tenant);
      if (response.status === 400) {
        if (result?.codigo_tanque) {
          throw new Error("El codigo de tanque que ingreso ya cuenta con un registro activo");
        }
        if (result?.nombre_tanque) {
          throw new Error("El nombre ya se encuentra asignado a otro tanque");
        }
        if (result?.id_tabla_aforo) {
          throw new Error("La tabla de aforo debe ser única para cada tanque");
        } else {
          errores();
        }
      }
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateAsignacionesTanques = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateAsignacionTanquesApi(id, data, auth.token, auth.me.tenant);
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteAsignacionesTanques = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteAsignacionTanquesApi(id, auth.token, auth.me.tenant);
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    tanques,
    getAsignacionesTanques,
    addAsignacionesTanques,
    updateAsignacionesTanques,
    deleteAsignacionesTanques,
  };
}
