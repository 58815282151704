import React, { useState, useCallback } from "react";
import Swal from "sweetalert2";
import { AddEditConductores, DocumentosConductores, AddEditHuellas } from "../../../Components/Client";
import { VisualizacionGeneralDocumentosForm } from "../../../Components/Comons";
import { useConductor } from "../../../hooks";
//import Avatar from '@mui/material/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import ArticleIcon from '@mui/icons-material/Article';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { IconButton } from "@material-ui/core";


export function Funciones() {
  const { deleteConductor } = useConductor();
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);

  const handleViews = useCallback((data) => {
    setContentModal(
      <VisualizacionGeneralDocumentosForm
        nombre={"Información del conductor"}
        visualizacion={data}

      />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const handleAdd = useCallback(() => {
    setTitleModal("Nuevo conductor");
    setContentModal(
      <AddEditConductores onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const handleUpdate = useCallback((data) => {
    setTitleModal("Actualizar conductor");
    setContentModal(
      <AddEditConductores
        onClose={openCloseModal}
        onRefetch={onRefetch}
        Conductores={data}
      />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const handleDelete = useCallback(async (data) => {
    const result = await Swal.fire({
      title: "Estas seguro?",
      text: "No podrás revertir esta acción!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si, eliminar!",
      cancelButtonText: "No, cancelar!",
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      await deleteConductor(data.id_conductor);
      Swal.fire("Eliminado!", "El dato ha sido eliminado.", "success");
      onRefetch();
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelado", "Operación cancelada", "error");
    }
  }, [deleteConductor, onRefetch]);

  const handleDocumento = useCallback((data) => {
    setTitleModal("Documentos conductor");
    setContentModal(
      <DocumentosConductores data={data} />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const handleAddEditBiometricData = useCallback((data) => {
    setTitleModal("Información del conductor en el dispositivo de acceso");
    setContentModal(
      <AddEditHuellas
        onClose={openCloseModal}
        onRefetch={onRefetch}
        Conductor={data}
      />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const columns = [
    // {
    //   name: 'foto',
    //   header: 'Foto',
    //   render: ({ value }) => <Avatar alt="nn" src={value} />,
    //   maxWidth: 75,
    //   style: ({ data }) => ({
    //     color: data.cumplimiento_documentacion === false ? "red" : "inherit",
    //   }),

    // },
    {
      name: 'nombre',
      header: 'Nombres',
      maxWidth: 1000, defaultFlex: 1,
      style: ({ data }) => ({
        color: data.cumplimiento_documentacion === false ? "red" : "inherit",
      }),
    },
    {
      name: 'apellidos',
      header: 'Apellidos',
      maxWidth: 1000, defaultFlex: 1
      ,
      style: ({ data }) => ({
        color: data.cumplimiento_documentacion === false ? "red" : "inherit",
      }),
    },
    {
      name: 'email',
      header: 'Email',
      maxWidth: 1000, defaultFlex: 1,
      style: ({ data }) => ({
        color: data.cumplimiento_documentacion === false ? "red" : "inherit",
      }),
    },
    {
      name: 'n_documento',
      header: 'Número de documento',
      maxWidth: 1000, defaultFlex: 1,
      style: ({ data }) => ({
        color: data.cumplimiento_documentacion === false ? "red" : "inherit",
      }),
    },
    {
      name: 'n_licencia',
      header: 'N. Licencia',
      maxWidth: 1000, defaultFlex: 1,
      style: ({ data }) => ({
        color: data.cumplimiento_documentacion === false ? "red" : "inherit",
      }),
    },
    {
      name: 'fecha_vencimineto_licencia',
      header: 'Vencimiento de licencia',
      maxWidth: 1000, defaultFlex: 1,
      style: ({ data }) => ({
        color: data.cumplimiento_documentacion === false ? "red" : "inherit",
      }),

    },
    {
      name: 'celular',
      header: 'Celular',
      maxWidth: 1000, defaultFlex: 1,
      style: ({ data }) => ({
        color: data.cumplimiento_documentacion === false ? "red" : "inherit",
      }),
    },
    {
      header: "Extras",
      render: ({ data }) => (
        <>
          <Tooltip title="Documentos" arrow>
            <IconButton style={{ padding: '2px' }} onClick={() => handleDocumento(data.id_conductor)}>
              <ArticleIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Agregar conductor o editar información en el dispositivo biométrico" arrow>
            <IconButton style={{ padding: '2px' }} onClick={() => handleAddEditBiometricData(data)}>
              <GroupAddIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
      maxWidth: 75,
      defaultFlex: 1,
    }
  ];

  return {
    handleAdd,
    handleDelete,
    handleUpdate,
    openCloseModal,
    handleViews,
    columns,
    refetch,
    contentModal,
    titleModal,
    showModal,
  };
}
