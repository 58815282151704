import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { theme } from "../../theme";
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 70,
      color: theme.default.primary.black2,
    }}
    spin
  />
);
export const Spinner = () => (
  <Spin
    indicator={antIcon}
    style={{
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  />
);
