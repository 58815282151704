
import { BASE_API } from "../../utils/constants";
import { makeRequest } from "../Request";
const BASE_URL = `${BASE_API}/`;



// Función para actualizar los datos de un cliente existente
export async function getScriptApi(token, schema) {
  const url = `${BASE_URL}${schema}/multicliente/script/`;
  return makeRequest(url, "GET", token);
}

// Función para actualizar los datos de un cliente existente
export async function updateScriptApi(id, data, token, schema) {
  const url = `${BASE_URL}${schema}/multicliente/script/${id}/`;
  return makeRequest(url, "PUT", token, data);
}


