export const columns = [
  {
    name: 'nombre',
    header: 'Nombre',
    defaultWidth: 80,
    type: 'string',
    maxWidth: 1000, defaultFlex: 1
  },
  {
    name: 'longitud',
    header: 'Unidad de longitud',
    maxWidth: 1000, defaultFlex: 1
  },
  {
    name: 'volumen',
    header: 'Unidad de volumen',
    maxWidth: 1000, defaultFlex: 1
  },
  {
    name: 'temperatura',
    header: 'Unidad de temperatura',
    maxWidth: 1000, defaultFlex: 1
  }
];


  export const filterValue = [
    { name: 'nombre', operator: 'startsWith', type: 'string', value: '' },
    { name: 'longitud', operator: 'startsWith', type: 'string', value: '' },
    { name: 'volumen', operator: 'startsWith', type: 'string', value: '' },
    { name: 'temperatura', operator: 'startsWith', type: 'string', value: '' }
  ];