export const columns = [
    {
        header: "Placa",
        name: "placa_obj",
        defaultFlex: 2, // Adjust the flex value as needed
        minWidth: 150,
    },
    {
        header: "Origen",
        name: "plantaOrigenNombre",
        defaultFlex: 2, // Adjust the flex value as needed
        minWidth: 150,
    },
    {
        header: "Destino",
        name: "plantaDestinoNombre",
        defaultFlex: 2, // Adjust the flex value as needed
        minWidth: 150,
    },
    {
        header: "Tipo de combustible",
        name: "tipoCombustible",
        defaultFlex: 2, // Adjust the flex value as needed
        minWidth: 150,
    },
    {
        header: "Emisión",
        name: "emision",
        defaultFlex: 2, // Adjust the flex value as needed
        minWidth: 150,
    }
]

export const filterValue = [
    { name: 'placa_obj', operator: 'startsWith', type: 'string', value: '' },
    { name: 'plantaOrigenNombre', operator: 'startsWith', type: 'string', value: '' },
    { name: 'plantaDestinoNombre', operator: 'startsWith', type: 'string', value: '' },
    { name: 'tipoCombustible', operator: 'startsWith', type: 'string', value: '' },
    { name: 'emision', operator: 'startsWith', type: 'string', value: '' },
]