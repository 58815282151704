import React, { useEffect, useState } from "react";
import { HeaderPage } from "../../../Components/Client";
import { DataGridBasic, ModalBasic } from "../../../Components/Comons";

import { Funciones } from "../ControladoresPage/Funciones";
import { useControladores } from "../../../hooks";
import { columns, filterValue } from "../ControladoresPage/ControladoresFormat";

import CircularProgress from '@mui/material/CircularProgress';

export function OtrosDispAdmin() {
    const { loading, Controladores, getControladores } = useControladores();
    const {
        handleAddOtrosDisp,
        handleEditOtrosDisp,
        handleDeleteControladores,
        refetch,
        contentModal,
        showModal,
        openCloseModal,
        titleModal,
    } = Funciones();

    const [filteredCtrl, setFilteredCtrl] = useState([])

    useEffect(() => {
        getControladores();
    }, [refetch]);

    useEffect(() => {
        const filtered = Controladores?.filter(item => item.tipo === "cam" || item.tipo === "bio_cam")
        setFilteredCtrl(filtered);
    }, [Controladores])

    const MemoizedModalBasic = React.memo(ModalBasic);

    return (
        <>
            <HeaderPage
                title="Otros dispositivos"
                btnTitle="Nuevo dispositivo"
                icono="plus square icon"
                btnClick={handleAddOtrosDisp}
            />

            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </div>
            ) : (
                <DataGridBasic
                    data={filteredCtrl || []}
                    title="Otros dispositivos"
                    id="id_controlador"
                    filterValue={filterValue}
                    columns={columns}
                    updateData={handleEditOtrosDisp}
                    onDeleteData={handleDeleteControladores}
                />
            )}

            {showModal && (
                <MemoizedModalBasic
                    show={showModal}
                    onClose={openCloseModal}
                    title={titleModal}
                    children={contentModal}
                />
            )}
        </>
    )
}