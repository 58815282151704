

  export const filterValue = [

    { name: 'nombres', operator: 'startsWith', type: 'string', value: '' },
    { name: 'apellidos', operator: 'startsWith', type: 'string', value: '' },
    { name: 'identificacion_pers', operator: 'startsWith', type: 'string', value: '' },
    { name: 'email', operator: 'startsWith', type: 'string', value: '' },
    { name: 'celular', operator: 'startsWith', type: 'string', value: '' },
    
  ];