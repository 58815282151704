import {
  Switch
} from "@mui/material";
import { alpha, styled } from '@mui/material/styles';

// Colores personalizados
const grayColor = "#BEBEBE";
const trackColor = "#444444";
const switchBackgroundColor = "#FFFFFF"; // Cambia a tu color de fondo deseado

export const GraySwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: grayColor,
    '&:hover': {
      backgroundColor: alpha(grayColor, theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: grayColor,
  },
  '& .MuiSwitch-track': {
    backgroundColor: trackColor,
  },
  '& .MuiSwitch-root': {
    width: 48, // Ajusta el ancho del switch si es necesario
    height: 28, // Ajusta la altura del switch si es necesario
    padding: 0, // Puedes ajustar el relleno según tus necesidades
    '& .MuiSwitch-thumb': {
      backgroundColor: switchBackgroundColor,
      boxShadow: 'none',
      width: 24,
      height: 24,
      marginTop: 2,
    },
    '& .Mui-focusVisible': {
      boxShadow: 'none',
    },
  },
}));
