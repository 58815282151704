import { makeStyles, } from "@material-ui/core";
import { Autocomplete } from "@mui/material"
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { Formik, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Tooltip from '@material-ui/core/Tooltip';
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';

import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  useProductoMezcla,
  useProductoTanque,
  useTanques,
} from "../../../../hooks";
import { alertas } from "../../../Comons/Alertas/alertas";

const useStyles = makeStyles((theme) => ({
  datePicker: {
    marginBottom: theme.spacing(2), // Espacio inferior entre DatePicker y los campos
  },
}));
export function AddEditProductosTanque(props) {
  const classes = useStyles();
  const { Successful } = alertas();
  const { onClose, onRefetch, Productos } = props;
  const { ProductoMezclaDB, getProductoMezclaDB } = useProductoMezcla();
  const { tanquesDB, getTanquesDB } = useTanques();
  const { addProductoTanque, updateProductoTanque } = useProductoTanque();
  const [currentDateTime, setCurrentDateTime] = useState("");
  const [showAllDays, setShowAllDays] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    getTanquesDB();
    getProductoMezclaDB();
  }, []);

  useEffect(() => {
    const date = new Date();
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
    const formattedTime = `${date.getHours().toString().padStart(2, "0")}:${date
      .getMinutes()
      .toString()
      .padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}`;
    const dateTime = `${formattedDate} ${formattedTime}`;
    setCurrentDateTime(dateTime);
  }, []);

  const formik = useFormik({
    initialValues: initialValues(Productos),
    validationSchema: Yup.object(newSchema()),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        if (Productos) await updateProductoTanque(Productos.id, formValue);
        else await addProductoTanque(formValue);
        Successful();
        onClose();
        onRefetch();
      } catch (error) {
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });

  return (
    <Formik initialValues={initialValues()}>
      <form className="add-edit-secciones-form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          {/* fila 1 */}
          <Grid item xs={12} sm={6}>
            <TextField
              id="codigo_producto"
              name="codigo_producto"
              label="Código de Producto"
              variant="outlined"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.codigo_producto}
              error={
                formik.touched.codigo_producto &&
                Boolean(formik.errors.codigo_producto)
              }
              helperText={
                formik.touched.codigo_producto && formik.errors.codigo_producto
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="nombre"
              name="nombre"
              label="Identificacion relacion"
              variant="outlined"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.nombre}
              error={formik.touched.nombre && Boolean(formik.errors.nombre)}
              helperText={formik.touched.nombre && formik.errors.nombre}
            />
          </Grid>

          {/* fila 2 */}
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="productos"
              options={ProductoMezclaDB}
              getOptionLabel={(option) => option.nombre}
              value={
                ProductoMezclaDB?.find(
                  (opt) => opt.id === formik.values?.productos
                ) || null
              }
              onChange={(_, value) => {
                formik.setFieldValue("productos", value?.id || "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Productos"
                  fullWidth
                  variant="outlined"
                  error={formik.errors.productos && Boolean(formik.errors.productos)}
                  helperText={formik.touched.productos && formik.errors.productos}
                  sx={{ background: "#fcfcfc" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="tanques"
              options={tanquesDB}
              getOptionLabel={(option) => option.nombre_tanque}
              value={
                tanquesDB?.find(
                  (opt) => opt.id_tanque === formik.values?.tanques
                ) || null
              }
              onChange={(_, value) => {
                formik.setFieldValue("tanques", value?.id_tanque || "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tanques"
                  fullWidth
                  variant="outlined"
                  error={formik.errors.tanques && Boolean(formik.errors.tanques)}
                  helperText={formik.touched.tanques && formik.errors.tanques}
                  sx={{ background: "#fcfcfc" }}
                />
              )}
            />
          </Grid>

          {/* fila 3 */}
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  API - BSW
                </Typography>
                {formik.values.api_bsw?.length > 0 && (
                  <DatePicker
                    className={classes.datePicker} // Aplica el margen inferior aquí
                    label="Fecha"
                    value={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                )}
                {formik.values.api_bsw
                  .filter((variable) => {
                    const variableDate = new Date(variable.date);
                    const currentDate = new Date(currentDateTime);
                    return (
                      showAllDays ||
                      variableDate.toISOString().split("T")[0] ===
                      selectedDate.toISOString().split("T")[0]
                    );
                  })
                  .reverse()
                  .map((variable, index) => {
                    const reverseIndex =
                      formik.values.api_bsw.length - index - 1;
                    return (
                      <>
                        <Grid container spacing={2} key={reverseIndex}>
                          <Grid item xs={12} sm={5}>
                            <TextField
                              required
                              variant="outlined"
                              label={`API ${reverseIndex + 1}`}
                              name={`api_bsw[${reverseIndex}].api`}
                              fullWidth
                              placeholder="Valor API"
                              value={variable.api}
                              onChange={formik.handleChange}
                              error={
                                formik.errors[`api_bsw[${reverseIndex}].api`]
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={5}>
                            <TextField
                              required
                              variant="outlined"
                              label={`BSW ${reverseIndex + 1}`}
                              name={`api_bsw[${reverseIndex}].bsw`}
                              fullWidth
                              placeholder="Valor BSW"
                              value={variable.bsw}
                              onChange={formik.handleChange}
                              error={
                                formik.errors[`api_bsw[${reverseIndex}].bsw`]
                              }
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <Tooltip title="Eliminar variable" arrow>
                              <Button
                                variant="outlined"
                                color="secondary"
                                style={{
                                  height: '100%', display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  padding: '0',
                                  margin: '0',
                                  color: 'rgba(252, 0, 0, 0.8)',
                                  border: '1px solid rgba(252, 0, 0, 0.8)',
                                }}
                                onClick={() =>
                                  formik.setFieldValue(
                                    "api_bsw",
                                    formik.values.api_bsw.filter(
                                      (_, i) => i !== reverseIndex
                                    )
                                  )
                                }
                              >
                                <DeleteIcon style={{ margin: '0' }} />
                              </Button>
                            </Tooltip>
                          </Grid>
                        </Grid>
                        <br />
                      </>
                    );
                  })}
                <Grid item xs={12}>
                  <Tooltip title="Agregar API-BSW" arrow>
                    <Button
                      variant="outlined"
                      color="secondary"
                      fullWidth
                      style={{
                        color: 'rgb(25, 118, 210)',
                        border: '1px solid rgb(25, 118, 210)',
                        '&:hover': {
                          color: 'rgba(221, 223, 221, 0.62)',
                          border: '1px solid rgba(221, 223, 221, 0.62)',
                        }
                      }}
                      onClick={() =>
                        formik.setFieldValue("api_bsw", [
                          ...formik.values.api_bsw,
                          { api: "", bsw: "", date: currentDateTime },
                        ])
                      }
                    >
                      <AddIcon />
                    </Button>
                  </Tooltip>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* fila 4 */}
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Variables extras
                </Typography>
                {formik.values.variables.map((variable, index) => (
                  <>
                    <Grid container spacing={2} key={index}>
                      <Grid item xs={5}>
                        <TextField
                          required
                          variant="outlined"
                          fullWidth
                          label={`Tipo de variable ${index + 1}`}
                          name={`variables[${index}].tipo`}
                          placeholder="Tipo de variable"
                          value={variable.tipo}
                          onChange={formik.handleChange}
                          error={formik.errors[`variables[${index}].tipo`]}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          required
                          variant="outlined"
                          fullWidth
                          label={`Valor de variable ${index + 1}`}
                          name={`variables[${index}].valorVariable`}
                          placeholder="Valor de variable"
                          value={variable.valorVariable}
                          onChange={formik.handleChange}
                          error={
                            formik.errors[`variables[${index}].valorVariable`]
                          }
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip title="Eliminar variable" arrow>
                          <Button
                            variant="outlined"
                            color="secondary"
                            style={{
                              height: '100%', display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              padding: '0',
                              margin: '0',
                              color: 'rgba(252, 0, 0, 0.8)',
                              border: '1px solid rgba(252, 0, 0, 0.8)',
                            }}
                            onClick={() =>
                              formik.setFieldValue(
                                "variables",
                                formik.values.variables.filter(
                                  (_, i) => i !== index
                                )
                              )
                            }
                          >
                            <DeleteIcon style={{ margin: '0' }} />
                          </Button>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <br />
                  </>
                ))}
                <Grid item xs={12}>
                  <Tooltip title="Agregar variable" arrow>
                    <Button
                      variant="outlined"
                      color="secondary"
                      fullWidth
                      style={{
                        color: 'rgb(25, 118, 210)',
                        border: '1px solid rgb(25, 118, 210)',
                        '&:hover': {
                          color: 'rgba(221, 223, 221, 0.62)',
                          border: '1px solid rgba(221, 223, 221, 0.62)',
                        }
                      }}
                      onClick={() =>
                        formik.setFieldValue("variables", [
                          ...formik.values.variables,
                          { tipo: "", valorVariable: "" },
                        ])
                      }
                    >
                      <AddIcon />
                    </Button>
                  </Tooltip>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              style={{
                backgroundColor: "rgb(204, 48, 43)",
                color: "white",
              }}
            >
              {Productos ? "Actualizar" : "Crear"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Formik>
  );
}

// Función para establecer valores iniciales para un objeto
function initialValues(data) {
  return {
    codigo_producto: data?.codigo_producto || "",
    nombre: data?.nombre || "",
    api_bsw: data?.api_bsw || [],
    variables: data?.variables || [],
    productos: data?.productos || "", // Puedes inicializarlo como nulo o con un valor predeterminado
    tanques: data?.tanques || "", // Puedes inicializarlo como nulo o con un valor predeterminado
  };
}

// Función para crear un nuevo esquema de validación
function newSchema() {
  return {
    codigo_producto: Yup.string().required(
      'El campo "codigo_producto" es obligatorio'
    ),
    nombre: Yup.string(),
    // api_bsw: Yup.object().required('El campo "api_bsw" es obligatorio'),
    // variables: Yup.object().required('El campo "variables" es obligatorio'),
    productos: Yup.number().required('El campo "producto" es obligatorio'), // Puedes permitir valores nulos si es necesario
    tanques: Yup.number().required('El campo "tanques" es obligatorio'), // Puedes permitir valores nulos si es necesario
  };
}
