import React, { useState, useCallback } from "react";
import Swal from "sweetalert2";
import { AddEditTablaForm } from "../../../Components/Client";
import { AddEditChecklist } from "../../../Components/Client/Tanques/AddEditChecklist";
import { ViewTankChecklist } from "../../../Components/Client/Tanques/ViewTankChecklist";
import { HistoryTankChecklists } from "../../../Components/Client/Tanques/HistoryTankCheckLists";
import { useTanques } from "../../../hooks";
import { useAuth } from "../../../hooks";

export function Funciones() {
  const { auth } = useAuth();
  const { deleteTanques } = useTanques();
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);

  const handleAdd = useCallback(() => {
    setTitleModal("Nuevo tanque");
    setContentModal(
      <AddEditTablaForm onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const handleUpdate = useCallback(
    (data) => {
      setTitleModal("Actualizar tanque");
      setContentModal(
        <AddEditTablaForm
          onClose={openCloseModal}
          onRefetch={onRefetch}
          Tanques={data}
        />
      );
      openCloseModal();
    },
    [openCloseModal, onRefetch]
  );

  const handleDelete = useCallback(
    async (data) => {
      const result = await Swal.fire({
        title: "Estas seguro?",
        text: "No podrás revertir esta acción!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, eliminar!",
        cancelButtonText: "No, cancelar!",
        reverseButtons: true,
      });

      if (result.isConfirmed) {
        await deleteTanques(data.id_tanque);
        Swal.fire("Eliminado!", "El dato ha sido eliminado.", "success");
        onRefetch();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelado", "Operación cancelada", "error");
      }
    },
    [deleteTanques, onRefetch]
  );

  // const handleChecklist = useCallback(
  //   (data, activeChecklist) => {
  //     setTitleModal("Realizar inspección externa")
  //     setContentModal(
  //       <AddEditChecklist onClose={openCloseModal} onRefetch={onRefetch} Tanques={data} activeChecklist={activeChecklist} />
  //     )
  //     openCloseModal()
  //   },
  //   [openCloseModal, onRefetch]
  // )

  const handleChecklist = useCallback(async (data) => {
    const newWindow = window.open('/tankinspection', '_blank');

    // You can pass data to the new window using localStorage or postMessage
    if (newWindow) {
      newWindow.localStorage.setItem('checklistTankData', JSON.stringify(data));
    } else {
      // Handle scenarios where pop-ups are blocked
      // You can redirect the user to the page instead
      window.location.href = '/tankinspection';
    }
  }, [onRefetch])

  const handleViewTankChecklist = useCallback(
    (data) => {
      setTitleModal("Ver inspección external actual")
      setContentModal(
        <ViewTankChecklist onClose={openCloseModal} onRefetch={onRefetch} Tanques={data} />
      )
      openCloseModal()
    },
    [openCloseModal, onRefetch]
  )

  const handleHistoryTankChecklist = useCallback(
    (data) => {
      setTitleModal("Ver historial de inspecciones externas")
      setContentModal(
        <HistoryTankChecklists onClose={openCloseModal} onRefetch={onRefetch} Tanques={data} />
      )
      openCloseModal()
    },
    [openCloseModal, onRefetch]
  )

  const renderUnidadLongitud = () => {
    if (auth.me?.SistemasMedicion && auth.me?.SistemasMedicion.longitud) {
      return `${auth.me.SistemasMedicion.longitud} `;
    }
    return "No especificado";
  };

  const renderUnidadVolumen = () => {
    if (auth.me?.SistemasMedicion && auth.me?.SistemasMedicion.volumen) {
      return `${auth.me.SistemasMedicion.volumen} `;
    }
    return "No especificado";
  };

  const renderUnidadTemperatura = () => {
    if (auth.me?.SistemasMedicion && auth.me?.SistemasMedicion.temperatura) {
      return `${auth.me.SistemasMedicion.temperatura} `;
    }
    return "No especificado";
  };

  return {
    handleAdd,
    handleDelete,
    handleUpdate,
    openCloseModal,
    renderUnidadTemperatura,
    renderUnidadVolumen,
    refetch,
    contentModal,
    titleModal,
    showModal,
    handleChecklist,
    handleViewTankChecklist,
    handleHistoryTankChecklist,
  };
}
