export const Perm3 = () => (
  <svg
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 442.41 656.11"
  >
    <path
      fill="#fff"
      d="M441.7,331.14c0-79.6,.19-159.2-.06-238.8-.18-57.56-32.81-91.32-90.25-91.8-86.44-.72-172.9-.7-259.34,0C33.43,.99,.58,35.07,.46,94.94,.14,250.87,1.05,406.81,0,562.74c-.4,60.34,44.41,94.7,93,93.23,85.29-2.59,170.78-2.8,256.05,.09,47.86,1.62,95.53-32.82,93.28-95.95-2.71-76.23-.64-152.64-.64-228.97Z"
    />
    <path
      fill="#050506"
      d="M441.7,331.14c0,76.33-2.07,152.74,.64,228.97,2.25,63.13-45.43,97.57-93.28,95.95-85.27-2.88-170.76-2.67-256.05-.09C44.41,657.44-.4,623.08,0,562.74,1.05,406.81,.14,250.87,.46,94.94,.58,35.07,33.43,.99,92.04,.52c86.44-.69,172.9-.71,259.34,0,57.44,.48,90.07,34.24,90.25,91.8,.25,79.6,.06,159.2,.06,238.8Zm-20.02-2.5c0-78.47,.2-156.95-.08-235.42-.17-46.39-23.11-71.77-68.69-72.34-87.47-1.1-174.98-1.02-262.45-.08-44.82,.49-69.74,26.44-69.89,72.08-.51,156.95-.52,313.9,0,470.85,.16,47.9,24.85,71.24,72.64,71.32,85.33,.15,170.65,.14,255.98,0,49.57-.08,72.34-23.39,72.45-74.25,.16-77.38,.04-154.77,.04-232.15Z"
    />
    <path
      fill="#fafafa"
      d="M421.68,328.64c0,77.38,.12,154.77-.04,232.15-.11,50.86-22.88,74.18-72.45,74.25-85.33,.13-170.65,.15-255.98,0-47.79-.08-72.48-23.42-72.64-71.32-.52-156.95-.51-313.9,0-470.85,.15-45.64,25.07-71.59,69.89-72.08,87.48-.95,174.98-1.02,262.45,.08,45.57,.57,68.52,25.95,68.69,72.34,.28,78.47,.08,156.95,.08,235.42Zm-31.14,112.21c0-33.74,.3-67.49-.08-101.23-.46-40.85-20.6-64.52-59.98-65.34-70.08-1.47-140.22-1.43-210.3-.09-39.18,.75-61.29,24.95-61.64,64.53-.61,68.57-.66,137.16,.04,205.73,.39,38.64,24.41,63.76,62.29,64.27,69.02,.93,138.07,0,207.11,.38,37.72,.2,65.17-32.59,63.1-63.78-2.3-34.68-.53-69.63-.53-104.46Z"
    />
    <path
      fill="#14120c"
      d="M390.54,440.85c0,34.83-1.78,69.79,.53,104.46,2.07,31.19-25.37,63.98-63.1,63.78-69.03-.37-138.08,.56-207.11-.38-37.88-.51-61.9-25.63-62.29-64.27-.69-68.57-.64-137.16-.04-205.73,.35-39.59,22.47-63.79,61.64-64.53,70.08-1.34,140.23-1.38,210.3,.09,39.38,.82,59.52,24.48,59.98,65.34,.38,33.74,.08,67.49,.08,101.23Zm-165.47,149.36c32.37,0,64.75,.24,97.12-.06,35.14-.33,51.3-13.78,51.91-47.85,1.22-67.49,1.03-135.03,.09-202.53-.42-30.25-16-48.27-45.55-48.98-69.01-1.66-138.12-1.69-207.12,.2-30.22,.83-44.77,18.12-45.16,48.84-.86,67.5-.89,135.03,.07,202.53,.47,33.27,15.5,47.18,48.28,47.73,33.44,.56,66.9,.12,100.35,.13Z"
    />
    <path
      fill="#f6bb07"
      d="M225.07,590.2c-33.45,0-66.91,.43-100.35-.13-32.78-.55-47.81-14.47-48.28-47.73-.96-67.5-.94-135.03-.07-202.53,.39-30.71,14.94-48.01,45.16-48.84,69-1.89,138.11-1.85,207.12-.2,29.55,.71,45.13,18.74,45.55,48.98,.94,67.5,1.13,135.04-.09,202.53-.61,34.07-16.77,47.52-51.91,47.85-32.37,.3-64.75,.06-97.12,.06Zm-50.88-217.06c-2.66,1.08-5.31,2.16-7.97,3.23-18.54-8.77-23.2,1.19-23.19,17.49,0,27.23-3.15,54.94,.86,81.55,4.09,27.12,15.09,53.2,23.52,79.58,.74,2.33,4.83,5.02,7.42,5.08,21.55,.49,43.29,1.9,64.56-.59,5.04-.59,8.43-14.84,12.81-22.68,1.02-1.83,2.72-3.61,4.53-4.6,41.6-22.51,34.07-66.79,44.06-103.05,1.28-4.65-6.02-11.7-9.34-17.64-5.73,3.83-13.44,6.43-16.69,11.78-4.34,7.15-5.23,16.44-7.6,24.82-4.85-29.64-4-57.89-5.17-86.05-.7-16.8-7.1-27.06-23.07-9.36-2.24-.48-4.48-.95-6.72-1.43-2.49-9.55,1.72-24.62-14.35-23.54-4.37,.29-7.98,12.19-11.93,18.76-2.15,1.13-4.3,2.26-6.46,3.4-21.96-9.87-22.85-9.05-25.27,23.25Z"
    />
    <path
      fill="#090806"
      d="M205.91,346.5c0-12.57,7.56-18.46,11.93-18.76,16.07-1.08,11.86,13.99,14.35,23.54-.52,15.09-.87,30.53-1.03,46.29-.14,14.89-.1,29.5,.1,43.8,2.55-.02,5.1-.04,7.64-.06,0-29.53-13.15-70.13,0-88.6,13.15-18.46,24.4-6.22,23.07,9.36,1.17,28.16,.32,56.41,5.17,86.05,2.37-8.37,4.16-17.19,7.6-24.82,8.89-19.74,36.46-18.67,16.69-11.78,3.32,5.94,10.62,12.99,9.34,17.64-9.99,36.26-2.46,80.54-44.06,103.05-1.82,.98-3.51,2.77-4.53,4.6-4.38,7.84-7.76,22.09-12.81,22.68-21.27,2.48-43.01,1.08-64.56,.59-2.59-.06-6.68-2.75-7.42-5.08-8.43-26.39-19.43-52.47-23.52-79.58-4.01-26.61-.86-54.32-.86-81.55,0-16.3,7.09-31.93,23.19-17.49s2.71,42.92,4.07,64.38c1.3-.03,2.6-.07,3.9-.1v-67.51c2.41-32.3,3.31-33.13,25.27-23.25v89.99c2.15-.09,4.3-.18,6.46-.27v-93.12Z"
    />
    <path
      fill="#f6bb0c"
      d="M238.92,352.71v88.6c-2.55,.02-5.1,.04-7.64,.06-.2-14.3-.24-28.9-.1-43.8,.15-15.76,.5-31.2,1.03-46.29,2.24,.48,4.48,.95,6.72,1.43Z"
    />
    <path
      fill="#f6bb0c"
      d="M205.91,346.5v93.12c-2.15,.09-4.3,.18-6.46,.27v-89.99c2.15-1.13,4.3-2.26,6.46-3.4Z"
    />
    <path
      fill="#f6bb0c"
      d="M174.19,373.15v67.51c-1.3,.03-2.6,.07-3.9,.1-1.36-21.46-2.71-42.92-4.07-64.38,2.66-1.08,5.31-2.16,7.97-3.23Z"
    />
    <path fill="#090806" d="M291.45,411.53c4.33-6.56,21.37-6.89,9.34,17.64" />
  </svg>
);
