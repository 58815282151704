import { BASE_API } from "../../utils/constants";
import { makeRequest } from "../Request";
const BASE_URL = `${BASE_API}/`;
  
  // Función para obtener los datos 
  export async function getControlDocTransportadoraApi(token, schema) {
    let url = `${BASE_URL}${schema}/transportadora/documenttransportadora`;
    return makeRequest(url, "GET", token);
  }
  
  // Función para agregar un nuevo dato
  export async function addControlDocTransportadoraApi(data, token,schema) {
    const url = `${BASE_URL}${schema}/transportadora/documenttransportadora/`;
    return makeRequest(url, "POST", token,  data);
  }
  
  // Función para actualizar los datos 
  export async function updateControlDocTransportadoraApi(id, data, token,schema) {
    const url = `${BASE_URL}${schema}/transportadora/documenttransportadora/${id}/`;
    return makeRequest(url, "PATCH", token, data);
  }
  
  // Función para eliminar 
  export async function deleteControlDocTransportadoraApi(id, token,schema) {
    const url = `${BASE_URL}${schema}/transportadora/documenttransportadora/${id}/`;
    return makeRequest(url, "DELETE", token);
  }