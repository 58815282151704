import React, { useEffect } from "react";
import { Loader } from "semantic-ui-react";
import { HeaderPage } from "../../../Components/Client";
import { DataGridBasic, ModalBasic } from "../../../Components/Comons";
import { useBahias } from "../../../hooks";
import Swal from "sweetalert2";
import { columns, filterValue } from "./bahiasFormat";
import { Funciones } from "./Funciones";

export function BahiasAdmin() {
  const { loading, Bahias, getBahias } = useBahias();
  const {
    refetch,
    contentModal,
    titleModal,
    showModal,
    handleAddBahias,
    handleDeleteBahias,
    handleUpdateBahias,
    openCloseModal,
  } = Funciones();

  useEffect(() => {
    getBahias();
  }, [refetch]);

  const MemoizedModalBasic = React.memo(ModalBasic);

  return (
    <>
      <HeaderPage
        title="Bahias"
        btnTitle="Nuevo Bahias"
        icono="plus square icon"
        btnClick={handleAddBahias}
      />
      {loading ? (
        <Loader active inline="centered">
          Cargando...
        </Loader>
      ) : (
        <DataGridBasic
          data={Bahias.results}
          title="Bahias"
          id="id_Bahias"
          filterValue={filterValue}
          columns={columns}
          updateData={handleUpdateBahias}
          onDeleteData={handleDeleteBahias}
        />
      )}

      {showModal && (
        <MemoizedModalBasic
          show={showModal}
          onClose={openCloseModal}
          title={titleModal}
          children={contentModal}
        />
      )}
    </>
  );
}
