import { useFormik } from "formik";

// importaciones para el mapa
import L from 'leaflet';
import 'leaflet-routing-machine';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import 'leaflet/dist/leaflet.css';

import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Autocomplete } from '@mui/material';
// import { Paper, Container, Button } from '@material-ui/core'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import * as Yup from "yup";
import markerIcon from '../../../assets/marker.png';
import { useEmisiones, useMaquinas, usePlantas, useVehiculos } from "../../../hooks";
import { alertas } from "../../Comons/Alertas/alertas";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const fuelTypes = [
    { key: 'GASOLINA', value: 'GASOLINA', constant: 2310 },
    { key: 'DIESEL', value: 'DIESEL', constant: 2680 },
    { key: 'GLP', value: 'GLP', constant: 1510 },
    { key: 'GNC', value: 'GNC', constant: 1880 },
]

export function AddEditEmisiones(props) {

    const { Successful } = alertas();

    const [map, setMap] = useState(null);
    const [originLocation, setOriginLocation] = useState(null);
    const [destinationLocation, setDestinationLocation] = useState(null);

    const [error, setError] = useState(false);
    const [error2, setError2] = useState(false);
    const [error3, setError3] = useState(false);

    const customIcon = L.icon({
        iconUrl: markerIcon,
        iconSize: [32, 32], // tamaño del ícono
        iconAnchor: [16, 32], // ajuste del anclaje del ícono
    });

    const [invalidAlert, setInvalidAlert] = useState(false)

    const { onClose, onRefetch, Emisiones } = props;

    const { getPlantas, Plantas } = usePlantas()
    const { addEmisiones, updateEmisiones } = useEmisiones()
    const { getVehiculos, Vehiculos } = useVehiculos()
    const { getMaquinas, Maquinas } = useMaquinas()
    const [totDistance, setTotDistance] = useState([])
    const [plantaOrigenNombre, setPlantaOrigenNombre] = useState()
    const [plantaDestinoNombre, setPlantaDestinoNombre] = useState()

    // constante de referencia para mantener el estado del mapa
    const mapRef = useRef(null);
    // constante de la ruta calculada por el mapa 
    const [routingControl, setRoutingControl] = useState(null);


    useEffect(() => {
        getPlantas()
        getVehiculos()
        getMaquinas()

        // si se está actualizando el registro entonces establece los puntos necesarios para el mapa
        if (Emisiones) {
            setOriginLocation(Emisiones?.plantaOrigenData?.id_direcFc)
            setDestinationLocation(Emisiones?.plantaDestinoData?.id_direcFc)
        }
    }, [Emisiones])

    const formik = useFormik({
        // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
        initialValues: initialValues(Emisiones),
        // Establece la validación del esquema utilizando Yup, si se proporciona un objeto  se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
        validationSchema: Yup.object(Emisiones ? updateScheme() : newScheme()),
        // Desactiva la validación al cambiar los valores del formulario
        validateOnChange: false,
        onSubmit: async (formValue) => {
            try {

                if (invalidAlert === true) {
                    toast.error('La placa ingresada no existe en vehículos o máquinas')
                    return
                }

                console.log(formValue);

                // Si se proporciona un objeto se actualiza, de lo contrario se crea uno nuevo
                if (Emisiones) await updateEmisiones(Emisiones.id_registro, formValue);
                else await addEmisiones(formValue);
                Successful();
                onRefetch();
                onClose();
            } catch (error) {
                // Muestra un mensaje de error si ocurre algún problema
                if (error?.message) {
                    toast.error(error.message, {
                        position: "top-center",
                    });
                } else {
                    onClose();
                }
            }
        },
    });

    // inicializa el mapa cuando carga el componente
    useEffect(() => {
        const leafletMap = L.map('map').setView([4.60971, -74.08175], 10);

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '© OpenStreetMap contributors'
        }).addTo(leafletMap);

        mapRef.current = leafletMap;

        return () => {
            leafletMap.remove();
        };
    }, []);

    // función que se encarga de marcar la ruta entre el origen y el destino, y recuperar la distancia marcada por el servicio de geolocalización
    const displayDirections = (origin, destination) => {
        const map = mapRef.current;

        if (map && origin && destination && origin.latitud && origin.longitud && destination.latitud && destination.longitud) {
            console.log("entró")
            map.eachLayer(layer => {
                if (layer instanceof L.Marker || layer instanceof L.Polyline) {
                    map.removeLayer(layer);
                }
            });

            if (routingControl) {
                map.removeControl(routingControl);
            }

            const originMarker = L.marker([origin.latitud, origin.longitud], { icon: customIcon }).addTo(map);
            const destinationMarker = L.marker([destination.latitud, destination.longitud], { icon: customIcon }).addTo(map);

            originMarker.bindPopup('Punto de origen: ' + (plantaOrigenNombre ? plantaOrigenNombre : formik.values.plantaOrigenNombre));
            destinationMarker.bindPopup('Punto de destino: ' + (plantaDestinoNombre ? plantaDestinoNombre : formik.values.plantaDestinoNombre));

            // Create a routing control and add it to the map
            const control = L.Routing.control({
                waypoints: [
                    L.latLng(origin.latitud, origin.longitud),
                    L.latLng(destination.latitud, destination.longitud)
                ],
                createMarker: () => { }, // Desabilita la creación de marcadores por defecto
                routeWhileDragging: false, // Muestra la ruta mientras arrastra los cursores
                show: false, // Oculta parte de la interfaz
                router: L.Routing.osrmv1({
                    language: 'en',
                    profile: 'car'
                }),
                sidebar: false // Oculta el panel de direcciones
            }).addTo(map);

            control.on('routesfound', function (event) {
                const route = event.routes[0]; // como solo hay una ruta se toma la primera que se encuentre
                const distance = route.summary.totalDistance; // Obtiene la distancia total del recorrido

                setTotDistance(distance);
            });

            setRoutingControl(control);
        }
    };

    // función que extrae los datos de las coordenadas de los puntos seleccionados
    useEffect(() => {
        if (formik.values.plantaOrigen && formik.values.plantaDestino) {
            const selectedOrigin = Plantas?.results?.find(item => item.id_myemp === formik.values.plantaOrigen);
            const selectedDestination = Plantas?.results?.find(item => item.id_myemp === formik.values.plantaDestino);

            if (selectedOrigin?.id_direcFc && selectedDestination?.id_direcFc) {

                setOriginLocation(selectedOrigin.id_direcFc);
                setDestinationLocation(selectedDestination.id_direcFc);
            }
        }

    }, [formik.values.plantaOrigen, formik.values.plantaDestino]);

    // función para establecer el punto de origney destino que va a ser calculado en el mapa
    useEffect(() => {
        if (originLocation && destinationLocation) {
            displayDirections(originLocation, destinationLocation);
        }
    }, [originLocation, destinationLocation])

    // función para saber si la placa que está ingresando existe
    const handlePlacaChange = (event) => {
        formik.setFieldValue('placa_obj', event.target.value)
        const placaObj = event.target.value;

        const existsInMaq = Maquinas.find((maquina) => maquina.placa == placaObj);
        const existsInVeh = Vehiculos.results.find((vehiculo) => vehiculo.placa_veh == placaObj);

        if (existsInMaq === undefined && existsInVeh === undefined) {
            toast.error('La placa ingresada no existe en vehículos o máquinas'); // Show error toast
            setInvalidAlert(true);
        } else {
            setInvalidAlert(false);
        }
    };

    // función para calcular la emisión aproximada
    useEffect(() => {
        if (formik.values.consumoProm && formik.values.consumoProm > 0 && formik.values.distanciaConsumoProm && formik.values.distanciaConsumoProm > 0 && formik.values.tipoCombustible && totDistance) {
            const usedFuel = ((totDistance / 1000) / formik.values.distanciaConsumoProm) * formik.values.consumoProm
            const aproxCO2 = (usedFuel * (formik.values.constanteCO2 / 1000)).toFixed(2)
            formik.setFieldValue('emision', aproxCO2)
        }
    })

    return (
        <>
            <form className="add-edit-secciones-form" onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>
                    {/* primera fila */}
                    <Grid item xs={12} sm={4}>
                        <TextField
                            required
                            fullWidth
                            label="Placa"
                            name="placa_obj"
                            value={formik.values.placa_obj}
                            error={formik.errors.placa_obj}
                            onChange={handlePlacaChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            required
                            fullWidth
                            label="Consumo promedio en litros"
                            name="consumoProm"
                            value={formik.values.consumoProm}
                            error={formik.errors.consumoProm}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            required
                            fullWidth
                            label="Distancia de consumo promedio en km"
                            name="distanciaConsumoProm"
                            value={formik.values.distanciaConsumoProm}
                            error={formik.errors.distanciaConsumoProm}
                            onChange={formik.handleChange}
                        />
                    </Grid>

                    {/* segunda fila */}
                    <Grid item xs={12} sm={4}>
                        <Autocomplete
                            id="tipoCombustible"
                            options={fuelTypes}
                            getOptionLabel={(option) => option.value}
                            value={fuelTypes.find((type) => type.key === formik.values?.tipoCombustible) || null}
                            onChange={(_, value) => {
                                formik.setFieldValue('tipoCombustible', value?.key || '');
                                const selectedFuel = fuelTypes.find((type) => type.key === value?.key);
                                formik.setFieldValue('constanteCO2', selectedFuel?.constant || '');

                                setError(!value || !value.key); // Check if selection is empty and set error accordingly
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Tipo de combustible"
                                    variant="outlined"
                                    error={error}
                                    helperText={error ? 'Es necesario una selección' : ''}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Autocomplete
                            id="plantaorigen"
                            options={Plantas?.results || []}
                            getOptionLabel={(option) => option.razon_social || ''}
                            value={Plantas?.results?.find((plantas) => plantas.id_myemp === formik.values?.plantaOrigen) || null}
                            onChange={(_, value) => {
                                formik.setFieldValue('plantaOrigen', value?.id_myemp || '');
                                setPlantaOrigenNombre(value?.razon_social || '');

                                setError2(!value || !value.id_myemp); // Check if selection is empty and set error accordingly
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Planta de origen"
                                    variant="outlined"
                                    error={error2}
                                    helperText={error2 ? 'Es necesario una selección' : ''}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Autocomplete
                            id="plantadestino"
                            options={Plantas?.results || []}
                            getOptionLabel={(option) => option.razon_social || ''}
                            value={Plantas?.results?.find((plantas) => plantas.id_myemp === formik.values?.plantaDestino) || null}
                            onChange={(_, value) => {
                                formik.setFieldValue('plantaDestino', value?.id_myemp || '');
                                setPlantaDestinoNombre(value?.razon_social || '');

                                setError3(!value || !value.id_myemp); // Check if selection is empty and set error accordingly
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Planta de destino"
                                    variant="outlined"
                                    error={error3}
                                    helperText={error3 ? 'Es necesario una selección' : ''}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Divider>
                        </Divider>
                    </Grid>

                    {/* tercera fila */}
                    <Grid item xs={12} sm={4}>
                        <Card>
                            <CardContent>
                                <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                    {"Gramos de CO2 por litro de " + formik.values?.tipoCombustible}
                                </Typography>
                                <Typography variant="h5" component="div" style={{ textAlign: "right" }}>
                                    {formik.values?.constanteCO2 + " g"}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Card>
                            <CardContent>
                                <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                    {"Distancia total en km "}
                                </Typography>
                                <Typography variant="h5" component="div" style={{ textAlign: "right" }}>
                                    {totDistance ? (totDistance / 1000).toFixed(2) : 0}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Card>
                            <CardContent>
                                <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                                    {"Emisión de CO2 en kg"}
                                </Typography>
                                <Typography variant="h5" component="div" style={{ textAlign: "right" }}>
                                    {formik.values?.emision}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12}>
                        <div id="map" style={{ height: '400px' }}></div>
                    </Grid>

                    {/* Fila no visible que contiene los valores indicados */}
                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            name="constanteCO2"
                            value={formik.values.constanteCO2}
                            error={formik.errors.constanteCO2}
                            onChange={formik.handleChange}
                            disabled
                            hidden
                        />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <TextField
                            required
                            fullWidth
                            value={totDistance ? (totDistance / 1000).toFixed(2) : 0}
                            disabled
                            hidden
                        />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <TextField
                            hidden
                            fullWidth
                            name="emision"
                            value={formik.values.emision}
                            error={formik.errors.emision}
                            onChange={formik.handleChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Button type="submit" variant="contained" className="btn btn-danger" color="error" fullWidth>{Emisiones ? "Actualizar" : "Crear"}</Button>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

function initialValues(data) {
    return {
        placa_obj: data?.placa_obj || "",
        plantaOrigen: data?.plantaOrigen || 0,
        plantaDestino: data?.plantaDestino || 0,
        consumoProm: data?.consumoProm || 0,
        distanciaConsumoProm: data?.distanciaConsumoProm || 0,
        emision: data?.emision || 0,
        constanteCO2: data?.constanteCO2 || 2310,
        tipoCombustible: data?.tipoCombustible || "GASOLINA",
        plantaOrigenNombre: data?.plantaOrigenData.razon_social || "",
        plantaDestinoNombre: data?.plantaDestinoData.razon_social || "",
    }
}

function newScheme() {
    return {
        placa_obj: Yup.string(),
        plantaOrigen: Yup.number(),
        plantaDestino: Yup.number(),
        consumoProm: Yup.number(),
        distanciaConsumoProm: Yup.number(),
        emision: Yup.number(),
        constanteCO2: Yup.number(),
        tipoCombusible: Yup.string(),
    }
}

function updateScheme() {
    return {
        placa_obj: Yup.string(),
        plantaOrigen: Yup.number(),
        plantaDestino: Yup.number(),
        consumoProm: Yup.number(),
        distanciaConsumoProm: Yup.number(),
        emision: Yup.number(),
        constanteCO2: Yup.number(),
        tipoCombusible: Yup.string(),
    }
}