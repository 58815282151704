import { useEffect, useState } from "react";

// material-ui
import {
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";

// project import
import OrdersTable from "./OrdersTable";
import ReportAreaChart from "./ReportAreaChart";
import SalesColumnChart from "./SalesColumnChart";

// assets
import { MainCard } from "../../../Components/Client";
import AnalyticEcommerce from "../../../Components/Client/Correcciones/AnalyticEcommerce";
import AppOrderTimeline from "../../../Components/Client/Correcciones/AppOrderTimeline";
import { useConciliaciones, useDatosCalculados, useTanques } from "../../../hooks";

// sales report status
const status = [
  {
    value: "today",
    label: "Today",
  },
  {
    value: "month",
    label: "This Month",
  },
  {
    value: "year",
    label: "This Year",
  },
];

// ==============================|| DASHBOARD - DEFAULT ||============================== //

export const DashboardDefault = () => {
  const [value, setValue] = useState("today");
  const [tanqueSeleccionado, setTanqueSeleccionado] = useState("");
  const { tanquesDB, getTanquesDB } = useTanques();
  const { datosCalculados, getDatosCalculados } = useDatosCalculados();

  const {
    ConciliacionesUlti,
    Conciliaciones,
    ConciliacionesSuma,
    ConciliacionesTotSum,
    getConciliacionesSumaTotal,
    getConciliacionesUlti,
    getConciliaciones,
    getConciliacionesSum,

  } = useConciliaciones();


  useEffect(() => {
    getConciliacionesUlti();
    getConciliaciones();
    getConciliacionesSum();
    getConciliacionesSumaTotal();
    getTanquesDB();
    getDatosCalculados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (tanqueSeleccionado) {
      getConciliacionesUlti(tanqueSeleccionado);
      getConciliaciones(tanqueSeleccionado);
      getConciliacionesSum(tanqueSeleccionado);
      getConciliacionesSumaTotal(tanqueSeleccionado);
      getDatosCalculados(tanqueSeleccionado);
    }
  }, [tanqueSeleccionado]);

  let mappedData = [];

  if (ConciliacionesUlti && ConciliacionesUlti.length > 0) {
    mappedData = ConciliacionesUlti.map((conciliacion) => {
      let type = "";
      let titles = "";

      switch (conciliacion.tipo) {
        case "1":
          type = conciliacion.volumen_ventas || "";
          titles = "Ventas de producto";

          break;
        case "2":
          type = conciliacion.volumen_recargas || "";
          titles = "Recargas de producto";
          break;
        case "3":
          type = conciliacion.perdida_evaporación || "";
          titles = "Evaporación de producto";
          break;
        case "4":
          type = conciliacion.derrame || "";
          titles = "Derrame de producto";
          break;
        default:
          break;
      }

      return {
        id: conciliacion.id_conciliaciones,
        title: titles,
        type: type,
        time: conciliacion.fecha_conciliacion,
      };
    });
  }


  const calculatePercentage = (value) => {
    const totalSum =
      parseInt(ConciliacionesTotSum.volumen_ventas) +
      parseInt(ConciliacionesTotSum.volumen_recargas) +
      parseInt(ConciliacionesTotSum.perdida_evaporación) +
      parseInt(ConciliacionesTotSum.derrame);

    const percentage = (value / totalSum) * 100;
    return parseFloat(percentage.toFixed(2));
  };

  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
      <Grid item xs={12} sx={{ mb: -2.25 }}>
        <FormControl style={{ width: '10rem' }}>
          <InputLabel id="tanque-label">Filtrar por tanque</InputLabel>
          <Select
            labelId="tanque-label"
            value={tanqueSeleccionado}
            onChange={(event) => setTanqueSeleccionado(event.target.value)}
          >
            <MenuItem value="">
              <em>--Tanques--</em>
            </MenuItem>
            {tanquesDB && tanquesDB.length > 0 ? (
              tanquesDB.map((tanque) => (
                <MenuItem key={tanque.id_tanque} value={tanque.id_tanque}>
                  {tanque.nombre_tanque}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No se encontraron tanques</MenuItem>
            )}
          </Select>
        </FormControl>
      </Grid>

      {/* row 1 */}
      <Grid item xs={12} sx={{ mb: -2.25 }}>
        <Typography variant="h5">Total por correccion</Typography>
      </Grid>
      {ConciliacionesTotSum && (
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <AnalyticEcommerce
            title="Volumen recargas"
            count={ConciliacionesTotSum.volumen_recargas}
            percentage={calculatePercentage(
              ConciliacionesTotSum.volumen_recargas
            )}
            color="success"
            extra="0"
          />
        </Grid>
      )}
      {ConciliacionesTotSum && (
        <>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title="Volumen de ventas"
              count={ConciliacionesTotSum.volumen_ventas}
              percentage={calculatePercentage(
                ConciliacionesTotSum.volumen_ventas
              )}
              isLoss
              color="error"
              extra="0"
            />
          </Grid>

          {ConciliacionesTotSum && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <AnalyticEcommerce
                title="Perdida por evaporacion"
                count={ConciliacionesTotSum.perdida_evaporación}
                percentage={calculatePercentage(
                  ConciliacionesTotSum.perdida_evaporación
                )}
                isLoss
                color="error"
                extra="0"
              />
            </Grid>
          )}
          {ConciliacionesTotSum && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <AnalyticEcommerce
                title="Derrame"
                count={ConciliacionesTotSum.derrame}
                percentage={calculatePercentage(ConciliacionesTotSum.derrame)}
                isLoss
                color="error"
                extra="0"
              />
            </Grid>
          )}
        </>
      )}

      <Grid
        item
        md={8}
        sx={{ display: { sm: "none", md: "block", lg: "none" } }}
      />
      {/* row 3 */}
      <Grid item xs={12} md={7} lg={8}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5">Ingresos vs Egresos</Typography>
          </Grid>
          <Grid item>
            <TextField
              id="standard-select-currency"
              size="small"
              select
              value={value}
              onChange={(e) => setValue(e.target.value)}
              sx={{
                "& .MuiInputBase-input": { py: 0.5, fontSize: "0.875rem" },
              }}
            >
              {status.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <MainCard sx={{ mt: 1.75 }}>


          <SalesColumnChart data={ConciliacionesSuma} />
        </MainCard>
      </Grid>




      {/* row 3 */}

      <Grid item xs={12} md={5} lg={4}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5">
              Reporte de correcciones por tanque
            </Typography>
            {/* 
            es decir se debe mostrar la historia de cada tanque cuando se hace el ingreso de datos manuales  en la grafica subiendo o bajando segun corresponda para cada fecha qeu se ingresan los datos
            ademas buscar la manera de combinarlos para que tambien se calculen las correcciones que se hagan manuales o generar otra linea que me muestre como deberia estar el tanque segun las correcciones
            */}
          </Grid>
          <Grid item />
        </Grid>
        <MainCard sx={{ mt: 2 }} content={false}>
          {ConciliacionesTotSum && (
            <List sx={{ p: 0, "& .MuiListItemButton-root": { py: 2 } }}>

              <ListItemButton divider>
                <ListItemText primary="Ingresado al tanque" />
                <Typography variant="h5">{ConciliacionesTotSum.volumen_recargas}</Typography>
              </ListItemButton>
              <ListItemButton divider>
                <ListItemText primary="salida del tanque" />
                <Typography variant="h5">{parseInt(ConciliacionesTotSum.volumen_ventas) - parseInt(ConciliacionesTotSum.perdida_evaporación) - parseInt(ConciliacionesTotSum.derrame)}</Typography>
              </ListItemButton>


            </List>
          )}
          {datosCalculados && datosCalculados.length > 0 ? (
            <ReportAreaChart data={datosCalculados} />
          ) : (
            <p>No hay datos disponibles.</p>
          )}
        </MainCard>
      </Grid>

      {/* row 4 */}
      <Grid item xs={12} md={7} lg={8}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5">Correcciones agregadas</Typography>
          </Grid>
          <Grid item />
        </Grid>
        <MainCard sx={{ mt: 2 }} content={false}>
          {Conciliaciones?.results && Conciliaciones?.results.length > 0 ? (
            <OrdersTable Conciliaciones={Conciliaciones?.results} />
          ) : (
            <p>No hay conciliaciones disponibles.</p>
          )}
        </MainCard>
      </Grid>

      <Grid item xs={12} md={6} lg={4}>
        <AppOrderTimeline
          title="Linea de tiempo de correcciones"
          list={mappedData}
        />
      </Grid>
    </Grid>
  );
};
