import { BASE_API } from "../../utils/constants";
import { makeRequest } from "../Request";
const BASE_URL = `${BASE_API}/`;
  
  // Función para obtener los datos 
  export async function getControlDocOperadorApi(token, schema) {
    let url = `${BASE_URL}${schema}/operadores/documentoperador`;
    return makeRequest(url, "GET", token);
  }
  
  // Función para agregar un nuevo dato
  export async function addControlDocOperadorApi(data, token,schema) {
    const url = `${BASE_URL}${schema}/operadores/documentoperador/`;
    return makeRequest(url, "POST", token,  data);
  }
  
  // Función para actualizar los datos 
  export async function updateControlDocOperadorApi(id, data, token,schema) {
    const url = `${BASE_URL}${schema}/operadores/documentoperador/${id}/`;
    return makeRequest(url, "PATCH", token, data);
  }
  
  // Función para eliminar 
  export async function deleteControlDocOperadorApi(id, token,schema) {
    const url = `${BASE_URL}${schema}/operadores/documentoperador/${id}/`;
    return makeRequest(url, "DELETE", token);
  }