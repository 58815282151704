import React from "react";
import { Typography, Box, Tooltip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LocationOnIcon from "@material-ui/icons/LocationOn";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#909090", // Gris en lugar de rojo
    color: "#030303",
    borderRadius: 10,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    marginTop: theme.spacing(2),
    overflow: "hidden", // Para evitar que las esquinas redondeadas se superpongan
  },
  tableContainer: {
    width: "100%",
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
  },
  tableHeadCell: {
    backgroundColor: "#404040", // Gris oscuro para las celdas del encabezado
    color: "#f5f5f5", // Blanco para el texto del encabezado
    borderBottom: "1px solid #fff", // Línea blanca en la parte inferior
  },
  labelCell: {
    color: "#404040", // Gris más oscuro para las etiquetas
    
    borderBottom: "1px solid #fff", // Línea blanca en la parte inferior
    padding: theme.spacing(1),
  },
  dataCell: {
    color: "#f5f5f5", // Blanco más claro para los datos
    
    borderBottom: "1px solid #fff", // Línea blanca en la parte inferior
    padding: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

export function PrincipalHeader(props) {
  const classes = useStyles();
  const { planta } = props;

  return (
    <Box className={classes.root}>
      <div className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeadCell}>Ubicación</TableCell>
              <TableCell className={classes.tableHeadCell}>Razón Social</TableCell>
              <TableCell className={classes.tableHeadCell}>País</TableCell>
              <TableCell className={classes.tableHeadCell}>Departamento</TableCell>
              <TableCell className={classes.tableHeadCell}>Ciudad</TableCell>
              <TableCell className={classes.tableHeadCell}>Dirección</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.labelCell}>
                <Tooltip
                  title={
                    <>
                      <Typography variant="subtitle2">Planta principal</Typography>
                      <Typography variant="body2">
                        Al definir una planta como principal, los documentos generados por el software la usarán como la planta de operación del usuario.
                      </Typography>
                    </>
                  }
                >
                  <LocationOnIcon className={classes.icon} />
                </Tooltip>
              </TableCell>
              <TableCell className={classes.dataCell}>
                <Typography variant="h6">{planta?.razon_social}</Typography>
              </TableCell>
              <TableCell className={classes.dataCell}>
                <Typography variant="h6">COLOMBIA</Typography>
              </TableCell>
              <TableCell className={classes.dataCell}>
                <Typography variant="h6">{planta?.id_direcFc.department_name}</Typography>
              </TableCell>
              <TableCell className={classes.dataCell}>
                <Typography variant="h6">{planta?.id_direcFc.city_name}</Typography>
              </TableCell>
              <TableCell className={classes.dataCell}>
                <Typography variant="h6">{planta?.id_direcFc.street}</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </Box>
  );
}
