import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "..";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { addControlDocConductorApi, deleteControlDocConductorApi, getControlDocConductorApi, updateControlDocConductorApi } from "../../api/A_Conductor/documentosConductorControl";




export  function useControlDocConductor() {
    const { auth, logout } = useAuth();
    const navigate = useNavigate();
    const { unauthorized, SessionExpired, unauthorizedModal } = alertas();
  
    const [state, setState] = useState({
      loading: true,
      error: null,
      ControlDocConductor: null,
      ControlDocConductorDB: null,
    });
  
    const handleResponse = (response) => {
      if (response.status === 401) {
        SessionExpired();
        logout();
      } else if (response.status === 403) {
        unauthorized();
        navigate("/");
      }
    };
  
    const { loading, error, ControlDocConductor, ControlDocConductorDB } = state;
  
    const getControlDocConductorDB = async () => {
      try {
        const [result, response] = await getControlDocConductorApi(auth.token, auth.me.tenant);
        setState((prevState) => ({ ...prevState, ControlDocConductorDB: result.results }));
      } catch (error) {
        setState((prevState) => ({ ...prevState, error }));
      }
    };
  
    const getControlDocConductor = async (data) => {
      try {
        setState((prevState) => ({ ...prevState, loading: true }));
        const [result, response] = await getControlDocConductorApi(auth.token, auth.me.tenant, data);
        handleResponse(response);
        setState((prevState) => ({ ...prevState, loading: false, ControlDocConductor: result.results }));
      } catch (error) {
        setState((prevState) => ({ ...prevState, loading: false, error }));
      }
    };
  
    const addControlDocConductor = async (data) => {
     
      try {
        setState((prevState) => ({ ...prevState, loading: true }));
        const [result, response] = await addControlDocConductorApi(data, auth.token, auth.me.tenant);
        if (response.status === 403) {
          throw new Error(unauthorizedModal());
        }
        setState((prevState) => ({ ...prevState, loading: false }));
      } catch (error) {
        setState((prevState) => ({ ...prevState, loading: false, error }));
        throw error;
      }
    };
  
    const updateControlDocConductor = async (id, data) => {
      try {
        setState((prevState) => ({ ...prevState, loading: true }));
        const [result, response] = await updateControlDocConductorApi(id, data, auth.token, auth.me.tenant);
        if (response.status === 403) {
          throw new Error(unauthorizedModal());
        }
        setState((prevState) => ({ ...prevState, loading: false }));
      } catch (error) {
        setState((prevState) => ({ ...prevState, loading: false, error }));
        throw error;
      }
    };
  
    const deleteControlDocConductor = async (id) => {
      try {
        setState((prevState) => ({ ...prevState, loading: true }));
        const [result, response] = await deleteControlDocConductorApi(id, auth.token, auth.me.tenant);
        if (response.status === 403) {
          throw new Error(unauthorizedModal());
        }
        setState((prevState) => ({ ...prevState, loading: false }));
      } catch (error) {
        setState((prevState) => ({ ...prevState, loading: false, error }));
      }
    };
  
    return {
      loading,
      error,
      ControlDocConductorDB,
      ControlDocConductor,
      getControlDocConductorDB,
      getControlDocConductor,
      addControlDocConductor,
      updateControlDocConductor,
      deleteControlDocConductor,
    };
  }
  
