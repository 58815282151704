import React from "react";
import { Table } from "semantic-ui-react";


export  function VistasCamposDinamicos(props) {
       /*
  Componente

  -componente de visualizacion del modal de visualizacion para datos dinamicos
  
 
  -se valida que no llegue vacio para que no haya errores
  -se obtiene los datos  mediante props desde su PageAdmin
   */
  const { visualizacion } = props;


  return (
    <Table celled selectable>
    <Table.Header>
      <Table.Row>
        
        <Table.HeaderCell>Tipo </Table.HeaderCell>
        <Table.HeaderCell>Numero </Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
        
    {visualizacion?.documentos && visualizacion?.documentos.length > 0 &&
  visualizacion.documentos.map((dta) => (
    <Table.Row>
      <Table.Cell>{dta?.tipo}</Table.Cell>
      <Table.Cell>{dta?.numeroDoc}</Table.Cell>
    </Table.Row>
  ))
}


    </Table.Body>
  </Table>
  )
}
