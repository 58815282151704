import React from 'react'
import { Tab } from 'semantic-ui-react'
import { ParametrizacionDecimalesBseAdmin, SistemaMedicionBaseAdmin, Url } from '../../Client'
import decimales from "./Icons/29.--P-DE-DECIMALES-NEGRO.png";
import unidades from "./Icons/28.-UNIDADES-DE-NEDICÓN-NEGRO.png";

export function ParametrizacionInicial() {
  const panes = [
    {
      menuItem: { key: 'ParametrizacionDecimalesBseAdmin', content: <><img src={decimales} alt="Configuracion decimales" /> Configuracion decimales</> },
      render: () => <Tab.Pane><ParametrizacionDecimalesBseAdmin /></Tab.Pane>,
    },
    {
      menuItem: { key: 'SistemaMedicionBaseAdmin', content: <><img src={unidades} alt="Configuracion unidades de medida" /> Configuracion unidades de medida</> },
      render: () => <Tab.Pane><SistemaMedicionBaseAdmin /></Tab.Pane>,
    },
    // {
    //   menuItem: { key: 'SurlAdmin', content: <><img src={unidades} alt="Configuracion url Local" /> Configuracion URL Local</> },
    //   render: () => <Tab.Pane><Url /></Tab.Pane>,
    // },
  ]

  return (
    <Tab panes={panes} />
  )
}
