import { useState } from "react";
import { getOperadorApi, addOperadorApi, updateOperadorApi, deleteOperadorApi, getOpMaqApi } from "../../api/A_Operador/Operador";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "..";
import { useNavigate } from "react-router-dom";

export function useOperador() {
  const { auth, logout } = useAuth();
  const navigate = useNavigate();
  const { unauthorized, SessionExpired, errores, unauthorizedModal } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    Operador: null,
    OperadorDB: null,
  });

  const { loading, error, Operador, OperadorDB } = state;

  const handleResponse = (response) => {
    if (response.status === 401) {
      SessionExpired();
      logout();
    } else if (response.status === 403) {
      unauthorized();
      navigate("/");
    }
  };

  const getOperadorDB = async () => {
    try {
      const [result, response] = await getOperadorApi(auth.token, auth.me.tenant);
      setState((prevState) => ({ ...prevState, OperadorDB: result.results }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  const getOperador = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getOperadorApi(auth.token, auth.me.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, Operador: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addOperador = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addOperadorApi(data, auth.token, auth.me.tenant);
      if (response.status === 400) {
        if (result?.id_pers_oper) {
          throw new Error("Ya existe un operador registrado con estos datos");
        } else {
          errores();
        }
      } else if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateOperador = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateOperadorApi(id, data, auth.token, auth.me.tenant);
      if (response.status === 400) {
        if (result?.id_pers_oper) {
          throw new Error("Ya existe un operador registrado con estos datos");
        } else {
          errores();
        }
      } else if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteOperador = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteOperadorApi(id, auth.token, auth.me.tenant);
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const getOpMaq = async () => {
    try {
      const [result, response] = await getOpMaqApi(auth.token, auth.me.tenant);
      setState((prevState) => ({ ...prevState, OperadorDB: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  return {
    loading,
    error,
    OperadorDB,
    Operador,
    getOperadorDB,
    getOperador,
    addOperador,
    updateOperador,
    deleteOperador,
    getOpMaq,
  };
}
