export const columns = [
    
    {
      header: "Nit cliente",
      name: "nit_proveedor",
      maxWidth: 1000, defaultFlex: 1
    },
    {
      header: "Razon social",
      name: "nombre_proveedor",
      maxWidth: 1000, defaultFlex: 1
    },
    {
      header: "Empresa",
      name: "id_empresa",
      maxWidth: 1000,
      defaultFlex: 1,
      render: ({ data }) =>
        `${data.id_empresa?.email}- Celular: ${data.id_empresa?.celular} - Teléfono: ${data.id_empresa?.telefono}`
    },
  ];

  export const filterValue = [
    { name: 'nit_proveedor', operator: 'startsWith', type: 'string', value: '' },
    { name: 'nombre_proveedor', operator: 'startsWith', type: 'string', value: '' },
    { name: 'id_empresa.nombre', operator: 'startsWith', type: 'string', value: '' },
    
  ];
  