import { format } from "date-fns";
import PropTypes from "prop-types";
import { useState } from "react";
import ArrowRightIcon from "@heroicons/react/24/solid/ArrowRightIcon";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Divider,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Pagination } from "@mui/material";



export const OverviewLatestOrders = (props) => {
  const { orders = [], sx } = props;

  const rowsPerPage = 10; // Define el número de filas por página
  const [currentPage, setCurrentPage] = useState(1); // Almacena la página actual

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = orders ? orders.slice(indexOfFirstRow, indexOfLastRow) : [];

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const pageCount = orders ? Math.ceil(orders.length / rowsPerPage) : 0;

  return (
    <Card sx={sx}>
      <CardHeader title="Lista transacciones" />

      <Box sx={{ minWidth: 800 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Order Number</TableCell>
              <TableCell>Volumen Entregado</TableCell>
              <TableCell>Volumen Restante</TableCell>
              <TableCell>API</TableCell>
              <TableCell>BSW</TableCell>
              <TableCell>Tanque</TableCell>
              <TableCell>ctl</TableCell>
              <TableCell>cpl</TableCell>
              <TableCell>M Factor</TableCell>
              <TableCell>Dens. Promedio</TableCell>
              <TableCell>Temp. Promedio</TableCell>
              <TableCell>Pres. Promedio</TableCell>
              <TableCell>Tipo de Transacción</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentRows && currentRows.length > 0 ? (
              currentRows.map((order) => {
                const fechaInicialMed = format(
                  new Date(order?.fecha_inicial_med),
                  "dd/MM/yyyy"
                );
                const fechaFinalMed = format(
                  new Date(order?.fecha_final_med),
                  "dd/MM/yyyy"
                );

                return (
                  <TableRow hover key={order.id}>
                    <TableCell>{order.n_orden}</TableCell>
                    <TableCell>{order.v_entregado}</TableCell>
                    <TableCell>{order.v_restante}</TableCell>
                    <TableCell>{order.gravedad_api}</TableCell>
                    <TableCell>{order.bsw}</TableCell>
                    <TableCell>{order.tanque}</TableCell>
                    <TableCell>{order.ctl}</TableCell>
                    <TableCell>{order.cpl}</TableCell>
                    <TableCell>{order.meter_factor}</TableCell>
                    <TableCell>{order.densidad_promedio}</TableCell>
                    <TableCell>{order.temp_promedio}</TableCell>
                    <TableCell>{order.pres_promedio}</TableCell>
                    <TableCell>{order.tipo_transaccion}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={7}>No se encontraron órdenes</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>

      <Pagination
        count={pageCount}
        page={currentPage}
        onChange={handleChangePage}
        color="primary"
        size="small"
        siblingCount={1}
        boundaryCount={1}
        showFirstButton
        showLastButton
        style={{ marginTop: "10px" }}
      />

      <Divider />
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <Button
          color="inherit"
          endIcon={
            <SvgIcon fontSize="small">
              <ArrowRightIcon />
            </SvgIcon>
          }
          size="small"
          variant="text"
        >
          View all
        </Button>
      </CardActions>
    </Card>
  );
};

OverviewLatestOrders.propTypes = {
  orders: PropTypes.array,
  sx: PropTypes.object,
};
