import React, { useEffect } from 'react';
import { useTransaccionesDescargadero } from '../../../../hooks';
import { DataGridNoOptTransacciones } from '../../../Comons/TablaBasic';


export  function VistaTransaccionesDescargadero(props) {
     /*
  Componente

  -componente de visualizacion del modal de visualizacion para transacciones pertenecientes o una orden
  
 
  -se valida que no llegue vacio para que no haya errores
  -se obtiene los datos  mediante props desde su PageAdmin
   */
  
  const {onClose,onRefetch,Transacciones}=props;
  const { retriveTransaccionesDescargadero, TransaccionesDescargaderoRTR } = useTransaccionesDescargadero();
  

  useEffect(() => {
      retriveTransaccionesDescargadero(Transacciones.n_orden.toString());
  }, []);
  console.log("si",TransaccionesDescargaderoRTR)

  return (
    <div >


        { TransaccionesDescargaderoRTR ?


<DataGridNoOptTransacciones
        title={"Lista de Transacciones"}
        data={TransaccionesDescargaderoRTR}
        id="id_transaccion"
        // filterValue={filterValue}
        columns={columns}
        
        />


   

    :"" }
  </div>
  )
}
function CalculoCuatroDecimales(data) {
  let entero = parseInt(data / 10000);
  let decimal = ((data % 10000) / 10000).toFixed(4);
  return `${entero}.${decimal}`.replace(".0","");
}
function CalculoDosDecimales(data) {
let entero = parseInt(data / 100);
let ultimos_digitos = data % 100;
let decimal = (ultimos_digitos / 100).toFixed(2);
return `${entero}.${decimal}`.replace(".0","");
}
function CalculoUnDecimales(data) {
let entero = parseInt(data / 100);
      let decimal = ((data % 100) / 10).toFixed(1);
      return `${entero}${decimal}`;
}
const columns = [
  {
    header: "ID",
    name: "id_transaccion",
  },
  {
    header: "Fecha creacion",
    name: "time_created",
  },
  {
    header: "Numero de orden",
    name: "n_orden",
  },
  {
    header: "Volumen restante",
    name: "v_restante",
    render: (rowData) => {
      return CalculoDosDecimales(rowData.data.v_restante)
    }
  },
  {
    header: "Volumen entregado",
    name: "v_entregado",
    render: (rowData) => {
      return CalculoDosDecimales(rowData.data.v_entregado)
    }
  },
  {
    header: "GSV",
    name: "vol_gsv",
    render: (rowData) => {
      return CalculoDosDecimales(rowData.data.vol_gsv)
    }
  },
  {
    header: "GOV",
    name: "vol_gov",
    render: (rowData) => {
      return CalculoDosDecimales(rowData.data.vol_gov)
    }
  },
  {
    header: "numero de bahia",
    name: "num_bahia",
  },
  {
    header: "Presion promedio",
    name: "pres_promedio",
    render: (rowData) => {
      return CalculoDosDecimales(rowData.data.pres_promedio)
    }
  },
  {
    header: "Temperatura promedio",
    name: "temp_promedio",
    render: (rowData) => {
      return CalculoDosDecimales(rowData.data.temp_promedio)
    }
  },
  {
    header: "Densidad promedio",
    name: "densidad_promedio",
    render: (rowData) => {
      return CalculoUnDecimales(rowData.data.densidad_promedio)
    }
  },
  
  {
    header: "MeterFactor",
    name: "meter_factor",
    
  },
  {
    header: "CTL",
    name: "ctl",
    
  },
  {
    header: "CPL",
    name: "cpl",
    
  },
  {
    header: "CTPL",
    name: "ctpl",
    
  },
  {
    header: "BSW",
    name: "bsw",
  },
  {
    header: "Gravedad API",
    name: "gravedad_api",
    
  },
  {
    header: "Numero de transaccion",
    name: "num_transaccion",
  },
  {
    header: "Totalizer inicio",
    name: "totalizer_init",
    render: (rowData) => {
      return CalculoDosDecimales(rowData.data.totalizer_init)
    }
  },
  {
    header: "Totalizer final",
    name: "totalizer_end",
    render: (rowData) => {
      return CalculoDosDecimales(rowData.data.totalizer_end)
    }
  },
  {
    header: "Fecha inicio de cargue",
    name: "fecha_initcargue",
  },
  {
    header: "Fecha de final del cargue",
    name: "fecha_endcargue",
  },
  {
    header: "NSV",
    name: "nsv",
  },

];
