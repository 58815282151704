
  import { BASE_API } from "../../utils/constants";
  import { makeRequest } from "../Request";
  const BASE_URL = `${BASE_API}/`;
  
  
  // Función para obtener los datos 
  export async function getGruposPermisosApi(token,schema) {
    const url = `${BASE_URL}${schema}/multicliente/grupos_permiso`;
    return makeRequest(url, "GET", token);
  }
  
  // Función para agregar un nuevo dato
  export async function addGruposPermisosApi(data, token,schema) {
    const url = `${BASE_URL}${schema}/multicliente/grupos_permiso/`;
    return makeRequest(url, "POST", token, data);
  }
  
  // Función para actualizar los datos 
  export async function updateGruposPermisosApi(id, data, token,schema) {
    const url = `${BASE_URL}${schema}/multicliente/grupos_permiso/${id}/`;
    return makeRequest(url, "PUT", token, data);
  }
  
  // Función para eliminar 
  export async function deleteGruposPermisosApi(id, token,schema) {
    const url = `${BASE_URL}${schema}/multicliente/grupos_permiso/${id}/`;
    return makeRequest(url, "DELETE", token);
  }