import React, { useEffect, useState } from "react";
import { Funciones } from "./Funciones";
import { filterValueCI, columnsConsumoInterno } from "./ConsumoFormat"
import { Loader } from "semantic-ui-react";
import { DataGridBasic, ModalBasic } from "../../../Components/Comons";
import { HeaderPage } from "../../../Components/Client";
import { useConsumo } from "../../../hooks";
import { DataGridNoOpt } from "../../../Components/Comons/TablaBasic";
import CircularProgress from '@mui/material/CircularProgress';

export function ConsumoInterno() {
    const { loading, ConsumoInterno, getConsumoInterno } = useConsumo()

    const {
        refetch,
        contentModal,
        titleModal,
        showModal,
        handleAddConsumoInterno,
        openCloseModal,
    } = Funciones();

    useEffect(() => {
        getConsumoInterno()
    }, [refetch])

    const MemoizedModalBasic = React.memo(ModalBasic);

    const modifiedData = ConsumoInterno ? ConsumoInterno.map((item) => ({
        ...item,
        n_orden: item.ordenSuministroData.n_orden,
        nombre_tanque: item.ordenSuministroData.prod_tanque.data_tanques.nombre_tanque,
        cant_consumo: item.ordenSuministroData.cant_consumo,
        tipo_combustible: item.ordenSuministroData.tipo_combustible,
        proveedor: item.ordenSuministroData.supplier.nombre_proveedor,
        // operador: item.op.nombres + " " + item.op.apellidos
    })) : [];
    
    return (
        <>
            <HeaderPage
                title="Inventario de combustible"
                btnTitle="Registrar nuevo aprovisionamiento"
                icono="plus square icon"
                btnClick={handleAddConsumoInterno}
            />
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    {ConsumoInterno ? (
                        <DataGridNoOpt
                            data={modifiedData}
                            title="Inventario de combustible"
                            id="id_consumo_int"
                            filterValue={filterValueCI}
                            columns={columnsConsumoInterno}
                        />
                    ) : (
                        <div>No hay datos registrados</div>
                    )}
                </>
            )}

            {showModal && (
                <MemoizedModalBasic
                    show={showModal}
                    onClose={openCloseModal}
                    title={titleModal}
                    children={contentModal}
                />
            )}
        </>
    )
}