import React from "react";
import { Autocomplete } from "@mui/material";

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from "@mui/material/Checkbox";
import MenuItem from '@mui/material/MenuItem';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

import { Formik, useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useProductoBase } from "../../../../hooks";
import { alertas } from "../../../Comons/Alertas/alertas";

const IndexOpts = [
  { key: '1', value: '1', text: '1' },
  { key: '2', value: '2', text: '2' },
  { key: '3', value: '3', text: '3' },
  { key: '4', value: '4', text: '4' },
  { key: '5', value: '5', text: '5' },
  { key: '6', value: '6', text: '6' },
]

export function AddEditProductosBase(props) {
  const { Successful } = alertas();
  const { onClose, onRefetch, Productos } = props;
  const { addProductoBase, updateProductoBase } = useProductoBase();

  const formik = useFormik({
    initialValues: initialValues(Productos),
    validationSchema: Yup.object(newSchema()),
    validateOnChange: false,
    onSubmit: async (formValues) => {
      try {

        formValues.index = formValues.index.padStart(3, '0')

        //console.log(formValues)
        if (Productos) await updateProductoBase(Productos.id, formValues);
        else await addProductoBase(formValues);
        Successful();
        onClose();
        onRefetch();
      } catch (error) {
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });

  return (
    <Formik initialValues={initialValues()}>
      <form className="add-edit-secciones-form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="index"
              options={IndexOpts}
              getOptionLabel={(option) => option.text}
              value={
                IndexOpts?.find(
                  (opt) => opt.key === formik.values?.index
                ) || null
              }
              onChange={(_, value) => {
                formik.setFieldValue("index", value?.key || "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Índice del componente"
                  fullWidth
                  variant="outlined"
                  error={formik.errors.index && Boolean(formik.errors.index)}
                  helperText={formik.touched.index && formik.errors.index}
                  sx={{ background: "#fcfcfc" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="code"
              name="code"
              label="Código"
              variant="outlined"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.code}
              error={formik.touched.code && Boolean(formik.errors.code)}
              helperText={formik.touched.code && formik.errors.code}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="multiload_name"
              name="multiload_name"
              label="Nombre en Multiload"
              variant="outlined"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.multiload_name}
              error={
                formik.touched.multiload_name &&
                Boolean(formik.errors.multiload_name)
              }
              helperText={
                formik.touched.multiload_name && formik.errors.multiload_name
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="nombre"
              name="nombre"
              label="Nombre"
              variant="outlined"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.nombre}
              error={formik.touched.nombre && Boolean(formik.errors.nombre)}
              helperText={formik.touched.nombre && formik.errors.nombre}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.venta}
                  onChange={formik.handleChange}
                  name="venta"
                  color="primary"
                />
              }
              style={{
                paddingLeft: '10px'
              }}
              label="Establecer producto para la venta"
            />
            <Tooltip title="Indica que el producto está disponible para que sea comercializado" placement="top">
              <HelpIcon style={{ color: "gray", marginBottom: "8px" }} />
            </Tooltip>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Button type="submit" variant="contained" className="btn btn-danger" color="error" fullWidth>
              {Productos ? "Actualizar" : "Crear"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Formik>
  );
}

function initialValues(data) {
  return {
    index: data?.index || "",
    code: data?.code || "",
    multiload_name: data?.multiload_name || "",
    nombre: data?.nombre || "",
    venta: data?.venta || false,
  };
}

function newSchema() {
  return {
    index: Yup.string().required('El campo "index" es obligatorio'),
    code: Yup.string().required('El campo "code" es obligatorio'),
    multiload_name: Yup.string().required(
      'El campo "multiload_name" es obligatorio'
    ),
    nombre: Yup.string().required('El campo "nombre" es obligatorio'),
    venta: Yup.boolean().required('El campo "venta" es obligatorio'),
  };
}
