import React from "react";
import { useNavigate } from "react-router-dom";
import combustibles from "../../../Icons/black/ADMINISTRACIÓN COMBUSTIBLE.png";
import AccesoManual from "../../../Icons/black/Accesos/26.-ACCESO-MANUAL-NEGRO.png";
import Almacenamiento2 from "../../../Icons/black/Almacenamiento/16.TANQUES-NEGRO.png";
import control from "../../../Icons/black/Procesos/4.PROCESOS-NEGRO.png";
import Almacenamiento from "../../../Icons/black/Procesos/7. ALMACENAMIENTO NEGRO.png";
import Ordenes from "../../../Icons/black/Procesos/8.-ordenes-negro.png";
import Clientes from "../../../Icons/black/SociosDeNegocios/2.CLIENTES-NEGRO.png";
import TablaAforo from "../../../Icons/black/TablaAforo/19.-TABLA-DE-AFORO-NEGRO.png";
import Transportadora from "../../../Icons/black/Transporte/12.TRANSPORTADORANEGRO.png";
import personas from "../../../Icons/black/Usuarios/PERSONAS-NEGRO.png";
import usuario from "../../../Icons/black/Usuarios/USUARIO-NEGRO.png";
import Producto from "../../../Icons/black/Usuarios/producto-negroo.png";
import { useAuth } from "../../../hooks";
import maquinaria_e from "../../../Icons/black/MAQUINARIA ESTACIONARIA.png";

export function Items() {
  let navigate = useNavigate();
  const { auth } = useAuth();

  const items = [
    {
      key: "inicio",
      icon: <img className="pequeña" src="https://static.thenounproject.com/png/3574480-200.png" />,
      label: "Inicio",
      onClick: () => navigate("/"),

    },
    {
      key: "admin-cuentas",
      icon: <img className="pequeña" src={personas} />,

      label: "Administración interna",
      children: [
        
        auth.me?.is_superadmin && {
          key: "parametros-iniciales",
          icon: null,
          label: "Parámetros iniciales",
          onClick: () => navigate("/parametros/inicial"),
        },
        auth.me?.is_superadmin && {
          key: "configadmin",
          icon: null,
          label: "Configuración admin",
          onClick: () => navigate("/configadmin"),
        },
      ].filter(Boolean),
    },
    {
      key: "maestros",
      icon: <img className="pequeña" src={usuario} />,
      label: "Datos maestros",
      children: [
        // auth.me?.is_admin && {
        //   key: "grupos-permisos",
        //   icon: null,
        //   label: "Grupos de permisos",
        //   onClick: () => navigate("/GruposDePermisos"),
        // },
        auth.me?.is_admin && {
          key: "configclient",
          icon: null,
          label: "Configuración cliente",
          onClick: () => navigate("/configclient"),
        },
        // auth.me?.is_admin && {
        //   key: "computador-permisos",
        //   icon: null,
        //   label: "Decimales",
        //   onClick: () => navigate("/decimales/computador"),
        // },
        // auth.me?.is_admin && {
        //   key: "computador-validaciones",
        //   icon: null,
        //   label: "Validaciones",
        //   onClick: () => navigate("/validaciones/computador"),
        // },
        // auth.me?.is_admin && {
        //   key: "documentos-permisos",
        //   icon: null,
        //   label: "Documentos",
        //   onClick: () => navigate("/control/documentos"),
        // },
        // {
        //   key: "tankcustveradm",
        //   icon: null,
        //   label: "Inspección tanque",
        //   onClick: () => navigate("/tankcustveradm"),
        // },
        // {
        //   key: "vehcustveradm",
        //   icon: null,
        //   label: "Inspección vehículo",
        //   onClick: () => navigate("/vehcustveradm"),
        // },
        auth.me?.is_admin && {
          key: "usuarios",
          icon: null,
          label: "Usuarios",
          onClick: () => navigate("/usuarios"),
        },
        // {
        //   key: "operador",
        //   icon: null,
        //   label: "Operadores",
        //   onClick: () => navigate("/operadores"),
        // },
        {
          key: "plantas",
          icon: null,
          label: "Plantas",
          onClick: () => navigate("/plantas"),
        },

      ].filter(Boolean),
    },
    {
      key: "productos",
      icon: <img className="pequeña" src={Producto} />,
      label: "Productos",
      children: [
        // {
        //   key: "aditivos",
        //   icon: null,
        //   label: "Aditivos",
        //   onClick: () => navigate("/aditivos"),
        // },
        // {
        //   key: "mezclas",
        //   icon: null,
        //   label: "Productos",
        //   onClick: () => navigate("/mezclas"),
        // },
        // {
        //   key: "productosbase",
        //   icon: null,
        //   label: "Productos base",
        //   onClick: () => navigate("/producto/base"),
        // },
        // {
        //   key: "productostanque",
        //   icon: null,
        //   label: "Productos tanque",
        //   onClick: () => navigate("/producto/tanque"),
        // },
        {
          key: "productomezclas",
          icon: null,
          label: "Productos mezclas",
          onClick: () => navigate("/producto/mezclas"),
        },
      ],
    },
    {
      key: "negocios",
      icon: <img className="pequeña" src={Clientes} />,
      label: "Socios de negocios",
      children: [
        {
          key: "clientes",
          icon: null,
          label: "Socios de negocios",
          onClick: () => navigate("/clientes"),
        },
        
        {
          key: "transportadora",
          icon: null,
          label: "Transportadora",
          onClick: () => navigate("/transportadora"),
        },
      ],
    },
    (
      auth.me?.owner2?.modulos_Activos?.modulo_descargadero ||
      auth.me?.owner2?.modulos_Activos?.modulo_cargadero ||
      auth.me?.owner2?.modulos_Activos?.modulo_almacenamiento
    ) && {
      key: "procesos",
      icon: <img src={Almacenamiento}
        className="pequeña"
        alt="ícono"
        style={{ float: "left" }}
      />,
      label: "Procesos",
      children: [
        auth.me?.owner2?.modulos_Activos?.modulo_descargadero && {
          key: "receipt",
          icon: null,
          label: "Recibo",
          onClick: () => navigate("/receipt"),
        },
        auth.me?.owner2?.modulos_Activos?.modulo_almacenamiento && {
          key: "storage",
          icon: null,
          label: "Almacenamiento",
          onClick: () => navigate("/storage"),
        },

        auth.me?.owner2?.modulos_Activos?.modulo_cargadero && {
          key: "dispatch",
          icon: null,
          label: "Despacho",
          onClick: () => navigate("/dispatch"),
        },
        // {
        //   key: "turns",
        //   icon: null,
        //   label: "Turnos",
        //   onClick: () => navigate("/turns"),
        // },
        auth.me?.owner2?.modulos_Activos?.modulo_turnos && {
          key: "turnos-vista",
          icon: null,
          label: "Pantalla turnos",
          onClick: () => {
            window.open('/turnos/visualizacion', '_blank');
          }
        },

      ].filter(Boolean),
    },
    (
      auth.me?.owner2?.modulos_Activos?.modulo_descargadero ||
      auth.me?.owner2?.modulos_Activos?.modulo_cargadero
    ) && {
      key: "ordenes",
      icon: <img className="pequeña" src={Ordenes} />,
      label: "Órdenes",
      children: [
        auth.me?.owner2?.modulos_Activos?.modulo_descargadero && {
          key: "ordenes-recibo",
          icon: null,
          label: "Órdenes recibo",
          onClick: () => navigate("/OrdenesDescargadero"),
        },
        auth.me?.owner2?.modulos_Activos?.modulo_cargadero && {
          key: "ordenes-despacho",
          icon: null,
          label: "Órdenes despacho",
          onClick: () => navigate("/ordenes"),
        },
        {
          key: "Órdenes suministro",
          icon: null,
          label: "Órdenes suministro",
          onClick: () => navigate("/ordenessuministro"),
        },
        {
          key: "Conciliaciones",
          icon: null,
          label: "Conciliaciones",
          onClick: () => navigate("/conciliacion/modulos"),
        },
      ].filter(Boolean),
    },
    {
      key: "transporte",
      icon: <img className="pequeña" src={Transportadora} />,
      label: "Transporte",
      children: [
        {
          key: "conductor",
          icon: null,
          label: "Conductor",
          onClick: () => navigate("/conductor"),
        },
        {
          key: "vehiculos",
          icon: null,
          label: "Vehículos",
          onClick: () => navigate("/vehiculos"),
        },
        {
          key: "remolques",
          icon: null,
          label: "Remolques",
          onClick: () => navigate("/remolques"),
        },
      ],
    },
    // {
    //   key: "maquinaria",
    //   icon: <img className="pequeña" src={maquinaria_e} />,

    //   label: "Equipos",
    //   children: [
    //     {
    //       key: "maquinariamgm",
    //       icon: null,
    //       label: "Registro de equipos",
    //       onClick: () => navigate("/maquinariamgm"),
    //     },
    //   ],
    // },
    (
      auth.me?.owner2?.modulos_Activos?.modulo_descargadero ||
      auth.me?.owner2?.modulos_Activos?.modulo_cargadero ||
      auth.me?.owner2?.modulos_Activos?.modulo_almacenamiento
    ) && {
      key: "almacenamiento",
      icon: <img className="pequeña" src={Almacenamiento2} />,
      label: "Almacenamiento",
      children: [
        {
          key: "tanques",
          icon: null,
          label: "Tanques",
          onClick: () => navigate("/tanques"),
        },
        auth.me?.owner2?.modulos_Activos?.modulo_almacenamiento && {
          key: "mediciones",
          icon: null,
          label: "Mediciones",
          onClick: () => navigate("/data-ingreso"),
        },
        auth.me?.owner2?.modulos_Activos?.modulo_almacenamiento && {
          key: "conciliaciones-almacenamiento",
          icon: null,
          label: "Correcciones",
          onClick: () => navigate("/conciliaciones"),
        },
        auth.me?.owner2?.modulos_Activos?.modulo_almacenamiento && {
          key: "tabla-aforo",
          icon: <img className="pequeña" src={TablaAforo} />,
          label: "Tabla de aforo",
          children: [
            {
              key: "tabla-aforo-base",
              icon: null,
              label: "Tabla de aforo",
              onClick: () => navigate("/tabla_aforo"),
            },
            {
              key: "secciones",
              icon: null,
              label: "Secciones",
              onClick: () => navigate("/aforo/secciones"),
            },
            {
              key: "fracciones",
              icon: null,
              label: "Fracciones",
              onClick: () => navigate("/aforo/fracciones"),
            },

          ],
        },
      ].filter(Boolean),
    },
    (
      auth.me?.owner2?.modulos_Activos?.modulo_turnos ||
      auth.me?.owner2?.modulos_Activos?.modulo_accesos
    ) && {
      key: "accesos",
      icon: <img src={AccesoManual} className="pequeña" alt="ícono" />,
      label: "Accesos",
      children: [
        auth.me?.owner2?.modulos_Activos?.modulo_accesos && {
          key: "configAcceso",
          icon: null,
          label: "Configurar accesos",
          onClick: () => navigate("/configAcceso"),
        },
        auth.me?.owner2?.modulos_Activos?.modulo_accesos && {
          key: "accesoManual",
          icon: null,
          label: "Acceso manual",
          onClick: () => navigate("/accesoManual"),
        },
        auth.me?.owner2?.modulos_Activos?.modulo_turnos && {
          key: "turnos",
          icon: null,
          label: "Turnos",
          onClick: () => navigate("/turnos"),
        },
        // auth.me?.owner2?.modulos_Activos?.modulo_turnos && {
        //   key: "turnos-vista",
        //   icon: null,
        //   label: "Pantalla turnos",
        //   onClick: () => {
        //     window.open('/turnos/visualizacion', '_blank');
        //   }
        // },
      ].filter(Boolean),
    },
    (
      auth.me?.owner2?.modulos_Activos?.modulo_descargadero ||
      auth.me?.owner2?.modulos_Activos?.modulo_cargadero
    ) && {
      key: "controladores",
      icon: <img className="pequeña" src={control} />,

      label: "Controladores",
      children: [
        // {
        //   key: "mapeocontrolado",
        //   icon: null,
        //   label: "Mapeos",
        //   onClick: () => navigate("/mapeos/edit"),
        // },
        // {
        //   key: "tipocontrolador",
        //   icon: null,
        //   label: "Tipo Controlador",
        //   onClick: () => navigate("/tiposcontroladores"),
        // },
        {
          key: "islas",
          icon: null,
          label: "Islas",
          onClick: () => navigate("/islas"),
        },
        {
          key: "controlador",
          icon: null,
          label: "Controlador",
          onClick: () => navigate("/controlador"),
        },
        // {
        //   key: "mapping",
        //   icon: null,
        //   label: "Mapping",
        //   onClick: () => navigate("/mapping"),
        // },
        // {
        //   key: "control",
        //   icon: null,
        //   label: "Control",
        //   onClick: () => navigate("/control"),
        // },
        {
          key: "maparegistros",
          icon: null,
          label: "Mapa de registros",
          children: [
            {
              key: "mapping",
              icon: null,
              label: "Mapping",
              onClick: () => navigate("/mapping")
            },
            {
              key: "/mapeos/edit",
              icon: null,
              label: "Mapeo",
              onClick: () => navigate("/mapeos/edit")
            }
          ],
        },
        {
          key: "sensores",
          icon: null,
          label: "Sensores",
          children: [
            {
              key: "sensoresadmin",
              icon: null,
              label: "Registro",
              onClick: () => navigate("/sensoresadmin")
            },
            {
              key: "sensoresgraphs",
              icon: null,
              label: "Visualización",
              onClick: () => navigate("/sensoresgraphs")
            }
          ],
        }
      ],
    },
    (
      auth.me?.owner2?.modulos_Activos?.modulo_gestion
    ) && {
      key: "fuelmgm",
      icon: <img className="pequeña" src={combustibles} />,
      label: "Combustible",
      children: [
        {
          key:"suministrostatus",
          icon: null,
          label: "Últimas actualizaciones",
          onClick: () => navigate("/suministrostatus"),
        },
        {
          key:"maquinariamgm",
          icon: null,
          label: "Vehiculos & Máquinas",
          onClick: () => navigate("/maquinariamgm"),
        },
        {
          key: "fueladm",
          icon: null,
          label: "Suministro",
          onClick: () => navigate("/fuelmgm"),
        },
        {
          key: "consumointerno",
          icon: null,
          label: "Llenado de tanques",
          onClick: () => navigate("/consumointerno"),
        },
        {
          key: "emisiones",
          icon: null,
          label: "Registro de emisiones",
          onClick: () => navigate("/emisiones"),
        }
      ],
    },
  ];
  return {
    items
  };
}
