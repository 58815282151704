import { useState } from "react";
import {
  getComputadorDecimalesApi,
  updateComputadorDecimalesApi
} from "../../api/A_Parametrizaciones/ComputadoresDecimal";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "../";


export  function useDecimalesComputador() {
    const { auth, logout } = useAuth();
    const { unauthorized, SessionExpired } = alertas();
  
    const [state, setState] = useState({
      loading: true,
      error: null,
      Decimales: null,
    });
  
    const { loading, error, Decimales } = state;
  
    const getDecimales = async () => {
      try {
        setState((prevState) => ({ ...prevState, loading: true }));
        const [result, response] = await getComputadorDecimalesApi(auth.token, auth.me.tenant);
        if (response.status === 401) {
          SessionExpired();
          logout();
        }
        if (response.status === 403) {
          unauthorized();
        }
        setState((prevState) => ({ ...prevState, loading: false, Decimales: result }));
      } catch (error) {
        setState((prevState) => ({ ...prevState, loading: false, error }));
      }
    };
  
  
    const updateDecimales = async (id, data) => {
      try {
        setState((prevState) => ({ ...prevState, loading: true }));
        await updateComputadorDecimalesApi(id, data, auth.token, auth.me.tenant);
        setState((prevState) => ({ ...prevState, loading: false }));
      } catch (error) {
        setState((prevState) => ({ ...prevState, loading: false, error }));
        throw error;
      }
    };
  
    
  
    return {
      loading,
      error,
      Decimales,
      getDecimales,
      updateDecimales,
    };
  }
