import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'semantic-ui-css/semantic.min.css'

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from "react-redux";
import store from "./vistas/store/configurationStore";
import { theme } from "./vistas/theme";
import { ThemeProvider } from "styled-components";
import { StyleProvider } from '@ant-design/cssinjs';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme.default}>
      <StyleProvider hashPriority="high">
    <App />
    </StyleProvider>
    </ThemeProvider>
    </Provider>
  </React.StrictMode>
  
);

