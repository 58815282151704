import { useFormik, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import TextField from '@mui/material/TextField'
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import * as Yup from "yup";
import { alertas } from "../../Comons/Alertas/alertas";
import { useConsumo, useTanques, useProveedores } from "../../../hooks";
import { useAuth } from "../../../hooks";
import { proveedorSelect, tanqueSelectWProd } from "../../Comons"
import CircularProgress from '@mui/material/CircularProgress';

export function AddEditCInterno(props) {
    const { auth } = useAuth()
    const { Successful, errores } = alertas();
    const { onClose, onRefetch, ConsumoInterno } = props;
    const { addConsumoInterno, updateConsumoInterno, ordenesSuministro, getOrdenesSuministro } = useConsumo()

    const [ordenFound, setOrdenFound] = useState(false)

    useEffect(() => {
        getOrdenesSuministro()
    }, []);

    const formik = useFormik({
        initialValues: initialValues(ConsumoInterno),
        validationSchema: Yup.object(ConsumoInterno ? updateScheme() : newScheme()),
        validateOnChange: false,
        onSubmit: async (formValue) => {

            try {
                if (!ordenFound) {
                    toast.error("Ese número de orden no existe", {
                        position: "top-center",
                    });
                    return
                }

                console.log(formValue)

                // Si se proporciona un objeto se actualiza, de lo contrario se crea uno nuevo
                if (ConsumoInterno) await updateConsumoInterno(ConsumoInterno.id_consumo_int, formValue);
                else await addConsumoInterno(formValue);
                onRefetch();
                onClose();
                Successful()
            } catch (error) {
                // Muestra un mensaje de error si ocurre algún problema
                if (error?.message) {
                    toast.error(error.message, {
                        position: "top-center",
                    });
                } else {
                    onClose();
                }
            }
        },
    })

    const handleOrdenChange = (event) => {
        formik.setFieldValue('n_suministro_orden', event.target.value)
        const n_orden_obj = event.target.value

        const ordenSumninistro = ordenesSuministro.find((orden) => orden.n_orden == n_orden_obj)

        if (ordenSumninistro != undefined) {
            setOrdenFound(true)
            formik.setFieldValue('tanque', ordenSumninistro.prod_tanque.data_tanques.nombre_tanque)
            formik.setFieldValue('proveedor', ordenSumninistro.supplier.nombre_proveedor)
            formik.setFieldValue('abierta', ordenSumninistro.abierta ? "Abierta" : "Cerrada")
            formik.setFieldValue('fecha_vencimiento', ordenSumninistro.fecha_vencimiento)
            formik.setFieldValue('cant_consumo', ordenSumninistro.cant_consumo)
        } else {
            setOrdenFound(false)
        }
    };

    if (!ordenesSuministro) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </div>
        )
    }

    return (
        <>
            <Formik initialValues={initialValues()}>
                <div>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Número de orden"
                                    variant="outlined"
                                    name="n_suministro_orden"
                                    value={formik.values.n_suministro_orden}
                                    onChange={handleOrdenChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Precio"
                                    variant="outlined"
                                    name="precio"
                                    value={formik.values.precio}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            {ordenFound ? (
                                <>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Tanque"
                                            variant="outlined"
                                            name="tanque"
                                            value={formik.values.tanque}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Proveedor"
                                            variant="outlined"
                                            name="proveedor"
                                            value={formik.values.proveedor}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            fullWidth
                                            label="Estado"
                                            variant="outlined"
                                            name="abierta"
                                            value={formik.values.abierta}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            fullWidth
                                            label="Cantidad"
                                            variant="outlined"
                                            name="cant_consumo"
                                            value={formik.values.cant_consumo}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            fullWidth
                                            label="Fecha límite"
                                            variant="outlined"
                                            name="fecha_vencimiento"
                                            value={formik.values.fecha_vencimiento}
                                            disabled
                                        />
                                    </Grid>
                                </>
                            ) : ""}

                            <Grid item xs={12} sm={12}>
                                <Button fullWidth type="submit" variant="contained" color="error" className="btn btn-danger">
                                    Crear registro de suministro y cerrar orden
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Formik>
        </>
    )
}

function initialValues(data) {
    return {
        n_suministro_orden: data?.n_suministro_orden || "",
        precio: data?.precio || "",
        tanque: data?.tanque || "",
        proveedor: data?.proveedor || "",
        abierta: data?.abierta || "",
        fecha_vencimiento: data?.fecha_vencimiento || "",
        cant_consumo: data?.cant_consumo || ""
    };
}

function newScheme() {
    return {
        n_suministro_orden: Yup.string(),
        precio: Yup.string(),
        tanque: Yup.string(),
        proveedor: Yup.string(),
        abierta: Yup.string(),
        fecha_vencimiento: Yup.string(),
        cant_consumo: Yup.string(),
    };
}

function updateScheme() {
    return {
        n_suministro_orden: Yup.string(),
        precio: Yup.string(),
    };
}