import { useState } from "react";
import { getMulticlienteApi, addMulticlienteApi, updateMulticlienteApi, deleteMulticlienteApi } from "../../api/A_Multicliente/Multicliente";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "../";

export function useMulticliente() {
  const { auth, logout } = useAuth();
  const { unauthorized, SessionExpired, errores, unauthorizedModal } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    Multicliente: null,
    MulticlienteDB: null,
  });

  const { loading, error, Multicliente, MulticlienteDB } = state;

  const handleResponse = (response) => {
    if (response.status === 401) {
      SessionExpired();
      logout();
    } else if (response.status === 403) {
      unauthorized();
    }
  };

  const getMulticlienteDB = async () => {
    try {
      const [result, response] = await getMulticlienteApi(auth.token, auth.me.tenant);
      setState((prevState) => ({ ...prevState, MulticlienteDB: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  const getMulticliente = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getMulticlienteApi(auth.token, auth.me.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, Multicliente: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addMulticliente = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addMulticlienteApi(data, auth.token, auth.me.tenant);
      if (response.status === 400) {
        errores();
      } else if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateMulticliente = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateMulticlienteApi(id, data, auth.token, auth.me.tenant);
      if (response.status === 400) {
        errores();
      } else if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteMulticliente = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteMulticlienteApi(id, auth.token, auth.me.tenant);
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    MulticlienteDB,
    Multicliente,
    getMulticlienteDB,
    getMulticliente,
    addMulticliente,
    updateMulticliente,
    deleteMulticliente,
  };
}
