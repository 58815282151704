import { BASE_API } from "../../utils/constants";
import { makeRequest } from "../Request";
const BASE_URL = `${BASE_API}/`;

export async function getMaquinasApi(token, schema) {
    const url = `${BASE_URL}${schema}/maquinaria/maquinariaadm`;
    return makeRequest(url, "GET", token);
}

export async function postMaquinaApi(data, token, schema) {
    const url = `${BASE_URL}${schema}/maquinaria/maquinariaadm/`;
    return makeRequest(url, "POST", token, data);
}

export async function updtMaquinaApi(id_maquina, data, token, schema) {
    const url = `${BASE_URL}${schema}/maquinaria/maquinariaadm/${id_maquina}/`;
    return makeRequest(url, "PATCH", token, data)
}

export async function deleteMaquinaApi(id_maquina, token, schema) {
    const url = `${BASE_URL}${schema}/maquinaria/maquinariaadm/${id_maquina}/`;
    return makeRequest(url, "DELETE", token);
}