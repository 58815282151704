import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#FFFFFF',
    borderRadius: 10,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },
  title: {

  },
  secTitle: {

  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column', // Arrange items vertically
    alignItems: 'center', // Center items horizontally
  },
  button: {
    margin: theme.spacing(1),
  },
  sameSizeButton: {
    width: '400px', // Adjust the width as needed
  },
  buttonMargin: {
    marginTop: theme.spacing(0.2), // Add margin at the top
  },
}));


export function HeaderPageWBtn(props) {
  const classes = useStyles();
  const { title, btnTitle, btnClick, secBtnTitle, secBtnClick } = props;

  return (
    <Box className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        {title}
      </Typography>
      <div className={classes.columnContainer}>
        {btnTitle && (
          <Button
            variant="contained"
            color="secondary"
            className={`${classes.button} ${classes.sameSizeButton} `}
            startIcon={<AddIcon />}
            onClick={btnClick}
          >
            {btnTitle}
          </Button>
        )}
        <br></br>
        {secBtnTitle && (
          <Button
            variant="contained"
            color="secondary"
            className={`${classes.button} ${classes.sameSizeButton} ${classes.buttonMargin}`}
            startIcon={<AddIcon />}
            onClick={secBtnClick}
          >
            {secBtnTitle}
          </Button>
        )}
      </div>
    </Box>
  );
}
