import { BASE_API } from "../../utils/constants";
import { makeRequest } from "../Request";
const BASE_URL = `${BASE_API}/`;

export async function addMultiLdProdApi(data, token, schema) {
    const url = `${BASE_URL}${schema}/conexiones/mliiprodcreation`;
    return makeRequest(url, "POST", token, data);
}

export async function delMLIIProdApi(data, token, schema) {
    const url = `${BASE_URL}${schema}/conexiones/mliiproddeletion`;
    return makeRequest(url, "POST", token, data);
}

export async function delMLIIAsgnAuthProdsApi(data, token, schema) {
    const url = `${BASE_URL}${schema}/conexiones/mliiasngauthprodsdel`;
    return makeRequest(url, "POST", token, data);
}
