import React, { useCallback, useState } from 'react';

import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup'

import { useAuth, useModulos } from '../../../hooks';
import { GraySwitch } from "./styles";

export function ModuloControl() {
  const { auth } = useAuth();

  const moduloDocumentos = auth?.me?.owner2.modulos_Activos.modulo_documentos;
  const moduloId = auth?.me?.owner2.modulos_Activos.id;

  const [refetch, setRefetch] = useState(false);
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);

  const { updateModuloDocumental } = useModulos();

  const handleSwitchChange = () => {
    const updatedData = {
      modulo_documentos: !moduloDocumentos,
    };

  updateModuloDocumental(moduloId, updatedData)
  .then(() => {
    onRefetch();
    window.location.reload();
  })
  .catch((error) => {
    console.log('Error en la actualización del módulo de documentos:', error);
  });
};
  return (
    <Box>
      <FormGroup>
        <FormControlLabel
          control={<GraySwitch checked={moduloDocumentos} onChange={handleSwitchChange} />}
          label="Módulo de Documentos"
        />
      </FormGroup>
    </Box>
  );
}
