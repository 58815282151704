//import { Button } from "@material-ui/core";
//import { Grid } from "@mui/material";
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import React from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useHazard } from "../../../hooks";
import { alertas } from "../../Comons/Alertas/alertas";

export function AddEditHazards(props) {
  const { updateHazard, getHazardMultiload } = useHazard();
  const { Successful } = alertas();
  const { onClose, onRefetch, Data } = props;

  const formik = useFormik({
    initialValues: initialValues(Data),
    validationSchema: Yup.object(validationSchema()),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        await updateHazard(Data.id_hazard, formValue);
        Successful();
        onRefetch();
        onClose();
      } catch (error) {
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });

  const handleGetHazardMultiload = async () => {
    try {
      // Llama a la función getHazardMultiload y pasa los datos de Data
      await getHazardMultiload(Data);
      Successful("Hazard obtenido exitosamente"); // Mensaje de éxito
    } catch (error) {
      if (error?.message) {
        toast.error(error.message, {
          position: "top-center",
        });
      }
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label="Hazard uno"
              name="hazard_uno"
              placeholder="Hazard uno"
              {...formik.getFieldProps("hazard_uno")}
              error={formik.touched.hazard_uno && formik.errors.hazard_uno}

            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label="Hazard dos"
              name="hazard_dos"
              placeholder="Hazard dos"
              {...formik.getFieldProps("hazard_dos")}
              error={formik.touched.hazard_dos && formik.errors.hazard_dos}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              required
              fullWidth
              label="Hazard tres"
              name="hazard_tres"
              placeholder="Hazard tres"
              {...formik.getFieldProps("hazard_tres")}
              error={formik.touched.hazard_tres && formik.errors.hazard_tres}

            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              fullWidth
              label="Hazard cuatro"
              name="hazard_cuatro"
              placeholder="Hazard cuatro"
              {...formik.getFieldProps("hazard_cuatro")}
              error={formik.touched.hazard_cuatro && formik.errors.hazard_cuatro}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              fullWidth
              label="Hazard cinco"
              name="hazard_cinco"
              placeholder="Hazard cinco"
              {...formik.getFieldProps("hazard_cinco")}
              error={formik.touched.hazard_cinco && formik.errors.hazard_cinco}

            />
          </Grid>


          {/* Fila para el botón "Actualizar" o "Crear" */}
          <Grid item xs={12} sm={12}>
            <Button
              type="submit"
              variant="contained"
              color="error"
              fullWidth
              className="btn btn-danger"
            >
              {Data ? "Actualizar" : "Crear"}
            </Button>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              className="btn btn-primary"
              onClick={handleGetHazardMultiload}
            >
              Obtener Hazard
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}


function initialValues(data) {
  return {
    hazard_uno: data?.hazard_uno || "",
    hazard_dos: data?.hazard_dos || "",
    hazard_tres: data?.hazard_tres || "",
    hazard_cuatro: data?.hazard_cuatro || "",
    hazard_cinco: data?.hazard_cinco || "",
  }
}

function validationSchema() {
  return {
    hazard_uno: Yup.string().required("El dato es requerido"),
    hazard_dos: Yup.string().required("El dato es requerido"),
    hazard_tres: Yup.string().required("El dato es requerido"),
    hazard_cuatro: Yup.string().required("El dato es requerido"),
    hazard_cinco: Yup.string().required("El dato es requerido"),
  }
}