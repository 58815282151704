export const columns = [
    { header: "Puerto", name: "puerto", maxWidth: 1000, defaultFlex: 1 },
    { header: "Módulo", name: "moduloText", maxWidth: 1000, defaultFlex: 1 },
    { header: "Rango de entrada", name: "inputRangeText", maxWidth: 1000, defaultFlex: 1 },
    { header: "Tipo", name: "tipo", maxWidth: 1000, defaultFlex: 1 },
    { header: "Categoría", name: "categoria", maxWidth: 1000, defaultFlex: 1 },
    { header: "Unidad de medida", name: "unidad", maxWidth: 1000, defaultFlex: 1 },
]

export const tagColumns = [
    { header: "Tipo", name: "tipo", maxWidth: 1000, defaultFlex: 1 },
    { header: "Categoría", name: "categoria", maxWidth: 1000, defaultFlex: 1 },
    { header: "Unidad", name: "unidad", maxWidth: 1000, defaultFlex: 1 },
    { header: "Marca", name: "marca", maxWidth: 1000, defaultFlex: 1 },
    { header: "Modelo", name: "modelo", maxWidth: 1000, defaultFlex: 1 },
]

export const filterValue = [
    { name: 'puerto', operator: 'startsWith', type: 'string', value: '' },
    { name: 'moduloText', operator: 'startsWith', type: 'string', value: '' },
    { name: 'inputRangeText', operator: 'startsWith', type: 'string', value: '' },
    { name: 'tipo', operator: 'startsWith', type: 'string', value: '' },
    { name: 'categoria', operator: 'startsWith', type: 'string', value: '' },
    { name: 'unidad', operator: 'startsWith', type: 'string', value: '' },
]

export const tagFilterValue = [
    { name: 'tipo', operator: 'startsWith', type: 'string', value: '' },
    { name: 'categoria', operator: 'startsWith', type: 'string', value: '' },
    { name: 'unidad', operator: 'startsWith', type: 'string', value: '' },
    { name: 'modelo', operator: 'startsWith', type: 'string', value: '' },
    { name: 'marca', operator: 'startsWith', type: 'string', value: '' },
]