import React, { useState, useCallback } from "react";
import Swal from "sweetalert2";
import { AddEditClientes } from "../../../Components/Client";
import { useCliente } from "../../../hooks";

export function Funciones() {
  const { deleteCliente } = useCliente();
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);

  const handleAddClientes = useCallback(() => {
    setTitleModal("Nuevo cliente");
    setContentModal(
      <AddEditClientes onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const handleUpdateClientes = useCallback((data) => {
    setTitleModal("Actualizar cliente");
    setContentModal(
      <AddEditClientes
        onClose={openCloseModal}
        onRefetch={onRefetch}
        Clientes={data}
      />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const handleDeleteClientes = useCallback(async (data) => {
    const result = await Swal.fire({
      title: "Estas seguro?",
      text: "No podrás revertir esta acción!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si, eliminar!",
      cancelButtonText: "No, cancelar!",
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      await deleteCliente(data.id_cliente);
      Swal.fire("Eliminado!", "El dato ha sido eliminado.", "success");
      onRefetch();
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelado", "Operación cancelada", "error");
    }
  }, [deleteCliente, onRefetch]);

  return {
    handleAddClientes,
    handleDeleteClientes,
    handleUpdateClientes,
    openCloseModal,
    refetch,
    contentModal,
    titleModal,
    showModal,
  };
}
