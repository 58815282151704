import { useEffect, useState } from "react";
import * as React from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { alertas } from "../../Comons/Alertas/alertas";
import { useVehiculos } from "../../../hooks";
import {
    Checkbox,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@material-ui/core";

import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';

export function ViewVehicleChecklistHistoric(props) {
    const { onClose, Vehiculos } = props;
    const { DataChklst, getVehVerById } = useVehiculos()
    const [loading, setLoading] = useState(true);
    const closeModal = async () => {
        onClose()
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableCellOp = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: 'rgba(76, 75, 75, 0.8)',
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await getVehVerById(Vehiculos.id_vehiculo)
            setLoading(false);
        }
        fetchData()
    }, [Vehiculos.id_vehiculo]);

    return (
        <>
            <Grid container spacing={3}>
                {loading ? (
                    <>
                        <Grid item xs={12} sm={12}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                                <CircularProgress />
                            </div>
                        </Grid>
                    </>
                ) : (
                    <>
                        {DataChklst && DataChklst.length > 0 ? (
                            <>
                                <Grid item xs={12} sm={12}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell align="center">
                                                        <strong>Placa</strong>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        <strong>Marca</strong>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        <strong>Modelo</strong>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        <strong>Tipo de vehículo</strong>
                                                    </StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <StyledTableRow>
                                                    <StyledTableCell component="th" align="center" scope="row">
                                                        {Vehiculos?.placa_veh}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        {Vehiculos?.marca}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        {Vehiculos?.modelo}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        {Vehiculos?.tipo_veh == 'carro_tanque' ? 'Carro tanque' : 'Tracto camión'}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    {DataChklst && DataChklst?.map((item, index) => (
                                        <div key={index}>
                                            <Accordion>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: item.approved ? 'rgba(211, 244, 189, 0.8)' : 'rgba(250, 141, 150, 0.8)' }}>
                                                    <Typography variant="h6">
                                                        {DataChklst?.length > 0 && (
                                                            <>
                                                                <div>Verificación de la fecha {new Date(item.fecha_crea).toLocaleString('es-CO', { dateStyle: 'long', timeStyle: 'short' })}</div>
                                                                <div>Resultado de la revisión: {item.approved ? "Aprobado" : "No aprobado"}</div>
                                                            </>
                                                        )}
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={12}>
                                                            <TableContainer component={Paper}>
                                                                <Table sx={{ minWidth: 700 }} aria-label="customized table" key={index}>
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <StyledTableCellOp align="center">
                                                                                <strong>Nombre del operario</strong>
                                                                            </StyledTableCellOp>
                                                                            <StyledTableCellOp align="center">
                                                                                <strong>Núm. documento</strong>
                                                                            </StyledTableCellOp>
                                                                            <StyledTableCellOp align="center">
                                                                                <strong>Correo</strong>
                                                                            </StyledTableCellOp>
                                                                            <StyledTableCellOp align="center">
                                                                                <strong>Celular</strong>
                                                                            </StyledTableCellOp>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {DataChklst && DataChklst.length > 0 && (
                                                                            <StyledTableRow>
                                                                                <StyledTableCell>{item.op.nombres + " " + item.op.apellidos}</StyledTableCell>
                                                                                <StyledTableCell>{item.op.n_documento}</StyledTableCell>
                                                                                <StyledTableCell>{item.op.email}</StyledTableCell>
                                                                                <StyledTableCell>{item.op.celular}</StyledTableCell>
                                                                            </StyledTableRow>
                                                                        )}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12}>
                                                            <Table>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell><strong>Verificación</strong></TableCell>
                                                                        <TableCell><strong>¿Cumple?</strong></TableCell>
                                                                        <TableCell><strong>¿Opcional? (no modificable)</strong></TableCell>
                                                                        <TableCell><strong>¿Obligatoria? (no modificable)</strong></TableCell>
                                                                        <TableCell><strong>¿Restrictiva? (no modificable)</strong></TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {DataChklst && DataChklst.length > 0 && item.checklist?.map((subItem, index) => (
                                                                        <TableRow key={index}>
                                                                            <TableCell>{subItem.check}</TableCell>
                                                                            <TableCell>
                                                                                <Checkbox
                                                                                    readOnly
                                                                                    checked={subItem.valor}
                                                                                    name={subItem.check}
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Checkbox
                                                                                    readOnly
                                                                                    checked={subItem.opcional}
                                                                                    name={subItem.check}
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Checkbox
                                                                                    readOnly
                                                                                    checked={subItem.obligatoria}
                                                                                    name={subItem.check}
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Checkbox
                                                                                    readOnly
                                                                                    checked={subItem.restrictiva}
                                                                                    name={subItem.check}
                                                                                />
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                            <br></br>
                                        </div>
                                    ))}
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <Button fullWidth variant="contained" className="btn btn-danger" onClick={closeModal}>
                                        Cerrar
                                    </Button>
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item xs={12} sm={1}>

                                </Grid>
                                <Grid item xs={12} sm={10} style={{ marginTop: '20px' }}>
                                    <Typography variant="h5" component="div" style={{ textAlign: "center" }}>
                                        No hay datos para mostrar
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={1}>

                                </Grid>
                            </>
                        )}
                    </>
                )}
            </Grid>
        </>
    );
}