import React, { useState, useCallback } from "react";
import Swal from "sweetalert2";
import { AddEditProductosMezcla } from "../../../../Components/Client";
import { useProductoMezcla, useMultiLdProd } from "../../../../hooks";


export function Funciones() {
  const { deleteProductoMezcla } = useProductoMezcla();
  const { handleDeleteMLIIProds, handleDeleteAsgnAuthProds } = useMultiLdProd();
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);

  const handleAdd = useCallback(() => {
    setTitleModal("Nuevo producto");
    setContentModal(
      <AddEditProductosMezcla onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const handleUpdate = useCallback((data) => {
    setTitleModal("Actualizar producto");
    setContentModal(
      <AddEditProductosMezcla
        onClose={openCloseModal}
        onRefetch={onRefetch}
        ProductosMezcla={data}
      />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const handleDelete = useCallback(async (data) => {
    const result = await Swal.fire({
      title: "Estas seguro?",
      text: "No podrás revertir esta acción!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si, eliminar!",
      cancelButtonText: "No, cancelar!",
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      if (data.setMLII) {
        // si es componente base o componente con un aditivo
        if (data.operation === "2" || data.operation === "3") {
          // si está asignado y autorizado
          if (data.assigned && data.authorized) {
            const dataToSend = {
              "ip": data.controlador,
              "operation": "1",
              "arm": data.arm,
              "index": data.index.padStart(3, '0'),
              "type": "2"
            }
            await handleDeleteAsgnAuthProds(dataToSend);

          } 
          // si está solo autorizado
          else if (!data.assigned && data.authorized) {
            const dataToSend = {
              "ip": data.controlador,
              "operation": "2",
              "arm": data.arm,
              "index": data.index.padStart(3, '0'),
              "type": "2"
            }

            await handleDeleteAsgnAuthProds(dataToSend);

          }
          // si está solo asignado 
          else if (data.assigned && !data.authorized) {
            const dataToSend = {
              "ip": data.controlador,
              "operation": "3",
              "arm": data.arm,
              "index": data.index.padStart(3, '0'),
              "type": "2"
            }

            await handleDeleteAsgnAuthProds(dataToSend);
          }
        }

        // si es una mezcla
        if (data.operation === "4") {
          const dataToSend = {
            "ip": data.controlador,
            "operation": "3",
            "arm": data.arm,
            "index": data.index.padStart(3, '0'),
            "type": "3"
          }

          await handleDeleteAsgnAuthProds(dataToSend);
        }

        // independientemente del tipo de operación se manda el index para que se elimine del MLII
        const dataToDel = {
          "ip": data.controlador,
          "index": data.index
        }

        await handleDeleteMLIIProds(dataToDel);
      }

      await deleteProductoMezcla(data.id);
      Swal.fire("Eliminado!", "El dato ha sido eliminado.", "success");
      onRefetch();
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelado", "Operación cancelada", "error");
    }

  }, [deleteProductoMezcla, handleDeleteMLIIProds, handleDeleteAsgnAuthProds, onRefetch]);

  return {
    handleAdd,
    handleDelete,
    handleUpdate,
    openCloseModal,
    refetch,
    contentModal,
    titleModal,
    showModal,
  };
}
