import React, { useEffect, useState } from "react";
import { Tab } from 'semantic-ui-react'
import { UserAdmin } from "../UserPage/UserAdmin"
import { OperadoresAdmin } from "../OperadoresPage/OperadoresAdmin"

export function GroupedUserOp() {
    const panes = [
        {
            menuItem: { key: 'UserAdmin', content: <>Usuario administrador</> },
            render: () => <Tab.Pane><UserAdmin /></Tab.Pane>,
        },
        {
            menuItem: { key: 'OperadoresAdmin', content: <>Usuario operador</> },
            render: () => <Tab.Pane><OperadoresAdmin /></Tab.Pane>,
        }
    ]

    return (
        <>
            <Tab panes={panes} />
        </>
    )
}