import React from "react";
import { Grid } from "@mui/material";
import { Calendar } from "../../vistas/modules/calendar";
import { Dashboard } from "./Dashboard/tableros";

export function Home() {

  return (
    <Grid>
      <Grid>
        <Dashboard />
      </Grid>
    </Grid>
  );
}
