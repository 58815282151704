import { createSlice } from "@reduxjs/toolkit";

// The initial state of the Global container
export const initialState = {
  main: {
    storage: [],
  },
};

const slice = createSlice({
  name: "storage",
  initialState,
  reducers: {
    // Actions
    getStorage: () => {},
    setStorage: (state, action) => {
      state.main.storage = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = slice;
