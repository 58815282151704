import { makeRequest } from "../Request";
import { BASE_API } from "../../utils/constants";
const BASE_URL = `${BASE_API}/`

export async function get_save_tcs_prod_api(id, data, token, schema) {
    const url = `${BASE_URL}${schema}/consumo/saveTcsProds/${id}/saveTcsProds/`;
    return makeRequest(url, "POST", token, data);
}

export async function get_tcs_prod_api(token, schema) {
    const url = `${BASE_URL}${schema}/consumo/saveTcsProds`;
    return makeRequest(url, "GET", token);
}

export async function get_plates_in_tcs_api(id, token, schema) {
    const url = `${BASE_URL}${schema}/consumo/platesintcs?id=${id}`;
    return makeRequest(url, "GET", token);
}

export async function save_plates_in_db_api(id, data, token, schema) {
    const url = `${BASE_URL}${schema}/consumo/platesintcs/${id}/savePlates/`
    return makeRequest(url, "POST", token, data)
}
