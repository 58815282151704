import React, { useEffect } from 'react';

import { useTransacciones } from '../../../../hooks';
import { DataGridNoOptTransacciones } from '../../../Comons/TablaBasic';

export  function VistaTransacciones(props) {
    /*
  Componente

  -componente de visualizacion del modal de visualizacion para transacciones pertenecientes o una orden
  
 
  -se valida que no llegue vacio para que no haya errores
  -se obtiene los datos  mediante props desde su PageAdmin
   */
  
    const {onClose,onRefetch,Transacciones}=props;
    const { retrieveTransacciones, TransaccionesRTR } = useTransacciones();
    

    useEffect(() => {
      retrieveTransacciones(Transacciones.n_orden.toString());
    }, []);
    

  return (
    
    
    <div >


        { TransaccionesRTR ?
    
    <DataGridNoOptTransacciones
        header={"Lista de Transacciones"}
        data={TransaccionesRTR}
        id="id_transaccion"
        // filterValue={filterValue}
        columns={columns}
        
        />

    :"" }
  </div>
  )
}
function CalculoCuatroDecimales(data) {
    let entero = parseInt(data / 10000);
    let decimal = ((data % 10000) / 10000).toFixed(4);
    return `${entero}.${decimal}`.replace(".0","");
}
function CalculoDosDecimales(data) {
  let entero = parseInt(data / 100);
  let ultimos_digitos = data % 100;
  let decimal = (ultimos_digitos / 100).toFixed(2);
  return `${entero}.${decimal}`.replace(".0","");
}
function CalculoUnDecimales(data) {
  let entero = parseInt(data / 100);
        let decimal = ((data % 100) / 10).toFixed(1);
        return `${entero}${decimal}`;
}

const columns = [
   
    {
      header: "Fecha creacion",
      name: "time_created",
    },
    {
      header: "Numero de orden",
      name: "n_orden",
    },
    {
      header: "Volumen restante",
      name: "v_restante",
     
    },
    {
      header: "Volumen entregado",
      name: "v_entregado",
     
    },
    {
      header: "GSV",
      name: "vol_gsv",
     
    },
    {
      header: "GOV",
      name: "vol_gov",
     
    },
    {
      header: "NSV",
      name: "nsv",
    },
    
    {
      header: "Presion promedio",
      name: "pres_promedio",
      render: (rowData) => {
        return CalculoDosDecimales(rowData.data.pres_promedio)
      }
    },
    {
      header: "Temperatura promedio",
      name: "temp_promedio",
      render: (rowData) => {
        return CalculoDosDecimales(rowData.data.temp_promedio)
      }
    },
    {
      header: "Densidad promedio",
      name: "densidad_promedio",
      render: (rowData) => {
        return CalculoUnDecimales(rowData.data.densidad_promedio)
      }
    },
    
    {
      header: "MeterFactor",
      name: "meter_factor",
      
    },
    {
      header: "CTL",
      name: "ctl",
      
    },
    {
      header: "CPL",
      name: "cpl",
      
    },
    {
      header: "CTPL",
      name: "ctpl",
      
    },
    {
      header: "BSW",
      name: "bsw",
    },
    {
      header: "API",
      name: "gravedad_api",
     
    },
    
    {
      header: "Totalizer inicio",
      name: "totalizer_init",
      render: (rowData) => {
        return CalculoDosDecimales(rowData.data.totalizer_init)
      }
    },
    {
      header: "Totalizer final",
      name: "totalizer_end",
      render: (rowData) => {
        return CalculoDosDecimales(rowData.data.totalizer_end)
      }
    },
    {
      header: "Numero de transaccion",
      name: "num_transaccion",
    },
    {
      header: "Fecha inicio de cargue",
      name: "fecha_initcargue",
    },
    {
      header: "Fecha de final del cargue",
      name: "fecha_endcargue",
    },
  
  ];
  
  const extraColumns = [


  ]