export const PermEmergencyStop = () => (
  <svg
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 450.06 666.65"
  >
    <path
      fill="#fff"
      d="M450,334.61c0-78.74,.15-157.49-.05-236.23-.15-62.89-32.79-97.17-95.13-97.77-85.9-.83-171.82-.78-257.72-.02C34.69,1.13,.55,35.28,.35,97.47c-.49,157.49-.44,314.98-.03,472.47,.16,63.02,33.96,96.51,95.84,96.61,85.91,.14,171.81,.12,257.72,0,62.93-.08,95.89-32.82,96.07-95.72,.22-78.74,.05-157.49,.05-236.23Z"
    />
    <path
      fill="#080809"
      d="M450,334.61c0,78.74,.17,157.49-.05,236.23-.18,62.9-33.14,95.64-96.07,95.72-85.91,.11-171.81,.13-257.72,0-61.88-.1-95.67-33.6-95.84-96.61C-.08,412.45-.14,254.96,.35,97.47,.55,35.28,34.69,1.13,97.11,.58c85.9-.75,171.82-.81,257.72,.02,62.34,.6,94.98,34.88,95.13,97.77,.19,78.74,.05,157.49,.05,236.23Zm-20.11,1.8c0-80.98,.18-161.95-.07-242.93-.15-47.13-23.93-72.28-70.6-72.64-89.22-.7-178.45-.69-267.68-.01-45.46,.34-71,26.72-71.11,72.96-.37,159.73,.56,319.47-.6,479.2-.32,44.54,31.5,75.32,73.56,74.14,88.06-2.49,176.28-2.57,264.33,.05,40.93,1.22,74.37-30.31,72.9-74.51-2.62-78.67-.74-157.49-.74-236.25Z"
    />
    <path
      fill="#fcfcfc"
      d="M429.9,336.41c0,78.76-1.88,157.58,.74,236.25,1.47,44.2-31.98,75.73-72.9,74.51-88.05-2.62-176.27-2.54-264.33-.05-42.05,1.19-73.88-29.6-73.56-74.14,1.16-159.72,.23-319.46,.6-479.2,.11-46.24,25.65-72.62,71.11-72.96,89.22-.68,178.45-.69,267.68,.01,46.67,.37,70.45,25.51,70.6,72.64,.26,80.98,.07,161.95,.07,242.93Zm-32.37,114.31c0-35.54,.31-71.07-.08-106.61-.46-40.9-22.39-64.13-63.07-64.62-68.36-.82-136.74-.73-205.11-.07-40.03,.39-63.82,20.09-65.02,59.48-2.23,73.19-2.19,146.54-.08,219.74,1.1,38.39,26.26,60.01,64.96,60.49,68.36,.85,136.75,.99,205.11-.05,39.85-.61,62.71-25.25,63.19-65.09,.42-34.42,.09-68.85,.09-103.27Z"
    />
    <path
      fill="#050506"
      d="M397.52,450.73c0,34.43,.33,68.85-.09,103.27-.48,39.84-23.34,64.48-63.19,65.09-68.36,1.04-136.75,.9-205.11,.05-38.71-.48-63.86-22.11-64.96-60.49-2.11-73.2-2.15-146.55,.08-219.74,1.2-39.39,24.99-59.1,65.02-59.48,68.36-.66,136.75-.74,205.11,.07,40.68,.49,62.61,23.71,63.07,64.62,.4,35.53,.08,71.07,.08,106.61Zm-166.52,110c61.17-.23,109.02-48.27,109.24-109.69,.22-61.48-47.43-110.38-108.12-110.95-60.74-.57-113.11,51.59-112.06,111.62,1.05,60.2,50.95,109.24,110.94,109.01Z"
    />
    <path
      fill="#a7a554"
      d="M231.01,560.72c-59.99,.23-109.89-48.81-110.94-109.01-1.05-60.03,51.32-112.19,112.06-111.62,60.69,.57,108.34,49.47,108.12,110.95-.22,61.42-48.07,109.46-109.24,109.69Zm47.27-107.37c1.05-27.67-21.57-53.08-47.11-52.94-23.59,.13-49.32,23.76-51.45,47.25-2.19,24.18,23.38,51.34,49.33,52.4,25.64,1.05,48.23-20.38,49.22-46.71Z"
    />
    <path
      fill="#aa5856"
      d="M278.27,453.36c-1,26.33-23.59,47.76-49.22,46.71-25.96-1.07-51.52-28.22-49.33-52.4,2.13-23.48,27.86-47.12,51.45-47.25,25.54-.14,48.16,25.27,47.11,52.94Z"
    />
  </svg>
);
