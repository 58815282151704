import React, { useEffect, useState, useCallback } from "react";
import Swal from "sweetalert2"
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton } from "@material-ui/core";
import { AddEditMaquinaria } from "../../../Components/Client";
import { useMaquinas } from "../../../hooks";

export function Funciones() {
    const { deleteMaquina } = useMaquinas()
    const [showModal, setShowModal] = useState(false);
    const [titleModal, setTitleModal] = useState(null);
    const [contentModal, setContentModal] = useState(null);
    const [refetch, setRefetch] = useState(false);

    const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
    const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);

    const handleAdd = useCallback(() => {
        setTitleModal("Nuevo registro");
        setContentModal(
            <AddEditMaquinaria
                onClose={openCloseModal}
                onRefetch={onRefetch}
            />
        );
        openCloseModal();
    }, [openCloseModal, onRefetch]);

    const handleUpdate = useCallback((data) => {
        setTitleModal("Actualizar máquina");
        setContentModal(
            <AddEditMaquinaria
                onClose={openCloseModal}
                onRefetch={onRefetch}
                Maquinas={data}
            />
        );
        openCloseModal();
    }, [openCloseModal, onRefetch]);

    const handleDelete = useCallback(async (data) => {
        const result = await Swal.fire({
            title: "¿Estás seguro?",
            text: "No podrás revertir esta acción!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "No, cancelar!",
            reverseButtons: true,
        });

        if (result.isConfirmed) {
            await deleteMaquina(data.id_maquina);
            Swal.fire("Eliminado!", "El dato ha sido eliminado.", "success");
            onRefetch();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Cancelado", "Operación cancelada", "error");
        }
    }, [deleteMaquina, onRefetch]);

    const columns = [
        {
            header: "Placa",
            name: "placa",
            defaultFlex: 2, // Adjust the flex value as needed
            minWidth: 100,
        },
        {
            header: "Nombre máquina",
            name: "nombre_maquina",
            defaultFlex: 2, // Adjust the flex value as needed
            minWidth: 100,
        },
        {
            header: "Descripción",
            name: "descripcion",
            defaultFlex: 2, // Adjust the flex value as needed
            minWidth: 150,
        },
        {
            header: "Desplazamiento",
            name: "desplazamiento",
            defaultFlex: 2, // Adjust the flex value as needed
            minWidth: 150,
        },
        {
            header: "Ubicación",
            name: "ubicacion_maquina",
            defaultFlex: 2, // Adjust the flex value as needed
            minWidth: 150,
        },
        {
            header: "Estado",
            name: "est_maquina",
            defaultFlex: 2, // Adjust the flex value as needed
            minWidth: 150,
        },
        {
            header: "Tipo de desplazamiento",
            name: "tipo_desplazamiento_maquina",
            defaultFlex: 2, // Adjust the flex value as needed
            minWidth: 150,
        },
        {
            header: "Potencia",
            name: "potencia_nominal",
            defaultFlex: 2, // Adjust the flex value as needed
            minWidth: 150,
        },
        {
            header: "Combustible",
            name: "prim_combustible",
            defaultFlex: 2, // Adjust the flex value as needed
            minWidth: 150,
        },
        {
            header: "Km",
            name: "trabajo_act",
            defaultFlex: 2, // Adjust the flex value as needed
            minWidth: 90,
        },
        {
            header: "Hr de uso",
            name: "hrs_uso",
            defaultFlex: 2, // Adjust the flex value as needed
            minWidth: 100,
        },
        {
            header: "Marca",
            name: "fabricante_maquina",
            defaultFlex: 2, // Adjust the flex value as needed
            minWidth: 150,
        },
    ]

    return {
        handleAdd,
        handleDelete,
        handleUpdate,
        openCloseModal,
        columns,
        refetch,
        contentModal,
        titleModal,
        showModal,
    }
}