//Columnas que se envian en forma de props al componente tabla basic para representar sus campos
export const columns = [
    {
      header: 'Nombre Tabla',
      name: 'nombre_tabla',
      maxWidth: 1000, defaultFlex: 1
  
    }, {
  
      header: 'Seccion asociada',
      name: 'id_secciones',
      maxWidth: 1000, defaultFlex: 1
  
    },{
  
      header: 'Fracciones asociadas',
      name: 'id_fracciones',
      maxWidth: 1000, defaultFlex: 1
  
    }, 
    // {
  
    //   header: 'Incertidumbre',
    //   name: 'id_incertidumbre',
  
    // }, {
  
    //   header: 'Calibracion',
    //   name: 'id_calibracion',
  
    // },
  
  ]


  export const filterValue = [
    { name: 'nombre_tabla', operator: 'startsWith', type: 'string', value: '' },
    { name: 'id_secciones', operator: 'startsWith', type: 'string', value: '' },
    { name: 'id_fracciones', operator: 'startsWith', type: 'string', value: '' },
    
    
  ];