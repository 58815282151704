import { useState } from "react";
import { getMezclasApi, addMezclasApi, updateMezclasApi, deleteMezclasApi } from "../../api/A_Mezclas/mezclas";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "..";

export function useMezclas() {
  const { auth, logout } = useAuth();
  const { unauthorized, SessionExpired, errores, unauthorizedModal } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    Mezclas: null,
    MezclasDB: null,
  });

  const { loading, error, Mezclas, MezclasDB } = state;

  const getMezclasDB = async () => {
    try {
      const [result, response] = await getMezclasApi(auth.token, auth.me.tenant);
      setState((prevState) => ({ ...prevState, MezclasDB: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  const handleResponse = (response) => {
    if (response.status === 401) {
      SessionExpired();
      logout();
    } else if (response.status === 403) {
      unauthorized();
    }
  };

  const getMezclas = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getMezclasApi(auth.token, auth.me.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, Mezclas: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addMezclas = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addMezclasApi(data, auth.token, auth.me.tenant);
      if (response.status === 400) {
        errores();
      } else if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateMezclas = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateMezclasApi(id, data, auth.token, auth.me.tenant);
      if (response.status === 400) {
        errores();
      } else if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteMezclas = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteMezclasApi(id, auth.token, auth.me.tenant);
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    MezclasDB,
    Mezclas,
    getMezclasDB,
    getMezclas,
    addMezclas,
    updateMezclas,
    deleteMezclas,
  };
}
