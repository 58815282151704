import { Box, FormControl, FormControlLabel, FormGroup, Grid, Switch, TextField, Button } from '@mui/material';
import { useFormik } from "formik";
import React from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useDecimales } from "../../../hooks";
import { alertas } from "../../Comons/Alertas/alertas";

export function AddEditParametrizacionBaseDecimales(props) {
  const { onClose, onRefetch, Datos } = props;

  const { updateDecimales, addDecimales } = useDecimales();
  const { Successful } = alertas();

  const formik = useFormik({
    initialValues: initialValues(Datos),
    validationSchema: Yup.object(Datos ? updateSchame() : newSchame()),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        if (Datos) await updateDecimales(Datos.id_parametrizacion, formValue);
        else await addDecimales(formValue);
        Successful();
        onRefetch();
        onClose();
      } catch (error) {
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });

  return (
    <Box component="form" className="add-edit-secciones-form" onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            label="Nombre configuracion"
            name="nombre"
            placeholder="Nombre"
            value={formik.values.nombre}
            onChange={formik.handleChange}
            error={formik.touched.nombre && Boolean(formik.errors.nombre)}
            helperText={formik.touched.nombre && formik.errors.nombre}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            label="Numero de decimales TOV"
            name="tov_decimales"
            placeholder="Decimales TOV"
            value={formik.values.tov_decimales}
            onChange={formik.handleChange}
            error={formik.touched.tov_decimales && Boolean(formik.errors.tov_decimales)}
            helperText={formik.touched.tov_decimales && formik.errors.tov_decimales}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            label="Numero de decimales FW"
            name="fw_decimales"
            placeholder="Decimales FW"
            value={formik.values.fw_decimales}
            onChange={formik.handleChange}
            error={formik.touched.fw_decimales && Boolean(formik.errors.fw_decimales)}
            helperText={formik.touched.fw_decimales && formik.errors.fw_decimales}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            label="Numero de decimales CTSh"
            name="ctsh_decimales"
            placeholder="Decimales CTSh"
            value={formik.values.ctsh_decimales}
            onChange={formik.handleChange}
            error={formik.touched.ctsh_decimales && Boolean(formik.errors.ctsh_decimales)}
            helperText={formik.touched.ctsh_decimales && formik.errors.ctsh_decimales}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            label="Numero de decimales GSV"
            name="gsv_decimales"
            placeholder="Decimales GSV"
            value={formik.values.gsv_decimales}
            onChange={formik.handleChange}
            error={formik.touched.gsv_decimales && Boolean(formik.errors.gsv_decimales)}
            helperText={formik.touched.gsv_decimales && formik.errors.gsv_decimales}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            label="Numero de decimales CSW"
            name="csw_decimales"
            placeholder="Decimales CSW"
            value={formik.values.csw_decimales}
            onChange={formik.handleChange}
            error={formik.touched.csw_decimales && Boolean(formik.errors.csw_decimales)}
            helperText={formik.touched.csw_decimales && formik.errors.csw_decimales}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            label="Numero de decimales FRA"
            name="fra_decimales"
            placeholder="Decimales FRA"
            value={formik.values.fra_decimales}
            onChange={formik.handleChange}
            error={formik.touched.fra_decimales && Boolean(formik.errors.fra_decimales)}
            helperText={formik.touched.fra_decimales && formik.errors.fra_decimales}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Numero de decimales GOV"
            name="gov_decimales"
            placeholder="Decimales GOV"
            value={formik.values.gov_decimales}
            onChange={formik.handleChange}
            error={formik.touched.gov_decimales && Boolean(formik.errors.gov_decimales)}
            helperText={formik.touched.gov_decimales && formik.errors.gov_decimales}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Button type="submit" variant="contained" color="error" className="btn btn-danger" fullWidth >
            {Datos ? "Actualizar" : "Crear"}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

function initialValues(data) {
  return {
    nombre: data?.nombre || "",
    tov_decimales: data?.tov_decimales || "",
    fw_decimales: data?.fw_decimales || "",
    ctsh_decimales: data?.ctsh_decimales || "",
    gsv_decimales: data?.gsv_decimales || "",
    csw_decimales: data?.csw_decimales || "",
    fra_decimales: data?.fra_decimales || "",
    gov_decimales: data?.gov_decimales || "",
  };
}
function newSchame() {
  return {
    nombre: Yup.string().required(true),
    tov_decimales: Yup.number().required(true),
    fw_decimales: Yup.number().required(true),
    ctsh_decimales: Yup.number().required(true),
    gsv_decimales: Yup.number().required(true),
    csw_decimales: Yup.number().required(true),
    fra_decimales: Yup.number().required(true),
    gov_decimales: Yup.number().required(true),
  };
}
function updateSchame() {
  return {
    nombre: Yup.string().required(true),
    tov_decimales: Yup.number().required(true),
    fw_decimales: Yup.number().required(true),
    ctsh_decimales: Yup.number().required(true),
    gsv_decimales: Yup.number().required(true),
    csw_decimales: Yup.number().required(true),
    fra_decimales: Yup.number().required(true),
    gov_decimales: Yup.number().required(true),
  };
}
