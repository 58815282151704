import React from "react";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import { toast } from "react-toastify";
import * as Yup from "yup";
import { useDecimalesComputador } from "../../../hooks";
import { alertas } from "../../Comons/Alertas/alertas";

export function AddEditDecimalComputador(props) {
  const { onClose, onRefetch, Datos } = props;

  const { updateDecimales } = useDecimalesComputador();
  const { Successful } = alertas();

  const formik = useFormik({
    initialValues: initialValues(Datos),
    validationSchema: Yup.object(newSchema()),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        await updateDecimales(Datos.id, formValue);
        Successful();
        onRefetch();
        onClose();
      } catch (error) {
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });

  return (
    <Box
      component="form"
      className="add-edit-secciones-form"
      onSubmit={formik.handleSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            name="nombre"
            label="Nombre"
            fullWidth
            value={formik.values.nombre}
            onChange={formik.handleChange}
            error={formik.touched.nombre && Boolean(formik.errors.nombre)}
            helperText={formik.touched.nombre && formik.errors.nombre}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="CTL_RT"
            label="CTL_RT"
            fullWidth
            value={formik.values.CTL_RT}
            onChange={formik.handleChange}
            error={formik.touched.CTL_RT && Boolean(formik.errors.CTL_RT)}
            helperText={formik.touched.CTL_RT && formik.errors.CTL_RT}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="CPL_RT"
            label="CPL_RT"
            fullWidth
            value={formik.values.CPL_RT}
            onChange={formik.handleChange}
            error={formik.touched.CPL_RT && Boolean(formik.errors.CPL_RT)}
            helperText={formik.touched.CPL_RT && formik.errors.CPL_RT}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="CTPL_RT"
            label="CTPL_RT"
            fullWidth
            value={formik.values.CTPL_RT}
            onChange={formik.handleChange}
            error={formik.touched.CTPL_RT && Boolean(formik.errors.CTPL_RT)}
            helperText={formik.touched.CTPL_RT && formik.errors.CTPL_RT}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="IV_DEV"
            label="IV_DEV"
            fullWidth
            value={formik.values.IV_DEV}
            onChange={formik.handleChange}
            error={formik.touched.IV_DEV && Boolean(formik.errors.IV_DEV)}
            helperText={formik.touched.IV_DEV && formik.errors.IV_DEV}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="GOV_RT"
            label="GOV_RT"
            fullWidth
            value={formik.values.GOV_RT}
            onChange={formik.handleChange}
            error={formik.touched.GOV_RT && Boolean(formik.errors.GOV_RT)}
            helperText={formik.touched.GOV_RT && formik.errors.GOV_RT}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="GSV_RT"
            label="GSV_RT"
            fullWidth
            value={formik.values.GSV_RT}
            onChange={formik.handleChange}
            error={formik.touched.GSV_RT && Boolean(formik.errors.GSV_RT)}
            helperText={formik.touched.GSV_RT && formik.errors.GSV_RT}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="NSV_RT"
            label="NSV_RT"
            fullWidth
            value={formik.values.NSV_RT}
            onChange={formik.handleChange}
            error={formik.touched.NSV_RT && Boolean(formik.errors.NSV_RT)}
            helperText={formik.touched.NSV_RT && formik.errors.NSV_RT}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="TEMP_RT"
            label="TEMP_RT"
            fullWidth
            value={formik.values.TEMP_RT}
            onChange={formik.handleChange}
            error={formik.touched.TEMP_RT && Boolean(formik.errors.TEMP_RT)}
            helperText={formik.touched.TEMP_RT && formik.errors.TEMP_RT}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="TEMP_AV"
            label="TEMP_AV"
            fullWidth
            value={formik.values.TEMP_AV}
            onChange={formik.handleChange}
            error={formik.touched.TEMP_AV && Boolean(formik.errors.TEMP_AV)}
            helperText={formik.touched.TEMP_AV && formik.errors.TEMP_AV}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="BSW_RT"
            label="BSW_RT"
            fullWidth
            value={formik.values.BSW_RT}
            onChange={formik.handleChange}
            error={formik.touched.BSW_RT && Boolean(formik.errors.BSW_RT)}
            helperText={formik.touched.BSW_RT && formik.errors.BSW_RT}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="PRES_RT"
            label="PRES_RT"
            fullWidth
            value={formik.values.PRES_RT}
            onChange={formik.handleChange}
            error={formik.touched.PRES_RT && Boolean(formik.errors.PRES_RT)}
            helperText={formik.touched.PRES_RT && formik.errors.PRES_RT}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="PRES_AV"
            label="PRES_AV"
            fullWidth
            value={formik.values.PRES_AV}
            onChange={formik.handleChange}
            error={formik.touched.PRES_AV && Boolean(formik.errors.PRES_AV)}
            helperText={formik.touched.PRES_AV && formik.errors.PRES_AV}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="GAPI_RT"
            label="GAPI_RT"
            fullWidth
            value={formik.values.GAPI_RT}
            onChange={formik.handleChange}
            error={formik.touched.GAPI_RT && Boolean(formik.errors.GAPI_RT)}
            helperText={formik.touched.GAPI_RT && formik.errors.GAPI_RT}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="GAPI_AV"
            label="GAPI_AV"
            fullWidth
            value={formik.values.GAPI_AV}
            onChange={formik.handleChange}
            error={formik.touched.GAPI_AV && Boolean(formik.errors.GAPI_AV)}
            helperText={formik.touched.GAPI_AV && formik.errors.GAPI_AV}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="DENS_RT"
            label="DENS_RT"
            fullWidth
            value={formik.values.DENS_RT}
            onChange={formik.handleChange}
            error={formik.touched.DENS_RT && Boolean(formik.errors.DENS_RT)}
            helperText={formik.touched.DENS_RT && formik.errors.DENS_RT}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="DENS_AV"
            label="DENS_AV"
            fullWidth
            value={formik.values.DENS_AV}
            onChange={formik.handleChange}
            error={formik.touched.DENS_AV && Boolean(formik.errors.DENS_AV)}
            helperText={formik.touched.DENS_AV && formik.errors.DENS_AV}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="DENR_AV"
            label="DENR_AV"
            fullWidth
            value={formik.values.DENR_AV}
            onChange={formik.handleChange}
            error={formik.touched.DENR_AV && Boolean(formik.errors.DENR_AV)}
            helperText={formik.touched.DENR_AV && formik.errors.DENR_AV}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Button
            type="submit"
            variant="contained"
            color="error"
            className="btn btn-danger"
            fullWidth
          >
            {Datos ? "Actualizar" : "Crear"}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

function initialValues(data) {
  return {
    nombre: data?.nombre || "",
    CTL_RT: data?.CTL_RT || "",
    CPL_RT: data?.CPL_RT || "",
    CTPL_RT: data?.CTPL_RT || "",
    IV_DEV: data?.IV_DEV || "",
    GOV_RT: data?.GOV_RT || "",
    GSV_RT: data?.GSV_RT || "",
    NSV_RT: data?.NSV_RT || "",
    TEMP_RT: data?.TEMP_RT || "",
    TEMP_AV: data?.TEMP_AV || "",
    BSW_RT: data?.BSW_RT || "",
    PRES_RT: data?.PRES_RT || "",
    PRES_AV: data?.PRES_AV || "",
    MTR1_IV_DEV: data?.MTR1_IV_DEV || "",
    MTR1_GOV_RT: data?.MTR1_GOV_RT || "",
    MTR1_VOLP_RT: data?.MTR1_VOLP_RT || "",
    MTR1_TEMP_RT: data?.MTR1_TEMP_RT || "",
    MTR1_PRES_RT: data?.MTR1_PRES_RT || "",
    MTR2_IV_DEV: data?.MTR2_IV_DEV || "",
    MTR2_GOV_RT: data?.MTR2_GOV_RT || "",
    MTR2_VOLP_RT: data?.MTR2_VOLP_RT || "",
    MTR2_TEMP_RT: data?.MTR2_TEMP_RT || "",
    MTR2_PRES_RT: data?.MTR2_PRES_RT || "",
    GAPI_RT: data?.GAPI_RT || "",
    GAPI_AV: data?.GAPI_AV || "",
    DENS_RT: data?.DENS_RT || "",
    DENS_AV: data?.DENS_AV || "",
    DENR_AV: data?.DENR_AV || "",
    MTR1_GAPI_RT: data?.MTR1_GAPI_RT || "",
    MTR1_DENS_RT: data?.MTR1_DENS_RT || "",
    MTR2_GAPI_RT: data?.MTR2_GAPI_RT || "",
    MTR2_DENS_RT: data?.MTR2_DENS_RT || "",
  };
}

function newSchema() {
  return {
    nombre: Yup.string().required('Este campo es obligatorio'),
    CTL_RT: Yup.string().required('Este campo es obligatorio'),
    CPL_RT: Yup.string().required('Este campo es obligatorio'),
    CTPL_RT: Yup.string().required('Este campo es obligatorio'),
    IV_DEV: Yup.string().required('Este campo es obligatorio'),
    GOV_RT: Yup.string().required('Este campo es obligatorio'),
    GSV_RT: Yup.string().required('Este campo es obligatorio'),
    NSV_RT: Yup.string().required('Este campo es obligatorio'),
    TEMP_RT: Yup.string().required('Este campo es obligatorio'),
    TEMP_AV: Yup.string().required('Este campo es obligatorio'),
    BSW_RT: Yup.string().required('Este campo es obligatorio'),
    PRES_RT: Yup.string().required('Este campo es obligatorio'),
    PRES_AV: Yup.string().required('Este campo es obligatorio'),
    GAPI_RT: Yup.string().required('Este campo es obligatorio'),
    GAPI_AV: Yup.string().required('Este campo es obligatorio'),
    DENS_RT: Yup.string().required('Este campo es obligatorio'),
    DENS_AV: Yup.string().required('Este campo es obligatorio'),
    DENR_AV: Yup.string().required('Este campo es obligatorio'),

  };
}
