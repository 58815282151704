import { useState } from "react";
import { getPlantasApi, addPlantasApi, updatePlantasApi, deletePlantasApi } from "../../api/A_Plantas/plantas";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "..";
import { useNavigate } from "react-router-dom";

export function usePlantas() {
  const { unauthorized, SessionExpired, unauthorizedModal } = alertas();
  const { auth, logout } = useAuth();
  const navigate = useNavigate();

  const [state, setState] = useState({
    loading: true,
    error: null,
    Plantas: null,
    PlantasDB: null,
  });

  const { loading, error, Plantas, PlantasDB } = state;

  const getPlantasDB = async () => {
    
    try {
      const [result, response] = await getPlantasApi(auth.token, auth.me.tenant);
      setState((prevState) => ({ ...prevState, PlantasDB: result.results }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  const getPlantas = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getPlantasApi(auth.token, auth.me.tenant);
      if (response.status === 401) {
        SessionExpired();
        logout();
      } else if (response.status === 403) {
        unauthorized();
        navigate("/");
        return;
      }
      setState((prevState) => ({ ...prevState, loading: false, Plantas: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const addPlantas = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addPlantasApi(data, auth.token, auth.me.tenant);
      if (response.status === 400) {
        throw new Error("Ya se ha registrado una planta con este NIT");
      } else if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updatePlantas = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updatePlantasApi(id, data, auth.token, auth.me.tenant);
      if (response.status === 400) {
        throw new Error("Ya se ha registrado una planta con este NIT");
      } else if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deletePlantas = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deletePlantasApi(id, auth.token, auth.me.tenant);
      setState((prevState) => ({ ...prevState, loading: false }));
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    PlantasDB,
    Plantas,
    getPlantasDB,
    getPlantas,
    addPlantas,
    updatePlantas,
    deletePlantas,
  };
}
