import React, { useEffect } from "react";
import { HeaderPage } from "../../../Components/Client";
import { DataGridBasic, ModalBasic } from "../../../Components/Comons";
import { useRemolques } from "../../../hooks";
import { Funciones } from "./Funciones";
import { columns, filterValue } from "./RemolquesFormat";

import CircularProgress from '@mui/material/CircularProgress';

export function RemolquesAdmin() {
  const { loading, Remolques, getRemolques } = useRemolques();

  const {
    refetch,
    contentModal,
    titleModal,
    showModal,
    handleAdd,
    handleDelete,
    handleUpdate,
    openCloseModal,
  } = Funciones();

  useEffect(() => {
    getRemolques();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);


  const MemoizedModalBasic = React.memo(ModalBasic);

  return (
    <>
      <HeaderPage
        title="Remolques"
        btnTitle="Nuevo remolque"
        icono="plus square icon"
        btnClick={handleAdd}
      />

      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <DataGridBasic
          data={Remolques.results}
          title="Remolques"
          id="id_remolque"
          filterValue={filterValue}
          columns={columns}
          updateData={handleUpdate}
          onDeleteData={handleDelete}
        />
      )}


      {showModal && (
        <MemoizedModalBasic
          show={showModal}
          onClose={openCloseModal}
          title={titleModal}
          children={contentModal}
        />
      )}

    </>
  )
}
//Columnas que se envian en forma de props al componente tabla basic para representer sus campos
