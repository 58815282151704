import React, {  useEffect, useState } from "react"
import { useUser } from "../../../hooks";
import { Loader } from "semantic-ui-react";
import { UserUpdateMeAdmin } from './UserUpdateMeAdmin';

export function UpdateMe() {
    const { loading, me, updateUser, getMeUSer } = useUser();
    const [refetch, setRefetch] = useState(false);
    const onRefetch = () => setRefetch((prev) => !prev);

    useEffect(() => {
        getMeUSer();
      }, [refetch]);
      let objeto = null;
    
      if (me && me.length > 0) {
        objeto = JSON.parse(JSON.stringify(me[0]));
      }
  return (
    <>

      {loading ? (
        <Loader active inline="centered">
          Cargando...
        </Loader>
      ) : (
        
        <UserUpdateMeAdmin updateUser={updateUser} objeto={objeto} onRefetch={onRefetch} />
  

      )}
      
    </>
  )
}
