import ReactDataGrid from "@inovua/reactdatagrid-community";
import filter from "@inovua/reactdatagrid-community/filter";
import "@inovua/reactdatagrid-community/index.css";
import { Box, IconButton, makeStyles,Card, CardContent } from "@material-ui/core";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import DownloadIcon from "@mui/icons-material/Download";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";

import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Divider,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import * as XLSX from "xlsx";
import "./stylesTabla.css";

const gridStyle = { minHeight: 720, marginTop: 10 };

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: "20px",
    backgroundColor: "#fff",
    borderRadius: 10,
    boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.2)",
    padding: theme.spacing(2),
  },
  dialogContent: {
    width: "100%", // Adjust the width as needed
    margin: "auto",
  },
}));

export function DataGridNoOptTransacciones(props) {
  const [filterData, setFilterData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRowClick = (row) => {
    setSelectedRow(row === selectedRow ? null : row);
    setIsModalOpen(true);
  };

  const { data, columns, id, filterValue, cargaMasiva, cargaFotos, groups } =
    props;
  const classes = useStyles();

  const onFilterValueChange = useCallback((filterValue) => {
    const filteredData = filter(data, filterValue);
    setFilterData(filteredData);
  }, []);

  const exportToExcel = () => {
    if (filterData.length > 0) {
      const worksheet = XLSX.utils.json_to_sheet(filterData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "data.xlsx");
    } else {
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "data.xlsx");
    }
  };

  function CalculoDosDecimales(data) {
    let entero = parseInt(data / 100);
    let ultimos_digitos = data % 100;
    let decimal = (ultimos_digitos / 100).toFixed(2);
    return `${entero}.${decimal}`.replace(".0","");
  }
  function CalculoUnDecimales(data) {
    let entero = parseInt(data / 100);
          let decimal = ((data % 100) / 10).toFixed(1);
          return `${entero}${decimal}`;
  }

  const newColumns = [...columns];

  return (
    <Box className={classes.tableContainer}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <IconButton
          onClick={exportToExcel}
          style={{ marginRight: "8px", fontSize: "1.2rem" }}
        >
          <DownloadIcon />
        </IconButton>
        {cargaMasiva && (
          <IconButton
            onClick={cargaMasiva}
            style={{ marginRight: "8px", fontSize: "1.2rem" }}
          >
            <DriveFolderUploadIcon />
          </IconButton>
        )}
        {cargaFotos && (
          <IconButton onClick={cargaFotos} style={{ fontSize: "1.2rem" }}>
            <CameraswitchIcon />
          </IconButton>
        )}
      </div>
      <ReactDataGrid
        idProperty={id}
        columns={newColumns}
        dataSource={data}
        style={gridStyle}
        groups={groups}
        className="custom-data-grid"
        pagination
        paginationPageSize={10}
        paginationToolbarLabels={{
          prev: "Anterior",
          next: "Siguiente",
          page: "Página",
          of: "de",
          rows: "filas",
        }}
        defaultFilterValue={filterValue}
        onFilterValueChange={onFilterValueChange}
        onDataSourceChange={({ skip, limit }) => {
          console.log(`Pagina actual: ${skip / limit + 1}`);
        }}
        onRowClick={handleRowClick} // Add the onRowClick event handler
        rowSelection={{
          showCheckbox: false,
          selectBy: {
            isSelectedKey: "isSelected",
          },
        }}
      />
      // ...
      {selectedRow && (
        <Dialog
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          classes={{ paper: classes.dialogContent }}
        >
           <Card variant="outlined" sx={{ backgroundColor: "rgba(224, 224, 224, 0.7)" }}>
        <DialogTitle>
          <Typography variant="h6">Detalle de la transacción</Typography>
        </DialogTitle>
      </Card>
          <DialogContent>
        <Grid container spacing={2}>
          {/* Sección 1: Datos generales */}
          <Grid item xs={12}>
            <Typography variant="h6">Datos generales</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Card variant="outlined" sx={{ borderRadius: 8, boxShadow: 3 }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1">
                      <strong>GOV:</strong> {CalculoDosDecimales(selectedRow.data.vol_gov)}
                    </Typography>
                    <Typography variant="subtitle1">
                      <strong>CTL:</strong> {selectedRow.data.ctl}
                    </Typography>
                    <Typography variant="subtitle1">
                      <strong>Densidad promedio:</strong>{" "}
                      {CalculoUnDecimales(selectedRow.data.densidad_promedio)}
                    </Typography>
                    <Typography variant="subtitle1">
                      <strong>API:</strong> {selectedRow.data.gravedad_api}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1">
                      <strong>GSV:</strong> {CalculoDosDecimales(selectedRow.data.vol_gsv)}
                    </Typography>
                    <Typography variant="subtitle1">
                      <strong>CPL:</strong> {selectedRow.data.cpl}
                    </Typography>
                    <Typography variant="subtitle1">
                      <strong>Presion promedio:</strong>{" "}
                      {CalculoDosDecimales(selectedRow.data.pres_promedio)}
                    </Typography>
                    <Typography variant="subtitle1">
                      <strong>MeterFactor:</strong>{" "}
                      {selectedRow.data.meter_factor}
                    </Typography>
                    
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1">
                      <strong>NSV:</strong> {selectedRow.data.nsv}
                    </Typography>
                    <Typography variant="subtitle1">
                      <strong>CTPL:</strong> {selectedRow.data.ctpl}
                    </Typography>
                    <Typography variant="subtitle1">
                      <strong>Temperatura promedio:</strong>{" "}
                      {CalculoDosDecimales(selectedRow.data.temp_promedio)}
                    </Typography>
                    <Typography variant="subtitle1">
                      <strong>BSW:</strong> {selectedRow.data.bsw}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Sección 2: Medidores Uno */}
          <Grid item xs={12}>
            <Typography variant="h6">Medidor Uno</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Card variant="outlined" sx={{ borderRadius: 8, boxShadow: 3 }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1">
                      <strong>MTR1 GOV RT:</strong>{" "}
                      {selectedRow.data.meter_uno?.MTR1_GOV_RT || "-"}
                    </Typography>
                    <Typography variant="subtitle1">
                      <strong>MTR1 IV DEV:</strong>{" "}
                      {selectedRow.data.meter_uno?.MTR1_IV_DEV || "-"}
                    </Typography>
                    <Typography variant="subtitle1">
                      <strong>MTR1 DENS_RT:</strong>{" "}
                      {selectedRow.data.meter_uno?.MTR1_DENS_RT || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1">
                      <strong>MTR1 FLOW_RT:</strong>{" "}
                      {selectedRow.data.meter_uno?.MTR1_FLOW_RT || "-"}
                    </Typography>
                    <Typography variant="subtitle1">
                      <strong>MTR1 GAPI_RT:</strong>{" "}
                      {selectedRow.data.meter_uno?.MTR1_GAPI_RT || "-"}
                    </Typography>
                    <Typography variant="subtitle1">
                      <strong>MTR1 PRES_RT:</strong>{" "}
                      {selectedRow.data.meter_uno?.MTR1_PRES_RT || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1">
                      <strong>MTR1 TEMP_RT:</strong>{" "}
                      {selectedRow.data.meter_uno?.MTR1_TEMP_RT || "-"}
                    </Typography>
                    <Typography variant="subtitle1">
                      <strong>MTR1 VOLP_RT:</strong>{" "}
                      {selectedRow.data.meter_uno?.MTR1_VOLP_RT || "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Sección 3: Medidores Dos */}
          <Grid item xs={12}>
            <Typography variant="h6">Medidor Dos</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Card variant="outlined" sx={{ borderRadius: 8, boxShadow: 3 }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1">
                      <strong>MTR2 GOV_RT:</strong>{" "}
                      {selectedRow.data.meter_dos?.MTR2_GOV_RT || "-"}
                    </Typography>
                    <Typography variant="subtitle1">
                      <strong>MTR2 IV_DEV:</strong>{" "}
                      {selectedRow.data.meter_dos?.MTR2_IV_DEV || "-"}
                    </Typography>
                    <Typography variant="subtitle1">
                      <strong>MTR2 DENS_RT:</strong>{" "}
                      {selectedRow.data.meter_dos?.MTR2_DENS_RT || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1">
                      <strong>MTR2 FLOW_RT:</strong>{" "}
                      {selectedRow.data.meter_dos?.MTR2_FLOW_RT || "-"}
                    </Typography>
                    <Typography variant="subtitle1">
                      <strong>MTR2 G. API_RT:</strong>{" "}
                      {selectedRow.data.meter_dos?.MTR2_GAPI_RT || "-"}
                    </Typography>
                    <Typography variant="subtitle1">
                      <strong>MTR2 PRESION_RT:</strong>{" "}
                      {selectedRow.data.meter_dos?.MTR2_PRES_RT || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1">
                      <strong>MTR2 TEMP_RT:</strong>{" "}
                      {selectedRow.data.meter_dos?.MTR2_TEMP_RT || "-"}
                    </Typography>
                    <Typography variant="subtitle1">
                      <strong>MTR2 VOLP_RT:</strong>{" "}
                      {selectedRow.data.meter_dos?.MTR2_VOLP_RT || "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Agregar más secciones si es necesario */}
        </Grid>
      </DialogContent>
          <DialogActions>
            <div>
              <Button
                sx={{
                  backgroundColor: "#D0302B",
                  color: "#FFFFFF",
                  "&:hover": {
                    backgroundColor: "#BEBEBE", // Color gris cuando se hace hover
                  },
                }}
                onClick={() => setIsModalOpen(false)}
              >
                Cerrar
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}
