import React from "react";
import { Table } from "semantic-ui-react";
import {useAuth } from "../../../../hooks";

export function VisualizacionForm(props) {
    /*
  Componente

  -componente de visualizacion del modal de visualizacion para datos de secciones
  
 
  -se valida que no llegue vacio para que no haya errores
  -se obtiene los datos  mediante props desde su PageAdmin
   */
  const { onClose, onRefetch, visualizacion } = props;
  
  const { auth} = useAuth();
  const  renderUnidadLongitud = () => {
  if (auth.me?.SistemasMedicion && auth.me?.SistemasMedicion.longitud) {
    return `${auth.me.SistemasMedicion.longitud} `;
  }
  return "No especificado";
};

const  renderUnidadVolumen = () => {
  if (auth.me?.SistemasMedicion && auth.me?.SistemasMedicion.volumen) {
    return `${auth.me.SistemasMedicion.volumen} `;
  }
  return "No especificado";
};

  return (
    <Table definition>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Nivel  <strong>( {renderUnidadLongitud()})</strong></Table.HeaderCell>
          <Table.HeaderCell>Volumen <strong>( {renderUnidadVolumen()})</strong></Table.HeaderCell>
          <Table.HeaderCell>Incremento <strong>({renderUnidadLongitud()}/{renderUnidadVolumen()})</strong></Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {visualizacion.data_secciones.map((dta) => (
          <Table.Row>
            <Table.Cell>{dta.Nivel}</Table.Cell>
            <Table.Cell>{dta.Volumen}</Table.Cell>
            <Table.Cell>{dta.Incremento}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}
