import React, { useEffect, useState } from 'react';
import Label from '@mui/material/FormLabel'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox';
import Swal from "sweetalert2"
import { Autocomplete } from '@mui/material'
import { useMultiLdProd } from "../../../hooks"
import { alertas } from "../../Comons/Alertas/alertas";
import { useFormik, Formik } from "formik";

const greenText = {
    color: 'green',
};

const redText = {
    color: 'red',
};

export function AddEditMultLdProds(props) {
    const { handleAddMultiLdProd } = useMultiLdProd()
    const { Successful } = alertas();
    const { onClose, onRefetch, Controladores } = props;
    const [proportionSum, setProportionSum] = useState(0);
    const [fontColorClass, setFontColorClass] = useState(null)
    const [fontColorClass2, setFontColorClass2] = useState(null)
    const [proportionSum2, setProportionSum2] = useState(null)
    const [error, setError] = useState(false)
    const [error1, setError1] = useState(false)

    const operationOptions = [
        { value: '1', label: 'Aditivo base' },
        { value: '2', label: 'Componente base' },
        { value: '3', label: 'Mezcla' },
        { value: '4', label: 'Mezca con aditivo' },
    ];

    const armOptions = [
        { value: '0', label: 'Brazo 1' },
        { value: '1', label: 'Brazo 2' },
        { value: '2', label: 'Brazo 3' },
        { value: '3', label: 'Brazo 4' },
        { value: '4', label: 'Brazo 5' },
        { value: '5', label: 'Brazo 6' },
        { value: '6', label: 'Brazo 7' },
        { value: '7', label: 'Brazo 8' },
        { value: '8', label: 'Brazo 9' },
        { value: '9', label: 'Brazo 10' },
        { value: '10', label: 'Brazo 11' },
    ]

    const formik = useFormik({
        // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
        initialValues: initialValues(),
        // Desactiva la validación al cambiar los valores del formulario
        validateOnChange: false,
        onSubmit: async (formValue) => {
            try {

                const indexValue = parseInt(formValue.index, 10);
                let isValid = true;

                switch (formValue.operation) {
                    case '1':
                        isValid = indexValue >= 21 && indexValue <= 26;
                        break;
                    case '2':
                        isValid = indexValue >= 1 && indexValue <= 6;
                        break;
                    case '3':
                        isValid = indexValue >= 31 && indexValue <= 39;
                        break;
                    case '4':
                        isValid = indexValue >= 31 && indexValue <= 39;
                        break;
                    default:
                        break;
                }

                if (!isValid) {
                    Swal.fire('Índice inválido para la operación seleccionada', '', 'error');
                    return;
                }

                const data = {
                    "index": formValue.index.padStart(3, "0"),
                    "code": formValue.code,
                    "l_desc": formValue.lDesc,
                    "s_desc": formValue.sDesc,
                    "operation": formValue.operation,
                    "ip": Controladores.ip,
                    "index_arr": [formValue.index.padStart(3, "0")],
                    "line": formValue.line,
                    "arm": formValue.arm,
                    "assign": false,
                    "auth": false
                }

                switch (formValue.operation) {
                    case "1":
                        data["proportion"] = formValue.add_prop
                        break
                    case "3":
                        if (proportionSum <= 0) {
                            Swal.fire('No puede crear una mezcla sin componentes', '', 'error');
                            return;
                        }

                        if (proportionSum !== 100) {
                            Swal.fire('El total de la mezcla debe ser igual a 100', '', 'error');
                            return;
                        }

                        const componentsArray = formValue.components;
                        const componentsObject = componentsArray.reduce((acc, component) => {
                            const key = component.index.padStart(3, "0");
                            const value = parseInt(component.proportion, 10);
                            acc[key] = value;
                            return acc;
                        }, {});

                        data["components"] = componentsObject
                        break
                    case "4":
                        if (proportionSum <= 0 || proportionSum2 <= 0) {
                            Swal.fire('No puede crear una mezcla con aditivos sin componentes o aditivos', '', 'error');
                            return;
                        }

                        if (proportionSum !== 100 || proportionSum2 >= 6.5) {
                            Swal.fire('El total del componente debe ser igual a 100 y el aditivo menor o igual a 6.5', '', 'error');
                            return;
                        }

                        const componentsArrayOpt4 = formValue.components;
                        const componentsObjectOpt4 = componentsArrayOpt4.reduce((acc, component) => {
                            const key = component.index.padStart(3, "0");
                            const value = parseInt(component.proportion, 10);
                            acc[key] = value;
                            return acc;
                        }, {});

                        const aditiveArrayOpt4 = formValue.aditive;
                        const aditiveObjectOpt4 = aditiveArrayOpt4.reduce((add, aditive) => {
                            const key = aditive.index.padStart(3, "0");
                            const value = parseInt(aditive.proportion, 10);
                            add[key] = value;
                            return add;
                        }, {});

                        data["components"] = componentsObjectOpt4
                        data["aditive"] = aditiveObjectOpt4
                        break
                    default:
                        break
                }



                console.log(data)

                // Si se proporciona un objeto se actualiza, de lo contrario se crea uno nuevo
                await handleAddMultiLdProd(data);
                Successful();
                onRefetch();
                onClose();
            } catch (error) {
                // Muestra un mensaje de error si ocurre algún problema
                if (error?.message) {
                    Swal.fire(error?.message, '', 'error');
                    return;
                }
            }
        },
    });

    const handleOperationChange = (event) => {
        formik.handleChange(event)
    };

    useEffect(() => {
        // Calculate the sum of "proportion" values from formik.values.components
        const sum = formik.values.components.reduce((acc, component) => {
            return acc + parseFloat(component.proportion || 0);
        }, 0);

        // Update the state with the calculated sum
        setProportionSum(sum);

        if (sum < 100) {
            setFontColorClass(redText);
        } else if (sum > 100) {
            setFontColorClass(redText);
        } else {
            setFontColorClass(greenText);
        }
    }, [formik.values.components]);

    useEffect(() => {
        // Calculate the sum of "proportion" values from formik.values.components
        const sum = formik.values.aditive.reduce((add, aditive) => {
            return add + parseFloat(aditive.proportion || 0);
        }, 0);

        // Update the state with the calculated sum
        setProportionSum2(sum);

        if (sum <= 0 || sum >= 6.5) {
            setFontColorClass2(redText);
        } else {
            setFontColorClass2(greenText);
        }
    }, [formik.values.aditive]);

    useEffect(() => {
        if (!formik.values.assign && !formik.values.auth) {
            formik.setFieldValue('arm', 0)
            formik.setFieldValue('line', "")
        }
    })

    return (
        <>
            <Formik initialValues={initialValues()}>
                <div>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Índice"
                                    variant="outlined"
                                    name="index"
                                    value={formik.values.index}
                                    onChange={formik.handleChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Código"
                                    variant="outlined"
                                    name="code"
                                    value={formik.values.code}
                                    onChange={formik.handleChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Descripción larga"
                                    variant="outlined"
                                    name="lDesc"
                                    value={formik.values.lDesc}
                                    onChange={formik.handleChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Descripción corta"
                                    variant="outlined"
                                    name="sDesc"
                                    value={formik.values.sDesc}
                                    onChange={formik.handleChange}
                                    required
                                />
                            </Grid>
                            {/* {formik.values.operation === '1' && (
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        fullWidth
                                        label="Porcentaje de aditivo"
                                        variant="outlined"
                                        name="add_prop"
                                        value={formik.values.add_prop}
                                        onChange={formik.handleChange}
                                        required
                                    />
                                </Grid>
                            )} */}

                            <Grid item xs={12} sm={6}>
                                <label>
                                    <Checkbox
                                        name="assign"
                                        value={formik.values.assign}
                                        onChange={formik.handleChange}
                                    />
                                    ¿Asignar?
                                </label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <label>
                                    <Checkbox
                                        name="auth"
                                        value={formik.values.auth}
                                        onChange={formik.handleChange}
                                    />
                                    ¿Autorizar?
                                </label>
                            </Grid>

                            {(formik.values.assign || formik.values.auth) && (
                                <>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Preset"
                                            variant="outlined"
                                            name="line"
                                            value={formik.values.line}
                                            onChange={formik.handleChange}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Autocomplete
                                            fullWidth
                                            options={armOptions}
                                            getOptionLabel={(option) => option.label || ''}
                                            value={armOptions.find((option) => option.value === formik.values.arm) || null}
                                            onChange={(event, value) => {
                                                formik.setFieldValue('arm', value?.value || '');
                                                setError1(!value || !value.value); // revisa si está vacía la selección
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Brazo"
                                                    variant="outlined"
                                                    error={error1}
                                                    helperText={error1 ? 'Es necesario una selección' : ''}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </>
                            )}

                            <Grid item xs={12}>
                                <Autocomplete
                                    fullWidth
                                    options={operationOptions}
                                    getOptionLabel={(option) => option.label || ''}
                                    value={operationOptions.find((option) => option.value === formik.values.operation) || null}
                                    onChange={(event, value) => {
                                        formik.setFieldValue('operation', value?.value || '');
                                        setError(!value || !value.value); // Check if selection is empty and set error accordingly
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Operación"
                                            variant="outlined"
                                            error={error}
                                            helperText={error ? 'Es necesario una selección' : ''}
                                        />
                                    )}
                                />

                            </Grid>

                            {/* área de componentes */}
                            {(formik.values.operation === '3' || formik.values.operation === '4') && (
                                <Grid item xs={12}>
                                    <Paper elevation={3}>
                                        <Grid item xs={12}>
                                            <Label attached="top left" style={{ marginLeft: '10px', marginTop: '10px' }}>Componentes</Label>
                                            {formik.values.components.map((component, index) => {
                                                return (
                                                    <>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={4} style={{ marginLeft: '10px' }}>
                                                                <TextField
                                                                    fullWidth
                                                                    label={`Componente número ${index + 1}`}
                                                                    variant="outlined"
                                                                    name={`components[${index}].index`}
                                                                    value={component.index}
                                                                    onChange={formik.handleChange}
                                                                    required
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <TextField
                                                                    fullWidth
                                                                    label={`Porcentaje del componente ${index + 1}`}
                                                                    variant="outlined"
                                                                    name={`components[${index}].proportion`}
                                                                    value={component.proportion}
                                                                    onChange={formik.handleChange}
                                                                    required
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={2} style={{ marginTop: '10px', marginLeft: '90px' }}>
                                                                <Button type="button" className="btn btn-danger" color="error"
                                                                    onClick={() =>
                                                                        formik.setFieldValue(
                                                                            "components",
                                                                            formik.values.components.filter(
                                                                                (_, i) => i !== index
                                                                            )
                                                                        )
                                                                    }
                                                                >
                                                                    Eliminar
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                        <br></br>
                                                    </>
                                                );
                                            })}
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <h3 style={fontColorClass}>Porcentaje de componente total: {proportionSum} %</h3>
                                                </Grid>

                                                <Grid item xs={12} sm={12}>
                                                    <Button fullWidth type="button" className="btn btn-success" color="success"
                                                        onClick={() =>
                                                            formik.setFieldValue("components", [
                                                                ...formik.values.components,
                                                                { index: "", proportion: "" },
                                                            ])
                                                        }
                                                    >
                                                        Agregar
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            )}

                            {/* área de aditivos */}
                            {formik.values.operation === '4' && (
                                <Grid item xs={12}>
                                    <Paper elevation={3}>
                                        <Grid item xs={12}>
                                            <Label attached="top left" style={{ marginLeft: '10px', marginTop: '10px' }}>Aditivos</Label>
                                            {formik.values.aditive.map((add, index) => {
                                                return (
                                                    <>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={4} style={{ marginLeft: '10px' }}>
                                                                <TextField
                                                                    fullWidth
                                                                    label={`Aditivo número ${index + 1}`}
                                                                    variant="outlined"
                                                                    name={`aditive[${index}].index`}
                                                                    value={add.index}
                                                                    onChange={formik.handleChange}
                                                                    required
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <TextField
                                                                    fullWidth
                                                                    label={`Porcentaje del aditivo ${index + 1}`}
                                                                    variant="outlined"
                                                                    name={`aditive[${index}].proportion`}
                                                                    value={add.proportion}
                                                                    onChange={formik.handleChange}
                                                                    required
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={2} style={{ marginTop: '10px', marginLeft: '90px' }}>
                                                                <Button type="button" className="btn btn-danger" color="error"
                                                                    onClick={() =>
                                                                        formik.setFieldValue(
                                                                            "aditive",
                                                                            formik.values.aditive.filter(
                                                                                (_, i) => i !== index
                                                                            )
                                                                        )
                                                                    }
                                                                >
                                                                    Eliminar
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                        <br></br>
                                                    </>
                                                );
                                            })}
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <h3 style={fontColorClass2}>Porcentaje de aditivo total: {proportionSum2} %</h3>
                                                </Grid>

                                                <Grid item xs={12} sm={12}>
                                                    <Button fullWidth type="button" className="btn btn-success" color="success"
                                                        onClick={() =>
                                                            formik.setFieldValue("aditive", [
                                                                ...formik.values.aditive,
                                                                { index: "", proportion: "" },
                                                            ])
                                                        }
                                                    >
                                                        Agregar
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            )}

                            <Grid item xs={12} sm={12}>
                                <Button type="submit" variant="contained" className="btn btn-danger" color="error" fullWidth>
                                    Crear
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Formik >
        </>
    );
}

function initialValues(data) {
    return {
        index: data?.index || "",
        code: data?.code || "",
        lDesc: data?.lDes || "",
        sDesc: data?.sDesc || "",
        operation: data?.operation || "1",
        aditive: data?.aditive || [],
        components: data?.components || [],
        arm: data?.arm || 0,
        line: data?.line || "",
        assign: data?.assign || false,
        auth: data?.auth || false,
        add_prop: data?.add_prop || 0,
    };
}
