import React, { useEffect, useState } from "react";
import { HeaderPage } from "../../../Components/Client";
import { Funciones } from "./Funciones";
import { useSensores } from "../../../hooks";
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Grid, Paper } from "@mui/material";
import { ASGI_API } from '../../../vistas/utils/constants'
import ReactApexChart from 'react-apexcharts';

export function SensoresGraphs() {
    const { loading, Sensores, getSensores } = useSensores()

    const [sensorDictionary, setSensorDictionary] = useState({})

    const [series, setSeries] = useState([{ data: [] }]);
    const [options, setOptions] = useState({
        chart: {
            animations: {
                enabled: true,
                easing: 'linear',
                dynamicAnimation: {
                    speed: 1000,
                },
            },
        },
        xaxis: {
            type: 'datetime',

        },
        yaxis: {
            title: {
                text: '',
            },
        },
    });

    const {
        refetch,
    } = Funciones();

    // estilos de la celda de la tabla 
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    // estilos de la fila de la tabla
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    useEffect(() => {
        getSensores();
    }, [refetch])

    const connectToWebSocket = () => {
        // url del web socket
        const ws = new WebSocket(`${ASGI_API}/data/getSondasData`);

        // abre la conexión
        ws.onopen = () => {
            console.log('WebSocket connected.');
        };

        // obtiene los datos provenientes de la petición del web socket
        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);

            handleWebSocketData(data, Sensores);
        };

        // cierra la conexión
        ws.onclose = () => {
            console.log('WebSocket disconnected. Intentando reconectar...');
            setTimeout(connectToWebSocket, 3000); // intenta reconexión después de 3 segundos
        };

        return ws
    }

    useEffect(() => {
        let ws = connectToWebSocket();

        return () => {
            ws.close() // Cierre la conexión WebSocket cuando el componente se desmonte o cambie
        }
    }, [Sensores]);

    // Actualizar el estado de sensorData cuando se reciben datos de WebSocket
    const handleWebSocketData = (data) => {

        const { id_sensor, input, raw_value, time, value } = data;

        const sensorExists = Sensores?.find((sensor) => sensor.id_sensor === id_sensor);

        if (sensorExists) {
            // Actualiza el diccionario del sensor
            setSensorDictionary((prevDictionary) => ({
                ...prevDictionary,
                [id_sensor]: { input, raw_value, time, value },
            }));
        } else {
            console.log('Sensor no encontrado en la lista de sensores.');
        }

    };

    useEffect(() => {
        if (!Array.isArray(Sensores)) {
            return; // Cierra la función si no existe el array
        }

        const newSeries = Sensores.map((sensor) => {
            const sensorData = sensorDictionary[sensor.id_sensor];

            if (sensorData) {
                const existingSeries = series.find((s) => s.name === sensor.id_sensor);

                const timestamp = new Date(sensorData.time).getTime(); // Get timestamp
                const value = parseFloat(sensorData.value.toFixed(5)); // Parse value

                const newDataPoint = {
                    x: timestamp,
                    y: value,
                };

                if (existingSeries) {
                    return {
                        ...existingSeries,
                        data: [...existingSeries.data, newDataPoint],
                    };
                } else {
                    return {
                        name: sensor.id_sensor,
                        data: [newDataPoint],
                    };
                }
            }

            return {
                name: sensor.id_sensor,
                data: [],
            };
        });

        setSeries(newSeries);
    }, [sensorDictionary, Sensores])

    return (
        <>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <HeaderPage title="Visualización" />

                    <br></br>

                    {Sensores.map((sensor) => (
                        <>
                            <React.Fragment key={sensor.id_sensor}>
                                <Grid container spacing={3} >
                                    <Grid item xs={12} sm={12}>
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell align="center">Ip</StyledTableCell>
                                                        <StyledTableCell align="center">Tipo</StyledTableCell>
                                                        <StyledTableCell align="center">Función</StyledTableCell>
                                                        <StyledTableCell align="center">Unidad</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <StyledTableRow key={sensor.id_sensor} hover>
                                                        <StyledTableCell align="center">{sensor.ip}</StyledTableCell>
                                                        <StyledTableCell align="center">{sensor.etiquetaSensorData.tipo === '1' ? 'Análogos' : 'Comunicaciones'}</StyledTableCell>
                                                        <StyledTableCell align="center">{sensor.etiquetaSensorData.sensor_category_rel_data.cat_name}</StyledTableCell>
                                                        <StyledTableCell align="center">{sensor.etiquetaSensorData.sensor_unit_rel_data.unit_name}</StyledTableCell>
                                                    </StyledTableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <ReactApexChart
                                            options={options}
                                            series={series.filter((s) => s.name === sensor.id_sensor)}
                                            type="line"
                                            height={350}
                                        />
                                    </Grid>
                                </Grid>
                                <br></br>
                            </React.Fragment>
                        </>
                    ))}
                </>
            )}
        </>
    )
}