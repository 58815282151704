import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import ReactApexChart from 'react-apexcharts';
import { groupBy } from 'lodash';
import { format } from 'date-fns';

const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

const OverviewSales = ({ data, yAxisTitle }) => {
    // console.log(data)
    const theme = useTheme();
    const { secondary } = theme.palette.text;
    const line = theme.palette.divider;
    const [series, setSeries] = useState([]);
    const [colors, setColors] = useState([]);

    useEffect(() => {
        if (data) {
            if (data) {
                const randomColors = Array.from({ length: data.labels.length }, () => getRandomColor());
                setColors(randomColors);
                setSeries([{ data: data.datasets[0].data }]);
            }
        }
    }, [data]);

    const options = {
        chart: {
            type: 'column',
        },
        xaxis: {
            categories: data.labels,
        },
        yaxis: {
            title: {
                text: yAxisTitle,
            },
        },
        fill: {
            colors: colors, // Assign random colors to bars
        },
        stroke: {
            width: 1, // Set border width for bars
            colors: ['#000000']
        },
    };

    return (
        <ReactApexChart options={options} series={series} type="bar" height={420} />
    );
};

export default OverviewSales;
