import React, { useCallback, useState } from "react";
import Swal from "sweetalert2";
import { AddEditMezclas } from "../../../Components/Client";
import { useProductos } from "../../../hooks";

export function Funciones() {
  const { deleteProducto } = useProductos();
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);

  
  const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);



  const handleAdd = useCallback(() => {
    setTitleModal("Nuevo mezclas");
      setContentModal(
        <AddEditMezclas onClose={openCloseModal} onRefetch={onRefetch} />
      );
      openCloseModal();
  }, [openCloseModal, onRefetch]);

  const handleUpdate = useCallback((data) => {
    setTitleModal("Actualizar mezclas");
    setContentModal(
      <AddEditMezclas
        onClose={openCloseModal}
        onRefetch={onRefetch}
        mezclas={data}
      />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const handleDelete = useCallback(async (data) => {
    const result = await Swal.fire({
      title: "Estas seguro?",
      text: "No podrás revertir esta acción!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si, eliminar!",
      cancelButtonText: "No, cancelar!",
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      await deleteProducto(data.id);
      Swal.fire("Eliminado!", "El dato ha sido eliminado.", "success");
      onRefetch();
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelado", "Operación cancelada", "error");
    }
  }, [deleteProducto , onRefetch]);


  return {
    handleAdd,
    handleDelete,
    handleUpdate,
    openCloseModal,
    refetch,
    contentModal,
    titleModal,
    showModal,
  };
}
