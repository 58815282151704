import { useState } from "react";
import { getLicenciaApi } from "../../api/A_Multicliente/Licencia";
import { useAuth } from "../";

export function useLicencia() {
  const { auth, logout } = useAuth();
  const [state, setState] = useState({
    error: null,
    suscripcionActiva: true,
  });

  const { error, suscripcionActiva } = state;

  const getLicencia = async () => {
    try {
      const [result, response] = await getLicenciaApi(auth.token, auth.me.tenant);
      setState((prevState) => ({
        ...prevState,
        suscripcionActiva: result.suscripcion_activa,
      }));
      if (response.status === 401) {
        logout();
      }
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  return {
    error,
    suscripcionActiva,
    getLicencia,
  };
}
