import { useState } from "react";
import {
  getVehiculosApi, addVehiculosApi, updateVehiculosApi, deleteVehiculosApi, getVehTractoTanqueAPI,
  getVehVerByIdApi, addVehVerApi, getVehVerApi, getVehCarroTanqueAPI
} from "../../api/A_vehiculos/vehiculos";
import { useNavigate } from "react-router-dom";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "../";

export function useVehiculos() {
  const { auth, logout } = useAuth();
  const navigate = useNavigate();
  const { unauthorized, SessionExpired, errores, unauthorizedModal } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    Vehiculos: null,
    VehiculosDB: null,
    VehTractoTanque: null,
    DataChklst: null,
    VehCarroTanque: null,
  });

  const { loading, error, Vehiculos, VehiculosDB, VehTractoTanque, DataChklst, VehCarroTanque } = state;

  const getVehTractoTanque = async () => {
    try {
      const [result, response] = await getVehTractoTanqueAPI(auth.token, auth.me.tenant)
      setState((prevState) => ({ ...prevState, VehTractoTanque: result }))
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }))
    }
  }

  const getVehCarroTanque = async () => {
    try {
      const [result, response] = await getVehCarroTanqueAPI(auth.token, auth.me.tenant)
      setState((prevState) => ({ ...prevState, VehCarroTanque: result.results }))
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }))
    }
  }

  const getVehiculosDB = async (filter, vehiculo) => {
    try {
      const [result, response] = await getVehiculosApi(auth.token, auth.me.tenant, filter, vehiculo);
      setState((prevState) => ({ ...prevState, VehiculosDB: result.results }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  const handleResponse = (response) => {
    if (response.status === 401) {
      SessionExpired();
      logout();
    } else if (response.status === 403) {
      unauthorized();
      navigate("/");
    }
  };

  const getVehiculos = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getVehiculosApi(auth.token, auth.me.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, Vehiculos: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addVehiculos = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addVehiculosApi(data, auth.token, auth.me.tenant);
      if (response.status === 400) {
        if (result?.placa_veh) {
          throw new Error("La placa del vehiculo ya cuenta con un registro activo");
        }
        else if (result?.id_conductor) {
          throw new Error("El conductor ya se encuentra asociado a otro vehiculo");
        }  
        else {
          errores();
        }
      } else if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateVehiculos = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateVehiculosApi(id, data, auth.token, auth.me.tenant);
      if (response.status === 400) {
        if (result?.placa_veh) {
          throw new Error("La placa del vehiculo ya cuenta con un registro activo");
        } else {
          errores();
        }
      } else if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteVehiculos = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteVehiculosApi(id, auth.token, auth.me.tenant);
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const getVehVerById = async (id_vehiculo) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getVehVerByIdApi(id_vehiculo, auth.token, auth.me.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, DataChklst: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  }

  const getVehVer = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getVehVerApi(auth.token, auth.me.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, DataChklst: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  }

  const addVehVer = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addVehVerApi(data, auth.token, auth.me.tenant);
      if (response.status === 400) {
        errores();
      } else if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  }

  return {
    loading,
    error,
    VehiculosDB,
    Vehiculos,
    getVehiculosDB,
    getVehiculos,
    addVehiculos,
    updateVehiculos,
    deleteVehiculos,
    getVehTractoTanque,
    VehTractoTanque,
    getVehVer,
    getVehVerById,
    addVehVer,
    DataChklst,
    VehCarroTanque,
    getVehCarroTanque,
  };
}
