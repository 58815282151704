import { useFormik, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
//import { Button, Form, Input, Select, Label } from "semantic-ui-react";

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { Autocomplete } from "@mui/material"

import * as Yup from "yup";
import { alertas } from "../../Comons/Alertas/alertas";
import { useMaquinas, useOperador, useTcs3000 } from "../../../hooks"
import { operadoresSelect } from "../../Comons";

export function AddEditMaquinaria(props) {
    const { Successful, errores } = alertas();
    const { onClose, onRefetch, Maquinas } = props;
    const { addMaquina, updtMaquina } = useMaquinas()
    const { OperadorDB, getOpMaq } = useOperador()
    const { products, getTcsProd } = useTcs3000()
    const [Operadores, setOperadores] = useState([])
    const [error, setError] = useState(false)

    const [errors, setErrors] = React.useState({
        desplazamiento: false,
        est_maquina: false,
        tipo_desplazamiento_maquina: false,
        prim_combustible: false,
        sec_combustible: false,
        id_operador: false,
    });

    useEffect(() => {
        getOpMaq()
        getTcsProd()
    }, [])

    useEffect(() => {
        setOperadores(operadoresSelect(OperadorDB))
    }, [OperadorDB])

    const formik = useFormik({
        initialValues: initialValues(Maquinas),
        validationSchema: Yup.object(Maquinas ? updateScheme() : newScheme()),
        validateOnChange: false,
        onSubmit: async (formValue) => {
            try {

                switch (formValue.desplazamiento) {
                    case "Estacionario":
                        formValue.tipo_desplazamiento_maquina = "Ninguno"
                        formValue.trabajo_act = "0"
                        break
                    case "Móvil":
                        formValue.hrs_uso = "0"
                        break
                }
                
                // Si se proporciona un objeto se actualiza, de lo contrario se crea uno nuevo
                if (Maquinas) await updtMaquina(Maquinas.id_maquina, formValue);
                else await addMaquina(formValue);
                Successful()
                onRefetch();
                onClose();
            } catch (error) {
                // Muestra un mensaje de error si ocurre algún problema
                if (error?.message) {
                    toast.error(error.message, {
                        position: "top-center",
                    });
                } else {
                    onClose();
                }
            }
        },
    })

    const handleAutocompleteChange = (name, value) => {
        console.log(value)
        formik.setFieldValue(name, value);
        setErrors((prevErrors) => ({ ...prevErrors, [name]: !value }));
    };

    const handleAutocompleteChange2 = (name, value) => {
        formik.setFieldValue(name, value);
        setErrors((prevErrors) => ({ ...prevErrors, [name]: !value }));
    };

    const handleAutocompleteChange3 = (name, value) => {
        formik.setFieldValue(name, value);
        setErrors((prevErrors) => ({ ...prevErrors, [name]: !value }));
    };

    const hasDesplazamientoError = errors.desplazamiento
    const isAutocompleteDisabled = formik.values.desplazamiento === 'Estacionario' || hasDesplazamientoError;

    return (
        <>
            <Formik initialValues={initialValues()}>
                <form className="add-edit-secciones-form" onSubmit={formik.handleSubmit}>
                    <Grid container spacing={3}>
                        {/* fila 1 */}
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                fullWidth
                                label="Placa de la máquina"
                                name="placa"
                                value={formik.values.placa}
                                onChange={formik.handleChange}
                                error={formik.errors.placa}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                fullWidth
                                label="Nombre de la máquina"
                                name="nombre_maquina"
                                value={formik.values.nombre_maquina}
                                onChange={formik.handleChange}
                                error={formik.errors.nombre_maquina}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                label="Descripción"
                                name="descripcion"
                                value={formik.values.descripcion}
                                onChange={formik.handleChange}
                                error={formik.errors.descripcion}
                            />
                        </Grid>

                        {/* fila 2 */}
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                fullWidth
                                label="Modelo"
                                name="modelo_maquina"
                                value={formik.values.modelo_maquina}
                                onChange={formik.handleChange}
                                error={formik.errors.modelo_maquina}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Autocomplete
                                id="desp"
                                name="desplazamiento"
                                label="Desplazamiento"
                                fullWidth
                                value={formik.values.desplazamiento}
                                onChange={(event, value) => handleAutocompleteChange('desplazamiento', value)}
                                options={["Estacionario", "Móvil", "Mixto"]}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Desplazamiento"
                                        error={errors.desplazamiento}
                                        helperText={errors.desplazamiento ? 'Debe seleccionar un desplazamiento' : ''}
                                        variant="outlined"
                                    />
                                )}
                            />
                            {/* <FormControl required fullWidth>
                                <InputLabel id="desplb">Desplazamiento</InputLabel>
                                <Select
                                    labelId="desplb"
                                    id="desp"
                                    label="Desplazamiento"
                                    name="desplazamiento"
                                    value={formik.values.desplazamiento}
                                    onChange={formik.handleChange}
                                    error={formik.errors.desplazamiento}
                                >
                                    <MenuItem value="Estacionario">Estacionario</MenuItem>
                                    <MenuItem value="Móvil">Móvil</MenuItem>
                                    <MenuItem value="Mixto">Mixto</MenuItem>
                                </Select>
                            </FormControl> */}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                fullWidth
                                label="Ubicación"
                                name="ubicacion_maquina"
                                value={formik.values.ubicacion_maquina}
                                onChange={formik.handleChange}
                                error={formik.errors.ubicacion_maquina}
                            />
                        </Grid>

                        {/* fila 3 */}
                        <Grid item xs={12} sm={4}>
                            <Autocomplete
                                id="est"
                                name="est_maquina"
                                label="Estado de la máquina"
                                fullWidth
                                value={formik.values.est_maquina}
                                onChange={(event, value) => handleAutocompleteChange('est_maquina', value)}
                                options={["Activa", "Inactiva", "Mantenimiento"]}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Estado de la máquina"
                                        error={errors.est_maquina}
                                        helperText={errors.est_maquina ? 'Debe seleccionar un estado de la máquina' : ''}
                                        variant="outlined"
                                    />
                                )}
                            />
                            {/* <FormControl required fullWidth>
                                <InputLabel id="estlb">Estado de la máquina</InputLabel>
                                <Select
                                    labelId="estlb"
                                    id="est"
                                    label="Estado de la máquina"
                                    name="est_maquina"
                                    value={formik.values.est_maquina}
                                    onChange={formik.handleChange}
                                    error={formik.errors.est_maquina}
                                >
                                    <MenuItem value="Activa">Activa</MenuItem>
                                    <MenuItem value="Inactiva">Inactiva</MenuItem>
                                    <MenuItem value="Mantenimiento">Mantenimiento</MenuItem>
                                </Select>
                            </FormControl> */}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                fullWidth
                                label="Peso de la máquina"
                                name="peso_maquina"
                                value={formik.values.peso_maquina}
                                onChange={formik.handleChange}
                                error={formik.errors.peso_maquina}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Autocomplete
                                id="tipo"
                                name="tipo_desplazamiento_maquina"
                                label="Tipo de desplazamiento"
                                fullWidth
                                disabled={isAutocompleteDisabled}
                                enabled={formik.values.desplazamiento === 'Mixto' || formik.values.desplazamiento === 'Móvil'}
                                value={formik.values.tipo_desplazamiento_maquina}
                                onChange={(event, value) => handleAutocompleteChange('tipo_desplazamiento_maquina', value)}
                                options={["Orugas", "Neumáticos", "Ninguno"]}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Tipo de desplazamiento"
                                        error={errors.tipo_desplazamiento_maquina}
                                        helperText={errors.tipo_desplazamiento_maquina ? 'Debe seleccionar un tipo de desplazamiento' : ''}
                                        variant="outlined"

                                        InputProps={{
                                            ...params.InputProps,
                                            disabled: formik.values.desplazamiento === 'Estacionario',
                                        }}
                                    />
                                )}
                            />
                            {/* <FormControl fullWidth>
                                <InputLabel id="tipolb">Tipo de desplazamiento</InputLabel>
                                <Select
                                    labelId="tipolb"
                                    id="tipo"
                                    label="Tipo de desplazamiento"
                                    name="tipo_desplazamiento_maquina"
                                    value={formik.values.tipo_desplazamiento_maquina}
                                    onChange={formik.handleChange}
                                    error={formik.errors.tipo_desplazamiento_maquina}
                                    disabled={formik.values.desplazamiento === 'Estacionario'}
                                    enabled={formik.values.desplazamiento === 'Mixto' || formik.values.desplazamiento === 'Móvil'}
                                >
                                    <MenuItem value="Orugas">Orugas</MenuItem>
                                    <MenuItem value="Neumáticos">Neumáticos</MenuItem>
                                </Select>
                            </FormControl> */}
                        </Grid>

                        {/* fila 4 */}
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                fullWidth
                                label="Potencia nominal"
                                name="potencia_nominal"
                                placeholder="Ej: 1000 W"
                                value={formik.values.potencia_nominal}
                                onChange={formik.handleChange}
                                error={formik.errors.potencia_nominal}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Autocomplete
                                id="comb"
                                name="prim_combustible"
                                label="Combustible primario"
                                fullWidth
                                value={products?.find((option) => option.id_table === formik.values.prim_combustible) || null}
                                onChange={(event, value) => handleAutocompleteChange2('prim_combustible', value?.id_table)}
                                options={products || {}}
                                getOptionLabel={(producto) => producto.prod_name ||''}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Combustible primario"
                                        error={errors.prim_combustible}
                                        helperText={errors.prim_combustible ? 'Debe seleccionar un combustible primario' : ''}
                                        variant="outlined"
                                    />
                                )}
                            />
                            {/* <FormControl required fullWidth>
                                <InputLabel id="comblb">Combustible primario</InputLabel>
                                <Select
                                    labelId="comblb"
                                    id="comb"
                                    label="Combustible primario"
                                    name="prim_combustible"
                                    value={formik.values.prim_combustible}
                                    onChange={formik.handleChange}
                                    error={formik.errors.prim_combustible}
                                >
                                    {products?.map((producto) => (
                                        <MenuItem key={producto.id_table} value={producto.id_table}>
                                            {producto.prod_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl> */}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Autocomplete
                                id="comb"
                                name="sec_combustible"
                                label="Combustible secundario"
                                fullWidth
                                value={products?.find((option) => option.id_table === formik.values.sec_combustible) || null}
                                onChange={(event, value) => handleAutocompleteChange2('sec_combustible', value.id_table)}
                                options={products || {}}
                                getOptionLabel={(producto) => producto.prod_name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Combustible secundario"
                                        error={errors.sec_combustible}
                                        helperText={errors.sec_combustible ? 'Debe seleccionar un combustible secundario' : ''}
                                        variant="outlined"
                                    />
                                )}
                            />

                            {/* <FormControl fullWidth>
                                <InputLabel id="comblb2">Combustible secundario</InputLabel>
                                <Select
                                    labelId="comblb2"
                                    id="comb"
                                    label="Combustible secundario"
                                    name="sec_combustible"
                                    value={formik.values.sec_combustible}
                                    onChange={formik.handleChange}
                                    error={formik.errors.sec_combustible}
                                >
                                    {products?.map((producto) => (
                                        <MenuItem key={producto.id_table} value={producto.id_table}>
                                            {producto.prod_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl> */}
                        </Grid>

                        {/* fila 5 */}
                        <Grid item xs={12} sm={3}>
                            <TextField
                                required
                                fullWidth
                                label="Capacidad del tanque"
                                name="cant_tot_combustible"
                                placeholder="Ej: 150"
                                value={formik.values.cant_tot_combustible}
                                onChange={formik.handleChange}
                                error={formik.errors.cant_tot_combustible}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                required
                                fullWidth
                                label="Km actuales"
                                name="trabajo_act"
                                placeholder="Ej: 10000"
                                value={formik.values.trabajo_act}
                                onChange={formik.handleChange}
                                error={formik.errors.trabajo_act}
                                disabled={formik.values.desplazamiento === 'Estacionario'}
                                enabled={formik.values.desplazamiento === 'Mixto' || formik.values.desplazamiento === 'Móvil'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                required
                                fullWidth
                                label="Hr uso actuales"
                                name="hrs_uso"
                                placeholder="Ej: 10000"
                                value={formik.values.hrs_uso}
                                onChange={formik.handleChange}
                                error={formik.errors.hrs_uso}
                                disabled={formik.values.desplazamiento === 'Móvil'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                fullWidth
                                label="Marca"
                                name="fabricante_maquina"
                                placeholder="Ej: Cat"
                                value={formik.values.fabricante_maquina}
                                onChange={formik.handleChange}
                                error={formik.errors.fabricante_maquina}
                            />
                        </Grid>

                        {/* fila 6 */}
                        <Grid item xs={12} sm={12}>
                            <Autocomplete
                                id="cond"
                                name="id_operador"
                                label="Conductor/operador asociado"
                                fullWidth
                                value={Operadores?.find((o) => o.value === formik.values.id_operador) || null}
                                onChange={(event, value) => handleAutocompleteChange3('id_operador', value?.value)}
                                options={Operadores || {}}
                                getOptionLabel={(option) => option.text}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Conductor/operador asociado"
                                        error={errors.id_operador}
                                        helperText={errors.id_operador ? 'Debe seleccionar un conductor/operador asociado' : ''}
                                        variant="outlined"
                                    />
                                )}
                            />
                            {/* <FormControl fullWidth>
                                <InputLabel id="condlb">Conductor/operador asociado</InputLabel>
                                <Select
                                    labelId="condlb"
                                    id="cond"
                                    label="Conductor/operador asociado"
                                    fullWidth
                                    name="id_operador"
                                    value={formik.values.id_operador}
                                    onChange={formik.handleChange}
                                >
                                    {Operadores.map((operador) => (
                                        <MenuItem key={operador.key} value={operador.value}>
                                            {operador.text}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl> */}
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Button type="submit" variant="contained" className="btn btn-danger" color="error" fullWidth>
                                {Maquinas ? 'Actualizar' : 'Crear'}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Formik>
        </>
    )
}

function initialValues(data) {
    return {
        placa: data?.placa || "",
        nombre_maquina: data?.nombre_maquina || "",
        descripcion: data?.descripcion || "",
        modelo_maquina: data?.modelo_maquina || "",
        desplazamiento: data?.desplazamiento || "Estacionario",
        ubicacion_maquina: data?.ubicacion_maquina || "",
        est_maquina: data?.est_maquina,
        peso_maquina: data?.peso_maquina || "",
        tipo_desplazamiento_maquina: data?.tipo_desplazamiento_maquina || "Orugas",
        potencia_nominal: data?.potencia_nominal || "",
        prim_combustible: data?.combustible?.id_table,
        sec_combustible: data?.combustible_sec?.id_table,
        fabricante_maquina: data?.fabricante_maquina || "",
        cant_tot_combustible: data?.cant_tot_combustible || "",
        trabajo_act: data?.trabajo_act || "0",
        hrs_uso: data?.hrs_uso || "0",
        id_operador: data?.id_operador
    };
}

function newScheme() {
    return {
        placa: Yup.string().required(true),
        nombre_maquina: Yup.string(true),
        descripcion: Yup.string(),
        modelo_maquina: Yup.string().required(true),
        desplazamiento: Yup.string().required(true),
        ubicacion_maquina: Yup.string().required(true),
        est_maquina: Yup.string().required(true),
        peso_maquina: Yup.string().required(true),
        tipo_desplazamiento_maquina: Yup.string().required(true),
        potencia_nominal: Yup.string().required(true),
        prim_combustible: Yup.number().required(true) ,
        sec_combustible: Yup.number(),
        fabricante_maquina: Yup.string(),
        cant_tot_combustible: Yup.string().required(true),
        trabajo_act: Yup.string().required(true),
        hrs_uso: Yup.string().required(true),
        id_operador: Yup.string()
    };
}

function updateScheme() {
    return {
        placa: Yup.string().required(true),
        nombre_maquina: Yup.string().required(true),
        descripcion: Yup.string(),
        modelo_maquina: Yup.string().required(true),
        desplazamiento: Yup.string().required(true),
        ubicacion_maquina: Yup.string().required(true),
        est_maquina: Yup.string().required(true),
        peso_maquina: Yup.string().required(true),
        tipo_desplazamiento_maquina: Yup.string().required(true),
        potencia_nominal: Yup.string().required(true),
        prim_combustible: Yup.number().required(true),
        sec_combustible: Yup.number(),
        fabricante_maquina: Yup.string(),
        cant_tot_combustible: Yup.string().required(true),
        trabajo_act: Yup.string().required(true),
        hrs_uso: Yup.string().required(true),
        id_operador: Yup.string()
    };
}