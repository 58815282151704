import { useDocumentosOperadores } from "../../../hooks";
import React, { useCallback, useState, useEffect } from "react";
import { DocumentosBase } from "../../Comons";

export function DocumentOperador(props) {
  const { data } = props;
  const [refetch, setRefetch] = useState(false);
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);
  const {
    DocumentosOperadores,
    getDocumentosOperadores,
    updateDocumentosOperadores,
  } = useDocumentosOperadores();

  useEffect(() => {
    getDocumentosOperadores(data);
  }, [refetch]);

  return (
    <>
      <DocumentosBase
        update={updateDocumentosOperadores}
        documentos={DocumentosOperadores}
        onRefetch={onRefetch}
      />
    </>
  );
}
