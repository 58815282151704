

  export const filterValue = [
    {
      name: 'fecha_captura',
      operator: 'before',
      type: 'date',
      value: ''
    },
    {
      name: 'hora_captura',
      operator: 'before',
      type: 'date',
      value: ''
    },
    { name: 'nivel_producto_m', operator: 'startsWith', type: 'string', value: '' },
    { name: 'nivel_producto_cm', operator: 'startsWith', type: 'string', value: '' },
    { name: 'nivel_producto_mm', operator: 'startsWith', type: 'string', value: '' },
    { name: 'nivel_agua_libre_m', operator: 'startsWith', type: 'string', value: '' },
    { name: 'nivel_agua_libre_cm', operator: 'startsWith', type: 'string', value: '' },
    { name: 'nivel_agua_libre_mm', operator: 'startsWith', type: 'string', value: '' },
    { name: 'temperatura_liquido', operator: 'startsWith', type: 'string', value: '' },
    { name: 'temperatura_ambiente', operator: 'startsWith', type: 'string', value: '' },
    { name: 'contenido_agua_sedimento', operator: 'startsWith', type: 'string', value: '' },
    
    
  ];