export const columns = [
    
    {
      header: "Nombre del grupo",
      name: "name",
      maxWidth: 1500, defaultFlex: 1
    },
    // {
    //   header: "Permisos asignados",
    //   name: "permissions",
    //   maxWidth: 1000, defaultFlex: 1
    // },
    
  ];


  export const filterValue = [
    { name: 'name', operator: 'startsWith', type: 'string', value: '' },
    { name: 'permissions', operator: 'startsWith', type: 'string', value: '' },

  ];