import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { FormControl, FormControlLabel, Grid, Input, InputLabel, Select, Typography } from '@mui/material';
import { Paper, Container, Button } from '@material-ui/core'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import * as Yup from "yup";
import Tooltip from '@material-ui/core/Tooltip';
import { ASGI_API } from "../../../../src/vistas/utils/constants"
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { DataGridNoOpt } from "../../../Components/Comons";
import { columns } from './DataVisualizerFormat'

export function DataVisualizer(props) {

    const { onClose, onRefetch, Sensores } = props;

    const modifiedData = Sensores ? Sensores?.valores?.map((item) => ({
        ...item,
        inputRange: Sensores?.inputRangeText,
    })) : [];

    return (
        <>
            <Grid container spacing={3}>
                {modifiedData ? (
                    <Grid item xs={12} sm={12}>
                        <DataGridNoOpt
                            data={modifiedData}
                            title="Valores"
                            id="valores"
                            columns={columns}
                        />
                    </Grid>
                ) : (
                    <Grid item xs={12} sm={12} textAlign={'center'}>
                        <Typography variant="h6">No hay datos para mostrar</Typography>
                    </Grid>
                )}
            </Grid>
        </>
    )
}