export const columns = [
    {
        header: "# orden",
        name: "n_orden",
        maxWidth: 1000,
        defaultFlex: 1
    },
    {
        header: "Tanque",
        name: "tanque",
        maxWidth: 1000,
        defaultFlex: 1
    },
    {
        header: "Proveedor",
        name: "proveedor",
        maxWidth: 1000,
        defaultFlex: 1
    },
    {
        header: "Estado",
        name: "estado",
        maxWidth: 1000,
        defaultFlex: 1
    },
    {
        header: "Combustible",
        name: "tipo_combustible",
        maxWidth: 1000,
        defaultFlex: 1
    },
    {
        header: "Fecha de límite",
        name: "fecha_vencimiento",
        maxWidth: 1000,
        defaultFlex: 1
    },
    {
        header: "Cantidad",
        name: "cant_consumo",
        maxWidth: 1000,
        defaultFlex: 1
    },
]

export const filterValue = [
    { name: 'n_orden', operator: 'startsWith', type: 'string', value: '' },
    { name: 'tanque', operator: 'startsWith', type: 'string', value: '' },
    { name: 'proveedor', operator: 'startsWith', type: 'string', value: '' },
    { name: 'estado', operator: 'startsWith', type: 'string', value: '' },
    { name: 'tipo_combustible', operator: 'startsWith', type: 'string', value: '' },
    { name: 'fecha_vencimiento', operator: 'startsWith', type: 'string', value: '' },
    { name: 'cant_consumo', operator: 'startsWith', type: 'string', value: '' },
]