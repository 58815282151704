import React, { useCallback, useEffect, useState } from 'react'
import { useControlDocVehiculos } from '../../../hooks';
import { TablaControl } from '../../Comons';


export function ModuloControlVehiculos() {
  const { ControlDocVehiculos, getControlDocVehiculos, addControlDocVehiculos, updateControlDocVehiculos, deleteControlDocVehiculos } = useControlDocVehiculos();
  const [refetch, setRefetch] = useState(false);
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);
  useEffect(() => {
    getControlDocVehiculos();
  }, [refetch]);
  
  return (
    <>
      <TablaControl 
        documentos={ControlDocVehiculos}
        addDocumentos={addControlDocVehiculos}
        updateDocumentos={updateControlDocVehiculos}
        deleteDocumentos={deleteControlDocVehiculos}
        onRefetch={onRefetch}
        suggestions={suggestions}
      />
    </>
  )
}


const suggestions = [
  'Seguro Obligatorio de Accidente de Tránsito vigente (SOAT)',
  'Póliza de responsabilidad civil extracontractual vigente',
  'Certificado vigente de la revisión Técnico-mecánica del vehículo emitida por un taller certificado',
  'Certificado de pruebas de hermeticidad y resistencia a presión de la cisterna',
  'Certificado vigente de calibración de las válvulas de seguridad (PSV) de la cisterna @ 250 psig (Vehículos que transporta GLP)',
  'Tabla de aforo de la cisterna',
  'Tarjeta de Emergencia',
  'Hoja de Seguridad del Producto (MSDS)'
];