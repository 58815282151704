import { MenuItem, Select } from "@material-ui/core";
import { Box, Container, Unstable_Grid2 as Grid } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Loader } from "semantic-ui-react";
import { useGrafica, useTanques } from "../../../hooks";
import { LineChart } from "../../../sections/overview/overview-Multiaxis";
import { OverviewLatestOrders } from "../../../sections/overview/overview-latest-orders";
import { OverviewLatestProducts } from "../../../sections/overview/overview-latest-products";
import { OverviewSales } from "../../../sections/overview/overview-sales";
import { OverviewTraffic } from "../../../sections/overview/overview-traffic";
import { DataGridNoOpt } from "../../../Components/Comons";

import CircularProgress from '@mui/material/CircularProgress';

const now = new Date();
export function IntegracionAdmin() {
  const {
    loading,
    dataTT,
    dataTTA,
    dataCP,
    dataPM,
    dataPorcentajes,
    getTipoTipoTransaccion,
    getTipoTransaccionAgno,
    getPorcentajeTanque,
    getCantidadProducto,
    getCantidadProductoMes,
  } = useGrafica();
  const listaAgno = [2022, 2023, 2024, 2025, 2026];

  const [refetch, setRefetch] = useState(false);
  const [tanqueSeleccionado, setTanqueSeleccionado] = useState("");
  const [tipoTransaccion, setTipoTransaccion] = useState("DESPACHO");
  const [agnoSeleccionado, setAgnoSeleccionado] = useState("2023");
  const { tanquesDB, getTanquesDB } = useTanques();
  const [tanques, setTanques] = useState([]);
  const [value, setValue] = useState(0);
  const [valueF, setValueF] = useState(0);
  const [showExtraColumns, setShowExtraColumns] = useState(false); // Estado para mostrar las columnas adicionales

  const handleChange = (event, newValue) => {
    setValue(newValue);

  };
  const handleChangeF = (event, newValue2) => {
    setValueF(newValue2);
  };

  useEffect(() => {
    const obtenerDatosTanques = async () => {
      const datosTanques = await getTanquesDB();
      setTanques(datosTanques);
    };

    obtenerDatosTanques();
  }, []);

  useEffect(() => {
    if (tanquesDB) {
      getTipoTipoTransaccion(
        tipoTransaccion,
        agnoSeleccionado,
        tanqueSeleccionado
      );
      getTipoTransaccionAgno(
        tipoTransaccion,
        agnoSeleccionado,
        tanqueSeleccionado
      );
      getPorcentajeTanque(tipoTransaccion, agnoSeleccionado);
      getCantidadProducto(tipoTransaccion, agnoSeleccionado);
      getCantidadProductoMes(tipoTransaccion, agnoSeleccionado);
    }
  }, [tanquesDB, tipoTransaccion, tanqueSeleccionado, agnoSeleccionado]);

  const chartSeries = dataPorcentajes
    ? dataPorcentajes.map((tanque) => tanque.porcentaje)
    : [];
  const labels = dataPorcentajes
    ? dataPorcentajes.map((tanque) => tanque.nombre_tanque)
    : [];
  const handleToggleColumns = () => {
    // Cambiar el estado showExtraColumns al hacer clic en el botón de recargar o reorganizar
    setShowExtraColumns((prevShowExtraColumns) => !prevShowExtraColumns);
  };
  return (
    <>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <div>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              py: 8,
            }}
          >
            <Container maxWidth="xl">
              <Grid
                xs={12}
                sm={12}
                lg={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Select
                  value={tanqueSeleccionado}
                  onChange={(event) =>
                    setTanqueSeleccionado(event.target.value)
                  }
                  style={{
                    marginRight: "1rem",
                    width: "10rem",
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                  }}
                >
                  <MenuItem key="" value="">
                    --Tanques--
                  </MenuItem>
                  {tanquesDB.map((tanque) => (
                    <MenuItem key={tanque.id_tanque} value={tanque.id_tanque}>
                      {tanque.nombre_tanque}
                    </MenuItem>
                  ))}
                </Select>
                <Select
                  value={tipoTransaccion}
                  onChange={(event) => setTipoTransaccion(event.target.value)}
                  style={{
                    marginRight: "1rem",
                    width: "10rem",
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                  }}
                >
                  <MenuItem value="DESPACHO">DESPACHO</MenuItem>
                  <MenuItem value="RECIBO">RECIBO</MenuItem>
                </Select>
                <Select
                  value={agnoSeleccionado}
                  onChange={(event) => setAgnoSeleccionado(event.target.value)}
                  style={{
                    width: "6rem",
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                  }}
                >
                  {listaAgno.map((agno) => (
                    <MenuItem key={agno} value={agno}>
                      {agno}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid container spacing={3}>

                <Grid xs={12} lg={8}>


                  <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={valueF}
                        onChange={handleChangeF}
                        aria-label="basic tabs example"
                      >
                        <Tab label="Grafica de consumos" {...a11yProps(0)} />
                        <Tab label="Consumos por productos" {...a11yProps(1)} />

                      </Tabs>
                    </Box>
                    <TabPanel value={valueF} index={0}>
                      {dataTT && dataTT.length > 0 ? (
                        <OverviewSales
                          chartSeries={[
                            {
                              name: "Año Actual",
                              data: dataTTA,
                            },
                            {
                              name: "Año Anterior",
                              data: [
                                1212, 1211, 124, 126, 122, 129, 129, 1210, 1121,
                                1122, 1312, 1213,
                              ],
                            },
                          ]}
                          sx={{ height: "100%" }}
                        />
                      ) : (
                        <OverviewSales
                          chartSeries={[
                            {
                              name: "Año Actual",
                              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                            },
                            {
                              name: "Año Anterior",
                              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                            },
                          ]}
                          sx={{ height: "100%" }}
                        />
                      )}
                    </TabPanel>
                    <TabPanel value={valueF} index={1}>
                      {dataPM && dataPM.length > 0 && (
                        <LineChart data={dataPM} />
                      )}
                    </TabPanel>

                  </Box>


                </Grid>
                <Grid xs={12} md={6} lg={4}>
                  <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                      >
                        {/* <Tab label="Tanque" {...a11yProps()} /> */}
                        <Tab label="porcentaje de uso" {...a11yProps(0)} />
                        <Tab label="Por producto" {...a11yProps(1)} />
                      </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                      {dataPorcentajes && dataPorcentajes.length > 0 && (
                        <OverviewTraffic
                          chartSeries={chartSeries}
                          labels={labels}
                          sx={{ height: "100%" }}
                        />
                      )}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <OverviewLatestProducts
                        products={dataCP}
                        sx={{ height: "100%" }}
                      />
                    </TabPanel>
                  </Box>
                </Grid>

                <Grid xs={12} md={12} lg={12}>
                  {dataTT && dataTT.length > 0 ? (
                    <DataGridNoOpt
                      data={dataTT}
                      title="Correcciones"
                      columns={showExtraColumns ? extraColumns : columns} // Selecciona las columnas a mostrar
                      cambioTabla={handleToggleColumns}
                    />
                  ) : (<>
                  </>)}

                </Grid>
              </Grid>
            </Container>
          </Box>
        </div>
      )}
    </>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const columns = [
  {
    name: "n_orden",
    header: "Orden",
    flex: 1,
    minWidth: 100,
  },
  {
    name: "tanque",
    header: "Tanque",
    flex: 1,
    minWidth: 100,
    render: ({ data }) => {
      const nombreTanque = data?.tanque?.nombre_tanque || "mezcla";
      const style = nombreTanque === "mezcla" ? { color: "red" } : {}; // Establecer el estilo inline para el color rojo si el valor es "mezcla"
      return <span style={style}>{nombreTanque}</span>;
    }
  },
  {
    name: "productos",
    header: "Producto",
    flex: 1,
    minWidth: 100,
    render: ({ data }) =>
      data?.productos?.nombre
  },

  {
    name: "v_entregado",
    header: "Volumen Entregado",
    flex: 1,
    minWidth: 150,
    render: ({ data }) => {
      const vEntregado = data?.v_entregado || 0;
      const formattedValue = (vEntregado / 100).toFixed(2);
      return formattedValue.padStart(4, '0'); // Agregar ceros a la izquierda si es necesario para un total de 4 caracteres.
    }
  },
  {
    name: "v_restante",
    header: "Volumen Restante",
    flex: 1,
    minWidth: 150,
    render: ({ data }) => {
      const vRestante = data?.v_restante || 0;
      const formattedValue = (vRestante / 100).toFixed(2);
      return formattedValue.padStart(4, '0'); // Agregar ceros a la izquierda si es necesario para un total de 4 caracteres.
    }
  },
  {
    name: "vol_gov",
    header: "Vol. GOV",
    flex: 1,
    minWidth: 100,
    render: ({ data }) => {
      const volGov = data?.vol_gov || 0;
      const formattedValue = (volGov / 100).toFixed(2);
      return formattedValue.padStart(4, '0'); // Agregar ceros a la izquierda si es necesario para un total de 4 caracteres.
    }
  },
  {
    name: "vol_gsv",
    header: "Vol. GSV",
    flex: 1,
    minWidth: 100,
    render: ({ data }) => {
      const volGsv = data?.vol_gsv || 0;
      const formattedValue = (volGsv / 100).toFixed(2);
      return formattedValue.padStart(4, '0'); // Agregar ceros a la izquierda si es necesario para un total de 4 caracteres.
    }
  },
  {
    name: "nsv",
    header: "NSV",
    flex: 1,
    minWidth: 100,
    render: ({ data }) => {
      const nsv = data?.nsv || 0;
      const formattedValue = (nsv / 100).toFixed(2);
      return formattedValue.padStart(4, '0'); // Agregar ceros a la izquierda si es necesario para un total de 4 caracteres.
    }
  },
  {
    name: "ctl",
    header: "CTL",
    flex: 1,
    minWidth: 100,
  },
  {
    name: "cpl",
    header: "CPL",
    flex: 1,
    minWidth: 100,
  },
  {
    name: "ctpl",
    header: "CTPL",
    flex: 1,
    minWidth: 100,
  },


];

export const extraColumns = [

  {
    name: "pres_promedio",
    header: "Pres. Promedio",
    flex: 1,
    minWidth: 150,
  },
  {
    name: "temp_promedio",
    header: "Temp. Promedio",
    flex: 1,
    minWidth: 150,
  },
  {
    name: "densidad_promedio",
    header: "Dens. Promedio",
    flex: 1,
    minWidth: 150,
  },
  {
    name: "meter_factor",
    header: "Meter Factor",
    flex: 1,
    minWidth: 150,
  },

  {
    name: "bsw",
    header: "BSW",
    flex: 1,
    minWidth: 100,
  },
  {
    name: "gravedad_api",
    header: "Gravedad API",
    flex: 1,
    minWidth: 150,
  },
  {
    name: "tipo_transaccion",
    header: "Tipo de Transacción",
    flex: 1,
    minWidth: 150,
  },
  {
    name: "fecha_inicial_med",
    header: "Fecha Inicial Med",
    flex: 1,
    minWidth: 200,
  },
  {
    name: "fecha_final_med",
    header: "Fecha Final Med",
    flex: 1,
    minWidth: 200,
  },
  {
    name: "fecha_crea",
    header: "Fecha Crea",
    flex: 1,
    minWidth: 150,
  },

];