export const columns = [
    
    {
      title: "Nombre asociado",
      field: "nombre",
    },
    {
      title: "Islas asociadas",
      field: "Islas_id",
    }
    
  
  ];
  export const filterValue = [
    { name: 'nombre_tabla', operator: 'startsWith', type: 'string', value: '' },
    { name: 'id_secciones', operator: 'startsWith', type: 'string', value: '' },
    { name: 'id_fracciones', operator: 'startsWith', type: 'string', value: '' },
    
    
  ];