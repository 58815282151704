export const columns = [
    {
        header: "Fecha",
        name: "fecha_crea",
        defaultFlex: 2, // Adjust the flex value as needed
        minWidth: 150,
    },
    {
        header: "Origen",
        name: "origen",
        defaultFlex: 2, // Adjust the flex value as needed
        minWidth: 150,
    },
    {
        header: "Placa",
        name: "placa_obj",
        defaultFlex: 2, // Adjust the flex value as needed
        minWidth: 150,
    },
    {
        header: "Combustible",
        name: "tipo_combustible_nombre",
        defaultFlex: 2, // Adjust the flex value as needed
        minWidth: 150,
    },
    {
        header: "Consumo",
        name: "cant_consumo",
        defaultFlex: 2, // Adjust the flex value as needed
        minWidth: 150,
    },
    {
        header: "Medida",
        name: "medida_consumo", // Access the field using dot notation
        defaultFlex: 2, // Adjust the flex value as needed
        minWidth: 150,
    },
    {
        header: "Precio",
        name: "precio_consumo",
        defaultFlex: 2, // Adjust the flex value as needed
        minWidth: 150,
    },
    {
        header: "Tipo de objeto",
        name: "tipo_obj",
        defaultFlex: 2, // Adjust the flex value as needed
        minWidth: 150,
    },
    {
        header: "Desplazamiento",
        name: "desplazamiento",
        defaultFlex: 2, // Adjust the flex value as needed
        minWidth: 150,
    },
    {
        header: "Km actual",
        name: "trabajo_act",
        defaultFlex: 2, // Adjust the flex value as needed
        minWidth: 150,
    },
    {
        header: "Hr actual",
        name: "hrs_uso",
        defaultFlex: 2, // Adjust the flex value as needed
        minWidth: 150,
    },
    {
        header: "Capacidad total",
        name: "cant_tot_combustible",
        defaultFlex: 2, // Adjust the flex value as needed
        minWidth: 150,
    },
    {
        header: "Operador",
        name: "nombre_op",
        defaultFlex: 2, // Adjust the flex value as needed
        minWidth: 150,
    },
    {
        header: "Conductor",
        name: "nombre_cond",
        defaultFlex: 2, // Adjust the flex value as needed
        minWidth: 150,
    },

]

export const columnsConsumoInterno = [
    {
        header: "Fecha",
        name: "fecha_crea",
        maxWidth: 1000,
        defaultFlex: 1
    },
    {
        header: "No. Orden",
        name: "n_orden",
        maxWidth: 1000,
        defaultFlex: 1
    },
    {
        header: "Tanque",
        name: "nombre_tanque",
        maxWidth: 1000,
        defaultFlex: 1
    },
    {
        header: "Capacidad anterior",
        name: "cap_momento",
        maxWidth: 1000,
        defaultFlex: 1
    },
    {
        header: "Cantidad registrada",
        name: "cant_consumo",
        maxWidth: 1000,
        defaultFlex: 1
    },
    {
        header: "Combustible",
        name: "tipo_combustible",
        maxWidth: 1000,
        defaultFlex: 1
    },
    {
        header: "Precio",
        name: "precio",
        maxWidth: 1000,
        defaultFlex: 1
    },
    {
        header: "Proveedor",
        name: "proveedor",
        maxWidth: 1000,
        defaultFlex: 1
    },
    // {
    //     header: "Operador",
    //     name: "operador",
    //     maxWidth: 1000,
    //     defaultFlex: 1
    // },
]

export const reportTableColumns = [
    {
        header: "Fecha",
        name: "fecha_crea",
        maxWidth: 1000,
        defaultFlex: 1
    },
    {
        header: "Placa",
        name: "placa_obj",
        maxWidth: 1000,
        defaultFlex: 1
    },
    {
        header: "Tipo de objeto",
        name: "tipo_obj",
        maxWidth: 1000,
        defaultFlex: 1
    },
    {
        header: "Consumo",
        name: "cant_consumo",
        maxWidth: 1000,
        defaultFlex: 1
    },
    {
        header: "Capacidad tanque",
        name: "cant_tot_combustible",
        maxWidth: 1000,
        defaultFlex: 1
    },
    {
        header: "Km",
        name: "trabajo_act",
        maxWidth: 1000,
        defaultFlex: 1
    },
    {
        header: "Hr",
        name: "hrs_uso",
        maxWidth: 1000,
        defaultFlex: 1
    },
    {
        header: "Desplazamiento",
        name: "desplazamiento",
        maxWidth: 1000,
        defaultFlex: 1
    },
    {
        header: "Operador/Conductor",
        name: "nombre",
        maxWidth: 1000,
        defaultFlex: 1
    },
    {
        header: "Tanque",
        name: "tanque",
        maxWidth: 1000,
        defaultFlex: 1
    },
]

export const TicketColumns = [
    {
        header: "Fecha de generación",
        name: "fecha_crea",
        maxWidth: 1000,
        defaultFlex: 1
    },
    {
        header: "TCS",
        name: "modelo",
        maxWidth: 1000,
        defaultFlex: 1
    },
    {
        header: "Placa del vehículo",
        name: "placa",
        maxWidth: 1000,
        defaultFlex: 1
    },
    {
        header: "Conductor",
        name: "conductor",
        maxWidth: 1000,
        defaultFlex: 1
    },
]

export const filterValue = [
    { name: 'cant_consumo', operator: 'startsWith', type: 'string', value: '' },
    { name: 'fecha_crea', operator: 'startsWith', type: 'string', value: '' },
    { name: 'placa_obj', operator: 'startsWith', type: 'string', value: '' },
    { name: 'precio_consumo', operator: 'startsWith', type: 'string', value: '' },
    { name: 'tipo_combustible_nombre', operator: 'startsWith', type: 'string', value: '' },
    { name: 'tipo_obj', operator: 'startsWith', type: 'string', value: '' },
    { name: 'desplazamiento', operator: 'startsWith', type: 'string', value: '' },
    { name: 'medida_consumo', operator: 'startsWith', type: 'string', value: '' },
    { name: 'trabajo_act', operator: 'startsWith', type: 'string', value: '' },
    { name: 'hrs_uso', operator: 'startsWith', type: 'string', value: '' },
    { name: 'cant_tot_combustible', operator: 'startsWith', type: 'string', value: '' },
    { name: 'nombre_op', operator: 'startsWith', type: 'string', value: '' },
    { name: 'nombre_cond', operator: 'startsWith', type: 'string', value: '' },
    { name: 'origen', operator: 'startsWith', type: 'string', value: '' },
]

export const filterValueCI = [
    { name: 'fecha_crea', operator: 'startsWith', type: 'string', value: '' },
    { name: 'n_orden', operator: 'startsWith', type: 'string', value: '' },
    { name: 'nombre_tanque', operator: 'startsWith', type: 'string', value: '' },
    { name: 'cap_momento', operator: 'startsWith', type: 'string', value: '' },
    { name: 'cant_consumo', operator: 'startsWith', type: 'string', value: '' },
    { name: 'tipo_combustible', operator: 'startsWith', type: 'string', value: '' },
    { name: 'precio', operator: 'startsWith', type: 'string', value: '' },
    { name: 'proveedor', operator: 'startsWith', type: 'string', value: '' },
    { name: 'operador', operator: 'startsWith', type: 'string', value: '' },
]

export const filterReportTable = [
    { name: 'fecha_crea', operator: 'startsWith', type: 'string', value: '' },
    { name: 'placa_obj', operator: 'startsWith', type: 'string', value: '' },
    { name: 'tipo_obj', operator: 'startsWith', type: 'string', value: '' },
    { name: 'cant_consumo', operator: 'startsWith', type: 'string', value: '' },
    { name: 'cant_tot_combustible', operator: 'startsWith', type: 'string', value: '' },
    { name: 'trabajo_act', operator: 'startsWith', type: 'string', value: '' },
    { name: 'hrs_uso', operator: 'startsWith', type: 'string', value: '' },
    { name: 'desplazamiento', operator: 'startsWith', type: 'string', value: '' },
    { name: 'nombre', operator: 'startsWith', type: 'string', value: '' },
    { name: 'tanque', operator: 'startsWith', type: 'string', value: '' },
]

export const filterTicketData = [
    { name: 'fecha_crea', operator: 'startsWith', type: 'string', value: '' },
    { name: 'modelo', operator: 'startsWith', type: 'string', value: '' },
    { name: 'placa', operator: 'startsWith', type: 'string', value: '' },
    { name: 'conductor', operator: 'startsWith', type: 'string', value: '' },
]