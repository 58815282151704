import React from 'react'
import { Tab } from 'semantic-ui-react'

import { IngresoDatosAdmin } from './IngresoDatosAdmin'
import { DatosCalculadosAdmin } from '../DatosCalculadosPage/DatosCalculadosAdmin'



export function IngresoDatosPage() {
    const panes = [
        {
          menuItem: { key: 'Conciliaciones',content: <> Medicion manual</> },
          render: () => <Tab.Pane><IngresoDatosAdmin /></Tab.Pane>,
        },
        {
          menuItem: { key: 'Medicion manual',content: <>Calculos mediciones</> },
          render: () => <Tab.Pane><DatosCalculadosAdmin /></Tab.Pane>,
        },
    
      ]
    
      return (
        <Tab panes={panes} />
      )
}