import {
    Button,
    Switch
} from "@mui/material";
import { blueGrey,grey } from '@mui/material/colors';
import { alpha, styled } from '@mui/material/styles';


export  const GrayButton = styled(Button)(({ theme }) => ({
  backgroundColor: blueGrey[400],
  color: theme.palette.getContrastText(blueGrey[400]),
  '&:hover': {
    backgroundColor: blueGrey[600],
    color: theme.palette.getContrastText(blueGrey[600]),
  },
}));

export const GraySwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: blueGrey[400],
      '&:hover': {
        backgroundColor: alpha(blueGrey[400], theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: blueGrey[400],
    },
  }));