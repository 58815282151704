import React from "react";
import { VistasCamposDinamicos } from "../VistasCamposDinamicos/VistasCamposDinamicos";

export function VisualizacionGeneralDocumentosForm(props) {
  const {nombre, visualizacion } = props;

  return (
    <div class="container">
      <h1 class="text-center">{nombre}</h1>
      <div class="row">
        {visualizacion ? (
          <div class="col-sm-4 text-center">
            <img
              src={visualizacion?.foto}
              alt={visualizacion?.nombres + " " + visualizacion?.apellidos}
              class="img-fluid"
            />
          </div>
        ) : (
          ""
        )}
        <div class="col-sm-8">
          {visualizacion?.nombres ? (
            <p>
              <b>Nombres:</b> {visualizacion?.nombres}
            </p>
          ) : (
            ""
          )}
          {visualizacion?.nombre ? (
            <p>
              <b>Nombres:</b> {visualizacion?.nombre}
            </p>
          ) : (
            ""
          )}
          {visualizacion ? (
            <p>
              <b>Apellidos:</b> {visualizacion?.apellidos}
            </p>
          ) : (
            ""
          )}
          {visualizacion?.identificacion_pers ? (
            <p>
              <b>Identificación:</b> {visualizacion?.identificacion_pers}
            </p>
          ) : (
            ""
          )}
          {visualizacion?.n_documento ? (
            <p>
              <b>Identificación:</b> {visualizacion?.n_documento}
            </p>
          ) : (
            ""
          )}
          {visualizacion?.n_licencia ? (
            <p>
              <b>Numero de licencia:</b> {visualizacion?.n_licencia}
            </p>
          ) : (
            ""
          )}

          {visualizacion?.fecha_vencimineto_licencia ? (
            <p>
              <b>Fecha de vencimiento:</b> {visualizacion?.fecha_vencimineto_licencia}
            </p>
          ) : (
            ""
          )}

          {visualizacion ? (
            <p>
              <b>Email:</b> {visualizacion?.email}
            </p>
          ) : (
            ""
          )}
         


          {visualizacion ? (
            <p>
              <b>Fecha de nacimiento:</b> {visualizacion?.fecha_nacimiento}
            </p>
          ) : (
            ""
          )}
          {visualizacion ? (
            <p>
              <b>Teléfono:</b> {visualizacion?.telefono}
            </p>
          ) : (
            ""
          )}
          {visualizacion ? (
            <p>
              <b>Celular:</b> {visualizacion?.celular}
            </p>
          ) : (
            ""
          )}

    {visualizacion?.seguro ? (
            <p>
              <b>Seguro:</b> {visualizacion?.seguro}
            </p>
          ) : (
            ""
          )}

          
        </div>
        {visualizacion ? (
            <VistasCamposDinamicos visualizacion={visualizacion} />
          ) : (
            ""
          )}
      </div>
    </div>
  );
}
