import {
    Switch
} from "@mui/material";
import { blueGrey } from '@mui/material/colors';
import { alpha, styled } from '@mui/material/styles';

export const GraySwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: blueGrey[400],
      '&:hover': {
        backgroundColor: alpha(blueGrey[400], theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: blueGrey[400],
    },
  }));