

import { BASE_API } from "../../utils/constants";
import { makeRequest } from "../Request";
const BASE_URL = `${BASE_API}/`;

// Función para obtener los datos 
export async function getVehiculosApi(token,schema,filter,vehiculo) {
  let url = `${BASE_URL}${schema}/vehiculos/vehiculosA`;

  if (filter) {
    url += `?filter=${filter}`;
  }
  if (vehiculo) {
    url += `&vehicle=${vehiculo}`;
  }
  return makeRequest(url, "GET", token);
}

// Función para agregar un nuevo dato
export async function addVehiculosApi(data, token, schema) {
  const url = `${BASE_URL}${schema}/vehiculos/vehiculosA/`;
  return makeRequest(url, "POST", token, data);
}

// Función para actualizar los datos 
export async function updateVehiculosApi(id, data, token, schema) {
  const url = `${BASE_URL}${schema}/vehiculos/vehiculosA/${id}/`;
  return makeRequest(url, "PUT", token, data);
}

// Función para eliminar 
export async function deleteVehiculosApi(id, token, schema) {
  const url = `${BASE_URL}${schema}/vehiculos/vehiculosA/${id}/`;
  return makeRequest(url, "DELETE", token);
}

// obtiene los vehículos de tipo carro tanque y tracto camión
export async function getVehTractoTanqueAPI(token, schema) {
  const url = `${BASE_URL}${schema}/vehiculos/getvehiclebytype`
  return makeRequest(url, "GET", token)
}

export async function getVehCarroTanqueAPI(token, schema) {
  const url = `${BASE_URL}${schema}/vehiculos/vehiculosA?tipo_veh=carro_tanque`
  return makeRequest(url, "GET", token)
}

// Función para obtener los datos 
export async function getVehVerApi(token, schema) {
  const url = `${BASE_URL}${schema}/vehiculos/vehiclechecklist`;
  return makeRequest(url, "GET", token);
}

export async function getVehVerByIdApi(id_vehiculo, token, schema) {
  const url = `${BASE_URL}${schema}/vehiculos/vehiclechecklist?id_vehiculo=` + id_vehiculo;
  return makeRequest(url, "GET", token);
}

// Función para agregar un nuevo dato
export async function addVehVerApi(data, token, schema) {
  const url = `${BASE_URL}${schema}/vehiculos/vehiclechecklist/`;
  return makeRequest(url, "POST", token, data);
}

// Función para actualizar los datos 
export async function updateVehVerApi(id, data, token, schema) {
  const url = `${BASE_URL}${schema}/vehiculos/vehiclechecklist/${id}/`;
  return makeRequest(url, "PUT", token, data);
}

// Función para eliminar 
export async function deleteVehVerApi(id, token, schema) {
  const url = `${BASE_URL}${schema}/vehiculos/vehiclechecklist/${id}/`;
  return makeRequest(url, "DELETE", token);
}