import React, { createElement } from "react";
import { useAuth } from "../../../hooks";
import { MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  BookOutlined,
  PoweroffOutlined,
  
  EditOutlined
} from "@ant-design/icons";


import { Layout, Menu, theme } from "antd";
const { Header, Content } = Layout;
const { Paragraph } = Typography;

export function TopMenu(props) {
  const { auth, logout } = useAuth();
  const { collapsed, setCollapsed, colorBgContainer } = props;
  let navigate = useNavigate();

  const renderName = () => {
    if (auth.me?.nombres && auth.me?.apellidos) {
      return `${auth.me.nombres} `;
    }
    return auth.me?.email;
  };

  const renderFotoOw = () => {
    if (auth.me?.owner2?.foto) {
      return (
        <img
          src={auth.me.owner2.foto}
          width={30}
          className="img-circle elevation-2"
          alt="User Image"
        />
      );
    }
    return <UserOutlined />;
  };

  const renderFoto = () => {
    if (auth.me?.foto) {
      return (
        <img
          src={auth.me?.foto}
          width={30}
          className="img-circle elevation-2"
          alt="User Image"
        />
      );
    }
    return <UserOutlined />;
  };

  const items = [
    {
      key: "1",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://syz-api-manual.s3-website.us-east-2.amazonaws.com/"
        >
          Manual de la aplicación
        </a>
      ),
      icon: <BookOutlined />
    },
    {
      key: "2",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => navigate("/usuarios/UpdateMe")}
        >
          Editar perfil
        </a>
      ),
      icon: <EditOutlined />
    },
    {
      key: "3",
      label: (
        <a target="_blank" rel="noopener noreferrer" onClick={logout}>
          Cerrar sesión
        </a>
      ),
      icon: <PoweroffOutlined />
    },
  ];

  const itemsM = [
   
    {
      key: "more",
      label: (
        
        <Dropdown
          key="more"
          menu={{
            items,
          }}
          placement="bottomRight" // Modificado para mostrar el dropdown a la izquierda
        >
          <Button type="text" icon={renderFoto()} />
        </Dropdown>
        
      ),
    },
  ];

  return (
    <>
      <Header
        className="site-page-header"
        style={{
          padding: 0,
          background: colorBgContainer,
        }}
      >
        <Menu
          theme="light"
          mode="horizontal"
          defaultSelectedKeys={["more"]}
          items={itemsM}
          style={{ float: "right" }}
        />

        {createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
          className: "trigger",
          onClick: () => setCollapsed(!collapsed),
        })}
      </Header>
    </>
  );
}
