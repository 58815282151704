import React, { useEffect } from "react";
import { HeaderPage } from "../../../Components/Client";
import { DataGridBasic, DataGridNoOpt, ModalBasic } from "../../../Components/Comons";
import { useTurnos } from "../../../hooks";
import { filterValue } from "./TurnosFormat";
import { Funciones } from "./Funciones";
import CircularProgress from '@mui/material/CircularProgress';

export function TurnosAdmin() {
  const { loading, Turnos, getTurnos, limpiarTurnos } = useTurnos();
  const {
    refetch,
    contentModal,
    titleModal,
    showModal,
    handleAdd,
    columns,
    openCloseModal,
  } = Funciones();

  useEffect(() => {
    getTurnos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const MemoizedModalBasic = React.memo(ModalBasic);

  return (
    <>
      <HeaderPage
        title="Turnos"
        btnTitle="Nuevo turno"
        icono="plus square icon"
        btnClick={handleAdd}
      />
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <DataGridNoOpt
          data={Turnos.results}
          title="Turnos"
          id="idIncremental"
          filterValue={filterValue}
          columns={columns}
        //onDeleteData={limpiarTurnos}
        />
      )}

      {showModal && (
        <MemoizedModalBasic
          show={showModal}
          onClose={openCloseModal}
          title={titleModal}
          children={contentModal}
        />
      )}
    </>
  );
}
