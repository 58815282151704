import { useState } from "react";
import { getBahiasApi, addBahiasApi, updateBahiasApi, deleteBahiasApi } from "../../api/A_Plantas/bahias";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "..";

export function useBahias() {
  const { auth, logout } = useAuth();
  const { unauthorized, SessionExpired, unauthorizedModal, errores } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    Bahias: null,
    BahiasDB: null,
  });

  const { loading, error, Bahias, BahiasDB } = state;

  const getBahiasDB = async () => {
    try {
      const [result, response] = await getBahiasApi(auth.token, auth.me.tenant);
      setState((prevState) => ({ ...prevState, BahiasDB: result.results }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  const getBahias = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getBahiasApi(auth.token, auth.me.tenant);
      if (response.status === 401) {
        SessionExpired();
        logout();
      } else if (response.status === 403) {
        unauthorized();
      }
      setState((prevState) => ({ ...prevState, loading: false, Bahias: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addBahias = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addBahiasApi(data, auth.token, auth.me.tenant);
      if (response.status === 400) {
        if (result?.nit_Bahias) {
          throw new Error("Ya existe un Bahias registrado con este NIT");
        } else {
          errores();
        }
      } else if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateBahias = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateBahiasApi(id, data, auth.token, auth.me.tenant);
      if (response.status === 400) {
        if (result?.nit_Bahias) {
          throw new Error("Ya existe un Bahias registrado con este NIT");
        } else {
          errores();
        }
      } else if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteBahias = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteBahiasApi(id, auth.token, auth.me.tenant);
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    BahiasDB,
    Bahias,
    getBahiasDB,
    getBahias,
    addBahias,
    updateBahias,
    deleteBahias,
  };
}
