import React, { useEffect, useState } from "react";

import ArrowDownOnSquareIcon from "@heroicons/react/24/solid/ArrowDownOnSquareIcon";

// import {
//   Button,
//   Collapse,
//   Container,
//   Divider,
//   Unstable_Grid2 as Grid,
//   Pagination,
//   Stack,
//   SvgIcon
// } from "@mui/material";

import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse"
import Container from "@mui/material/Container"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import Pagination from "@mui/material/Pagination"
import Stack from "@mui/material/Stack"
import SvgIcon from "@mui/material/SvgIcon"
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField"

import Card from "@mui/material/Card"
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import CardHeader from "@mui/material/CardHeader";
import Menu from "@mui/material/Menu";

import PropTypes from "prop-types";
import Toptech from "../../../assets/controladores/Toptech.png";
import kunbus from "../../../assets/controladores/kunbus.jpg";
import tcs from "../../../assets/controladores/tcs-3000.png";

// import { MenuItem, Select, TextField } from '@mui/material';

import { Cancel, CheckCircle } from '@material-ui/icons';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useBahias, usePlantas } from "../../../hooks";
import { ControladorCard } from "./controlador-card";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const tipoOptions = [
  { value: 'reg_comb', label: 'Registradora' },
  { value: 'disp_comb', label: 'Dispensadora' },
  { value: 'comp_flujo', label: 'Comp. flujo' },
  { value: 'kunbus', label: 'Kunbus' }
]

export function CardsControladores(props) {
  const {
    data,
    onDeleteData,
    updateData,
    assignTo,
    tcsProdUpdt,
    savePlates,
    setMultLdProds,
    handleGetProducto,
    addEditHazards,
    activateCalMode,
  } = props;

  const columnWidth = 300;

  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const itemsPerPage = 12; // mostrar 2 tanques por página

  const { BahiasDB, getBahiasDB } = useBahias()
  const { PlantasDB, getPlantasDB } = usePlantas()

  const [inputModelo, setInputModelo] = useState("")
  const [inputIp, setInputIp] = useState("")
  const [inputMarca, setInputMarca] = useState("")
  const [inputSerial, setInputSerial] = useState("")
  const [selectedPlanta, setSelectedPlanta] = useState("")
  const [selectedBahia, setSelectedBahia] = useState("")
  const [selectedTipo, setSelectedTipo] = useState("")
  const [inputTag, setInputTag] = useState("")
  const [filteredControladores, setFilteredControladores] = useState("")
  const [filterBtnClicked, setFilterBtnClicked] = useState(false)

  const [showDropdown, setShowDropdown] = useState(false)

  const displayedData = data.filter((controladores) =>
    controladores.modelo.toLowerCase().includes(searchText.toLowerCase())
  );

  // Verificar si displayedData es null o undefined antes de acceder a su propiedad 'length'
  const totalItems = displayedData ? displayedData.length : 0;

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const paginatedData = displayedData.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const [anchorEls, setAnchorEls] = useState({}); // constante para controlar el estado del menú con las opciones de cada tarjeta

  const [expanded, setExpanded] = useState({}); // constante para controlar si se expande o se contrae la tarjeta

  const maxTitleLength = 18; // define el número máximo de los caracteres para ajustar el tamaño del título de cada tarjeta

  useEffect(() => {
    getBahiasDB()
    getPlantasDB()
  }, [])

  // función para controlar si se expande o se cierra la tarjeta
  // const handleExpandClick = (index) => {
  //   setExpanded(prevExpanded => ({
  //     ...prevExpanded,
  //     [index]: !prevExpanded[index], // Toggle the expansion state of the clicked card
  //   }));
  // };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSearchChange = (searchValue) => {
    setSearchText(searchValue);
    setPage(1); // Resetear la página actual cuando se realiza una búsqueda
  };

  const handleShowFilters = () => {
    setShowDropdown(!showDropdown)
  }

  // filtros
  const handleFilterClick = () => {
    setFilterBtnClicked(true)

    const filteredCtrl = data.filter((item) => {
      if (inputModelo && !item.modelo.toLowerCase().includes(inputModelo.toLowerCase())) {
        return false;
      }

      if (inputMarca && !item.marca.toLowerCase().includes(inputMarca.toLowerCase())) {
        return false;
      }

      if (inputIp && item.ip !== inputIp) {
        return false;
      }

      if (inputSerial && item.serial_equipo !== inputSerial) {
        return false;
      }

      if (inputTag && !item.ubicacion_id.tag.toLowerCase().includes(inputTag.toLowerCase())) {
        return false;
      }

      if (selectedTipo && item.tipo !== selectedTipo) {
        return false;
      }

      if (selectedBahia && item.ubicacion_id.bahias_id !== selectedBahia) {
        return false;
      }

      if (selectedPlanta && item.ubicacion_id.planta_id !== selectedPlanta) {
        return false;
      }

      return true;
    });

    setFilteredControladores(filteredCtrl)
  }

  // limpia los campos de los filtros
  const clearFilters = () => {
    setFilterBtnClicked(false)
    setInputMarca("")
    setInputModelo("")
    setInputIp("")
    setInputSerial("")
    setInputTag("")
    setSelectedBahia("")
    setSelectedPlanta("")
    setSelectedTipo("")
  }

  // decide según el tipo de controlador la imagen
  const getAvatarImg = (tipo) => {
    let avatarImg;
    if (tipo === "comp_flujo") {
      avatarImg = Toptech;
    } else if (tipo === "reg_comb") {
      avatarImg = tcs;
    } else if (tipo === "kunbus") {
      avatarImg = kunbus;
    }
    return avatarImg;
  };

  // trunca la cadena de texto si excende el número definido como máximo (maxTitleLength)
  const truncatedTitle = (controlador) => {
    let title = controlador?.modelo.length > maxTitleLength
      ? controlador?.modelo.slice(0, maxTitleLength) + '...'
      : controlador?.modelo;

    return title
  }

  // abre el menú de opciones de la tarjeta
  const handleMenuOpen = (event, index) => {
    setAnchorEls({ ...anchorEls, [index]: event.currentTarget });
  };

  const handleMenuClose = (index) => {
    const updatedAnchorEls = { ...anchorEls };
    delete updatedAnchorEls[index]; // Remove anchor element for the specific index
    setAnchorEls(updatedAnchorEls);
  };

  const isMenuVisible = (controlador) => {
    return controlador?.tipo === 'reg_comb' || controlador?.tipo === 'comp_flujo';
  }

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 2,
        }}
      >
        <Container maxWidth="xl">
          <div>
            <Divider textAlign="right">
              <strong onClick={handleShowFilters} style={{ cursor: 'pointer' }}>
                Filtrar
              </strong>
            </Divider>
            <br />

            <Collapse in={showDropdown}>
              <Grid container spacing={2}>
                {/* primera columna */}
                <Grid item xs={12} sm={6} md={4} lg={3} sx={{ width: columnWidth }}>
                  <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '16px' }}>
                    <div style={{ marginBottom: '8px' }}>Modelo</div>
                    <TextField value={inputModelo} onChange={(e) => setInputModelo(e.target.value)}></TextField>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '16px' }}>
                    <div style={{ marginBottom: '8px' }}>Marca</div>
                    <TextField value={inputMarca} onChange={(e) => setInputMarca(e.target.value)}></TextField>
                  </div>
                </Grid>

                {/* segunda columna */}
                <Grid item xs={12} sm={6} md={4} lg={3} sx={{ width: columnWidth }}>
                  <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '16px' }}>
                    <div style={{ marginBottom: '8px' }}>Dirección IP</div>
                    <TextField value={inputIp} onChange={(e) => setInputIp(e.target.value)}></TextField>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '16px' }}>
                    <div style={{ marginBottom: '8px' }}>Número serial</div>
                    <TextField value={inputSerial} onChange={(e) => setInputSerial(e.target.value)}></TextField>
                  </div>
                </Grid>

                {/* tercera columna */}
                <Grid item xs={12} sm={6} md={4} lg={3} sx={{ width: columnWidth }}>
                  <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '16px' }}>
                    <div style={{ marginBottom: '8px' }}>Número de tag</div>
                    <TextField value={inputTag}
                      onChange={(e) => setInputTag(e.target.value)} >
                    </TextField>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '16px' }}>
                    <div style={{ marginBottom: '8px' }}>Tipo de computadora</div>
                    <Select style={{ height: "2.5rem", }} value={selectedTipo}
                      onChange={(e) => setSelectedTipo(e.target.value)}>
                      {tipoOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </Grid>

                {/* cuarta columna */}
                <Grid item xs={12} sm={6} md={4} lg={3} sx={{ width: columnWidth }}>
                  <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '16px' }}>
                    <div style={{ marginBottom: '8px' }}>Bahía</div>
                    <Select style={{ height: "2.5rem", }} value={selectedBahia}
                      onChange={(e) => setSelectedBahia(e.target.value)}>
                      {BahiasDB?.map((option) => (
                        <MenuItem key={option.id_bahias} value={option.id_bahias}>
                          {option.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '16px' }}>
                    <div style={{ marginBottom: '8px' }}>Planta</div>
                    <Select style={{ height: "2.5rem", }} value={selectedPlanta}
                      onChange={(e) => setSelectedPlanta(e.target.value)}>
                      {PlantasDB?.map((option) => (
                        <MenuItem key={option.id_myemp} value={option.id_myemp}>
                          {option.razon_social}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '16px' }}>
                    <Button sx={{
                      backgroundColor: "#444444",
                      color: "#FFFFFF",
                      "&:hover": {
                        backgroundColor: "#BEBEBE", // Color gris cuando se hace hover
                      },
                    }} onClick={handleFilterClick}>Filtrar</Button>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '16px' }}>
                    <Button variant="outlined" sx={{
                      borderColor: "#D0302B",
                      color: "#D0302B",
                      "&:hover": {
                        borderColor: "#D0302B",
                        backgroundColor: "rgba(250, 146, 142, 0.1)",
                      },
                    }} onClick={clearFilters}>Limpiar filtros</Button>
                  </div>
                </Grid>
              </Grid>
            </Collapse>

            <Grid item xs={6}>
              <Divider />
            </Grid>
          </div>

          <Stack spacing={2}>
            <Stack direction="row" justifyContent="space-between" spacing={4} alignItems="center" >
              <Stack spacing={1}>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Button color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ArrowDownOnSquareIcon />
                      </SvgIcon>
                    }
                  >
                    Descargar
                  </Button>
                </Stack>
              </Stack>
            </Stack>

            <Grid container spacing={2}>
              {((filteredControladores.length > 0 || filterBtnClicked) ? filteredControladores : paginatedData).map((controlador, index) => (
                <Grid item xs={12} md={6} lg={4} key={controlador.id_controlador}>
                  <Card sx={{ height: "100%" }}>
                    <CardHeader
                      // onClick={() => handleExpandClick(index)}

                      sx={{
                        backgroundColor:
                          controlador?.estado === false
                            ? "rgba(224, 224, 224, 0.7)"
                            : controlador?.estado === true
                              ? "rgba(0, 0, 0, 0.7)"
                              : "inherit",
                        borderRadius: "8px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                        color: controlador?.estado === true ? "#ffffff" : "inherit",
                        "& .MuiCardHeader-title": {
                          fontSize: "1.5rem",
                          display: "flex",
                          alignItems: "center",
                        },
                        "& .MuiCardHeader-action": {
                          "& svg": {
                            color: controlador?.estado === true ? "#ffffff" : "inherit",
                          },
                        },
                      }}

                      action={isMenuVisible(controlador) && [
                        <IconButton aria-label="settings" onClick={(event) => handleMenuOpen(event, index)} key="settingsButton">
                          <MoreVertIcon />
                        </IconButton>,
                        <Menu
                          anchorEl={anchorEls[index] || null}
                          open={Boolean(anchorEls[index])}
                          onClose={() => handleMenuClose(index)}
                        >
                          {controlador?.tipo === "reg_comb" ? (
                            [
                              <MenuItem onClick={() => {
                                tcsProdUpdt(controlador.id_controlador, controlador)
                                handleMenuClose(index)
                              }}>
                                {/* <PageviewIcon />  */}
                                Obtener producto
                              </MenuItem>,
                              <MenuItem onClick={() => {
                                assignTo(controlador)
                                handleMenuClose(index);
                              }}>
                                {/* <AssignmentReturnedIcon /> */}
                                Asignar
                              </MenuItem>,
                              <MenuItem onClick={() => {
                                savePlates(controlador)
                                handleMenuClose(index);
                              }}>
                                {/* <MoveDownIcon />  */}
                                Ingresar placas a TCS3000
                              </MenuItem>,
                            ]
                          ) : controlador?.tipo === "comp_flujo" ? (
                            [
                              <MenuItem onClick={() => {
                                setMultLdProds(controlador)
                                handleMenuClose(index);
                              }}>
                                {/* <OilBarrelIcon />  */}
                                Añadir productos
                              </MenuItem>,
                              <MenuItem onClick={() => {
                                handleGetProducto(controlador)
                                handleMenuClose(index);
                              }}>
                                {/* <GetAppIcon />  */}
                                Obtener producto
                              </MenuItem>,
                              <MenuItem onClick={() => {
                                addEditHazards(controlador?.id_hazard)
                                handleMenuClose(index);
                              }}>
                                {/* <ReportProblemIcon />  */}
                                Revisar indicaciones de peligro
                              </MenuItem>,
                              <MenuItem onClick={() => {
                                activateCalMode(controlador)
                                handleMenuClose(index);
                              }}>
                                {/* <RemoveCircleOutlineIcon />  */}
                                Establecer computador para calibración
                              </MenuItem>,
                            ]
                          ) : null}
                        </Menu>
                      ]}

                      avatar={
                        <div style={{ position: 'relative', display: 'inline-block' }}>
                          <Avatar src={getAvatarImg(controlador.tipo)} aria-label="recipe" />
                          <div
                            style={{
                              position: 'absolute',
                              bottom: '0',
                              left: '50%',
                              transform: 'translateX(-50%)',
                              backgroundColor: 'white',
                              borderRadius: '50%',
                              lineHeight: '0.7rem',
                            }}
                            title={controlador?.estado ? "Activo" : "Inactivo"}
                          >
                            {controlador?.estado ? (
                              <CheckCircle style={{ color: 'green', fontSize: '1rem' }} />
                            ) : (
                              <Cancel style={{ color: 'red', fontSize: '1rem' }} />
                            )}
                          </div>
                        </div>
                      }

                      title={
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={12}>
                            <Typography variant="h6" component="div">
                              {truncatedTitle(controlador)}
                            </Typography>
                          </Grid>
                        </Grid>
                      }
                    />

                    <CardContent >
                      <Grid container spacing={1} sx={{ padding: "5px" }}>
                        <Grid item xs={12} sm={12}>
                          <Stack direction="column" spacing={2}>
                            <Typography variant="body2" color="text.secondary">
                              Marca: {controlador?.marca}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              IP:<strong>{controlador?.ip}</strong>
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Serial del equipo: {controlador?.serial_equipo}
                            </Typography>
                            {/* <Typography variant="body2" color="text.secondary">
                              Serial syz:{controlador?.serial_syz}
                            </Typography> */}
                          </Stack>
                        </Grid>

                        <Grid item xs={6} sm={6}>
                          <Tooltip title="Actualizar este controlador" arrow>
                            <Button
                              fullWidth
                              sx={{
                                backgroundColor: "#444444",
                                color: "#FFFFFF",// anchura autoajustable
                                display: "flex", // hacer el botón como contendor con la propiedad flex
                                justifyContent: "center", // centrar el contenido horizontalmente
                                alignItems: "center", // Centrar el contenido verticalmente
                                "&:hover": {
                                  backgroundColor: "#BEBEBE", // Color gris cuando se hace hover
                                },
                              }}
                              onClick={() => updateData(controlador)}
                              startIcon={<UpdateIcon />}
                            >
                            </Button>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <Tooltip title="Eliminar este controlador" arrow>
                            <Button
                              fullWidth
                              sx={{
                                backgroundColor: "#D0302B",
                                color: "#FFFFFF", // anchura autoajustable
                                display: "flex", // hacer el botón como contendor con la propiedad flex
                                justifyContent: "center", // centrar el contenido horizontalmente
                                alignItems: "center", // Centrar el contenido verticalmente
                                "&:hover": {
                                  backgroundColor: "#BEBEBE", // Color gris cuando se hace hover
                                },
                              }}
                              onClick={() => onDeleteData(controlador)}
                              startIcon={<DeleteForeverIcon />}
                            >
                            </Button>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </CardContent>

                    {/* <Collapse in={expanded[index]} timeout="auto" unmountOnExit>

                    </Collapse> */}
                  </Card>

                  {/* <ControladorCard
                    controlador={controladores}
                    actualizar={updateData}
                    eliminar={onDeleteData}
                    getProducto={handleGetProducto}
                    tcsProdUpdt={tcsProdUpdt}
                    assignTo={assignTo}
                    savePlates={savePlates}
                    setMultLdProds={setMultLdProds}
                    addEditHazards={addEditHazards}
                    activateCalMode={activateCalMode}
                  /> */}
                </Grid>
              ))}

            </Grid>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Pagination
                count={totalPages}
                size="small"
                page={page}
                onChange={handlePageChange}
              />
            </Box>
          </Stack>
        </Container>
      </Box>
    </>
  );
}
