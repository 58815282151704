import { BASE_API } from "../../utils/constants";
import { makeRequest } from "../Request";
const BASE_URL = `${BASE_API}/`;
  
  // Función para obtener los datos 
  export async function getControlDocConductorApi(token, schema) {
    let url = `${BASE_URL}${schema}/conductores/documentconductor`;
    return makeRequest(url, "GET", token);
  }
  
  // Función para agregar un nuevo dato
  export async function addControlDocConductorApi(data, token,schema) {
    const url = `${BASE_URL}${schema}/conductores/documentconductor/`;
    return makeRequest(url, "POST", token,  data);
  }
  
  // Función para actualizar los datos 
  export async function updateControlDocConductorApi(id, data, token,schema) {
    const url = `${BASE_URL}${schema}/conductores/documentconductor/${id}/`;
    return makeRequest(url, "PATCH", token, data);
  }
  
  // Función para eliminar 
  export async function deleteControlDocConductorApi(id, token,schema) {
    const url = `${BASE_URL}${schema}/conductores/documentconductor/${id}/`;
    return makeRequest(url, "DELETE", token);
  }