import React, { useRef, useEffect } from "react";
import ReactToPrint from "react-to-print";
import { useImpresionesDescargadero } from "../../../../hooks";
import "../../../../Scss/impresiones.scss";

const ComponentToPrint = React.forwardRef((props, ref) => {
  const { data } = props;

  /*
  Componente

  -componente de visualizacion del modal de visualizacion para tiquete de transacciones o una orden
  
 
  -se valida que no llegue vacio para que no haya errores
  -se obtiene los datos  mediante props desde su PageAdmin
   */



  const estacion = "Estación ABC";

  const direc_empresa = "Calle Falsa 123";

  const contacto_cliente = "Juan Pérez";

 

  const fecha_impresion = "2022-01-01 10:00:00";

  return (
    <div ref={ref}>
      <p className="contenedor">
      <table id="header-table">
        <tr>
          <td class="header-title">
            <div>
              <h3 style={{ color: "black" }}>TIQUETE DE TRANSACCIÓN DESCARGADERO</h3>
            </div>
          </td>
          <td class="header-logo">
            <img
              src="https://seeklogo.com/images/E/Ecopetrol-logo-575EE75C00-seeklogo.com.png"
              class="company-logo"
              alt="Company logo"
            />
          </td>
        </tr>
      </table>
      </p>
      <div className="contenedor">
      <table id="cont_tiket" class="table">
        <thead>
          <tr class="titulo_tiket">
            <th class="sub_titulo_tiket" colspan="4">
              DATOS DEL PROVEEDOR
            </th>
            <th class="sub_titulo_tiket" colspan="4">
              DATOS DEL CLIENTE
            </th>
            <th class="sub_titulo_tiket" colspan="4">
              DATOS DE LA TRANSPORTADORA
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th colspan="2">Razón Social</th>
            <td colspan="2">{data?.empresa}</td>
            <th colspan="2">Razón Social</th>
            <td colspan="2">{data?.empresa}</td>
            <th colspan="2">Razón Social</th>
            <td colspan="2">{data?.transportadora}</td>
          </tr>
          <tr>
            <th colspan="2">Nit</th>
            <td colspan="2">{data?.nit_empresa}</td>
            <th colspan="2">Nit</th>
            <td colspan="2">{data?.nit_empresa}</td>
            <th colspan="2">Nit</th>
            <td colspan="2">{data?.nit_transportadora}</td>
          </tr>
          <tr>
            <th colspan="2">Planta</th>
            <td colspan="2">{estacion}</td>
            <th colspan="2">Planta</th>
            <td colspan="2">{estacion}</td>
            <th colspan="2">Cabezote</th>
            <td colspan="2">{data?.cabezote}</td>
          </tr>
          <tr>
            <th colspan="2">Dirección</th>
            <td colspan="2">{direc_empresa}</td>
            <th colspan="2">Dirección</th>
            <td colspan="2">{direc_empresa}</td>
            <th colspan="2">Remolque</th>
            <td colspan="2">{data?.remolque}</td>
          </tr>
          <tr>
            <th colspan="2">Contacto</th>
            <td colspan="2">{contacto_cliente}</td>
            <th colspan="2">Contacto</th>
            <td colspan="2">{contacto_cliente}</td>
            <th colspan="2">Nombre Conductor</th>
            <td colspan="2">{data?.conductor_nombre}</td>
          </tr>
          <tr>
            <th colspan="2">Cargo</th>
            <td colspan="2">Logístico</td>
            <th colspan="2">Cargo:</th>
            <td colspan="2">Logistico:</td>
            <th colspan="2">Identificación Conductor:</th>
            <td colspan="2">{data?.conductor_documento}</td>
          </tr>
        </tbody>
        <thead>
          <tr class="titulo_tiket">
            <th class="titulo_tiket" colspan="4">
              DATOS DEL MEDIDOR
            </th>
            <th class="sub_titulo_tiket" colspan="4">
              DATOS DEL PRODUCTO
            </th>
            <th class="sub_titulo_tiket" colspan="4">
              DATOS DE LA TRANSACCIÓN
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th colspan="2">Brazo:</th>
            <td colspan="2">{data?.brazo}</td>
            <th colspan="2">Nombre:</th>
            <td colspan="2">{data?.nombre_producto}</td>
            <th colspan="2">Numero de Orden:</th>
            <td colspan="2">{data?.numero_orden}</td>
          </tr>
          <tr>
            <th colspan="2">Serial Medidor:</th>
            <td colspan="2">1478521</td>
            <th colspan="2">Codigo:</th>
            <td colspan="2">{data?.codigo_producto}</td>
            <th colspan="2">Numero Transacción:</th>
            <td colspan="2">{data?.numero_transaccion}</td>
          </tr>
          <tr>
            <th colspan="2">Tag Medidor:</th>
            <td colspan="2">F201</td>
            <th colspan="2">Gravedad API:</th>
            <td colspan="2">{data?.api}</td>
            <th colspan="2">Fecha Transacción:</th>
            <td colspan="2">{data?.fecha_transaccion}</td>
          </tr>
          <tr>
            <th colspan="2">K-factor:</th>
            <td colspan="2">5000</td>
            <th colspan="2">BSW (%):</th>
            <td colspan="2">{data?.bsw}</td>
            <th colspan="2">Inicio Cargue:</th>
            <td colspan="2">{data?.start_cargue}</td>
          </tr>
          <tr>
            <th colspan="2">Meter-Factor (MF):</th>
            <td colspan="2">{data?.meter_factor}</td>
            <th colspan="2">--------</th>
            <td colspan="2">--------</td>
            <th colspan="2">Fin Cargue:</th>
            <td colspan="2">{data?.end_cargue}</td>
          </tr>
        </tbody>
        <thead>
          <tr class="titulo_tiket">
            <th class="sub_titulo_tiket" colspan="4">
              DATOS DE LECTURAS
            </th>
            <th class="sub_titulo_tiket" colspan="8">
              DATOS CALCULADOS
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th colspan="2">Lectura Inicial<br/> (MRo):</th>
            <td colspan="2">{data?.t_inicial}</td>
            <th colspan="2">Volumen Indicado<br/> (IV=MRc - MRo):</th>
            <td colspan="2">{data?.v_indicado}</td>
            <th colspan="2">
              Factor de Corrección Combinado<br/> (CCF = CTL * CPL * MF ):
            </th>
            <td colspan="2">{data?.ccf}</td>
          </tr>
          <tr>
            <th colspan="2">Lectura Final (MRc):</th>
            <td colspan="2">{data?.t_final}</td>
            <th colspan="2">Volumen Bruto Observado <br/> (GOV = IV * MF):</th>
            <td colspan="2">{data?.gov}</td>
            <th colspan="2">Volumen Bruto Estándar (GSV = IV * CCF):</th>
            <td colspan="2">{data?.gsv}</td>
          </tr>
          <tr>
            <th colspan="2">Temperatura Promedio (F):</th>
            <td colspan="2">{data?.temperatura}</td>
            <th colspan="2">Factor de Corrección por Temperatura (CTL):</th>
            <td colspan="2">{data?.ctl}</td>
            <th colspan="2">
              Factor de Corrección por BSW <br/>(CSW = 1 - %BSW/100):
            </th>
            <td colspan="2">{data?.fcbsw}</td>
          </tr>
          <tr>
            <th colspan="2">Presión Promedio<br/> (PSI):</th>
            <td colspan="2">{data?.presion}</td>
            <th colspan="2">Fator de Corrección por Presión <br/>(CPL):</th>
            <td colspan="2">{data?.cpl}</td>
            <th colspan="2">Volumen Neto Estandar<br/> (NSV = GSV * CSW):</th>
            <td colspan="2">{data?.nsv}</td>
          </tr>
        </tbody>
      </table>
      </div>
      <div className="contenedor">
      <footer style={{ textAlign: "center" }}>
        <table id="cont_tiket">
          <tr rowspan="4">
            <th
              colspan="12"
              style={{ backgroundColor: "white", textAlign: "left" }}
            >
              OBSERVACIONES:
            </th>
          </tr>

          <tr rowspan="2">
            <th
              colspan="4"
              style={{ backgroundColor: "white", textAlign: "left" }}
            >
              Operador:
            </th>
            <th
              colspan="4"
              style={{ backgroundColor: "white", textAlign: "left" }}
            >
              Identificación:
            </th>
            <th
              colspan="4"
              style={{ backgroundColor: "white", textAlign: "left" }}
            >
              Firma:
            </th>
          </tr>
        </table>
        <table>
          <tr rowspan="2">
            <th
              colspan="4"
              style={{ backgroundColor: "white", textAlign: "left" }}
            >
              Documento generado por software SYZAPI®
            </th>
            <th
              colspan="4"
              style={{ backgroundColor: "white", textAlign: "left" }}
            >
              www.grupo-syz.com
            </th>
            <th
              colspan="4"
              style={{ backgroundColor: "white", textAlign: "right" }}
            >
              Generado el  {data?.fecha_impresion}
            </th>
          </tr>
        </table>
      </footer>
      </div>
    </div>
  );
});

export function ImpresionTD(props) {

  

  const componentRef = useRef(null);
  const { data } = props;
  const { getImpresionesDescargadero, ImpresionesDescargadero } = useImpresionesDescargadero();

  useEffect(() => {
    getImpresionesDescargadero(data);
  }, []);

  return (
    <>
      <ComponentToPrint ref={componentRef} data={ImpresionesDescargadero} />
      <ReactToPrint
        trigger={() => <button class="btn btn-danger">IMPRIMIR</button>}
        content={() => componentRef.current}
      />
    </>
  );
}
