import React, {useCallback, useEffect,useState} from 'react'
import { useControlDocOperador } from '../../../hooks';
import { TablaControl } from '../../Comons';


export  function ModuloControlOperadores() {
    const { ControlDocOperador, getControlDocOperador, addControlDocOperador, updateControlDocOperador,  deleteControlDocOperador } = useControlDocOperador();
    const [refetch, setRefetch] = useState(false);
    const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);
    useEffect(() => {
        getControlDocOperador();
      }, [refetch]);
    return (
    <>
   <TablaControl documentos={ControlDocOperador} 
   addDocumentos={addControlDocOperador} 
   updateDocumentos={updateControlDocOperador} 
   deleteDocumentos={ deleteControlDocOperador} 
   onRefetch={onRefetch}
   suggestions={suggestions}
   />
    </>
  )
}
const suggestions = [
  'Cedula de ciudadanía',
  'Licencia de conducción',
  'Carnet o certificación vigente del distribuidor',
  'Documentos de afiliación (EPS)',
  'Documentos de afiliación (ARP)'
];
