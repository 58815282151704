import { useState, useEffect } from "react";
import { LoginClient } from "../../../pages/Client";
import { TopMenu } from "./TopMenu";
import { Layout, Menu, theme } from "antd";
import { StyledSider, LogoContainer, Logo } from "./styles";
import { useNavigate } from "react-router-dom";
import LogoSyz from "../../../assets/logo_syzapi.png";
import { useAuth, useLicencia } from "../../../hooks";
import { Items } from "./Items";
import { ItemsFacturador } from "./ItemsFacturador";
import { ItemsClientes } from "./ItemsClientes";
const { Header, Content } = Layout;



export const LayoutComponent = ({ children }) => {
  const { auth } = useAuth();
  
  if (!auth) return <LoginClient />;

  if (auth.me?.is_facturador) {
    return <FacturadorSideBar>{children}</FacturadorSideBar>;
  } else if (auth.me?.is_superadmin) {
    return <SideBar>{children}</SideBar>;
  } else if (auth.me?.is_admin) {
    return <ClientesSideBar>{children}</ClientesSideBar>;
  } else {
    return <ClientesSideBar>{children}</ClientesSideBar>;
  }
};


export default LayoutComponent;

function SideBar({ children }) {
  let navigate = useNavigate();
  
  const { items } = Items();
  
  const [collapsed, setCollapsed] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const { auth } = useAuth();
  const { suscripcionActiva, getLicencia } = useLicencia();

  useEffect(() => {
    getLicencia();
  }, [suscripcionActiva]);

  if (!suscripcionActiva && auth.me) {
    return navigate("/aviso-licencia");
  }

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };
 

  return (
    <Layout style={{ height: "100%" }}>
      <StyledSider
        width={270}
        trigger={null}
        collapsible
        collapsed={collapsed}
        theme="light"
      >
        <LogoContainer>
          <Logo src={LogoSyz} alt="Descripción del logo" />
        </LogoContainer>
        <Menu
          theme="light"
          mode="inline"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          defaultOpenKeys={["/"]}
          style={{ border: "none" }}
          items={items}
        />
      </StyledSider>
      <Layout className="site-layout">
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <TopMenu
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            colorBgContainer={colorBgContainer}
          />
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: "100vh",
            background: colorBgContainer,
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
}


function FacturadorSideBar({ children }) {
  let navigate = useNavigate();
  const { items } = ItemsFacturador();
  const [collapsed, setCollapsed] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const { auth } = useAuth();
  const { suscripcionActiva, getLicencia } = useLicencia();

  useEffect(() => {
    getLicencia();
  }, [suscripcionActiva]);

  if (!suscripcionActiva && auth.me) {
    return navigate("/aviso-licencia");
  }

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };
 
  
  return (
    <Layout style={{ height: "100%" }}>
      <StyledSider
        trigger={null}
        collapsible
        collapsed={collapsed}
        theme="light"
      >
        <LogoContainer>
          <Logo src={LogoSyz} alt="Descripción del logo" />
        </LogoContainer>
        <Menu
          theme="light"
          mode="inline"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          defaultOpenKeys={["/"]}
          style={{ border: "none" }}
          items={items}
        />
      </StyledSider>
      <Layout className="site-layout">
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <TopMenu
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            colorBgContainer={colorBgContainer}
          />
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: "100vh",
            background: colorBgContainer,
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
}


function ClientesSideBar({ children }) {
  let navigate = useNavigate();
  const { items } = ItemsClientes();
  const [collapsed, setCollapsed] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const { auth } = useAuth();
  const { suscripcionActiva, getLicencia } = useLicencia();

  useEffect(() => {
    getLicencia();
  }, [suscripcionActiva]);

  if (!suscripcionActiva && auth.me) {
    return navigate("/aviso-licencia");
  }

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };
 
  
  return (
    <Layout style={{ height: "100%" }}>
      <StyledSider
        trigger={null}
        collapsible
        collapsed={collapsed}
        theme="light"
      >
        <LogoContainer>
          <Logo src={LogoSyz} alt="Descripción del logo" />
        </LogoContainer>
        <Menu
          theme="light"
          mode="inline"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          defaultOpenKeys={["/"]}
          style={{ border: "none" }}
          items={items}
        />
      </StyledSider>
      <Layout className="site-layout">
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <TopMenu
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            colorBgContainer={colorBgContainer}
          />
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: "100vh",
            background: colorBgContainer,
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
}