import React from "react";
import { useNavigate } from "react-router-dom";
import Ordenes from "../../../Icons/black/Procesos/8.-ordenes-negro.png";
import { useAuth } from "../../../hooks";


export function ItemsFacturador() {
  let navigate = useNavigate();
  const { auth } = useAuth();

  const items = [
   
    (
      auth.me?.owner2?.modulos_Activos?.modulo_descargadero ||
      auth.me?.owner2?.modulos_Activos?.modulo_cargadero
    ) && {
      key: "ordenes",
      icon: <img className="pequeña" src={Ordenes} />,
      label: "Ordenes",
      children: [
        auth.me?.owner2?.modulos_Activos?.modulo_cargadero && {
          key: "facturaciones",
          icon: null,
          label: "facturaciones",
          onClick: () => navigate("/facturaciones"),
        },

      ].filter(Boolean),
    },
    
    
  ];
  return {
    items
  };
}
