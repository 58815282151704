import React from "react";

export const TankSmall = () => {
  return (
    <svg
      id="Capa_1"
      data-name="Capa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1867.81 2843.12"
      style={{
        zIndex: 4,
      }}
    >
      <path
        fill="#e5e3e5"
        d="m0,2755.45c2.47-.39,4.96-.52,7.46-.41,2.09,1.86,3.11,3.96,3.07,6.29-.05,2.45-.34,3.68-.88,3.68-3.48,2.49-6.68,2.06-9.64-.91v-8.66Z"
      />
      <path
        fill="#cecbcf"
        d="m0,2764.11c2.56-.26,5.12-.4,7.69-.42,1.99,1.93,2.94,4.06,2.84,6.39-.1,2.43-.41,3.64-.93,3.65-3.47,2.33-6.68,2.14-9.6-.97v-8.66Z"
      />
      <path
        fill="#afaab0"
        d="m0,2772.77c2.73-.17,5.47-.35,8.2-.51,1.78,2.07,2.57,4.28,2.38,6.62-.2,2.4-.54,3.6-1.02,3.61-3.49,2.41-6.66,1.92-9.56-1.05v-8.66Z"
      />
      <path
        fill="#b9b5bb"
        d="m0,2781.43c2.79.06,5.59.11,8.39.15,2.36,3.14,2.71,6.49,1.04,10.06-3.57,2.07-6.71,1.55-9.43-1.55v-8.66Z"
      />
      <path
        fill="#d5d3d6"
        d="m0,2790.09c2.67.13,5.33.3,8,.52,1.81,2.06,2.34,4.06,1.6,5.99-.73,1.89-1.42,2.84-2.07,2.84-2.52-.1-5.03-.33-7.52-.68v-8.66Z"
      />
      <path
        fill="#9c969e"
        d="m7.52,2799.43c.16-2.94.32-5.89.48-8.83.13-3.01.26-6.01.38-9.02-.06-3.1-.12-6.21-.18-9.32.27-2.88.1-5.74-.51-8.56-.08-2.89-.16-5.77-.24-8.65,12.44-18.72,31.02-25.25,52.31-26.41,48.94-8.74,98.54,1.08,147.56-5.21,17.8-7.56,36.65-6.04,55.25-6.05,119.2-.13,238.39.11,357.59-.23,18.11-.05,38.01,4.81,51.63-13.3,10.4-23.45,6.15-48.18,6.32-72.31.55-77.4-.14-154.8.41-232.2.15-21.24-2.17-43.42,13.33-61.56,16.37-12.38,35.63-10.01,54.13-10.03,263.66-.25,527.32-.27,790.98.01,18.44.02,37.94-2.69,53.09,12.33.66,126.53,1.32,253.07,1.98,379.6,41.78,11.94,84.48,2.8,126.67,5.5,49.42,3.16,99.25-5.64,148.44,5.02.22,33.99.45,67.99.67,101.98-11.37,9.45-24.91,5.18-37.41,5.19-581.42.23-1162.84.21-1744.26.12-11.41,0-23.08,1.87-34.13-2.88-21.89-2.78-31.97-20.53-44.49-35.19Z"
      />
      <path
        fill="#b7b2b8"
        d="m1590.33,2115.05c-.07,72.1-.15,144.19-.22,216.29-9.17,5.95-19.49,3.91-29.38,3.92-281.55.09-563.1.09-844.65.08-7.19,0-14.37.07-21.39-1.8-13.4-9.47-15.47-24.2-15.21-38.41,1.09-58.56-7.78-117.49,5.75-175.67,2.74-5.26,6.79-9.18,12.06-11.86,19.18-8.11,39.51-6.84,59.56-6.72,255.96,1.47,511.91,1.41,767.87.1,22.6-.12,46.55-3.62,65.61,14.08Z"
      />
      <path
        fill="#c9c6ca"
        d="m1037.97,11.17c11.52,2.36,23.04,4.72,34.57,7.08,5.87-4.04,11.65-3.79,17.34.38l-.25.97c5.77,4.51,11.59,4.86,17.48.24,91.77,18.17,185.1,27.67,274.5,58.73,55.04,19.12,105.95,45.62,157.71,71.07,33.29,36.66,51.85,79.15,51.1,129.29-11.3,11.18-25.81,11.87-40.27,11.87-276.38.13-552.76.98-829.14-.42-5.77-.03-11.44-1.17-16.95-2.63-27.52-12.14-23.9-36.84-21.88-59.29,5.73-63.53,48.45-102.4,96.21-135.83,59.81-41.86,127.16-65.52,199.63-74.38,8.22.15,16.44.3,24.66.45,4.42-3.66,9.73-3.81,15.07-3.82,6.89-.41,14.18,1.34,20.22-3.73Z"
      />
      <path
        fill="#c9c6ca"
        d="m1590.33,2115.05c-299.35-.25-598.7-.5-898.06-.75-16.36-16.05-14.13-37.09-14.14-56.92-.38-533.13-.36-1066.25-.06-1599.38.01-20.03-1.13-40.65,11.98-58.15,15.53-13.96,34.86-11.76,53.32-11.78,264.58-.31,529.17-.27,793.75-.02,18.42.02,37.82-2.64,53.23,11.8.35,259.22,1,518.45.97,777.67-.03,312.51-.64,625.02-1,937.53Z"
      />
      <path
        fill="#b7b2b8"
        d="m1590.29,287.84c-.03,34.48-.06,68.96-.08,103.45-9.02,6.49-19.41,4.25-29.29,4.25-280.59.11-561.18.09-841.76.06-8.6,0-17.3.69-25.64-2.45-22.64-35.26-21.1-70.79-1.21-106.52,9.45-4.72,19.65-3.25,29.6-3.25,278.32-.07,556.64-.09,834.95.08,11.21,0,23.07-3.01,33.42,4.38Z"
      />
      <path
        fill="#ecebed"
        d="m52.01,2834.62c15.83-.41,31.66-1.18,47.49-1.18,570.72-.06,1141.43-.04,1712.15-.1,18.72,0,37.44-.74,56.15-1.14-11.72,16.26-29.03,9.54-43.87,9.55-576.94.34-1153.87.32-1730.81.12-13.9,0-29.06,4.39-41.11-7.25Z"
      />
      <path
        fill="#c6c2c7"
        d="m1590.29,287.84c-299.45-.11-598.9-.22-898.35-.34,0,0-2.51-2.58-2.51-2.58,0,0,.05-3.6.05-3.6,0,0,2.65-2.43,2.65-2.43,299.43.01,598.87.02,898.29.04-.05,2.97-.1,5.94-.14,8.91Z"
      />
      <path
        fill="#beb9bf"
        d="m692.12,391.55c299.37-.09,598.73-.17,898.09-.26.77,2.84.83,5.69.15,8.56-299.38.12-598.76.24-898.13.36,0,0-2.72-2.46-2.72-2.46,0,0-.03-3.67-.03-3.67,0,0,2.64-2.54,2.64-2.54Z"
      />
      <path
        fill="#ada7ae"
        d="m692.33,2331.13c299.26.07,598.53.14,897.79.21-.02,2.92-.05,5.84-.07,8.75-299.34-.13-598.68-.26-898.02-.39,0,0-2.57-2.58-2.57-2.58,0,0,.08-3.65.08-3.65,0,0,2.79-2.35,2.79-2.35Z"
      />
      <path
        fill="#c1bec3"
        d="m1867.14,2730.22c-77.22-.25-154.45-.11-231.67-1.08-14.66-.19-31.94,7.14-43.43-9.43,78.7.37,157.4.4,236.09,1.44,13.18.18,29.1-6.9,39.02,9.07Z"
      />
      <path
        fill="#b7b2b8"
        d="m692.23,400.22c.01,571.36.03,1142.73.05,1714.09,0,0-.71.7-.71.7-15.21,13.76-34.2,13.26-52.71,13.32-125.8.43-251.59.45-377.39-.03-18.39-.07-37.58,1.21-52.6-13.17-.43-18.71-1.23-37.41-1.23-56.12-.07-534.37-.07-1068.74,0-1603.11,0-18.71.81-37.41,1.24-56.12,15.43-14.56,34.91-11.65,53.33-11.7,125.34-.34,250.68-.31,376.02-.04,18.53.04,37.86-2.18,53.39,11.92l.6.25Z"
      />
      <path
        fill="#867e88"
        d="m692.03,2339.71c-.06,126.99-.12,253.99-.18,380.98-17.82,8.67-36.81,4.04-55.16,4.06-140.92.17-281.84.87-422.75-.2-2.54-1.19-5.08-2.39-7.61-3.58.89-126.99,1.78-253.97,2.66-380.96,13.66-13.82,31.4-12.18,48.38-12.22,128.62-.32,257.24-.28,385.86-.05,17.11.03,34.62-.81,48.6,12.13l.2-.17Z"
      />
      <path
        fill="#b7b2b8"
        d="m978.01,18.27c-6.18,13.23-17.15,16.4-31.02,18.47-78.21,11.67-144.98,46.27-204.1,99.16-44.15,39.5-55.09,87.79-50.76,143,0,0-.42-.06-.42-.06-14.11,13.1-31.82,12.05-49.05,12.09-128.29.25-256.57.27-384.86-.04-17.11-.04-35,1.74-48.96-11.93-17.43-88.43,45.53-128.01,117.53-165.16,95.9-49.48,201.62-63.76,304.85-87.12,2.98-2.05,5.96-1.95,8.94,0,20.13-2.91,40.26-5.82,60.39-8.73,2.89-1.98,5.77-1.89,8.66,0,15.04,2.78,29.36.89,42.81-6.65,3.99,2.92,8.55,3.67,13.35,3.57,4.76.02,9.38-.47,13.31-3.56,14.38-.66,28.76-1.32,43.14-1.98,48.88-5.11,97.76-4.97,146.65-.11,3.18,3.02,6.35,6.03,9.53,9.05Z"
      />
      <path
        fill="#9c969e"
        d="m208.86,2115.13c160.91-.04,321.81-.08,482.71-.12.25,72.05.5,144.09.76,216.13,0,0-.81,0-.81,0-23.8,8.54-48.45,3.81-72.63,3.93-122.49.58-244.98.4-367.47.09-14.14-.04-28.86,3.58-42.42-3.88-.04-72.05-.09-144.1-.13-216.14Z"
      />
      <path
        fill="#9c969e"
        d="m691.94,287.51c.06,34.68.12,69.36.18,104.04,0,0-.43-.26-.43-.26-82.46,9.76-165.22,3.72-247.81,3.76-78.28.04-156.77,6.03-234.92-3.79-.02-34.45-.05-68.9-.07-103.35,11.73-8.03,25.02-4.38,37.57-4.41,135.88-.28,271.76-.27,407.64-.02,12.6.02,25.83-3.33,37.71,4.13l.12-.11Z"
      />
      <path
        fill="#b2adb3"
        d="m691.82,287.62c-160.98.1-321.95.2-482.92.29-.02-2.98-.04-5.97-.05-8.95,160.96-.04,321.91-.08,482.87-.12.03,2.93.07,5.85.11,8.78Z"
      />
      <path
        fill="#a6a0a8"
        d="m208.96,391.26c160.91.01,321.82.02,482.73.03-.02,2.89-.04,5.78-.06,8.67-160.92-.06-321.84-.12-482.76-.18-.89-2.85-.86-5.69.1-8.52Z"
      />
      <path
        fill="#948d96"
        d="m208.99,2331.27c160.84-.04,321.69-.09,482.53-.13.1,2.91.21,5.82.31,8.73-160.95.05-321.89.09-482.83.14,0-2.91,0-5.83,0-8.74Z"
      />
      <path
        fill="#c1bec3"
        d="m207.23,2729.39c-49.15-.25-98.31-.5-147.46-.75,48.1-16.93,97.78-3.72,146.56-7.67,0,0,3.47-.9,3.47-.9,2.07,3.91,1.22,7.02-2.56,9.32Z"
      />
      <path
        fill="#f1f1f2"
        d="m968.48,9.23c-48.89.04-97.77.08-146.66.12,48.88-12.54,97.76-12.33,146.66-.12Z"
      />
      <path
        fill="#d3d0d4"
        d="m778.69,11.32c-4.24,6.4-10.64,7.62-17.65,7.4-1.89-1.72-2.5-3.42-1.82-5.09.67-1.66,1.32-2.49,1.97-2.49,5.83.06,11.67.12,17.5.18Z"
      />
      <path
        fill="#d3d0d3"
        d="m1020.66,18.96c-5.99-.08-11.98-.15-17.98-.23,4-7.02,10.55-8.08,17.75-7.63,2.9,1.67,3.9,3.39,3,5.16-.91,1.8-1.83,2.7-2.78,2.7Z"
      />
      <path
        fill="#d9d7db"
        d="m1020.66,18.96c-.06-2.63-.13-5.25-.22-7.87,5.84.03,11.69.05,17.53.08-4.03,6.48-10.39,7.77-17.31,7.8Z"
      />
      <path
        fill="#d7d5d8"
        d="m1107.11,19.85c-5.95,8.19-11.77,7.9-17.48-.25,5.83.09,11.66.17,17.48.25Z"
      />
      <path
        fill="#f5f5f5"
        d="m1089.88,18.63c-5.78-.12-11.56-.24-17.34-.37,5.96-8.21,11.74-7.75,17.34.37Z"
      />
      <path
        fill="#f2f1f2"
        d="m709.22,17.96c-2.89,0-5.78,0-8.66,0,2.89-4.28,5.78-4.28,8.66,0Z"
      />
      <path
        fill="#d7d4d8"
        d="m761.19,11.14c-.05,2.53-.1,5.06-.15,7.59-3-2.48-6.01-4.95-9.01-7.43,3.05-.05,6.11-.11,9.16-.16Z"
      />
      <path
        fill="#ecebec"
        d="m640.17,26.68c-2.98,0-5.96,0-8.94,0,2.98-4.36,5.96-4.36,8.94,0Z"
      />
      <path
        fill="#948d96"
        d="m207.23,2729.39c.85-3.1,1.7-6.21,2.55-9.32,160.69.2,321.38.41,482.07.61-12.61,14.45-29.57,8.48-44.63,8.52-146.66.41-293.33.21-439.99.18Z"
      />
      <path d="m1557.84,451.58c12.25,23.94,16.43,49.39,16.42,76.21-.21,480.43.11,960.86-.79,1441.29-.06,29.44,6.5,63.49-24.33,85.33-5.24,1.95-9.13.43-11.7-4.58-3.33-17.77,1.22-34.75,4.9-51.89,2.9-12.86,2.21-25.89,2.21-38.84.1-474.29.1-948.57.01-1422.86,0-11.52.32-23.08-2.05-34.39-3.76-14.29-9.93-28.26-7.21-43.64,1.78-4.59,4.94-7.95,9.39-10.05,4.84-.69,9.23.45,13.15,3.42Z" />
      <path d="m735.66,2053.46c-15.4,1.68-24.77-4.79-26.2-20.53-.78-8.57-.25-17.27-.25-25.91,0-502.65-.09-1005.31.33-1507.96.02-19.01-10.34-46.76,26.15-46.42,1.26,2.15,2.53,4.3,3.82,6.45,3.16,12.79-1.53,25.4-.8,38.19-.16,501.68.12,1003.29,0,1504.85-.65,14.99,4.46,29.95.86,45.07-1.28,2.09-2.6,4.18-3.92,6.27Z" />
      <path
        fill="#262526"
        d="m1505.06,2080.68c-7.48,13.57-20.49,7.69-30.9,7.71-221.63.38-443.26.38-664.89,0-10.43-.02-23.39,5.8-31.01-7.57,1.94-1.96,3.89-3.92,5.81-5.91,20.24-10.41,42.15-6.78,63.41-6.87,207.92-.82,415.84.93,623.69-1.04,5.02-.88,10.12-1.64,15.18-2.38,5.07-.12,9.92.99,14.49,3.22,4.44,3.24,5.86,7.53,4.22,12.82Z"
      />
      <path
        fill="#423434"
        d="m1486.95,418.67c5.64,2.1,11.27,4.19,16.91,6.29.95,2.64,1.89,5.29,2.84,7.93-1.83,2.13-3.67,4.25-5.48,6.4-2.48,1.31-4.98,2.59-7.45,3.93-45.56,7.5-91.49,3.64-137.21,3.37-187.63-1.11-375.3,4.01-562.93-2.51-5.52-1.65-10.3-4.54-14.36-8.62-3.48-5.99-2.01-10.31,4.41-12.94,218.75-1.51,437.51-.15,656.27-.8,15.66-.05,31.67,3.01,46.99-3.06Z"
      />
      <path d="m1486.95,418.67c-13.23,13.23-29.96,7.07-44.99,7.1-221.03.49-442.05.42-663.08.48-3.13,2.73-6.51,3.24-10.2,1.16-2.52-3.71-1.84-6.68,2.04-8.91,238.74.06,477.48.11,716.23.17Z" />
      <path
        fill="#030101"
        d="m751.44,2071.86c-2.71-2.89-5.42-5.78-8.14-8.67,1.12-1.25,2.23-2.51,3.34-3.78,3.19-1.29,6.48-1.64,9.88-1.22,3.31,1.15,5.77,3.29,7.4,6.39,0,1.69,0,3.39,0,5.09-1.01,1.36-2.03,2.71-3.05,4.08-3.44.84-6.6.29-9.44-1.88Z"
      />
      <path
        fill="#030101"
        d="m1540.02,2063.18c-2.71,2.89-5.42,5.78-8.13,8.68-2.85,2.09-5.98,2.79-9.43,1.89-1.02-1.36-2.04-2.72-3.06-4.09-.35-3.53.6-6.66,2.83-9.42,3.01-1.77,6.27-2.61,9.75-2.68,1.63.51,3.26,1.04,4.89,1.56,1.05,1.35,2.1,2.71,3.15,4.06Z"
      />
      <path
        fill="#010101"
        d="m778.26,2080.82c-5.65-.73-11.31-1.47-16.96-2.2-2.06-1.55-2.77-3.15-2.14-4.78.63-1.65,1.28-2.47,1.95-2.47v-.02c2.83-2.91,6.26-4.44,10.31-4.6,1.86.6,3.73,1.21,5.59,1.82,1.19,1.55,2.39,3.11,3.58,4.66-.83,1.77-1.66,3.55-2.49,5.33,0,0,.17,2.26.17,2.26Z"
      />
      <path
        fill="#010101"
        d="m1505.06,2080.68c-.04-3.09-.07-6.17-.11-9.26,0,0,2.67-3.23,2.67-3.24,2.5-1.52,5.21-2.07,8.11-1.7,3.01.48,5.17,2.11,6.48,4.87v.02c1.97,1.67,2.6,3.29,1.89,4.89-.7,1.57-1.4,2.36-2.08,2.36-5.65.68-11.3,1.37-16.95,2.06Z"
      />
      <path
        fill="#6c6a6d"
        d="m770.72,418.5c-.68,2.4-1.3,4.83-1.85,7.26-1.46.74-2.91,1.47-4.37,2.21-1.14-1.17-2.28-2.34-3.42-3.51,1.59-4.62,4.42-7.23,9.64-5.97Z"
      />
      <path
        fill="#39383a"
        d="m1503.85,424.96c3.03-2.1,6.06-4.2,9.09-6.3,3.27,1.8,4.08,4.46,2.76,7.86-2.73,2.12-5.71,2.67-8.99,1.43,0,0-2.86-2.99-2.86-2.99Z"
      />
      <path
        fill=" #6d6b6e"
        d="m1514.33,424.98c-.45-2.11-.91-4.22-1.39-6.32,3.1,1.94,6.2,3.87,9.29,5.81-1.25,1.15-2.5,2.3-3.75,3.45-1.39-.98-2.77-1.96-4.16-2.94Z"
      />
      <path
        fill="#565456"
        d="m743.42,433.17c1.67-4.52,4.4-7.3,9.67-5.84,1.02,3.13.43,5.86-2.03,8.12-1.49.36-2.99.72-4.49,1.09-1.05-1.12-2.1-2.25-3.15-3.37Z"
      />
      <path
        fill="#111112"
        d="m752.21,433.8c.15-2.18.44-4.34.89-6.47,2.69-.88,5.35-1.83,7.98-2.86,0,0-.28,4.02-.28,4.02,1.93,2.93,1.42,5.34-1.52,7.24,0,0-4.05.89-4.05.89,0,0-3.03-2.83-3.03-2.83Z"
      />
      <path
        fill="#111112"
        d="m1522.23,424.47c2.63,1.03,5.29,1.98,7.98,2.86l2.96,2.81s-1.62,3.75-1.62,3.75c0,0-3.46,2.13-3.46,2.13,0,0-4.03-.56-4.03-.55-3.01-1.77-3.53-4.1-1.55-6.99,1.22-1.43,1.12-2.76-.28-4.01Z"
      />
      <path d="m1538.98,2053.62c3.39.26,6.78.53,10.17.79-3.04,2.92-6.08,5.85-9.12,8.77,0,0-.76-.76-.76-.76-.99-1.44-1.98-2.87-2.98-4.31.89-1.5,1.8-2.99,2.7-4.49Z" />
      <path d="m743.3,2063.19c-5.3-1.08-8.27-3.99-7.64-9.73,0,0-1,1.09-1,1.09,2.59-3.45,5.82-4.04,9.61-2.15,2.5,3.4,2.43,6.74-.22,10.03l-.75.76Z" />
      <path
        fill="#111"
        d="m1557.84,451.58c-3.2.15-6.4.29-9.59.44-.92-1.67-1.85-3.34-2.78-5.01,1.07-1.57,2.16-3.15,3.24-4.72,3.04,3.1,6.09,6.2,9.13,9.29Z"
      />
      <path
        fill="#171718"
        d="m1522.02,2078.62c.15-2.41.22-4.83.2-7.25,3.22.16,6.44.32,9.67.48-1.61,4.71-3.88,8.45-9.87,6.76Z"
      />
      <path d="m735.69,452.63c.29-2.97.58-5.95.87-8.92,1.47-1.24,2.93-2.48,4.39-3.73,1.61,1.03,3.23,2.07,4.85,3.09,1.06,3.85.48,7.44-1.72,10.77-3.81,2-6.93,1.11-9.44-2.3l1.04,1.09Z" />
      <path
        fill="#555456"
        d="m1531.62,434.14c-.4-2.28-.87-4.55-1.41-6.81,5.28-1.46,8,1.32,9.68,5.84,0,0-1.6,3.62-1.6,3.63,0,0-3.96.21-3.96.22,0,0-2.72-2.88-2.72-2.88Z"
      />
      <path
        fill="#232223"
        d="m1539.9,433.17c2.94,3.04,5.88,6.08,8.81,9.12l-1.4,3.74-3.85.7s-3.29-2.11-3.3-2.1c-2.2-2.17-2.5-4.58-.89-7.23.72-1.33.92-2.74.62-4.23Z"
      />
      <path
        fill="#232223"
        d="m743.95,443.53h-3.7s-3.69.17-3.69.17c-1.53-6,2.37-8.47,6.86-10.54,0,0,.61,4.23.61,4.23l2.28,3.1-2.37,3.04Z"
      />
      <path
        fill="#171718"
        d="m761.11,2071.37c-.03,2.42.04,4.84.2,7.25-5.98,1.68-8.26-2.05-9.86-6.76,3.22-.16,6.44-.32,9.66-.49Z"
      />
      <path
        style={{ fill: "transparent" }}
        d="m734.66,451.55c2.97-.3,5.95-.61,8.92-.91,2.62-1.36,4.31-3.46,5.06-6.32-.01,0,2.83-3,2.81-3,2.33-1.43,4.88-2.31,7.59-2.63,2.51-.76,5.05-1.5,7.57-2.27,2.48-.79,5.03-1.32,7.62-1.68-.01-.02,3.86-.55,3.86-.55,2.97.67,5.94,1.34,8.91,2.02,68.68,6.82,137.55,2.89,206.29,2.63,170.72-.63,341.47,3.76,512.16-2.25l-.74-3c2.46-1.87,5.19-2.46,8.2-1.8,3.8,2.13,7.1,5.12,11.21,6.76,1.42.34,2.83.69,4.25,1.04,1.32.63,2.63,1.28,3.96,1.91,2.25,1.92,4.19,4.1,5.81,6.58.31,1.44.61,2.88.91,4.32,3.34,11.12,6.68,22.25,10.02,33.37,5.29,10.96,3.21,22.69,3.21,34.09.1,485.9.1,971.8,0,1457.7,0,11.4,2.07,23.13-3.21,34.09-2.45,14.21,2.79,30.27-10.08,41.97,0,0-.09-.37-.09-.37-2,4.02-5.07,6.95-9.06,8.96-2.48.2-4.86.81-7.12,1.84-2.83,1.86-5.92,2.88-9.31,2.94-3.34.44-6.36,1.67-9.06,3.69l.62.73c-2.84-.47-5.68-.95-8.52-1.42-234.01,3.62-468.04-.72-702.05,2.29-5.45,1.76-10.78,1.47-16.01-.88l.65-.8c-2.78-1.86-5.82-3.07-9.12-3.61-3.38-.08-6.48-1.06-9.29-2.94-2.27-1.03-4.65-1.65-7.14-1.85-3.38-2.1-6.66-4.35-9.84-6.75-2.99-.31-5.98-.61-8.97-.92,6.17-13.23-13.75-21.27-6.87-34.64,5.03-11,3.1-22.7,3.1-34.11.09-491.4.09-982.81,0-1474.21,0-11.41,1.94-23.11-3.12-34.11,1.21-8.93,11.1-15.55,6.89-25.93Z"
      />
      <path
        fill="#c19f9f"
        d="m1549.05,2011.65c0-508.63,0-1017.25,0-1525.87,14.61,10.99,7.38,26.72,7.39,39.99.44,481.97.44,963.93,0,1445.9-.01,13.27,7.21,29-7.39,39.99Z"
      />
      <path d="m1539.37,443.53c2.87.12,5.51.94,7.95,2.48.52,1.98.84,3.98.93,6.01-3.07.13-6.15.26-9.22.39,0,0-.16.43-.16.43-.88-1.6-1.76-3.21-2.66-4.81,1.04-1.5,2.1-3,3.16-4.49Z" />
      <path
        fill="#b08c8b"
        d="m727.77,477.47c13.64,13.14,7.05,29.99,7.06,44.95.4,484.18.4,968.36,0,1452.54-.01,14.96,6.6,31.8-7.04,44.94-.43-197.3-1.24-394.6-1.19-591.9.09-316.85.75-633.69,1.17-950.54Z"
      />
      <path
        fill="#241e1e"
        d="m778.38,2071.41c2.93.23,5.74-.25,8.43-1.45,101.87-6.37,203.84-1.82,305.76-2.59,117.84-.88,235.7-.41,353.55.06,13.81.05,28.83-5.17,41.46,5.42-6.65,6.86-15.23,7.18-23.95,7.16-228.51-.48-457.01-.96-685.52-1.44-.06-2.4.04-4.79.28-7.16Z"
      />
      <path
        fill="#d9c4c3"
        d="m1487.57,2072.85c-200.65-.5-401.3-.94-601.95-1.54-32.94-.1-65.88-.88-98.82-1.35,10.52-10.94,24.16-7.09,36.61-7.11,212.15-.21,424.3-.21,636.45,0,12.43.01,26.09-3.9,36.57,7.14-2.96.95-5.91,1.91-8.87,2.86Z"
      />
      <path
        fill="#e2d4d4"
        d="m1505.44,436.6c-10.86,10.1-24.37,6.66-36.91,6.67-214.95.17-429.9.18-644.86-.01-12.49-.01-26.08,3.63-36.69-7.04,239.48.13,478.97.25,718.45.38Z"
      />
      <path
        fill="#020101"
        d="m761,434.38c.08-1.97.01-3.93-.2-5.89,2.51-1.44,5.2-2.35,8.06-2.72,3.33.16,6.66.32,10,.48-.28,2.65-.54,5.3-.79,7.95l.81,1.34c-2.94,1.73-6.12,2.75-9.5,3.17-1.68-.17-3.36-.34-5.05-.51-1.12-1.27-2.23-2.55-3.34-3.82Z"
      />
      <path
        fill="#020101"
        d="m1512.74,435.79c-2.68-.72-5.36-1.45-8.04-2.19.32-2.01.99-3.89,2.02-5.64,2.48-1.13,5.02-2.12,7.61-2.97,2.73,1.16,5.46,2.33,8.19,3.5-.21,1.96-.29,3.93-.22,5.91,0,0-1.64,3.86-1.63,3.87-2.85-.13-5.49-.95-7.93-2.46Z"
      />
      <path
        fill="#4e4342"
        d="m760.62,2064.06c2.95.01,5.91.02,8.86.04l3.16,2.91-2.52,3.47c-3,.29-6,.58-9,.87.01-2.44-.15-4.87-.5-7.29Z"
      />
      <path
        fill="#231514"
        d="m743.63,2055.47c6.15-1.95,8.29,1.97,9.84,6.75-3.14.07-6.29.15-9.43.21-.19-2.32-.33-4.64-.41-6.96Z"
      />
      <path
        fill="#231514"
        d="m1529.83,2062.22c3.02-2.98,6.05-5.97,9.07-8.96.12,3.05.24,6.11.37,9.16-3.15-.07-6.29-.14-9.43-.21Z"
      />
      <path
        fill="#4e4342"
        d="m1513.84,2064.1c2.95-.01,5.91-.03,8.86-.04-.34,2.42-.51,4.85-.5,7.29-3-.3-6.01-.6-9.01-.91l-2.51-3.46,3.15-2.88Z"
      />
      <path
        fill="#686262"
        d="m770.11,2070.48c-.22-2.13-.43-4.26-.63-6.39,5.71-1.55,8.14,1.74,9.55,6.51-2.97-.04-5.94-.08-8.91-.13Z"
      />
      <path
        fill="#686262"
        d="m1513.84,2064.1c-.2,2.13-.41,4.25-.64,6.36-2.96.06-5.92.15-8.87.23,1.36-4.82,3.77-8.12,9.51-6.59Z"
      />
      <path d="m743.95,443.53c-.06-2.05-.03-4.1.09-6.14,2.72-1.19,5.45-2.39,8.17-3.59,0,0,.3,1.9.3,1.9,2.73,1.74,3.68,3.54,2.85,5.39-.84,1.88-1.7,2.83-2.58,2.83l.45.08c-3.31,2.1-6.47,2-9.45-.66l.18.18Z" />
      <path
        fill="#0b0202"
        d="m752.77,443.93c-.17-2.74-.26-5.49-.27-8.23,2.8-.62,5.64-1.06,8.5-1.32,0,0,.37.65.37.65,2.75,2.03,3.51,3.78,2.29,5.24-1.09,1.31-2.2,1.97-3.33,1.97-2.42,1-4.94,1.56-7.55,1.69Z"
      />
      <path
        fill="#0b0202"
        d="m1530.51,443.9c-2.59-.11-5.09-.66-7.49-1.65-2.32-1.28-3.26-2.76-2.81-4.47.47-1.81,1.04-2.71,1.71-2.71,0,0,.38-.68.38-.69,3.1-.08,6.21-.16,9.32-.25,0,0-.59.29-.59.29,2.46,2.24,3.2,4.4,2.23,6.45-.95,2.01-1.86,3.02-2.74,3.02Z"
      />
      <path
        fill="#2d1b1b"
        d="m743.77,443.35c3.15.21,6.29.43,9.45.65-1.38,4.88-3.6,8.55-9.64,6.63.21-2.42.27-4.85.19-7.28Z"
      />
      <path d="m1530.51,443.9c.17-3.16.34-6.31.52-9.47,2.75.99,5.51,1.98,8.26,2.97.12,2.04.16,4.09.1,6.14,0,0,.17-.17.17-.17-2.98,2.6-6.13,2.81-9.44.64l.4-.1Z" />
      <path
        fill="#83706f"
        d="m1521.92,435.07c.05,2.44.42,4.83,1.1,7.18-5.64,1.38-8.47-1.73-10.28-6.46,3.06-.24,6.12-.48,9.19-.72Z"
      />
      <path
        fill="#2d1b1b"
        d="m1530.11,444c3.14-.21,6.29-.42,9.43-.64-.22,3.15-.44,6.3-.67,9.47-2.93-2.94-5.85-5.88-8.77-8.83Z"
      />
      <path
        fill="#a4a0a0"
        d="m770.18,435.98c2.91-.15,5.81-.29,8.72-.44-3.2,2.26-6.4,4.52-9.6,6.78-1.95-1.71-2.47-3.22-1.56-4.53.84-1.21,1.65-1.81,2.44-1.81Z"
      />
      <path
        fill="#83706f"
        d="m770.18,435.98c-.29,2.13-.58,4.25-.88,6.36-3-.04-5.99-.07-8.98-.1.68-2.36,1.02-4.77,1.03-7.22,2.95.32,5.88.64,8.82.95Z"
      />
    </svg>
  );
};
