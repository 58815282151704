import React, { useEffect } from "react";
import { Loader } from "semantic-ui-react";
import { HeaderPage } from "../../../Components/Client";
import { DataGridBasic, ModalBasic, } from "../../../Components/Comons";
import { useProveedores } from "../../../hooks";
import { Funciones } from "./Funciones";
import { columns, filterValue } from "./ProveedoresFormat";
import CircularProgress from '@mui/material/CircularProgress';

export function ProveedoresAdmin() {
  const { loading, Proveedores, getProveedores } = useProveedores();

  const {
    refetch,
    contentModal,
    titleModal,
    showModal,
    handleAdd,
    handleDelete,
    handleUpdate,
    openCloseModal,
  } = Funciones();

  useEffect(() => {
    getProveedores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const MemoizedModalBasic = React.memo(ModalBasic);

  return (
    <>
      <HeaderPage
        title="Proveedores"
        btnTitle="Nuevo proveedor"
        icono="plus square icon"
        btnClick={handleAdd}
      />

      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <DataGridBasic
          data={Proveedores.results}
          title="Proveedores"
          id="id_proveedor"
          filterValue={filterValue}
          columns={columns}
          updateData={handleUpdate}
          onDeleteData={handleDelete}
        />
      )}

      {showModal && (
        <MemoizedModalBasic
          show={showModal}
          onClose={openCloseModal}
          title={titleModal}
          children={contentModal}
        />
      )}

    </>
  )
}


