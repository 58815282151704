
  import { BASE_API } from "../../utils/constants";
  import { makeRequest } from "../Request";
  const BASE_URL = `${BASE_API}/`;
  
  // Función para obtener los datos 
  export async function getTransportadoraApi(token,schema,filter,transportadora) {
    let url = `${BASE_URL}${schema}/transportadora/transportadora`;
    if (filter) {
      url += `?filter=${filter}`;
    }
    if (transportadora) {
      url += `&transp=${transportadora}`;
    }
    return makeRequest(url, "GET", token);
  }
  
  // Función para agregar un nuevo dato
  export async function addTransportadoraApi(data, token,schema) {
    const url = `${BASE_URL}${schema}/transportadora/transportadora/`;
    return makeRequest(url, "POST", token, data);
  }
  
  // Función para actualizar los datos 
  export async function updateTransportadoraApi(id, data, token,schema) {
    const url = `${BASE_URL}${schema}/transportadora/transportadora/${id}/`;
    return makeRequest(url, "PUT", token, data);
  }
  
  // Función para eliminar 
  export async function deleteTransportadoraApi(id, token,schema) {
    const url = `${BASE_URL}${schema}/transportadora/transportadora/${id}/`;
    return makeRequest(url, "DELETE", token);
  } 