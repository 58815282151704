import { BASE_API } from "../../utils/constants";
import { makeRequest } from "../Request";
const BASE_URL = `${BASE_API}/`;

export async function getBaseVerApi(token, schema) {
  const url = `${BASE_URL}${schema}/tanques/basetankcheklist`;
  return makeRequest(url, "GET", token);
}

// Función para agregar un nuevo dato
export async function addBaseVerApi(data, token, schema) {
  const url = `${BASE_URL}${schema}/tanques/basetankcheklist/`;
  return makeRequest(url, "POST", token, data);
}

// Función para actualizar los datos 
export async function updateBaseVerApi(id_ver_group, data, token, schema) {
  const url = `${BASE_URL}${schema}/tanques/basetankcheklist/${id_ver_group}/`;
  return makeRequest(url, "PUT", token, data);
}

// Función para eliminar 
export async function deleteBaseVerApi(id_ver_group, token, schema) {
  const url = `${BASE_URL}${schema}/tanques/basetankcheklist/${id_ver_group}/`;
  return makeRequest(url, "DELETE", token);
}

export async function getBaseVehVerApi(token, schema) {
  const url = `${BASE_URL}${schema}/vehiculos/basevehiclechecklist`;
  return makeRequest(url, "GET", token);
}

// Función para agregar un nuevo dato
export async function addBaseVehVerApi(data, token, schema) {
  const url = `${BASE_URL}${schema}/vehiculos/basevehiclechecklist/`;
  return makeRequest(url, "POST", token, data);
}

// Función para actualizar los datos 
export async function updateBaseVehVerApi(id_ver_group, data, token, schema) {
  const url = `${BASE_URL}${schema}/vehiculos/basevehiclechecklist/${id_ver_group}/`;
  return makeRequest(url, "PUT", token, data);
}

// Función para eliminar 
export async function deleteBaseVehVerApi(id_ver_group, token, schema) {
  const url = `${BASE_URL}${schema}/vehiculos/basevehiclechecklist/${id_ver_group}/`;
  return makeRequest(url, "DELETE", token);
}