import { useFormik } from "formik";
import React, { useState } from 'react';
import { toast } from "react-toastify";
import Button from "@mui/material/Button"
import Container from "@mui/material/Container"
import Paper from "@mui/material/Paper"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select"
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Grid from "@mui/material/Grid";
import { Autocomplete } from "@mui/material";

import * as Yup from "yup";
import { useSistemaMedicion } from "../../../hooks";
import { alertas } from "../../Comons/Alertas/alertas";

export function AddEditSistemaMedicionBase(props) {

  /*
 Componente

 -componente de visualizacion del modal de edicion y creacion para Sistema de medicion
 
 -se crea y valida mediante formik y yup
 -Se obtienen datos para dropdown mediante codigo en el componente
 -se obtiene los datos (para edicion) y funciones correspondientes mediante props desde su PageAdmin
  */
  const { onClose, onRefetch, Datos } = props;
  const { Successful } = alertas();
  const { updateSistemaMedicion } = useSistemaMedicion();

  const [errors, setErrors] = React.useState({
    longitud: false,
    volumen: false,
    temperatura: false,
    presion: false,
    densidad: false,
    flujo: false,
  });

  const formik = useFormik({
    // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
    initialValues: initialValues(Datos),
    // Establece la validación del esquema utilizando Yup, si se proporciona un objeto  se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
    validationSchema: Yup.object(newSchame()),
    // Desactiva la validación al cambiar los valores del formulario
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        // Si se proporciona un objeto se actualiza, de lo contrario se crea uno nuevo
        await updateSistemaMedicion(Datos.id_sistema, formValue);

        Successful()
        onRefetch();
        onClose();
      } catch (error) {
        if (error?.message) {
          // Muestra un mensaje de error si ocurre algún problema
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });

  const handleAutocompleteChange = (name, value) => {
    formik.setFieldValue(name, value?.value || '');
    setErrors((prevErrors) => ({ ...prevErrors, [name]: !value || !value.value }));
  };

  return (
    <Container maxWidth="md">
      <form className="add-edit-secciones-form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Paper elevation={3} style={{ padding: 20, marginBottom: 20 }}>
              <InputLabel attached="top">Nombre configuración</InputLabel>
              <TextField
                required
                name="nombre"
                placeholder="Nombre"
                fullWidth
                value={formik.values.nombre}
                onChange={formik.handleChange}
                error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                helperText={formik.touched.nombre && formik.errors.nombre}
              />
            </Paper>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Paper elevation={3} style={{ padding: 20, marginBottom: 20 }}>
              <InputLabel>Unidad de medida para Longitud</InputLabel>
              <Autocomplete
                fullWidth
                options={LongitudOptions}
                getOptionLabel={(option) => option.text || ''}
                value={LongitudOptions.find((option) => option.value === formik.values.longitud) || null}
                onChange={(event, value) => { handleAutocompleteChange('longitud', value) }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Longitud"
                    variant="outlined"
                    error={errors.longitud}
                    helperText={errors.longitud ? 'Es necesaria una selección' : ''}
                  />
                )}
              />
              {/* <FormControl fullWidth>
            <Select
              value={formik.values.longitud}
              onChange={formik.handleChange}
              name="longitud"
            >
              {LongitudOptions.map((option) => (
                <MenuItem key={option.key} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
            </Paper>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Paper elevation={3} style={{ padding: 20, marginBottom: 20 }}>
              <InputLabel>Unidad de Medida para Volumen</InputLabel>
              <Autocomplete
                fullWidth
                options={VolumenOptions}
                getOptionLabel={(option) => option.text || ''}
                value={VolumenOptions.find((option) => option.value === formik.values.volumen) || null}
                onChange={(event, value) => handleAutocompleteChange('volumen', value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Unidad de Medida para Volumen"
                    variant="outlined"
                    error={errors.volumen}
                    helperText={errors.volumen ? 'Es necesario una selección' : ''}
                  />
                )}
              />
              {/* <FormControl fullWidth>
            <Select
              value={formik.values.volumen}
              onChange={formik.handleChange}
              name="volumen"
            >
              {VolumenOptions.map((option) => (
                <MenuItem key={option.key} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
            </Paper>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Paper elevation={3} style={{ padding: 20, marginBottom: 20 }}>
              <InputLabel>Unidad de Medida para Temperatura</InputLabel>
              <Autocomplete
                fullWidth
                options={TemperaturaOptions}
                getOptionLabel={(option) => option.text || ''}
                value={TemperaturaOptions.find((option) => option.value === formik.values.temperatura) || null}
                onChange={(event, value) => handleAutocompleteChange('temperatura', value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Unidad de Medida para Temperatura"
                    variant="outlined"
                    error={errors.temperatura}
                    helperText={errors.temperatura ? 'Es necesario una selección' : ''}
                  />
                )}
              />
              {/* <FormControl fullWidth>
            <Select
              value={formik.values.temperatura}
              onChange={formik.handleChange}
              name="temperatura"
            >
              {TemperaturaOptions.map((option) => (
                <MenuItem key={option.key} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
            </Paper>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Paper elevation={3} style={{ padding: 20, marginBottom: 20 }}>
              <InputLabel>Unidad de medida para presión</InputLabel>
              <Autocomplete
                fullWidth
                options={PresionOptions}
                getOptionLabel={(option) => option.text || ''}
                value={PresionOptions.find((option) => option.value === formik.values.presion) || null}
                onChange={(event, value) => handleAutocompleteChange('presion', value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Unidad de medida para presión"
                    variant="outlined"
                    error={errors.presion}
                    helperText={errors.presion ? 'Selection is required' : ''}
                  />
                )}
              />
              {/* <FormControl fullWidth>
            <Select
              value={formik.values.presion}
              onChange={formik.handleChange}
              name="presion"
            >
              {PresionOptions.map((option) => (
                <MenuItem key={option.key} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
            </Paper>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Paper elevation={3} style={{ padding: 20, marginBottom: 20 }}>
              <InputLabel>Unidad de Medida para densidad</InputLabel>
              <Autocomplete
                fullWidth
                options={DensidadOptions}
                getOptionLabel={(option) => option.text || ''}
                value={DensidadOptions.find((option) => option.value === formik.values.densidad) || null}
                onChange={(event, value) => handleAutocompleteChange('densidad', value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Unidad de Medida para densidad"
                    variant="outlined"
                    error={errors.densidad}
                    helperText={errors.densidad ? 'Selection is required' : ''}
                  />
                )}
              />
              {/* <FormControl fullWidth>
            <Select
              value={formik.values.densidad}
              onChange={formik.handleChange}
              name="densidad"
            >
              {DensidadOptions.map((option) => (
                <MenuItem key={option.key} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
            </Paper>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Paper elevation={3} style={{ padding: 20 }}>
              <InputLabel>Unidad de Medida para flujo</InputLabel>
              <Autocomplete
                fullWidth
                options={FlujoOptions}
                getOptionLabel={(option) => option.text || ''}
                value={FlujoOptions.find((option) => option.value === formik.values.flujo) || null}
                onChange={(event, value) => handleAutocompleteChange('flujo', value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Unidad de Medida para densidad"
                    variant="outlined"
                    error={errors.flujo}
                    helperText={errors.flujo ? 'Selection is required' : ''}
                  />
                )}
              />
              {/* <FormControl fullWidth>
            <Select
              value={formik.values.flujo}
              onChange={formik.handleChange}
              name="flujo"
            >
              {FlujoOptions.map((option) => (
                <MenuItem key={option.key} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Button type="submit" variant="contained" color="error" className="btn btn-danger" fullWidth>
              {Datos ? "Actualizar" : "Crear"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}


function initialValues(data) {
  return {
    nombre: data?.nombre || "",
    longitud: data?.longitud || "",
    volumen: data?.volumen || "",
    temperatura: data?.temperatura || "",
    flujo: data?.flujo || "",
    presion: data?.presion || "",
    densidad: data?.densidad || "",

  };
}
function newSchame() {
  return {
    nombre: Yup.string().required(true),
    longitud: Yup.string().required(true),
    volumen: Yup.string().required(true),
    temperatura: Yup.string().required(true),
    presion: Yup.string().required(true),
    densidad: Yup.string().required(true),
    flujo: Yup.string().required(true),
  };
}




const LongitudOptions = [
  {
    key: "m",
    text: "Metro(m)",
    value: "m",
  },
  {
    key: "cm",
    text: "Centimetro(cm)",
    value: "cm",
  },
  {
    key: "yd",
    text: "yarda(yd)",
    value: "yd",
  },
  {
    key: "ft",
    text: "pie(ft)",
    value: "ft",
  },

];
const VolumenOptions = [
  {
    key: "Gal",
    text: "Galones(Gal)",
    value: "Gal",
  },
  {
    key: "m3",
    text: "Metros cubicos(m3)",
    value: "m3",
  },
  {
    key: "bbl",
    text: "Barriles (bbl)",
    value: "bbl",
  },
  {
    key: "l",
    text: "Litros (l)",
    value: "l",
  },
];
const TemperaturaOptions = [
  {
    key: "°C",
    text: "Grados Celsius (°C)",
    value: "°C",
  },
  {
    key: "°F",
    text: "Grados Fahrenheit (°F)",
    value: "°F",
  },
  {
    key: "K",
    text: "Kelvin (K)",
    value: "K",
  }
];

const PresionOptions = [
  {
    key: "Psi",
    text: "Psi",
    value: "Psi",
  },

];
const DensidadOptions = [
  {
    key: "Kg/m3",
    text: "Kg/m3",
    value: "Kg/m3",
  },


];
const FlujoOptions = [
  {
    key: "gal/m",
    text: "gal/m",
    value: "gal/m",
  },

];



