import React, { useEffect, useState } from "react";
import { useVerificaciones } from "../../../hooks";
import { toast } from "react-toastify";
import FormControlLabel from "@mui/material/FormControlLabel"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import Paper from "@mui/material/Paper"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import { makeStyles } from "@material-ui/core/styles"
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';

import * as Yup from "yup";
import { alertas } from "../../Comons/Alertas/alertas";
import { useFormik, Formik } from "formik";
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#F5F5F5',
        padding: theme.spacing(2),
        borderRadius: 10,
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
        marginBottom: theme.spacing(2), // Agrega un margen inferior de 16px (2 * 8px)
    },
}))

export function AddEditVehVer(props) {
    const { Successful } = alertas();
    const { onClose, onRefetch, Data } = props;
    const { addBaseVehVer, updateBaseVehVer } = useVerificaciones()

    const classes = useStyles()

    const formik = useFormik({
        // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
        initialValues: initialValues(Data),
        // Establece la validación del esquema utilizando Yup, si se proporciona un objeto  se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
        validationSchema: Yup.object(Data ? updateScheme() : newScheme()),
        // Desactiva la validación al cambiar los valores del formulario
        validateOnChange: false,
        onSubmit: async (formValue) => {
            try {
                //console.log(formValue)

                // Si se proporciona un objeto se actualiza, de lo contrario se crea uno nuevo
                if (Data) await updateBaseVehVer(Data.id_ver_group, formValue);
                else await addBaseVehVer(formValue);
                Successful();
                onRefetch();
                onClose();
            } catch (error) {
                // Muestra un mensaje de error si ocurre algún problema
                if (error?.message) {
                    toast.error(error.message, {
                        position: "top-center",
                    });
                } else {
                    onClose();
                }
            }
        },
    });
    const divStyle = {
        textAlign: "left",
    };

    return (
        <>
            <Formik initialValues={initialValues()}>
                <form className="add-edit-secciones-form" style={divStyle} onSubmit={formik.handleSubmit}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12}>
                            <Paper elevation={3} style={{ padding: 20, marginBottom: 20, borderRadius: 10 }}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="h5">Conjunto de inspecciones</Typography>
                                    <br />
                                </Grid>
                                {formik.values.checklist.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={10}>
                                                <TextField
                                                    fullWidth
                                                    label={`Verificación ${index + 1}`}
                                                    name={`checklist[${index}].check`}
                                                    placeholder="Verificación"
                                                    value={item.check}
                                                    onChange={formik.handleChange}
                                                    error={formik.errors[`checklist[${index}].check`]}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={1}>
                                                <Tooltip title="Eliminar verificación" arrow>
                                                    <Button
                                                        fullWidth
                                                        variant="outlined"
                                                        color="secondary"
                                                        style={{
                                                            height: '100%', display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            padding: '0',
                                                            margin: '0',
                                                            color: 'rgba(252, 0, 0, 0.8)',
                                                            border: '1px solid rgba(252, 0, 0, 0.8)',
                                                        }}
                                                        onClick={() =>
                                                            formik.setFieldValue(
                                                                'checklist',
                                                                formik.values.checklist.filter((_, i) => i !== index)
                                                            )
                                                        }
                                                    >
                                                        <DeleteIcon style={{ margin: '0' }} />
                                                    </Button>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                        <br />
                                        <Grid container spacing={4}>
                                            <Grid item xs={12} sm={3}>
                                                <FormControlLabel hidden
                                                    control={
                                                        <Checkbox
                                                            readOnly
                                                            id={`valor_${index}`}
                                                            name={`valor_${index}`}
                                                            checked={item.valor === true}
                                                            onChange={() =>
                                                                formik.setFieldValue(`checklist[${index}].opcional`, !item.opcional)
                                                            }
                                                            hidden
                                                        />
                                                    }
                                                    label={Data ? "¿Cumple?" : ""}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            id={`opcional_${index}`}
                                                            name={`opcional_${index}`}
                                                            checked={item.opcional === true}
                                                            onChange={() =>
                                                                formik.setFieldValue(`checklist[${index}].opcional`, !item.opcional)
                                                            }
                                                            disabled={item.obligatoria === true || item.restrictiva === true}
                                                        />
                                                    }
                                                    label="¿Opcional?"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            id={`obligatoria_${index}`}
                                                            name={`obligatoria_${index}`}
                                                            checked={item.obligatoria === true}
                                                            onChange={() =>
                                                                formik.setFieldValue(`checklist[${index}].obligatoria`, !item.obligatoria)
                                                            }
                                                            disabled={item.opcional === true || item.restrictiva === true}
                                                        />
                                                    }
                                                    label="¿Obligatoria?"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            id={`restrictiva_${index}`}
                                                            name={`restrictiva_${index}`}
                                                            checked={item.restrictiva === true}
                                                            onChange={() =>
                                                                formik.setFieldValue(`checklist[${index}].restrictiva`, !item.restrictiva)
                                                            }
                                                            disabled={item.opcional === true || item.obligatoria === true}
                                                        />
                                                    }
                                                    label="¿Restrictiva?"
                                                />
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                ))}
                                <br />
                                <Box className={classes.root}>
                                    <Button
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        onClick={() =>
                                            formik.setFieldValue('checklist', [
                                                ...formik.values.checklist,
                                                { check: '', valor: false, opcional: false, obligatoria: false, restrictiva: false },
                                            ])
                                        }
                                    >
                                        Agregar
                                    </Button>
                                    <Box flexGrow={1} />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id="active"
                                                name="active"
                                                checked={formik.values.active}
                                                onChange={formik.handleChange}
                                            />
                                        }
                                        label="¿Seleccionar como formulario activo?"
                                    />
                                </Box>

                            </Paper>

                            <Grid item xs={12} sm={12}>
                                <Button type="submit" color='error' className="btn btn-danger" variant="contained" fullWidth> { Data ? "Actualizar" : "Crear" } </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Formik>
        </>
    )
}

function initialValues(data) {
    return {
        checklist: data?.checklist || [],
        active: data?.active || false
    };
}

function newScheme() {
    return {

    };
}

function updateScheme() {
    return {

    };
}