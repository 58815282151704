import { useFormik } from "formik";
import { map } from "lodash";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Form, Input, Select, Segment, Label } from "semantic-ui-react";
import * as Yup from "yup";
import { useMapping } from "../../../hooks";
import { alertas } from "../../Comons/Alertas/alertas";

export function AddEditMapping(props) {
  /*
  Componente

  -componente de visualización del modal de edicion y creacion para Mapping
  
  -se crea y valida mediante formik y yup
  -Se obtienen datos para dropdown mediante hooks
   */
  const { Successful } = alertas();
  const { onClose, onRefetch, Mapping } = props;
  const { addMapping, updateMapping } = useMapping();

  const formik = useFormik({
    // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
    initialValues: initialValues(Mapping),
    // Establece la validación del esquema utilizando Yup, si se proporciona un objeto GruposPermisos se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
    validationSchema: Yup.object(Mapping ? updateSchame() : newSchame()),
    // Desactiva la validación al cambiar los valores del formulario
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        // Si se proporciona un objeto Mapping se actualiza, de lo contrario se crea uno nuevo
        if (Mapping) await updateMapping(Mapping.id_mapping, formValue);
        else await addMapping(formValue);
        // Llama a una función Successful si se completa correctamente
        Successful();
        // Llama a la función onRefetch para actualizar la lista de GruposPermisos
        onRefetch();
        // Llama a la función onClose para cerrar el formulario
        onClose();
      } catch (error) {
        if (error?.message) {
          // Muestra un mensaje de error si ocurre algún problema
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });

  const [showFields, setShowFields] = useState(-1);
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const results = !searchTerm
    ? formik.values.registro
    : formik.values.registro.filter((registro) =>
        registro.nombre_var.toLowerCase().includes(searchTerm.toLowerCase())
      );

  const datos = [
    "Volumen Indicado",
    "GOV Entregado",
    "GSV Entregado",
    "NSV Entregado",
    "Volumen Restante",
    "Volumen Preestablecido",
    "Tasa de Flujo",
    "Totalizador IV",
    "Totalizador GOV",
    "Totalizador GSV",
    "Totalizador NSV",
    "Temperatura",
    "Temperatura Promedio",
    "Presión",
    "Presión Promedio",
    "Gravedad API",
    "Gravedad API Promedio",
    "BSW Entregado",
    "Densidad",
    "Densidad Promedio",
    "Densidad Relativa Promedio",
    "CTL",
    "CPL",
    "CTPL",
    "Estado de Bomba",
    "Permisivo 0 Puesta a Tierra",
    "Permisivo 1 Sobrellenado",
    "Permisivo 2 Parada de Emergencia",
    "Permisivo 3",
    "Permisivo 4",
    "Permisivo 5 SCADA",
    "Permisivo 6",
    "Permisivo 7",
    "Número de Orden",
    "Placa de Vehículo",
    "Libre",
    "Libre",
    "Libre",
    "Número de Bahía",
    "Máximo Preset Entrada",
    "Establecer Máximo Preset",
    "Entrada API",
    "Establecer API",
    "Entrada BSW",
    "Establecer BSW",
    "Entrada Permisivo 5",
    "Factor de Medidor",
    "Factor k- del Medidor",
    "Estado del Preestablecido",
    "Estado RCU",
    "PRODUCT_ID",
    "MTR1_IV_DEV",
    "MTR1_GOV_RT",
    "MTR1_FLOW_RT",
    "MTR1_VOLP_RT",
    "MTR1_TEMP_RT",
    "MTR1_PRES_RT",
    "MTR1_GAPI_RT",
    "MTR1_DENS_RT",
    "MTR2_IV_DEV",
    "MTR2_GOV_RT",
    "MTR2_FLOW_RT",
    "MTR2_VOLP_RT",
    "MTR2_TEMP_RT",
    "MTR2_PRES_RT",
    "MTR2_GAPI_RT",
    "MTR2_DENS_RT",
  ];

  return (
    <Form className="add-edit-persona-form" onSubmit={formik.handleSubmit}>
      <Form.Group widths="equal">
        <Form.Field
          required
          fluid
          control={Input}
          label="Nombre mapping"
          name="nombre_mapeo"
          placeholder="Nombre de mapeo"
          value={formik.values.nombre_mapeo}
          onChange={formik.handleChange}
          error={formik.errors.nombre_mapeo}
        />
      </Form.Group>
      {/* <Input
        type="text"
        placeholder="Buscar por nombre de variable..."
        value={searchTerm}
        onChange={handleChange}
        style={{ marginBottom: "1rem" }}
      /> */}

      {results.map((registro, index) => (
        <div key={index}>
          <div onClick={() => setShowFields(index === showFields ? -1 : index)}>
            <Segment padded>
              <label
                style={{
                  position: "absolute",
                  top: "10px",
                  left: "10px",
                  color: "black",
                  padding: "5px",
                }}
              >
                {datos[index]}{" "}
              </label>
            </Segment>
          </div>
          {index === showFields && (
            <Segment padded>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Registro</label>
                  <Input
                    name={`registro[${index}].registro`}
                    onChange={formik.handleChange}
                    value={formik.values.registro[index].registro}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Longitud</label>
                  <Input
                    name={`registro[${index}].longitud`}
                    onChange={formik.handleChange}
                    value={formik.values.registro[index].longitud}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>R_W</label>
                  <Input
                    name={`registro[${index}].r_w`}
                    onChange={formik.handleChange}
                    value={formik.values.registro[index].r_w}
                  />
                </Form.Field>

                <Form.Field>
                  <label>Tipo de dato</label>
                  <Input
                    name={`registro[${index}].tipo_dato`}
                    onChange={formik.handleChange}
                    value={formik.values.registro[index].tipo_dato}
                  />
                </Form.Field>

                <Form.Field>
                  <label>Unidad de medida</label>
                  <Input
                    name={`registro[${index}].unidad`}
                    onChange={formik.handleChange}
                    value={formik.values.registro[index].unidad}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Field>
                <label>Descripción</label>
                <Input
                  name={`registro[${index}].descripcion`}
                  onChange={formik.handleChange}
                  value={formik.values.registro[index].descripcion}
                />
              </Form.Field>
            </Segment>
          )}
        </div>
      ))}

      <Button
        type="submit"
        className="btn btn-danger"
        fluid
        content={Mapping ? "Actualizar" : "Crear"}
      />
    </Form>
  );
}

function initialValues(data) {
  const registros = [
    "IV_DEV",
    "GOV_RT",
    "GSV_RT",
    "NSV_RT",
    "VOLR_RT",
    "VOLP_RT",
    "FLOW_RT",
    "IV_TOT",
    "GOV_TOT",
    "GSV_TOT",
    "NSV_TOT",
    "TEMP_RT",
    "TEMP_AV",
    "PRES_RT",
    "PRES_AV",
    "GAPI_RT",
    "GAPI_AV",
    "BSW_RT",
    "DENS_RT",
    "DENS_AV",
    "DENR_AV",
    "CTL_RT",
    "CPL_RT",
    "CTPL_RT",
    "PUMP_ST",
    "GND_SW",
    "OVFL_SW",
    "EMER_SW",
    "PER4_SW",
    "PER5_SW",
    "SCADA_SW",
    "PER7_SW",
    "PER8_SW",
    "ORDEN_N",
    "PLACA_V",
    "PROMPT3",
    "PROMPT4",
    "PROMPT5",
    "BAY_NUMBER",
    "W_MAX_PRESET",
    "W_MAX_PRESET_VAL",
    "W_API",
    "W_API_VAL",
    "W_BSW",
    "W_BSW_VAL",
    "W_PER5",
    "MTFR_RT",
    "KFAC_RT",
    "PRESET_STATE",
    "RCU_STATE",
    "PRODUCT_ID",
  "MTR1_IV_DEV",
  "MTR1_GOV_RT",
  "MTR1_FLOW_RT",
  "MTR1_VOLP_RT",
  "MTR1_TEMP_RT",
  "MTR1_PRES_RT",
  "MTR1_GAPI_RT",
  "MTR1_DENS_RT",
  "MTR2_IV_DEV",
  "MTR2_GOV_RT",
  "MTR2_FLOW_RT",
  "MTR2_VOLP_RT",
  "MTR2_TEMP_RT",
  "MTR2_PRES_RT",
  "MTR2_GAPI_RT",
  "MTR2_DENS_RT"
  ];

  if (data) {
    // Si existe data, se asignan los valores de data a initialValues
    const initialValues = {
      nombre_mapeo: data.nombre_mapeo,
      registro: data.registro.map((r) => ({
        nombre_var: r.nombre_var,
        registro: r.registro,
        longitud: r.longitud,
        r_w: r.r_w,
        tipo_dato: r.tipo_dato,
        unidad: r.unidad,
        descripcion: r.descripcion,
      })),
    };

    // Asigna un valor vacío a los campos que no existen en data
    registros.forEach((r) => {
      if (
        !data.registro.some((d) => d.nombre_var === r) &&
        !initialValues.registro.some((i) => i.nombre_var === r)
      ) {
        initialValues.registro.push({
          nombre_var: r,
          registro: "",
          longitud: "",
          r_w: "",
          tipo_dato: "",
          unidad: "",
          descripcion: "",
        });
      }
    });

    return initialValues;
  }

  // Si no existe data, se retorna el objeto con valores por defecto
  return {
    nombre_mapeo: "",
    registro: registros.map((r) => ({
      nombre_var: r,
      registro: "",
      longitud: "",
      r_w: "",
      tipo_dato: "",
      unidad: "",
      descripcion: "",
    })),
  };
}

function newSchame() {
  return {
    nombre_mapeo: Yup.string().required(true),
  };
}

function updateSchame() {
  return {
    nombre_mapeo: Yup.string().required(true),
  };
}
