import React, { useEffect, useState } from "react";
import { Form, Input, Divider } from "semantic-ui-react";
import { useIngresoDatos } from "../../../hooks";
import { useAuth } from "../../../hooks";
import "./Styles/visualizacion.scss"
import {
  gallonsToLiters,
  barrelsToLiters,
  cubicMetersToLiters,
  litersToGallons,
  litersToBarrels,
  litersToCubicMeters,
  gallonsToBarrels,
  barrelsToGallons,
  cubicMetersToBarrels,
  barrelsToCubicMeters,
  cubicMetersToGallons,
  gallonsToCubicMeters,
} from "../../Comons/CalculoUnidades/Funciones";

export function VisualizacionDatosIngresadosForm(props) {
  /*
  Componente

  -componente de visualizacion del modal de visualizacion para datos calculados
  
 
  -se valida que no llegue vacio para que no haya errores
  -se obtiene los datos  mediante props desde su PageAdmin
   */
  const { auth } = useAuth();

  const bigStyle = {
    color: "red",
  };

  const { visualizacionDI } = props;
  const { ingresoDatoR, retriveIngresoDatos } = useIngresoDatos();

  useEffect(() => {
    retriveIngresoDatos(visualizacionDI.id_datos);
  }, []);

  const divStyle = {
    textAlign: "left",
  };

  
  const [fromUnit, setFromUnit] = useState(auth.me?.SistemasMedicion.volumen);
  const [toUnit, setToUnit] = useState(auth.me?.SistemasMedicion.volumen);

 


  const handleToSelectChange = (event) => {
    setToUnit(event.target.value);
  };

  const convertValue = (value, fromUnit, toUnit) => {
    if (fromUnit === "Gal" && toUnit === "l") {
      return gallonsToLiters(value);
    } else if (fromUnit === "bbl" && toUnit === "l") {
      return barrelsToLiters(value);
    } else if (fromUnit === "m3" && toUnit === "l") {
      return cubicMetersToLiters(value);
    } else if (fromUnit === "l" && toUnit === "Gal") {
      return litersToGallons(value);
    } else if (fromUnit === "l" && toUnit === "bbl") {
      return litersToBarrels(value);
    } else if (fromUnit === "l" && toUnit === "m3") {
      return litersToCubicMeters(value);
    } else if (fromUnit === "Gal" && toUnit === "bbl") {
      return gallonsToBarrels(value);
    } else if (fromUnit === "bbl" && toUnit === "Gal") {
      return barrelsToGallons(value);
    } else if (fromUnit === "m3" && toUnit === "bbl") {
      return cubicMetersToBarrels(value);
    } else if (fromUnit === "bbl" && toUnit === "m3") {
      return barrelsToCubicMeters(value);
    } else if (fromUnit === "m3" && toUnit === "Gal") {
      return cubicMetersToGallons(value);
    } else if (fromUnit === "Gal" && toUnit === "m3") {
      return gallonsToCubicMeters(value);
    } else {
      return value;
    }
  };

  const renderOptions = () => {
    return (
      <>
        
        <option value="Gal">Galones</option>
        <option value="l">Litros</option>
        <option value="bbl">Barriles</option>
        <option value="m3">MetroCubico</option>
      </>
    );
  };

  return (
    <>
      <div className="container-fluid ">
        <div className="row ">
          <div className="col-md-4"></div>
        </div>
        <div className="row ">
          <div className="col-md-6  ">
            <div className="card-header  ">
              <h4 className="card-title ">Datos Calculados</h4>
              {ingresoDatoR ? (
                <p className="card-category">
                  {" "}
                  Tanque <strong>{ingresoDatoR.NombreT} </strong>
                </p>
              ) : (
                ""
              )}

              {ingresoDatoR ? (
                <p className="card-category">
                  Capacidad{" "}
                  <strong>
                  {convertValue(ingresoDatoR.Capacidad, fromUnit, toUnit)}{" "}
                  <big style={bigStyle}>({toUnit.toUpperCase() === 'L' ? 'L' : toUnit === 'm3' ? 'm³' : toUnit=== 'Gal' ? 'gal' : toUnit})</big>{" "}
                  </strong>
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="card-body ">
              <div id="chartActivity" className="ct-chart"></div>
            </div>
            <div className="col-md-8 ">
              <Form.Group widths="equal" style={divStyle}>
                <Divider />

                {ingresoDatoR ? (
                  <Form.Field
                    fluid
                    control={Input}
                    label={
                      <strong>
                        Corrección por temperatura de lámina del tanque (CTSh)
                      </strong>
                    }
                    placeholder={
                      "Corrección por temperatura de lámina del tanque (CTSh)"
                    }
                    value={ingresoDatoR.CTSh}
                  />
                ) : (
                  ""
                )}

                {ingresoDatoR ? (
                  <Form.Field
                    fluid
                    control={Input}
                    label={<strong>Ajuste por techo flotante (FRA)</strong>}
                    placeholder={"Ajuste por techo flotante (FRA)"}
                    value={ingresoDatoR.FRA}
                  />
                ) : (
                  ""
                )}


              

                <Divider />
                {ingresoDatoR ? (
                  <Form.Field
                  fluid
                  control={Input}
                  label={<strong>Agua libre (FW)</strong>}
                  placeholder={"Agua libre (FW)"}
                  value={convertValue(parseFloat(ingresoDatoR.FW), fromUnit, toUnit) + " (" + (toUnit.toUpperCase() === 'L' ? 'L' : toUnit === 'm3' ? 'm³' : toUnit === 'gal' ? 'gal' : toUnit) + ")"}
                />
                ) : (
                  ""
                )}

                {ingresoDatoR ? (
                  <Form.Field
                    fluid
                    control={Input}
                    label={<strong>Volumen total observado (TOV)</strong>}
                    placeholder={"Volumen total observado (TOV)"}
                    value={convertValue(parseFloat(ingresoDatoR.TOV), fromUnit, toUnit)+ " (" + (toUnit.toUpperCase() === 'L' ? 'L' : toUnit === 'm3' ? 'm³' : toUnit === 'gal' ? 'gal' : toUnit) + ")"}
                  />
                ) : (
                  ""
                )}
                {ingresoDatoR ? (
                  <Form.Field
                    fluid
                    control={Input}
                    label={<strong>Volumen Bruto observado (GOV)</strong>}
                    placeholder={"Volumen Bruto observado (GOV)"}
                    value={convertValue(parseFloat(ingresoDatoR.GOV), fromUnit, toUnit)+ " (" + (toUnit.toUpperCase() === 'L' ? 'L' : toUnit === 'm3' ? 'm³' : toUnit === 'gal' ? 'gal' : toUnit) + ")"}
                  />
                ) : (
                  ""
                )}

                {ingresoDatoR ? (
                  <Form.Field
                    fluid
                    control={Input}
                    label={<strong>Volumen bruto estándar (GSV)</strong>}
                    placeholder={"Volumen bruto estándar (GSV)"}
                    value={convertValue(parseFloat(ingresoDatoR.GSV), fromUnit, toUnit)+ " (" + (toUnit.toUpperCase() === 'L' ? 'L' : toUnit === 'm3' ? 'm³' : toUnit === 'gal' ? 'gal' : toUnit) + ")"}
                  />
                ) : (
                  ""
                )}
                {ingresoDatoR ? (
                  <Form.Field
                    fluid
                    control={Input}
                    label={<strong>Volumen neto estándar (NSV)</strong>}
                    placeholder={"Volumen neto estándar (NSV)"}
                    value={convertValue(parseFloat(ingresoDatoR.NSV), fromUnit, toUnit)+ " (" + (toUnit.toUpperCase() === 'L' ? 'L' : toUnit === 'm3' ? 'm³' : toUnit === 'gal' ? 'gal' : toUnit) + ")"}
                  />
                ) : (
                  ""
                )}
              </Form.Group>
            </div>
          </div>
          <div className="col-md-6 ">
          <div className="form-group col-md-6">
                <label htmlFor="toUnitSelect" style={bigStyle}><strong>Convertir A:</strong></label>
                <select
                  defaultValue=""
                  className="form-control custom-select-style" // Agregamos la clase "custom-select-style"
                  id="toUnitSelect"
                  value={toUnit}
                  onChange={handleToSelectChange}
                >
                  {renderOptions()}
                </select>
              </div>
              <br/>
            <div className="card  card-tasks ">
              <div className="card-header  ">
                <h4 className="card-title ">Tarea&nbsp; </h4>
                monitoreo del tanque{" "}
                <strong>{/* {visualizacionDI.nombre_tanque}*/}</strong>
              </div>
              <div className="card-body  colores">
                <section>
                  <iframe
                    width="100%"
                    height="450px"
                    src="http://192.168.0.254:8080/tq1.htm"
                    title="codesys"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  ></iframe>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
