
import React, { useCallback, useState } from "react";
import Swal from "sweetalert2";
import { AddEditIslas } from "../../../Components/Client";
import { useIslas } from "../../../hooks";
import { columns } from "./IslaFomat";

export function Funciones() {
  const { deleteIslas } = useIslas();
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);


  const handleAdd = useCallback(() => {
    setTitleModal("Nuevo Islas");
    setContentModal(
      <AddEditIslas onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);



  const handleUpdate = useCallback((data) => {
    setTitleModal("Actualizar Islas");
    setContentModal(
      <AddEditIslas
        onClose={openCloseModal}
        onRefetch={onRefetch}
        Islas={data}
      />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);



  const handleDelete = useCallback(async (data) => {
    const result = await Swal.fire({
      title: "Estas seguro?",
      text: "No podrás revertir esta acción!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si, eliminar!",
      cancelButtonText: "No, cancelar!",
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      await deleteIslas(data.id_isla);
      Swal.fire("Eliminado!", "El dato ha sido eliminado.", "success");
      onRefetch();
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelado", "Operación cancelada", "error");
    }
  }, [deleteIslas, onRefetch]);




  return {
    handleAdd,
    handleDelete,
    handleUpdate,
    openCloseModal,
    columns,
    refetch,
    contentModal,
    titleModal,
    showModal,
  };
}
