import { Formik, useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

import { Autocomplete } from '@mui/material';
import Tooltip from '@material-ui/core/Tooltip';
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField';
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';

import * as Yup from "yup";
import { useOperador } from "../../../hooks";
import { alertas } from "../../Comons/Alertas/alertas";
import Swal from 'sweetalert2';

export function AddEditOperadores(props) {
  /*
  Componente

  -componente de visualizacion del modal de edicion y creacion para Operadores
  
  -se crea y valida mediante formik y yup
  -Se obtienen datos para dropdown mediante hooks
  -se obtiene los datos (para edicion) y funciones correspondientes mediante props desde su PageAdmin
   */
  const { onClose, onRefetch, Operador } = props;
  const { addOperador, updateOperador } = useOperador();
  const { Successful } = alertas();
  const [previewImage, setPreviewImage] = useState(
    Operador ? Operador?.foto : null
  );

  // diccionario para establecer los errores de los campos Autocomplete
  const [errors, setErrors] = React.useState({
    tipo_id: false,
    funcion: false
  });

  // const [error, setError] = useState(false)
  // const [error2, setError2] = useState(false)
  const [id_as_pass, setIdAsPass] = useState(false)

  //console.log(props.Operador)

  const formik = useFormik({
    // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
    initialValues: initialValues(Operador),
    // Establece la validación del esquema utilizando Yup, si se proporciona un objeto  se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
    // validationSchema: Yup.object(Operador ? updateSchame() : newSchame()),
    // Desactiva la validación al cambiar los valores del formulario
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        // Si se proporciona un objeto se actualiza, de lo contrario se crea uno nuevo
        if (formValue.funcion === "op_maq") {
          formValue.ctrl_login_pass = ""
          formValue.is_active = false
          setIdAsPass(false)
        }

        if (Operador) await updateOperador(Operador.id_operador, formValue);
        else await addOperador(formValue);
        Successful()
        onRefetch();
        onClose();

      } catch (error) {
        // Muestra un mensaje de error si ocurre algún problema
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });

  // función que establece la imagen en el formulario
  const onDrop = useCallback(async (acceptedFile) => {
    const file = acceptedFile[0] ? acceptedFile[0] : acceptedFile; // cuando se selecciona la imagen el archivo viende dentro de un array, cuando se toma la foto no
    await formik.setFieldValue("foto", file);

    setPreviewImage(URL.createObjectURL(file)); // crea una url del archivo para mostrarlo en el navegador

  }, []);

  // se usa cuando la imagen es seleccionada
  const { getRootProps, getInputProps } = useDropzone({
    accept: "foto/jpeg, foto/png, foto/jpg",
    noKeyboard: true,
    multiple: false,
    onDrop,
  });

  const divStyle = {
    textAlign: "left",
  };

  const imagenStyle = {
    width: "300px",
    margin: "0 auto"
  }

  // maneja el cambio en el autocomplete
  useEffect(() => {
    if (!id_as_pass && !props?.Operador?.ctrl_login_pass) {
      formik.setFieldValue("ctrl_login_pass", "")
    }
  }, [id_as_pass])

  const handleAutocompleteChange = (name, value) => {
    formik.setFieldValue(name, value?.value || '');
    setErrors((prevErrors) => ({ ...prevErrors, [name]: !value }));
  };
  
  // genera un nombre aleatorio para ponerle al archivo generado cuando se toma la foto
  const generateRandomName = () => {
    //  genera un string aleatorio
    const randomString = Math.random().toString(36).substring(2, 8);
    // genera una marca de tiempo 
    const timestamp = Date.now();
    // combina ambas cosas para generar el nombre del archivo
    const fileName = `${randomString}_${timestamp}.png`;
    return fileName;
  };

  // función para tomar la foto, abre un stream de video y toma la foto
  const takeSnapshot = () => {
    // crear un elemento de video
    const video = document.createElement('video');

    // accede a la cámara del pc y pide autorización para iniciar la transmisión del video
    navigator.mediaDevices.getUserMedia({ video: true })
      .then((stream) => {
        video.srcObject = stream; // crea el objeto de video
        video.play(); // lo inicia, es decir inicia la cámara para que empiece a transmitir imágenes

        // aplica las propiedades posteriores cuando el video empieza a transmitirse
        video.onloadedmetadata = () => {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');

          // crea un área plana
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;

          // toma el "pantallazo" de la transmisión, es decir toma la foto
          context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

          // convierte la imagen tomada en un archivo y se pasa a la función para que se establezca como la foto del formulario
          canvas.toBlob((blob) => {
            const fileName = generateRandomName();
            const file = new File([blob], fileName, { type: 'image/png' });

            console.log(file)
            formik.setFieldValue('foto', file);
            onDrop(file);
          }, 'image/png');

          //setPreviewImage(imageBase64);

          // detiene la transmisión y apaga la foto
          video.srcObject.getTracks().forEach((track) => track.stop());
        };
      })
      .catch((error) => { // si no se autoriza entonces arroja el siguiente error
        toast.error('Error: No se pudo acceder a la cámara. Asegúrese de permitir el acceso a la cámara.');
      });
  };

  // meétodo para toma la foto desde el botón
  const handleClick = () => {
    takeSnapshot();
  };

  return (
    <Formik initialValues={initialValues()}>
      <>
        <form className="add-edit-secciones-form" style={divStyle} onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            {/* fila 1 */}
            <Grid item xs={12} sm={4}>
              <Autocomplete
                id="tipo"
                options={IdentificacionOptions}
                getOptionLabel={(option) => option.text || ''}
                value={IdentificacionOptions.find((option) => option.value === formik.values.tipo_id) || null}
                onChange={(_, value) => handleAutocompleteChange('tipo_id', value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tipo identificación"
                    variant="outlined"
                    error={errors.tipo_id}
                    helperText={errors.tipo_id ? 'Debe seleccionar el tipo de documento' : ''}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={8}>
              <TextField
                required
                fullWidth
                label="Número de identificación"
                name="n_documento"
                placeholder="Identificación"
                value={formik.values.n_documento}
                onChange={formik.handleChange}
                error={formik.touched.n_documento && Boolean(formik.errors.n_documento)}
                type="number"
              />
            </Grid>

            {/* fila 2 */}
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Nombres"
                name="nombre"
                placeholder="Nombres"
                value={formik.values.nombre}
                onChange={formik.handleChange}
                error={formik.touched.nombre && Boolean(formik.errors.nombre)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Apellidos"
                name="apellidos"
                placeholder="Apellidos"
                value={formik.values.apellidos}
                onChange={formik.handleChange}
                error={formik.touched.apellidos && Boolean(formik.errors.apellidos)}
              />
            </Grid>

            {/* fila 3 */}
            <Grid item xs={12} sm={12}>
              <TextField
                required
                fullWidth
                label="Email"
                name="email"
                placeholder="JohnDoe@mail.com"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                type="email"
              />
            </Grid>

            {/* fila 4 */}
            <Grid item xs={12} sm={4}>
              <Button
                type="button"
                fullWidth
                color={formik.errors.foto && "red"}
                style={{
                  color: 'rgb(208, 48, 43)',
                  border: '1px solid rgb(208, 48, 43)',
                  '&:hover': {
                    color: 'rgba(208, 48, 43, 0.62)',
                    border: '1px solid rgba(221, 223, 221, 0.62)',
                  }
                }}
                {...getRootProps()}
              >
                {previewImage ? "Cambiar imagen" : "Subir imagen"}
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                type="button"
                fullWidth

                style={{
                  color: 'rgb(208, 48, 43)',
                  border: '1px solid rgb(208, 48, 43)',
                  '&:hover': {
                    color: 'rgba(208, 48, 43, 0.62)',
                    border: '1px solid rgba(221, 223, 221, 0.62)',
                  }
                }}
                // {...getRootProps()}
                onClick={() => {
                  handleClick()
                }}
              >
                Tomar foto
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Paper variant="outlined">
                <input {...getInputProps()} />
                <img
                  style={{
                    ...imagenStyle,
                    maxWidth: '100%',
                    maxHeight: '250px',
                  }}
                  src={previewImage}
                  fluid
                />
              </Paper>
            </Grid>

            {/* fila 6 */}
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                name="fecha_nacimiento"
                value={formik.values.fecha_nacimiento}
                onChange={formik.handleChange}
                error={formik.touched.fecha_nacimiento && Boolean(formik.errors.fecha_nacimiento)}
                type="date"
                helperText="Fecha de nacimiento"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Celular"
                name="celular"
                placeholder="Celular"
                value={formik.values.celular}
                onChange={formik.handleChange}
                error={formik.touched.celular && Boolean(formik.errors.celular)}
                type="number"
              />
            </Grid>

            {/* fila 7 */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Teléfono"
                name="telefono"
                placeholder="Teléfono"
                value={formik.values.telefono}
                onChange={formik.handleChange}
                error={formik.touched.telefono && Boolean(formik.errors.telefono)}
                type="number"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="EPS"
                name="seguro"
                placeholder="EPS"
                value={formik.values.seguro}
                onChange={formik.handleChange}
                error={formik.touched.seguro && Boolean(formik.errors.seguro)}
              />
            </Grid>

            {/* fila 8 */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Área"
                name="area"
                placeholder="Área"
                value={formik.values.area}
                onChange={formik.handleChange}
                error={formik.touched.area && Boolean(formik.errors.area)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                fullWidth
                id="op"
                options={OpFuncOptions}
                getOptionLabel={(option) => option.text || ''}
                value={OpFuncOptions.find((option) => option.value === formik.values.funcion) || null}
                onChange={(_, value) => handleAutocompleteChange('funcion', value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tipo de operario"
                    variant="outlined"
                    error={errors.funcion}
                    helperText={errors.funcion ? 'Debe seleccionar el tipo de operador' : ''}
                  />
                )}
              />
            </Grid>

            {/* fila 9 */}
            {formik.values.funcion === "op_comp" ?
              (
                <>
                  <Grid item xs={6} sm={4}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formik.values.is_active}
                          onChange={(_, checked) => formik.setFieldValue("is_active", checked)}
                          color="primary"
                        />
                      }
                      label="¿Operario activo?"
                    />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={id_as_pass}
                          onChange={(_, checked) => {
                            if (formik.values.n_documento) {
                              setIdAsPass(checked);
                              formik.setFieldValue("ctrl_login_pass", formik.values.n_documento);
                            } else {
                              setIdAsPass(!checked);
                              Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Por favor, ingrese el documento primero',
                              });
                            }
                          }}
                          color="primary"
                        />
                      }
                      label="¿Usar id como código?"
                    />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <TextField
                      fullWidth
                      label="Código de acceso para computador"
                      name="ctrl_login_pass"
                      placeholder="Código de acceso para computador"
                      value={formik.values.ctrl_login_pass}
                      onChange={formik.handleChange}
                      error={formik.touched.ctrl_login_pass && Boolean(formik.errors.ctrl_login_pass)}
                      required={formik.values.funcion === "op_comp" ? true : false}
                    />
                  </Grid>
                </>
              ) : (<></>)
            }

            {/* fila 10 */}
            <Grid item xs={12} sm={12}>
              <Paper elevation={3} style={{ padding: 20, marginBottom: 20 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h5">Datos extra del operador</Typography>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <>
                      {formik.values.documentos.map((variable, index) => (
                        <>
                          <Grid container spacing={2} key={index}>
                            <Grid item xs={5}>
                              <TextField
                                required
                                fullWidth
                                label={`Tipo ${index + 1}`}
                                name={`documentos[${index}].tipo`}
                                placeholder="Tipo"
                                value={variable.tipo}
                                onChange={formik.handleChange}
                                error={formik.touched[`documentos[${index}].tipo`] && Boolean(formik.errors[`documentos[${index}].tipo`])}
                              />
                            </Grid>
                            <Grid item xs={5}>
                              <TextField
                                required
                                fullWidth
                                label={`Número de documento ${index + 1}`}
                                name={`documentos[${index}].numeroDoc`}
                                placeholder="Número"
                                value={variable.numeroDoc}
                                onChange={formik.handleChange}
                                error={formik.touched[`documentos[${index}].numeroDoc`] && Boolean(formik.errors[`documentos[${index}].numeroDoc`])}
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <Tooltip title="Eliminar dato extra" arrow>
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  style={{
                                    height: '100%', display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '0',
                                    margin: '0',
                                    color: 'rgba(252, 0, 0, 0.8)',
                                    border: '1px solid rgba(252, 0, 0, 0.8)',
                                  }}
                                  onClick={() => {
                                    const updatedDocumentos = [...formik.values.documentos];
                                    updatedDocumentos.splice(index, 1);
                                    formik.setFieldValue('documentos', updatedDocumentos);
                                  }}
                                >
                                  <DeleteIcon style={{ margin: '0' }} />
                                </Button>
                              </Tooltip>
                            </Grid>
                          </Grid>
                          <br />
                        </>
                      ))}
                    </>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Tooltip title="Agregar dato extra" arrow>
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  style={{
                    color: 'rgb(208, 48, 43)',
                    border: '1px solid rgb(208, 48, 43)',
                    '&:hover': {
                      color: 'rgba(221, 223, 221, 0.62)',
                      border: '1px solid rgba(221, 223, 221, 0.62)',
                    }
                  }}
                  onClick={() =>
                    formik.setFieldValue('documentos', [
                      ...formik.values.documentos,
                      { tipo: '', numeroDoc: '' },
                    ])
                  }
                >
                  <AddIcon />
                </Button>
              </Tooltip>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Button type="submit" className="btn btn-danger" color="error" variant="contained" fullWidth>
                {Operador ? "Actualizar" : "Crear"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </>
    </Formik>
  );
}


function initialValues(data) {
  return {
    foto: null,
    tipo_id: data?.tipo_id || "",
    nombre: data?.nombre || "",
    apellidos: data?.apellidos || "",
    n_documento: data?.n_documento || "",
    seguro: data?.seguro || "",
    celular: data?.celular || "",
    telefono: data?.telefono || "",
    email: data?.email || "",
    fecha_nacimiento: data?.fecha_nacimiento || "",
    area: data?.area || "",
    documentos: data?.documentos || [],
    funcion: data?.funcion || "",
    is_active: data?.is_active || false,
    ctrl_login_pass: data?.ctrl_login_pass || "",
  };
}

function newSchame() {
  return {
    tipo_id: Yup.number().required(
      "El campo de numero tipo identificacion es requerido"
    ),
    nombre: Yup.string().required("El campo Nombre es requerido"),
    apellidos: Yup.string().required("El campo Apellidos es requerido"),
    n_documento: Yup.string()
      .required("El campo es requerido")
      .matches(/^[0-9]+$/, "Deben ser digitos"),
    seguro: Yup.string(),
    celular: Yup.string()
      .required("El campo Celular es requerido")
      .matches(/^[0-9]+$/, "Deben ser digitos")
      .min(5, "La longitud debe ser mayor que 5")
      .max(16, "La longitud debe ser menor que 16"),
    telefono: Yup.string("El dato debe ser numerico")
      .matches(/^[0-9]+$/, "Deben ser digitos")
      .min(5, "La longitud debe ser mayor que 5")
      .max(16, "La longitud debe ser menor que 16"),


    foto: Yup.mixed(),
    email: Yup.string()
      .email("Ingrese un Email valido")
      .required("El Email es requerido"),
    area: Yup.string(),
    funcion: Yup.string(),
  };
}

function updateSchame() {
  return {
    tipo_id: Yup.number().required(
      "El campo de numero tipo identificacion es requerido"
    ),
    nombre: Yup.string().required("El campo Nombre es requerido"),
    apellidos: Yup.string().required("El campo Apellidos es requerido"),
    n_documento: Yup.string()
      .required("El campo es requerido")
      .matches(/^[0-9]+$/, "Deben ser digitos"),

    celular: Yup.string()
      .required("El campo Celular es requerido")
      .matches(/^[0-9]+$/, "Deben ser digitos")
      .min(5, "La longitud debe ser mayor que 5")
      .max(16, "La longitud debe ser menor que 16"),
    telefono: Yup.string("El dato debe ser numerico")
      .matches(/^[0-9]+$/, "Deben ser digitos")
      .min(5, "La longitud debe ser mayor que 5")
      .max(16, "La longitud debe ser menor que 16"),
    fecha_nacimiento: Yup.date(),

    foto: Yup.mixed(),
    email: Yup.string()
      .email("Ingrese un Email valido")
      .required("El Email es requerido"),
    area: Yup.string(),
    funcion: Yup.string(),
  };
}



const OpFuncOptions = [
  {
    key: "op_comp",
    text: "Operario de computadores",
    value: "op_comp"
  },
  {
    key: "op_maq",
    text: "Operario de maquinaria",
    value: "op_maq"
  }
]

const IdentificacionOptions = [
  {
    key: 1,
    text: "Cedula",
    value: 1,
  },
  {
    key: 2,
    text: "Pasaporte",
    value: 2,
  },
];
