import styled from "styled-components";

export const ContainerInput = styled.div`
  display: grid;
  grid-template-columns: 0.8fr 1.2fr;
  grid-gap: 20px;
  margin-top: 8px;
  label {
    text-align: center;
  }
`;
