import { useFormik } from "formik";
import React from "react";
import { toast } from "react-toastify";
import {
  Button,
  Form,
  Input,
  
} from "semantic-ui-react";
import * as Yup from "yup";
import { useTransacciones } from "../../../../hooks";
import { alertas } from "../../../Comons/Alertas/alertas";
export function AddEditTransacciones(props) {
  /*
  Componente

  -componente de visualizacion del modal de edicion y creacion para Transacciones
  
  -se crea y valida mediante formik y yup
  -Se obtienen datos para dropdown mediante hooks
  -se obtiene los datos (para edicion) y funciones correspondientes mediante props desde su PageAdmin
   */
  const { onClose, onRefetch, Transacciones } = props;
  const { addTransacciones, updateTransacciones } = useTransacciones();

  const { Successful } = alertas();

  const formik = useFormik({
    // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
    initialValues: initialValues(Transacciones),
    // Establece la validación del esquema utilizando Yup, si se proporciona un objeto  se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
    validationSchema: Yup.object(Transacciones ? updateSchame() : newSchame()),
     // Desactiva la validación al cambiar los valores del formulario
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
         // Si se proporciona un objeto se actualiza, de lo contrario se crea uno nuevo
        if (Transacciones)
          await updateTransacciones(Transacciones.id_transaccion, formValue);
        else await addTransacciones(formValue);
        Successful()
        onRefetch();
        onClose();
      } catch (error) {
        // Muestra un mensaje de error si ocurre algún problema
        toast.error(error.message);
      }
    },
  });

  const divStyle = {
    textAlign: "left",
  };
  return (
    <Form
      className="add-edit-secciones-form"
      style={divStyle}
      onSubmit={formik.handleSubmit}
    >
      <Form.Group widths="equal" style={divStyle}>
        <Form.Field
          fluid
          control={Input}
          label="Fecha de creacion"
          name="time_created"
          placeholder="Fecha creacion"
          value={formik.values.time_created}
          error={formik.errors.time_created}
          onChange={formik.handleChange}
          type="date"
        />

        <Form.Field
          fluid
          control={Input}
          label="Numero de la orden"
          name="n_orden"
          placeholder="Numero de orden"
          value={formik.values.n_orden}
          onChange={formik.handleChange}
          error={formik.errors.n_orden}
          type="number"
        />
      </Form.Group>

      <Form.Group widths="equal" style={divStyle}>
        <Form.Field
          fluid
          control={Input}
          label="Volumen restante"
          name="v_restante"
          placeholder="Volumen restante"
          value={formik.values.v_restante}
          onChange={formik.handleChange}
          error={formik.errors.v_restante}
          type="number"
        />

        <Form.Field
          fluid
          control={Input}
          label="Volumen entregado"
          name="v_entregado"
          placeholder="Volumen entregado"
          value={formik.values.v_entregado}
          error={formik.errors.v_entregado}
          onChange={formik.handleChange}
          type="number"
        />
        <Form.Field
          fluid
          control={Input}
          label="Volumen gsv"
          name="vol_gsv"
          placeholder="GSV"
          value={formik.values.vol_gsv}
          onChange={formik.handleChange}
          error={formik.errors.vol_gsv}
          type="number"
        />
        <Form.Field
          fluid
          control={Input}
          label="Volumen gov"
          name="vol_gov"
          placeholder="GOV"
          value={formik.values.vol_gov}
          onChange={formik.handleChange}
          error={formik.errors.vol_gov}
          type="number"
        />
      </Form.Group>


      <Form.Group widths="equal" style={divStyle}>
        <Form.Field
          fluid
          control={Input}
          label="Operador de bahia"
          name="operador_bahia"
          placeholder="Operador de bahia"
          value={formik.values.operador_bahia}
          error={formik.errors.operador_bahia}
          onChange={formik.handleChange}
          type="number"
        />

        <Form.Field
          fluid
          control={Input}
          label="Numero de  bahia"
          name="num_bahia"
        placeholder="Numero de bahia"
        value={formik.values.num_bahia}
        onChange={formik.handleChange}
        error={formik.errors.num_bahia}
        />
      </Form.Group>

      <Form.Group widths="equal" style={divStyle}>
        <Form.Field
          fluid
          control={Input}
          label="Presion promedio"
          name="pres_promedio"
        placeholder="presion Promedio"
        value={formik.values.pres_promedio}
        onChange={formik.handleChange}
        error={formik.errors.pres_promedio}
        type="number"
        />

        <Form.Field
          fluid
          control={Input}
          label="Temperatura promedio"
          name="temp_promedio"
        placeholder="Temperatura promedio"
        value={formik.values.temp_promedio}
        error={formik.errors.temp_promedio}
        onChange={formik.handleChange}
        type="number"
        />

<Form.Field
          fluid
          control={Input}
          label="Densidad promedio"
          name="densidad_promedio"
        placeholder="Densidad promedio"
        value={formik.values.densidad_promedio}
        onChange={formik.handleChange}
        error={formik.errors.densidad_promedio}
        type="number"
        />

<Form.Field
          fluid
          control={Input}
          label="MeterFactor"
          name="meter_factor"
        placeholder="MeterFactor"
        value={formik.values.meter_factor}
        onChange={formik.handleChange}
        error={formik.errors.meter_factor}
        type="number"
        />

      </Form.Group>

      <Form.Group widths="equal" style={divStyle}>
        <Form.Field
          fluid
          control={Input}
          label="CTL"
          name="ctl"
        placeholder="CTL"
        value={formik.values.ctl}
        error={formik.errors.ctl}
        onChange={formik.handleChange}
        type="number"
        />

        <Form.Field
          fluid
          control={Input}
          label="CPL"
          name="cpl"
        placeholder="CPL"
        value={formik.values.cpl}
        onChange={formik.handleChange}
        error={formik.errors.cpl}
        type="number"
        />

<Form.Field
          fluid
          control={Input}
          label="CTPL"
          name="ctpl"
        placeholder="CTPL"
        value={formik.values.ctpl}
        onChange={formik.handleChange}
        error={formik.errors.ctpl}
        type="number"
        />

<Form.Field
          fluid
          control={Input}
          label="BSW"
          name="bsw"
        placeholder="BWS"
        value={formik.values.bsw}
        error={formik.errors.bsw}
        onChange={formik.handleChange}
        type="number"
        />

<Form.Field
          fluid
          control={Input}
          label="Gravedad API"
          name="gravedad_api"
        placeholder="Gravedad API"
        value={formik.values.gravedad_api}
        onChange={formik.handleChange}
        error={formik.errors.gravedad_api}
        type="number"
        />

<Form.Field
          fluid
          control={Input}
          label="NSV"
          name="nsv"
        placeholder="NSV"
        value={formik.values.nsv}
        onChange={formik.handleChange}
        error={formik.errors.nsv}
        type="number"
        />

      </Form.Group>

      
      <Form.Field
          fluid
          control={Input}
          label="Numero de transaccion"
          name="num_transaccion"
        placeholder="Numero de transaccion"
        value={formik.values.num_transaccion}
        onChange={formik.handleChange}
        error={formik.errors.num_transaccion}
        type="number"
        />
     

<Form.Group widths="equal" >
        

<Form.Field
          fluid
          control={Input}
          label="Totalizer inicio"
          name="totalizer_init"
        placeholder="Totalizer inicio"
        value={formik.values.totalizer_init}
        error={formik.errors.totalizer_init}
        onChange={formik.handleChange}
        type="number"
        />

<Form.Field
          fluid
          control={Input}
          label="MeterFactor"
          name="totalizer final"
        placeholder="Totalizer fin"
        value={formik.values.totalizer_end}
        onChange={formik.handleChange}
        error={formik.errors.totalizer_end}
        type="number"
        />

      </Form.Group>

      <Form.Group widths="equal" >
        

<Form.Field
          fluid
          control={Input}
          label="Totalizer inicio cargue"
          name="fecha_initcargue"
          placeholder="Fecha Inicio Cargue"
          value={formik.values.fecha_initcargue}
          onChange={formik.handleChange}
          error={formik.errors.fecha_initcargue}
          type="date"
        />

<Form.Field
          fluid
          control={Input}
          label="Fecha finalizacion cargue"
          name="fecha_endcargue"
          placeholder="Fecha finalizacion cargue"
          value={formik.values.fecha_endcargue}
          onChange={formik.handleChange}
          error={formik.errors.fecha_endcargue}
          type="date"
        />

      </Form.Group>
      
    
      <Button
        type="submit"
        // primary
        className="btn btn-danger"
        fluid
        content={Transacciones ? "Actualizar" : "Crear"}
      />
    </Form>
  );
}

function initialValues(data) {
  return {
    time_created: data?.time_created || "",
    n_orden: data?.n_orden || "",
    v_restante: data?.v_restante || "",
    v_entregado: data?.v_entregado || "",
    vol_gsv: data?.vol_gsv || "",
    vol_gov: data?.vol_gov || "",
    operador_bahia: data?.operador_bahia || "",
    num_bahia: data?.num_bahia || "",
    pres_promedio: data?.pres_promedio || "",
    temp_promedio: data?.temp_promedio || "",
    densidad_promedio: data?.densidad_promedio || "",
    meter_factor: data?.meter_factor || "",
    ctl: data?.ctl || "",
    cpl: data?.cpl || "",
    ctpl: data?.ctpl || "",
    bsw: data?.bsw || "",
    gravedad_api: data?.gravedad_api || "",
    num_transaccion: data?.num_transaccion || "",
    totalizer_init: data?.totalizer_init || "",
    totalizer_end: data?.totalizer_end || "",
    fecha_initcargue: data?.fecha_initcargue || "",
    nivel: data?.nivel || "",
    fecha_endcargue: data?.fecha_endcargue || "",
    nsv: data?.nsv || "",
  };
}

function newSchame() {
  return {
    time_created: Yup.date().required(true),
    n_orden: Yup.number().required(true),
    v_restante: Yup.number().required(true),
    v_entregado: Yup.number().required(true),
    vol_gsv: Yup.number().required(true),
    vol_gov: Yup.number().required(true),
    operador_bahia: Yup.number().required(true),
    num_bahia: Yup.string().required(true),
    pres_promedio: Yup.number().required(true),
    temp_promedio: Yup.number().required(true),
    densidad_promedio: Yup.number().required(true),
    meter_factor: Yup.number().required(true),
    ctl: Yup.number().required(true),
    cpl: Yup.number().required(true),
    ctpl: Yup.number().required(true),
    bsw: Yup.number().required(true),
    gravedad_api: Yup.number().required(true),
    num_transaccion: Yup.number().required(true),
    totalizer_init: Yup.number().required(true),
    totalizer_end: Yup.number().required(true),
    fecha_initcargue: Yup.date().required(true),
    fecha_endcargue: Yup.date().required(true),
    nsv: Yup.number().required(true),
  };
}

function updateSchame() {
  return {
    time_created: Yup.date().required(true),
    n_orden: Yup.number().required(true),
    v_restante: Yup.string().required(true),
    v_entregado: Yup.number().required(true),
    vol_gsv: Yup.number().required(true),
    vol_gov: Yup.number().required(true),
    operador_bahia: Yup.number().required(true),
    num_bahia: Yup.string().required(true),
    pres_promedio: Yup.number().required(true),
    temp_promedio: Yup.number().required(true),
    densidad_promedio: Yup.number().required(true),
    meter_factor: Yup.number().required(true),
    ctl: Yup.number().required(true),
    cpl: Yup.number().required(true),
    ctpl: Yup.number().required(true),
    bsw: Yup.number().required(true),
    gravedad_api: Yup.number().required(true),
    num_transaccion: Yup.number().required(true),
    totalizer_init: Yup.number().required(true),
    totalizer_end: Yup.number().required(true),
    fecha_initcargue: Yup.date().required(true),
    fecha_endcargue: Yup.date().required(true),
    nsv: Yup.number().required(true),
  };
}
