import React from "react";
import "./PaginaAviso.scss";

export function PaginaAviso() {
  return (
    <div className="paginaAviso">
      <div className="paginaAviso-content">
        <h1>Se ha enviado un correo de confirmación al email</h1>
        <p>
          Por favor revisa tu bandeja de entrada para continuar con el proceso
        </p>
      </div>
    </div>
  );
}
