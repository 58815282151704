import { useFormik, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Form, Input, Select, Label } from "semantic-ui-react";
import * as Yup from "yup";
import { alertas } from "../../Comons/Alertas/alertas";
import { useConsumo, useTanques, useMaquinas, useVehiculos, useControladores, useTcs3000 } from "../../../hooks";
import { tanqueSelectWProd, tcsSelect, tcsProdSelect } from "../../Comons"
import { useAuth } from "../../../hooks";
import { Loader } from "semantic-ui-react";
import { ASGI_API } from "../../../../src/vistas/utils/constants"
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'; // Import Material-UI components

export function AddEditAsignacionTcs(props) {
    const { auth } = useAuth()
    const { Successful } = alertas();
    const { onClose, onRefetch, Consumo } = props;
    const { addConsumoWTcs, loadingTcsOp, respTicket } = useConsumo()
    const { TanquesCI, getTanquesCI } = useTanques()
    const { Maquinas, getMaquinas } = useMaquinas()
    const { VehiculosDB, getVehiculosDB } = useVehiculos()
    const { CtrlRegCom, getControladorRegComb } = useControladores()
    const { products, getTcsProd } = useTcs3000()
    const [dataTanqueCI, setTanqueCI] = useState([])
    const [tcsModels, setTcsModels] = useState([])
    const [existsInMaquinas, setExistInMaquina] = useState([])
    const [existsInVehiculos, setExistsInVehiculos] = useState([])
    const [desplazamientoMaq, setDesplazamientoMaq] = useState([])
    const [desplazamientoVeh, setDesplazamientoVeh] = useState([])
    const [condOrOp, setCondOrOp] = useState([])
    const [condOrOpId, setCondOrOpId] = useState([])
    const [placaExists, setPlacaExists] = useState(false);
    const [tcsProds, setTcsProds] = useState([])
    const [relationType, setRelationType] = useState([])
    const [relationFlag, setRelationFlag] = useState("tanque")
    const [ws, setWs] = useState(null);
    const [pulsos, setPulsos] = useState([])
    const [isFinished, setIsFinished] = useState(false)

    useEffect(() => {
        getMaquinas()
        getVehiculosDB()
        getControladorRegComb()
        getTcsProd()
    }, [])

    useEffect(() => {
        setTcsModels(tcsSelect(CtrlRegCom))
        setTcsProds(tcsProdSelect(products))
    }, [CtrlRegCom, products])

    // useEffect(() => {
    //     // Establece la conexión al web socket
    //     const websocket = new WebSocket(`${ASGI_API}/data/getPulsos`); // url de donde está el web socket

    //     setWs(websocket); // establece el web socket en una variable global para que sea accedida luego

    //     websocket.onopen = () => {
    //         console.log("Conexión con el WS abierta");
    //     };

    //     websocket.onclose = () => {
    //         console.log("Conexión con el WS cerrada");
    //     };

    //     return () => {
    //         // Cierra la conexión cuando el componente se cierra o se recarga
    //         if (ws) {
    //             ws.close();
    //         }
    //     };
    // }, []);

    const formik = useFormik({
        initialValues: initialValues(Consumo, auth.me.SistemasMedicion.volumen, { placa_obj: '' }),
        validationSchema: Yup.object(Consumo),
        validateOnChange: false,
        onSubmit: async (formValue) => {

            try {

                if (!formValue.id_conductor) {
                    throw new Error("El vehículo o máquina debe estar asociado con un conductor");
                } else {
                    formValue.tipo_operacion = "auto"

                    formValue.medida_consumo = auth.me.SistemasMedicion.volumen
                    formValue.id_operador = auth.me.id_usuario

                    switch (formValue.desplazamiento) {
                        case "Estacionario":
                            formValue.trabajo_act = "0"
                            break
                        case "Móvil":
                            formValue.hrs_act = "0"
                            break
                        case "Móvil - Grandes distancias":
                            formValue.hrs_act = "0"
                            break
                    }

                    console.log(formValue)

                    // if (ws) {
                    //     // Subscribe to WebSocket messages before sending data
                    //     ws.onmessage = (event) => {
                    //         const receivedData = JSON.parse(event.data);
                    //         setPulsos(receivedData)
                    //         console.log("Pulsos desde el web socket", receivedData);
                    //         // Handle received data as needed
                    //     };
                    // }

                    // await addConsumoWTcs(formValue);

                    // setIsFinished(true)

                    // if (!loadingTcsOp) {
                    //     ws.close()
                    // }

                    // onClose()

                    //Si se proporciona un objeto se actualiza, de lo contrario se crea uno nuevo

                    // onRefetch();
                    // // onClose();
                    // Successful()
                }
            } catch (error) {
                // Muestra un mensaje de error si ocurre algún problema
                if (error?.message) {
                    toast.error(error.message, {
                        position: "top-center",
                    });
                } else {
                    onClose();
                }
            }
        },
    })

    const handlePlacaChange = (event) => {
        formik.setFieldValue('placa_obj', event.target.value)
        const placaObj = event.target.value;

        const existsInMaq = Maquinas.find((maquina) => maquina.placa == placaObj);
        const existsInVeh = VehiculosDB.find((vehiculo) => vehiculo.placa_veh == placaObj);

        if (existsInMaq != undefined) {
            setExistInMaquina(existsInMaq)
            setDesplazamientoMaq(existsInMaq.desplazamiento)
            setCondOrOp(existsInMaq.conductor?.nombre + " " + existsInMaq.conductor?.apellidos)
            setCondOrOpId(existsInMaq.conductor.id_operador)

            formik.setFieldValue('nombre_cond_op', existsInMaq.conductor?.nombre + " " +
                existsInMaq.conductor?.apellidos)
            formik.setFieldValue('desplazamiento', existsInMaq.desplazamiento)
            formik.setFieldValue('id_conductor', existsInMaq.conductor?.id_operador)
            setPlacaExists("Maquina");
        } else if (existsInVeh != undefined) {
            setExistsInVehiculos(existsInVeh)
            setDesplazamientoVeh(existsInVeh.desplazamiento)
            setCondOrOp(existsInVehiculos.conductor?.nombre + " " + existsInVehiculos.conductor?.apellidos)
            setCondOrOpId(existsInVeh.conductor.id_conductor)

            formik.setFieldValue('nombre_cond_op', existsInVehiculos.conductor?.nombre + " " +
                existsInVehiculos.conductor?.apellidos)
            formik.setFieldValue('desplazamiento', existsInVeh.desplazamiento)
            formik.setFieldValue('id_conductor', existsInVeh.conductor?.id_conductor)
            setPlacaExists("Vehiculo");
        } else {
            setPlacaExists("None")
        }
    };

    const divStyle = {
        textAlign: "left",
    };

    if (!Maquinas || !VehiculosDB) {
        // muestra un spinner mientras los datos se terminan de cargar
        return <Loader active inline="centered" />;
    }

    return (
        <>
            {loadingTcsOp || isFinished ? (
                <>
                    <h3>{isFinished ? "Operación completada con éxito" : "Realizando operación, espere..."}</h3>
                    <br></br>
                    {!isFinished ? <><Loader active inline="centered" /></> : <></>}
                    <br></br>
                    <h5>Suministrado</h5>
                    <div style={{ textAlign: 'center', fontSize: '58px', fontFamily: 'Consolas' }}>
                        {pulsos ? pulsos.pulsos : "0"}
                    </div>
                    <h5>{isFinished ? "Tiquete generado" : ""}</h5>
                    {isFinished && respTicket ? <ul>
                        {Object.entries(respTicket).map(([key, value], index) => (
                            <li key={index}>
                                <strong>{key}</strong>: <span style={{ textAlign: 'center' }}>{value}</span>
                            </li>
                        ))}
                    </ul> : <></>}
                </>
            ) : (
                <>
                    <Formik initialValues={initialValues()}>
                        <Form className="add-edit-secciones-form" style={divStyle} onSubmit={formik.handleSubmit}>
                            <Form.Group widths="equal">
                                <Form.Field
                                    required
                                    fluid
                                    control={Input}
                                    label={"Placa vehículo/maquinaria"}
                                    name="placa_obj"
                                    placeholder="Ej:HGS291"
                                    value={formik.values.placa_obj}
                                    onChange={handlePlacaChange}

                                />
                                <Form.Field
                                    readOnly
                                    fluid
                                    control={Input}
                                    label={"Desplazamiento"}
                                    name="desplazamiento"
                                    placeholder="Ej:Estacionario"
                                    value={existsInMaquinas != undefined && placaExists == "Maquina" ? desplazamientoMaq : existsInVehiculos != undefined && placaExists == "Vehiculo" ? desplazamientoVeh : ""}
                                    onChange={formik.handleChange}

                                />
                                <Form.Field
                                    required
                                    fluid
                                    control={Input}
                                    label={"Km actual"}
                                    name="trabajo_act"
                                    value={formik.values.trabajo_act}
                                    onChange={formik.handleChange}
                                    error={formik.errors.trabajo_act}
                                    disabled={formik.values.desplazamiento == "Estacionario" || formik.values.desplazamiento == ""}
                                    enabled={formik.values.desplazamiento == "Móvil" || formik.desplazamiento == "Móvil - Grandes distancias" || formik.values.desplazamiento === "Mixto"}
                                />
                                <Form.Field
                                    required
                                    fluid
                                    control={Input}
                                    label={"Hr actual"}
                                    name="hrs_uso"
                                    value={formik.values.hrs_uso}
                                    onChange={formik.handleChange}
                                    error={formik.errors.hrs_uso}
                                    disabled={formik.values.desplazamiento === "Móvil" || formik.values.desplazamiento == "Móvil - Grandes distancias" || formik.values.desplazamiento == ""}
                                    enabled={formik.values.desplazamiento === "Mixto" || formik.values.desplazamiento === "Estacionario"}
                                />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <Form.Field
                                    required
                                    fluid
                                    control={Select}
                                    label={"Máquina registradora"}
                                    name="controlador"
                                    options={tcsModels}
                                    value={formik.values.controlador}
                                    onChange={(event, data) => {
                                        formik.setFieldValue('controlador', data.value);
                                        const selectedCtrl = data.options.find(option => option.value == data.value)
                                        const objStr = selectedCtrl && selectedCtrl.tank ? selectedCtrl.tank : selectedCtrl.vehicle
                                        formik.setFieldValue('origen_text', objStr)
                                        formik.setFieldValue('id_origen', selectedCtrl && selectedCtrl.relacionTanque ? selectedCtrl.relacionTanque : selectedCtrl.relacionVehiculo)
                                        setRelationType(data.relacionTanque ? data.relacionTanque : data.relacionVehiculo)
                                        setRelationFlag(data.relacionTanque ? "tanque" : "vehiculo")
                                    }}
                                    error={formik.errors.controlador}
                                />
                                <Form.Field
                                    readOnly
                                    fluid
                                    control={Select}
                                    label={"Producto"}
                                    name="tipo_combustible"
                                    options={tcsProds}
                                    onChange={(event, data) => {
                                        formik.setFieldValue('prod_id', data.value);
                                        const selectedProd = data.options.find(option => option.value == data.value)
                                        formik.setFieldValue('tipo_combustible', selectedProd.text)
                                    }}
                                    error={formik.errors.tipo_combustible}
                                />
                                <Form.Field
                                    readOnly
                                    fluid
                                    control={Input}
                                    label={relationFlag == "tanque" ? "Tanque" : "Carro tanque"}
                                    name="origen_text"
                                    id="origen_text"
                                    value={formik.values.origen_text}
                                    onChange={formik.handleChange}
                                    error={formik.errors.origen_text}
                                />
                                <Form.Field
                                    required
                                    fluid
                                    control={Input}
                                    label={"Cantidad para suministrar"}
                                    name="cant_consumo"
                                    placeholder="Ej:10"
                                    value={formik.values.cant_consumo}
                                    onChange={formik.handleChange}
                                    error={formik.errors.cant_consumo}
                                />
                            </Form.Group>
                            <Form.Field
                                hidden
                                control={Input}
                                name="id_origen"
                                id="id_origen"
                                value={formik.values.id_origen}
                                onChange={formik.handleChange}
                                error={formik.errors.id_origen}
                            />
                            <Form.Group widths="equal">
                                <Form.Field
                                    required
                                    readOnly
                                    fluid
                                    control={Input}
                                    label={"Conductor del vehículo/máquina"}
                                    name="nombre_cond_op"
                                    placeholder="Ej: Armando Casas"
                                    value={existsInMaquinas != undefined && placaExists == "Maquina" ?
                                        condOrOp : existsInVehiculos != undefined && placaExists == "Vehiculo" ?
                                            condOrOp : ""}
                                    onChange={formik.handleChange}
                                    error={formik.errors.nombre_cond_op}
                                />
                            </Form.Group>
                            <Form.Field
                                hidden
                                readOnly
                                fluid
                                control={Input}
                                name="id_conductor"
                                placeholder="Ej: Armando Casas"
                                value={existsInMaquinas != undefined && placaExists == "Maquina" ?
                                    condOrOpId : existsInVehiculos != undefined && placaExists == "Vehiculo" ?
                                        condOrOpId : ""}
                                onChange={formik.handleChange}
                                error={formik.errors.id_conductor}
                            />
                            <Button
                                type="submit"
                                // primary
                                className="btn btn-danger"
                                fluid
                                content={Consumo ? "Actualizar" : "Empezar operación"}
                            />
                        </Form>
                    </Formik>
                </>
            )}
        </>
    )
}

function initialValues(data, data2) {
    return {
        placa_obj: data?.placa_obj || "",
        tipo_obj: data?.tipo_obj || "",
        cant_consumo: data?.cant_consumo || "0",
        tipo_combustible: data?.tipo_combustible || "",
        trabajo_act: data?.trabajo_act || "0",
        id_operador: data?.id_operador || 0,
        id_conductor: data?.id_conductor || "",
        hrs_uso: data?.hrs_uso || "0",
        desplazamiento: "",
        controlador: data?.controlador || 0,
        id_origen: data?.id_origen || "",
        tipo_operacion: data?.tipo_operacion || "",
        nombre_cond_op: undefined,
        origen_text: data?.origen_text || "",
    };
}

function newScheme() {
    return {
        placa_obj: Yup.string(),
        tipo_obj: Yup.string(),
        cant_consumo: Yup.string().required(true),
        tipo_combustible: Yup.string().required(true),
        precio_consumo: Yup.string(),
        medida_consumo: Yup.string(),
        trabajo_act: Yup.string().required(true),
        id_conductor: Yup.string(),
        hrs_uso: Yup.string().required(true),
        controlador: Yup.string(),
        id_origen: Yup.string(),
        tipo_operacion: Yup.string(),
        nombre_cond_op: Yup.string(),
        origen_text: Yup.string()
    };
}