import { useState } from "react";

import { addMultiLdProdApi, delMLIIProdApi, delMLIIAsgnAuthProdsApi } from "../../api/A_MultiLoadProds/multiloadprods";

import { alertas } from "../../Components/Comons/Alertas/alertas";

import { useAuth } from "..";

export function useMultiLdProd() {
    const { auth, logout } = useAuth();
    const { unauthorized, SessionExpired, unauthorizedModal, errores } = alertas();

    const [state, setState] = useState({
        loading: true,
        error: null,
    });

    const { loading, error } = state;

    const handleAddOrUpdateError = (result) => {
        if (result?.nit_Controladores) {
            throw new Error("Ya existe un Controladores registrado con este NIT");
        } else {
            errores();
        }
    };

    const handleAddMultiLdProd = async (data) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));

            const [result, response] = await addMultiLdProdApi(data, auth.token, auth.me.tenant);

            switch (response.status) {
                case 200:
                    setState((prevState) => ({ ...prevState, loading: false }));
                    break
                case 403:
                    throw new Error(unauthorizedModal());
                case 400:
                    throw new Error(errores())
                default:
                    throw new Error(errores())
            }
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
            throw error;
        }
    }

    const handleDeleteMLIIProds = async (data) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await delMLIIProdApi(data, auth.token, auth.me.tenant);
            if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
        }
    }

    const handleDeleteAsgnAuthProds = async (data) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await delMLIIAsgnAuthProdsApi(data, auth.token, auth.me.tenant);
            if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
        }
    }

    return {
        loading,
        error,
        handleAddMultiLdProd,
        handleDeleteMLIIProds,
        handleDeleteAsgnAuthProds
    }
}