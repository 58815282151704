import { useFormik } from "formik";
import { map } from "lodash";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Autocomplete } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid"
import TextField from '@mui/material/TextField'
// import { Button, Form, Input, Select } from "semantic-ui-react";
import * as Yup from "yup";
import { useConciliaciones, useProductos, useTanques } from "../../../hooks";
import { alertas } from "../../Comons/Alertas/alertas";
import { productoIdSelect, tanqueSelect } from "../../Comons";

export function AddEditConciliaciones(props) {
  /*
  Componente

  -componente de visualizacion del modal de edicion y creacion para Conciliaciones
  
  -se crea y valida mediante formik y yup
  -Se obtienen datos para dropdown mediante hooks
  -se obtiene los datos (para edicion) y funciones correspondientes mediante props desde su PageAdmin
   */
  const { Successful } = alertas();
  const { onClose, onRefetch, Conciliaciones } = props;
  const { addConciliaciones, updateConciliaciones } = useConciliaciones();
  const { ProductoDB, getProductoDB } = useProductos();
  const { tanquesDB, getTanquesDB } = useTanques();
  const [tablasFormatP, setTablasTFormatP] = useState([]);
  const [tablasFormatT, setTablasTFormatT] = useState([]);

  useEffect(() => {
    getProductoDB();
    getTanquesDB();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTablasTFormatP(productoIdSelect(ProductoDB));
  }, [ProductoDB]);

  useEffect(() => {
    setTablasTFormatT(tanqueSelect(tanquesDB));
  }, [tanquesDB]);

  const formik = useFormik({
    // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
    initialValues: initialValues(Conciliaciones),
    // Establece la validación del esquema utilizando Yup, si se proporciona un objeto  se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
    validationSchema: Yup.object(Conciliaciones ? updateSchame() : newSchame()),
    // Desactiva la validación al cambiar los valores del formulario
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        // Si se proporciona un objeto  se actualiza, de lo contrario se crea uno nuevo
        if (Conciliaciones)
          await updateConciliaciones(
            Conciliaciones.id_conciliaciones,
            formValue
          );
        else await addConciliaciones(formValue);
        // Llama a una función Successful si se completa correctamente
        Successful()
        // Llama a la función onRefetch para actualizar la lista 
        onRefetch();
        // Llama a la función onClose para cerrar el formulario
        onClose();
      } catch (error) {
        // Muestra un mensaje de error si ocurre algún problema
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });

  const divStyle = {
    textAlign: "left",
  };

  return (
    <>
      <form className="add-edit-persona-form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              name="fecha_inicial"
              placeholder="Fecha inicial"
              value={formik.values.fecha_inicial}
              onChange={formik.handleChange}
              error={formik.errors.fecha_inicial}
              type="date"
              helperText="Fecha inicial de la conciliación"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              name="fecha_final"
              placeholder="Fecha final"
              value={formik.values.fecha_final}
              onChange={formik.handleChange}
              error={formik.errors.fecha_final}
              type="date"
              helperText="Fecha final de la conciliación"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              fullWidth
              id="id_producto"
              options={tablasFormatP}
              getOptionLabel={(option) => `${option.text} `}
              value={
                tablasFormatP?.find(
                  (opt) => opt.key === formik.values?.id_producto
                ) || null
              }
              onChange={(_, value) => {
                formik.setFieldValue("id_producto", value?.key || "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Producto asociado"
                  variant="outlined"
                  error={formik.touched.id_producto && Boolean(formik.errors.id_producto)}
                  helperText={formik.touched.id_producto && formik.errors.id_producto}
                  sx={{ background: "#fcfcfc" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              fullWidth
              id="id_tanque"
              options={tablasFormatT}
              getOptionLabel={(option) => `${option.text} `}
              value={
                tablasFormatT?.find(
                  (opt) => opt.key === formik.values?.id_tanque
                ) || null
              }
              onChange={(_, value) => {
                formik.setFieldValue("id_tanque", value?.key || "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Tanque asociado"
                  variant="outlined"
                  error={formik.touched.id_tanque && Boolean(formik.errors.id_tanque)}
                  helperText={formik.touched.id_tanque && formik.errors.id_tanque}
                  sx={{ background: "#fcfcfc" }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              required
              label="Volumen inicial"
              name="volumen_inicial"
              placeholder="Volumen inicial"
              value={formik.values.volumen_inicial}
              onChange={formik.handleChange}
              error={formik.errors.volumen_inicial}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              required
              label="Volumen vendido"
              name="volumen_ventas"
              placeholder="Volumen ventas"
              value={formik.values.volumen_ventas}
              onChange={formik.handleChange}
              error={formik.errors.volumen_ventas}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              required
              label="Volumen recargado"
              name="volumen_recargas"
              placeholder="Volumen recargas"
              value={formik.values.volumen_recargas}
              onChange={formik.handleChange}
              error={formik.errors.volumen_recargas}
              type="number"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label="Perdidas por evaporación"
              name="perdida_evaporación"
              placeholder="Perdidas por evaporación"
              value={formik.values.perdida_evaporación}
              onChange={formik.handleChange}
              error={formik.errors.perdida_evaporación}
              type="number"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label="Perdidas por derrame"
              name="derrame"
              placeholder="Perdidas por derrame"
              value={formik.values.derrame}
              onChange={formik.handleChange}
              error={formik.errors.derrame}
              type="number"
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Button type="submit" variant="contained" color="error" className="btn btn-danger" fullWidth>
              {Conciliaciones ? "Actualizar" : "Crear"}
            </Button>
          </Grid>
        </Grid>
      </form>

    </>
  );
}



function initialValues(data) {
  return {
    fecha_inicial: data?.fecha_inicial || "",
    fecha_final: data?.fecha_final || "",
    id_tanque: data?.id_tanque || "",
    id_producto: data?.id_producto || "",
    volumen_inicial: data?.volumen_inicial || "",
    volumen_ventas: data?.volumen_ventas || "",
    volumen_recargas: data?.volumen_recargas || "",
    perdida_evaporación: data?.perdida_evaporación || "",
    derrame: data?.derrame || "",
  };
}

function newSchame() {
  return {
    fecha_inicial: Yup.date().required(true),
    fecha_final: Yup.date().required(true),
    id_tanque: Yup.number().required(true),
    id_producto: Yup.number().required(true),
    volumen_inicial: Yup.number().required(true),
    volumen_ventas: Yup.number().required(true),
    volumen_recargas: Yup.number().required(true),
    perdida_evaporación: Yup.number().required(true),
    derrame: Yup.number().required(true),
  };
}

function updateSchame() {
  return {
    fecha_inicial: Yup.date().required(true),
    fecha_final: Yup.date().required(true),
    id_tanque: Yup.number().required(true),
    id_producto: Yup.number().required(true),
    volumen_inicial: Yup.number().required(true),
    volumen_ventas: Yup.number().required(true),
    volumen_recargas: Yup.number().required(true),
    perdida_evaporación: Yup.number().required(true),
    derrame: Yup.number().required(true),
  };
}
