export const columns = [
    
    {
      header: "Placa remolque",
      name: "placa_remolq",
      maxWidth: 1000, defaultFlex: 1
    },
    {
      header: "Capacidad Total",
      name: "capacidad",
      maxWidth: 1000, defaultFlex: 1
    },

    {
      header: "Descripción",
      name: "descrip",
      maxWidth: 1000, defaultFlex: 1
    },

  ];

  export const filterValue = [
    { name: 'placa_remolq', operator: 'startsWith', type: 'string', value: '' },
    { name: 'capacidad', operator: 'startsWith', type: 'string', value: '' },
    { name: 'id_vehiculo', operator: 'startsWith', type: 'string', value: '' },
    { name: 'descrip', operator: 'startsWith', type: 'string', value: '' },
    
  ];
  