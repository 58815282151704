import { useState } from "react";
import { getOrdenesApi, addOrdenesApi, updateOrdenesApi, deleteOrdenesApi, getOrdenesFacturadorApi } from "../../api/A_OrdenVenta/ordenes";
import { useNavigate } from "react-router-dom";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "../";

export function useOrdenes() {
  const { auth, logout } = useAuth();
  const navigate = useNavigate();
  const { unauthorized, SessionExpired, errores, unauthorizedModal } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    Ordenes: null,
    OrdenesDB: null,
    OrdenesFacturador: null,
  });

  const { loading, error, Ordenes, OrdenesDB, OrdenesFacturador } = state;

  const handleAddOrUpdateError = (result) => {
    if (result?.errors.n_orden) {
      throw new Error("El número de orden se ha registrado con anterioridad");
    } else {
      errores();
    }
  };

  const handleResponse = (response) => {
    if (response.status === 401) {
      SessionExpired();
      logout();
    } else if (response.status === 403) {
      unauthorized();
      navigate("/");
    }
  };

  const getOrdenesDB = async () => {
    try {
      const [result, response] = await getOrdenesApi(auth.token, auth.me.tenant);
      setState((prevState) => ({ ...prevState, OrdenesDB: result.results }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  const getOrdenes = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getOrdenesApi(auth.token, auth.me.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, Ordenes: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const getOrdenesFacturador = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getOrdenesFacturadorApi(auth.token, auth.me.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, OrdenesFacturador: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addOrdenes = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addOrdenesApi(data, auth.token, auth.me.tenant);
      if (response.status === 400) {
        handleAddOrUpdateError(result);
      }
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateOrdenes = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateOrdenesApi(id, data, auth.token, auth.me.tenant);
      if (response.status === 400) {
        handleAddOrUpdateError(result);
      }
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteOrdenes = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteOrdenesApi(id, auth.token, auth.me.tenant);
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    OrdenesDB,
    Ordenes,
    OrdenesFacturador,
    getOrdenesFacturador,
    getOrdenesDB,
    getOrdenes,
    addOrdenes,
    updateOrdenes,
    deleteOrdenes,
  };
}
