export const columns = [
    {
      header: 'Fecha Conciliación',
      name: 'fecha_conciliacion',
      maxWidth: 1000, defaultFlex: 1
  
    },  {
      header: 'Tanque',
      name: 'tanque_nombre',
      maxWidth: 1000, defaultFlex: 1
  
    }, {
  
      header: 'Producto',
      name: 'producto_nombre',
      maxWidth: 1000, defaultFlex: 1
  
    },
    {
  
      header: 'Volumen inicial',
      name: 'volumen_inicial',
      maxWidth: 1000, defaultFlex: 1
  
    },
    {
      header: 'Volumen ventas',
      name: 'volumen_ventas',
      maxWidth: 1000,
      defaultFlex: 1,
      render: ({ data }) => {
        const volumenVentas = data?.volumen_ventas || 0;
        const formattedValue = (volumenVentas / 100).toFixed(2);
        return (
          <span>
            {formattedValue.padStart(4, '0')}
          </span>
        );
      },
    },
    
    {
  
      header: 'Volumen recargas',
      name: 'volumen_recargas',
      maxWidth: 1000, defaultFlex: 1
  
    },
    {
  
      header: 'Derrames',
      name: 'derrame',
      maxWidth: 1000, defaultFlex: 1
  
    },
    {
  
      header: 'Perdida evaporación',
      name: 'perdida_evaporación',
      maxWidth: 1000, defaultFlex: 1
  
    },
    
    {
  
      header: 'Volumen diferencia',
      name: 'volumen_diferencia',
      maxWidth: 1000, defaultFlex: 1
  
    },
    {
  
      header: 'Volumen final',
      name: 'volumen_final',
      maxWidth: 1000, defaultFlex: 1
  
    },
   
   
  ]

  export const filterValue = [
    {
      name: 'fecha_conciliacion',
      operator: 'before',
      type: 'date',
      value: ''
    },
    // {
    //     name: 'fecha_inicial',
    //     operator: 'before',
    //     type: 'date',
    //     value: ''
    // },
    // {
    //     name: 'fecha_final',
    //     operator: 'before',
    //     type: 'date',
    //     value: ''
    //   },
    { name: 'id_tanque', operator: 'startsWith', type: 'string', value: '' },
    { name: 'id_producto', operator: 'startsWith', type: 'string', value: '' },
    { name: 'volumen_inicial', operator: 'startsWith', type: 'string', value: '' },
    { name: 'volumen_ventas', operator: 'startsWith', type: 'string', value: '' },
    { name: 'volumen_recargas', operator: 'startsWith', type: 'string', value: '' },
    { name: 'derrame', operator: 'startsWith', type: 'string', value: '' },
    { name: 'perdida_evaporación', operator: 'startsWith', type: 'string', value: '' },
    // { name: 'volumen_estimado', operator: 'startsWith', type: 'string', value: '' },
    { name: 'volumen_diferencia', operator: 'startsWith', type: 'string', value: '' },
    // { name: 'age', operator: 'gte', type: 'number', value: 21 },
    { name: 'volumen_final', operator: 'startsWith', type: 'string', value: '' },
    
  ];