
export const columns = [
  
    {
      header: "nombre_bahia",
      name: "nombre_bahia",
      maxWidth: 2000, defaultFlex: 1
    },
   
  
  ];

export const filterValue = [
    { name: 'nombre_bahia', operator: 'startsWith', type: 'string', value: '' },
    // { name: 'mapeo_bahia', operator: 'startsWith', type: 'string', value: '' },
    
    
]