import { useFormik } from "formik";

import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Button, Form, Input, Select } from "semantic-ui-react";
import * as Yup from "yup";
import { useConciliaciones } from "../../../hooks";
import { alertas } from "../../Comons/Alertas/alertas";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
const options = [
  { key: "1", text: "Ventas", value: "1" },
  { key: "2", text: "Recargas", value: "2" },
  { key: "3", text: "Evaporacion", value: "3" },
  { key: "4", text: "Derrame", value: "4" },
];

const columnas = [
  "Fecha conciliacion",
  "Fecha inicial",
  "Fecha final",
  "Inicial",
  "Ventas",
  "Recargas",
  "Evaporacion",
  "Derrame",
];

export function ComponentConciliaciones(props) {
  const { Successful } = alertas();
  const { onClose, Conciliacionesu, tanque, producto } = props;
  const [refetch, setRefetch] = useState(false);

  const {
    Conciliaciones,
    addConciliaciones,
    updateConciliaciones,
    getConciliaciones,
  } = useConciliaciones();

  useEffect(() => {
    getConciliaciones();
  }, [refetch]);
  const onRefetch = () => setRefetch((prev) => !prev);

  const formik = useFormik({
    // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
    initialValues: initialValues(Conciliacionesu),
    // Establece la validación del esquema utilizando Yup, si se proporciona un objeto  se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
    validationSchema: Yup.object(
      Conciliacionesu ? updateSchame() : newSchame()
    ),
    // Desactiva la validación al cambiar los valores del formulario
    validateOnChange: false,
    onSubmit: async (formValue) => {
      formValue.id_tanque = tanque;
      formValue.id_producto = producto;
      try {
        // Si se proporciona un objeto  se actualiza, de lo contrario se crea uno nuevo
        if (Conciliacionesu)
          await updateConciliaciones(
            Conciliacionesu.id_conciliaciones,
            formValue
          );
        else await addConciliaciones(formValue);
        // Llama a una función Successful si se completa correctamente
        Successful();
        // Llama a la función onRefetch para actualizar la lista
        onRefetch();
        // Llama a la función onClose para cerrar el formulario
        onClose();
      } catch (error) {
        // Muestra un mensaje de error si ocurre algún problema
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });

  const divStyle = {
    textAlign: "left",
  };

  return (
    <>
      <Form
        className="add-edit-persona-form"
        style={divStyle}
        onSubmit={formik.handleSubmit}
      >
        

        <Form.Field
          required
          control={Select}
          label="Opciones"
          placeholder="Opciones"
          fluid
          selection
          search
          options={options}
          value={formik.values.tipo}
          error={formik.errors.tipo}
          onChange={(_, data) =>
            formik.setFieldValue("tipo", data.value)
          }
        />

        <Form.Group widths="equal">
          <Form.Field
            required
            fluid
            control={Input}
            label="Desde"
            name="fecha_inicial"
            placeholder="Fecha inicial"
            value={formik.values.fecha_inicial}
            onChange={formik.handleChange}
            error={formik.errors.fecha_inicial}
            type="date"
          />
          <Form.Field
            required
            fluid
            control={Input}
            label="Hasta"
            name="fecha_final"
            placeholder="Fecha final"
            value={formik.values.fecha_final}
            onChange={formik.handleChange}
            error={formik.errors.fecha_final}
            type="date"
          />
        </Form.Group>

        {formik?.values?.tipo === "1" && (
          <Form.Group widths="equal">
            <Form.Field
              required
              fluid
              control={Input}
              label="Volumen vendido"
              name="volumen_ventas"
              placeholder="Volumen ventas"
              value={formik.values.volumen_ventas}
              onChange={formik.handleChange}
              error={formik.errors.volumen_ventas}
              type="number"
            />
          </Form.Group>
        )}
        {formik?.values?.tipo === "2" && (
          <Form.Group widths="equal">
            <Form.Field
              required
              fluid
              control={Input}
              label="Volumen recargado"
              name="volumen_recargas"
              placeholder="Volumen recargas"
              value={formik.values.volumen_recargas}
              onChange={formik.handleChange}
              error={formik.errors.volumen_recargas}
              type="number"
            />
          </Form.Group>
        )}
        {formik?.values?.tipo === "3" && (
          <Form.Group widths="equal">
            <Form.Field
              required
              fluid
              control={Input}
              label="Perdidas por evaporación"
              name="perdida_evaporación"
              placeholder="Perdidas por evaporación"
              value={formik.values.perdida_evaporación}
              onChange={formik.handleChange}
              error={formik.errors.perdida_evaporación}
              type="number"
            />
          </Form.Group>
        )}
        {formik?.values?.tipo === "4" && (
          <Form.Group widths="equal">
            <Form.Field
              required
              fluid
              control={Input}
              label="Perdidas por derrame"
              name="derrame"
              placeholder="Perdidas por derrame"
              value={formik.values.derrame}
              onChange={formik.handleChange}
              error={formik.errors.derrame}
              type="number"
            />
          </Form.Group>
        )}
        <br />
        <br />

        <Button
          type="submit"
          // primary
          className="btn btn-danger"
          fluid
          content={Conciliacionesu ? "Actualizar" : "Crear"}
        />
      </Form>
      {Conciliaciones && Conciliaciones.results.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              {columnas.map((columna, index) => (
                <TableCell key={index}>{columna}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Conciliaciones.results.map((conciliacion, index) => (
              <TableRow key={index}>
                <TableCell>{conciliacion.fecha_conciliacion}</TableCell>
                <TableCell>{conciliacion.fecha_inicial}</TableCell>
                <TableCell>{conciliacion.fecha_final}</TableCell>
                <TableCell>{conciliacion.volumen_inicial}</TableCell>
                <TableCell>{conciliacion.volumen_ventas}</TableCell>
                <TableCell>{conciliacion.volumen_recargas}</TableCell>
                <TableCell>{conciliacion.perdida_evaporación}</TableCell>
                <TableCell>{conciliacion.derrame}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
}

function initialValues(data) {
  return {
    fecha_inicial: data?.fecha_inicial || "",
    fecha_final: data?.fecha_final || "",
    id_tanque: data?.id_tanque || "",
    id_producto: data?.id_producto || "",
    volumen_inicial: data?.volumen_inicial || "",
    volumen_ventas: data?.volumen_ventas || "",
    volumen_recargas: data?.volumen_recargas || "",
    perdida_evaporación: data?.perdida_evaporación || "",
    derrame: data?.derrame || "",
    tipo: data?.tipo || "",
  };
}

function newSchame() {
  return {
    fecha_inicial: Yup.date().required(true),
    fecha_final: Yup.date().required(true),
    id_tanque: Yup.number(),
    id_producto: Yup.number(),
    volumen_inicial: Yup.number(),
    volumen_ventas: Yup.number(),
    volumen_recargas: Yup.number(),
    perdida_evaporación: Yup.number(),
    derrame: Yup.number(),
  };
}

function updateSchame() {
  return {
    fecha_inicial: Yup.date().required(true),
    fecha_final: Yup.date().required(true),
    id_tanque: Yup.number(),
    id_producto: Yup.number(),
    volumen_inicial: Yup.number(),
    volumen_ventas: Yup.number(),
    volumen_recargas: Yup.number(),
    perdida_evaporación: Yup.number(),
    derrame: Yup.number(),
  };
}
