
  

  import { BASE_API } from "../../utils/constants";
  import { makeRequestFormData } from "../Request";
  const BASE_URL = `${BASE_API}/`;
  
  
  // Función para obtener los datos 
  export async function getDocumentosconductoresApi(token, schema, id) {
    let url = `${BASE_URL}${schema}/conductores/documentos`;
    if (id) {
      url += `?conductor=${id}`;
    }
    
    return makeRequestFormData(url, "GET", token);
  }
  
  // Función para agregar un nuevo dato
  export async function addDocumentosconductoresApi(data, token,schema) {
    const formData = new FormData();

    Object.keys(data).forEach(key => {
      if(key === 'documentos'){
        formData.append(key, JSON.stringify(data[key]));
      }else{
         formData.append(key, data[key]);
        }
      });
    const url = `${BASE_URL}${schema}/conductores/documentos/`;
    return makeRequestFormData(url, "POST", token, formData);
  }
  
  // Función para actualizar los datos 
  export async function updateDocumentosApi(id, data, token,schema) {
    const formData = new FormData();

    Object.keys(data).forEach(key => {
      if(key === 'documentos'){
        formData.append(key, JSON.stringify(data[key]));
      }else{
         formData.append(key, data[key]);
        }
      });
    const url = `${BASE_URL}${schema}/conductores/documentos/${id}/`;
    return makeRequestFormData(url, "PATCH", token, formData);
  }
  
  // Función para eliminar 
  export async function deleteDocumentosconductoresApi(id, token,schema) {
    const url = `${BASE_URL}${schema}/conductores/documentos/${id}/`;
    return makeRequestFormData(url, "DELETE", token);
  }