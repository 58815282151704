import React from "react";
import { Link } from "react-router-dom";

export function Licencia() {
  // const {suscripcionActiva, getLicencia} = useLicencia();

  // useEffect(() => {
  //   getLicencia();
  // }, []);
  return (
    <div
    className="justify-content-center d-flex align-items-center"
    style={{ backgroundImage: "https://ii.ct-stc.com/1/logos/empresas/2018/07/10/syz-colombia-sas-B6C7BAF49F7AA1CB190711369thumbnail.jpg", backgroundSize: "cover", height: "100vh" }}
  >
    <div
      className="alert alert-danger"
      role="alert"
      style={{ width: "50%", textAlign: "center", backgroundColor: "rgba(255, 255, 255, 0.8)", padding: "50px" }}
    >
      <h4 className="alert-heading">Licencia vencida</h4>
      <p>
        Lamentamos informarle que su suscripción ha vencido. Por favor realice
        un nuevo pago para reactivar su licencia.
      </p>
      <hr />
      <p className="mb-0">
        Si tiene alguna duda o problema, por favor contáctenos.
      </p>
  
      <div
        className="center d-flex justify-content-center"
      >
        
        <Link
          to="/renovar/licencia"
          className="btn btn-danger center"
          style={{ marginTop: "10px" }}
          onMouseEnter={() => {
            this.style.backgroundColor = "#dc3545";
            this.style.color = "white";
            this.style.cursor = "pointer";
            this.style.boxShadow = "0px 0px 20px rgba(0,0,0,0.4)";
          }}
          onMouseLeave={() => {
            this.style.backgroundColor = "";
            this.style.color = "";
            this.style.cursor = "";
            this.style.boxShadow = "0px 0px 10px rgba(0,0,0,0.2)";
          }}
        >
          Renovar licencia
        </Link>
      </div>
    </div>
  </div>
  );
}
