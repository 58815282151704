
  

  export const filterValue = [
    { name: 'nombre', operator: 'startsWith', type: 'string', value: '' },
    { name: 'apellidos', operator: 'startsWith', type: 'string', value: '' },
    { name: 'email', operator: 'startsWith', type: 'string', value: '' },
    { name: 'n_documento', operator: 'startsWith', type: 'string', value: '' },
    { name: 'celular', operator: 'startsWith', type: 'string', value: '' },
    
  ];
  