import { useState } from "react";


import { useAuth } from "..";
import { getTenantsApi } from "../../api/Tenants/tenants";

export function useTenants() {
  
  const [error, setError] = useState(null);  
  const [TenantsDB, setTenantsDB] = useState(null);

  //traemos los datos de usuario almacenados en sesion
  const { auth } = useAuth();
  //peticion get de usuarios

  const getTenantsDB = async () => {
    try {
      const [result,response] = await getTenantsApi(auth.token);
      setTenantsDB(result);
      
    } catch (error) {
      
      setError(error);
      
    }
  };


  return {
    error,
    TenantsDB,
    getTenantsDB,
  };
}
