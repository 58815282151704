import React, { useEffect, useState } from "react";
// import { Loader } from "semantic-ui-react";
import Swal from "sweetalert2";
import {
  AddEditSistemaMedicionBase, HeaderPage
} from "../../../Components/Client";
import { DataGridBasic, ModalBasic } from "../../../Components/Comons";
import { useSistemaMedicion } from "../../../hooks";
import { columns, filterValue } from "./SistemaMedicionBFormat";
import CircularProgress from '@mui/material/CircularProgress';

export function SistemaMedicionBaseAdmin() {
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [sizeModal, setsizeModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const { loading, SistemaMedicion, getSistemaMedicionAdmin, } = useSistemaMedicion();

  useEffect(() => {
    getSistemaMedicionAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const openCloseModal = () => setShowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);

  const updateDatos = (data) => {
    setTitleModal("Actualizar unidades");
    setContentModal(
      <AddEditSistemaMedicionBase
        onClose={openCloseModal}
        onRefetch={onRefetch}
        Datos={data}
      />
    );
    openCloseModal();
  };



  return (
    <>

      <HeaderPage title="Ingreso unidades de medida" icono="plus square icon" />
      {loading ? (
        // <Loader active inline="centered">
        //   Cargando...
        // </Loader>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <DataGridBasic
          data={SistemaMedicion}
          columns={columns}
          id="id_sistema"
          filterValue={filterValue}
          title="Sistema de medicion Base"
          updateData={updateDatos}
        />

      )}

      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        title={titleModal}
        children={contentModal}
        size={sizeModal}
      />
    </>
  )
}

//Columnas que se envian en forma de props al componente tabla basic para representer sus campos

