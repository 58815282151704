import React, { useEffect } from "react";
import { Loader } from "semantic-ui-react";
import CircularProgress from '@mui/material/CircularProgress';
import { HeaderPage } from "../../../Components/Client";
import { ModalBasic, DataGridNoOpt } from "../../../Components/Comons";
import { useConciliaciones } from "../../../hooks";
import { columns, filterValue } from "./ConciliacionesFormat";
import { Funciones } from "./Funciones";

export function ConciliacionesAdmin() {
  const { loading, Conciliaciones, getConciliaciones } = useConciliaciones();

  const {
    refetch,
    contentModal,
    titleModal,
    showModal,
    handleAdd,
    openCloseModal,
  } = Funciones();

  useEffect(() => {
    getConciliaciones();
  }, [refetch]);



  const MemoizedModalBasic = React.memo(ModalBasic);

  return (
    <>
      <HeaderPage title="Conciliaciones" btnTitle="Nueva correción" icono="plus square icon" btnClick={handleAdd} />

      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <DataGridNoOpt
          data={Conciliaciones.results}
          title="Correcciones"
          columns={columns}
          id="id_myemp"
          filterValue={filterValue}
        />
      )}

      {showModal && (
        <MemoizedModalBasic
          show={showModal}
          onClose={openCloseModal}
          title={titleModal}
          children={contentModal}
        />
      )}
    </>
  );
}
