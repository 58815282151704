import React, { useEffect } from "react";
import { Funciones } from "./Funciones";
import { columns, filterValue } from "./MapeosFormat"
// import { Loader } from "semantic-ui-react";
import { DataGridBasic, ModalBasic } from "../../../Components/Comons";
import { HeaderPage } from "../../../Components/Client";
import { useMapeos } from "../../../hooks";
import CircularProgress from '@mui/material/CircularProgress';

export function MapeosAdmin() {
    const { loading, Mapeos, getMapeos } = useMapeos(); 

    const {
      refetch,
      contentModal,
      titleModal,
      showModal,
      handleDelete,
      handleUpdate,
      openCloseModal,
    } = Funciones();
  
  
    useEffect(() => {
        getMapeos();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetch]);


    const MemoizedModalBasic = React.memo(ModalBasic);
    
    console.log(Mapeos)
    return (
      <>
        {/* <HeaderPage
          title="Mapeos"
          btnTitle="Nuevo mapeo"
          icono="plus square icon"
          btnClick={handleAdd}
        />
        */}
  
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
          </div>
        ) : (
          <DataGridBasic
            data={Mapeos}
            title="Mapeos"
            id="id_myemp"
            filterValue={filterValue}
            columns={columns}
            updateData={handleUpdate}
            onDeleteData={handleDelete}
          />
        )}
        {showModal && (
          <MemoizedModalBasic
            show={showModal}
            onClose={openCloseModal}
            title={titleModal}
            children={contentModal}
          />
        )}
  
      </>
    )
  }