export const columns = [
    
    {
      header: "Nombre",
      name: "nombre_mapeo",
      maxWidth: 2000, defaultFlex: 1
    },
    
  ];

  export const filterValue = [
    { name: 'nombre_mapeo', operator: 'startsWith', type: 'string', value: '' },
    
  ];