import { useFormik } from "formik";
import React from "react";
import {
  Button,
  Form,
  Input,
  
} from "semantic-ui-react";
import * as Yup from "yup";
import { alertas } from "../Alertas/alertas";
import { toast } from "react-toastify";
import { useUser } from "../../../hooks";
import "./ModalCambioContraseña.scss"

export function ModalCambioContraseña(props) {
  const { onClose, data } = props;
  const { Successful } = alertas();
  const { updatePassword } = useUser();

  const formik = useFormik({
    // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
    initialValues: initialValues(data),
    // Establece la validación del esquema utilizando Yup, si se proporciona un objeto  se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
    validationSchema: Yup.object(data ? updateSchame() : false),
     // Desactiva la validación al cambiar los valores del formulario
    // validateOnChange: false,
    onSubmit: async (formValue) => {
       // Si se proporciona un objeto se actualiza, de lo contrario se crea uno nuevo
      try {
        if (data) await updatePassword(data, formValue);

        Successful();
        onClose();
      } catch (error) {
        // Muestra un mensaje de error si ocurre algún problema
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
        }
      }
    },
  });
 
  return (
    <>
      <Form className="add-edit-user-form" onSubmit={formik.handleSubmit}>
        <div class="col-xxl-6">
          <div class="bg-secondary-soft px-4 py-5 rounded">
            <div class="row g-3">
              <h4 class="my-4">Cambio de contraseña</h4>
              {/* 
              <div class="col-md-6">
                <label for="exampleInputPassword1" class="form-label">
                  Old password *
                </label>
                <input
                  type="password"
                  class="form-control"
                  id="exampleInputPassword1"
                />
              </div> */}

              <div class="col-md-12">
                <label for="exampleInputPassword2" class="form-label">
                  Nueva Contraseña *
                </label>
                <Form.Field
                  required
                  fluid
                  control={Input}
                  
                  name="password"
                  placeholder="Contraseña..."
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.errors.password && (
                    <div className="error-message">{formik.errors.password}</div>
                  )}
                  type="password"
                />
              </div>

              <div class="col-md-12">
                <label for="exampleInputPassword3" class="form-label">
                  Confirma la contraseña *
                </label>
                <Form.Field
                  required
                  fluid
                  control={Input}
                  
                  name="password2"
                  placeholder="Contraseña 2"
                  value={formik.values.password2}
                  onChange={formik.handleChange}
                  error={formik.errors.password2 && (
                    <div className="error-message">{formik.errors.password2}</div>
                  )}
                  type="password"
                />
              </div>
            </div>
          </div>
        </div>
        <Button
        type="submit"
        // primary
        className="btn btn-danger"
        fluid
        content={"Actualizar"}
      />
      </Form>
    </>
  );
}

function initialValues(data) {
  return {
    id_usuario: data || "",
    password: "",
    password2: "",
  };
}

function updateSchame() {
  return {
    password: Yup.string().required(true)
  .min(8, 'La contraseña debe tener al menos 8 caracteres')
  .matches(/[a-z]/, 'La contraseña debe tener al menos una letra minúscula')
  .matches(/[A-Z]/, 'La contraseña debe tener al menos una letra mayúscula')
  .matches(/\d/, 'La contraseña debe tener al menos un número')
  .matches(/[^a-zA-Z\d]/, 'La contraseña debe tener al menos un carácter especial'),
  password2: Yup.string().required(true)
  .oneOf([Yup.ref('new_password'), null], 'Las contraseñas no coinciden'),
  };
}
