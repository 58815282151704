
    
import { BASE_API } from "../../utils/constants";
import { makeRequest } from "../Request";
const BASE_URL = `${BASE_API}/`;

// Función para obtener los datos 
export async function getPlantasApi(token,schema) {
  const url = `${BASE_URL}${schema}/plantas/plantas`;
  return makeRequest(url, "GET", token);
}

// Función para agregar un nuevo dato
export async function addPlantasApi(data, token,schema) {
  const url = `${BASE_URL}${schema}/plantas/plantas/`;
  return makeRequest(url, "POST", token, data);
}


// Función para actualizar los datos 
export async function updatePlantasApi(id, data, token,schema) {
  const url = `${BASE_URL}${schema}/plantas/plantas/${id}/`;
  return makeRequest(url, "PUT", token, data);
}


// Función para eliminar 
export async function deletePlantasApi(id, token,schema) {
  const url = `${BASE_URL}${schema}/plantas/plantas/${id}/`;
  return makeRequest(url, "DELETE", token);
}