import { useFormik } from "formik";
import React, { useEffect, useState } from "react";

import { Autocomplete } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'

import Swal from "sweetalert2";
import * as Yup from "yup";

import { useIslas, useControladores } from "../../../hooks"

import { alertas } from "../../Comons/Alertas/alertas";

const funcOpts = [
    { "key": 1, "text": "Entrada", "value": 1 },
    { "key": 2, "text": "Salida", "value": 2 },
]

export function AddEditIslaConf(props) {

    const { onClose, onRefetch, islaConf } = props;
    const { Islas, getIslas } = useIslas()
    const { Controladores, getControladores } = useControladores()
    const [filterCtrl, setFilterCtrl] = useState()
    const [error1, setError1] = useState(false);
    const [error2, setError2] = useState(false);
    const [error3, setError3] = useState(false);

    useEffect(() => {
        getControladores()
        getIslas()
    }, [])

    const formik = useFormik({
        // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
        initialValues: initialValues(islaConf),
        // Establece la validación del esquema utilizando Yup, si se proporciona un objeto  se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
        validationSchema: Yup.object(islaConf ? updateSchame() : newSchame()),
        // Desactiva la validación al cambiar los valores del formulario
        validateOnChange: false,
        onSubmit: async (formValue) => {

            try {
                console.log(formValue);

                // Successful();
                // onRefetch();
                // onClose();
            } catch (error) {
                // Muestra un mensaje de error si ocurre algún problema
                if (error?.message) {
                    Swal.fire({
                        icon: "warning",
                        title: "Advertencia",
                        text: error.message,
                    });
                } else {
                    onClose();
                }
            }
        },
    });

    console.log(Islas?.results, Controladores)

    return (
        <>
            <form className="add-edit-secciones-form" onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>
                    {/* fila 2 */}
                    <Grid item xs={12} sm={4}>
                        <Autocomplete
                            id="funcion"
                            options={funcOpts}
                            getOptionLabel={(option) => option.text}
                            value={
                                funcOpts?.find(
                                    (opt) => opt.key === formik.values?.funcion
                                ) || null
                            }
                            onChange={(_, value) => {
                                formik.setFieldValue("funcion", value?.key);
                                setError1(!value || !value.value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Función"
                                    required
                                    fullWidth
                                    variant="outlined"
                                    error={error1}
                                    helperText={error1 ? 'Es necesario una selección' : ''}
                                    sx={{ background: "#fcfcfc" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            required
                            fullWidth
                            name="dir_cam"
                            label="Ip de cámara"
                            placeholder="ej: 192.168.10.2"
                            value={formik.values.dir_cam}
                            onChange={formik.handleChange}
                            error={formik.errors.dir_cam}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            required
                            fullWidth
                            name="dir_bio_cam"
                            label="Ip del biométrico"
                            placeholder="ej: 192.168.10.3"
                            value={formik.values.dir_bio_cam}
                            onChange={formik.handleChange}
                            error={formik.errors.dir_bio_cam}
                        />
                    </Grid>

                    {/* fila 2 */}
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            id="id_isla"
                            options={Islas?.results}
                            getOptionLabel={(option) => option?.nombre}
                            value={
                                Islas?.results.find(
                                    (opt) => opt.id_isla === formik.values?.id_isla
                                ) || null
                            }
                            onChange={(_, value) => {
                                formik.setFieldValue("id_isla", value?.id_isla);
                                setError2(!value || !value.id_isla);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Isla"
                                    required
                                    fullWidth
                                    variant="outlined"
                                    error={error2}
                                    helperText={error2 ? 'Es necesario una selección' : ''}
                                    sx={{ background: "#fcfcfc" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            id="id_kunbus"
                            options={Controladores}
                            getOptionLabel={(option) => option?.modelo}
                            value={
                                Controladores?.find(
                                    (opt) => opt.id_controlador === formik.values?.id_kunbus
                                ) || null
                            }
                            onChange={(_, value) => {
                                formik.setFieldValue("id_kunbus", value?.id_controlador);
                                setError3(!value || !value.id_controlador);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Kunbus"
                                    required
                                    fullWidth
                                    variant="outlined"
                                    error={error3}
                                    helperText={error3 ? 'Es necesario una selección' : ''}
                                    sx={{ background: "#fcfcfc" }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Button type="submit" variant="contained" color="error" className="btn btn-danger" fullWidth>
                            {islaConf ? "Actualizar" : "Crear"}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

function initialValues(data) {
    return {
        funcion: data?.funcion,
        dir_cam: data?.dir_cam,
        dir_bio_cam: data?.dir_bio_cam,
        id_kunbus: data?.id_kunbus,
        id_isla: data?.id_isla,
    }
}

function newSchame() {
    return {
        funcion: Yup.string(),
        dir_cam: Yup.string(),
        dir_bio_cam: Yup.string(),
        id_kunbus: Yup.number().required(true),
        id_isla: Yup.number().required(true)
    }
}

function updateSchame() {
    return {
        funcion: Yup.string(),
        dir_cam: Yup.string(),
        dir_bio_cam: Yup.string(),
        id_kunbus: Yup.number().required(true),
        id_isla: Yup.number().required(true)
    }
}