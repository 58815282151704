import React, { useEffect, useState } from "react";

// import { Tabs, Tab } from '@material-ui/core';
// import Tab from "@mui/material/Tab"
// import Tabs from "@mui/material/Tabs"
import { Tab } from 'semantic-ui-react'
import Box from '@mui/material/Box'

import { SensoresAdmin, EtiquetaSensoresAdmin } from "../index"

export function GroupedSensores() {
    const panes = [
        {
            menuItem: { key: 'sensores', content: <>Sensores</> },
            render: () => <Tab.Pane><SensoresAdmin /></Tab.Pane>,
        },
        {
            menuItem: { key: 'tag', content: <>Etiquetas</> },
            render: () => <Tab.Pane><EtiquetaSensoresAdmin /></Tab.Pane>,
        },
    ]

    return (
        <>
            <Tab panes={panes} />
        </>
    )
}