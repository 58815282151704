
  import { BASE_API } from "../../utils/constants";
  import { makeRequest } from "../Request";
  const BASE_URL = `${BASE_API}/`;
  
  // Función para obtener los datos 
  export async function getTurnosApi(token,schema) {
    const url = `${BASE_URL}${schema}/turno/turnos/`;
    return makeRequest(url, "GET", token);
  }
  
  // Función para agregar un nuevo dato
  export async function addTurnosApi(data, token,schema) {
    const url = `${BASE_URL}${schema}/turno/turnos/`;
    return makeRequest(url, "POST", token, data);
  }
  // Función para agregar un nuevo dato
  export async function limpiarTurnosApi(data, token,schema) {
    const url = `${BASE_URL}${schema}/turno/Limpiar/`;
    return makeRequest(url, "POST", token, data);
  }
  
 