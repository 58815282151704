import styled from "styled-components";
import { Layout } from "antd";
const { Sider } = Layout;

export const StyledSider = styled(Sider)`
  .ant-layout-sider-children {
    .logo {
      padding: 30px;
    }
  }
`;
export const LogoContainer = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img`
  max-width: 60%;
  max-height: 60%;
  object-fit: contain;
`;
