// Convierte galones a litros
export function gallonsToLiters(gallons) {
  return gallons * 3.78541;
}

// Convierte litros a galones
export function litersToGallons(liters) {
  return liters / 3.78541;
}

// Convierte barriles a litros
export function barrelsToLiters(barrels) {
  return barrels * 119.240471;
}

// Convierte litros a barriles
export function litersToBarrels(liters) {
  return liters / 119.240471;
}

// Convierte metros cúbicos a litros
export function cubicMetersToLiters(cubicMeters) {
  return cubicMeters * 1000;
}

// Convierte litros a metros cúbicos
export function litersToCubicMeters(liters) {
  return liters / 1000;
}

// Convierte galones a barriles (de petróleo)
export function gallonsToBarrels(gallons) {
  return gallons / 42;
}

// Convierte barriles (de petróleo) a galones
export function barrelsToGallons(barrels) {
  return barrels * 42;
}

// Convierte metros cúbicos a barriles (de petróleo)
export function cubicMetersToBarrels(cubicMeters) {
  return cubicMeters * 6.28981;
}

// Convierte barriles (de petróleo) a metros cúbicos
export function barrelsToCubicMeters(barrels) {
  return barrels / 6.28981;
}

// Convierte metros cúbicos a galones
export function cubicMetersToGallons(cubicMeters) {
  return cubicMeters * 264.172;
}

// Convierte galones a metros cúbicos
export function gallonsToCubicMeters(gallons) {
  return gallons / 264.172;
}