
import { BASE_API } from "../../utils/constants";
import { makeRequest } from "../Request";
const BASE_URL = `${BASE_API}/`;

// Función para obtener los datos 
export async function getTransaccionesApi(token,schema) {
  const url = `${BASE_URL}${schema}/transacciones/transaccion`;
  return makeRequest(url, "GET", token);
}

// Función para agregar un nuevo dato
export async function addTransaccionesApi(data, token,schema) {
  const url = `${BASE_URL}${schema}/transacciones/transaccion/`;
  return makeRequest(url, "POST", token, data);
}

// Función para actualizar los datos 
export async function updateTransaccionesApi(id, data, token,schema) {
  const url = `${BASE_URL}${schema}/transacciones/transaccion/${id}/`;
  return makeRequest(url, "PUT", token, data);
}

// Función para eliminar 
export async function deleteTransaccionesApi(id, token,schema) {
  const url = `${BASE_URL}${schema}/transacciones/transaccion/${id}/`;
  return makeRequest(url, "DELETE", token);
}

// Función para eliminar 
export async function retrieveTransaccionesApi(id,token,schema) {
  const url = `${BASE_URL}${schema}/transacciones/transaccion/${id}/`;
  return makeRequest(url, "GET", token);
}