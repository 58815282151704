

export const ExcelColumns = [
  {
    header: "Numero de orden",
    name: "n_orden",
    group: 'general',
    maxWidth: 1000, defaultFlex: 1
  },
  {
    header: "Producto",
    name: "producto_nombre",
    group: 'producto',
    maxWidth: 1000, defaultFlex: 1
  },
  {
    header: "Cliente",
    name: "cliente_nombre",
    maxWidth: 1000, defaultFlex: 1
  },
  {
    header: "API",
    name: "api",
    group: 'producto',
    maxWidth: 90, defaultFlex: 1,
  },
  {
    header: "Conductor",
    name: "conductor_nombre",
    group: 'transporte',
    maxWidth: 1000, defaultFlex: 1
  },
  {
    header: "Vehículo",
    name: "vehiculo_nombre",
    group: 'transporte',
    maxWidth: 1000, defaultFlex: 1
  },
  
  {
    header: "Remolque",
    name: "remolque_nombre",
    group: 'transporte',
    maxWidth: 1000, defaultFlex: 1
  },
  {
    header: "Autorizado",
    name: "tiempo_auth",
    maxWidth: 1000, defaultFlex: 1
  },
 
];

export const groups = [
  { name: 'transporte', header: 'Transporte' },
  { name: 'general', header: 'General' },
  { name: 'producto', header: 'Producto' },
]

export const filterValue = [
    {
        name: 'time_created',
        operator: 'contains',
        type: 'string',
        value: ''
      },
      { name: 'n_orden', operator: 'startsWith', type: 'string', value: '' },
    { name: 'v_auth', operator: 'startsWith', type: 'string', value: '' },
    { name: 'estado_orden', operator: 'startsWith', type: 'string', value: '' },
    { name: 'observacion_orden', operator: 'startsWith', type: 'string', value: '' },
    { name: 'cliente_nombre', operator: 'startsWith', type: 'string', value: '' },
    { name: 'transportadora_nombre', operator: 'startsWith', type: 'string', value: '' },
    { name: 'vehiculo_nombre', operator: 'startsWith', type: 'string', value: '' },
    { name: 'conductor_nombre', operator: 'startsWith', type: 'string', value: '' },
    { name: 'remolque_nombre', operator: 'startsWith', type: 'string', value: '' },
    {
        name: 'fecha_initcargue',
        operator: 'before',
        type: 'date',
        value: ''
      },
      {
        name: 'fecha_endcargue',
        operator: 'before',
        type: 'date',
        value: ''
      },
      {
        name: 'fecha_auth',
        operator: 'before',
        type: 'date',
        value: ''
      },
    { name: 'bsw', operator: 'startsWith', type: 'string', value: '' },
    { name: 'api', operator: 'startsWith', type: 'string', value: '' },
    
  ];