import { BASE_API } from "../../utils/constants";
// Esta función realiza una solicitud GET a una ruta específica de la API (`${BASE_API}/...`)
// Proporciona un token de autorización en el encabezado de la solicitud.
// La respuesta se convierte en un objeto JSON y se devuelve junto con el objeto de respuesta.
// En caso de error, arroja un error.
export async function getMulticlienteApi(token,schema) {
    try {
      const url = `${BASE_API}/${schema}/multicliente/multicliente`;
      const params = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(url, params);
      const result = await response.json();
      
      return [result,response];
    } catch (error) {
      throw error;
    }
  }
   // Esta función realiza una solicitud POST a una ruta específica de la API (`${BASE_API}/...`)
// Proporciona un token de autorización en el encabezado de la solicitud y los datos en el cuerpo de la solicitud en formato JSON.
// La respuesta se convierte en un objeto JSON y se devuelve junto con el objeto de respuesta.
// En caso de error, arroja un error. 
  export async function addMulticlienteApi(data, token,schema) {
    const formData = new FormData();
    formData.append("nombre", data.nombre);
    formData.append("foto", data.foto);
    formData.append("start_licencia", data.start_licencia);
    formData.append("end_licencia", data.end_licencia);
    
    
    try {
      const url = `${BASE_API}/${schema}/multicliente/multicliente/`;
      const params = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          // "Content-Type": "application/json",
        },
        body: formData,
      };
      const response = await fetch(url, params);
      const result = await response.json();
      
    
      return [result,response];
    } catch (error) {
      throw error;
    }
  }
  // Esta función realiza una solicitud PUT a una ruta específica de la API (`${BASE_API}/...`)
// Proporciona un token de autorización en el encabezado de la solicitud, el id especificado y los datos en el cuerpo de la solicitud en formato JSON.
// La respuesta se convierte en un objeto JSON y se devuelve junto con el objeto de respuesta.
// En caso de error, arroja un error.
  export async function updateMulticlienteApi(id, data, token,schema) {
    const formData = new FormData();
    formData.append("nombre", data.nombre);
    if (data.foto !== null) {
      formData.append("foto", data.foto);
    }
    formData.append("start_licencia", data.start_licencia);
    formData.append("end_licencia", data.end_licencia);
    
    try {
      const url = `${BASE_API}/${schema}/multicliente/multicliente/${id}/`;
      const params = {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          // "Content-Type": "application/json",
        },
        body: formData,
      };
  
      const response = await fetch(url, params);
      const result = await response.json();
      
      return [result,response];
    } catch (error) {
      throw error;
    }
  }
      // Esta función realiza una solicitud DELETE a una ruta específica de la API (`${BASE_API}/...`)
// Proporciona un token de autorización en el encabezado de la solicitud y el id especificado.
// La respuesta se convierte en un objeto JSON y se devuelve junto con el objeto de respuesta.
// En caso de error, arroja un error.
  export async function deleteMulticlienteApi(id, token,schema) {
    try {
      const url = `${BASE_API}/${schema}/multicliente/multicliente/${id}/`;
      const params = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
  
      const response = await fetch(url, params);
      const result = await response.json();
      return [result,response];
    } catch (error) {
      throw error;
    }
  }