import React, { useEffect, useState, useCallback } from "react";
import Swal from "sweetalert2"
import { AddEditAsignacion, AddEditAsignacionTcs, AddEditCInterno, AddEditConsumo } from "../../../Components/Client";
import { useConsumo } from "../../../hooks";

export function Funciones() {
    const { deleteConsumo, deleteConsumoInterno } = useConsumo()
    const [showModal, setShowModal] = useState(false);
    const [titleModal, setTitleModal] = useState(null);
    const [contentModal, setContentModal] = useState(null);
    const [refetch, setRefetch] = useState(false);

    const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
    const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);

    const handleAdd = useCallback(() => {
        setTitleModal("Nueva operación de suministro");
        setContentModal(
            <AddEditAsignacion
                onClose={openCloseModal}
                onRefetch={onRefetch}
            />
        );
        openCloseModal();
    }, [openCloseModal, onRefetch]);

    const handleAddWTcs = useCallback(() => {
        setTitleModal("Nueva operación con TCS3000")
        setContentModal(
            <AddEditAsignacionTcs
                onClose={openCloseModal}
                onRefetch={onRefetch}
            />
        )
        openCloseModal()
    }, [openCloseModal, onRefetch])

    const handleUpdate = useCallback((data) => {
        setTitleModal("Actualizar asignación");
        setContentModal(
            <AddEditAsignacion
                onClose={openCloseModal}
                onRefetch={onRefetch}
                Consumo={data}
            />
        );
        openCloseModal();
    }, [openCloseModal, onRefetch]);

    const handleDelete = useCallback(async (data) => {
        const result = await Swal.fire({
            title: "¿Estás seguro?",
            text: "No podrás revertir esta acción!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "No, cancelar!",
            reverseButtons: true,
        });

        if (result.isConfirmed) {
            await deleteConsumo(data.id_table);
            Swal.fire("Eliminado!", "El dato ha sido eliminado.", "success");
            onRefetch();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Cancelado", "Operación cancelada", "error");
        }
    }, [deleteConsumo, onRefetch]);

    const handleShowTicket = useCallback(async (data) => {
        const tableRows = Object.keys(data?.ticket_data).map((key) => (
            <tr key={key}>
                <td>{data.ticket_data[key]}</td>
            </tr>
        ));

        setTitleModal("Tiquete de entrega desde TCS3000");
        setContentModal(
            <>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <table style={{ borderCollapse: 'collapse', border: '1px solid black', width: '50%', margin: 'auto', borderSpacing: '0' }}>
                        <thead>
                            <tr>
                                <th style={{ padding: '10px', border: '1px solid black', width: '20%' }}>Modelo</th>
                                <th style={{ padding: '10px', border: '1px solid black', width: '20%' }}>Direccion IP</th>
                                <th style={{ padding: '10px', border: '1px solid black', width: '20%' }}>Serial</th>
                                <th style={{ padding: '10px', border: '1px solid black', width: '20%' }}>Serial SYZ</th>
                                <th style={{ padding: '10px', border: '1px solid black', width: '20%' }}>Vehículo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ padding: '10px', textAlign: 'center', border: '1px solid black' }}>{data.modelo}</td>
                                <td style={{ padding: '10px', textAlign: 'center', border: '1px solid black' }}>{data.tcs_model_data.ip}</td>
                                <td style={{ padding: '10px', textAlign: 'center', border: '1px solid black' }}>{data.tcs_model_data.serial_equipo}</td>
                                <td style={{ padding: '10px', textAlign: 'center', border: '1px solid black' }}>{data.tcs_model_data.serial_syz}</td>
                                <td style={{ padding: '10px', textAlign: 'center', border: '1px solid black' }}>{data.placa}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <hr></hr>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <table style={{ border: '1px solid black' }}>
                        <tbody>
                            <tr>
                                <td style={{padding:'10px', fontFamily: 'Consolas, monospace'}}>{tableRows}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </>
        )
        openCloseModal()
    }, [openCloseModal, onRefetch])

    

    const handleAddConsumoInterno = useCallback(() => {
        setTitleModal("Nuevo registro de aprovisionamiento");
        setContentModal(
            <AddEditCInterno
                onClose={openCloseModal}
                onRefetch={onRefetch}
            />
        );
        openCloseModal();
    }, [openCloseModal, onRefetch]);

    const handleUpdateConsumoInterno = useCallback((data) => {
        setTitleModal("Actualizar asignación");
        setContentModal(
            <AddEditCInterno
                onClose={openCloseModal}
                onRefetch={onRefetch}
                ConsumoInterno={data}
            />
        );
        openCloseModal();
    }, [openCloseModal, onRefetch]);

    const handleDeleteConsumoInterno = useCallback(async (data) => {
        const result = await Swal.fire({
            title: "¿Estás seguro?",
            text: "No podrás revertir esta acción!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "No, cancelar!",
            reverseButtons: true,
        });

        if (result.isConfirmed) {
            await deleteConsumo(data.id_consumo_int);
            Swal.fire("Eliminado!", "El dato ha sido eliminado.", "success");
            onRefetch();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Cancelado", "Operación cancelada", "error");
        }
    }, [deleteConsumo, onRefetch]);

    return {
        handleAdd,
        handleAddWTcs,
        handleDelete,
        handleUpdate,
        handleAddConsumoInterno,
        handleDeleteConsumoInterno,
        handleUpdateConsumoInterno,
        openCloseModal,
        refetch,
        contentModal,
        titleModal,
        showModal,
        handleShowTicket,
    }
}