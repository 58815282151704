import { useFormik, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { alertas } from "../../Comons/Alertas/alertas";
import { useConsumo, useTanques, useMaquinas, useVehiculos, useControladores, useTcs3000, useProductoTanque } from "../../../hooks";
import { tanqueSelectWProd, tcsSelect, tcsProdSelect } from "../../Comons"
import { useAuth } from "../../../hooks";

import { ASGI_API } from "../../../../src/vistas/utils/constants"

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import CircularProgress from '@mui/material/CircularProgress';
import { Autocomplete } from '@mui/material'

export function AddEditAsignacion(props) {
    const { auth } = useAuth()
    const { Successful, errores } = alertas();
    const { onClose, onRefetch, Consumo } = props;
    const { addConsumo, updateConsumo, addConsumoWTcs, loadingTcsOp, respTicket } = useConsumo()
    const { ProductoTanque, getProductoTanque } = useProductoTanque()
    const { TanquesCI, getTanquesCI } = useTanques()
    const { Maquinas, getMaquinas } = useMaquinas()
    const { VehiculosDB, getVehiculosDB } = useVehiculos()
    const { CtrlRegCom, getControladorRegComb } = useControladores()
    const { products, getTcsProd } = useTcs3000()
    const [tcsModels, setTcsModels] = useState([])
    const [dataTanqueCI, setTanqueCI] = useState([])
    const [existsInMaquinas, setExistInMaquina] = useState([])
    const [existsInVehiculos, setExistsInVehiculos] = useState([])
    const [desplazamientoMaq, setDesplazamientoMaq] = useState([])
    const [desplazamientoVeh, setDesplazamientoVeh] = useState([])
    const [condOrOp, setCondOrOp] = useState([])
    const [condOrOpId, setCondOrOpId] = useState([])
    const [placaExists, setPlacaExists] = useState(false);
    const [isAuto, setIsAuto] = useState(false)
    const [tcsProds, setTcsProds] = useState([])
    const [relationType, setRelationType] = useState([])
    const [relationFlag, setRelationFlag] = useState("tanque")
    const [ws, setWs] = useState(null);
    const [pulsos, setPulsos] = useState([])
    const [isFinished, setIsFinished] = useState(false)
    const operationOptions = [
        { key: 'manual', value: 'manual', text: 'Operación manual' },
        // { key: 'auto', value: 'auto', text: 'Operación con máquina registradora' },
    ];
    const [selectedOperation, setSelectedOperation] = useState();
    const [error, setError] = React.useState(false);

    const handleOperationChange = (event) => {
        setSelectedOperation(event.target.value);
    };

    useEffect(() => {
        getMaquinas()
        getVehiculosDB()
        getProductoTanque()
        // getTanquesCI()
        getControladorRegComb()
        getTcsProd()
    }, []);

    useEffect(() => {
        setTanqueCI(tanqueSelectWProd(ProductoTanque?.results))
        setTcsModels(tcsSelect(CtrlRegCom))
        setTcsProds(tcsProdSelect(products))
    }, [ProductoTanque, CtrlRegCom, products])

    // useEffect(() => {
    //     const websocket = new WebSocket(`${ASGI_API}/data/getPulsos`); // URL del web socket

    //     setWs(websocket);

    //     websocket.onopen = () => {
    //         console.log("Conexión con el WS abierta");
    //     };

    //     websocket.onclose = () => {
    //         console.log("Conexión con el WS cerrada");
    //     };

    //     return () => {
    //         // Cierra la conexión cuando el componente se cierra o se recarga
    //         if (ws) {
    //             ws.close();
    //         }
    //     };

    // }, []);

    const formik = useFormik({
        initialValues: initialValues(Consumo, auth.me.SistemasMedicion.volumen, { placa_obj: '' }),
        validationSchema: Yup.object(Consumo ? updateScheme() : newScheme()),
        validateOnChange: false,
        onSubmit: async (formValue) => {

            try {

                if (!formValue.id_conductor) {
                    throw new Error("El vehículo o máquina debe estar asociado con un conductor");
                } else {

                    formValue.medida_consumo = auth.me.SistemasMedicion.volumen
                    formValue.id_operador = auth.me.id_usuario
                    switch (formValue.desplazamiento) {
                        case "Estacionario":
                            formValue.trabajo_act = "0"
                            break
                        case "Móvil":
                            formValue.hrs_act = "0"
                            break
                        case "Móvil - Grandes distancias":
                            formValue.hrs_act = "0"
                            break
                    }

                    if (selectedOperation === "auto") {
                        // if (ws) {
                        //     // Subscribe to WebSocket messages before sending data
                        //     ws.onmessage = (event) => {
                        //         const receivedData = JSON.parse(event.data);
                        //         setPulsos(receivedData)
                        //         console.log("Pulsos desde el web socket", receivedData);
                        //         // Handle received data as needed
                        //     };
                        // }

                        if (relationFlag === "tanque") {
                            formValue.tank_or_vehicle = 1
                        } else {
                            formValue.tank_or_vehicle = 2
                        }

                        // await addConsumoWTcs(formValue);

                        // setIsFinished(true)

                        // if (!loadingTcsOp) {
                        //     ws.close()
                        // }

                        //onRefetch();
                        //onClose();
                        //Successful()
                    } else if (selectedOperation === "manual") {
                        formValue.controlador = 0
                        formValue.tank_or_vehicle = 1
                        console.log(formValue)
                        // ws.close()
                        //Si se proporciona un objeto se actualiza, de lo contrario se crea uno nuevo
                        if (Consumo) await updateConsumo(Consumo.id_table, formValue);
                        else await addConsumo(formValue);
                        onRefetch();
                        onClose();
                        Successful()
                    }

                    // formValue.controlador = 0
                    // formValue.tank_or_veh = 1
                    // console.log(formValue)
                    // ws.close()
                    // //Si se proporciona un objeto se actualiza, de lo contrario se crea uno nuevo
                    // if (Consumo) await updateConsumo(Consumo.id_table, formValue);
                    // else await addConsumo(formValue);

                }
            } catch (error) {
                // Muestra un mensaje de error si ocurre algún problema
                if (error?.message) {
                    toast.error(error.message, {
                        position: "top-center",
                    });
                } else {
                    onClose();
                }
            }
        },
    })

    const handlePlacaChange = (event) => {
        formik.setFieldValue('placa_obj', event.target.value)
        const placaObj = event.target.value;

        const existsInMaq = Maquinas.find((maquina) => maquina.placa == placaObj);
        const existsInVeh = VehiculosDB.find((vehiculo) => vehiculo.placa_veh == placaObj);

        if (existsInMaq != undefined) {
            setExistInMaquina(existsInMaq)
            setDesplazamientoMaq(existsInMaq.desplazamiento)
            setCondOrOp(existsInMaq.conductor?.nombre + " " + existsInMaq.conductor?.apellidos)
            setCondOrOpId(existsInMaq.conductor.id_operador)

            formik.setFieldValue('nombre_cond_op', existsInMaq.conductor?.nombre + " " +
                existsInMaq.conductor?.apellidos)
            formik.setFieldValue('desplazamiento', existsInMaq.desplazamiento)
            formik.setFieldValue('id_conductor', existsInMaq.conductor?.id_operador)
            setPlacaExists("Maquina");

        } else if (existsInVeh != undefined) {
            setExistsInVehiculos(existsInVeh)
            setDesplazamientoVeh(existsInVeh.desplazamiento)
            setCondOrOp(existsInVeh.conductor?.nombre + " " + existsInVeh.conductor?.apellidos)
            setCondOrOpId(existsInVeh.conductor.id_conductor)

            formik.setFieldValue('nombre_cond_op', existsInVeh.conductor?.nombre + " " +
                existsInVeh.conductor?.apellidos)
            formik.setFieldValue('desplazamiento', existsInVeh.desplazamiento)
            formik.setFieldValue('id_conductor', existsInVeh.conductor?.id_conductor)
            setPlacaExists("Vehiculo");
        } else {
            setPlacaExists("None")
        }
    };

    const divStyle = {
        textAlign: "left",
    };

    if (!Maquinas || !VehiculosDB) {
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
        </div>
    }

    return (
        <>
            {loadingTcsOp || isFinished ? (
                <>
                    <h3>{isFinished ? "Operación completada con éxito" : "Realizando operación, espere..."}</h3>
                    <br></br>
                    {!isFinished ? <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                            <CircularProgress />
                        </div></> : <></>
                    }
                    <br></br>
                    <h5>Suministrado</h5>
                    <div style={{ textAlign: 'center', fontSize: '58px', fontFamily: 'Consolas' }}>
                        {pulsos ? pulsos.pulsos : "0"}
                    </div>
                    <h5>{isFinished ? "Tiquete generado" : ""}</h5>
                    {isFinished && respTicket ? <ul>
                        {Object.entries(respTicket).map(([key, value], index) => (
                            <li key={index}>
                                <strong>{key}</strong>: <span style={{ textAlign: 'center' }}>{value}</span>
                            </li>
                        ))}
                    </ul> : <></>}
                </>
            ) : (
                <>
                    <Formik initialValues={initialValues()}>
                        <form className="add-edit-secciones-form" style={divStyle} onSubmit={formik.handleSubmit}>
                            <Grid container spacing={3}>
                                {/* primera fila */}
                                <Grid item xs={12} sm={12}>
                                    <Autocomplete
                                        id="operacion"
                                        options={operationOptions}
                                        getOptionLabel={(opt) => opt.text}
                                        value={operationOptions.find((opt) => opt.value === formik.values?.tipo_operacion) || null}
                                        onChange={(_, value) => {
                                            formik.setFieldValue('tipo_operacion', value?.value || '');
                                            handleOperationChange({ target: { value: value?.value || '' } });
                                            setError(!value || !value.value); // Check if selection is empty and set error accordingly
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Tipo de operación"
                                                variant="outlined"
                                                error={error}
                                                helperText={error ? 'Es necesario una selección' : ''}
                                            />
                                        )}
                                    />
                                    {/* <FormControl fullWidth>
                                        <InputLabel id="operacionlb">Tipo de operación</InputLabel>
                                        <Select
                                            labelId="operacionlb"
                                            id="operacion"
                                            label="Tipo de operación"
                                            fullWidth
                                            value={formik.values?.tipo_operacion}
                                            onChange={(event) => {
                                                formik.setFieldValue('tipo_operacion', event.target.value);
                                                handleOperationChange(event);
                                            }}
                                            error={formik.errors.tipo_operacion}
                                        >
                                            {operationOptions.map((opt) => (
                                                <MenuItem key={opt.key} value={opt.value}>
                                                    {opt.text}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl> */}
                                </Grid>

                                {/* segunda fila */}
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Placa"
                                        name="placa_obj"
                                        placeholder="Placa"
                                        value={formik.values.placa_obj}
                                        error={formik.errors.placa_obj}
                                        onChange={handlePlacaChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        required
                                        fullWidth
                                        disabled
                                        label="Desplazamiento"
                                        name="desplazamiento"
                                        placeholder="Ej:Estacionario"
                                        value={existsInMaquinas != undefined && placaExists === "Maquina" ? desplazamientoMaq : existsInVehiculos != undefined && placaExists === "Vehiculo" ? desplazamientoVeh : ""}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Hr de uso"
                                        name="hrs_uso"
                                        value={formik.values.hrs_uso}
                                        onChange={formik.handleChange}
                                        error={formik.errors.hrs_uso}
                                        disabled={formik.values.desplazamiento === "Móvil" || formik.values.desplazamiento == "Móvil - Grandes distancias" || formik.values.desplazamiento == ""}
                                        enabled={formik.values.desplazamiento === "Mixto" || formik.values.desplazamiento === "Estacionario"}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Km de uso"
                                        name="trabajo_act"
                                        value={formik.values.trabajo_act}
                                        onChange={formik.handleChange}
                                        error={formik.errors.trabajo_act}
                                        disabled={formik.values.desplazamiento == "Estacionario" || formik.values.desplazamiento == ""}
                                        enabled={formik.values.desplazamiento == "Móvil" || formik.desplazamiento == "Móvil - Grandes distancias" || formik.values.desplazamiento === "Mixto"}
                                    />
                                </Grid>

                                {/*  tercera fila */}
                                {selectedOperation === 'manual' ? (
                                    <>
                                        <Grid item xs={12} sm={4}>
                                            <FormControl fullWidth>
                                                <InputLabel id="origenlb">Tanque</InputLabel>
                                                <Select
                                                    labelId="origenlb"
                                                    name="id_origen"
                                                    label="Tanque"
                                                    fullWidth
                                                    value={formik.values?.id_origen}
                                                    onChange={(event, data) => {
                                                        formik.setFieldValue('id_origen', event.target.value);
                                                        const selectedTanque = dataTanqueCI.find(option => option.value === event.target.value);
                                                        const tipoCombustible = selectedTanque && selectedTanque.prod_id ? selectedTanque.prod_id : "";
                                                        const tipoCombustibleNombre = selectedTanque && selectedTanque.producto ? selectedTanque.producto : ""
                                                        const capacidad_actual = selectedTanque && selectedTanque.cap_act ? selectedTanque.cap_act : "0"
                                                        formik.setFieldValue('tipo_combustible', tipoCombustible);
                                                        formik.setFieldValue('cap_act', capacidad_actual)
                                                        formik.setFieldValue('tipo_combustible_nombre', tipoCombustibleNombre)
                                                    }}
                                                    error={formik.errors.id_origen}
                                                >
                                                    {dataTanqueCI.map((opt) => (
                                                        <MenuItem key={opt.key} value={opt.value}>
                                                            {opt.text}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                fullWidth
                                                disabled
                                                fluid
                                                label="Capacidad actual"
                                                name="cap_act"
                                                id="cap_act"
                                                value={formik.values.cap_act}
                                                onChange={formik.handleChange}
                                                error={formik.errors.cap_act}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                fullWidth
                                                disabled
                                                fluid
                                                label="Tipo de combustible"
                                                name="tipo_combustible_nombre"
                                                id="tipo_combustible_nombre"
                                                value={formik.values.tipo_combustible_nombre}
                                                onChange={formik.handleChange}
                                                error={formik.errors.tipo_combustible_nombre}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                required
                                                fluid
                                                fullWidth
                                                label="Cantidad de consumo"
                                                name="cant_consumo"
                                                placeholder="Ej:10gl"
                                                value={formik.values.cant_consumo}
                                                onChange={formik.handleChange}
                                                error={formik.errors.cant_consumo}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                disabled
                                                fluid
                                                fullWidth
                                                label="Medida"
                                                name="medida_consumo"
                                                value={formik.values.medida_consumo}
                                                onChange={formik.handleChange}
                                                error={formik.errors.medida_consumo}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                fluid
                                                fullWidth
                                                label="Precio de consumo"
                                                name="precio_consumo"
                                                placeholder="Ej:20000"
                                                value={formik.values.precio_consumo}
                                                onChange={formik.handleChange}
                                                error={formik.errors.precio_consumo}
                                            />
                                        </Grid>
                                    </>
                                ) : selectedOperation === 'auto' ? (
                                    <>
                                        <Grid item xs={12} sm={3}>
                                            <FormControl fullWidth>
                                                <InputLabel id="controladorlb">Máquina registradora</InputLabel>
                                                <Select
                                                    labelId="controladorlb"
                                                    name="controlador"
                                                    label="Máquina registradora"
                                                    fullWidth
                                                    value={formik.values?.controlador}
                                                    onChange={(event, data) => {
                                                        formik.setFieldValue('controlador', event.target.value);
                                                        const selectedCtrl = CtrlRegCom?.find(option => option.id_controlador === event.target.value);
                                                        const objStr = selectedCtrl && selectedCtrl.tank ? selectedCtrl.tank.nombre : selectedCtrl.vehicle.placa_veh
                                                        formik.setFieldValue('origen_text', objStr)
                                                        formik.setFieldValue('id_origen', selectedCtrl && selectedCtrl.relacionTanque ? selectedCtrl.relacionTanque : selectedCtrl.relacionVehiculo)
                                                        setRelationType(data.relacionTanque ? data.relacionTanque : data.relacionVehiculo)
                                                        setRelationFlag(data.relacionTanque ? "tanque" : "vehiculo")
                                                    }}
                                                    error={formik.errors.controlador}
                                                >
                                                    {tcsModels.map((opt) => (
                                                        <MenuItem key={opt.key} value={opt.value}>
                                                            {opt.text}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={3}>
                                            <FormControl fullWidth>
                                                <InputLabel id="productolb">Producto</InputLabel>
                                                <Select
                                                    labelId="productolb"
                                                    name="tipo_combustible"
                                                    label="Producto"
                                                    fullWidth
                                                    value={formik.values.tipo_combustible}
                                                    onChange={(event) => {
                                                        formik.setFieldValue('prod_id', event.target.value);
                                                        const selectedProd = products?.find(option => option.prod_id == event.target.value);
                                                        formik.setFieldValue('tipo_combustible', selectedProd.prod_id)
                                                    }}
                                                    error={formik.errors.tipo_combustible}
                                                >
                                                    {tcsProds.map((opt) => (
                                                        <MenuItem key={opt.key} value={opt.value}>
                                                            {opt.text}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                fullWidth
                                                disabled
                                                fluid
                                                label={relationFlag === "tanque" ? "Tanque" : "Carro tanque"}
                                                name="origin_text"
                                                id="origin_text"
                                                value={formik.values.origen_text}
                                                onChange={formik.handleChange}
                                                error={formik.errors.origen_text}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                fullWidth
                                                fluid
                                                label="Cantidad para suministrar"
                                                name="origin_consumo"
                                                value={formik.values.cant_consumo}
                                                onChange={formik.handleChange}
                                                error={formik.errors.cant_consumo}
                                            />
                                        </Grid>
                                    </>
                                ) : (<></>)}

                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        disabled
                                        fullWidth
                                        label={"Conductor del vehículo/máquina"}
                                        name="nombre_cond_op"
                                        value={
                                            existsInMaquinas != undefined && placaExists == "Maquina" ?
                                                condOrOp : existsInVehiculos != undefined && placaExists == "Vehiculo" ?
                                                    condOrOp : ""}
                                        onChange={formik.handleChange}
                                        error={formik.errors.nombre_cond_op}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        hidden
                                        disabled
                                        fullWidth
                                        name="tipo_combustible"
                                        id="tipo_combustible"
                                        value={formik.values.tipo_combustible}
                                        onChange={formik.handleChange}
                                        error={formik.errors.tipo_combustible}
                                    />
                                </Grid>



                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        hidden
                                        disabled
                                        fullWidth
                                        name="id_conductor"
                                        value={existsInMaquinas != undefined && placaExists == "Maquina" ?
                                            condOrOpId : existsInVehiculos != undefined && placaExists == "Vehiculo" ?
                                                condOrOpId : ""}
                                        onChange={formik.handleChange}
                                        error={formik.errors.id_conductor}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        hidden
                                        disabled
                                        fullWidth
                                        name="id_origen"
                                        id="id_origen"
                                        value={formik.values.id_origen}
                                        onChange={formik.handleChange}
                                        error={formik.errors.id_origen}
                                    />
                                </Grid>
                            </Grid>

                            {selectedOperation ? (
                                <>
                                    <Grid item xs={12} sm={12}>
                                        <Button type="submit" className="btn btn-danger" color="error" fullWidth>
                                            {Consumo ? "Actualizar" : "Crear"}
                                        </Button>
                                    </Grid>
                                </>
                            ) : (<></>)}
                        </form>
                    </Formik>
                </>
            )}
        </>
    )
}

function initialValues(data, data2) {
    return {
        placa_obj: data?.placa_obj || "",
        tipo_obj: data?.tipo_obj || "",
        cant_consumo: data?.cant_consumo || "0",
        tipo_combustible: data?.tipo_combustible || "",
        tipo_combustible_nombre: data?.tipo_combustible_nombre || "",
        precio_consumo: data?.precio_consumo || "0",
        medida_consumo: data?.medida_consumo || data2,
        trabajo_act: data?.trabajo_act || "0",
        id_operador: data?.id_operador || 0,
        id_conductor: data?.id_conductor || "",
        hrs_uso: data?.hrs_uso || "0",
        desplazamiento: "",
        id_origen: data?.id_origen || null,
        cap_act: data?.cap_act || "",
        tipo_operacion: data?.tipo_operacion || "",
        nombre_cond_op: undefined,
        controlador: data?.controlador || 0,
        origen_text: data?.origen_text || "",
        tank_or_vehicle: data?.tank_or_vehicle || ""
    };
}

function newScheme() {
    return {
        placa_obj: Yup.string(),
        tipo_obj: Yup.string(),
        cant_consumo: Yup.string().required(true),
        tipo_combustible: Yup.string(),
        precio_consumo: Yup.string(),
        medida_consumo: Yup.string(),
        trabajo_act: Yup.string().required(true),
        id_conductor: Yup.string(),
        hrs_uso: Yup.string().required(true),
        id_origen: Yup.string(),
        tipo_operacion: Yup.string(),
        nombre_cond_op: Yup.string(),
        tank_or_vehicle: Yup.number(),
    };
}

function updateScheme() {
    return {
        placa_obj: Yup.string(),
        tipo_obj: Yup.string(),
        cant_consumo: Yup.string().required(true),
        tipo_combustible: Yup.string(),
        precio_consumo: Yup.string(),
        medida_consumo: Yup.string(),
        trabajo_act: Yup.string().required(true),
        id_conductor: Yup.string(),
        hrs_uso: Yup.string().required(true),
        id_origen: Yup.string(),
        tipo_operacion: Yup.string(),
        nombre_cond_op: Yup.string(),
        controlador: Yup.string(),
        origen_text: Yup.string(),
        tank_or_vehicle: Yup.number(),
    };
}