import { useState } from "react";
import { useAuth } from "..";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import {
  updateScriptApi,
  getScriptApi
} from "../../api/A_Conexiones/script";



export function useScript() {
  const { auth, logout } = useAuth();
  const { unauthorized, SessionExpired, unauthorizedModal, errores } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    Script: null,
    
  });


  const { loading, error,Script} = state;


  const handleResponse = (response) => {
    if (response.status === 401) {
      SessionExpired();
      logout();
    } else if (response.status === 403) {
      unauthorized();
    }
  };


  const handleAddOrUpdateError = () => {
    errores();
  };
  
  const getScript = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getScriptApi(auth.token, auth.me.tenant);
      
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, Script: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  

  const updateScript = async (id, data) => {
    console.log(id)
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateScriptApi(id, data, auth.token, auth.me.tenant);
      if (response.status === 400) {
        handleAddOrUpdateError();
      }
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };


 
  return {
    loading,
    error,
    Script,
    getScript,
    updateScript,
  
   
  };
}
