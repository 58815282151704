
import { BASE_API } from "../../utils/constants";
import { makeRequest } from "../Request";
const BASE_URL = `${BASE_API}/`;



// Función para actualizar los datos de un cliente existente
export async function getHazardApi(data, token, schema) {
  const url = `${BASE_URL}${schema}/conexiones/create_hazard/`;
  return makeRequest(url, "POST", token, data);
}
// Función para actualizar los datos de un cliente existente
export async function updateHazardApi(id, data, token, schema) {
  const url = `${BASE_URL}${schema}/conexiones/hazard/controladores/${id}/`;
  return makeRequest(url, "PUT", token, data);
}

export async function patchHazardApi(id, data, token, schema) {
  const url = `${BASE_URL}${schema}/conexiones/hazard/controladores/${id}/`;
  return makeRequest(url, "PATCH", token, data);
}

