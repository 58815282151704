import { createSlice } from "@reduxjs/toolkit";

// The initial state of the Global container
export const initialState = {
  main: {
    loadingDock: [],
    loadingDockTable: [],
  },
};

const slice = createSlice({
  name: "loadingDock",
  initialState,
  reducers: {
    // Actions
    setLoadingDock: (state, action) => {
      state.main.loadingDock = action.payload;
    },
    setLoadingDockTable: (state, action) => {
      state.main.loadingDockTable = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = slice;
