import React from "react";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { tieneError: false, mensajeError: "" };
  }

  static getDerivedStateFromError(error) {
    // Método 1
    // Este método se ejecuta cuando ocurre un error dentro de los componentes hijos de este componente
    // Establece el estado "tieneError" y el mensaje del error
    return { tieneError: true, mensajeError: error.message };
  }

  componentDidCatch(error) {
    // Método 2
    // Este método se ejecuta cuando ocurre un error dentro de los componentes hijos de este componente
    // Sirve para registrar el error en algún servicio de seguimiento de errores o para realizar otras acciones
    // console.log("Component did catch:", error.message);
  }

  render() {
    if (this.state.tieneError) {
      // "UI de emergencia"
      // Si ocurre un error, se muestra una UI de emergencia con un mensaje y un botón para recargar

      return (
        <div className="px-4 py-2 m-4 justify-content-center align-items-center">
          <h1 className="mt-2 font-bold text-lg mb-1">Se produjo un error</h1>
          <p> {this.state.mensajeError} </p>
          <button
          class="btn btn-success"
            className="px-4 py-2 rounded bg-blue-800 hover:bg-blue-700 active:outline text-sm"
            onClick={() => (window.location.href = "/")}
          >
            Recargar la página{" "}
          </button>
        </div>
      );
    }
// Si no hay errores, se renderiza el contenido normal
return this.props.children;
  }
}

export default ErrorBoundary;