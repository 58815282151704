

  import { BASE_API } from "../../utils/constants";
  import { makeRequest } from "../Request";
  const BASE_URL = `${BASE_API}/`;
  
  // Función para obtener los datos 
  export async function getDataImpresionDescargaderApi(id,token,schema) {
    const url = `${BASE_URL}${schema}/descargadero/orden-descargadero/${id}/impresiones/`;
    return makeRequest(url, "POST", token);
  }
  
 
  
 