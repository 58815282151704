import { BASE_API } from "../utils/constants";
import { makeRequest } from "./Request";
const BASE_URL = `${BASE_API}/`;

// Función para obtener los datos 
export async function getIngresoDatosApi(token,schema) {
  const url = `${BASE_URL}${schema}/datos/datosC`;
  return makeRequest(url, "GET", token);
}

// Función para agregar un nuevo dato
export async function addIngresoDatosApi(data, token,schema) {
  console.log(data,"data");
  const url = `${BASE_URL}${schema}/datos/datosC/`;
  return makeRequest(url, "POST", token, data);
}

// Función para actualizar los datos 
export async function updateIngresoDatosApi(id, data, token,schema) {
  const url = `${BASE_URL}${schema}/datos/datosC/${id}/`;
  return makeRequest(url, "PUT", token, data);
}

// Función para eliminar 
export async function deleteIngresoDatosApi(id, token,schema) {
  const url = `${BASE_URL}${schema}/datos/datosC/${id}/`;
  return makeRequest(url, "DELETE", token);
}


// Función para obtener los datos 
export async function retriveIngresoDatosApi(id, token,schema) {
  const url = `${BASE_URL}${schema}/datos/datosC/${id}/`;
  return makeRequest(url, "GET", token);
}

// Función para obtener los datos 
export async function getDatosCalculadosApi(token,schema,id) {
  let url = `${BASE_URL}${schema}/datos/medicion/total`;
  if (id) {  
    url += `?id_tanque=${id}`;
  }
  return makeRequest(url, "GET", token);
}