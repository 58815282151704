import React, { useEffect, useState } from "react";
import { AdministracionPage } from '../AdministracionPage/AdministracionPage'
import { UserBaseAdmin } from '../../Client/UserBasePage'
import { MulticlienteTab } from '../MulticlienteTab'
import { Tab } from 'semantic-ui-react'

// import Tabs from "@mui/material/Tabs"
// import Tab from "@mui/material/Tab"

export function GroupedAdminPages() {

    // const [activeTab, setActiveTab] = useState(0);

    // const handleTabChange = (event, newValue) => {
    //     setActiveTab(newValue);
    // };

    // return (
    //     <>
    //         <Tabs value={activeTab} onChange={handleTabChange}>
    //             <Tab label="Licencias" />
    //             <Tab label="Clientes internos" />
    //             <Tab label="Módulos" />
    //         </Tabs>

    //         <br />

    //         {activeTab === 0 && (
    //             <>
    //                 <MulticlienteTab />
    //             </>
    //         )}

    //         {activeTab === 1 && (
    //             <>
    //                 <UserBaseAdmin />
    //             </>
    //         )}

    //         {activeTab === 2 && (
    //             <>
    //                 <AdministracionPage />
    //             </>
    //         )}
    //     </>
    // )
    const panes = [
        {
            menuItem: { key: 'MulticlienteTab', content: <> Licencias</> },
            render: () => <Tab.Pane><MulticlienteTab /></Tab.Pane>,
        },
        {
            menuItem: { key: 'UserBaseAdmin', content: <>Clientes internos</> },
            render: () => <Tab.Pane><UserBaseAdmin /></Tab.Pane>,
        },
        {
            menuItem: { key: 'AdministracionPage', content: <>Módulos</> },
            render: () => <Tab.Pane><AdministracionPage /></Tab.Pane>,
        },
    ]

    return (
        <Tab panes={panes} />
    )
}