import React, { useEffect, useState } from "react";
// import Tab from "@mui/material/Tab";
// import Tabs from "@mui/material/Tabs"
import { Tab } from 'semantic-ui-react'

import { ConfigTurnosAdmin, StartStopAccess } from "../index"

export function GroupedAccesoConfig() {

    const panes = [
        {
            menuItem: { key: 'confacceso', content: <>Configuración de acceso</> },
            render: () => <Tab.Pane><ConfigTurnosAdmin /></Tab.Pane>,
        },
        {
            menuItem: { key: 'procacceso', content: <>Proceso de acceso</> },
            render: () => <Tab.Pane><StartStopAccess /></Tab.Pane>,
        },
    ]

    return (
        <>
            <Tab panes={panes} />
        </>
    )
}