import { BASE_API } from "../../utils/constants";
import { makeRequest } from "../Request";

const BASE_URL = `${BASE_API}/`;


export async function getModulosApi(token,schema) {
    const url = `${BASE_URL}${schema}/multicliente/modulos`;
    return makeRequest(url, "GET", token);
  }

export async function updateModulosApi(id, data, token,schema) {
    const url = `${BASE_URL}${schema}/multicliente/modulos/${id}/`;
    return makeRequest(url, "PUT", token, data);
  }

  export async function updateModuloDocumentalApi(id, data, token,schema) {
    
    const url = `${BASE_URL}${schema}/multicliente/modulosactivos/${id}/`;
    return makeRequest(url, "PATCH", token, data);
  }
  
  