import { BASE_API } from "../../utils/constants";
import { makeRequest } from "../Request";
const BASE_URL = `${BASE_API}/`;


// Función para obtener  datos 
export async function getAccesosApi(token, schema) {
  const url = `${BASE_URL}${schema}/acceso/accesos`;
  return makeRequest(url, "GET", token);
}

export async function getConfigTurnosApi(token, schema) {
  const url = `${BASE_URL}${schema}/acceso/configt`;
  return makeRequest(url, "GET", token);
}

export async function updateConfigTurnosApi(data, token, schema) {
  const url = `${BASE_URL}${schema}/acceso/configt/updtConfigT/`;
  return makeRequest(url, "PATCH", token, data)
}

// Función para agregar un nuevo dato
export async function addAccesosApi(data, token, schema) {
  const url = `${BASE_URL}${schema}/acceso/accesos/`;
  return makeRequest(url, "POST", token, data);
}

export async function getDataFromBiometricApi(token, schema) {
  const url = `${BASE_URL}${schema}/acceso/getDeviceData`;
  return makeRequest(url, "GET", token)
}

export async function getPersonDataFromBiometricApi(data, token, schema) {
  const url = `${BASE_URL}${schema}/acceso/getPersonData/`
  return makeRequest(url, "POST", token, data)
}

export async function postPersonDataToBiometricApi(data, token, schema) {
  const url = `${BASE_URL}${schema}/acceso/postPersonData/`
  return makeRequest(url, "POST", token, data)
}

export async function postDataToBiometricApi(data, token, schema) {
  const url = `${BASE_URL}${schema}/acceso/postDeviceData/`
  return makeRequest(url, "POST", token, data)
}

export async function getIslaConfApi(token, schema) {
  const url = `${BASE_URL}${schema}/acceso/isla_conf`;
  return makeRequest(url, "GET", token);
}

export async function postIslaConfApi(data, token, schema) {
  const url = `${BASE_URL}${schema}/acceso/isla_conf/`
  return makeRequest(url, "POST", token, data)
}

export async function updateIslaConfApi(data, token, schema) {
  const url = `${BASE_URL}${schema}/acceso/isla_conf/`;
  return makeRequest(url, "PATCH", token, data)
}

export async function deleteIslaConfApi(id, token, schema) {
  const url = `${BASE_URL}${schema}/acceso/isla_conf/${id}`
  return makeRequest(url, "DELETE", token)
}

export async function start_access_proccess(data, token, schema) {
  const url = `${BASE_URL}${schema}/acceso/start_or_stop_access/`
  return makeRequest(url, "POST", token, data)
}


