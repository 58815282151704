
  
   
import { BASE_API } from "../../utils/constants";
import { makeRequest } from "../Request";
const BASE_URL = `${BASE_API}/`;

// Función para obtener los datos 
export async function getSistemaMedicionApi(token,schema) {
  const url = `${BASE_URL}${schema}/Configuraciones/sitemaMedicion`;
  return makeRequest(url, "GET", token);
}

// Función para obtener los datos 
export async function getSistemaMedicionAdminApi(token,schema) {
  const url = `${BASE_URL}${schema}/Configuraciones/sitemaMedicionadm`;
  return makeRequest(url, "GET", token);
}

// Función para actualizar los datos 
export async function updateSistemaMedicionApi(id, data, token,schema) {
  const url = `${BASE_URL}${schema}/Configuraciones/sitemaMedicionadm/${id}/`;
  return makeRequest(url, "PUT", token, data);
}
