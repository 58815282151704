import { Grid } from '@mui/material';
import { Table, Tag } from "antd";
import React, { useEffect, useState, useCallback } from 'react';
import LogoSyz from '../../../assets/logo_syzapi.png';
import { ASGI_API } from '../../../vistas/utils/constants';

const columns = [
  {
    title: "Turno",
    dataIndex: "n_turno",
    key: "n_turno",
  },
  {
    title: "Estado",
    dataIndex: "estado",
    key: "estado",
    render: (text) => {
      const getColor = () => {
        switch (text) {
          case "ingresado":
            return "green-inverse";
          case "Retomando":
            return "red-inverse";
          case "espera":
            return "yellow-inverse";
          case "enturnado":
            return "lime-inverse";
          case "finalizado":
            return "gray"
          case "en cargue":
            return "blue"
          default:
            return "green";
        }
      };

      return (
        <>
          <Tag
            color={getColor()}
            key={"tag"}
            style={{
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            {
              text === "enturnado" ? "esperando ingreso" : text
            }
          </Tag>
        </>
      );
    },
  },
  {
    title: "Orden",
    dataIndex: "orden",
    key: "orden",
  },
  {
    title: "Conductor",
    dataIndex: "conductor",
    key: "conductor",
  },

  {
    title: "Vehículo",
    dataIndex: "placa",
    key: "placa",
  },
  {
    title: "Proceso",
    dataIndex: "proceso",
    key: "proceso",
  },
  {
    title: "Bahía",
    dataIndex: "bahia",
    key: "bahia",
  },
  {
    title: "Tipo",
    dataIndex: "tipo_turno",
    key: "tipo_turno",
  }
  // {
  //   title: "Validación por rostro",
  //   dataIndex: "",
  //   key: ""
  // },
  // {
  //   title: "Validación por placa",
  //   dataIndex: "",
  //   key: ""
  // },
  // {
  //   title: "Validación por código",
  //   dataIndex: "",
  //   key: ""
  // }
];

export function TurnosVisualizacion() {
  const [fullscreen, setFullscreen] = useState(false);
  const [datos, setDatos] = useState([])

  // useEffect(() => {
  //   const obtenerTurnos = async () => {
  //     const respuesta = await fetch('http://localhost:5000/data_turnos');
  //     const datos = await respuesta.json();

  //     const joinedTurnos = datos.reduce((acc, curr) => acc.concat(curr), [])

  //     setDatos(joinedTurnos)
  //     // setTurnos1(datos[0])
  //     // setTurnos2(datos[1])
  //     // setTurnos3(datos[2])
  //   };

  //   obtenerTurnos();

  //   const intervalo = setInterval(() => {
  //     obtenerTurnos();
  //   }, 1000);
  //   return () => clearInterval(intervalo);
  // }, []);

  const connect_ws = useCallback(() => {
    const ws = new WebSocket(`${ASGI_API}/data/turnos_data`)

    ws.onopen = () => {
      console.log('WebSocket connected.');
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);

      console.log(data)

      const joinedTurnos = data.reduce((acc, curr) => acc.concat(curr), [])
      setDatos(joinedTurnos)
    };

    // cierra la conexión
    ws.onclose = () => {
      console.log('WebSocket disconnected. Intentando reconectar...');
      setTimeout(connect_ws, 3000); // intenta reconexión después de 3 segundos
    };

    return ws
  }, [])

  useEffect(() => {
    let ws = connect_ws();

    return () => {
      ws.close()
    }
  }, [connect_ws])

  // useEffect(() => {
  //   const socket = socketIOClient('http://localhost:5000/data_turnos');

  //   socket.on('connect', () => {
  //     console.log('Connected to server');
  //   });

  //   socket.on('update_data_turnos', (...args) => {
  //     const joinedTurnos = args.reduce((acc, curr) => acc.concat(curr), [])
  //     setDatos(joinedTurnos)
  //   });

  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  const modifiedData = datos?.map((item) => ({
    ...item,
    proceso: item.proceso === "1" ? "Cargadero" : "Descargadero",
  }));

  return (
    <>
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <div style={{ backgroundColor: 'rgba(109, 101, 105, 0.8)', padding: '10px', textAlign: 'center' }}>
              <img src={LogoSyz} width={100} alt="logo" />
            </div>
          </Grid>
        </Grid>

        {fullscreen ? (
          <>
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                height: "100vh",
                zIndex: 9999,
                backgroundColor: "white",
              }}
            >
              <Table
                columns={columns}
                dataSource={modifiedData ? modifiedData : []}
                pagination={false}
              />
            </div>
          </>
        ) : (
          <>
            <Table
              columns={columns}
              dataSource={modifiedData ? modifiedData : []}
              pagination={false}
            />
          </>
        )}
      </div>
    </>
  )
}

