export const filterValue = [
    { name: 'placa', operator: 'startsWith', type: 'string', value: '' },
    { name: 'nombre_maquina', operator: 'startsWith', type: 'string', value: '' },
    { name: 'descripcion', operator: 'startsWith', type: 'string', value: '' },
    { name: 'modelo_maquina', operator: 'startsWith', type: 'string', value: '' },
    { name: 'desplazamiento', operator: 'startsWith', type: 'string', value: '' },
    { name: 'ubicacion_maquina', operator: 'startsWith', type: 'string', value: '' },
    { name: 'est_maquina', operator: 'startsWith', type: 'string', value: '' },
    { name: 'peso_maquina', operator: 'startsWith', type: 'string', value: '' },
    { name: 'tipo_desplazamiento_maquina', operator: 'startsWith', type: 'string', value: '' },
    { name: 'potencia_nominal', operator: 'startsWith', type: 'string', value: '' },
    { name: 'prim_combustible', operator: 'startsWith', type: 'string', value: '' },
    { name: 'sec_combustible', operator: 'startsWith', type: 'string', value: '' },
    { name: 'trabajo_act', operator: 'startsWith', type: 'string', value: '' },
    { name: 'hrs_uso', operator: 'startsWith', type: 'string', value: '' },
    { name: 'fabricante_maquina', operator: 'startsWith', type: 'string', value: '' },
]