import React, { useEffect } from "react";
import { Loader } from "semantic-ui-react";
import { GraficaProductos } from "../../../Components/Client";
import { ModalBasic } from "../../../Components/Comons";
import { useProductos } from "../../../hooks";
import { Funciones } from "./Funciones";

export function MezclasAdmin() {
  const { loading, Producto, getProducto } = useProductos();
  const {
    refetch,
    contentModal,
    titleModal,
    showModal,
    handleAdd,
    handleDelete,
    handleUpdate,
    openCloseModal,
  } = Funciones();

  useEffect(() => {
    getProducto();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);
  
  const MemoizedModalBasic = React.memo(ModalBasic);
  return (
    <>
      {loading ? (
        <Loader active inline="centered">
          Cargando...
        </Loader>
      ) : (
        <GraficaProductos
          agregar={handleAdd}
          actualizar={handleUpdate}
          eliminar={handleDelete}
          data={Producto.results}
        />
      )}

      {showModal && (
        <MemoizedModalBasic
          show={showModal}
          onClose={openCloseModal}
          title={titleModal}
          children={contentModal}
        />
      )}
    </>
  );
}
//Columnas que se envian en forma de props al componente tabla basic para representer sus campos
