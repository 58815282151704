import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

const customColors = ["#D0302B", "#BEBEBE", "#444444", "#08131A", "#FFFFFF", "#052D42", "#7E7E7E", "#363636", "#FFD700", "#F39867",];

export const OverviewTraffic = (props) => {
  const { chartSeries, labels, mezcla } = props;

  // Calcular el ancho de cada barra en función de los porcentajes
  const totalPercentage = chartSeries.reduce((acc, percentage) => acc + percentage, 0);
  const barWidths = chartSeries.map((percentage) => (percentage / totalPercentage) * 100);

  return (
    <Card >
      <CardContent
        sx={{
          backgroundColor:
            mezcla?.activo === false
              ? "rgba(224, 224, 224, 0.7)"
              : mezcla?.activo === true
              ? "rgba(0, 0, 0, 0.7)"
              : "inherit",
          borderRadius: "8px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          color: mezcla?.activo === true ? "#ffffff" : "inherit",
          "& .MuiCardHeader-title": {
            fontSize: "1.5rem",
            display: "flex",
            alignItems: "center",
          },
          "& .MuiCardHeader-action": {
            "& svg": {
              color: mezcla?.activo === true ? "#ffffff" : "inherit",
            },
          },
        }}
      >
        <Stack
          alignItems="center"
          direction="column"
          justifyContent="center"
          spacing={2}
          sx={{ mt: 2 }}
        >
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            {chartSeries.map((item, index) => (
              <div key={labels[index]} style={{ width: `${barWidths[index]}%`, boxSizing: 'border-box' }}>
                <Tooltip title={`${labels[index]}: ${item}%`} arrow>
                  <div
                    style={{
                      width: '100%',
                      height: '20px',
                      backgroundColor: customColors[index],
                      marginTop: '5px',
                      borderRadius: '5px',
                      position: 'relative',
                    }}
                  >
                    <Typography
                      variant="body2"
                      align="center"
                      style={{
                        position: 'absolute',
                        top: '-20px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        color: mezcla?.activo ? '#ffffff' : 'inherit',
                      }}
                    >
                      {item}%
                    </Typography>
                  </div>
                </Tooltip>
              </div>
            ))}
          </div>
          <div style={{ marginTop: '10px', textAlign: 'center', width: '100%' }}>
            {labels.map((label, index) => (
              <Typography key={label} variant="body2">
                {label}
              </Typography>
            ))}
          </div>
        </Stack>
      </CardContent>
      
    </Card>
  );
};

OverviewTraffic.propTypes = {
  chartSeries: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
  sx: PropTypes.object,
  CHART_HEIGHT_OVER: PropTypes.string,
};
