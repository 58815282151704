import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {BASE_API_PUBLIC} from '../../../utils/constants';
import { Dimmer, Loader } from 'semantic-ui-react'
export function TenantAdmin() {
  const [formData, setFormData] = useState({
    schema_name: '',
    name: '',
    paid_until: '',
    on_trial: false,
  });

  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setLoading(true);

    fetch(`${BASE_API_PUBLIC}/tenants/clients/create/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);
        setLoading(false);
        toast.success('Tenant creado exitosamente!');
      })
      .catch((error) => {
        console.error('Error:', error);
        setLoading(false);
        toast.error('Error al crear el tenant.');
      });
  };

  return (
   
      <div className="card">
        <div className="card-body">
          
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Nombre del Schema:</label>
              <input
                type="text"
                name="schema_name"
                value={formData.schema_name}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>Nombre del cliente:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>Pagado hasta:</label>
              <input
                type="date"
                name="paid_until"
                value={formData.paid_until}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>

            <div className="form-group">
              <label>
                <input
                  type="checkbox"
                  name="on_trial"
                  checked={formData.on_trial}
                  onChange={handleInputChange}
                  className="form-check-input"
                />
                Periodo de prueba:
              </label>
            </div>
            <button type="submit" className="btn btn-danger">
              Generar un cliente
            </button>
          </form>
          {loading && (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} className="loader-container">
              <div className="loader"> </div>
              <Dimmer active>
                <Loader size='big'>Loading</Loader>
              </Dimmer>
              <p>Creando tenant...</p>
            </div>
          )}
        </div>
      </div>
  

  );
}
