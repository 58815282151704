import React from "react";
import { Button, Grid } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useMapeos } from "../../../hooks";
import { alertas } from "../../Comons/Alertas/alertas";

export function AddEditMapeos(props) {
  const { Successful } = alertas();
  const { onClose, onRefetch, Mapeos } = props;

  const { updtMapeos } = useMapeos();

  const formik = useFormik({
    initialValues: initialValues(Mapeos),
    validationSchema: validationSchema(),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        await updtMapeos(Mapeos.id, formValue);
        Successful();
        onRefetch();
        onClose();
      } catch (error) {
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          console.error(error);
        }
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        {/* Campos del formulario */}
        <Grid item xs={12} sm={12}>
        <Grid container spacing={2}>
                <Grid item xs={12}>
          <TextField
            fullWidth
            id="nombre_bahia"
            name="nombre_bahia"
            label="Nombre de Bahía"
            value={formik.values.nombre_bahia}
            onChange={formik.handleChange}
            error={formik.touched.nombre_bahia && Boolean(formik.errors.nombre_bahia)}
            helperText={formik.touched.nombre_bahia && formik.errors.nombre_bahia}
          />
          </Grid>
          </Grid>
        </Grid>
  
        {/* Campos para brazos */}
        {formik.values.brazos.map((brazo, index) => (
          <Grid item xs={12} key={index}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
            <TextField
              fullWidth
              id={`brazos[${index}].nombre_brazo`}
              name={`brazos[${index}].nombre_brazo`}
              label={`Nombre del brazo ${index + 1}`}
              value={formik.values.brazos[index].nombre_brazo}
              disabled // Deshabilitar la edición del nombre del brazo
            />
            </Grid>
          </Grid>
  
            {/* Campos para los datos de mapeo del brazo */}
            {formik.values.brazos[index].mapeo_brazo.map((mapeo, mapeoIndex) => (
              <Grid item xs={12} key={mapeoIndex}>
                <Grid container spacing={2}>
                <Grid item xs={6}>
                <TextField
                  fullWidth
                  id={`brazos[${index}].mapeo_brazo[${mapeoIndex}].nombre`}
                  name={`brazos[${index}].mapeo_brazo[${mapeoIndex}].nombre`}
                  value={formik.values.brazos[index].mapeo_brazo[mapeoIndex].nombre}
                  disabled // Deshabilitar la edición del nombre del mapeo
                />
                </Grid>
                <Grid item xs={6}>
                <TextField
                  fullWidth
                  id={`brazos[${index}].mapeo_brazo[${mapeoIndex}].registro`}
                  name={`brazos[${index}].mapeo_brazo[${mapeoIndex}].registro`}
                  label={`Registro del mapeo ${mapeoIndex + 1}`}
                  value={formik.values.brazos[index].mapeo_brazo[mapeoIndex].registro}
                  onChange={formik.handleChange}
                  error={formik.touched.brazos && formik.touched.brazos[index] && formik.errors.brazos && formik.errors.brazos[index]?.mapeo_brazo && formik.errors.brazos[index].mapeo_brazo[mapeoIndex]?.registro}
                  helperText={formik.touched.brazos && formik.touched.brazos[index] && formik.errors.brazos && formik.errors.brazos[index]?.mapeo_brazo && formik.errors.brazos[index].mapeo_brazo[mapeoIndex]?.registro}
                />
                </Grid>
                <br/>
                <br/>
                </Grid>
              </Grid>
            ))}
  
            {/* Campos para los medidores */}
            {formik.values.brazos[index].medidores.map((medidor, medidorIndex) => (
              <Grid item xs={12} key={medidorIndex}>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                <TextField
                  fullWidth
                  id={`brazos[${index}].medidores[${medidorIndex}].nombre_medidor`}
                  name={`brazos[${index}].medidores[${medidorIndex}].nombre_medidor`}
                  label={`Nombre del medidor ${medidorIndex + 1} - Brazo ${index + 1}`}
                  value={formik.values.brazos[index].medidores[medidorIndex].nombre_medidor}
                  disabled // Deshabilitar la edición del nombre del medidor
                />
                </Grid>
                </Grid>
                {formik.values.brazos[index].medidores[medidorIndex].mapeo_medidor.map((mapeoMedidor, mapeoMedidorIndex) => (
                  <Grid item xs={12} key={mapeoMedidorIndex}>
                    <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id={`brazos[${index}].medidores[${medidorIndex}].mapeo_medidor[${mapeoMedidorIndex}].nombre`}
                      name={`brazos[${index}].medidores[${medidorIndex}].mapeo_medidor[${mapeoMedidorIndex}].nombre`}
                      value={formik.values.brazos[index].medidores[medidorIndex].mapeo_medidor[mapeoMedidorIndex].nombre}
                      disabled // Deshabilitar la edición del nombre del mapeo
                    />
                    </Grid>
                <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id={`brazos[${index}].medidores[${medidorIndex}].mapeo_medidor[${mapeoMedidorIndex}].registro`}
                      name={`brazos[${index}].medidores[${medidorIndex}].mapeo_medidor[${mapeoMedidorIndex}].registro`}
                      label={`Registro del mapeo ${mapeoMedidorIndex + 1} - Medidor ${medidorIndex + 1} - Brazo ${index + 1}`}
                      value={formik.values.brazos[index].medidores[medidorIndex].mapeo_medidor[mapeoMedidorIndex].registro}
                      onChange={formik.handleChange}
                      error={formik.touched.brazos && formik.touched.brazos[index] && formik.errors.brazos && formik.errors.brazos[index]?.medidores && formik.errors.brazos[index].medidores[medidorIndex]?.mapeo_medidor && formik.errors.brazos[index].medidores[medidorIndex].mapeo_medidor[mapeoMedidorIndex]?.registro}
                      helperText={formik.touched.brazos && formik.touched.brazos[index] && formik.errors.brazos && formik.errors.brazos[index]?.medidores && formik.errors.brazos[index].medidores[medidorIndex]?.mapeo_medidor && formik.errors.brazos[index].medidores[medidorIndex].mapeo_medidor[mapeoMedidorIndex]?.registro}
                    />
                    </Grid>
                    <br/>
                    <br/>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            ))}
  
            {/* Campos para los componentes */}
            {formik.values.brazos[index].componentes.map((componente, componenteIndex) => (
              <Grid item xs={12} key={componenteIndex}>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                <TextField
                  fullWidth
                  id={`brazos[${index}].componentes[${componenteIndex}].nombre_componente`}
                  name={`brazos[${index}].componentes[${componenteIndex}].nombre_componente`}
                  label={`Nombre del componente ${componenteIndex + 1} - Brazo ${index + 1}`}
                  value={formik.values.brazos[index].componentes[componenteIndex].nombre_componente}
                  disabled // Deshabilitar la edición del nombre del componente
                />
                </Grid>
                </Grid>
                {formik.values.brazos[index].componentes[componenteIndex].mapeo_componente.map((mapeoComponente, mapeoComponenteIndex) => (
                  <Grid item xs={12} key={mapeoComponenteIndex}>
                    <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id={`brazos[${index}].componentes[${componenteIndex}].mapeo_componente[${mapeoComponenteIndex}].nombre`}
                      name={`brazos[${index}].componentes[${componenteIndex}].mapeo_componente[${mapeoComponenteIndex}].nombre`}
                      value={formik.values.brazos[index].componentes[componenteIndex].mapeo_componente[mapeoComponenteIndex].nombre}
                      disabled // Deshabilitar la edición del nombre del mapeo
                    />
                    </Grid>
                <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id={`brazos[${index}].componentes[${componenteIndex}].mapeo_componente[${mapeoComponenteIndex}].registro`}
                      name={`brazos[${index}].componentes[${componenteIndex}].mapeo_componente[${mapeoComponenteIndex}].registro`}
                      label={`Registro del mapeo ${mapeoComponenteIndex + 1} - Componente ${componenteIndex + 1} - Brazo ${index + 1}`}
                      value={formik.values.brazos[index].componentes[componenteIndex].mapeo_componente[mapeoComponenteIndex].registro}
                      onChange={formik.handleChange}
                      error={formik.touched.brazos && formik.touched.brazos[index] && formik.errors.brazos && formik.errors.brazos[index]?.componentes && formik.errors.brazos[index].componentes[componenteIndex]?.mapeo_componente && formik.errors.brazos[index].componentes[componenteIndex].mapeo_componente[mapeoComponenteIndex]?.registro}
                      helperText={formik.touched.brazos && formik.touched.brazos[index] && formik.errors.brazos && formik.errors.brazos[index]?.componentes && formik.errors.brazos[index].componentes[componenteIndex]?.mapeo_componente && formik.errors.brazos[index].componentes[componenteIndex].mapeo_componente[mapeoComponenteIndex]?.registro}
                    />
                    </Grid>
                    <br/>
                    <br/>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            ))}
  
            {/* Campos para los aditivos */}
            {formik.values.brazos[index].aditivos.map((aditivo, aditivoIndex) => (
              <Grid item xs={12} key={aditivoIndex}>
               <Grid container spacing={2}>
                <Grid item xs={12}>
                <TextField
                  fullWidth
                  id={`brazos[${index}].aditivos[${aditivoIndex}].nombre_aditivo`}
                  name={`brazos[${index}].aditivos[${aditivoIndex}].nombre_aditivo`}
                  label={`Nombre del aditivo ${aditivoIndex + 1} - Brazo ${index + 1}`}
                  value={formik.values.brazos[index].aditivos[aditivoIndex].nombre_aditivo}
                  disabled // Deshabilitar la edición del nombre del aditivo
                />
                </Grid>
                </Grid>
                {formik.values.brazos[index].aditivos[aditivoIndex].mapeo_aditivo.map((mapeoAditivo, mapeoAditivoIndex) => (
                  <Grid item xs={12} key={mapeoAditivoIndex}>
                    <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id={`brazos[${index}].aditivos[${aditivoIndex}].mapeo_aditivo[${mapeoAditivoIndex}].nombre`}
                      name={`brazos[${index}].aditivos[${aditivoIndex}].mapeo_aditivo[${mapeoAditivoIndex}].nombre`}
                      value={formik.values.brazos[index].aditivos[aditivoIndex].mapeo_aditivo[mapeoAditivoIndex].nombre}
                      disabled // Deshabilitar la edición del nombre del mapeo
                    />
                    </Grid>
                <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id={`brazos[${index}].aditivos[${aditivoIndex}].mapeo_aditivo[${mapeoAditivoIndex}].registro`}
                      name={`brazos[${index}].aditivos[${aditivoIndex}].mapeo_aditivo[${mapeoAditivoIndex}].registro`}
                      label={`Registro del mapeo ${mapeoAditivoIndex + 1} - Aditivo ${aditivoIndex + 1} - Brazo ${index + 1}`}
                      value={formik.values.brazos[index].aditivos[aditivoIndex].mapeo_aditivo[mapeoAditivoIndex].registro}
                      onChange={formik.handleChange}
                      error={formik.touched.brazos && formik.touched.brazos[index] && formik.errors.brazos && formik.errors.brazos[index]?.aditivos && formik.errors.brazos[index].aditivos[aditivoIndex]?.mapeo_aditivo && formik.errors.brazos[index].aditivos[aditivoIndex].mapeo_aditivo[mapeoAditivoIndex]?.registro}
                      helperText={formik.touched.brazos && formik.touched.brazos[index] && formik.errors.brazos && formik.errors.brazos[index]?.aditivos && formik.errors.brazos[index].aditivos[aditivoIndex]?.mapeo_aditivo && formik.errors.brazos[index].aditivos[aditivoIndex].mapeo_aditivo[mapeoAditivoIndex]?.registro}
                    />
                    </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            ))}
          </Grid>
        ))}
  
        {/* Botón de enviar */}
        <Grid item xs={12}>
          <Button type="submit" color="primary" variant="contained">
            Guardar cambios
          </Button>
        </Grid>
      </Grid>
    </form>
  );
  
}

// Función para definir los valores iniciales del formulario
function initialValues(data) {
  return {
    nombre_bahia: data.nombre_bahia || "",
    brazos: data.brazos.map((brazo) => ({
      nombre_brazo: brazo.nombre_brazo,
      mapeo_brazo: brazo.mapeo_brazo.map((mapeo) => ({
        nombre: mapeo.nombre,
        registro: mapeo.registro,
      })),
      medidores: brazo.medidores.map((medidor) => ({
        nombre_medidor: medidor.nombre_medidor,
        mapeo_medidor: medidor.mapeo_medidor.map((mapeo) => ({
          nombre: mapeo.nombre,
          registro: mapeo.registro,
        })),
      })),
      componentes: brazo.componentes.map((componente) => ({
        nombre_componente: componente.nombre_componente,
        mapeo_componente: componente.mapeo_componente.map((mapeo) => ({
          nombre: mapeo.nombre,
          registro: mapeo.registro,
        })),
      })),
      aditivos: brazo.aditivos.map((aditivo) => ({
        nombre_aditivo: aditivo.nombre_aditivo,
        mapeo_aditivo: aditivo.mapeo_aditivo.map((mapeo) => ({
          nombre: mapeo.nombre,
          registro: mapeo.registro,
        })),
      })),
    })),
  };
}

// Función para definir el esquema de validación del formulario
function validationSchema() {
  return Yup.object().shape({
    nombre_bahia: Yup.string().required("El nombre de la bahía es requerido"),
    brazos: Yup.array().of(
      Yup.object().shape({
        nombre_brazo: Yup.string().required("El nombre del brazo es requerido"),
        mapeo_brazo: Yup.array().of(
          Yup.object().shape({
            nombre: Yup.string().required("El nombre del mapeo es requerido"),
            registro: Yup.number().required("El registro del mapeo es requerido"),
          })
        ),
        medidores: Yup.array().of(
          Yup.object().shape({
            nombre_medidor: Yup.string().required("El nombre del medidor es requerido"),
            mapeo_medidor: Yup.array().of(
              Yup.object().shape({
                nombre: Yup.string().required("El nombre del mapeo es requerido"),
                registro: Yup.number().required("El registro del mapeo es requerido"),
              })
            ),
          })
        ),
        componentes: Yup.array().of(
          Yup.object().shape({
            nombre_componente: Yup.string().required("El nombre del componente es requerido"),
            mapeo_componente: Yup.array().of(
              Yup.object().shape({
                nombre: Yup.string().required("El nombre del mapeo es requerido"),
                registro: Yup.number().required("El registro del mapeo es requerido"),
              })
            ),
          })
        ),
        aditivos: Yup.array().of(
          Yup.object().shape({
            nombre_aditivo: Yup.string().required("El nombre del aditivo es requerido"),
            mapeo_aditivo: Yup.array().of(
              Yup.object().shape({
                nombre: Yup.string().required("El nombre del mapeo es requerido"),
                registro: Yup.number().required("El registro del mapeo es requerido"),
              })
            ),
          })
        ),
      })
    ),
  });
}
