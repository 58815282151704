import { useFormik } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Form,Input } from "semantic-ui-react";
import { useAutenticacion } from "../../../hooks";
import { useParams } from "react-router-dom";

import { Navigate } from 'react-router-dom';

export function ConfirmacionCredenciales() {
  let { uid, token } = useParams();
  const [redirect, setRedirect] = useState(false);
  const { ConfirmacionClave } = useAutenticacion();

  const formik = useFormik({
    // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
    initialValues: initialValues(),
    // Establece la validación del esquema utilizando Yup, si se proporciona un objeto  se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (formValue) => {
      formValue.uid = uid;
      formValue.token = token;
      try {
        // Si hace una apeticion en caso de que retone valores se envia a la funcion login
        ConfirmacionClave(formValue);
        setRedirect(true);
      } catch (error) {
        /*
          capturamos el error si el email o la contraseña son incorrectos y lo mostramos con toastify
          */
        toast.error(error.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    },
  });
  
  if (redirect) {
    return <Navigate to="/" />;
  }

  
  return (
  <div className="paginaAviso">
    <div className="container mt-3 text-center">
    <h1 className="mb-3">Actualiza tu contraseña:</h1>
    <div className="field-container" style={{ maxWidth: 500, margin: '0 auto' }}>
    <Form className="add-edit-secciones-form" onSubmit={formik.handleSubmit}>
      
      <Form.Field
            fluid
            control={Input}
            label="Nueva contraseña"
            name="new_password"
            placeholder="Nueva contraseña"
            value={formik.values?.new_password}
            onChange={formik.handleChange}
            error={formik.errors.new_password && (
              <div className="error-message">{formik.errors.new_password}</div>
            )}

            type="password"
          />
       
        <Form.Field
            fluid
            control={Input}
            label="Confirmar la nueva contraseña"
            name="re_new_password"
            placeholder="Confirmar la nueva contraseña"
            value={formik.values?.re_new_password}
            onChange={formik.handleChange}
            error={formik.errors.re_new_password && (
              <div className="error-message">{formik.errors.re_new_password}</div>
            )}
            type="password"
          />
      
      <button className="btn btn-danger mt-3" type="submit">
        Actualizar contraseña
      </button>
    </Form>
    </div>
    </div>
    </div>

  );
}
function initialValues() {
  return {
    uid: "",
    token: "",
    new_password: "",
    re_new_password: "",
  };
}
function validationSchema() {
  return {
    new_password: Yup.string().required(true)
  .min(8, 'La contraseña debe tener al menos 8 caracteres')
  .matches(/[a-z]/, 'La contraseña debe tener al menos una letra minúscula')
  .matches(/[A-Z]/, 'La contraseña debe tener al menos una letra mayúscula')
  .matches(/\d/, 'La contraseña debe tener al menos un número')
  .matches(/[^a-zA-Z\d]/, 'La contraseña debe tener al menos un carácter especial'),
  re_new_password: Yup.string().required(true)
  .oneOf([Yup.ref('new_password'), null], 'Las contraseñas no coinciden'),
  };
}
