// This file is used to define the theme of the application

const defaultTheme = {
  primary: {
    black: "#000000",
    black2: "#181a2a",
    white: "#FFFFFF",
    red: "#e1443f",
    red2: "#a7120e",
    gray: "rgb(229, 231, 235)",
    gray2: "#ddd4d4",
    gray3: "#989ba1",
    gray4: "#f3f5f8",
    gray5: "#fafafa",
    gray6: "#96999f",
    gray7: "#c2c4c7",
    gray8: "#6f747e",
    green: "#00ff00",
    gradient:
      "linear-gradient(180deg, rgba(107, 107, 111, 1) 0%, rgba(164, 159, 159, 1) 48%, rgba(175, 179, 180, 1) 100%)",
    yellow: "#ffd402",
  },
};

export const theme = {
  default: defaultTheme,
};
