import React, { useEffect } from "react";
import { Loader } from "semantic-ui-react";
import { HeaderPage } from "../../../Components/Client";
import { DataGridBasic, ModalBasic } from "../../../Components/Comons";
import { useConductor } from "../../../hooks";
import { filterValue } from "./ConductorFormat";
import CircularProgress from '@mui/material/CircularProgress';

import { Funciones } from "./Funciones";

export function ConductorAdmin() {
  const { loading, Conductor, getConductor } = useConductor();
  const {
    refetch,
    contentModal,
    titleModal,
    showModal,
    columns,
    handleAdd,
    handleDelete,
    handleUpdate,
    handleViews,
    openCloseModal,
  } = Funciones();

  useEffect(() => {
    getConductor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const MemoizedModalBasic = React.memo(ModalBasic);

  return (
    <>
      <HeaderPage
        title="Conductor"
        btnTitle="Nuevo conductor"
        icono="plus square icon"
        btnClick={handleAdd}
      />

      {loading ? (
        <>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
          </div>
        </>
      ) : (
        <DataGridBasic
          data={Conductor.results}
          title="Conductores"
          columns={columns}
          id="id_conductor"
          filterValue={filterValue}
          updateData={handleUpdate}
          onDeleteData={handleDelete}
          visualizacionData={handleViews}
        />
      )}

      {showModal && (
        <MemoizedModalBasic
          show={showModal}
          onClose={openCloseModal}
          title={titleModal}
          children={contentModal}
        />
      )}
    </>
  );
}
//Columnas que se envian en forma de props al componente tabla basic para representer sus campos
