import React, { useEffect, useState } from "react";
import { HeaderPage } from "../../../Components/Client";
import { tagColumns, tagFilterValue } from "./SensoresFormat";
import { Funciones } from "./Funciones";
import { DataGridBasic, ModalBasic } from "../../../Components/Comons";
import { useSensores } from "../../../hooks";

import CircularProgress from '@mui/material/CircularProgress';

export function EtiquetaSensoresAdmin() {
    const { loading, EtiquetaSensores, getEtiquetaSensores } = useSensores()
    
    const MemoizedModalBasic = React.memo(ModalBasic);

    const {
        refetch,
        contentModal,
        showModal,
        openCloseModal,
        titleModal,
        handleAddEtiquetas,
        handleUpdateEtiquetas,
        handleDeleteEtiquetaSensores,
    } = Funciones();

    useEffect(() => {
        getEtiquetaSensores();
    }, [refetch])

    const modifiedData = EtiquetaSensores ? EtiquetaSensores?.map((item) => ({
        ...item,
        tipo: item.tipo === '1' ? 'Análogos' : 'Comunicación',
        categoria: item.sensor_category_rel_data.cat_name,
        unidad: item.sensor_unit_rel_data.unit_name
    })) : [];

    return (
        <>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <HeaderPage title="Etiquetas" btnTitle="Nueva etiqueta" icono="plus square icon" btnClick={handleAddEtiquetas} />

                    <DataGridBasic
                        data={modifiedData}
                        title="Etiqueta Sensores"
                        id="id_tag"
                        filterValue={tagFilterValue}
                        columns={tagColumns}
                        updateData={handleUpdateEtiquetas}
                        onDeleteData={handleDeleteEtiquetaSensores}
                    />
                </>
            )}

            {showModal && (
                <MemoizedModalBasic
                    show={showModal}
                    onClose={openCloseModal}
                    title={titleModal}
                    children={contentModal}
                />
            )}
        </>
    )
}