export const columns = [

  {
    header: "Modelo",
    name: "modelo",
    maxWidth: 1000, defaultFlex: 1
  },
  {
    header: "Marca",
    name: "marca",
    maxWidth: 1000, defaultFlex: 1
  },
  {
    header: "IP",
    name: "ip",
    maxWidth: 1000, defaultFlex: 1
  },
  {
    header: "Serial del equipo",
    name: "serial_equipo",
    maxWidth: 1000, defaultFlex: 1
  },
  {
    header: "Serial SYZ",
    name: "serial_syz",
    maxWidth: 1000, defaultFlex: 1
  },
];

export const filterValue = [
  { name: 'modelo', operator: 'startsWith', type: 'string', value: '' },
  { name: 'marca', operator: 'startsWith', type: 'string', value: '' },
  { name: 'ip', operator: 'startsWith', type: 'string', value: '' },
  { name: 'ubicacion_id.tag', operator: 'startsWith', type: 'string', value: '' },
  { name: 'serial_equipo', operator: 'startsWith', type: 'string', value: '' },
  { name: 'serial_syz', operator: 'startsWith', type: 'string', value: '' },
];