import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';

import { Line } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
const labels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];

const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: [0, 10, 5, 2, 20, 30, 45,32,54,65,43,],
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
      yAxisID: 'y',
    },
    {
      label: 'Dataset 2',
      data: labels.map(() => Math.floor(Math.random() * 2000) - 1000),
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
      yAxisID: 'y1',
    },
  ],
};
export const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Consumo mensual por producto',
      },
    },
  };
  

  const getRandomColor = () => {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgb(${r}, ${g}, ${b})`;
  };
  
  const transformData = (response) => {
    const labels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  
    const datasets = response.map((producto) => ({
      label: producto.producto,
      data: producto.data,
      borderColor: getRandomColor(),
      backgroundColor: getRandomColor() + '1',
      yAxisID: 'y',
    }));
  
    return {
      labels,
      datasets,
    };
  };

  export function LineChart(props) {
    const { data } = props;
    
    const x= transformData(data)
    if (!x || x.length === 0) {
      return <div>No hay datos disponibles</div>;
    }
  
    
    
    return (
      <div>
        <Line data={x} options={options} />
      </div>
    );
  };


  

