import React, { useEffect, useState } from 'react';
import { AddEditTablaAforoForms, HeaderPage, } from "../../../../Components/Client";
import { ModalBasic, DataGridBasic } from "../../../../Components/Comons";
import { useTablaAforo } from "../../../../hooks";
import "./TablaAforoAdmin.scss";
import Swal from "sweetalert2"
import { filterValue, columns } from "./TablaAforoFormat";
import CircularProgress from '@mui/material/CircularProgress';

export function TablaAforoAdmin() {
  const { loading, tablasAforo, getTablasAforo, deleteTablasAforo } = useTablaAforo();
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);


  useEffect(() => {
    getTablasAforo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const openCloseModal = () => setShowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);
  /**La función establece el título y el contenido del modal, además de abrirlo. 
     * El título establecido es "Nuevo ..." y el contenido es un componente "AddEditComponent", 
     * que se encarga de agregar un nuevo dato. El componente cuenta con dos props, "onClose" y "onRefetch". 
     * "onClose" es una función que cierra el modal, y "onRefetch" es una función que recarga los datos de la tabla después de agregar un nuevo dato. */

  const addTablaAforo = () => {
    setTitleModal("Nueva tabla aforo");

    setContentModal(
      <AddEditTablaAforoForms onClose={openCloseModal} onRefetch={onRefetch} />

    );
    openCloseModal();
  };
  /// Ejecucion de modal para actualizar datos

  /** La función  es similar a la función a la anterior, con la diferencia de que establece el título "Actualizar..." y además recibe un parámetro "data",
   *  que contiene los datos del dato a actualizar. El contenido del modal es el mismo componente "AddEditComponent" que antes, pero en este caso, 
   * el componente recibe la propiedad  con los datos a actualizar. El componente cuenta con dos props, "onClose" y "onRefetch". 
   * "onClose" es una función que cierra el modal, y "onRefetch" es una función que recarga los datos de la tabla después de actualizar un dato. */
  const updateTablaAforo = (data) => {
    setTitleModal("Actualizar Tabla aforo");
    setContentModal(
      <AddEditTablaAforoForms
        onClose={openCloseModal}
        onRefetch={onRefetch}
        TablaAforo={data}
      />
    );
    openCloseModal();
  };
  /** La función "onDelete" es una función que utiliza la librería SweetAlert para mostrar una alerta de confirmación al usuario 
   * antes de eliminar un dato . La función recibe como parámetro "data", que contiene los datos  a eliminar. 
   * Cuando el usuario confirma la eliminación, se llama a la función "delete" pasando como parámetro el id de los datos 
   * a eliminar y se ejecuta la función "onRefetch" para actualizar los datos de la tabla. En caso de cancelar, se muestra un mensaje de error. */

  const onDeleteTablaAforo = async (data) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: 'Estas seguro?',
      text: "No podras revertir esta accion!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, eliminar!',
      cancelButtonText: 'No, cancelar!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        deleteTablasAforo(data.id_aforo);
        onRefetch();
        swalWithBootstrapButtons.fire(
          'Eliminado!',
          'El dato a sido eliminado.',
          'success'
        )
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelado',
          'Operacion cancelada',
          'error'
        )
      }
    })
  };

  /**Este es el código del componente principal que utiliza las funciones anteriores. El componente comienza con un componente "HeaderPage" 
   * que tiene un título "", un botón con el título "Nuevo ..." y un icono "user plus icon", que al ser presionado, 
   * llama a la función "addCalibraciones".
  
  Luego, se verifica si el componente está cargando (loading) y muestra un spinner si es así, si no, se muestra un componente "TablaBasic" 
  que recibe como propiedades los datos de las obtenidos en la peticion, el título, las columnas, y las funciones "updateData" y "onDeleteData" que llaman a las funciones 
  "update..." y "onDelete..." respectivamente.
  
  Finalmente, se renderiza un componente "ModalBasic" que es mostrado o ocultado según el estado de "showModal",
   tiene un título y un contenido que se establecen mediante las funciones "setTitleModal" y "setContentModal" respectivamente. */
  return (
    <>
      <HeaderPage title="Creacion tabla de aforo" btnTitle="Nueva tabla" icono="plus square icon" btnClick={addTablaAforo} />


      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <>

          <DataGridBasic
            data={tablasAforo.results}
            title="Tabla aforo"
            id="id_aforo"
            filterValue={filterValue}
            columns={columns}
            updateData={updateTablaAforo}
            onDeleteData={onDeleteTablaAforo}
          />
        </>

      )}
      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        title={titleModal}
        children={contentModal}

      />
    </>
  )
}

