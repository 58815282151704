import styled from "styled-components";

export const Circle = styled.div`
  width: ${({ size }) => (size ? `${size}px` : "10px")};
  height: ${({ size }) => (size ? `${size}px` : "10px")};
  border-radius: 50%;
  background-color: ${({ color, theme }) => color || theme.primary.gray4};
  border: 1px solid ${({ theme }) => theme.primary.gray3};
  margin: 0 5px;
  ${({ relative, top }) =>
    relative && ` position: relative; top: ${top ? `${top}px` : "13px"}`};
`;

export const Badge = styled.div`
  background-color: ${({ bgColor, theme }) =>
    bgColor ? bgColor : theme.primary.gray3};
  display: inline;
  padding: 4px;
  border-radius: 2px;
  color: ${({ theme }) => theme.primary.white};
  font-weight: 700;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "10px")};
  ${({ width }) => width && `width: ${width}px;`}
  text-align: center;
  position: relative;
  ${({ left }) => left && `left: ${left}px;`}
`;
