export const columns = [
    {
        name: "nombre",
        header: "Nombre",
        maxWidth: 1000,
        defaultFlex: 1
    },
    {
        name: "url_local",
        header: "URL Local",
        maxWidth: 1000,
        defaultFlex: 1,
    },
]


  export const filterValue = [
    { name: 'nombre', operator: 'startsWith', type: 'string', value: '' },
    
  ];