
import { BASE_API } from "../../utils/constants";
import { makeRequest } from "../Request";
const BASE_URL = `${BASE_API}/`;


// Función para obtener los datos de un cliente
export async function getControladoresApi(token, schema) {
  const url = `${BASE_URL}${schema}/conexiones/controlador/`;
  return makeRequest(url, "GET", token);
}

// Función para agregar un nuevo cliente
export async function addControladoresApi(data, token, schema) {
  console.log('dataaaaa',data);
  const url = `${BASE_URL}${schema}/conexiones/controlador/`;
  return makeRequest(url, "POST", token, data);
}

// Función para actualizar los datos de un cliente existente
export async function updateControladoresApi(id, data, token, schema) {
  const url = `${BASE_URL}${schema}/conexiones/controlador/${id}/`;
  return makeRequest(url, "PUT", token, data);
}

export async function patchControladoresApi(id, data, token, schema) {
  const url = `${BASE_URL}${schema}/conexiones/controlador/${id}/`;
  return makeRequest(url, "PATCH", token, data);
}

export async function getControladorRegCombApi(token, schema) {
  const url = `${BASE_URL}${schema}/conexiones/controlador?tipo=reg_comb`
  return makeRequest(url, "GET", token)
}

export async function getControladorCompFlujoApi(token, schema, data) { 
  const url = `${BASE_URL}${schema}/conexiones/controlador?tipo=comp_flujo`
  return makeRequest(url, "GET", token, data)
}

// Función para eliminar un cliente
export async function deleteControladoresApi(id, token, schema) {
  const url = `${BASE_URL}${schema}/conexiones/controlador/${id}/`;
  return makeRequest(url, "DELETE", token);
}