import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { Button, Form, Image, Input, Select } from "semantic-ui-react";
import * as Yup from "yup";
import { ModalBasic, ModalCambioContraseña } from "../../../Components/Comons";
import { alertas } from "../../../Components/Comons/Alertas/alertas";


export function UserUpdateMeAdmin(props) {
  const {objeto, updateUser,onRefetch}= props;
  const { Successful } = alertas();
  
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
 
  const [previewImage, setPreviewImage] = useState(objeto ? objeto?.foto : null);
  
  const openCloseModal = () => setShowModal((prev) => !prev);

  
 
  const formik = useFormik({
    initialValues: initialValues(objeto),
    validationSchema: Yup.object(objeto ? updateSchame() : {}),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        if (objeto) await updateUser(objeto.id_usuario, formValue);
        Successful();
        onRefetch();
      } catch (error) {
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
        }
      }
    },
  });
console.log(formik.values)
  const onDrop = useCallback(async (acceptedFile) => {
    const file = acceptedFile[0];
    await formik.setFieldValue("foto", file);
    setPreviewImage(URL.createObjectURL(file));
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png, image/jpg",
    noKeyboard: true,
    multiple: false,
    onDrop,
  });

  const imagenStyle = {
    width: "300px",
    margin: "0 auto",
  };

  const updatePassword = (data) => {
    setContentModal(
      <ModalCambioContraseña
        onClose={openCloseModal}
        onRefetch={onRefetch}
        data={data}
      />
    );
    openCloseModal();
  };


  if (!objeto) {
    return null; // Renderizar un componente de carga o un mensaje de espera mientras se obtiene objeto
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="my-5">
              <h3>Mi perfil</h3>
              <hr />
            </div>
            <div className="gap-3 d-md-flex justify-content-md-end text-center">
              <button
                type="button"
                className="btn btn-secondary btn-lg"
                onClick={() => updatePassword(objeto.id_usuario)}
              >
                Cambiar contraseña
              </button>
            </div>

            <Form className="add-edit-user-form" onSubmit={formik.handleSubmit}>
              <div className="row mb-5 gx-5">
                <div className="col-xxl-8 mb-5 mb-xxl-0">
                  <div className="bg-secondary-soft px-4 py-5 rounded">
                    <div className="row g-3">
                      <h4 className="mb-4 mt-0">Datos</h4>
                      <div className="col-md-6">
                        <label className="form-label">Tipo de documento</label>

                        <Form.Field
                          required
                          control={Select}
                          placeholder="Tipo de documento"
                          fluid
                          selection
                          search
                          options={IdentificacionOptions}
                          value={formik.values.tipo_id_pers}
                          error={formik.errors.tipo_id_pers}
                          onChange={(_, data) =>
                            formik.setFieldValue("tipo_id_pers", data.value)
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="form-label">
                          Numero de identificación
                        </label>
                        <Form.Field
                          required
                          fluid
                          control={Input}
                          name="identificacion_pers"
                          placeholder="Identificación"
                          value={formik.values.identificacion_pers}
                          onChange={formik.handleChange}
                          error={formik.errors.identificacion_pers}
                          type="number"
                        />
                      </div>

                      <div className="col-md-6">
                        <label className="form-label">Nombres</label>
                        <Form.Field
                          required
                          fluid
                          control={Input}
                          name="nombres"
                          placeholder="Nombres"
                          value={formik.values.nombres}
                          onChange={formik.handleChange}
                          error={formik.errors.nombres}
                        />
                      </div>

                      <div className="col-md-6">
                        <label className="form-label">Apellidos</label>
                        <Form.Field
                          required
                          fluid
                          control={Input}
                          name="apellidos"
                          placeholder="Apellidos"
                          value={formik.values.apellidos}
                          onChange={formik.handleChange}
                          error={formik.errors.apellidos}
                        />
                      </div>

                      <div className="col-md-12">
                        <label className="form-label">Email </label>
                        <Form.Field
                          required
                          fluid
                          control={Input}
                          name="email"
                          placeholder="JohnDoe@mail.com"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          error={formik.errors.email}
                          type="email"
                        />
                      </div>

                      

                      <div className="col-md-6">
                        <label className="form-label">Fecha de nacimiento</label>
                        <Form.Field
                          required
                          fluid
                          control={Input}
                          name="fecha_nacimiento"
                          placeholder="Fecha de nacimiento"
                          value={formik.values.fecha_nacimiento}
                          onChange={formik.handleChange}
                          error={formik.errors.fecha_nacimiento}
                          type="date"
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="form-label">Celular</label>
                        <Form.Field
                          required
                          fluid
                          control={Input}
                          name="celular"
                          placeholder="celular"
                          value={formik.values.celular}
                          onChange={formik.handleChange}
                          error={formik.errors.celular}
                          type="number"
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="form-label">Teléfono</label>
                        <Form.Field
                          fluid
                          control={Input}
                          name="telefono"
                          placeholder="Teléfono"
                          value={formik.values.telefono}
                          onChange={formik.handleChange}
                          error={formik.errors.telefono}
                          type="number"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-4">
                  <div className="bg-secondary-soft px-4 py-5 rounded">
                    <div className="row g-3">
                      <h4 className="mb-4 mt-0">Actualizar foto de perfil</h4>
                      <div className="text-center">
                        <div className="square position-relative display-2 mb-3">
                          <input {...getInputProps()} />
                          <Image style={imagenStyle} src={previewImage} fluid />
                          <Button
                            type="button"
                            width={160}
                            fluid
                            color={formik.errors.foto && "red"}
                            {...getRootProps()}
                          >
                            {previewImage ? "Cambiar imagen" : "Subir imagen"}
                          </Button>
                          <i className="position-absolute top-50 start-50 translate-middle text-secondary"></i>
                        </div>

                        <p className="text-muted mt-3 mb-0">
                          <span className="me-1">Note:</span>Minimum size 300px x
                          300px
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="gap-3 d-md-flex justify-content-md text-center">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="btn btn-danger"
                  fullWidth
                >
                  Actualizar
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>

      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        children={contentModal}
      />
    </>
  );
}


function initialValues(data) {

  return {
    tipo_id_pers: data?.tipo_id_pers || "",
    identificacion_pers: data?.identificacion_pers || "",
    nombres: data?.nombres || "",
    apellidos: data?.apellidos || "",
    foto: null,
    email: data?.email || "",
    
    fecha_nacimiento: data?.fecha_nacimiento || "",
    celular: data?.celular || "",
    telefono: data?.telefono || "",
    groups: data?.groups || "",
    password: "",
    is_admin: data?.is_admin ? true : false,
    is_staff: data?.is_staff ? true : false,
    is_active: data?.is_active ? true : false,
    is_superuser: data?.is_superuser ? true : false,
    is_superadmin: data?.is_superadmin ? true : false,
  };
}

function updateSchame() {
  return {
    tipo_id_pers: Yup.number("El dato debe ser numerico").required(
      "El tipo de identificacion es obligatorio"
    ),
    identificacion_pers: Yup.string()
      .required("El Numero de identificacion es requerido")
      .matches(/^[0-9]+$/, "Deben ser digitos")
      .min(6, "La longitud debe ser mayor que 5")
      .max(16, "La longitud debe ser menor que 16"),
    nombres: Yup.string().required("El Nombre es requerido"),
    apellidos: Yup.string().required("Los Apellidos son requeridos"),
    foto: Yup.mixed(),
    email: Yup.string()
      .email("Ingrese un Email valido")
      .required("El Email es requerido"),
    
    fecha_nacimiento: Yup.date("Ingrese una fecha correcta"),
    celular: Yup.string()
      .required("El celular es requerido")
      .matches(/^[0-9]+$/, "Deben ser digitos")
      .min(5, "La longitud debe ser mayor que 5")
      .max(16, "La longitud debe ser menor que 16"),
    telefono: Yup.string()
      .matches(/^[0-9]+$/, "Deben ser digitos")
      .min(5, "La longitud debe ser mayor que 5")
      .max(16, "La longitud debe ser menor que 16"),
  };
}

const IdentificacionOptions = [
  {
    key: 1,
    text: "Cedula",
    value: 1,
  },
  {
    key: 2,
    text: "Pasaporte",
    value: 2,
  },
];


