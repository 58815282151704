import React, { useCallback, useEffect, useState } from 'react'
import { useControlDocTransportadora } from '../../../hooks';
import { TablaControl } from '../../Comons';

export function ModuloControlTransportadora() {
  const { ControlDocTransportadora, getControlDocTransportadora, addControlDocTransportadora, updateControlDocTransportadora, deleteControlDocTransportadora } = useControlDocTransportadora();
  const [refetch, setRefetch] = useState(false);
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);
  useEffect(() => {
    getControlDocTransportadora();
  }, [refetch]);
  return (
    <>
      <TablaControl documentos={ControlDocTransportadora}
        addDocumentos={addControlDocTransportadora}
        updateDocumentos={updateControlDocTransportadora}
        deleteDocumentos={deleteControlDocTransportadora}
        onRefetch={onRefetch}
        suggestions={suggestions}
      />
    </>
  )
}
const suggestions = [];
