import { BASE_API } from "../../utils/constants";
import { makeRequest } from "../Request";
const BASE_URL = `${BASE_API}/`;


// Función para obtener los datos 
export async function getMezclasApi(token,schema) {
  const url = `${BASE_URL}${schema}/mezclas/mezcla`;
  return makeRequest(url, "GET", token);
}

// Función para agregar un nuevo dato
export async function addMezclasApi(data, token,schema) {
  const url = `${BASE_URL}${schema}/mezclas/mezcla/`;
  return makeRequest(url, "POST", token, data);
}

// Función para actualizar los datos 
export async function updateMezclasApi(id, data, token,schema) {
  const url = `${BASE_URL}${schema}/mezclas/mezcla/${id}/`;
  return makeRequest(url, "PUT", token, data);
}

// Función para eliminar 
export async function deleteMezclasApi(id, token, schema) {
  const url = `${BASE_URL}${schema}/mezclas/mezcla/${id}/`;
  return makeRequest(url, "DELETE", token);
}