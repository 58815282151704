import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Typography } from '@material-ui/core';
// import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#FFFFFF',
    borderRadius: 10,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },
  title: {
    
  },
}));


export function HeaderPage(props) {
  const classes = useStyles();
  const { title, btnTitle, btnClick } = props;

  return (
    <Box className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        {title}
      </Typography>
      {btnTitle && (
          <Button
            variant="contained"
            color="secondary"
            className="btn btn-danger"
            startIcon={<AddIcon />}
            onClick={btnClick}
          >
            {btnTitle}
          </Button>
        )}
    </Box>
  );
}
