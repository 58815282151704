import Tooltip from "@material-ui/core/Tooltip";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import ChecklistIcon from "@mui/icons-material/Checklist";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import HistoryIcon from "@mui/icons-material/History";

// import {
//   Avatar,
//   Button,
//   Card,
//   CardContent,
//   Dialog,
//   DialogContent,
//   DialogTitle,
//   Divider,
//   IconButton,
//   Stack,
//   Typography
// } from "@mui/material";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid"

import PropTypes from "prop-types";
import { useState } from "react";
import { ComponentConciliaciones } from "../../Components/Client";
import tanques2 from "./images/cam.png";
import tanques3 from "./images/esf.jpg";
import tanques1 from "./images/images.jpg";
import { TankClientModal } from "./tank-client-modal";
import { TankModal } from "./tank-modal";

export const TanquesCard = (props) => {
  const {
    tank,
    activeChecklist,
    volumen,
    temperatura,
    eliminar,
    actualizar,
    makeChecklist,
    viewChecklist,
    viewHistoricChecklists,
  } = props;

  let avatarImg;
  if (tank?.tipo_tanque === "1") {
    avatarImg = tanques1;
  } else if (tank?.tipo_tanque === "2") {
    avatarImg = tanques2;
  } else {
    avatarImg = tanques3;
  }

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [openConciliacion, setOpenConciliacion] = useState(false);

  const handleOpenModalC = () => {
    setOpenConciliacion(true);
  };

  const handleCloseModalC = () => {
    setOpenConciliacion(false);
  };

  return (
    <>
      <Card sx={{ height: "100%" }}>
        <CardHeader
          sx={{
            backgroundColor:
              tank?.tipo_medicion === "1"
                ? "rgba(224, 224, 224, 0.7)"
                : tank?.tipo_medicion === "2"
                  ? "rgba(0, 0, 0, 0.7)"
                  : "inherit",
            borderRadius: "8px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            color: tank?.tipo_medicion === "2" ? "#ffffff" : "inherit",
            "& .MuiCardHeader-title": {
              fontSize: "1.5rem",
              display: "flex",
              alignItems: "center",
            },
            "& .MuiCardHeader-action": {
              "& svg": {
                color: tank?.tipo_medicion === "2" ? "#ffffff" : "inherit",
              },
            },
          }}
          avatar={<Avatar src={avatarImg} aria-label="recipe" />}
          action={
            <>
              <IconButton sx={{ top: 0, right: 0 }} onClick={handleOpenModalC}>
                <AssignmentLateIcon />
              </IconButton>
              <IconButton sx={{ top: 0, right: 0 }} onClick={handleOpenModal}>
                <GroupAddIcon />
              </IconButton>
            </>
          }
          title={
            <>
              {tank?.nombre_tanque}
              {tank?.tipo_medicion === "1" && (
                <span style={{ fontSize: "0.8rem", marginLeft: "0.5rem" }}>
                  (ESTATICA)
                </span>
              )}
              {tank?.tipo_medicion === "2" && (
                <span style={{ fontSize: "0.8rem", marginLeft: "0.5rem" }}>
                  (DINAMICA)
                </span>
              )}
            </>
          }
        />

        <CardContent>
          <Stack direction="column" spacing={2}>
            <Typography variant="body2" color="text.secondary">
              Código: {tank?.codigo_tanque}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Altura de referencia: {tank?.altura_referencia} m
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Capacidad: {tank?.capacidad_tanque} m³
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Temperatura de operación: {tank?.temperatura_operacion} °C
            </Typography>

            <Typography variant="body2" color="text.secondary">
              Gravedad API: {tank?.GravedadApi}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Operatividad:{" "}
              {tank?.operative == true
                ? "Operativo"
                : "No operativo debido a faltas en la inspección externa"}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Consumo interno: {tank?.is_consumo_int == true ? "Si" : "No"}
            </Typography>
          </Stack>
        </CardContent>
        <Divider />
        <Grid container spacing={0.4} padding={0.4}>
          <Grid item xs={12} sm={4}>
            <Button
              fullWidth
              sx={{
                backgroundColor: "#7E7E7E",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#BEBEBE", // Color gris cuando se hace hover
                },
              }}
              onClick={() => setOpen(true)}
            >
              Ver
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              fullWidth
              sx={{
                backgroundColor: "#444444",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#BEBEBE", // Color gris cuando se hace hover
                },
              }}
              onClick={() => actualizar(tank)}
            >
              Actualizar
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              fullWidth
              sx={{
                backgroundColor: "#D0302B",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#BEBEBE", // Color gris cuando se hace hover
                },
              }}
              onClick={() => eliminar(tank)}
            >
              Eliminar
            </Button>
          </Grid>

          <Grid item sx={12} sm={12}>
            <Divider textAlign="center">Inspección del tanque</Divider>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Tooltip title="Realizar inspección externa" arrow>
              <Button fullWidth onClick={() => makeChecklist(tank, activeChecklist)} >
                <DoneAllIcon />
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Tooltip title="Ver inspección actual" arrow>
              <Button fullWidth onClick={() => viewChecklist(tank)} >
                <ChecklistIcon />
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Tooltip title="Ver historial de inspecciones" arrow>
              <Button fullWidth onClick={() => viewHistoricChecklists(tank)} >
                <HistoryIcon />
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Card>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{tank?.nombre_tanque}</DialogTitle>
        <DialogContent>
          <TankModal data={tank} />
        </DialogContent>
      </Dialog>
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogContent>
          <TankClientModal data={tank} onClose={handleCloseModal} />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openConciliacion}
        onClose={handleCloseModalC}
        s
        x={{ maxWidth: "lg", width: "100%", height: "100%" }}
      >
        <DialogTitle>{tank?.nombre_tanque}</DialogTitle>
        <DialogContent>
          <ComponentConciliaciones
            onClose={handleCloseModalC}
            tanque={tank?.id_tanque}
          // producto={tank?.id_producto}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

TanquesCard.propTypes = {
  tank: PropTypes.shape({
    id_tanque: PropTypes.number.isRequired,
    nombre_tanque: PropTypes.string.isRequired,
    codigo_tanque: PropTypes.string.isRequired,
    altura_referencia: PropTypes.number.isRequired,
    capacidad_tanque: PropTypes.number.isRequired,
    temperatura_operacion: PropTypes.number.isRequired,

    tipo_tanque: PropTypes.string.isRequired,
    GravedadApi: PropTypes.number.isRequired,
  }).isRequired,
};
