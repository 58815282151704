// * Create the store with dynamic reducers

import { configureStore } from "@reduxjs/toolkit";
import { createInjectorsEnhancer } from "redux-injectors";
import createSagaMiddleware from "redux-saga";
import { reducer as receiptReducer } from "../modules/receipt/slice";
import { reducer as despatchReducer } from "../modules/despatch/slice";
import { reducer as storageReducer } from "../modules/storage/slice";

import { createReducer } from "./reducers";

export function createAppStore() {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const { run: runSaga } = sagaMiddleware;

  // Create the store with saga middleware
  const middlewares = [sagaMiddleware];

  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ];

  const store = configureStore({
    reducer: createReducer({
      receipt: receiptReducer,
      despatch: despatchReducer,
      storage: storageReducer,
    }),
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({ serializableCheck: false }),
      ...middlewares,
    ],
    devTools: process.env.NODE_ENV !== "production",
    enhancers,
  });

  return store;
}

const store = createAppStore();

export default store;
