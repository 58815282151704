import React from "react";

export function VisualizacionGeneralForm(props) {
  const { visualizacion } = props;

  return (
    <div class="container">
      <h1 class="text-center">Información del usuario</h1>
      <div class="row">
        {visualizacion ? (
          <div class="col-sm-4 text-center">
            <img
              src={visualizacion.foto}
              alt={visualizacion.nombres + " " + visualizacion.apellidos}
              class="img-fluid"
            />
          </div>
        ) : (
          ""
        )}
        <div class="col-sm-8">
          {visualizacion ? (
            <p>
              <b>Nombres:</b> {visualizacion.nombres}
            </p>
          ) : (
            ""
          )}
          {visualizacion ? (
            <p>
              <b>Apellidos:</b> {visualizacion.apellidos}
            </p>
          ) : (
            ""
          )}
          {visualizacion ? (
            <p>
              <b>Identificación:</b> {visualizacion.identificacion_pers}
            </p>
          ) : (
            ""
          )}
          {visualizacion ? (
            <p>
              <b>Email:</b> {visualizacion.email}
            </p>
          ) : (
            ""
          )}
          
          {visualizacion ? (
            <p>
              <b>Fecha de nacimiento:</b> {visualizacion.fecha_nacimiento}
            </p>
          ) : (
            ""
          )}
          {visualizacion ? (
            <p>
              <b>Teléfono:</b> {visualizacion.telefono}
            </p>
          ) : (
            ""
          )}
          {visualizacion ? (
            <p>
              <b>Celular:</b> {visualizacion.celular}
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
