import React, { useEffect, useState } from "react"

import Divider from "@mui/material/Divider";
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from "@mui/material/Typography";
import { Autocomplete } from "@mui/material";
import { Add as AddIcon, Delete as DeleteIcon, Toll } from '@material-ui/icons';

import { Formik, useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  useAditivos,
  useProductoBase,
  useProductoMezcla,
  useControladores,
  useMultiLdProd,
} from "../../../../hooks";
import { alertas } from "../../../Comons/Alertas/alertas";

const indexOptions = [
  { key: '031', value: '031', text: '031' },
  { key: '032', value: '032', text: '032' },
  { key: '033', value: '033', text: '033' },
  { key: '034', value: '034', text: '034' },
  { key: '035', value: '035', text: '035' },
  { key: '036', value: '036', text: '036' },
]

export function AddEditProductosMezcla(props) {
  const { Successful } = alertas();
  const { onClose, onRefetch, ProductosMezcla } = props;
  const { addProductoMezcla, updateProductoMezcla } = useProductoMezcla();
  const { ProductoBaseDB, getProductoBaseDB } = useProductoBase();
  const { AditivosDB, getAditivosDB } = useAditivos();
  const { CtrlCompFlujo, getControladorCompFlujo } = useControladores();
  const [selectedController, setSelectedController] = useState('')
  const { handleAddMultiLdProd } = useMultiLdProd()
  const [selectedBaseProdID, setSelectedBaseProdID] = useState([])
  const [selectedAdd, setSelectedAdd] = useState([])
  const [selectedArms, setSelectedArms] = useState([])

  const [errors, setErrors] = useState({

  })

  useEffect(() => {
    getProductoBaseDB();
    getAditivosDB();
    getControladorCompFlujo()
  }, []);

  const formik = useFormik({
    initialValues: initialValues(ProductosMezcla),
    validationSchema: Yup.object(newSchema()),
    validateOnChange: false,
    onSubmit: async (formValues) => {
      try {

        formValues.longDesc = formValues.nombre_comercial
        formValues.shortDesc = formValues.multiload_name

        if (formValues.isBase) {
          if (formValues.isBaseWAdd) {
            // indica que el producto a crear es un componente base con aditivo
            formValues.operation = "3"

            formValues.aditive = selectedAdd
          } else {
            // indica que el producto a crear es un componente base
            formValues.operation = "2"
          }

          const base_prod = {
            productos_base: selectedBaseProdID,
            porcentaje_producto: '100',
            index: formValues.index
          }

          const existingIndex = formValues.productos_base.findIndex(
            (item) => item.productos_base === selectedBaseProdID
          );

          if (existingIndex !== -1) {
            formValues.productos_base[existingIndex] = base_prod;
          } else {
            formValues.productos_base = [base_prod];
          }
        } else {
          formValues.operation = "4"
        }

        console.log(formValues)

        if (ProductosMezcla && formValues.setMLII) {
          formValues.is_updating = true
          await handleAddMultiLdProd(formValues)
        } else if (formValues.setMLII) {
          await handleAddMultiLdProd(formValues)
        }

        if (formValues.operation === "3") {
          formValues.index = formValues.comp_index
        }

        if (ProductosMezcla)
          await updateProductoMezcla(ProductosMezcla.id, formValues);
        else await addProductoMezcla(formValues);
        Successful();
        onClose();
        onRefetch();
      } catch (error) {
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });

  const buttonStyle = {
    backgroundColor: "rgb(204, 48, 43)", // Establecer el color de fondo en RGB
    color: "white", // Establecer el color del texto en blanco o el que desees
  };

  return (
    <Formik initialValues={initialValues()}>
      <form className="add-edit-secciones-form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          {/* primera fila */}
          <Grid item xs={12} sm={4}>
            {formik.values.isBaseWAdd || formik.values.setMLII && !formik.values.isBase ? (
              <>
                <Autocomplete
                  id="comp_index"
                  options={indexOptions}
                  getOptionLabel={(option) => option.text}
                  value={
                    indexOptions?.find(
                      (opt) => opt.key === formik.values?.comp_index
                    ) || null
                  }
                  onChange={(_, value) => {
                    formik.setFieldValue("comp_index", value?.key);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Index"
                      fullWidth
                      name="comp_index"
                      variant="outlined"
                      error={formik.errors.comp_index && Boolean(formik.errors.comp_index)}
                      helperText={formik.touched.comp_index && formik.errors.comp_index}
                      sx={{ background: "#fcfcfc" }}
                    />
                  )}
                />
              </>
            ) : (
              <TextField
                id="index"
                name="index"
                label="Index"
                variant="outlined"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.index}
                error={formik.touched.index && Boolean(formik.errors.index)}
                helperText={formik.touched.index && formik.errors.index}
                disabled={formik.values.isBase}
              />
            )}

          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="code"
              name="code"
              label="Code"
              variant="outlined"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.code}
              error={formik.touched.code && Boolean(formik.errors.code)}
              helperText={formik.touched.code && formik.errors.code}
              disabled={formik.values.isBase}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="color"
              name="color"
              label="Color"
              variant="outlined"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.color}
              error={formik.touched.color && Boolean(formik.errors.color)}
              helperText={formik.touched.color && formik.errors.color}
            />
          </Grid>

          {/* segunda fila */}
          <Grid item xs={12} sm={4}>
            <TextField
              id="nombre_comercial"
              name="nombre_comercial"
              label="Nombre Comercial"
              variant="outlined"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.nombre_comercial}
              error={
                formik.touched.nombre_comercial &&
                Boolean(formik.errors.nombre_comercial)
              }
              helperText={
                formik.touched.nombre_comercial &&
                formik.errors.nombre_comercial
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="nombre"
              name="nombre"
              label="Nombre"
              variant="outlined"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.nombre}
              error={formik.touched.nombre && Boolean(formik.errors.nombre)}
              helperText={formik.touched.nombre && formik.errors.nombre}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="multiload_name"
              name="multiload_name"
              label="Multiload Name"
              variant="outlined"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.multiload_name}
              error={
                formik.touched.multiload_name &&
                Boolean(formik.errors.multiload_name)
              }
              helperText={
                formik.touched.multiload_name && formik.errors.multiload_name
              }
            />
          </Grid>

          {/* tercer fila */}
          <Grid item xs={12} sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.isBase}
                  onChange={(e) => {
                    formik.handleChange(e)
                    if (e.target.checked || !e.target.checked) {
                      formik.setFieldValue('isBaseWAdd', false)
                      formik.setFieldValue('productos_base', [])
                      formik.setFieldValue('aditivo', [])
                      formik.setFieldValue('aditivo_marcador', [])
                      formik.setFieldValue('muestra', '')
                      formik.setFieldValue('aditive', [])
                    }
                  }}
                  name="isBase"
                  color="primary"
                />
              }
              label="Crear como componente base"
            />
            <Tooltip title="Establece el porcentaje del componente al 100%" placement="top">
              <HelpIcon style={{ color: "gray", marginBottom: "8px" }} />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={4}>
            {formik.values.isBase ? (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.isBaseWAdd}
                      onChange={(e) => {
                        formik.handleChange(e)
                        if (!e.target.checked) {
                          formik.setFieldValue('aditivo', []);
                          formik.setFieldValue('aditive', [])
                        }
                      }}
                      name="isBaseWAdd"
                      color="primary"
                    />
                  }
                  label="Añadir aditivo"
                />
                <Tooltip title="Crear componente base al 100% con un aditivo" placement="top">
                  <HelpIcon style={{ color: "gray", marginBottom: "8px" }} />
                </Tooltip>
              </>
            ) : (<></>)}
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.setMLII}
                  onChange={formik.handleChange}
                  name="setMLII"
                  color="primary"
                />
              }
              label="Crear en MLII"
            />
            <Tooltip title="Crear mezcla o componente base en el MLII" placement="top">
              <HelpIcon style={{ color: "gray", marginBottom: "8px" }} />
            </Tooltip>
          </Grid>
        </Grid>

        <br />

        {/* Sección para asignar al MLII */}
        {
          formik.values.setMLII ? (
            <>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    id="controlador"
                    options={CtrlCompFlujo}
                    getOptionLabel={(option) => option.modelo + " " + option.marca}
                    value={
                      CtrlCompFlujo?.find(
                        (opt) => opt.id_controlador === formik.values?.controlador
                      ) || null
                    }
                    onChange={(_, value) => {
                      formik.setFieldValue("controlador", value?.id_controlador);
                      setSelectedController(value?.id_controlador)
                      const selectedArms = CtrlCompFlujo.find((ctrl) => ctrl.id_controlador === value?.id_controlador)
                      setSelectedArms(selectedArms?.arms || [])
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Controlador"
                        fullWidth
                        name="controlador"
                        variant="outlined"
                        error={formik.errors.controlador && Boolean(formik.errors.controlador)}
                        helperText={formik.touched.controlador && formik.errors.controlador}
                        sx={{ background: "#fcfcfc" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <>
                    <Autocomplete
                      disabled={!selectedController || selectedArms.length <= 0}
                      id="arm"
                      options={selectedArms}
                      getOptionLabel={(option) => option.name ? option.name : ""}
                      value={
                        selectedArms?.find(
                          (opt) => opt.index === formik.values?.arm
                        ) || null
                      }
                      onChange={(_, value) => {
                        formik.setFieldValue("arm", value ? value.index : "");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Brazo"
                          variant="outlined"
                          name="arm"
                          required
                          error={formik.errors.arm && Boolean(formik.errors.arm)}
                          helperText={selectedArms.length <= 0 ? "No hay brazos" : ""}
                          sx={{ background: "#fcfcfc" }}
                        />
                      )}
                    />
                  </>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.assigned}
                        onChange={formik.handleChange}
                        name="assigned"
                        color="primary"
                      />
                    }
                    label="Asignar el componente en el MLII"
                  />
                  <Tooltip title="Establece el componente creado como asignado en el MLII" placement="top">
                    <HelpIcon style={{ color: "gray", marginBottom: "8px" }} />
                  </Tooltip>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.authorized}
                        onChange={formik.handleChange}
                        name="authorized"
                        color="primary"
                      />
                    }
                    label="Autorizar el componente en el MLII"
                  />
                  <Tooltip title="Establece el aditivo creado como autorizado en el MLII" placement="top">
                    <HelpIcon style={{ color: "gray", marginBottom: "8px" }} />
                  </Tooltip>
                </Grid>
              </Grid>
            </>
          ) : (<></>)
        }

        <br />

        {/* sección para elegir el producto base en caso de que sea un producto 100% */}
        {
          formik.values.isBase ? (
            <>
              <Grid item xs={12}>
                <Autocomplete
                  id="index"
                  options={ProductoBaseDB}
                  getOptionLabel={(option) => option.nombre ? option.nombre : ""}
                  value={
                    ProductoBaseDB?.find(
                      (opt) => opt.index === formik.values?.index
                    ) || null
                  }
                  onChange={(_, value) => {
                    formik.setFieldValue("index", value ? value.index : "");
                    const selectedOption = ProductoBaseDB.find((option) => option.index === value.index)
                    //console.log(selectedOption)
                    formik.setFieldValue("code", selectedOption.code)
                    setSelectedBaseProdID(selectedOption.id)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Producto base"
                      variant="outlined"
                      name="index"
                      required
                      error={formik.errors.index && Boolean(formik.errors.index)}
                      helperText=""
                      sx={{ background: "#fcfcfc" }}
                    />
                  )}
                />
              </Grid>
            </>
          ) : formik.values.isBaseWAdd ? (
            <>
              <Grid item xs={12}>
                <Autocomplete
                  id="comp_index"
                  options={ProductoBaseDB}
                  getOptionLabel={(option) => option.nombre ? option.nombre : ""}
                  value={
                    ProductoBaseDB?.find(
                      (opt) => opt.id === formik.values?.comp_index
                    ) || null
                  }
                  onChange={(_, value) => {
                    formik.setFieldValue("comp_index", value ? value.id : "");
                    const selectedOption = ProductoBaseDB.find((option) => option.id === value.id)
                    formik.setFieldValue("code", selectedOption.code)
                    setSelectedBaseProdID(selectedOption.id)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Producto base"
                      variant="outlined"
                      name="comp_index"
                      required
                      error={formik.errors.comp_index && Boolean(formik.errors.comp_index)}
                      helperText=""
                      sx={{ background: "#fcfcfc" }}
                    />
                  )}
                />
              </Grid>
            </>
          ) : (<></>)
        }

        <br />

        {/* Sección de productos */}
        {
          !formik.values.isBase ? (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card variant="outlined">
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        Productos
                      </Typography>
                      {formik.values.productos_base.map((variable, index) => (
                        <>
                          <Grid container spacing={2} key={index}>
                            <Grid item xs={6}>
                              <Autocomplete
                                id={`productos_base[${index}].productos_base`}
                                name={`productos_base[${index}].productos_base`}
                                label="Produto base"
                                variant="outlined"
                                fullWidth
                                options={ProductoBaseDB}
                                getOptionLabel={(comp_base) => comp_base.nombre || ''}
                                value={ProductoBaseDB.find((option) => option.id === variable.productos_base) || null}
                                onChange={(event, selectedOption) => {
                                  formik.setFieldValue(`productos_base[${index}].productos_base`, selectedOption ? selectedOption.id : '') // Update formik value
                                  formik.setFieldValue(`productos_base[${index}].index`, selectedOption ? selectedOption.index : '') // Update index value
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Componente base"
                                    variant="outlined"
                                  />
                                )}
                                error={formik.errors[`productos_base[${index}].productos_base`]} // Update error handling
                                helperText={
                                  formik.touched.productos_base &&
                                  formik.errors.productos_base
                                }
                              />
                            </Grid>

                            <Grid item xs={5}>
                              <TextField
                                id={`productos_base[${index}].porcentaje_producto`}
                                name={`productos_base[${index}].porcentaje_producto`}
                                label="Porcentaje de producto"
                                variant="outlined"
                                fullWidth
                                onChange={formik.handleChange}
                                value={variable.porcentaje_producto}
                                error={
                                  formik.errors[
                                  `productos_base[${index}].porcentaje_producto`
                                  ]
                                }
                              />
                            </Grid>

                            <Grid item xs={1} style={{ display: 'flex', alignItems: 'flex-end' }}>
                              <Tooltip title="Eliminar" arrow>
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  style={{
                                    height: '100%', display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '0',
                                    margin: '0',
                                    color: 'rgba(252, 0, 0, 0.8)',
                                    border: '1px solid rgba(252, 0, 0, 0.8)',
                                  }}
                                  onClick={() =>
                                    formik.setFieldValue(
                                      "productos_base",
                                      formik.values?.productos_base?.filter(
                                        (_, i) => i !== index
                                      )
                                    )
                                  }
                                >
                                  <DeleteIcon style={{ margin: '0' }} />
                                </Button>
                              </Tooltip>
                            </Grid>
                          </Grid>
                          <br />
                        </>
                      ))}

                      <Grid item xs={12}>
                        <Tooltip title="Agregar" arrow>
                          <Button
                            fullWidth
                            type="button"
                            style={{
                              color: 'rgb(25, 118, 210)',
                              border: '1px solid rgb(25, 118, 210)',
                              '&:hover': {
                                color: 'rgba(221, 223, 221, 0.62)',
                                border: '1px solid rgba(221, 223, 221, 0.62)',
                              }
                            }}
                            onClick={() =>
                              formik.setFieldValue("productos_base", [
                                ...formik.values.productos_base,
                                { productos_base: "", porcentaje_producto: "", index: "" },
                              ])
                            }
                          >
                            <AddIcon />
                          </Button>
                        </Tooltip>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              <br />
            </>
          ) : (<></>)
        }

        {/* Sección de aditivos */}
        {
          !formik.values.isBase || formik.values.isBaseWAdd ? (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card variant="outlined">
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        Aditivos
                      </Typography>
                      {formik.values.aditivo.map((variable, index) => (
                        <>
                          <Grid container spacing={2} key={index}>
                            <Grid item xs={6}>
                              <Autocomplete
                                id={`aditivo[${index}].aditivo`}
                                name={`aditivo[${index}].aditivo`}
                                label="Aditivo"
                                variant="outlined"
                                fullWidth
                                options={AditivosDB}
                                getOptionLabel={(aditivo) => aditivo.nombre || ''}
                                value={AditivosDB.find((option) => option.id === variable.aditivo) || null}
                                onChange={(event, selectedOption) => {
                                  formik.setFieldValue(`aditivo[${index}].aditivo`, selectedOption ? selectedOption.id : '') // Update formik value
                                  if (selectedOption) {
                                    formik.setFieldValue(`aditivo[${index}].index`, selectedOption.codigo_aditivo.padStart(3, '0')) // Update index value
                                    setSelectedAdd(selectedOption)
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Aditivo"
                                    variant="outlined"
                                  />
                                )}
                                error={formik.errors[`aditivo[${index}].aditivo`]} // Update error handling
                                helperText={
                                  formik.touched.aditivo && formik.errors.aditivo
                                }
                              />
                            </Grid>
                            <Grid item xs={5}>
                              <TextField
                                id={`aditivo[${index}].aditivo_porcentaje`}
                                name={`aditivo[${index}].aditivo_porcentaje`}
                                label="Porcentaje de aditivo"
                                variant="outlined"
                                fullWidth
                                onChange={formik.handleChange}
                                value={variable.aditivo_porcentaje}
                                error={
                                  formik.errors[`aditivo[${index}].aditivo_porcentaje`]
                                }
                              />
                            </Grid>

                            <Grid item xs={1}>
                              <Tooltip title="Eliminar" arrow>
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  style={{
                                    height: '100%', display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '0',
                                    margin: '0',
                                    color: 'rgba(252, 0, 0, 0.8)',
                                    border: '1px solid rgba(252, 0, 0, 0.8)',
                                  }}
                                  onClick={() =>
                                    formik.setFieldValue(
                                      "aditivo",
                                      formik.values.aditivo?.filter(
                                        (_, i) => i !== index
                                      )
                                    )
                                  }
                                >
                                  <DeleteIcon style={{ margin: '0' }} />
                                </Button>
                              </Tooltip>
                            </Grid>
                          </Grid>
                          <br />
                        </>
                      ))}

                      <Grid item xs={12}>
                        <Tooltip title="Agregar" arrow>
                          <Button
                            fullWidth
                            type="button"
                            style={{
                              color: 'rgb(25, 118, 210)',
                              border: '1px solid rgb(25, 118, 210)',
                              '&:hover': {
                                color: 'rgba(221, 223, 221, 0.62)',
                                border: '1px solid rgba(221, 223, 221, 0.62)',
                              }
                            }}
                            onClick={() =>
                              formik.setFieldValue("aditivo", [
                                ...formik.values.aditivo,
                                { aditivo: "", aditivo_porcentaje: "", index: "" },
                              ])
                            }
                          >
                            <AddIcon />
                          </Button>
                        </Tooltip>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </>
          ) : (<></>)
        }

        {/* Sección de aditivos marcadores */}
        {
          !formik.values.isBase ? (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card variant="outlined">
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        Aditivos Marcadores
                      </Typography>
                      {formik.values.aditivo_marcador.map((variable, index) => (
                        <>
                          <Grid container spacing={2} key={index}>
                            <Grid item xs={6}>
                              <Autocomplete
                                id={`aditivo_marcador[${index}].aditivo_marcador`}
                                name={`aditivo_marcador[${index}].aditivo_marcador`}
                                label="Aditivo marcador"
                                variant="outlined"
                                fullWidth
                                options={AditivosDB.filter((aditivo) => aditivo.tipo === true)}
                                getOptionLabel={(aditivo) => aditivo.nombre || ''}
                                value={variable.aditivo_marcador || null}
                                onChange={(event, selectedOption) => {
                                  formik.setFieldValue(`aditivo_marcador[${index}].aditivo_marcador`, selectedOption ? selectedOption.nombre : '') // Update formik value
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Aditivo marcador"
                                    variant="outlined"
                                  />
                                )}
                                error={formik.errors[`aditivo_marcador[${index}].aditivo_marcador`]} // Update error handling
                                helperText={
                                  formik.touched.aditivo_marcador &&
                                  formik.errors.aditivo_marcador
                                }
                              />

                            </Grid>

                            <Grid item xs={5}>
                              <TextField
                                id={`aditivo_marcador[${index}].aditivo_porcentaje_marcador`}
                                name={`aditivo_marcador[${index}].aditivo_porcentaje_marcador`}
                                label="Porcentaje de aditivo marcador"
                                variant="outlined"
                                fullWidth
                                onChange={formik.handleChange}
                                value={variable.aditivo_porcentaje_marcador}
                                error={
                                  formik.errors[
                                  `aditivo_marcador[${index}].aditivo_porcentaje_marcador`
                                  ]
                                }
                                helperText={
                                  formik.touched.aditivo_marcador &&
                                  formik.errors.aditivo_marcador
                                }
                              />
                            </Grid>

                            <Grid item xs={1}>
                              <Tooltip title="Eliminar variable" arrow>
                                <Button

                                  variant="outlined"
                                  color="secondary"
                                  style={{
                                    height: '100%', display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '0',
                                    margin: '0',
                                    color: 'rgba(252, 0, 0, 0.8)',
                                    border: '1px solid rgba(252, 0, 0, 0.8)',
                                  }}
                                  onClick={() =>
                                    formik.setFieldValue(
                                      "aditivo_marcador",
                                      formik.values?.aditivo_marcador?.filter(
                                        (_, i) => i !== index
                                      )
                                    )
                                  }
                                >
                                  <DeleteIcon style={{ margin: '0' }} />
                                </Button>
                              </Tooltip>
                            </Grid>
                            <Divider />
                          </Grid>
                          <br />
                        </>
                      ))}

                      <Grid item xs={12}>
                        <Tooltip title="Agregar" arrow>
                          <Button
                            fullWidth
                            type="button"
                            style={{
                              color: 'rgb(25, 118, 210)',
                              border: '1px solid rgb(25, 118, 210)',
                              '&:hover': {
                                color: 'rgba(221, 223, 221, 0.62)',
                                border: '1px solid rgba(221, 223, 221, 0.62)',
                              }
                            }}
                            onClick={() =>
                              formik.setFieldValue("aditivo_marcador", [
                                ...formik.values.aditivo_marcador,
                                {
                                  aditivo_marcador: "",
                                  aditivo_porcentaje_marcador: "",
                                },
                              ])
                            }
                          >
                            <AddIcon />
                          </Button>
                        </Tooltip>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              <br />

              {/* Input de marcador */}
              <Grid item xs={12}>
                <TextField
                  id="muestra"
                  name="muestra"
                  label="Muestra"
                  variant="outlined"
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.muestra}
                  error={formik.touched.muestra && Boolean(formik.errors.muestra)}
                  helperText={formik.touched.muestra && formik.errors.muestra}
                />
              </Grid>
            </>
          ) : (<></>)
        }

        <br />
        <Button type="submit" variant="contained" fullWidth style={buttonStyle}>
          {ProductosMezcla ? "Actualizar" : "Crear"}
        </Button>
      </form >
    </Formik >
  );
}

function initialValues(data) {
  return {
    index: data?.index || "",
    code: data?.code || "",
    multiload_name: data?.multiload_name || "",
    nombre: data?.nombre || "",
    nombre_comercial: data?.nombre_comercial || "",
    color: data?.color || "",
    productos_base: data?.productos_base || [],
    aditivo: data?.aditivo || [],
    aditivo_marcador: data?.aditivo_marcador || [],
    muestra: data?.muestra || "",
    setMLII: data?.setMLII ? true : false,
    longDesc: data?.longDesc || "",
    shortDesc: data?.shortDesc || "",
    operation: data?.operation || "",
    isBase: data?.operation == "2" || data?.operation == "3" ? true : false,
    isBaseWAdd: data?.operation == "3" ? true : false,
    controlador: data?.controlador || "",
    assigned: data?.assigned || false,
    authorized: data?.authorized || false,
    arm: data?.arm || "",
    is_updating: false,
    aditive: [],
    comp_index: ""
  };
}

function newSchema() {
  return {
    index: Yup.string().required('El campo "index" es obligatorio'),
    code: Yup.string().required('El campo "code" es obligatorio'),
    multiload_name: Yup.string().required('El campo "multiload_name" es obligatorio'),
    nombre: Yup.string().required('El campo "nombre" es obligatorio'),
    nombre_comercial: Yup.string().required('El campo "nombre_comercial" es obligatorio'),
    color: Yup.string(),
    // productos_base: Yup.object().required(
    //   'El campo "productos_base" es obligatorio'
    // ),
    // aditivo: Yup.object().required('El campo "aditivo" es obligatorio'),
    // aditivo_marcador: Yup.object().required(
    //   'El campo "aditivo_marcador" es obligatorio'
    // ),
    muestra: Yup.string(),

  };
}

// old comp base section
{/* <Select
                              id={`productos_base[${index}].productos_base`}
                              name={`productos_base[${index}].productos_base`}
                              label="Produto base"
                              variant="outlined"
                              fullWidth
                              onChange={(e) => {
                                formik.handleChange(e)
                                const selectedOption = ProductoBaseDB.find((option) => option.id === e.target.value)
                                formik.setFieldValue(`productos_base[${index}].index`, selectedOption.index)
                              }}
                              value={variable.productos_base}
                              error={
                                formik.errors[
                                `productos_base[${index}].productos_base`
                                ]
                              }
                              helperText={
                                formik.touched.productos_base &&
                                formik.errors.productos_base
                              }
                            >
                              {ProductoBaseDB?.map((comp_base) => (
                                <MenuItem key={comp_base.id} value={comp_base.id}>
                                  {comp_base.nombre}
                                </MenuItem>
                              ))}
                            </Select> */}

// old aditivo section
{/* <Select
                              id={`aditivo[${index}].aditivo`}
                              name={`aditivo[${index}].aditivo`}
                              label="Aditivo marcador"
                              variant="outlined"
                              fullWidth
                              onChange={(e) => {
                                formik.handleChange(e)
                                const selectedOption = AditivosDB?.find((option) => option.id === e.target.value)
                                formik.setFieldValue(`aditivo[${index}].index`, selectedOption.codigo_aditivo.padStart(3, '0'))
                                setSelectedAdd(selectedOption)
                              }}
                              value={variable.aditivo}
                              error={formik.errors[`aditivo[${index}].aditivo`]}
                              helperText={
                                formik.touched.aditivo && formik.errors.aditivo
                              }
                            >
                              {formik.values.isBaseWAdd ? (
                                AditivosDB?.map((aditivo) => (
                                  <MenuItem key={aditivo.id} value={aditivo.id}>
                                    {aditivo.tipo === false ? aditivo.nombre + " - aditivo base" : aditivo.nombre + " - aditivo marcador"}
                                  </MenuItem>
                                ))
                              ) : (
                                AditivosDB?.filter(
                                  (aditivo) => aditivo.tipo === false
                                ).map((aditivo) => (
                                  <MenuItem key={aditivo.id} value={aditivo.id}>
                                    {aditivo.nombre}
                                  </MenuItem>
                                ))
                              )}
                            </Select> */}

{/* <Select
                              id={`aditivo_marcador[${index}].aditivo_marcador`}
                              name={`aditivo_marcador[${index}].aditivo_marcador`}
                              label="Aditivo marcador"
                              variant="outlined"
                              fullWidth
                              onChange={formik.handleChange}
                              value={variable.aditivo_marcador}
                              error={
                                formik.errors[
                                `aditivo_marcador[${index}].aditivo_marcador`
                                ]
                              }
                              helperText={
                                formik.touched.aditivo_marcador &&
                                formik.errors.aditivo_marcador
                              }
                            >
                              {AditivosDB?.filter(
                                (aditivo) => aditivo.tipo === true
                              ).map((aditivo) => (
                                <MenuItem key={aditivo.id} value={aditivo.nombre}>
                                  {aditivo.nombre}
                                </MenuItem>
                              ))}
                            </Select> */}