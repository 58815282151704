import React, { useEffect, useState } from "react";
import { Loader } from "semantic-ui-react";
import { HeaderPage } from "../../Components/Client";
import { DataGridBasic, ModalBasic } from "../../Components/Comons";
import { useOrdenes } from "../../hooks";
import { Funciones } from "./Funciones";
import { filterValue, groups,ExcelColumns } from "./OrdenesFormat";




export function FacturacionAdmin() {
  const { loading, OrdenesFacturador,  getOrdenesFacturador } = useOrdenes();
  const [showExtraColumns, setShowExtraColumns] = useState(false); // Estado para mostrar las columnas adicionales
  const { columns, refetch, contentModal, titleModal, showModal, extraColumns, updateOrdenes, vistaTransacciones, openCloseModal } = Funciones();


  useEffect(() => {
     getOrdenesFacturador();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);
  const handleToggleColumns = () => {
    // Cambiar el estado showExtraColumns al hacer clic en el botón de recargar o reorganizar
    setShowExtraColumns((prevShowExtraColumns) => !prevShowExtraColumns);
  };

  return (
    <>
      <HeaderPage
        title="Ordenes y facturacion cargadero"
        // btnTitle="Nueva orden cargue"
        icono="plus square icon"
        // btnClick={addOrdenes}
      />

      {loading ? (
        <Loader active inline="centered">
          Cargando...
        </Loader>
      ) : (

        <DataGridBasic
          data={OrdenesFacturador?.results}
          title="Ordenes"
          id="id_orden"
          filterValue={filterValue}
          ExcelColumns={ExcelColumns}
          columns={showExtraColumns ? extraColumns : columns} // Selecciona las columnas a mostrar
          updateData={updateOrdenes}
        
          visualizacionData={vistaTransacciones}
          cambioTabla={handleToggleColumns}
          groups={groups}
        
        />

      )}

      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        title={titleModal}
        children={contentModal}

      />


    </>
  );
}
//Columnas que se envian en forma de props al componente tabla basic para representer sus campos
