import React, { useEffect, useState } from "react";
// import Tab from "@mui/material/Tab"
// import Tabs from "@mui/material/Tabs"
import { Tab } from 'semantic-ui-react'

import { AditivosAdmin } from "../../index"
import { ProductoBaseAdmin } from "../../index"
import { ProductosMezclaAdmin } from "../../index"

export function GroupedProductos() {
    const panes = [
        {
            menuItem: { key: 'GruposPermisosAdmin', content: <>Productos vendibles</> },
            render: () => <Tab.Pane><ProductosMezclaAdmin /></Tab.Pane>,
        },
        {
            menuItem: { key: 'ParametrizacionDecimalesComputador', content: <>Adición</> },
            render: () => <Tab.Pane><AditivosAdmin /></Tab.Pane>,
        },
        {
            menuItem: { key: 'Validaciones', content: <>Productos base</> },
            render: () => <Tab.Pane><ProductoBaseAdmin /></Tab.Pane>,
        },
    ]

    return (
        <>
            <Tab panes={panes} />
        </>
    )
}