import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../hooks";
import { BASE_API } from "../../../../utils/constants";

import { Checkbox } from "@material-ui/core";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

export function AddEditGruposPermisos(props) {
  const { onClose, onRefetch, GruposPermisos } = props;
  const [permisos, setPermisos] = useState([]);

  const { auth } = useAuth();
  const [submitted, setSubmitted] = useState(false);
  const [selectedPermisos, setSelectedPermisos] = useState([]);
  const [nombreGrupo, setNombreGrupo] = useState("");
  const url = `${BASE_API}/${auth.me.tenant}/multicliente/permiso`;

  useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((data) => setPermisos(data))
      .catch((error) => console.log(error));
  }, []);


  const handleCheckboxChange = (e, index) => {
    const { name, value, checked } = e.target;

    setSelectedPermisos((prevSelectedPermisos) => {
      return prevSelectedPermisos.map((permiso, permisoIndex) => {
        if (permisoIndex === index) {
          return {
            ...permiso,
            [name]: checked,
          };
        }
        return permiso;
      });
    });


    const existingPermiso = selectedPermisos.find(
      (permiso) => permiso.codename === value
    );

    if (!existingPermiso) {
      const selectedPermiso = permisos.find(
        (permiso) => permiso.codename === value
      );
      if (selectedPermiso) {
        const newPermiso = {
          id: selectedPermiso.id,
          codename: selectedPermiso.codename,
          ver: false,
          agregar: false,
          editar: false,
          eliminar: false,
        };
        newPermiso[name] = checked;
        setSelectedPermisos((prevSelectedPermisos) => [
          ...prevSelectedPermisos,
          newPermiso,
        ]);
      }
    } else {
      existingPermiso[name] = checked;
    }
  };

  const urlg = `${BASE_API}/${auth.me.tenant}/multicliente/grupos_permiso/`;
  const handleCreateGroup = () => {
    // Verificar si el campo nombreGrupo está vacío
    setSubmitted(true);

    // Validar si el campo nombreGrupo está vacío
    if (nombreGrupo === "") {
      return;
    }

    const permissions = selectedPermisos.map((permiso) => permiso.id);

    const data = {
      name: nombreGrupo,
      permissions: permissions,
    };

    fetch(urlg, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        onRefetch();
        onClose();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const groupedPermisos = permisos.reduce((acc, permiso) => {
    const { content_type, name } = permiso;
    if (!acc[content_type]) {
      acc[content_type] = { name: name, permissions: [] };
    }
    acc[content_type].permissions.push(permiso);
    return acc;
  }, {});

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            error={submitted && nombreGrupo === ""}
            required
            name="nombreGrupo"
            label="Nombre del grupo"
            type="text"
            value={nombreGrupo}
            onChange={(e) => setNombreGrupo(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Permiso</StyledTableCell>
                  <StyledTableCell>Ver</StyledTableCell>
                  <StyledTableCell>Agregar</StyledTableCell>
                  <StyledTableCell>Editar</StyledTableCell>
                  <StyledTableCell>Eliminar</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.values(groupedPermisos).map((group, index) => (
                  <StyledTableRow  key={group.name}>
                    <StyledTableCell  component="th" scope="row">{group.name}</StyledTableCell >
                    <StyledTableCell >
                      <Checkbox
                        key={`${group.permissions[0].id}-ver`}
                        name="ver"
                        value={group.permissions[0].codename}
                        checked={selectedPermisos.some(
                          (selectedPermiso) =>
                            selectedPermiso.codename === group.permissions[0].codename &&
                            selectedPermiso.ver
                        )}
                        onChange={(e) => handleCheckboxChange(e, index)}
                        style={{ transform: "scale(1.5)", color: "red" }}
                      />
                    </StyledTableCell >
                    <StyledTableCell >
                      <Checkbox
                        key={`${group.permissions[1].id}-agregar`}
                        name="agregar"
                        value={group.permissions[1].codename}
                        checked={selectedPermisos.some(
                          (selectedPermiso) =>
                            selectedPermiso.codename === group.permissions[1].codename &&
                            selectedPermiso.agregar
                        )}
                        onChange={(e) => handleCheckboxChange(e, index)}
                        style={{ transform: "scale(1.5)", color: "red" }}
                      />
                    </StyledTableCell >
                    <StyledTableCell >
                      <Checkbox
                        key={`${group.permissions[2].id}-editar`}
                        name="editar"
                        value={group.permissions[2].codename}
                        checked={selectedPermisos.some(
                          (selectedPermiso) =>
                            selectedPermiso.codename === group.permissions[2].codename &&
                            selectedPermiso.editar
                        )}
                        onChange={(e) => handleCheckboxChange(e, index)}
                        style={{ transform: "scale(1.5)", color: "red" }}
                      />
                    </StyledTableCell >
                    <StyledTableCell >
                      <Checkbox
                        key={`${group.permissions[3].id}-eliminar`}
                        name="eliminar"
                        value={group.permissions[3].codename}
                        checked={selectedPermisos.some(
                          (selectedPermiso) =>
                            selectedPermiso.codename === group.permissions[3].codename &&
                            selectedPermiso.eliminar
                        )}
                        onChange={(e) => handleCheckboxChange(e, index)}
                        style={{ transform: "scale(1.5)", color: "red" }}
                      />
                    </StyledTableCell >
                  </StyledTableRow >
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Button fullWidth variant="contained" color='error' className="btn btn-danger" onClick={handleCreateGroup}>
            Crear grupo de permisos
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
