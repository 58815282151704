
import { BASE_API } from "../../utils/constants";
import { makeRequest } from "../Request";
const BASE_URL = `${BASE_API}/`;

// Función para obtener los datos 
export async function getOrdenesDescargaderoApi(token,schema) {
  const url = `${BASE_URL}${schema}/descargadero/orden-descargadero`;
  return makeRequest(url, "GET", token);
}

// Función para agregar un nuevo dato
export async function addOrdenesDescargaderoApi(data, token,schema) {
  const url = `${BASE_URL}${schema}/descargadero/orden-descargadero/`;
  return makeRequest(url, "POST", token, data);
}

// Función para actualizar los datos 
export async function updateOrdenesDescargaderoApi(id, data, token,schema) {
  const url = `${BASE_URL}${schema}/descargadero/orden-descargadero/${id}/`;
  return makeRequest(url, "PUT", token, data);
}

// Función para eliminar 
export async function deleteOrdenesDescargaderoApi(id, token,schema) {
  const url = `${BASE_URL}${schema}/descargadero/orden-descargadero/${id}/`;
  return makeRequest(url, "DELETE", token);
}