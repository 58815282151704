import React, { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { HeaderPage } from "../../../Components/Client";
import { ModalBasic } from "../../../Components/Comons";
import { Funciones } from "./Funciones";
import { useAccesos, useControladores } from "../../../hooks";
// import { useConductor } from "../../../hooks";
import Swal from "sweetalert2";
import { Autocomplete } from '@mui/material';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

const options = [
    { value: '1', label: 'Activo solo turnos' },
    { value: '2', label: 'Activo solo accesos' },
    { value: '3', label: 'Activo turnos luego acceso' },
    { value: '4', label: 'Activo acceso luego turnos' },
];

export function ConfigTurnosAdmin() {
    const { getConfigTurnos, ConfigT, updateConfigTurnos, responseUpdt, loadingConfigT, handle_start_stop_access, loading_start_stop_access } = useAccesos()
    // const { loading, Conductor, getConductor } = useConductor();
    // const [activeTab, setActiveTab] = useState(0)
    const { Controladores, getControladores } = useControladores();
    const [configT, setConfigT] = useState([]);
    const [updatedConfig, setUpdatedConfig] = useState([]);
    const [error, setError] = useState(false); // controla un mensaje de error cuando no hay selección de modo en la operación de acceso

    const {
        refetch,
        contentModal,
        titleModal,
        showModal,
        openCloseModal,
    } = Funciones()

    const MemoizedModalBasic = React.memo(ModalBasic);

    useEffect(() => {
        getConfigTurnos()
        getControladores()
        // getConductor()
    }, [refetch])

    useEffect(() => {
        setConfigT(ConfigT); // se actualiza ConfigT cuando se cambia initialConfigT
        setUpdatedConfig(ConfigT); // se establece updatedConfig inicialmente con initialConfigT
    }, [ConfigT]);

    // maneja los cambios en las checkbox
    const handleCheckboxChange = (id) => (event) => {
        // estado del checkbox 1 para check 0 para uncheck
        let updatedConfigData = updatedConfig.map((row) =>
            row.id_conf === id ? { ...row, selecccion: event.target.checked ? '1' : '0' } : row
        );

        // deshabilita el check en el codigo qr cuando el método de acceso elegido es 2 (solo acceso) o 4 (acceso luego turno)
        if (event.target.checked && updatedConfigData.find((row) => row.id_conf === id)?.selecccion === '2'
            || updatedConfigData.find((row) => row.id_conf === id)?.selecccion === '4') {
            const codigoRow = updatedConfigData.find((row) => row.descripcion === 'codigo');
            if (codigoRow) {
                codigoRow.selecccion = '0';
            }

            updatedConfigData = [...updatedConfigData];
        }

        setUpdatedConfig(updatedConfigData);
        setConfigT(updatedConfigData); // sincroniza configT con updateConfig
    };

    // maneja los cambios en el autocomplete
    const handleSelectChange = (id) => (event, value) => {
        let updatedConfigData = updatedConfig.map((row) =>
            row.id_conf === id ? { ...row, selecccion: value?.value || '' } : row
        );

        if (event.target.value === '2' || event.target.value === '4') {
            const codigoRow = updatedConfigData.find((row) => row.descripcion === 'codigo');
            if (codigoRow) {
                codigoRow.selecccion = '0';
            }

            updatedConfigData = [...updatedConfigData];
        }

        setUpdatedConfig(updatedConfigData);
        setConfigT(updatedConfigData); // sincroniza configT con updateConfig

        // establece la variable de acuerdo a la validación de si hay un valor seleccionado
        setError(!value || !value.value);

        // Reset error when a selection is made
        if (value?.value) {
            setError(false);
        }
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    // const handleTabChange = (event, newValue) => {
    //     setActiveTab(newValue);
    // }

    const handleSubmit = () => {
        const selectedValues = configT.map(item => ({
            id_conf: item.id_conf,
            descripcion: item.descripcion,
            selecccion: item.selecccion
        }));

        try {
            const turnoAccesoSelecccion = selectedValues.find(v => v.descripcion === "turno-acceso")?.selecccion;
            if (turnoAccesoSelecccion === "2" || turnoAccesoSelecccion === "4") {
                // establece el valor del campo selecccion para el registro de codigo
                const updatedValues = selectedValues.map(item => {
                    if (item.descripcion === "codigo") {
                        return { ...item, selecccion: "0" };
                    }
                    return item;
                });

                console.log(updatedValues);

                updateConfigTurnos(updatedValues)

                Swal.fire({
                    icon: 'success',
                    title: 'Configuración guardada',
                    text: 'Datos guardados correctamente',
                });
            } else {
                console.log(selectedValues)

                updateConfigTurnos(selectedValues)

                Swal.fire({
                    icon: 'success',
                    title: 'Configuración guardada',
                    text: 'Datos guardados correctamente',
                });
            }

        } catch (Exception) {
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Ha sucedido un error',
            });
        }
    };

    return (
        <>
            < HeaderPage title="Configuración de métodos de acceso" />

            <br></br>

            {!ConfigT || !configT ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                    </div>
                </>
            ) : (
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">Descripción</StyledTableCell>
                                            <StyledTableCell align="center">Selección</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {configT?.map((row) => (
                                            <StyledTableRow key={row.id_conf}>
                                                <StyledTableCell component="th" align="center" scope="row">
                                                    {row.descripcion}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {row.id_conf !== 1 ? ( // revisa el id por cada registro de la tabla de configuración de accesos
                                                        row.descripcion === 'codigo' && updatedConfig.find((configRow) => configRow.id_conf === 1)?.selecccion === '2'
                                                            || row.descripcion === 'codigo' && updatedConfig.find((configRow) => configRow.id_conf === 1)?.selecccion === '4' ? (
                                                            <Checkbox disabled />
                                                        ) : (
                                                            <Checkbox
                                                                checked={row.selecccion === '1'}
                                                                onChange={handleCheckboxChange(row.id_conf)}
                                                            />
                                                        )
                                                    ) : (
                                                        <Autocomplete
                                                            fullWidth
                                                            options={options}
                                                            getOptionLabel={(option) => option.label}
                                                            value={options.find((option) => option.value === row.selecccion) || null}
                                                            onChange={handleSelectChange(row.id_conf)}
                                                            renderInput={(params) => (
                                                                <TextField {...params}
                                                                    label="Selección"
                                                                    variant="outlined"
                                                                    required
                                                                    error={error}
                                                                    helperText={error ? 'Es necesario una selección' : ''}
                                                                />
                                                            )}
                                                        />
                                                    )}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Button type="submit" variant="contained" color="error" className="btn btn-danger" onClick={handleSubmit} fullWidth>
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </>
            )}

            {showModal && (
                <MemoizedModalBasic
                    show={showModal}
                    onClose={openCloseModal}
                    title={titleModal}
                    children={contentModal}
                />
            )}
        </>
    )
}