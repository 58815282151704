// Modal Alerts for the storage module details

import { Input, Modal } from "antd";
import React, { useCallback } from "react";
import { ContainerInput } from "./styles";

export const ModalsAlerts = ({
  modalLevel,
  modalTemp,
  modalFlow,
  open,
  setOpen,
  alarm,
  setAlarm,
  formCompleted,
}) => {
  const modalAlamrsTitle = useCallback(() => {
    if (modalLevel) {
      return "ALARMAS NIVEL DE PRODUCTO";
    } else if (modalTemp) {
      return "ALARMAS TEMPERATURA DE PRODUCTO";
    } else if (modalFlow) {
      return "ALARMAS FLUJO DE PRODUCTO";
    }
  }, [modalFlow, modalLevel, modalTemp]);

  return (
    <Modal
      title={modalAlamrsTitle()}
      open={open}
      onOk={() => {
        setOpen(false);
        setAlarm({
          veryHigh: "",
          high: "",
          low: "",
          veryLow: "",
        });
      }}
      onCancel={() => {
        setOpen(false);
        setAlarm({
          veryHigh: "",
          high: "",
          low: "",
          veryLow: "",
        });
      }}
      centered
    >
      <form>
        {formCompleted && (
          <ContainerInput>
            <label htmlFor="veryHigh">Muy Alto</label>
            <Input
              value={alarm.veryHigh}
              type="text"
              width={200}
              name="veryHigh"
              onChange={(e) =>
                setAlarm(() => ({
                  ...alarm,
                  veryHigh: e.target.value,
                }))
              }
            />
          </ContainerInput>
        )}
        <ContainerInput>
          <label htmlFor="high">Alto</label>
          <Input
            value={alarm.high}
            type="text"
            width={200}
            name="high"
            onChange={(e) =>
              setAlarm(() => ({
                ...alarm,
                high: e.target.value,
              }))
            }
          />
        </ContainerInput>
        <ContainerInput>
          <label htmlFor="low">Bajo</label>
          <Input
            value={alarm.low}
            type="text"
            width={200}
            name="low"
            onChange={(e) =>
              setAlarm(() => ({
                ...alarm,
                low: e.target.value,
              }))
            }
          />
        </ContainerInput>
        {formCompleted && (
          <ContainerInput>
            <label htmlFor="veryLow">Muy Bajo</label>
            <Input
              value={alarm.veryLow}
              type="text"
              width={200}
              name="veryLow"
              onChange={(e) =>
                setAlarm(() => ({
                  ...alarm,
                  veryLow: e.target.value,
                }))
              }
            />
          </ContainerInput>
        )}
      </form>
    </Modal>
  );
};
