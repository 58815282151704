import React, { useEffect, useState } from "react";
import { Loader } from "semantic-ui-react";
import { AddEditTransacciones } from "../../../../Components/Client";
import { ModalBasic, DataGridBasic } from "../../../../Components/Comons";
import { useTransacciones } from "../../../../hooks";
import Swal from "sweetalert2"
import { columns,filterValue } from "./TransaccionesFormat";


export function TransaccionesAdmin() {
  const { loading, Transacciones, getTransacciones, deleteTransacciones } = useTransacciones();
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    getTransacciones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);
  const openCloseModal = () => setShowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);

  /// Ejecucion de modal para actualizar datos

  const updateTransacciones = (data) => {
    setTitleModal("Actualizar transacción");
    setContentModal(
      <AddEditTransacciones
        onClose={openCloseModal}
        onRefetch={onRefetch}
        Transacciones={data}
      />
    );
    openCloseModal();
  };

  /// borrados de datos

  const onDeleteTransacciones = async (data) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })
    
    swalWithBootstrapButtons.fire({
      title: 'Estas seguro?',
      text: "No podras revertir esta accion!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, eliminar!',
      cancelButtonText: 'No, cancelar!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        deleteTransacciones(data.id_transaccion);
        onRefetch();
        swalWithBootstrapButtons.fire(
          'Eliminado!',
          'El dato a sido eliminado.',
          'success'
        )
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelado',
          'Operacion cancelada',
          'error'
        )
      }
    })
  };
  

  return (
    <>
      {loading ? (
        <Loader active inline="centered">
          Cargando...
        </Loader>
      ) : (
        <DataGridBasic
        data={Transacciones.results}
        title="Transacciones"
        id="id_transaccion"
        filterValue={filterValue}
        columns={columns}
        updateData={updateTransacciones}
        onDeleteData={onDeleteTransacciones}
        />
      )}

       

      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        title={titleModal}
        children={contentModal}
      />
    </>
  );
}


