import React, { useCallback, useState } from "react";
import Swal from "sweetalert2";
import moment from "moment";

import {
  AddEditOrdenesDescargadero,
  ImpresionTD,
  VistaTransaccionesDescargadero,
} from "../../../../Components/Client";
import { useOrdenesDescargadero } from "../../../../hooks";

export function Funciones() {
  const { deleteOrdenesDescargadero } = useOrdenesDescargadero();
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);

  const handleViews = useCallback(
    (data) => {
      setTitleModal("Lista de transacciones");
      setContentModal(
        <VistaTransaccionesDescargadero
          onClose={openCloseModal}
          onRefetch={onRefetch}
          Transacciones={data}
        />
      );
      openCloseModal();
    },
    [openCloseModal, onRefetch]
  );

  const handleAdd = useCallback(() => {
    setTitleModal("Nueva Orden");

    setContentModal(
      <AddEditOrdenesDescargadero
        onClose={openCloseModal}
        onRefetch={onRefetch}
      />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const handleUpdate = useCallback(
    (data) => {
      setTitleModal("Actualizar Ordenes descargadero");
      setContentModal(
        <AddEditOrdenesDescargadero
          onClose={openCloseModal}
          onRefetch={onRefetch}
          OrdenesDescargadero={data}
        />
      );

      openCloseModal();
    },
    [openCloseModal, onRefetch]
  );

  const handleDelete = useCallback(
    async (data) => {
      const result = await Swal.fire({
        title: "Estas seguro?",
        text: "No podrás revertir esta acción!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, eliminar!",
        cancelButtonText: "No, cancelar!",
        reverseButtons: true,
      });

      if (result.isConfirmed) {
        await deleteOrdenesDescargadero(data.id_orden);
        Swal.fire("Eliminado!", "El dato ha sido eliminado.", "success");
        onRefetch();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelado", "Operación cancelada", "error");
      }
    },
    [deleteOrdenesDescargadero, onRefetch]
  );

  const ImpresionTiquet = (data) => {
    setTitleModal("Lista de transacciones");
    setContentModal(
      <ImpresionTD onClose={openCloseModal} onRefetch={onRefetch} data={data} />
    );
    openCloseModal();
  };

  const columns = [
    {
      name: "time_created",
      header: "Fecha creación",
      group: "general",
      defaultFlex: 1,
      minWidth: 150,
      render: ({ value, cellProps }) => {
        return moment(value).format("MM-DD-YYYY");
      },
    },
    {
      header: "Numero de orden",
      name: "n_orden",
      group: "general",
      maxWidth: 1000,
      defaultFlex: 1,
    },

    {
      header: "Autorizado",
      name: "v_auth",
      group: "general",
      maxWidth: 1000,
      defaultFlex: 1,
      render: (rowData) => (
        <div
          style={{
            background: rowData.data.v_auth == 1 ? "#008000aa" : "#f90000aa",
            borderRadius: "5px",
            paddingLeft: 10,
          }}
        >
          {rowData.data.v_auth == 1 ? "Autorizado" : "NO Autorizado"}
        </div>
      ),
    },
    {
      header: "Cliente",
      name: "cliente_nombre",
      group: "general",
      maxWidth: 1000,
      defaultFlex: 1,
    },
    {
      header: "Tanque",
      name: "tanque_nombre",
      group: 'producto',
      maxWidth: 1000,
      defaultFlex: 1,
    },

    {
      header: "Producto",
      name: "producto_nombre",
      group: "producto",
      maxWidth: 1000,
      defaultFlex: 1,
    },
    {
      header: "BSW",
      name: "bsw",
      group: "producto",
      maxWidth: 90,
      defaultFlex: 1,
    },
    {
      header: "API",
      name: "api",
      group: "producto",
      maxWidth: 90,
      defaultFlex: 1,
    },
    {
      header: "Entregado",
      name: "v_entregado",
      group: "producto",
      maxWidth: 90,
      defaultFlex: 1,
      render: ({ value }) => value / 100, // Dividir el valor por 100 antes de mostrarlo
    },
    {
      header: "Restante",
      name: "v_restante",
      group: "producto",
      maxWidth: 90,
      defaultFlex: 1,
      render: ({ value }) => value / 100, // Dividir el valor por 100 antes de mostrarlo
    },

    {
      header: "Autorizado",
      name: "tiempo_auth",

      maxWidth: 1000,
      defaultFlex: 1,
    },

    {
      header: "Tiquet",
      render: (rowData) => (
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <button
            style={{
              width: "30px",
              height: "30px",
              borderRadius: 0,
              backgroundColor: "transparent",
              border: "none",
            }}
            className="btn btn-primary"
            onClick={() => ImpresionTiquet(rowData.data.id_orden, "print")}
          >
            <i
              style={{ fontSize: "20px", color: "black" }}
              className="fa fa-print"
            />
          </button>
        </div>
      ),
      maxWidth: 1000,
      defaultFlex: 1,
    },
  ];

  const extraColumns = [
    // {
    //   name: "time_created",
    //   header: "Fecha creación",
    //   group: "general",
    //   defaultFlex: 1,
    //   minWidth: 150,
    //   render: ({ value, cellProps }) => {
    //     return moment(value).format("MM-DD-YYYY");
    //   },
    // },
    {
      header: "Numero de orden",
      name: "n_orden",
      group: "general",
      maxWidth: 1000,
      defaultFlex: 1,
    },
    {
      header: "Estado",
      group: "general",
      name: "estado_orden",
      maxWidth: 1000,
      defaultFlex: 1,
    },
    {
      header: "Vehículo",
      name: "vehiculo_nombre",
      group: "transporte",
      maxWidth: 1000,
      defaultFlex: 1,
    },
    {
      header: "Conductor",
      name: "conductor_nombre",
      group: "transporte",
      maxWidth: 1000,
      defaultFlex: 1,
    },
    {
      header: "Remolque",
      name: "remolque_nombre",
      group: "transporte",
      maxWidth: 1000,
      defaultFlex: 1,
    },
    {
      header: "Transportadora",
      name: "transportadora_nombre",
      group: "transporte",
      maxWidth: 1000,
      defaultFlex: 1,
    },

    {
      header: "Sellos",
      name: "sellos",
      maxWidth: 1000, defaultFlex: 1
    },
    {
      header: "N. Factura",
      name: "n_factura",
      maxWidth: 1000, defaultFlex: 1
    },
    {
      header: "Observaciónes",
      name: "observacion_orden",
      maxWidth: 1000,
      defaultFlex: 1,
    },
  ];

  return {
    handleAdd,
    handleDelete,
    handleUpdate,
    openCloseModal,
    handleViews,
    extraColumns,
    columns,
    refetch,
    contentModal,
    titleModal,
    showModal,
  };
}
