import { useState } from "react";
import { getTransportadoraApi, addTransportadoraApi, updateTransportadoraApi, deleteTransportadoraApi } from "../../api/A_Transportadora/transportadora";
import { useNavigate } from "react-router-dom";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "..";

export function useTransportadora() {
  const { auth, logout } = useAuth();
  const navigate = useNavigate();
  const { unauthorized, SessionExpired, errores, unauthorizedModal } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    Transportadora: null,
    TransportadoraDB: null,
  });

  const { loading, error, Transportadora, TransportadoraDB } = state;

  const handleResponse = (response) => {
    if (response.status === 401) {
      SessionExpired();
      logout();
    } else if (response.status === 403) {
      unauthorized();
      navigate("/");
    }
  };

  const getTransportadoraDB = async (filter,transportadora) => {
    try {
      const [result, response] = await getTransportadoraApi(auth.token, auth.me.tenant,filter,transportadora);
      setState((prevState) => ({ ...prevState, TransportadoraDB: result.results }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  const getTransportadora = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getTransportadoraApi(auth.token, auth.me.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, Transportadora: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addTransportadora = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addTransportadoraApi(data, auth.token, auth.me.tenant);
      if (response.status === 400) {
        if (result?.nit_transportadora) {
          throw new Error("El NIT de la tranportadora se ha registrado con anterioridad");
        } else {
          errores();
        }
      } else if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateTransportadora = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateTransportadoraApi(id, data, auth.token, auth.me.tenant);
      if (response.status === 400) {
        if (result?.nit_transportadora) {
          throw new Error("El NIT de la tranportadora se ha registrado con anterioridad");
        } else {
          errores();
        }
      } else if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteTransportadora = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteTransportadoraApi(id, auth.token, auth.me.tenant);
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    TransportadoraDB,
    Transportadora,
    getTransportadoraDB,
    getTransportadora,
    addTransportadora,
    updateTransportadora,
    deleteTransportadora,
  };
}
