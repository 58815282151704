import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

export const columns = [
  {
    header: "Nit cliente",
    name: "nit_cliente",
    maxWidth: 150,
    defaultFlex: 1,
  },
  {
    header: "Razón social",
    name: "razon_social",
    maxWidth: 200,
    defaultFlex: 1,
  },
  {
    header: "Empresa",
    name: "id_empresa",
    maxWidth: 1000,
    defaultFlex: 1,
    render: ({ data }) => (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ backgroundColor: '#FDFAFA', padding: '8px', width: '100px', borderRadius:"6px" }}>EMAIL</div>
        <div style={{  padding: '8px', width: '250px' }}>{data.id_empresa?.email}</div>
        <div style={{ backgroundColor: '#FDFAFA', padding: '8px', width: '100px', borderRadius:"6px" }}>CELULAR</div>
        <div style={{  padding: '8px', width: '150px' }}>{data.id_empresa?.celular}</div>
        <div style={{ backgroundColor: '#FDFAFA', padding: '8px', width: '100px', borderRadius:"6px" }}>TELEFONO</div>
        <div style={{  padding: '8px', width: '150px' }}>{data.id_empresa?.telefono}</div>
      </div>
    ),
  }
  ,  
  {
    header: "Clientes",
    name: "cliente",
    maxWidth: 100,
    defaultFlex: 1,
    render: ({ data }) => (
      <div
        style={{
          display: 'flex',
          alignItems: 'center', // Centra verticalmente
          justifyContent: 'center', // Centra horizontalmente
        }}
      >
      {data.cliente ? <CheckIcon style={{ color: 'green' }} /> : <ClearIcon style={{ color: 'red' }} />}
      </div>
    ),
  },
  {
    header: "Proveedores",
    name: "proveedores",
    maxWidth: 120,
    defaultFlex: 1,
    render: ({ data }) => (
      <div
        style={{
          display: 'flex',
          alignItems: 'center', // Centra verticalmente
          justifyContent: 'center', // Centra horizontalmente
        }}
      >
      {data.proveedores ? <CheckIcon style={{ color: 'green' }} /> : <ClearIcon style={{ color: 'red' }} />}
      </div>
    ),
  },
];



export const filterValue = [
    { name: 'nit_cliente', operator: 'startsWith', type: 'string', value: '' },
    // { name: 'age', operator: 'gte', type: 'number', value: 21 },
    { name: 'razon_social', operator: 'startsWith', type: 'string', value: '' },
    
  ];