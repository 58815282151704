// PARA DESARROLLO LOCAL
// export const API = "http://127.0.0.1:8000/r"
// export const ASGI_API = "ws://127.0.0.1:8000/ws"

// PARA DESPLIEGUE DEMOSTRACIONES
// export const API = "http://192.168.50.220:9006/r";
// export const ASGI_API="ws://192.168.50.220:9006/ws"

// PARA DESPLIEGUE WINDOWS (SIN USO)
// export const API = "http://3.144.38.233/r";
// export const ASGI_API="ws://3.144.38.233/ws"

// PARA DESPLIEGUE PRODUCCIÓN (AWS)
export const API = "https://syzapiback.syzapi.com/r";
export const ASGI_API="wss://syzapiback.syzapi.com/ws"

//export const API = "http://13.59.133.211:82/r"
//export const ASGI_API = "http://13.59.133.211:82/ws"

// PARA DESPLIEGUE DE DESARROLLO
// export const API = "http://192.168.50.220:9008/r"
// export const ASGI_API = "ws://192.168.50.220:9008/ws"

// export const API = "http://18.223.156.86/r"
// export const ASGI_API = "ws://18.223.156.86/ws"

// const present state
export const presentState = (state) => {
  switch (state) {
    case 0:
      return "Listo";
    case 1:
      return "Flujo bajo";
    case 2:
      return "Flujo alto";
    case 3:
      return "Etapa 1 (1st trip)";
    case 4:
      return "Etapa 2 (2st trip)";
    case 5:
      return "Etapa final (Final trip)";
    case 6:
      return "Iniciando cargue";
    case 7:
      return "Alarmado";
    case 8:
      return "Completado";
    case 9:
      return "Conectado";
    case 10:
      return "Espera TMS";
    case 11:
      return "Autorizado";
    case 12:
      return "Preset";
    case 13:
      return "Deshabilitado";
    case 14:
      return "Detenido";
    case 15:
      return "Remote";
    case 16:
      return "Fin de batch";
    case 17:
      return "Archivado";
    case 18:
      return "Limpiando";
    case 19:
      return "Transacción realizado";
    case 20:
      return "FFFFFFFFFF";
    case 99:
      return "!!!!!!!!!!!!";
    default:
      return "Error";
  }
};

const date = new Date();
// const date now for table alerts
export const dateNow = `${
  date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
}.${
  date.getMonth() < 10 ? `0${date.getMonth()}` : date.getMonth()
}.${date.getFullYear()} ${
  date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}:${
  date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()
}`;

// function read alarm
export const readAlarm = (alarm, text) => {
  if (alarm === 1) {
    return text;
  } else {
    return;
  }
};

// const alarms preset
export const readAlarmsPreset = {
  FCMP: "Falla comunicación Preset o FCM",
  Permisivo_Preset_no_activo: "Permisivo Preset no activo",
  Brazo_Oscilante_NO_posicionado: "Brazo oscilante NO está en posición",
  Volumen_minimo_descarga_linea_NO_alcanzado:
    "No se alcanza el volumen mínimo de descarga de la línea",
  Valvula_Bloqueo_posicion_incorrecta: "Válvula de bloqueo posición incorrecta",
  Alarma_a_nivel_de_bahia: "Alarma a nivel de bahía",
  Alarma_a_nivel_de_componente: "Alarma a nivel de componente",
  Alarma_a_nivel_de_aditivo: "Alarma a nivel de aditivo",
  Boton_Parada_Medidor: "Botón de parada del medidor",
  Cantidad_entregada_excedio:
    "La cantidad entregada excedió la cantidad preestablecida",
  Flujo_brazo: "Flujo de brazo excedido",
  Error_BSW: "Error de BSW, fuera del rango",
  Alarma_nivel: "Alarma a nivel de tanque",
  BSW_actual_debajo: "BSW actual no está por debajo del valor de Desvío de BSW",
  Alarma_mostrador: "Alarma a nivel de mostrador activa",
  Error_configuracion_Preset: "Error configuracion",
};

// const alarms component
export const readAlarmsComponent = {
  Falla_comunicacion_medidor: "Falla comunicación Componente o FCM",
  Permisivo_no_activo: "Permisivo del componente no activo",
  Bloqueo_valvula_posicion_incorrecta:
    "Bloqueo de válvula en posición incorrecta",
  Flujo_NO_Autorizado: "Flujo NO Autorizado",
  Estado_Bomba_incorrecto: "Estado de Bomba incorrecto",
  Alarma_Componente_nivel_medidor: "Alarma de Componente a nivel de medidor",
  Exceso_mezcla: "Exceso de mezcla",
  Mezcla_Insuficiente: "Mezcla Insuficiente",
  Tabla_API_VCF_fuera_rango: "Tabla API VCF fuera de rango",
  Caudal_debajo_minimo_recuperacion:
    "Caudal por debajo del mínimo de recuperación",
  No_usado: "No usado",
};

// const alarms meter
export const readAlarmsMeter = {
  Falla_comunicacion_medidor: "Falla comunicación Medidor o FCM",
  Permisivo_medidor_no_activo: "Permisivo del medidor no activo",
  FCM_error_configuracion: "FCM error de configuración",
  FCM_falla_comunicacion: "FCM detuvo el flujo por falta de comunicación RCU",
  Tiempo_espera_superado: "Tiempo de espera del FCM superado",
  Falla_valvula: "Falla de válvula",
  Flujo_bajo: "Flujo bajo",
  Exceso_flujo: "Exceso de flujo",
  Pulsos_fuera_rango: "Pulsos fuera de rango",
  Densidad_fuera_rango: "Densidad fuera de rango",
  Temperatura_fuera_rango: "Temperatura fuera de rango",
  Error_control_valvula: "Error de control de válvula",
  Deteccion_flujo_valvula_cerrada: "Detección de flujo con válvula cerrada",
  Presion_fuera_rango: "Presión fuera de rango",
  Falla_pulsos_canal_A: "Falla en los pulsos del canal A",
  Falla_pulsos_canal_B: "Falla en los pulsos del canal B",
};

// const alarms bay
export const readAlarmsBay = {
  FCMB: "Falla comunicación Bahia o FCM",
  Permisivo_Tierra_no_activo: "Permisivo Puesta a Tierra no activo",
  Permisivo_Sobrellenado_no_activo: "Permisivo Sobrellenado no activo",
  Permisivo_Parada_Emergencia_no_activo:
    "Permisivo Parada de Emergencia no activo",
  Permisivo_3_no_activo: "Permisivo 3 no activo",
  Permisivo_4_no_activo: "Permisivo 4 no activo",
  Permisivo_5_no_activo: "Permisivo 5 no activo",
  Permisivo_6_no_activo: "Permisivo 6 no activo",
  Permisivo_7_no_activo: "Permisivo 7 no activo",
  boton_parada: "Boton de parada total activo",
  Falla_Impresoras: "Falla Impresoras",
  no_usado: "No usado",
  Deadman_activo: "Deadman activo",
  PCMB: "Falta información de PCMB",
};
