import ReactDataGrid from "@inovua/reactdatagrid-community";
import filter from "@inovua/reactdatagrid-community/filter";
import "@inovua/reactdatagrid-community/index.css";
import { Box, IconButton, makeStyles } from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import ChecklistIcon from '@mui/icons-material/Checklist';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DownloadIcon from "@mui/icons-material/Download";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import GridViewIcon from '@mui/icons-material/GridView';
import HistoryIcon from '@mui/icons-material/History';
import HttpsIcon from '@mui/icons-material/Https';
import React, { useCallback, useState } from "react";
import * as XLSX from "xlsx";
import { useVerificaciones } from "../../../hooks";
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import AddIcon from '@mui/icons-material/Add';
import "./stylesTabla.css";
import PreviewIcon from '@mui/icons-material/Preview';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import GradingIcon from '@mui/icons-material/Grading';

const gridStyle = { minHeight: 720, marginTop: 10 };

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: "20px",
    backgroundColor: "#fff",
    borderRadius: 10,
    boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.2)",
    padding: theme.spacing(2),
  },
}));

export function DataGridBasicVeh(props) {

  const [filterData, setFilterData] = useState([]);
  const { addBaseVer, addBaseVehVer } = useVerificaciones()

  const {
    data,
    columns,
    updateData,
    onDeleteData,
    visualizacionData,
    cambioTabla,
    id,
    filterValue,
    cargaMasiva,
    cargaFotos,
    gruposPermisos,
    vehChecklist,
    viewVehChecklist,
    viewVehChecklistHistory,
    copyData,
    copyDataVeh,
    tcsProdUpdt,
    groups,
    assignTo,
    showTicket,
    handleAddEditBiometricData,
    handleDataVisualizer,
  } = props;

  const classes = useStyles();

  const onFilterValueChange = useCallback((filterValue) => {
    const filteredData = filter(data, filterValue);
    setFilterData(filteredData);
    console.log(filteredData);
  }, []);

  const exportToExcel = () => {
    if (filterData && filterData.length > 0) {
      const worksheet = XLSX.utils.json_to_sheet(filterData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "data.xlsx");
      console.log('llegó aquí');
    } else {
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "data.xlsx");
      console.log('llego allá');
    }
  };

  const newColumns = [
    ...columns,

    {
      header: "Opciones",
      id: "actions",

      render: ({ data }) => (
        <>
          {updateData && (
            <Tooltip title="Actualizar datos" arrow>
              <IconButton style={{ padding: '2px' }} onClick={() => updateData(data)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {onDeleteData && (
            <Tooltip title="Eliminar datos" arrow>
              <IconButton style={{ padding: '2px' }} onClick={() => onDeleteData(data)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
          {vehChecklist && (
            <Tooltip title="Realizar inspección" arrow>
              <IconButton style={{ padding: '2px' }} onClick={() => {vehChecklist(data)}}>
                <DoneAllIcon />
              </IconButton>
            </Tooltip>
          )}
          {viewVehChecklist && (
            <Tooltip title="Ver inspección actual" arrow>
              <IconButton style={{ padding: '2px' }} onClick={() => viewVehChecklist(data)}>
                <ChecklistIcon />
              </IconButton>
            </Tooltip>
          )}
          {viewVehChecklistHistory && (
            <Tooltip title="Ver historial de inspecciones" arrow>
              <IconButton style={{ padding: '2px' }} onClick={() => viewVehChecklistHistory(data)}>
                <HistoryIcon />
              </IconButton>
            </Tooltip>
          )}
        </>
      ),
      width: 145, // anchura fija para la columna
    },
  ];


  return (
    <Box className={classes.tableContainer}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        {cambioTabla && (
          <Tooltip title="Recargar o reorganizar columnas" arrow>
            <IconButton onClick={cambioTabla} style={{ marginRight: '8px', fontSize: '1.2rem' }}>
              <GridViewIcon /> {/* Icono de recargar (cambia ReloadIcon por el nombre del icono que desees utilizar) */}
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Descargar excel" arrow>
          <IconButton onClick={exportToExcel} style={{ marginRight: '8px', fontSize: '1.2rem' }}>
            <DownloadIcon />
          </IconButton>
        </Tooltip>
        {cargaMasiva && (
          <Tooltip title="Cargue masivo" arrow>
            <IconButton onClick={cargaMasiva} style={{ marginRight: '8px', fontSize: '1.2rem' }}>
              <DriveFolderUploadIcon />
            </IconButton>
          </Tooltip>
        )}
        {cargaFotos && (
          <IconButton onClick={cargaFotos} style={{ fontSize: '1.2rem' }}>
            <CameraswitchIcon />
          </IconButton>
        )}
      </div>

      <ReactDataGrid
        idProperty={id}
        columns={newColumns}
        dataSource={data}
        style={gridStyle}
        groups={groups}
        className="custom-data-grid"
        pagination
        paginationPageSize={10}
        paginationToolbarLabels={{
          prev: "Anterior",
          next: "Siguiente",
          page: "Página",
          of: "de",
          rows: "filas",
        }}
        defaultFilterValue={filterValue}
        onFilterValueChange={onFilterValueChange}
        onDataSourceChange={({ skip, limit }) => {
          console.log(`Pagina actual: ${skip / limit + 1}`);
        }}
      />
    </Box>
  );
}
