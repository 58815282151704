import { useState } from "react";
import { getMapeosApi, postMapeosApi, updtMapeosApi, deleteMapeosApi } from "../../api/A_Mapeos/mapeos";
import { useNavigate } from "react-router-dom";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "../";

export function useMapeos() {
    const { auth, logout } = useAuth();
    const navigate = useNavigate();
    const { unauthorized, SessionExpired, errores, unauthorizedModal } = alertas();

    const [state, setState] = useState({
        loading: true,
        error: null,
        Mapeos: null,
    });

    const { loading, error, Mapeos } = state

    const handleResponse = (response) => {
        if (response.status === 401) {
            SessionExpired();
            logout();
        } else if (response.status === 403) {
            unauthorized();
            navigate("/");
        }
    }

    const getMapeos = async () => {

        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await getMapeosApi(auth.token, auth.me.tenant);
            
            handleResponse(response);
            setState((prevState) => ({ ...prevState, loading: false, Mapeos: result }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
        }
    }

    const addMapeos = async (data) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await postMapeosApi(data, auth.token, auth.me.tenant);
            if (response.status === 400) {
                if (result?.placa) {
                    throw new Error("La placa de la máquina ya cuenta con un registro activo");
                } else {
                    errores();
                }
            } else if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
            throw error;
        }
    }

    const updtMapeos = async (id_maquina, data) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await updtMapeosApi(id_maquina, data, auth.token, auth.me.tenant);
            if (response.status === 400) {
                if (result?.placa) {
                    throw new Error("La placa de la maquina ya cuenta con un registro activo");
                } else {
                    errores();
                }
            } else if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
            throw error;
        }
    }

    const deleteMapeos = async (id_maquina) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await deleteMapeosApi(id_maquina, auth.token, auth.me.tenant);
            if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
        }
    }

    return {
        loading,
        error,
        deleteMapeos,
        getMapeos,
        addMapeos,
        updtMapeos,
        Mapeos
    }
}