import { Avatar, Divider, Image } from "antd";
import {
  Circle,
  ContainerContent,
  ContainerFlex,
  ContainerPopover,
  SpanNormal,
  TextBold,
  TextCrudo,
} from "./styles";
import ImageAlarm from "../../../assets/alarm-icon.svg";

export const PopoverContent = ({ data }) => {
  // Verificar que data exista y tenga las propiedades necesarias antes de utilizarlas
  if (!data || typeof data !== 'object') {
    // Si data no existe o no es un objeto válido, puedes mostrar un mensaje o componente de carga
    return <div>Cargando...</div>;
  }

  // Validar propiedades requeridas
  const conductorNombre = data.conductor_nombre ?? "Nombre del Conductor Desconocido";
  const productoNombre = data.producto_nombre ?? "Nombre del Producto Desconocido";
  const volumen = data.v_volumen ?? "Volumen Desconocido";
  // Continúa validando otras propiedades según lo necesario...

  const hour = new Date(data?.tiempo_auth)?.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  }) ?? "Hora Desconocida";

  // Renderizar el contenido una vez que las propiedades requeridas estén validadas
  return (
    <ContainerPopover>
      <ContainerContent>
        <Avatar src={data.conductor_foto} />
        <div>
          <TextBold>{conductorNombre}</TextBold>
          <ContainerFlex>
            <TextCrudo>{productoNombre}</TextCrudo>
            <TextBold>
              Volumen: <span>{volumen}</span>
            </TextBold>
          </ContainerFlex>
        </div>
        <TextBold style={{ marginTop: "18px" }}>
          API: <span>{data.api}</span>
        </TextBold>
        <div>
          <TextBold>{data.n_orden}</TextBold>
          <TextBold>
            BSW: <span>{data.bsw}</span>
          </TextBold>
        </div>
      </ContainerContent>
      <Divider style={{ margin: "8px 0" }} />
      <ContainerContent>
        <ContainerFlex>
          <ContainerFlex style={{ marginRight: "8px" }}>
            <Image src={ImageAlarm} />
            <TextBold>{hour}</TextBold>
          </ContainerFlex>
          <ContainerFlex>
            <Circle></Circle>
            <TextBold>{data.estado_orden}</TextBold>
          </ContainerFlex>
        </ContainerFlex>
        <TextBold>
          Tanque: <span>{data.tanque}</span>
        </TextBold>
      </ContainerContent>
      <ContainerFlex style={{ marginTop: "30px", alignItems: "flex-start" }}>
        <div style={{ marginRight: "10px", width: "120px" }}>
          <TextBold>
            Cliente: <SpanNormal>{data.cliente_nombre}</SpanNormal>
          </TextBold>
          <TextBold>
            Transportadora:{" "}
            <SpanNormal>{data.transportadora_nombre}</SpanNormal>
          </TextBold>
          <TextBold>
            Planta: <SpanNormal>{data.planta_nombre}</SpanNormal>
          </TextBold>
        </div>
        <div style={{ width: "120px" }}>
          <TextBold>
            Vehiculo: <SpanNormal>{data.vehiculo_nombre}</SpanNormal>
          </TextBold>
          <TextBold>
            Remolque: <SpanNormal>{data.remolque_nombre}</SpanNormal>
          </TextBold>
        </div>
      </ContainerFlex>
    </ContainerPopover>
  );
};
