import { BASE_API } from "../../utils/constants";
import { makeRequest } from "../Request";
const BASE_URL = `${BASE_API}/`;

// Función para obtener los datos 
export async function getComputadorDecimalesApi(token,schema) {
  const url = `${BASE_URL}${schema}/Configuraciones/decimalcompt`;
  return makeRequest(url, "GET", token);
}
// Función para actualizar los datos 
export async function updateComputadorDecimalesApi(id, data, token,schema) {
  const url = `${BASE_URL}${schema}/Configuraciones/decimalcompt/${id}/`;
  return makeRequest(url, "PUT", token, data);
}