import { useFormik } from "formik";
import { map } from "lodash";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
//import { Button, Form, Input, Select, Label, Segment, Table } from "semantic-ui-react";
import { Autocomplete } from "@mui/material"
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';

import * as Yup from "yup";
import { useIslas } from "../../../hooks";
import { alertas } from "../../Comons/Alertas/alertas";

const opType = [
  { key: "Cargadero", value: "Cargadero", text: "Cargadero" },
  { key: "Descarga", value: "Descarga", text: "Descarga" },
]

// Función que define un formulario para crear o editar una isla
export function AddEditIslas(props) {
  // Importación de funciones y variables necesarias de las props
  const { Successful } = alertas();
  const { onClose, onRefetch, Islas } = props;
  const { addIslas, updateIslas } = useIslas();

  const [error, setError] = useState(false)

  // Estado local para almacenar las bahías asociadas a la isla
  const [bahias_id, setBahias] = useState(Islas?.bahias_id || []);

  // Creación del objeto formik con la configuración necesaria para el formulario
  const formik = useFormik({
    initialValues: {
      nombre: Islas?.nombre || "",
      N_bahias: Islas?.N_bahias || "",
      tipo_operacion: Islas?.tipo_operacion || "",
      tipo_operacion: Islas?.tipo_operacion || "",
    },
    validationSchema: Yup.object({
      nombre: Yup.string().required("Este campo es obligatorio"),
      N_bahias: Yup.number().required("Este campo es obligatorio"),
      tipo_operacion: Yup.string().required("Este campo es obligatorio"),
      tipo_operacion: Yup.string().required("Este campo es obligatorio"),
    }),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        // Si la isla ya existe, se actualiza; si no, se crea una nueva
        if (Islas) {
          await updateIslas(Islas.id_isla, { ...formValue, bahias_id });
        } else {
          await addIslas({ ...formValue, bahias_id });
        }
        // Se muestra una notificación de éxito y se actualiza la lista de islas
        Successful();
        onRefetch();
        onClose();
      } catch (error) {
        // Si se produce un error, se muestra una notificación de error o se cierra el formulario
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });

  /*
      handleAddBahia es una función que se encarga de agregar una bahía vacía al arreglo de bahías.
      setBahias es una función que se utiliza para actualizar el estado de bahias_id con el nuevo arreglo de bahías que se creará a partir de la operación siguiente.
      [...bahias_id, { nombre: "" }] crea un nuevo arreglo con todos los elementos del arreglo bahias_id y agrega un nuevo objeto al final con la propiedad nombre vacía.
          ...bahias_id es el operador de propagación que se utiliza para copiar todos los elementos del arreglo bahias_id en el nuevo arreglo.
          { nombre: "" } es un objeto que se agrega al final del nuevo arreglo con una propiedad nombre vacía.
  
  */
  const handleAddBahia = () => {
    setBahias([...bahias_id, { nombre: "" }]);
  };
  /*
      handleDeleteBahia es una función que se encarga de eliminar una bahía del arreglo de bahías.
      index es el índice de la bahía que se quiere eliminar.
      setBahias es una función que se utiliza para actualizar el estado de bahias_id con el nuevo arreglo de bahías que se creará a partir de la operación siguiente.
      [...bahias_id.slice(0, index), ...bahias_id.slice(index + 1)] crea un nuevo arreglo con todos los elementos del arreglo bahias_id excepto el elemento en la posición index.
          bahias_id.slice(0, index) es un nuevo arreglo que contiene todos los elementos de bahias_id hasta la posición index (no inclusiva).
          bahias_id.slice(index + 1) es un nuevo arreglo que contiene todos los elementos de bahias_id después de la posición index (inclusiva).
  */
  const handleDeleteBahia = (index) => {
    setBahias([...bahias_id.slice(0, index), ...bahias_id.slice(index + 1)]);
  };
  /*
      handleBahiaChange es una función que se encarga de actualizar la propiedad nombre de una bahía en el arreglo de bahías.
      index es el índice de la bahía que se quiere actualizar.
      value es el valor nuevo que se quiere asignar a la propiedad nombre de la bahía.
      setBahias es una función que se utiliza para actualizar el estado de bahias_id con el nuevo arreglo de bahías que se creará a partir de la operación siguiente.
      [...bahias_id.slice(0, index), { nombre: value }, ...bahias_id.slice(index + 1)] crea un nuevo arreglo con todos los elementos del arreglo bahias_id y reemplaza la bahía en la posición index con una nueva bahía que tiene la propiedad nombre actualizada.
          ...bahias_id.slice(0, index) es el operador de propagación que se utiliza para copiar todos los elementos de bahias_id hasta
  */
  const handleBahiaChange = (index, value) => {
    setBahias([
      ...bahias_id.slice(0, index),
      { nombre: value },
      ...bahias_id.slice(index + 1),
    ]);
  };

  return (
    <>
      <form className="add-edit-persona-form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label="Nombre"
              name="nombre"
              placeholder="Nombre"
              value={formik.values.nombre}
              onChange={formik.handleChange}
              error={formik.errors.nombre}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label="Numero de bahias"
              name="N_bahias"
              placeholder="Numero de bahias"
              value={formik.values.N_bahias}
              onChange={formik.handleChange}
              error={formik.errors.N_bahias}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Autocomplete
              fullWidth
              id="tipo_operacion"
              options={opType}
              getOptionLabel={(option) => `${option.text} `}
              value={
                opType?.find(
                  (opt) => opt.key === formik.values?.tipo_operacion
                ) || null
              }
              onChange={(_, value) => {
                formik.setFieldValue("tipo_operacion", value?.key || "");
                setError(!value || !value.value)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tipo de operación"
                  variant="outlined"
                  required
                  error={error}
                  helperText={error ? 'Es necesario una selección' : ''}
                  sx={{ background: "#fcfcfc" }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h5">
                Bahías
              </Typography>
            </Grid>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="h6">
                        Nombre
                      </Typography>
                    </TableCell>
                    <TableCell style={{ width: "50px" }}>
                      <Tooltip title="Agregar" arrow>
                        <Button variant="outlined" color="secondary" fullWidth onClick={handleAddBahia}
                          style={{
                            color: 'rgb(25, 118, 210)',
                            border: '1px solid rgb(25, 118, 210)',
                            '&:hover': {
                              color: 'rgba(221, 223, 221, 0.62)',
                              border: '1px solid rgba(221, 223, 221, 0.62)',
                            }
                          }}
                        >
                          <AddIcon />
                        </Button>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bahias_id.map((bahia, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <TextField
                          fullWidth
                          required
                          placeholder="Nombre de la bahía"
                          value={bahia.nombre}
                          onChange={(e) => handleBahiaChange(index, e.target.value)}
                        />
                      </TableCell>
                      <TableCell style={{ width: "50px" }}>
                        <Tooltip title="Eliminar" arrow>
                          <Button
                            variant="outlined"
                            color="secondary"
                            style={{
                              height: '100%', display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              padding: '0',
                              margin: '0',
                              color: 'rgba(252, 0, 0, 0.8)',
                              border: '1px solid rgba(252, 0, 0, 0.8)',
                            }}
                            onClick={() => handleDeleteBahia(index)}
                          >
                            <DeleteIcon style={{ margin: '0' }} />
                          </Button>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Button type="submit" variant="contained" color="error" className="btn btn-danger" fullWidth>
              {Islas ? "Actualizar" : "Crear"}
            </Button>
          </Grid>
        </Grid>
      </form>

      {/*       
      <Form className="add-edit-persona-form" onSubmit={formik.handleSubmit}>
        <Form.Group widths="equal">
          <Form.Field
            required
            fluid
            control={Input}
            label="Nombre"
            name="nombre"
            placeholder="Nombre"
            value={formik.values.nombre}
            onChange={formik.handleChange}
            error={formik.errors.nombre}
          />

          <Form.Field
            required
            fluid
            control={Input}
            label="Numero de bahias"
            name="N_bahias"
            placeholder="Numero de bahias"
            value={formik.values.N_bahias}
            onChange={formik.handleChange}
            error={formik.errors.N_bahias}
          />

        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            required
            fluid
            control={Input}
            label="Tipo de operacion"
            name="tipo_operacion"
            placeholder="Tipo de operacion"
            value={formik.values.tipo_operacion}
            onChange={formik.handleChange}
            error={formik.errors.tipo_operacion}
          />


        </Form.Group>


        <label>Bahías</label>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Nombre</Table.HeaderCell>
              <Table.HeaderCell style={{ width: "50px" }}>
                <Button
                  icon="plus"
                  circular
                  size="small"
                  onClick={handleAddBahia}
                  type="button" // Agregamos type="button" para evitar que se envíe el formulario
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {bahias_id.map((bahia, index) => (
              <Table.Row key={index}>
                <Table.Cell>
                  <Input
                    fluid
                    required
                    placeholder="Nombre de la bahía"
                    value={bahia.nombre}
                    onChange={(e, { value }) =>
                      handleBahiaChange(index, value)
                    }
                  />
                </Table.Cell>
                <Table.Cell verticalAlign="top" style={{ width: "50px" }}>
                  <Button
                    icon="trash"
                    circular
                    size="small"
                    onClick={() => handleDeleteBahia(index)}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>


        <Button
          type="submit"
          // primary
          className="btn btn-danger"
          fluid
          content={Islas ? "Actualizar" : "Crear"}
        />
      </Form> */}
    </>
  );
}
