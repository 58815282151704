import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
// import { Button, Form, Input, Select } from "semantic-ui-react";
import * as Yup from "yup";
import { useTurnos, useBahias, useOrdenes, useOrdenesDescargadero } from "../../../hooks";
import { alertas } from "../../Comons/Alertas/alertas";
import { map } from "lodash";

import { Autocomplete } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';

export function AddEditTurnos(props) {

  const { onClose, onRefetch } = props;

  const { addTurnos } = useTurnos();
  const { BahiasDB, getBahiasDB } = useBahias();
  const { OrdenesDB, getOrdenesDB } = useOrdenes()
  const { OrdenesDescargaderoDB, getOrdenesDescargaderoDB } = useOrdenesDescargadero()
  const { Successful } = alertas();
  const [tipoProceso, setTipoProceso] = useState(1)
  const [error, setError] = useState(false); // controla un mensaje de error cuando no hay selección en el tipo de proceso
  const [error2, setError2] = useState(false)

  useEffect(() => {
    // getVehiculosDB();
    // getConductorDB();
    getBahiasDB();
  }, []);

  useEffect(() => {
    if (tipoProceso == 1) {
      getOrdenesDB();
    } else if (tipoProceso == 2) {
      getOrdenesDescargaderoDB()
    }
  }, [OrdenesDB, OrdenesDescargaderoDB])
  // useEffect(() => {
  //   setTablasTFormatV(VehiculosSelect(VehiculosDB));
  // }, [VehiculosDB]);
  // useEffect(() => {
  //   setTablasTFormatCDR(conductoresSelect(ConductorDB));
  // }, [ConductorDB]);

  const handleOrdenChange = (event) => {
    formik.setFieldValue('orden', event.target.value)
    const ordenObj = event.target.value
    let ordenes = ""
    switch (tipoProceso) {
      case 1:
        ordenes = OrdenesDB.find((orden) => orden.n_orden == ordenObj.toString())
        break
      case 2:
        ordenes = OrdenesDescargaderoDB.find((orden) => orden.n_orden == ordenObj.toString())
        break
    }

    if (ordenes) {
      const bahia = BahiasDB?.find((b) => b.id_producto == ordenes.product_id)

      formik.setFieldValue('id_orden', ordenes.id_orden)
      formik.setFieldValue('bahia', bahia.id_bahias)
      formik.setFieldValue('conductor_name', ordenes.conductor_data.nombre + " " + ordenes.conductor_data.apellidos)
      formik.setFieldValue('placa_text', ordenes.vehiculo_data.placa_veh)
      formik.setFieldValue('conductor', ordenes.conductor_data.id_conductor)
      formik.setFieldValue('placa', ordenes.vehiculo_data.id_vehiculo)
    }
  }

  const formik = useFormik({

    // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
    initialValues: initialValues(),
    // Desactiva la validación al cambiar los valores del formulario
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        //console.log(formValue);
        // Si se proporciona un obj|eto se actualiza, de lo contrario se crea uno nuevo
        await addTurnos(formValue);
        Successful();
        onRefetch();
        onClose();
      } catch (error) {
        // Muestra un mensaje de error si ocurre algún problema
        toast.error(error.message, {
          position: "top-center",
        });
      }
    },
  });

  if (tipoProceso == 1 && !OrdenesDB) {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress />
    </div>;
  } else if (tipoProceso == 2 && !OrdenesDescargaderoDB) {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress />
    </div>;
  }

  return (
    <>
      <form className="add-edit-secciones-form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          {/* fila 1 */}
          <Grid item xs={12} sm={4}>
            <Autocomplete
              id="proc"
              options={ProcOpts}
              getOptionLabel={(option) => option.text}
              value={ProcOpts.find((opt) => opt.value === formik.values.proceso_t) || null}
              onChange={(_, value) => {
                formik.setFieldValue("proceso_t", value?.value || "");
                setTipoProceso(value?.value || 1);
                setError(!value || !value.value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tipo de proceso"
                  variant="outlined"
                  error={error}
                  required
                  helperText={error ? 'Es necesario que se haga una selección' : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              fullWidth={true}
              fluid
              label="Numero de orden"
              name="orden"
              placeholder="Numero orden"
              value={formik.values.orden}
              onChange={handleOrdenChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              id="turno"
              options={Options}
              getOptionLabel={(opt) => opt.text}
              value={Options.find((opt) => opt.value === formik.values.tipo_turno) || null}
              onChange={(_, value) => {
                formik.setFieldValue("tipo_turno", value?.value || "");
                setError2(!value || !value.value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tipo de turno"
                  variant="outlined"
                  error={error2}
                  required
                  helperText={error2 ? 'Es necesario una selección' : ''}
                />
              )}
            />
          </Grid>

          {/* fila 2 */}
          <Grid item xs={12} sm={4}>
            <Card>
              <CardHeader
                subheader="Conductor asociado"
              />
              <CardContent>
                <Typography variant="h6" >
                  {formik.values.conductor_name}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardHeader
                subheader="Vehículo asociado"
              />
              <CardContent>
                <Typography variant="h6" >
                  {formik.values.placa_text}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardHeader
                subheader="Bahía"
              />
              <CardContent>
                <Typography variant="h6">
                  {formik.values.bahia}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* fila 3 hidden */}
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              hidden
              disabled
              name="conductor"
              id="conductor"
              value={formik.values.conductor}
              onChange={formik.handleChange}
              error={formik.errors.conductor}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              hidden
              disabled
              name="placa"
              id="placa"
              value={formik.values.placa}
              onChange={formik.handleChange}
              error={formik.errors.placa}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField 
              fullWidth
              hidden
              disabled
              name="id_orden"
              value={formik.values.id_orden}
              onChange={formik.handleChange}
              error={formik.errors.id_orden}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Button type="submit" variant="contained" color="error" className="btn btn-danger" fullWidth>
              Crear
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

function initialValues() {
  return {
    id_orden: 0,
    tipo_turno: "",
    orden: "",
    placa: "",
    conductor: "",
    bahia: "",
    proceso_t: 1,
    placa_text: "",
    conductor_name: "",
  };
}

const Options = [
  {
    key: 1,
    text: "Normal",
    value: 1,
  },
  {
    key: 2,
    text: "Prioritario",
    value: 2,
  },
];

const ProcOpts = [
  {
    key: 1,
    text: "Cargadero",
    value: 1,
  },
  {
    key: 2,
    text: "Descargadero",
    value: 2,
  },
];
