import { useState } from "react";
import { useAuth } from "..";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import {
  addTankVerApi,
  addTanquesApi,
  deleteTanquesApi,
  getChecklistByTankApi,
  getSingleTankChecklistApi,
  getTanquesApi,
  getTanquesConsumoInternoApi,
  updatePartialApi,
  updateTanquesApi
} from "../../api/tanques";

export function useTanques() {
  const { auth, logout } = useAuth();
  const { unauthorized, SessionExpired, errores, unauthorizedModal } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    tanques: null,
    tanquesDB: null,
    TanquesCI: null,
    DataChklst: null,
  });

  const { loading, error, tanques, tanquesDB, TanquesCI, DataChklst } = state;

  const handleResponse = (response) => {
    if (response.status === 401) {
      SessionExpired();
      logout();
    } else if (response.status === 403) {
      unauthorized();
    }
  };

  const getTanquesDB = async () => {
    try {
      const [result, response] = await getTanquesApi(auth.token, auth.me.tenant);
      
      setState((prevState) => ({ ...prevState, tanquesDB: result.results}));
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  const getTanques = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getTanquesApi(auth.token, auth.me.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, tanques: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addTanques = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addTanquesApi(data, auth.token, auth.me.tenant);
      if (response.status === 400) {
        if (result?.codigo_tanque) {
          throw new Error("El codigo de tanque que ingreso ya cuenta con un registro activo");
        }
        if (result?.nombre_tanque) {
          throw new Error("El nombre ya se encuentra asignado a otro tanque");
        }
        if (result?.id_tabla_aforo) {
          throw new Error("La tabla de aforo debe ser unica para cada tanque");
        } else {
          throw new Error("Ya hay un tanque para consumo interno con ese combustible")
        }
      }
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateTanques = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateTanquesApi(id, data, auth.token, auth.me.tenant);
      if (response.status === 400) {
        if (result?.codigo_tanque) {
          throw new Error("El codigo de tanque que ingreso ya cuenta con un registro activo");
        }
        if (result?.nombre_tanque) {
          throw new Error("El nombre ya se encuentra asignado a otro tanque");
        }
        if (result?.id_tabla_aforo) {
          throw new Error("La tabla de aforo debe ser unica para cada tanque");
        } else {
          throw new Error("Ya hay un tanque para consumo interno con ese combustible");
        }
      }
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updatePartialTanques = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updatePartialApi(id, data, auth.token, auth.me.tenant);
  
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };


  const deleteTanques = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteTanquesApi(id, auth.token, auth.me.tenant);
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const getTankVer = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getTanquesApi(auth.token, auth.me.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, DataChklst: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  }

  const addTankVer = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addTankVerApi(data, auth.token, auth.me.tenant);
      if (response.status === 400) {
        throw new Error("Hubo un error");
      }
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  }

  const updateTankVer = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateTanquesApi(id, data, auth.token, auth.me.tenant);
      if (response.status === 400) {
        if (result?.codigo_tanque) {
          throw new Error("El codigo de tanque que ingreso ya cuenta con un registro activo");
        }
        if (result?.nombre_tanque) {
          throw new Error("El nombre ya se encuentra asignado a otro tanque");
        }
        if (result?.id_tabla_aforo) {
          throw new Error("La tabla de aforo debe ser unica para cada tanque");
        } else {
          errores();
        }
      }
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const getSingleTankChecklist = async (id_tanque) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getSingleTankChecklistApi(id_tanque, auth.token, auth.me.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, DataChklst: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  }

  const getChecklistByTank = async (id_tanque) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getChecklistByTankApi(id_tanque, auth.token, auth.me.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, DataChklst: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  }

  const getTanquesCI = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getTanquesConsumoInternoApi(auth.token, auth.me.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, TanquesCI: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  }

  return {
    loading,
    error,
    tanques,
    tanquesDB,
    getTanquesDB,
    getTanques,
    addTanques,
    updateTanques,
    deleteTanques,
    getTanquesCI,
    updatePartialTanques,
    TanquesCI,
    getTankVer,
    addTankVer,
    getSingleTankChecklist,
    getChecklistByTank,
    updateTankVer,
    DataChklst,
  };
}
