import React, { useCallback, useState } from "react";
import { AddEditAccesos, AddEditFacesInfo, AddEditIslaConf } from "../../../Components/Client";
import { useAccesos } from "../../../hooks"
import Swal from "sweetalert2";

export function Funciones() {

  const { handleDeleteIslaConf } = useAccesos();

  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);

  const handleAdd = useCallback(() => {
    setTitleModal("Nuevo acceso");
    setContentModal(
      <AddEditAccesos onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const handleAddConductorInfo = useCallback(() => {
    setTitleModal("Añadir información de conductores al dispositivo biométrico")
    setContentModal(
      <AddEditFacesInfo
        onClose={openCloseModal} onRefetch={onRefetch}
      />
    )
    openCloseModal()
  }, [openCloseModal, onRefetch])

  const handleAddIslaConf = useCallback(() => {
    setTitleModal("Añadir configuración de acceso a una isla")
    setContentModal(
      <AddEditIslaConf onClose={openCloseModal} onRefetch={onRefetch} />
    )
    openCloseModal()
  }, [openCloseModal, onRefetch])

  const handleEditIslaConf = useCallback((data) => {
    setTitleModal("Editar configuración de acceso a una isla")
    setContentModal(
      <AddEditIslaConf onClose={openCloseModal} onRefetch={onRefetch} islaConf={data} />
    )
    openCloseModal()
  }, [openCloseModal, onRefetch])

  const handleDeleteIslaConfig = useCallback( async (data) => {
    const result = await Swal.fire({
      title: "Estas seguro?",
      text: "No podrás revertir esta acción!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si, eliminar!",
      cancelButtonText: "No, cancelar!",
      reverseButtons: true,
    })

    if (result.isConfirmed) {
      await handleDeleteIslaConf(data.id_conf);
      Swal.fire("Eliminado!", "El dato ha sido eliminado.", "success");
      onRefetch();
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelado", "Operación cancelada", "error");
    }
  }, [handleDeleteIslaConf, onRefetch])

  return {
    handleAdd,
    openCloseModal,
    refetch,
    contentModal,
    titleModal,
    showModal,
    handleAddConductorInfo,
    handleAddIslaConf,
    handleEditIslaConf,
    handleDeleteIslaConfig,
  };
}
