import React, { useEffect, useState } from "react";
import { Loader } from "semantic-ui-react";
import {
  AddEditParamatrizacionDecimales,
  
} from "../../../Components/Client";
import { ModalBasic,DataGridBasic } from "../../../Components/Comons";
import { useDecimales } from "../../../hooks";
import { columns,filterValue } from "./ParametrizacionFormat";

export function ParamatrizacionDecimalesAdmin() {
  const { loading, Decimales, getDecimales } = useDecimales();
  const [refetch, setRefetch] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  
  useEffect(() => {
    getDecimales();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const onRefetch = () => setRefetch((prev) => !prev);
  const openCloseModal = () => setShowModal((prev) => !prev);
/** La función  establece el título "Actualizar..." y además recibe un parámetro "data",
 *  que contiene los datos del dato a actualizar. El contenido del modal es el mismo componente "AddEditComponent" que antes, pero en este caso, 
 * el componente recibe la propiedad  con los datos a actualizar. El componente cuenta con dos props, "onClose" y "onRefetch". 
 * "onClose" es una función que cierra el modal, y "onRefetch" es una función que recarga los datos de la tabla después de actualizar un dato. */
  const updateConductores = (data) => {
    
    setTitleModal("Actualizar conductor");
    setContentModal(
      <AddEditParamatrizacionDecimales
        onClose={openCloseModal}
        onRefetch={onRefetch}
        decimales={data}
      />
    );
    openCloseModal();
  };
  return (
    <>
      {loading ? (
        <Loader active inline="centered">
          Cargando...
        </Loader>
      ) : (
        <DataGridBasic
        data={Decimales}
        columns={columns}
        id="id_parametrizacion"
        filterValue={filterValue}
        updateData={updateConductores}
        />
      )}
      

      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        title={titleModal}
        children={contentModal}
      />
    </>
  );
}

