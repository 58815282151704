import React, { useCallback, useEffect, useState } from "react";
import { useDocumentosConductores } from "../../../hooks";
import { DocumentosBase } from "../../Comons";
export function DocumentosConductores(props) {

  const { data } = props;
  const [refetch, setRefetch] = useState(false);
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);
  const {

    Documentosconductores,
    getDocumentosconductores,
    updateDocumentosconductores,
  } = useDocumentosConductores();
  
  useEffect(() => {
    getDocumentosconductores(data);
  }, [refetch]);

  return (
    <>
    <DocumentosBase update={updateDocumentosconductores} documentos={Documentosconductores} onRefetch={onRefetch}/>
    </>
  );
}
