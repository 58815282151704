import { useDocumentosVehiculos } from "../../../hooks";
import React, { useCallback, useState, useEffect  } from "react";
import { DocumentosBase } from "../../Comons";
export function DocumentComponent(props) {

  const { data } = props;
  const [refetch, setRefetch] = useState(false);
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);
  const {

    DocumentosVehiculos,
    getDocumentosVehiculos,
    updateDocumentosVehiculos,

  } = useDocumentosVehiculos();
  
  useEffect(() => {
    getDocumentosVehiculos(data);
  }, [refetch]);

  return (
    <>
    <DocumentosBase update={updateDocumentosVehiculos} documentos={DocumentosVehiculos} onRefetch={onRefetch}/>
    </>
  );
}
