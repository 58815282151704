import { useState } from "react";
import { useAuth } from "..";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import {
  patchHazardApi,
  updateHazardApi,
  getHazardApi
} from "../../api/A_Conexiones/hazard";


export function useHazard() {
  const { auth } = useAuth();
  const { unauthorizedModal, errores } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    
  });

  const { loading, error} = state;

  const handleAddOrUpdateError = () => {
    errores();
  };
  const getHazardMultiload = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getHazardApi(data, auth.token, auth.me.tenant);
      if (response.status === 400) {
        handleAddOrUpdateError();
      }
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };
  
  const updateHazard = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateHazardApi(id, data, auth.token, auth.me.tenant);
      if (response.status === 400) {
        handleAddOrUpdateError();
      }
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const patchHazard = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await patchHazardApi(id, data, auth.token, auth.me.tenant);
      if (response.status === 400) {
        handleAddOrUpdateError();
      }
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

 
  return {
    loading,
    error,
    getHazardMultiload,
    updateHazard,
    patchHazard,
   
  };
}
