

  import { BASE_API } from "../../utils/constants";
  import { makeRequestFormData } from "../Request";
  const BASE_URL = `${BASE_API}/`;
  
  
  // Función para obtener los datos 
  export async function getConductorApi(token,schema,filter,conductor) {
    let url = `${BASE_URL}${schema}/conductores/conductorA`;
    if (filter) {
      url += `?filter=${filter}`;
    }

    if (conductor) {
      url += `&conductor=${conductor}`;
    }
    return makeRequestFormData(url, "GET", token);
  }
  
  // Función para agregar un nuevo dato
  export async function addConductorApi(data, token,schema) {
    const formData = new FormData();

      formData.append("nombre", data.nombre);
      formData.append("apellidos", data.apellidos);
      formData.append("email", data.email);
      if (data.foto !== null) {
        formData.append("foto", data.foto);
      }
      formData.append("n_documento", data.n_documento);
      formData.append("n_licencia", data.n_licencia);
      formData.append("fecha_vencimineto_licencia", data.fecha_vencimineto_licencia);
      formData.append("seguro", data.seguro);
      formData.append("celular", data.celular);
      formData.append("telefono", data.telefono);
      formData.append("fecha_nacimiento", data.fecha_nacimiento);
      formData.append("id_transp", data.id_transp);
      formData.append("documentos", JSON.stringify(data.documentos));
      formData.append("tipo_id", data.tipo_id);
    
    const url = `${BASE_URL}${schema}/conductores/conductorA/`;
    return makeRequestFormData(url, "POST", token, formData);
  }
  
  // Función para actualizar los datos 
  export async function updateConductorApi(id, data, token,schema) {
    const formData = new FormData();

      formData.append("nombre", data.nombre);
      formData.append("apellidos", data.apellidos);
      formData.append("email", data.email);
      if (data.foto !== null) {
        formData.append("foto", data.foto);
      }
      formData.append("n_documento", data.n_documento);
      formData.append("n_licencia", data.n_licencia);
      formData.append("fecha_vencimineto_licencia", data.fecha_vencimineto_licencia);
      formData.append("seguro", data.seguro);
      formData.append("celular", data.celular);
      formData.append("telefono", data.telefono);
      formData.append("fecha_nacimiento", data.fecha_nacimiento);
      formData.append("id_transp", data.id_transp);
      formData.append("documentos", JSON.stringify(data.documentos));
      formData.append("tipo_id", data.tipo_id);
      
    const url = `${BASE_URL}${schema}/conductores/conductorA/${id}/`;
    return makeRequestFormData(url, "PATCH", token, formData);
  }
  
  // Función para eliminar 
  export async function deleteConductorApi(id, token,schema) {
    const url = `${BASE_URL}${schema}/conductores/conductorA/${id}/`;
    return makeRequestFormData(url, "DELETE", token);
  }