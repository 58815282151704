import React, { useEffect } from "react";
import { Loader } from "semantic-ui-react";
import CircularProgress from '@mui/material/CircularProgress';
import { ModalBasic, DataGridNoOpt } from "../../../Components/Comons";
import { useDatosCalculados } from "../../../hooks";
import { columns, filterValue } from "./DatosCalculadosFormat";
import { Funciones } from "./Funciones";

export function DatosCalculadosAdmin() {
  const { loading, datosCalculados, getDatosCalculados } = useDatosCalculados();
  const {
    refetch,
    contentModal,
    titleModal,
    showModal,

    openCloseModal,
  } = Funciones();

  useEffect(() => {
    getDatosCalculados();
  }, [refetch]);

  const MemoizedModalBasic = React.memo(ModalBasic);

  return (
    <>

      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <DataGridNoOpt
          data={datosCalculados}
          title="Correcciones"
          columns={columns}
          id="id_myemp"
          filterValue={filterValue}
        />
      )}

      {showModal && (
        <MemoizedModalBasic
          show={showModal}
          onClose={openCloseModal}
          title={titleModal}
          children={contentModal}
        />
      )}
    </>
  );
}
