
  

  import { BASE_API } from "../../utils/constants";
  import { makeRequestFormData } from "../Request";
  const BASE_URL = `${BASE_API}/`;
  
  
  // Función para obtener los datos 
  export async function getDocumentosTransportadoraApi(token, schema, id) {
    let url = `${BASE_URL}${schema}/transportadora/documentos`;
    if (id) {
      url += `?transportadora=${id}`;
    }
    return makeRequestFormData(url, "GET", token);
  }
  
  
  // Función para actualizar los datos 
export async function updateDocumentosApi(id, data, token,schema) {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      if(key === 'documentos'){
        formData.append(key, JSON.stringify(data[key]));
      }else{
         formData.append(key, data[key]);
        }
      });
    const url = `${BASE_URL}${schema}/transportadora/documentos/${id}/`;
    return makeRequestFormData(url, "PATCH", token, formData);
  }
  
 