export const columns = [
  
    {
      header: "Nit de planta",
      name: "nit_myemp",
      maxWidth: 1000, defaultFlex: 1
    },
    {
      header: "Razón social",
      name: "razon_social",
      maxWidth: 1000, defaultFlex: 1
    },
    {
      header: "Dirección",
        name: "id_direcFc",
        maxWidth: 1000, defaultFlex: 1,
        render: ({ data }) =>
        `${data?.id_direcFc?.department_name}- Ciudad: ${data?.id_direcFc?.city_name } - Direccion: ${data?.id_direcFc?.id_direcFc}`
    
      },
    {
      header: "Datos",
      name: "id_empresa.email",
      maxWidth: 1000, defaultFlex: 1,
      render: ({ data }) =>
        `${data?.id_empresa?.email}`
    
    },
  ];

  export const filterValue = [
    { name: 'nit_myemp', operator: 'startsWith', type: 'string', value: '' },
    // { name: 'age', operator: 'gte', type: 'number', value: 21 },
    { name: 'razon_social', operator: 'startsWith', type: 'string', value: '' },
  
  ];
  




























  
//   const columns2 = [
//     { name: 'id', header: 'Id', defaultVisible: false, defaultWidth: 80, type: 'number' },
//     { name: 'name', header: 'Name', defaultFlex: 1 },
//     { name: 'age', header: 'Age', defaultFlex: 1, type: 'number', filterEditor: NumberFilter },
//     {
//       name: 'country',
//       header: 'Country',
//       defaultFlex: 1,
//       filterEditor: SelectFilter,
//       filterEditorProps: {
//         placeholder: 'All',
//         dataSource: countries
//       },
//       render: ({ value })=> flags[value]? flags[value]: value
//     },
//     {
//       name: 'birthDate',
//       header: 'Birth date',
//       defaultFlex: 1,
//       minWidth: 200,
//       filterEditor: DateFilter,
//       filterEditorProps: (props, { index }) => {
//         // for range and notinrange operators, the index is 1 for the after field
//         return {
//           dateFormat: 'MM-DD-YYYY',
//           cancelButton: false,
//           highlightWeekends: false,
//           placeholder: index == 1 ? 'Created date is before...': 'Created date is after...'
//         }
//       },
//       render: ({ value, cellProps }) => {
//         return moment(value).format('MM-DD-YYYY')
//       }
//     },
//     { name: 'city', header: 'City', defaultFlex: 1 },
//   ];