import React, { useState } from 'react';

export function LengthConverter() {
  const [value, setValue] = useState('');
  const [fromUnit, setFromUnit] = useState('m');
  const [toUnit, setToUnit] = useState('m');

  const handleInputChange = (event) => {
    setValue(event.target.value);
  };

  const handleFromSelectChange = (event) => {
    setFromUnit(event.target.value);
  };

  const handleToSelectChange = (event) => {
    setToUnit(event.target.value);
  };

  const convertToMeters = (value, fromUnit) => {
    switch (fromUnit) {
      case 'm':
        return parseFloat(value);
      case 'cm':
        return parseFloat(value) / 100;
      case 'yd':
        return parseFloat(value) * 0.9144;
      case 'ft':
        return parseFloat(value) * 0.3048;
      default:
        return 0;
    }
  };

  const convertFromMeters = (meters, toUnit) => {
    switch (toUnit) {
      case 'm':
        return meters;
      case 'cm':
        return meters * 100;
      case 'yd':
        return meters / 0.9144;
      case 'ft':
        return meters / 0.3048;
      default:
        return 0;
    }
  };

  const convertValue = (value, fromUnit, toUnit) => {
    const meters = convertToMeters(value, fromUnit);
    const convertedValue = convertFromMeters(meters, toUnit);
    return convertedValue.toFixed(2);
  };

  const renderOptions = () => {
    return (
      <>
        <option value="m">Meters (m)</option>
        <option value="cm">Centimeters (cm)</option>
        <option value="yd">Yards (yd)</option>
        <option value="ft">Feet (ft)</option>
      </>
    );
  };

  return (
    <div className="container" style={{ maxWidth: '500px', margin: '0 auto', textAlign: 'center' }}>
      <h2 style={{ marginBottom: '1rem' }}>Conversor de longitud</h2>
      <div className="form-group" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '1rem' }}>
        <label style={{ marginRight: '1rem' }}>
          Ingrese un valor:
          <input type="number" value={value} onChange={handleInputChange} className="form-control" style={{ marginLeft: '0.5rem' }} />
        </label>
      </div>
      <div className="form-group" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
        <label style={{ width: '45%' }}>
          Convertir de:
          <select value={fromUnit} onChange={handleFromSelectChange} className="form-control" style={{ marginLeft: '0.5rem' }}>
            {renderOptions()}
          </select>
        </label>
        <label style={{ width: '45%' }}>
          Convertir a:
          <select value={toUnit} onChange={handleToSelectChange} className="form-control" style={{ marginLeft: '0.5rem' }}>
            {renderOptions()}
          </select>
        </label>
      </div>
      <p style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>La unidad convertida {value} {fromUnit} a {toUnit} es {convertValue(value, fromUnit, toUnit)} {toUnit}</p>
    </div>
  );
}
