import { useFormik } from "formik";
import { map } from "lodash";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
// import { Button, Form, Input, Select } from "semantic-ui-react";
import { Autocomplete } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid"
import TextField from '@mui/material/TextField'
import * as Yup from "yup";

import { useTablaAforo, useFracciones, useSecciones } from "../../../../hooks";

import { alertas } from "../../../Comons/Alertas/alertas";
import { fraccionesSelect, seccionesSelect } from "../../../Comons";

export function AddEditTablaAforoForms(props) {
  /*
  Componente

  -componente de visualizacion del modal de edicion y creacion para Tabla de aforo
  
  -se crea y valida mediante formik y yup
  -Se obtienen datos para dropdown mediante hooks
  -se obtiene los datos (para edicion) y funciones correspondientes mediante props desde su PageAdmin
   */
  const { Successful } = alertas();
  const { onClose, onRefetch, TablaAforo } = props;
  const { addTablasAforo, updateTablasAforo } = useTablaAforo();
  const { FraccionesDB, getFraccionesDB } = useFracciones();
  const { SeccionesDB, getSeccionesDB } = useSecciones();

  //Prueba de nuevo modal sobre modal

  const [tablasFormatF, setTablasTFormatF] = useState([]);
  const [tablasFormatS, setTablasTFormatS] = useState([]);

  useEffect(() => {
    getSeccionesDB();
    getFraccionesDB();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTablasTFormatF(fraccionesSelect(FraccionesDB));
  }, [FraccionesDB]);

  useEffect(() => {
    setTablasTFormatS(seccionesSelect(SeccionesDB));
  }, [SeccionesDB]);

  const formik = useFormik({
    // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
    initialValues: initialValues(TablaAforo),
    // Establece la validación del esquema utilizando Yup, si se proporciona un objeto  se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
    validationSchema: Yup.object(TablaAforo ? updateSchame() : newSchame()),
    // Desactiva la validación al cambiar los valores del formulario
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        // Si se proporciona un objeto se actualiza, de lo contrario se crea uno nuevo
        if (TablaAforo) await updateTablasAforo(TablaAforo.id_aforo, formValue);
        else await addTablasAforo(formValue);
        Successful();
        onRefetch();
        onClose();
      } catch (error) {
        // Muestra un mensaje de error si ocurre algún problema
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });

  // const divStyle = {
  //   textAlign: "left",
  // };

  return (
    <>
      <form className="add-edit-persona-form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              required
              label="Nombre de la tabla"
              name="nombre_tabla"
              placeholder="Nombre de la tabla"
              value={formik.values.nombre_tabla}
              onChange={formik.handleChange}
              error={formik.errors.nombre_tabla}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              fullWidth
              id="id_secciones"
              options={tablasFormatS}
              getOptionLabel={(option) => `${option.text} `}
              value={
                tablasFormatS?.find(
                  (opt) => opt.key === formik.values?.id_secciones
                ) || null
              }
              onChange={(_, value) => {
                formik.setFieldValue("id_secciones", value?.key || "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Secciones asociadas"
                  variant="outlined"
                  error={formik.touched.id_secciones && Boolean(formik.errors.id_secciones)}
                  helperText={formik.touched.id_secciones && formik.errors.id_secciones}
                  sx={{ background: "#fcfcfc" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              fullWidth
              id="id_secciones"
              options={tablasFormatF}
              getOptionLabel={(option) => `${option.text} `}
              value={
                tablasFormatF?.find(
                  (opt) => opt.key === formik.values?.id_fracciones
                ) || null
              }
              onChange={(_, value) => {
                formik.setFieldValue("id_fracciones", value?.key || "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Fracciones asociadas"
                  variant="outlined"
                  error={formik.touched.id_fracciones && Boolean(formik.errors.id_fracciones)}
                  helperText={formik.touched.id_fracciones && formik.errors.id_fracciones}
                  sx={{ background: "#fcfcfc" }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label="Documento del calibrador "
              placeholder="# documento"
              name="id_calibracion.id_calibrador"
              onChange={formik.handleChange}
              value={formik.values?.id_calibracion.id_calibrador}
              error={formik.errors.id_calibracion?.id_calibrador}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label="Documento de quien aprueba"
              placeholder="# documento"
              name="id_calibracion.id_aprovador"
              onChange={formik.handleChange}
              value={formik.values?.id_calibracion?.id_aprovador}
              error={formik.errors.id_calibracion?.id_aprovador}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              required
              name="id_calibracion.fecha_calibracion"
              placeholder="Fecha calibracion"
              value={formik.values?.id_calibracion?.fecha_calibracion}
              onChange={formik.handleChange}
              error={formik.errors.id_calibracion?.fecha_calibracion}
              type="date"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              required
              name="id_calibracion.fecha_calibracion_anterior"
              placeholder="Fecha calibracion anterior"
              value={formik.values?.id_calibracion.fecha_calibracion_anterior}
              onChange={formik.handleChange}
              error={formik.errors.id_calibracion?.fecha_calibracion_anterior}
              type="date"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              required
              name="id_calibracion.fecha_vencimiento"
              placeholder="Fecha de vencimiento"
              value={formik.values?.id_calibracion.fecha_vencimiento}
              onChange={formik.handleChange}
              error={formik.errors.id_calibracion?.fecha_vencimiento}
              type="date"
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              required
              label="Incertidumbre extendida"
              name="id_incertidumbre.Incertidumbre_extendida"
              placeholder="Incertidumbre extendida"
              value={formik.values?.id_incertidumbre?.Incertidumbre_extendida}
              onChange={formik.handleChange}
              error={formik.errors.id_incertidumbre?.Incertidumbre_extendida}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              required
              label="Factor de cobertura"
              name="id_incertidumbre.factor_cobertura"
              placeholder="Factor de cobertura"
              value={formik.values?.id_incertidumbre?.factor_cobertura}
              onChange={formik.handleChange}
              error={formik.errors.id_incertidumbre?.factor_cobertura}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              required
              label="Nivel de confianza"
              name="id_incertidumbre.nivel_confianza"
              placeholder="Nivel de confianza"
              value={formik.values?.id_incertidumbre?.nivel_confianza}
              onChange={formik.handleChange}
              error={formik.errors.id_incertidumbre?.nivel_confianza}
              type="number"
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Button type="submit" variant="contained" color="error" className="btn btn-danger" fullWidth>
              {TablaAforo ? "Actualizar" : "Crear"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}



function initialValues(data) {
  return {
    nombre_tabla: data?.nombre_tabla || "",
    id_incertidumbre: {
      Incertidumbre_extendida:
        data?.id_incertidumbre?.Incertidumbre_extendida || "",
      factor_cobertura: data?.id_incertidumbre?.factor_cobertura || "",
      nivel_confianza: data?.id_incertidumbre?.nivel_confianza || "",
    },
    id_calibracion: {
      id_calibrador: data?.id_calibracion?.id_calibrador || "",
      id_aprovador: data?.id_calibracion?.id_aprovador || "",
      fecha_calibracion: data?.id_calibracion?.fecha_calibracion || "",
      fecha_calibracion_anterior:
        data?.id_calibracion?.fecha_calibracion_anterior || "",
      fecha_vencimiento: data?.id_calibracion?.fecha_vencimiento || "",
    },
    id_fracciones: data?.id_fracciones || "",
    id_secciones: data?.id_secciones || "",
  };
}

function newSchame() {
  return {
    nombre_tabla: Yup.string().required(true),
    id_fracciones: Yup.number().required(true),
    id_secciones: Yup.number().required(true),
  };
}

function updateSchame() {
  return {
    nombre_tabla: Yup.string().required(true),
    id_fracciones: Yup.number().required(true),
    id_secciones: Yup.number().required(true),
  };
}
