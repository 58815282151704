import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import ReactApexChart from 'react-apexcharts';
import { groupBy } from 'lodash';
import { format } from 'date-fns';

const customColors = ["#D0302B","#BEBEBE","#444444", "#08131A"];

const getRandomColor = () => {
  const randomIndex = Math.floor(Math.random() * customColors.length);
  return customColors[randomIndex];
};



const ReportAreaChart = ({ data }) => {
  const theme = useTheme();
  const { secondary } = theme.palette.text;
  const line = theme.palette.divider;

  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});

  useEffect(() => {
    const groupedData = data.reduce((accumulator, item) => {
      if (accumulator[item.tanque_nombre]) {
        accumulator[item.tanque_nombre].data.push(item.nsv);
      } else {
        accumulator[item.tanque_nombre] = {
          name: item.tanque_nombre,
          data: [item.nsv],
          color: getRandomColor
        };
      }
      return accumulator;
    }, {});

    const categories = data.map((item) => format(new Date(item.fecha_crea), 'yyyy-MM-dd'));

    const newSeries = Object.values(groupedData);

    setSeries(newSeries);
    setOptions((prevState) => ({
      ...prevState,
      colors: newSeries.map((serie) => serie.color),
      xaxis: {
        ...prevState.xaxis,
        categories: categories,
      },
      grid: {
        borderColor: line
      },
      tooltip: {
        theme: 'light'
      },
      legend: {
        labels: {
          colors: 'grey.500'
        }
      }
    }));
  }, [data, line]);

  return (
    <ReactApexChart options={options} series={series} type="line" height={345} />
  );
};

export default ReportAreaChart;
