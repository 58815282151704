  
import { BASE_API } from "../../utils/constants";
import { makeRequest } from "../Request";
const BASE_URL = `${BASE_API}/`;

// Función para obtener los datos 
export async function getBahiasApi(token,schema) {
  const url = `${BASE_URL}${schema}/plantas/bahias`;
  return makeRequest(url, "GET", token);
}

// Función para agregar un nuevo dato
export async function addBahiasApi(data, token,schema) {
  const url = `${BASE_URL}${schema}/plantas/bahias/`;
  return makeRequest(url, "POST", token, data);
}


// Función para actualizar los datos 
export async function updateBahiasApi(id, data, token,schema) {
  const url = `${BASE_URL}${schema}/plantas/bahias/${id}/`;
  return makeRequest(url, "PUT", token, data);
}


// Función para eliminar 
export async function deleteBahiasApi(id, token,schema) {
  const url = `${BASE_URL}${schema}/plantas/bahias/${id}/`;
  return makeRequest(url, "DELETE", token);
}