import styled from "styled-components";

export const ContainerImageTank = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  svg {
    height: ${({ height }) => (height ? `${height}px` : "150px")};
    > .bg-gradient {
      fill: transparent !important;
    }
    z-index: 2;
  }
`;

export const Oval = styled.div`
  width: ${({ width }) => (width ? `${width}px` : "20px")};
  height: ${({ height }) => (height ? `${height}px` : "15px")};
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.primary.gray8};
  background-color: ${({ bg, theme }) => (bg ? bg : theme.primary.white)};
`;

export const ContainerOval = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;

  p {
    font-size: ${({ detail }) => (detail ? "12px" : "8px")};
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
  }
`;

export const ContainerOvalText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  z-index: 3;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  position: relative;
  left: ${({ leftContainerOval }) =>
    leftContainerOval ? `${leftContainerOval}px` : "145px"};
  bottom: ${({ bottomContainerOval }) =>
    bottomContainerOval ? `${bottomContainerOval}px` : "30px"};
`;

export const ContainerFooter = styled.div`
  display: flex;
  gap: 6px;
  margin-top: 8px;
  margin-left: 8px;
  > div {
    display: flex;
    gap: 6px;
    > p {
      font-size: 8px;
      text-transform: uppercase;
      margin: 0;
    }
  }
`;

export const Percent = styled.span`
  font-size: ${({ fontSizePercent }) =>
    fontSizePercent ? `${fontSizePercent}px` : "12px"};
  font-weight: bold;
  color: ${({ theme }) => theme.primary.gray7};
  margin: 0;
  position: relative;
  right: ${({ rightPercent }) =>
    rightPercent ? `${rightPercent}px` : "102px"};
  bottom: ${({ bottomPercent }) =>
    bottomPercent ? `${bottomPercent}px` : "60px"};
  z-index: 3;
`;

export const ContainerTank = styled.div`
  position: relative;
  width: 0px;
  height: ${({ detail }) => (detail ? "270px" : "102px")};
  align-self: center;
  border-radius: 14px;
  background: ${({ theme }) => theme.primary.gradient} !important;
  transform: rotate(180deg);
  right: ${({ detail }) => (detail ? "200px" : "67px")};
  bottom: -24px;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    width: ${({ detail }) => (detail ? "180px" : "87px")};
    height: ${({ percent }) => (percent ? `${percent}%` : "0%")};
    background: ${({ theme }) => theme.primary.black};
  }
`;
