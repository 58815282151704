export const Perm4 = () => {
  return (
    <svg
      id="Capa_1"
      data-name="Capa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 441.65 654.74"
    >
      <path
        fill="#fff"
        d="M87.22,0C33.94,.68,.65,35.19,.45,89.18c-.59,159.23-.61,318.47,0,477.7,.21,53.95,33.83,87.07,87.77,87.45,91.86,.64,183.74,.48,275.6-.21,11.2-.08,23.43-3.44,33.34-8.77,30.2-16.27,44.52-42.75,44.45-77.43-.16-79.62-.05-159.24-.05-238.85,0-79.62,.23-159.24-.08-238.85C441.27,35.84,409.41,.79,356.33,.04"
      />
      <path
        fill="#040404"
        d="M356.33,.04c53.08,.75,84.94,35.8,85.15,90.18,.31,79.62,.08,159.24,.08,238.85,0,79.62-.11,159.24,.05,238.85,.07,34.67-14.25,61.16-44.45,77.43-9.9,5.34-22.14,8.69-33.34,8.77-91.86,.69-183.74,.85-275.6,.21-53.94-.38-87.57-33.5-87.77-87.45C-.16,407.65-.14,248.41,.45,89.18,.65,35.19,33.94,.68,87.22,0"
      />
      <path
        fill="#040404"
        d="M88.63,20.15c-42.93,.52-68.01,26.7-68.16,70.36-.57,158.07-.59,316.14,.01,474.2,.17,46,25.19,69.49,71.02,69.59,86.42,.2,172.85,.19,259.27,0,47.49-.1,70.61-23.84,70.75-72.64,.21-77.4,.06-154.8,.06-232.2,0-79.58,.26-159.16-.1-238.74-.2-44.38-23.28-69.93-67.1-70.52"
      />
      <path
        fill="#fafafa"
        d="M354.37,20.21c43.82,.59,66.89,26.14,67.1,70.52,.37,79.58,.1,159.16,.1,238.74,0,77.4,.15,154.8-.06,232.2-.13,48.8-23.26,72.54-70.75,72.64-86.42,.19-172.85,.2-259.27,0-45.82-.1-70.84-23.59-71.02-69.59-.6-158.07-.58-316.14-.01-474.2,.16-43.66,25.23-69.84,68.16-70.36"
      />
      <path
        fill="#fafafa"
        d="M390.13,441.49c0-33.8,.26-67.6-.06-101.4-.42-43.87-21.68-65.96-65.72-66.39-66.98-.66-133.99-.7-200.97,.1-41.31,.5-64.34,23.57-64.78,64.67-.72,67.59-.67,135.21-.02,202.8,.41,42.3,24.24,66.24,66.4,66.66,66.98,.68,133.99,.71,200.97,0,41.21-.44,63.55-23.71,64.09-65.04,.44-33.79,.09-67.6,.09-101.4Z"
      />
      <path
        fill="#100e0d"
        d="M390.13,441.49c0,33.8,.35,67.61-.09,101.4-.54,41.33-22.88,64.6-64.09,65.04-66.98,.72-133.99,.69-200.97,0-42.17-.43-65.99-24.37-66.4-66.66-.66-67.6-.7-135.21,.02-202.8,.44-41.11,23.47-64.18,64.78-64.67,66.98-.8,133.98-.76,200.97-.1,44.04,.43,65.31,22.52,65.72,66.39,.32,33.8,.06,67.6,.07,101.4Zm-211.51,144.87c-4.29-2.4-8.57-4.8-12.86-7.2,0,0-.74-.21-.74-.21-4.89-4.31-9.79-8.61-14.68-12.92-28.1-28.67-33.14-63.4-26.13-101.29,1.67-6.58,3.35-13.16,5.02-19.74,6.96-15.42,13.43-31.1,21.27-46.06,1.77-3.38,8.31-4.21,12.66-6.22-.86,2.05-.92,4.13-.17,6.23,.95,12.43,1.89,24.86,2.84,37.28,5.44,9.65,10.87,19.31,16.31,28.96,29.02,11.99,37.23,8.08,40.75-21.03,1.96-16.17,2.82-32.57,2.76-48.87-.13-35.37,11-66.05,34.99-91.9,5.62-3.95,11.24-7.9,20.25-14.23h-20.96c-45.39-.37-90.78-1.26-136.17-.95-32.27,.23-51.45,19.72-51.71,52.52-.51,65.47-.55,130.94,.02,196.41,.29,33.39,23.49,56.78,56.17,57.94,11.81,.42,23.69-.79,35.53-1.26,2.25-.03,4.5-.07,6.75-.1,7.12-.53,14.24-1.05,21.35-1.58-4.42-1.93-8.83-3.85-13.25-5.78Zm127.53-61.74c1.97-15.67,3.93-31.35,5.9-47.02-5.32-11.22-10.63-22.44-15.95-33.66-20.15-25.9-43.53-49.98-48.3-85.21-2.88,35.13-5.76,70.26-8.63,105.38-8.38,8.49-16.75,16.98-25.13,25.47-8.45,.23-16.91,.46-25.36,.69-14.67-12.92-29.34-25.84-43.97-38.72-6.8,24.77-16.61,51.29-.36,78.37,7.63,9.38,15.26,18.76,22.89,28.13,6.44,4.34,12.88,8.68,19.33,13.02,23.84,15.59,48.4,14.53,73.39,3.19,21.13-8.41,37.16-21.98,44.39-44.5,1.43-1.42,2.04-3.14,1.82-5.15Zm-20.8-123.95c14.38,22.66,28.75,45.31,43.13,67.97-.49,15.99-.98,31.99-1.47,47.98-12.81,34.93-35.46,60.83-70.09,76.65,24.19,1.9,47.57,3.6,70.61,1.5,33.37-3.04,48.63-21.32,48.77-55.33,.28-65.29-.28-130.58,.27-195.87,.2-24.14-7.56-45.06-30.93-50.5-18.99-4.42-40.11,.42-60.28,1.15-.93,1.89-1.59,3.88-2,5.95-1.79,4.4-3.59,8.8-5.38,13.2-10.7,19.06-12.05,38.99-5.5,59.67,1.83,4.42,3.66,8.85,5.49,13.27,2.46,4.79,4.91,9.57,7.37,14.36Z"
      />
      <path
        fill="#c4bdb5"
        d="M165.83,436.23c-.95-12.43-1.89-24.86-2.84-37.28,.06-2.08,.11-4.15,.17-6.23-4.34,2.01-10.88,2.84-12.66,6.22-7.84,14.95-14.3,30.64-21.27,46.06-14.52,3.25-16.71,9.7-5.02,19.74-7.01,37.89-1.97,72.62,26.13,101.29-.49,10.54,1.07,18.7,14.68,12.92l.74,.21c-.66,4.89-1.33,9.77-1.99,14.66-11.85,.47-23.72,1.68-35.53,1.26-32.68-1.16-55.88-24.55-56.17-57.94-.57-65.47-.53-130.94-.02-196.41,.25-32.8,19.43-52.3,51.71-52.52,45.39-.32,90.78,.57,136.17,.95,.24,4.74,.48,9.49,.71,14.23-23.99,25.86-35.12,56.53-34.99,91.9,.06,16.29-.8,32.7-2.76,48.87-3.52,29.11-11.73,33.02-40.75,21.03-5.44-9.65-10.87-19.31-16.31-28.96Z"
      />
      <path
        fill="#c3bdb5"
        d="M277.99,313.38l5.38-13.2c1.92-.56,3.84-1.12,5.76-1.68-1.25-1.43-2.51-2.85-3.76-4.28,20.17-.73,41.29-5.58,60.28-1.15,23.37,5.44,31.13,26.36,30.93,50.5-.55,65.29,0,130.58-.27,195.87-.14,34.01-15.4,52.29-48.77,55.33-23.04,2.1-46.42,.4-70.61-1.5,34.63-15.83,57.28-41.73,70.09-76.65,18.45-15.43,14.22-31.57,1.47-47.98-14.38-22.66-28.75-45.31-43.13-67.97-2.46-4.79-4.91-9.57-7.37-14.36-1.83-4.42-3.66-8.85-5.49-13.27,1.83-19.89,3.67-39.78,5.5-59.67Z"
      />
      <path
        fill="#f5e727"
        d="M188.68,490.28c8.45-.23,16.91-.46,25.36-.69,8.64,2.48,17.47,4.46,25.88,7.57,11.42,4.21,22.53,9.28,33.77,13.99-33.14,40.17-66.1,45.11-111.46,16.71,.36-1.39,.46-2.8,.3-4.23,8.72-11.12,17.43-22.23,26.15-33.35Z"
      />
      <path
        fill="#ef7917"
        d="M239.17,464.12c2.88-35.13,5.76-70.26,8.63-105.38,4.77,35.23,28.14,59.31,48.3,85.21-1.67-.23-3.03,.31-4.09,1.63-3.34,.56-6.68,1.11-10.02,1.67,.96,1.32,1.93,2.63,2.89,3.95,.17,6.58,.33,13.16,.5,19.74-15.4-2.27-30.8-4.54-46.2-6.81Z"
      />
      <path
        fill="#f4b213"
        d="M188.68,490.28c-8.72,11.12-17.43,22.23-26.15,33.35-6.06,2.1-12.13,4.2-18.19,6.3-16.24-27.08-6.44-53.61,.36-78.37,14.63,12.89,29.3,25.8,43.97,38.72Z"
      />
      <path
        fill="#f4b213"
        d="M239.17,464.12c15.4,2.27,30.8,4.54,46.2,6.81-3.89,13.41-7.78,26.81-11.68,40.22-11.24-4.7-22.35-9.77-33.77-13.99-8.42-3.1-17.24-5.09-25.88-7.57,8.38-8.49,16.75-16.98,25.13-25.47Z"
      />
      <path
        fill="#de9b0e"
        d="M259.95,574.27c-24.99,11.34-49.55,12.4-73.39-3.19,2.79-2.55,5.57-5.09,8.36-7.64,19.48,.08,38.97,.16,58.45,.25,2.19,3.53,4.38,7.05,6.57,10.58Z"
      />
      <path
        fill="#e0d210"
        d="M259.95,574.27c-2.19-3.53-4.38-7.05-6.57-10.58,14.78-13.96,29.56-27.93,44.34-41.89,2.21,2.66,4.41,5.31,6.62,7.97-7.23,22.52-23.26,36.1-44.39,44.5Z"
      />
      <path
        fill="#d0c9c1"
        d="M277.99,313.38c-1.83,19.89-3.67,39.78-5.5,59.67-6.56-20.68-5.2-40.61,5.5-59.67Z"
      />
      <path
        fill="#bd6e1b"
        d="M296.1,443.94c5.32,11.22,10.63,22.44,15.95,33.66-2.31,10.56-4.63,21.11-6.94,31.67-1.78,.61-3.59,1.15-5.41,1.61-2.86-19.53-5.71-39.06-8.57-58.58,.29-2.24,.58-4.49,.88-6.73,1.06-1.32,2.42-1.86,4.09-1.63Z"
      />
      <path
        fill="#ece328"
        d="M144.34,529.93c6.06-2.1,12.13-4.2,18.19-6.3,.17,1.43,.07,2.84-.3,4.23,3.88,7.71,7.76,15.42,11.64,23.12-2.22,2.36-4.43,4.72-6.65,7.08-7.63-9.38-15.26-18.76-22.89-28.13Z"
      />
      <path
        fill="#c3bdb5"
        d="M328.49,468.64c12.75,16.41,16.98,32.55-1.47,47.98,.49-15.99,.98-31.99,1.47-47.98Z"
      />
      <path
        fill="#f4b213"
        d="M305.11,509.27c2.31-10.56,4.63-21.11,6.94-31.67-1.97,15.67-3.93,31.35-5.9,47.02-.35-5.12-.7-10.23-1.05-15.35Z"
      />
      <path
        fill="#ece328"
        d="M194.92,563.45c-2.79,2.55-5.57,5.09-8.36,7.64-6.44-4.34-12.88-8.68-19.33-13.02,2.22-2.36,4.43-4.72,6.65-7.08,7.01,4.16,14.03,8.31,21.04,12.47Z"
      />
      <path
        fill="#d0c9c1"
        d="M260.63,303.39c-.24-4.74-.48-9.49-.71-14.23h20.96c-9.01,6.33-14.63,10.28-20.25,14.23Z"
      />
      <path
        fill="#d0c9c1"
        d="M124.21,464.74c-11.69-10.04-9.5-16.49,5.02-19.74-1.67,6.58-3.35,13.16-5.02,19.74Z"
      />
      <path
        fill="#ddd7cf"
        d="M165.83,436.23c5.44,9.65,10.87,19.31,16.31,28.96-5.44-9.65-10.87-19.31-16.31-28.96Z"
      />
      <path
        fill="#d0c9c1"
        d="M165.02,578.95c-13.61,5.79-15.18-2.38-14.68-12.92,4.89,4.31,9.79,8.61,14.68,12.92Z"
      />
      <path
        fill="#f5e727"
        d="M305.11,509.27c.35,5.12,.7,10.23,1.05,15.35,.21,2.01-.39,3.73-1.82,5.15-2.21-2.66-4.41-5.31-6.62-7.97,.66-3.64,1.32-7.28,1.99-10.92,1.83-.46,3.63-1,5.41-1.61Z"
      />
      <path
        fill="#c4bdb5"
        d="M178.63,586.37c4.42,1.93,8.83,3.85,13.25,5.78-7.12,.53-14.24,1.05-21.35,1.58,2.7-2.45,5.4-4.9,8.11-7.35Z"
      />
      <path
        fill="#d0c9c1"
        d="M163.77,593.82c.66-4.89,1.33-9.77,1.99-14.66,4.29,2.4,8.57,4.8,12.86,7.2-2.7,2.45-5.4,4.9-8.11,7.35-2.25,.03-4.5,.07-6.75,.1Z"
      />
      <path
        fill="#ddd7cf"
        d="M277.99,386.32c2.46,4.79,4.91,9.57,7.37,14.36-2.46-4.79-4.91-9.57-7.37-14.36Z"
      />
      <path
        fill="#d0c9c1"
        d="M285.37,294.22c1.25,1.43,2.51,2.85,3.76,4.28-1.92,.56-3.84,1.12-5.76,1.68,.4-2.07,1.07-4.06,2-5.95Z"
      />
      <path
        fill="#ddd7cf"
        d="M163.16,392.72c-.06,2.08-.11,4.15-.17,6.23-.75-2.1-.69-4.17,.17-6.23Z"
      />
      <path
        fill="#13130d"
        d="M299.7,510.88c-.66,3.64-1.32,7.28-1.99,10.92-14.78,13.96-29.56,27.93-44.34,41.89-19.48-.08-38.97-.16-58.45-.25-7.01-4.16-14.03-8.31-21.04-12.47-3.88-7.71-7.76-15.42-11.64-23.12,45.36,28.4,78.32,23.46,111.46-16.71,3.89-13.41,7.78-26.81,11.68-40.22-.17-6.58-.33-13.16-.5-19.74,2.08,.4,4.16,.77,6.26,1.11,2.86,19.53,5.71,39.06,8.57,58.58Z"
      />
      <path
        fill="#7c5f15"
        d="M291.14,452.3c-2.09-.34-4.18-.71-6.26-1.11-.96-1.32-1.93-2.63-2.89-3.95,3.34-.56,6.68-1.11,10.02-1.67-.29,2.24-.58,4.49-.88,6.73Z"
      />
    </svg>
  );
};
