import React, { useEffect } from "react";
import { Loader } from "semantic-ui-react";
import { } from "../../../Components/Client";
import { DataGridBasic, ModalBasic } from "../../../Components/Comons";
import { useDocumentosGut } from "../../../hooks";
import { filterValue } from "./DocumentosGUTFormat";
import { Funciones } from "./Funciones";


export function DocumentosGUTAdmin() {
  const { loading, DocumentosGutFacturacion, getDocumentosGutFacturacion } = useDocumentosGut();
  
  const {
    refetch,
    contentModal,
    titleModal,
    showModal,
    columns,
    openCloseModal,
  } = Funciones();


  useEffect(() => {
    getDocumentosGutFacturacion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const MemoizedModalBasic = React.memo(ModalBasic);
  return (
    <>
      {loading ? (
        <Loader active inline="centered">
          Cargando...
        </Loader>
      ) : (
        <DataGridBasic
          data={DocumentosGutFacturacion?.results}
          title="Documentos GUT"
          id="id_gut"
          filterValue={filterValue}
          columns={columns}
        />
      )}

      {showModal && (
        <MemoizedModalBasic
          show={showModal}
          onClose={openCloseModal}
          title={titleModal}
          children={contentModal}
        />
      )}

    </>
  );
}
//Columnas que se envian en forma de props al componente tabla basic para representer sus campos
