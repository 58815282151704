import { BASE_API } from "../../utils/constants";
import { makeRequest } from "../Request";
const BASE_URL = `${BASE_API}/`;


// Función para obtener los datos de un tipo controladores
export async function getTipoControladorApi(token, schema) {
  const url = `${BASE_URL}${schema}/conexiones/tipo_controladores`;
  return makeRequest(url, "GET", token);
}

// Función para agregar un nuevo tipo controladores
export async function addTipoControladorApi(data, token, schema) {
  console.log('creacion de datos',data) 
  const url = `${BASE_URL}${schema}/conexiones/tipo_controladores/`;
  return makeRequest(url, "POST", token, data);
}

// Función para actualizar los datos de un tipo controladores existente
export async function updateTipoControladorApi(id, data, token, schema) {
  const url = `${BASE_URL}${schema}/conexiones/tipo_controladores/${id}/`;
  return makeRequest(url, "PUT", token, data);
}

// Función para eliminar un tipo controladores
export async function deleteTipoControladorApi(id, token, schema) {
  const url = `${BASE_URL}${schema}/conexiones/tipo_controladores/${id}/`;
  return makeRequest(url, "DELETE", token);
}
