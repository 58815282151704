import { useFormik } from "formik";
import { map } from "lodash";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useDataEmpresa, useProveedores, useDirecciones } from "../../../hooks";
import { alertas } from "../../Comons/Alertas/alertas";

import { FormControl, InputLabel, Select, Grid, FormControlLabel } from '@mui/material';
import { Paper, Container, Button } from '@material-ui/core'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import FormHelperText from '@mui/material/FormHelperText';

export function AddEditProveedores(props) {
  /*
  Componente

  -componente de visualizacion del modal de edicion y creacion para Proveedores
  
  -se crea y valida mediante formik y yup
  -Se obtienen datos para dropdown mediante hooks
  -se obtiene los datos (para edicion) y funciones correspondientes mediante props desde su PageAdmin
   */
  const { Successful } = alertas();
  const { onClose, onRefetch, Proveedores } = props;
  const { addProveedores, updateProveedores } = useProveedores();

  // Destructurar las funciones de manejo de direcciones
  const {
    getCountries,
    getDepartmentsByCountry,
    getCitiesByDepartment,
    countries,
    departments,
    cities,
  } = useDirecciones();

  // Estado para almacenar el país seleccionado
  const [selectedCountry, setSelectedCountry] = useState("");

  // Estado para almacenar el departamento seleccionado
  const [selectedDepartment, setSelectedDepartment] = useState("");

  // Primer useEffect para obtener los países
  useEffect(() => {
    getCountries();
  }, []);

  // Segundo useEffect para obtener los departamentos del país seleccionado
  useEffect(() => {
    // si viene un pais previamente seleccionado
    if (Proveedores?.direccion?.country) {
      getDepartmentsByCountry(Proveedores.direccion?.country);
    }
    // si se selecciona un pais
    if (selectedCountry) {
      getDepartmentsByCountry(selectedCountry);
    }
  }, [selectedCountry]);

  // Tercer useEffect para obtener las ciudades del departamento seleccionado
  useEffect(() => {
    // si viene un departamento previamente seleccionado
    if (Proveedores?.direccion?.department) {
      getCitiesByDepartment(Proveedores.direccion?.department);
    }
    // si se selecciona un departamento
    if (selectedDepartment) {
      getCitiesByDepartment(selectedDepartment);
    }
  }, [selectedDepartment]);


  const formik = useFormik({
    // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
    initialValues: initialValues(Proveedores),
    // Establece la validación del esquema utilizando Yup, si se proporciona un objeto  se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
    validationSchema: Yup.object(Proveedores ? updateSchame() : newSchame()),
    // Desactiva la validación al cambiar los valores del formulario
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        // Si se proporciona un objeto se actualiza, de lo contrario se crea uno nuevo
        if (Proveedores)
          await updateProveedores(Proveedores.id_proveedor, formValue);
        else await addProveedores(formValue);
        Successful()
        onRefetch();
        onClose();
      } catch (error) {
        // Muestra un mensaje de error si ocurre algún problema
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });


  const divStyle = {
    textAlign: "left",
  };

  return (
    <>
      <form className="add-edit-secciones-form" style={divStyle} onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          {/* fila 1 */}
          <Grid item xs={12} sm={4}>
            <TextField
              required
              fullWidth
              label="Celular de contacto"
              name="id_empresa.celular"
              placeholder="celular"
              value={formik.values?.id_empresa.celular}
              onChange={formik.handleChange}
              error={formik.errors.id_empresa?.celular}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Teléfono de contacto"
              name="id_empresa.telefono"
              placeholder="Teléfono"
              value={formik.values?.id_empresa.telefono}
              onChange={formik.handleChange}
              error={formik.errors.id_empresa?.telefono}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              fullWidth
              label="Email"
              name="id_empresa.email"
              placeholder="Empresa@mail.com"
              value={formik.values?.id_empresa.email}
              onChange={formik.handleChange}
              error={formik.errors.id_empresa?.email}
            />
          </Grid>

          {/* fila 2 */}
          <Grid item xs={12} sm={4}>
            <TextField
              required
              fullWidth
              label="NIT del proveedor"
              name="nit_proveedor"
              placeholder="NIT"
              value={formik.values.nit_proveedor}
              onChange={formik.handleChange}
              error={formik.errors.nit_proveedor}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              required
              label="Nombre del proveedor"
              name="nombre_proveedor"
              placeholder="Nombre del proveedor"
              value={formik.values.nombre_proveedor}
              onChange={formik.handleChange}
              error={formik.errors.nombre_proveedor}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Área"
              name="id_empresa.area"
              placeholder="Área"
              value={formik.values?.id_empresa.area}
              onChange={formik.handleChange}
              error={formik.errors.id_empresa?.area}
            />
          </Grid>

          {/* Fila 3 */}
          <Grid item xs={12} sm={4}>
            <TextField
              required
              fullWidth
              label="Dirección"
              name="direccion.street"
              placeholder="Dirección"
              value={formik.values?.direccion.street}
              onChange={formik.handleChange}
              error={formik.errors.direccion?.street}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Código postal"
              name="direccion.zip_code"
              placeholder="Código postal"
              value={formik.values?.direccion.zip_code}
              onChange={formik.handleChange}
              error={formik.errors.direccion?.zip_code}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Datos extra de la dirección"
              name="direccion.extra"
              placeholder="Datos extra"
              value={formik.values?.direccion.extra}
              onChange={formik.handleChange}
              error={formik.errors.direccion?.extra}
              type="text"
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="paislb">País</InputLabel>
              <Select
                labelId="paislb"
                id="pais"
                label="País"
                fullWidth
                value={formik.values?.direccion.country}
                onChange={(event) => {
                  formik.setFieldValue("direccion.country", event.target.value);
                  setSelectedCountry(event.target.value)
                }}
                error={formik.errors.direccion?.country}
              >
                {countries.map((country) => (
                  <MenuItem key={country.id} value={country.id}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="deplb">Departamento</InputLabel>
              <Select
                disabled={!formik.values?.direccion.country}
                labelId="deplb"
                id="dep"
                label="Departamento"
                fullWidth
                value={formik.values?.direccion.department}
                onChange={(event) => {
                  formik.setFieldValue('direccion.department', event.target.value)
                  setSelectedDepartment(event.target.value)
                }}
                error={formik.errors.direccion?.department}
              >
                {departments.map((department) => (
                  <MenuItem key={department.id} value={department.id}>
                    {department.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{!formik.values?.direccion.country ? "Debe seleccionar un país" : ""}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="citylb">Ciudad</InputLabel>
              <Select
                disabled={!formik.values.direccion.department}
                labelId="citylb"
                id="city"
                label="Ciudad"
                fullWidth
                value={formik.values?.direccion.city}
                onChange={(event) => {
                  formik.setFieldValue('direccion.city', event.target.value)
                }}
                error={formik.errors.direccion?.city}
              >
                {cities.map((city) => (
                  <MenuItem key={city.id} value={city.id}>
                    {city.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{!formik.values.direccion.department ? "Debe seleccionar un departamento" : ""}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Button
              type="submit"
              variant="contained"
              className="btn btn-danger"
              fullWidth
            >
              {Proveedores ? "Actualizar" : "Crear"}
            </Button>
          </Grid>
        </Grid>
      </form>


    </>
  );
}



function initialValues(data) {
  return {
    nit_proveedor: data?.nit_proveedor || "",
    nombre_proveedor: data?.nombre_proveedor || "",
    id_empresa: {
      nombre: data?.id_empresa.nombre || "",
      email: data?.id_empresa.email || "",
      celular: data?.id_empresa.celular || "",
      telefono: data?.id_empresa.telefono || "",
      area: data?.id_empresa.area || "",
    },
    direccion: {
      street: data?.direccion.street || "",
      city: data?.direccion.city || "",
      department: data?.direccion.department || "",
      country: data?.direccion.country || "",
      zip_code: data?.direccion.zip_code || "",
      extra: data?.direccion.extra || "",
    },
  };
}

function newSchame() {
  return {
    nit_proveedor: Yup.number().required(true),
    nombre_proveedor: Yup.string().required(true),
    id_empresa: Yup.object({
      // nombre: Yup.string().required("El nombre de al empresa es requerido"),
      email: Yup.string()
        .email("Ingrese un Email valido")
        .required("El campo Email es requerido"),
      celular: Yup.string()
        .required("El campo Celular es requerido")
        .matches(/^[0-9]+$/, "Deben ser digitos")
        .min(8, "La longitud debe ser mayor que 8")
        .max(16, "La longitud debe ser menor que 16"),
      telefono: Yup.number("El dato debe ser numerico"),
      area: Yup.string(),
    }),
    direccion: Yup.object().shape({
      street: Yup.string().required("La calle es requerida"),
      city: Yup.string().required("La ciudad es requerida"),
      department: Yup.string().required("El departamento es requerido"),
      country: Yup.string().required("El país es requerido"),
      zip_code: Yup.string(),
    }),
  };
}

function updateSchame() {
  return {
    nit_proveedor: Yup.number().required(true),
    nombre_proveedor: Yup.string().required(true),
    id_empresa: Yup.object({
      // nombre: Yup.string().required("El nombre de al empresa es requerido"),
      email: Yup.string()
        .email("Ingrese un Email valido")
        .required("El campo Email es requerido"),
      celular: Yup.string()
        .required("El campo Celular es requerido")
        .matches(/^[0-9]+$/, "Deben ser digitos")
        .min(8, "La longitud debe ser mayor que 8")
        .max(16, "La longitud debe ser menor que 16"),
      telefono: Yup.number("El dato debe ser numerico"),
      area: Yup.string(),
    }),
    direccion: Yup.object().shape({
      street: Yup.string().required("La calle es requerida"),
      city: Yup.string().required("La ciudad es requerida"),
      department: Yup.string().required("El departamento es requerido"),
      country: Yup.string().required("El país es requerido"),
      zip_code: Yup.string(),
    }),
  };
}
