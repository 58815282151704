import styled from "styled-components";

export const ContainerData = styled.div`
  margin-top: 4px;
  border: 1px solid ${({ theme }) => theme.primary.red2};
  border-radius: 2px;
  padding: 6px;
`;
export const ContainerDataInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 4px;
  align-items: center;
  margin-top: 10px;
`;
export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
  height: 48px;
  border-right: 1px solid ${({ theme }) => theme.primary.red2};
  padding-right: 8px;

  &:last-child {
    border-right: none;
  }
`;
export const ContainerText = styled.div`
  display: grid;
  grid-template-columns: 2fr 0.5fr 0.5fr;
  grid-gap: 4px;
`;

export const BadgeDataCard = styled.div`
  background-color: ${({ theme }) => theme.primary.white};
  display: block;
  border-radius: 2px;
  color: ${({ theme }) => theme.primary.black};
  font-weight: 700;
  font-size: 14px;
  width: 48px;
  text-align: center;
  position: relative;
  border: 1px solid ${({ theme }) => theme.primary.gray3};
`;
