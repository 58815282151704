import { BASE_API } from "../../utils/constants";
import Swal from 'sweetalert2';


/*
  Peticion fetch

  -Peticiones a la aplicacion Documentos GUT (guia unica de transporte) con 4  funciones asyncronas basicas para CRUD
  -Se importa y se envian el token necesario para cada peticion (token)
    */

  export async function getDocumentosGutApi(token,schema) {
    try {
      // Construye la URL de la API utilizando la constante BASE_API y la ruta específica
      const url = `${BASE_API}/${schema}/documentos/gut`;
      
      // Crea un objeto con los parámetros de la solicitud, incluyendo el token de autorización
      const params = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      
      // Realiza una solicitud GET a la URL especificada utilizando los parámetros creados anteriormente
      const response = await fetch(url, params);
      
      // Convierte la respuesta a un objeto JSON
      const result = await response.json();
      
      // Devuelve el objeto JSON y el objeto de respuesta
      return [result,response];
    } catch (error) {
      // En caso de error, arroja el error
      throw error;
    }
  }
  
  export async function addDocumentosGutApi(data, token,schema) {
    try {
      // Construye la URL de la API utilizando la constante BASE_API y la ruta específica
      const url = `${BASE_API}/${schema}/documentos/gut/`;
      
      // Crea un objeto con los parámetros de la solicitud, incluyendo el token de autorización y los datos en formato JSON
      const params = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
  
      // Realiza una solicitud POST a la URL especificada utilizando los parámetros creados anteriormente
      const response = await fetch(url, params);
      
      // Convierte la respuesta a un objeto JSON
      const result = await response.json();
      
      // Devuelve el objeto JSON y el objeto de respuesta
      return [result,response];
    } catch (error) {
      // En caso de error, arroja el error
      throw error;
    }
  }
  export async function updateDocumentosGutApi(id, data, token,schema) {
    try {
      // Construye la URL de la API utilizando la constante BASE_API, la ruta específica y el id especificado
      const url = `${BASE_API}/${schema}/documentos/gut/${id}/`;
      
      // Crea un objeto con los parámetros de la solicitud, incluyendo el token de autorización, el método PUT y los datos en formato JSON
      const params = {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
  
      // Realiza una solicitud PUT a la URL especificada utilizando los parámetros creados anteriormente
      const response = await fetch(url, params);
      
      // Convierte la respuesta a un objeto JSON
      const result = await response.json();
      
      // Devuelve el objeto JSON y el objeto de respuesta
      return [result,response];
    } catch (error) {
      // En caso de error, arroja el error
      throw error;
    }
  }
  
  export async function deleteDocumentosGutApi(id, token,schema) {
    try {
      // Construye la URL de la API utilizando la constante BASE_API, la ruta específica y el id especificado
      const url = `${BASE_API}/${schema}/documentos/gut/${id}/`;
      
      // Crea un objeto con los parámetros de la solicitud, incluyendo el token de autorización y el método DELETE
      const params = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
  
      // Realiza una solicitud DELETE a la URL especificada utilizando los parámetros creados anteriormente
      const response = await fetch(url, params);
      
      // Convierte la respuesta a un objeto JSON
      const result = await response.json();
      
      // Devuelve el objeto JSON y el objeto de respuesta
      return [result,response];
    } catch (error) {
      // En caso de error, arroja el error
      throw error;
    }
  }

  export async function generarDocumentosGutApi(id, token,schema) {
    const data = {"id":id}
    try {
      // Construye la URL de la API utilizando la constante BASE_API, la ruta específica y el id especificado
      const url = `${BASE_API}/${schema}/documentos/generar/`;
      // Crea un objeto con los parámetros de la solicitud, incluyendo el token de autorización y el método DELETE
      const params = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
  
      // Realiza una solicitud POST a la URL especificada utilizando los parámetros creados anteriormente
      const response = await fetch(url, params);
      const result = await response.json();
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'GUT',
          text: "Generada exitosamente",
        });
      } else {
        if (result.error) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: result.error,
          });
        }

        if (result.message) {
          // Si hay un mensaje de éxito, mostrarlo
          Swal.fire({
            icon: 'error',
            title: 'Transaccion',
            text: result.message,
          });
        } 
  
        
      }



      
      
      // Convierte la respuesta a un objeto JSON
      
      
      // Devuelve el objeto JSON y el objeto de respuesta
      return [result,response];
    } catch (error) {
      // En caso de error, arroja el error
      throw error;
    }
  }

  export async function getDocumentosFacturacionGutApi(token,schema) {
    try {
      // Construye la URL de la API utilizando la constante BASE_API y la ruta específica
      const url = `${BASE_API}/${schema}/documentos/gutfacturador`;
      
      // Crea un objeto con los parámetros de la solicitud, incluyendo el token de autorización
      const params = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      
      // Realiza una solicitud GET a la URL especificada utilizando los parámetros creados anteriormente
      const response = await fetch(url, params);
      
      // Convierte la respuesta a un objeto JSON
      const result = await response.json();
      
      // Devuelve el objeto JSON y el objeto de respuesta
      return [result,response];
    } catch (error) {
      // En caso de error, arroja el error
      throw error;
    }
  }
  