import { useState } from "react";
import {
  getTransaccionesApi,
  addTransaccionesApi,
  updateTransaccionesApi,
  deleteTransaccionesApi,
  retrieveTransaccionesApi
} from "../../api/A_OrdenVenta/transacciones";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "..";

export function useTransacciones() {
  const { auth, logout } = useAuth();
  const { unauthorized, SessionExpired, unauthorizedModal } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    Transacciones: null,
    TransaccionesRTR: null,
    TransaccionesDB: null,
  });

  const { loading, error, Transacciones, TransaccionesRTR, TransaccionesDB } = state;

  const handleResponse = (response) => {
    if (response.status === 401) {
      SessionExpired();
      logout();
    } else if (response.status === 403) {
      unauthorized();
    }
  };

  const getTransaccionesDB = async () => {
    try {
      const [result, response] = await getTransaccionesApi(auth.token, auth.me.tenant);
      setState((prevState) => ({ ...prevState, TransaccionesDB: result.results }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  const getTransacciones = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getTransaccionesApi(auth.token, auth.me.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, Transacciones: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const retrieveTransacciones = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await retrieveTransaccionesApi(id, auth.token, auth.me.tenant);
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false, TransaccionesRTR: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addTransacciones = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addTransaccionesApi(data, auth.token, auth.me.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const updateTransacciones = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateTransaccionesApi(id, data, auth.token, auth.me.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const deleteTransacciones = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteTransaccionesApi(id, auth.token, auth.me.tenant);
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    TransaccionesDB,
    TransaccionesRTR,
    Transacciones,
    getTransaccionesDB,
    getTransacciones,
    addTransacciones,
    updateTransacciones,
    deleteTransacciones,
    retrieveTransacciones,
  };
}
