import React, { useEffect, useState, useCallback } from "react";
import Swal from "sweetalert2"
import { useSuministroStatus } from "../../../hooks";

export function Funciones() {
    
    const [refetch, setRefetch] = useState(false);

    const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);

    return {
        refetch
    }
}