import React, { useEffect, useState } from "react";
import { Loader } from "semantic-ui-react";
import {
  AddEditTransaccionesDescargadero
} from "../../../../Components/Client";
import { DataGridBasic, ModalBasic } from "../../../../Components/Comons";
import { useTransaccionesDescargadero } from "../../../../hooks";
import Swal from "sweetalert2"
export  function TransaccionesDescargaderoAdmin() {
  const { loading, TransaccionesDescargadero, getTransaccionesDescargadero, deleteTransaccionesDescargadero } = useTransaccionesDescargadero();
  const [showModal, setShowModal] = useState(false);
  const [headerModal, setheaderModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    getTransaccionesDescargadero();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);
  const openCloseModal = () => setShowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);

  /// Ejecucion de modal para actualizar datos

  const updateTransaccionesDescargadero = (data) => {
    setheaderModal("Actualizar transaccion");
    setContentModal(
      <AddEditTransaccionesDescargadero
        onClose={openCloseModal}
        onRefetch={onRefetch}
        TransaccionesDescargadero={data}
      />
    );
    openCloseModal();
  };

  /// borrados de datos

  const onDeleteTransaccionesDescargadero = async (data) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })
    
    swalWithBootstrapButtons.fire({
      header: 'Estas seguro?',
      text: "No podras revertir esta accion!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, eliminar!',
      cancelButtonText: 'No, cancelar!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        deleteTransaccionesDescargadero(data.id_transaccion);
        onRefetch();
        swalWithBootstrapButtons.fire(
          'Eliminado!',
          'El dato a sido eliminado.',
          'success'
        )
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelado',
          'Operacion cancelada',
          'error'
        )
      }
    })
  };
  return (
    <>
      {loading ? (
        <Loader active inline="centered">
          Cargando...
        </Loader>
      ) : (
        
        <DataGridBasic
        data={TransaccionesDescargadero.results}
        title="TransaccionesDescargadero"
        id="id_transaccion"
        // filterValue={filterValue}
        columns={columns}
        updateData={updateTransaccionesDescargadero}
        onDeleteData={onDeleteTransaccionesDescargadero}
        
        />
      )}
      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        header={headerModal}
        children={contentModal}
      />
    </>
  )
}

const columns = [
  {
    header: "ID",
    name: "id_transaccion",
  },
  {
    header: "Fecha creación",
    name: "time_created",
  },
  {
    header: "Numero de orden",
    name: "n_orden",
  },
  {
    header: "Volumen restante",
    
    render: (rowData) => {
      let numero = parseFloat(rowData.v_restante);
      return <p className={rowData.v_restante < 0 ? 'negative' : 'positive'}>{numero.toFixed(2)}</p>;
    }
  },
  {
    header: "Volumen entregado",
    name: "v_entregado",
  },
  {
    header: "GSV",
    name: "vol_gsv",
  },
  {
    header: "GOV",
    name: "vol_gov",
  },
  {
    header: "Operador de bahía",
    name: "operador_bahia",
  },
  {
    header: "numero de bahía",
    name: "num_bahia",
  },
  {
    header: "Presión promedio",
    name: "pres_promedio",
  },
  {
    header: "Temperatura promedio",
    name: "temp_promedio",
  },
  {
    header: "Densidad promedio",
    name: "densidad_promedio",
  },
  {
    header: "Temperatura promedio",
    name: "temp_promedio",
  },
  {
    header: "MeterFactor",
    name: "meter_factor",
  },
  {
    header: "CTL",
    name: "ctl",
  },
  {
    header: "CPL",
    name: "cpl",
  },
  {
    header: "CTPL",
    name: "ctpl",
  },
  {
    header: "BSW",
    name: "bsw",
  },
  {
    header: "Gravedad API",
    name: "gravedad_api",
  },
  {
    header: "Numero de transacción",
    name: "num_transaccion",
  },
  {
    header: "Totalizer inicio",
    name: "totalizer_init",
  },
  {
    header: "Totalizer final",
    name: "totalizer_end",
  },
  {
    header: "Fecha inicio de cargue",
    name: "fecha_initcargue",
  },
  {
    header: "Fecha de final del cargue",
    name: "fecha_endcargue",
  },
  {
    header: "NSV",
    name: "nsv",
  },
];

