import { BASE_API } from "../../utils/constants";
import { makeRequest } from "../Request";
const BASE_URL = `${BASE_API}/`;

export async function getMapeosApi(token, schema) {
    const url = `${BASE_URL}${schema}/computadores/bahiasmap`;
    return makeRequest(url, "GET", token);
}

export async function postMapeosApi(data, token, schema) {
    const url = `${BASE_URL}${schema}/computadores/bahiasmap/`;
    return makeRequest(url, "POST", token, data);
}

export async function updtMapeosApi(id_maquina, data, token, schema) {
    const url = `${BASE_URL}${schema}/computadores/bahiasmap/${id_maquina}/`;
    return makeRequest(url, "PATCH", token, data)
}

export async function deleteMapeosApi(id_maquina, token, schema) {
    const url = `${BASE_URL}${schema}/computadores/bahiasmap/${id_maquina}/`;
    return makeRequest(url, "DELETE", token);
}