import React, { useState, useCallback } from "react";
import Swal from "sweetalert2";
import { AddEditControlador, AssignControlador, AddEditPlatesInTcs, AddEditMultLdProds, GetProductos, AddEditHazards, AddEditOtrosDisp } from "../../../Components/Client";
import { useControladores } from "../../../hooks";

export function Funciones() {
  const { deleteControladores, updateControladores } = useControladores();
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);


  const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);

  const handleGetProducto = useCallback((data) => {
    setTitleModal("Obtener productos");
    setContentModal(
      <GetProductos onClose={openCloseModal} onRefetch={onRefetch} data={data} />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const handleAddControladores = useCallback(() => {
    setTitleModal("Nuevo controlador");
    setContentModal(
      <AddEditControlador onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const handleUpdateControladores = useCallback((data) => {
    setTitleModal("Actualizar controlador");
    setContentModal(
      <AddEditControlador
        onClose={openCloseModal}
        onRefetch={onRefetch}
        Controladores={data}
      />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const handleAssignTo = useCallback(async (data) => {
    setTitleModal("Asignar");
    setContentModal(
      <AssignControlador
        onClose={openCloseModal}
        onRefetch={onRefetch}
        Controladores={data}
      />
    )
    openCloseModal();
  }, [openCloseModal, onRefetch])

  const handleAddEditPlacasInTcs = useCallback(async (data) => {
    setTitleModal("Guardar placas en TCS3000")
    setContentModal(
      <AddEditPlatesInTcs
        onClose={openCloseModal}
        onRefetch={onRefetch}
        Controladores={data}
      />
    )
    openCloseModal();
  }, [openCloseModal, onRefetch])

  const handleDeleteControladores = useCallback(async (data) => {
    const result = await Swal.fire({
      title: "¿Estás seguro?",
      text: "¡No podrás revertir esta acción!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "No, cancelar",
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      await deleteControladores(data.id_controlador);
      Swal.fire("¡Eliminado!", "El dato ha sido eliminado.", "success");
      onRefetch();
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelado", "Operación cancelada", "error");
    }
  }, [deleteControladores, onRefetch]);

  const setProductMultLd = useCallback(async (data) => {
    setTitleModal("Añadir productos al MultiLoad")
    setContentModal(
      <AddEditMultLdProds
        onClose={openCloseModal}
        onRefetch={onRefetch}
        Controladores={data}
      />
    )
    openCloseModal();
  }, [openCloseModal, onRefetch])

  const addEditHazards = useCallback(async (data) => {
    setTitleModal("Revisar indicaciones de peligro")
    setContentModal(
      <AddEditHazards
        onClose={openCloseModal}
        onRefetch={onRefetch}
        Data={data}
      />
    )
    openCloseModal();
  }, [openCloseModal, onRefetch])

  const handleAddOtrosDisp = useCallback(() => {
    setTitleModal("Nuevo dispositivo externo");
    setContentModal(
      <AddEditOtrosDisp onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const handleEditOtrosDisp = useCallback((data) => {
    setTitleModal("Actualizar dispositivo externo");
    setContentModal(
      <AddEditOtrosDisp onClose={openCloseModal} onRefetch={onRefetch} Controladores={data} />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const activateCalMode = useCallback(async (data) => {
    const result = await Swal.fire({
      title: "¿Dejar el controlador para calibración?",
      text: "Esta acción desactivará el controlador del control automático, se deberá restablecer el estado del controlador manualmente luego",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, desactivar",
      cancelButtonText: "No, cancelar",
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      data.estado = false
      await updateControladores(data.id_controlador, data);
      Swal.fire("Desactivado...", "El controlador ha sido desactivado", "success");
      onRefetch();
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelado", "Operación cancelada", "error");
    }
  }, [openCloseModal, onRefetch]);

  return {
    openCloseModal,
    refetch,
    contentModal,
    titleModal,
    showModal,
    handleAddControladores,
    handleAddEditPlacasInTcs,
    handleDeleteControladores,
    handleUpdateControladores,
    handleGetProducto,
    handleAssignTo,
    setProductMultLd,
    addEditHazards,
    handleAddOtrosDisp,
    handleEditOtrosDisp,
    activateCalMode,
  };
}
