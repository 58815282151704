import React, { useEffect, useState } from "react";
// import { Tabs, Tab } from '@material-ui/core';
// import Tabs from "@mui/material/Tabs"
// import Tab from "@mui/material/Tab"
import { Tab } from 'semantic-ui-react'

import { ControladoresAdmin, GroupedSensores, OtrosDispAdmin, TipoControladorAdmin } from "../index"

export function GroupedControladores() {
    const panes = [
        {
            menuItem: { key: 'ctrl', content: <>Controladores</> },
            render: () => <Tab.Pane><ControladoresAdmin /></Tab.Pane>,
        },
        {
            menuItem: { key: 'tipoctrl', content: <>Tipos de controladores</> },
            render: () => <Tab.Pane><TipoControladorAdmin /></Tab.Pane>,
        },
        {
            menuItem: { key: 'disp', content: <>Otros dispositivos</> },
            render: () => <Tab.Pane><OtrosDispAdmin /></Tab.Pane>,
        },
    ]

    return (
        <>
            <Tab panes={panes} />
        </>
    )
}