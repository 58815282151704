import React, { useCallback, useState } from "react";
import { } from "../../../Components/Client";

export function Funciones() {

  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);

  const abrirExcel = (link) => {
    window.open(link, "_blank");
  };
  


  const columns = [
    // {
    //   header: "ID",
    //   name: "idIncremental",
    //   maxWidth: 1000, defaultFlex: 1
    // },
    {
      header: "Numero de orden",
      name: "numero_orden",
      maxWidth: 1000, defaultFlex: 1
    },
    
    {
      header: "GUT",
      render: (rowData) => (
        <button
          style={{
            width: "30px",
            height: "30px",
            borderRadius: 0,
            backgroundColor: "transparent",
            border: "none",
          }}
          className="btn btn-primary"
          onClick={() => abrirExcel(rowData.data.documento)}
        >
          <i
            style={{ fontSize: "20px", color: "black" }}
            className="fa fa-print"
          />
        </button>
      ),
      maxWidth: 1000, defaultFlex: 1
    },
    
  ];



  

  return {
    abrirExcel,
    openCloseModal,
    columns,
    refetch,
    contentModal,
    titleModal,
    showModal,
  };
}
