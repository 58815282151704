import React, { useEffect } from "react";
import { HeaderPage } from "../../../Components/Client";
import { DataGridBasic, ModalBasic } from "../../../Components/Comons";
import { useCliente } from "../../../hooks";
import { columns, filterValue } from "./ClientesFormat";
import { Funciones } from "./Funciones";
import CircularProgress from '@mui/material/CircularProgress';

export function ClientesAdmin() {
  const { loading, Cliente, getCliente } = useCliente();
  const {
    refetch,
    contentModal,
    titleModal,
    showModal,
    handleAddClientes,
    handleDeleteClientes,
    handleUpdateClientes,
    openCloseModal,
  } = Funciones();

  useEffect(() => {
    getCliente();
  }, [refetch]);

  const MemoizedModalBasic = React.memo(ModalBasic);


  return (
    <>
      <HeaderPage
        title="Socios de negocios"
        btnTitle="Nuevo socio"
        icono="plus square icon"
        btnClick={handleAddClientes}
      />
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <DataGridBasic
          data={Cliente?.results}
          title="Socios de negocio"
          id="id_cliente"
          filterValue={filterValue}
          columns={columns}
          updateData={handleUpdateClientes}
          onDeleteData={handleDeleteClientes}
        />
      )}

      {showModal && (
        <MemoizedModalBasic
          show={showModal}
          onClose={openCloseModal}
          title={titleModal}
          children={contentModal}
        />
      )}
    </>
  );
}
