export const columns = [
  
    {
      header: "Nombre tipo",
      name: "nombre_tipo",
      maxWidth: 1000, defaultFlex: 1
    },
    
    {
        header: "Descripcion",
        name: "descripcion",
        maxWidth: 1000, defaultFlex: 1
      },

    
  ];

export const filterValue = [
    { name: 'nombre_tipo', operator: 'startsWith', type: 'string', value: '' },
  
  ];


  