import React, { useEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { DataCard } from "../../../../components/DataCard";
import { ASGI_API, presentState } from "../../../../utils/constants";
import {
  ContainerBadge,
  ContainerBomb,
  ContainerCarTank,
  ContainerCardDetail,
  ContainerCircle,
  ContainerHeader,
  ContainerImages,
  ContainerImagesTank,
  ContainerTank,
  ContainerTankComplete,
  SpanStyle,
  Text,
  Title,
} from "./styles";
import { LeftOutlined } from "@ant-design/icons";
import { IconAlert } from "../../../../components/IconsAlert";
import { Badge, Circle } from "../../../../components/common";
import { CardLeft } from "../../../../components/icons/card-left";
import { TableAlert } from "../../../../components/TableAlert";
import { StateBombFail } from "../../../../components/icons/state_bomb_fail";
import { StateBombDone } from "../../../../components/icons/state_bomb_done";
import { IconTankSamll } from "../../../../components/IconTankSmall";
import { theme } from "../../../../theme";
import { Popover } from "antd";
import { PopoverContent } from "../../../../components/PopoverContent";
import { getReceiptDatosId } from "../../../../API/ProcesosApi";
import { Spinner } from "../../../../components/Spinner";

export const CardDetailReceipt = () => {
  const [data, setData] = useState([]);
  const [showBlinkState, setShowBlinkState] = useState(false);
  const [datosIdReceipt, setDatosIdReceipt] = useState({});
  const [loading, setLoading] = useState(false);

  // this useMemo is for get the id of the url
  const idUrl = useMemo(() => {
    const url = window.location.pathname.split("/");
    return url[url.length - 1];
  }, []);

  // this useEffect is for get the data of the receipt
  useEffect(() => {
    if (idUrl === null) return;

    // Construir la URL de la conexión WebSocket
    let url = `${ASGI_API}/data/descargadero`;
    if (idUrl !== null) {
      url += `?bay=${idUrl}`;
    }

    // Crear una instancia del objeto WebSocket
    const socket = new WebSocket(url);
    setLoading(true);

    // Escuchar el evento 'message' para recibir los datos enviados por el servidor
    socket.addEventListener("message", (event) => {
      setData(JSON.parse(event.data));
      setLoading(false);
    });

    // Devolver una función que se ejecutará cuando el componente se desmonte
    return () => {
      // Cerrar la conexión WebSocket
      socket.close();
    };
  }, [idUrl]);

  const dataObject = useMemo(() => data[0], [data]);

  // this useMemo is for get the percent of the level of the tank
  const percentLevelDes = useMemo(() => {
    const percent = dataObject?.read_reg?.GOV_RT / dataObject?.read_reg?.VOLP_RT;
    return percent.toFixed() !== "NaN" ? percent.toFixed() : 0;
  }, [dataObject]);

  // this useMemo change showBlinkState for change appears and disappears
  useEffect(() => {
    const interval = setInterval(() => {
      setShowBlinkState(true);
      setTimeout(() => {
        setShowBlinkState(false);
      }, 1000);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  return (
    <ContainerCardDetail>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Popover
            placement="bottomRight"
            content={<PopoverContent data={datosIdReceipt} />}
            trigger="hover"
          >
            <div
              onMouseEnter={async () => {
                await getReceiptDatosId(dataObject?.read_reg?.ORDEN_N).then(
                  (r) => setDatosIdReceipt(r?.data.results[0])
                );
              }}
            >
              <ContainerHeader>
                <NavLink
                  to={"/receipt"}
                  style={{
                    marginRight: "85px",
                  }}
                >
                  <LeftOutlined />
                </NavLink>
                <Title>
                  Bahia <span>{dataObject?.BAY ?? ""}</span>
                </Title>
                <div>
                  <Text fontSize={14}>
                    No ORDEN <span>{dataObject?.read_reg?.ORDEN_N ?? ""}</span>
                  </Text>
                </div>
              </ContainerHeader>
              <ContainerImages>
                <IconAlert
                  data={dataObject}
                  top={18}
                  size={15}
                  width={40}
                  height={52}
                />
                <ContainerTankComplete>
                  <ContainerImagesTank>
                    <ContainerBadge>
                      {" "}
                      <Badge fontSize={18}>
                        {presentState(dataObject?.read_reg?.PRESET_STATE)}
                      </Badge>
                    </ContainerBadge>
                    <ContainerTank>
                      <CardLeft />
                      <ContainerCarTank
                        percent={percentLevelDes}
                      ></ContainerCarTank>
                      <SpanStyle>{percentLevelDes}%</SpanStyle>
                    </ContainerTank>
                  </ContainerImagesTank>
                  <IconTankSamll percent={dataObject?.level_tank} />
                  <ContainerBomb
                    showBlink={showBlinkState}
                    done={dataObject?.read_reg?.PUMP_ST === 1}
                  >
                    {dataObject?.read_reg?.PUMP_ST === 1 ? (
                      <StateBombDone />
                    ) : (
                      <StateBombFail />
                    )}
                  </ContainerBomb>
                </ContainerTankComplete>
                <ContainerCircle>
                  <Circle
                    color={
                      dataObject?.read_reg?.RCU_STATE === 0
                        ? theme.default.primary.red
                        : theme.default.primary.green
                    }
                    size={25}
                  />
                </ContainerCircle>
              </ContainerImages>
              <DataCard
                data={dataObject}
                title={"Datos Observados"}
                dataOneTitle={"Vol.indicado"}
                dataOneInfo={dataObject?.read_reg?.IV_DEV}
                dataOneUnit={"gal"}
                dataTwoTitle={"GSV"}
                dataTwoInfo={dataObject?.read_reg?.GSV_RT}
                dataTwoUnit={"gal"}
                dataThreeTitle={"v.preset"}
                dataThreeInfo={dataObject?.read_reg?.VOLP_RT}
                dataThreeUnit={"gal"}
                dataFourTitle={"Gov"}
                dataFourInfo={dataObject?.read_reg?.GOV_RT}
                dataFourUnit={"gal"}
                dataFiveTitle={"Nsv"}
                dataFiveInfo={dataObject?.read_reg?.NSV_RT}
                dataFiveUnit={"gal"}
                dataSixTitle={"v.restante"}
                dataSixInfo={dataObject?.read_reg?.VOLR_RT}
                dataSixUnit={"gal"}
              />
              <DataCard
                title={"Datos de campo"}
                dataOneTitle={"Temperatura"}
                dataOneInfo={dataObject?.read_reg?.TEMP_RT}
                dataOneUnit={"°F"}
                dataTwoTitle={"Flujo"}
                dataTwoInfo={dataObject?.read_reg?.FLOW_RT}
                dataTwoUnit={"gal/m"}
                dataThreeTitle={"Densidad"}
                dataThreeInfo={dataObject?.read_reg?.DENS_RT}
                dataThreeUnit={"Kg/m3"}
                dataFourTitle={"Presion"}
                dataFourInfo={dataObject?.read_reg?.PRES_RT}
                dataFourUnit={"Psi"}
              />
              <DataCard
                title={"Factores de corrección"}
                dataOneTitle={"CTL"}
                dataOneInfo={dataObject?.read_reg?.CTL_RT}
                dataTwoTitle={"CPL"}
                dataTwoInfo={dataObject?.read_reg?.CPL_RT}
                dataThreeTitle={"CTPL"}
                dataThreeInfo={dataObject?.read_reg?.CTPL_RT}
                dataFourTitle={"Gravedad Api"}
                dataFourInfo={dataObject?.read_reg?.GAPI_RT}
                dataFiveTitle={"Bsw (%)"}
                dataFiveInfo={dataObject?.read_reg?.BSW_RT}
              />
            </div>
          </Popover>

          <TableAlert data={dataObject} />
        </>
      )}
    </ContainerCardDetail>
  );
};
