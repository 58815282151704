import {
    Box,
    Grid,
    Stack,
    Typography
} from '@mui/material';
import { GenerarLicencias } from '../../Licencia';
import { MulticlienteAdmin } from '../MulticlienteAdmin';
import { TenantAdmin } from '../TenantAdmin';
import MainCard from '../../../Components/Comons/MainCard'



export function MulticlienteTab() {

    return (
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            {/* row 1 */}
            <Grid item xs={12} sx={{ mb: -2.25 }}>
                <Typography variant="h5">Administracion de licencias</Typography>
            </Grid>


            <Grid item md={8} sx={{ display: { sm: 'none', md: 'block', lg: 'none' } }} />

            {/* row 2 */}
            <Grid item xs={12} md={7} lg={8}>
                <Grid container alignItems="center" justifyContent="space-between">

                    <Grid item>

                    </Grid>
                </Grid>
                <MainCard content={false} sx={{ mt: 1.5 }}>
                    <Box sx={{ pt: 1, pr: 2 }}>

                        <MulticlienteAdmin />
                    </Box>
                </MainCard>
            </Grid>
            <Grid item xs={12} md={5} lg={4}>
                <Grid container alignItems="center" justifyContent="space-between">

                    <Grid item />
                </Grid>
                <MainCard sx={{ mt: 2 }} content={false}>
                    <Box sx={{ p: 3, pb: 0 }}>
                        <Stack spacing={2}>
                            <Typography variant="h6" color="textSecondary">
                                Schemas
                            </Typography>

                        </Stack>
                    </Box>
                    <TenantAdmin />

                </MainCard>

                <Grid container alignItems="center" justifyContent="space-between">

                    <Grid item />
                </Grid>
                <MainCard sx={{ mt: 2 }} content={false}>
                    <Box sx={{ p: 3, pb: 0 }}>
                        <Stack spacing={2}>
                            <Typography variant="h6" color="textSecondary">
                                Licencias
                            </Typography>

                        </Stack>
                    </Box>
                    <GenerarLicencias />

                </MainCard>
            </Grid>



        </Grid>
    );
}

