import { useFormik } from "formik";
import React from "react";
import { toast } from "react-toastify";
import { Button, Form,Label,Segment, } from "semantic-ui-react";
import * as Yup from "yup";
import { useDecimales } from "../../../hooks";
import { alertas } from "../../Comons/Alertas/alertas";









export  function AddEditParamatrizacionDecimales(props) {
  
   /*
  Componente

  -componente de visualizacion del modal de edicion y creacion para ParametrizacionDecimales
  
  -se crea y valida mediante formik y yup
  -Se obtienen datos para dropdown mediante hooks
  -se obtiene los datos (para edicion) y funciones correspondientes mediante props desde su PageAdmin
   */

  const { onClose, onRefetch, decimales } = props;

  const { updateDecimales } = useDecimales();
  const { Successful } = alertas();
 
  

  



  const formik = useFormik({
    // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
    initialValues: initialValues(decimales),
    // Establece la validación del esquema utilizando Yup, si se proporciona un objeto  se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
    validationSchema: Yup.object(decimales ? updateSchame() : false),
     // Desactiva la validación al cambiar los valores del formulario
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
         // Si se proporciona un objeto se actualiza, de lo contrario se crea uno nuevo
        if (decimales) await updateDecimales(decimales.id_parametrizacion, formValue);
        else await true;
        Successful()
        onRefetch();
        onClose();
      } catch (error) {
        // Muestra un mensaje de error si ocurre algún problema
        toast.error(error.message, {
          position: "top-center",
          });
      }
    },
  });
  return (
    <Form className="add-edit-secciones-form" onSubmit={formik.handleSubmit}>
      
      <Segment padded>
        <Label attached="top">Numero de decimales TOV</Label>
      <Form.Input
      required
        name="tov_decimales"
        placeholder="Decimales TOV"
        value={formik.values.tov_decimales}
        onChange={formik.handleChange}
        error={formik.errors.tov_decimales}
      />
      </Segment>
      <Segment padded>
        <Label attached="top">Numero de decimales FW</Label>
      <Form.Input
      required
        name="fw_decimales"
        placeholder="Decimales FW"
        value={formik.values.fw_decimales}
        onChange={formik.handleChange}
        error={formik.errors.fw_decimales}
      />
      </Segment>
      <Segment padded>
        <Label attached="top">Numero de decimales CTSh</Label>
      <Form.Input
      required
        name="ctsh_decimales"
        placeholder="Decimales CTSh"
        value={formik.values.ctsh_decimales}
        onChange={formik.handleChange}
        error={formik.errors.ctsh_decimales}
      />
      </Segment>
      <Segment padded>
        
        <Label attached="top">Numero de decimales GSV</Label>
      <Form.Input
      required
        name="gsv_decimales"
        placeholder="Decimales GSV"
        value={formik.values.gsv_decimales}
        onChange={formik.handleChange}
        error={formik.errors.gsv_decimales}
      />
      </Segment>
      <Segment padded>
        <Label attached="top">Numero de decimales CSW </Label>
      <Form.Input
      required
        name="csw_decimales"
        placeholder="Decimales CSW"
        value={formik.values.csw_decimales}
        onChange={formik.handleChange}
        error={formik.errors.csw_decimales}
      />
      </Segment>
      <Segment padded>
        <Label attached="top">Numero de decimales FRA</Label>
      <Form.Input
      required
        name="fra_decimales"
        placeholder="Decimales FRA"
        value={formik.values.fra_decimales}
        onChange={formik.handleChange}
        error={formik.errors.fra_decimales}
      />
      </Segment>
      <Segment padded>
        <Label attached="top">Numero de decimales GOV</Label>
      <Form.Input
      required
        name="gov_decimales"
        placeholder="Decimales GOV"
        value={formik.values.gov_decimales}
        onChange={formik.handleChange}
        error={formik.errors.gov_decimales}
      />
      </Segment>

      <Button
        type="submit"
        // primary
        className="btn btn-danger"
        fluid
        content={decimales ? "Actualizar" : "Crear"}
      />
    </Form>
  )
}

function initialValues(data) {
  return {
    tov_decimales: data?.tov_decimales || "",
    fw_decimales: data?.fw_decimales || "",
    ctsh_decimales: data?.ctsh_decimales || "",
    gsv_decimales: data?.gsv_decimales || "",
    csw_decimales: data?.csw_decimales || "",
    fra_decimales: data?.fra_decimales || "",
    gov_decimales: data?.gov_decimales || "",
    
  };
}
function updateSchame() {
  return {
    tov_decimales: Yup.number().required(true),
    fw_decimales: Yup.number().required(true),
    ctsh_decimales: Yup.number().required(true),
    gsv_decimales: Yup.number().required(true),
    csw_decimales: Yup.number().required(true),
    fra_decimales: Yup.number().required(true),
    gov_decimales: Yup.number().required(true),
    
  };
}