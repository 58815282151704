export const columns = [
    {
        header: 'ID',
        name: 'id_fracciones',
        maxWidth: 1000, defaultFlex: 1
    },
    {
        header: 'Nombre',
        name: 'nombre',
        maxWidth: 1000, defaultFlex: 1
    },
    
   
  ]

  export const filterValue = [
    { name: 'id_fracciones', operator: 'startsWith', type: 'string', value: '' },
    { name: 'nombre', operator: 'startsWith', type: 'string', value: '' },
    
    
  ];