import { Warning } from '@material-ui/icons'
import React from 'react'
import Swal from "sweetalert2"
export function alertas() {
  //Alerta para opercaiones exitosas 
  const Successful = () => {

    Swal.fire({

      icon: 'success',
      title: 'Operacion exitosa',
      showConfirmButton: false,
      timer: 1500
    })
  }

  //Warning para acciones no autorizadas 
  const unauthorized = () => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'No tienes acceso a esta area, comunicate con tu administrador!',
      // footer: '<a href="">Why do I have this issue?</a>'
    })


  }
  //Warning para acciones no autorizadas
  const unauthorizedModal = () => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'No tienes permiso para realizar esta acción, comunicate con tu administrador!',
      // footer: '<a href="">Why do I have this issue?</a>'
    })


  }
  //Warning para sesion expirada
  const SessionExpired = () => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Tu session a vencido porfavor inicia session nuevamente!',
      // footer: '<a href="">Why do I have this issue?</a>'
    })

  }
  //Errores generales
  const errores = () => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Error de operacion!',
      // footer: '<a href="">Why do I have this issue?</a>'
    })
  }
  //Modal para eliminar
  const Eliminar = () => {
    Swal.fire({
      title: 'Estas seguro?',
      text: "No podras revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!'
    }).then((result) => {
      if (result.isConfirmed) {
        return true
      }
    })
  }


  return {
    unauthorized,
    Successful,
    SessionExpired,
    unauthorizedModal,
    errores,
    Eliminar
  };
}
