import React, { useState, useCallback } from "react";
import Swal from "sweetalert2";
import { AddEditConciliaciones } from "../../../Components/Client";
import { useConciliaciones } from "../../../hooks";

export function Funciones() {

  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);


  return {
    openCloseModal,
    refetch,
    contentModal,
    titleModal,
    showModal,
  };
}
