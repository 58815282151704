// import { IconButton } from "@material-ui/core";
// import Tooltip from "@material-ui/core/Tooltip";
// import HttpsIcon from "@mui/icons-material/Https";
// import Avatar from "@mui/material/Avatar";
import React, { useCallback, useState } from "react";
import Swal from "sweetalert2";
import { AddEditUserForm, EditPermisos } from "../../../Components/Client";
import { VisualizacionGeneralForm } from "../../../Components/Comons";
import { useUser } from "../../../hooks";
import "./UserAdmin.scss";

export function Funciones() {
  const { deleteUser } = useUser();
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);

  const handleAdd = useCallback(() => {
    setTitleModal("Nuevo usuario");
    setContentModal(
      <AddEditUserForm onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const handleUpdate = useCallback(
    (data) => {
      setTitleModal("Actualizar usuario");
      setContentModal(
        <AddEditUserForm
          onClose={openCloseModal}
          onRefetch={onRefetch}
          user={data}
        />
      );
      openCloseModal();
    },
    [openCloseModal, onRefetch]
  );

  const handlePermisos = useCallback(
    (data) => {
      setTitleModal("Grupos de permisos");
      setContentModal(
        <EditPermisos
          onClose={openCloseModal}
          onRefetch={onRefetch}
          user={data}
        />
      );
      openCloseModal();
    },
    [openCloseModal, onRefetch]
  );

  const handleViews = useCallback(
    (data) => {
      setTitleModal("Vista general");
      setContentModal(
        <VisualizacionGeneralForm
          onClose={openCloseModal}
          onRefetch={onRefetch}
          visualizacion={data}
        />
      );
      openCloseModal();
    },
    [openCloseModal, onRefetch]
  );

  const handleDelete = useCallback(
    async (data) => {
      const result = await Swal.fire({
        title: "Estas seguro?",
        text: "No podrás revertir esta acción!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, eliminar!",
        cancelButtonText: "No, cancelar!",
        reverseButtons: true,
      });

      if (result.isConfirmed) {
        await deleteUser(data.id_usuario);
        Swal.fire("Eliminado!", "El dato ha sido eliminado.", "success");
        onRefetch();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelado", "Operación cancelada", "error");
      }
    },
    [deleteUser, onRefetch]
  );

  const columns = [
    // {
    //   header: "Foto",
    //   name: "foto",
    //   render: ({ data }) => {
    //     return (
    //       <>
    //         <Avatar alt="nn" src={data.foto} />
    //       </>
    //     );
    //   },
    //   maxWidth: 70,
    //   defaultFlex: 1,
    // },
    {
      header: "Nombre",
      name: "nombres",
      maxWidth: 1000,
      defaultFlex: 1,
    },
    {
      header: "Apellidos",
      name: "apellidos",
      maxWidth: 1000,
      defaultFlex: 1,
    },
    {
      header: "Identificación",
      name: "identificacion_pers",
      maxWidth: 1000,
      defaultFlex: 1,
    },
    {
      header: "Email",
      name: "email",
      maxWidth: 1000,
      defaultFlex: 1,
    },
    {
      header: "Celular",
      name: "celular",
      maxWidth: 1000,
      defaultFlex: 1,
    },
    // {
    //   header: "Permisos",

    //   render: ({ data }) => (
    //     <>
    //       <Tooltip title="Permisos" arrow>
    //         <IconButton onClick={() => handlePermisos(data)}>
    //           <HttpsIcon />
    //         </IconButton>
    //       </Tooltip>
    //     </>
    //   ),
    //   maxWidth: 150,
    //   defaultFlex: 1,
    // },
  ];

  return {
    handleAdd,
    handleDelete,
    handleUpdate,
    openCloseModal,
    handleViews,
    handlePermisos, 
    columns,
    refetch,
    contentModal,
    titleModal,
    showModal,
  };
}
