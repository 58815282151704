import React from 'react';
import * as Yup from 'yup';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';

import { toast } from "react-toastify";
import { useValidaciones } from "../../../hooks";
import { alertas } from "../../Comons/Alertas/alertas";
import { useFormik } from "formik";

export function AddEditValidacion(props) {
  const { onClose, onRefetch, Datos } = props;

  const { updateValidaciones } = useValidaciones();
  const { Successful } = alertas();

  const formik = useFormik({
    initialValues: initialValues(Datos),
    validationSchema: Yup.object(newSchema()),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        await updateValidaciones(Datos.id, formValue);
        Successful();
        onRefetch();
        onClose();
      } catch (error) {
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });

  return (
    <Box
      component="form"
      className="add-edit-secciones-form"
      onSubmit={formik.handleSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            name="nombre"
            label="Nombre"
            fullWidth
            value={formik.values.nombre}
            onChange={formik.handleChange}
            error={formik.touched.nombre && Boolean(formik.errors.nombre)}
            helperText={formik.touched.nombre && formik.errors.nombre}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControlLabel
            control={
              <Checkbox
                name="conductor"
                checked={formik.values.conductor}
                onChange={formik.handleChange}
              />
            }
            label="Conductor"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControlLabel
            control={
              <Checkbox
                name="placa_vehiculo"
                checked={formik.values.placa_vehiculo}
                onChange={formik.handleChange}
              />
            }
            label="Placa Vehículo"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControlLabel
            control={
              <Checkbox
                name="placa_remolque"
                checked={formik.values.placa_remolque}
                onChange={formik.handleChange}
              />
            }
            label="Placa Remolque"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControlLabel
            control={
              <Checkbox
                name="transportadora"
                checked={formik.values.transportadora}
                onChange={formik.handleChange}
              />
            }
            label="Transportadora"
          />
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12}>
        <Button
          type="submit"
          variant="contained"
          color="error"
          className="btn btn-danger"
          fullWidth
        >
          {Datos ? "Actualizar" : "Crear"}
        </Button>
      </Grid>
    </Box>
  );
}

function initialValues(data) {
  return {
    nombre: data?.nombre || "",
    conductor: data?.conductor || false,
    placa_vehiculo: data?.placa_vehiculo || false,
    placa_remolque: data?.placa_remolque || false,
    transportadora: data?.transportadora || false,
  };
}

function newSchema() {
  return {
    nombre: Yup.string().required('Este campo es obligatorio'),
  };
}
