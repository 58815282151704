import { useFormik } from "formik";
import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";

import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import { Autocomplete } from '@mui/material'

import Button from "@mui/material/Button"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import Grid from "@mui/material/Grid"
import MenuItem from "@mui/material/MenuItem"
import TextField from "@mui/material/TextField"
import SearchIcon from "@mui/icons-material/Search";

import markerIcon from "../../../assets/marker.png"

import * as Yup from "yup";
import { alertas } from "../../Comons/Alertas/alertas";

import { useSensores, useTanques, useControladores } from "../../../hooks";

const inputRangeOpts = [
    { key: '1', value: '1', text: '-10 - 20V' },
    { key: '2', value: '2', text: '0 - 10V' },
    { key: '3', value: '3', text: '0 - 5V' },
    { key: '4', value: '4', text: '-5 - 5V' },
    { key: '5', value: '5', text: '0 - 20mA' },
    { key: '6', value: '6', text: '0 - 24mA' },
    { key: '7', value: '7', text: '4 - 20mA' },
    { key: '8', value: '8', text: '-25 - 25mA' },
]

const moduloOpts = [
    { key: '1', value: '1', text: 'Entrada 1 - InputValue1' },
    { key: '2', value: '2', text: 'Entrada 2 - InputValue2' },
    { key: '3', value: '3', text: 'Entrada 3 - InputValue3' },
    { key: '4', value: '4', text: 'Entrada 4 - InputValue4' },
    { key: '5', value: '5', text: 'Entrada 5 - InputValue5' },
]

export function AddEditSensores(props) {

    const { onClose, onRefetch, Sensores } = props;
    const { addSensores, updateSensores, EtiquetaSensores, getEtiquetaSensores } = useSensores()
    const { tanques, getTanques } = useTanques()
    const { Controladores, getControladores } = useControladores()
    const { Successful } = alertas();
    const [selectedCtrl, setSelectedCtrl] = useState()

    // campo de búsqueda para la ubicación del mapa
    const [initialValuesMap, setInitialValuesMap] = useState({
        searchQuery: '',
    })

    const [tipoSensor, setTipoSensor] = useState("")
    const [tagData, setTagData] = useState()

    const [mapKey, setMapKey] = useState(0)

    const customIcon = L.icon({
        iconUrl: markerIcon,
        iconSize: [32, 32], // tamaño del icono
        iconAnchor: [16, 32], // anclaje del icono
    });

    useEffect(() => {
        getEtiquetaSensores()
        getControladores()
        getTanques()
    }, [])

    useEffect(() => {
        if (Sensores?.etiquetaSensorData) {
            setTipoSensor(Sensores.etiquetaSensorData.tipo)
        } else {
            setTipoSensor("1")
        }
    }, [Sensores])

    useEffect(() => {
        if (Sensores) {
            setSelectedCtrl(Sensores.controlador_data)
        }
    }, [Controladores])

    const formik = useFormik({
        // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
        initialValues: initialValues(Sensores),
        // Establece la validación del esquema utilizando Yup, si se proporciona un objeto  se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
        validationSchema: Yup.object(Sensores ? updateSchame() : newSchame()),
        // Desactiva la validación al cambiar los valores del formulario
        validateOnChange: false,
        onSubmit: async (formValue) => {
            try {
                formValue.latitud = parseFloat(formValue.latitud);
                formValue.longitud = parseFloat(formValue.longitud);
                formValue.rango_max = parseFloat(formValue.rango_max);
                formValue.rango_min = parseFloat(formValue.rango_min);
                formValue.puerto = selectedCtrl.ip + ':' + formValue.puerto

                // Si se proporciona un objeto se actualiza, de lo contrario se crea uno nuevo
                if (Sensores) await updateSensores(Sensores.id_sensor, formValue);
                else await addSensores(formValue);
                Successful();
                onRefetch();
                onClose();
            } catch (error) {
                // Muestra un mensaje de error si ocurre algún problema
                if (error?.message) {
                    toast.error(error.message, {
                        position: "top-center",
                    });
                } else {
                    onClose();
                }
            }
        },
    });

    // función para manejar el cambio de posición del marcador cuando se arrastra
    const handleMarkerMove = async (event) => {
        const newPosition = event.target.getLatLng(); // obtiene la nueva posición de maracador

        setMarkerPosition([newPosition.lat, newPosition.lng]); // catualiza el estado de la posición de maracador
        formik.setFieldValue('latitud', newPosition.lat); // actualiza el campo de la latitud
        formik.setFieldValue('longitud', newPosition.lng); // actualiza el campo de la longitud

        await handleSearchByLatLon(newPosition.lat, newPosition.lng);
    }

    // función para la búsqueda de la dirección
    const handleSearch = async (values) => {
        const response = await fetch(
            `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(values)}`
        );
        const data = await response.json();

        if (data && data.length > 0) {
            formik.setFieldValue('latitud', data[0].lat)
            formik.setFieldValue('longitud', data[0].lon)

            setMarkerPosition([parseFloat(data[0].lat), parseFloat(data[0].lon)]);

            // await handleSearchByLatLon(parseFloat(data[0].lat), parseFloat(data[0].lon))
        }
    }

    // función para determinar los datos de la dirección a buscar, por ahora pone el país de la ubicación seleccionada
    const handleSearchByLatLon = async (lat, lon) => {

        const response = await fetch(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`
        )

        const data = await response.json();
    }

    // manejador que controla el cambio en la barra de búsqueda
    const handleInputChange = (event) => {
        const { value } = event.target;
        setInitialValuesMap({ ...initialValuesMap, searchQuery: value });
    };

    // función de búsqueda cuando se le da click al botón
    const handleSearchClick = async () => {
        await handleSearch(initialValuesMap.searchQuery);
    }

    const [markerPosition, setMarkerPosition] = useState([formik.values?.latitud, formik.values?.longitud]);

    useEffect(() => {
        setMapKey(prevKey => prevKey + 1);
    }, [formik.values?.latitud, formik.values?.longitud])

    console.log(tipoSensor)

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>
                    {/* fila 1 */}
                    <Grid item xs={12} sm={3}>
                        <TextField
                            fullWidth
                            label="Puerto"
                            name="puerto"
                            placeholder="Ej: 8000"
                            value={formik.values.puerto}
                            error={formik.errors.puerto}
                            onChange={formik.handleChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Autocomplete
                            fullWidth
                            id="modulo"
                            options={moduloOpts}
                            getOptionLabel={(option) => `${option.text} `}
                            value={
                                moduloOpts?.find(
                                    (opt) => opt.key === formik.values?.modulo
                                ) || null
                            }
                            onChange={(_, value) => {
                                formik.setFieldValue("modulo", value?.key || "");
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Entrada"
                                    variant="outlined"
                                    error={formik.touched.modulo && Boolean(formik.errors.modulo)}
                                    helperText={formik.touched.modulo && formik.errors.modulo}
                                    sx={{ background: "#fcfcfc" }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Autocomplete
                            fullWidth
                            id="etiquetaSensor"
                            options={EtiquetaSensores}
                            getOptionLabel={option => option.sensor_category_rel_data?.cat_name}
                            value={
                                EtiquetaSensores?.find(
                                    (opt) => opt.id_tag === formik.values?.etiquetaSensor
                                ) || null
                            }
                            onChange={(_, value) => {
                                formik.setFieldValue('etiquetaSensor', value?.id_tag || "")
                                const tag = EtiquetaSensores.find((tag) => tag.id_tag === value?.id_tag)
                                console.log(tag)
                                setTagData(tag)
                                setTipoSensor(tag.tipo)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Etiqueta"
                                    variant="outlined"
                                    error={formik.touched.etiquetaSensor && Boolean(formik.errors.etiquetaSensor)}
                                    helperText={formik.touched.etiquetaSensor && formik.errors.etiquetaSensor}
                                    sx={{ background: "#fcfcfc" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        {tipoSensor === '1' ? (
                            <Autocomplete
                                fullWidth
                                id="inputRange"
                                options={inputRangeOpts}
                                getOptionLabel={(option) => `${option.text} `}
                                value={
                                    inputRangeOpts?.find(
                                        (opt) => opt.key === formik.values?.inputRange
                                    ) || null
                                }
                                onChange={(_, value) => {
                                    formik.setFieldValue('inputRange', value?.key || "")
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Rango de entrada"
                                        variant="outlined"
                                        error={formik.touched.inputRange && Boolean(formik.errors.inputRange)}
                                        helperText={formik.touched.inputRange && formik.errors.inputRange}
                                        sx={{ background: "#fcfcfc" }}
                                    />
                                )}
                            />
                        ) : (
                            <></>
                        )}
                    </Grid>

                    {/* fila 2 */}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Número de serie"
                            name="num_serie"
                            placeholder="Ej: A0121"
                            value={formik.values.num_serie}
                            error={formik.errors.num_serie}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            fullWidth
                            label={Sensores ? Sensores.etiquetaSensorData.sensor_unit_rel_data.unit_name : tagData?.sensor_unit_rel_data?.unit_name ? tagData?.sensor_unit_rel_data?.unit_name : 'No especificado'}
                            name="rango_min"
                            placeholder="Ej: 0.1"
                            value={formik.values.rango_min}
                            error={formik.errors.rango_min}
                            onChange={formik.handleChange}
                            helperText="Rango de entrada mínimo"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            fullWidth
                            label={Sensores ? Sensores.etiquetaSensorData.sensor_unit_rel_data.unit_name : tagData?.sensor_unit_rel_data?.unit_name ? tagData?.sensor_unit_rel_data?.unit_name : 'No especificado'}
                            name="rango_max"
                            placeholder="Ej: 200" controlador_rel
                            value={formik.values.rango_max}
                            error={formik.errors.rango_max}
                            onChange={formik.handleChange}
                            helperText="Rango de entrada máximo"
                        />
                    </Grid>

                    {/* fila 3 */}
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            fullWidth
                            id="controlador_rel"
                            options={Controladores}
                            getOptionLabel={(opt) => `${opt.modelo + " " + opt.marca + " (" + opt.ip + ") "} `}
                            value={
                                Controladores?.find(
                                    (opt) => opt.id_controlador === formik.values?.controlador_rel
                                ) || null
                            }
                            onChange={(_, value) => {
                                formik.setFieldValue('controlador_rel', value?.id_controlador || "")
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Controlador"
                                    variant="outlined"
                                    error={formik.touched.controlador_rel && Boolean(formik.errors.controlador_rel)}
                                    helperText={formik.touched.controlador_rel && formik.errors.controlador_rel}
                                    sx={{ background: "#fcfcfc" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            fullWidth
                            id="tanque_rel"
                            options={tanques?.results}
                            getOptionLabel={(option) => `${option.nombre_tanque} `}
                            value={
                                tanques?.results?.find(
                                    (opt) => opt.id_tanque === formik.values?.tanque_rel
                                ) || null
                            }
                            onChange={(_, value) => {
                                formik.setFieldValue('tanque_rel', value?.id_tanque || "")
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Tanque"
                                    variant="outlined"
                                    error={formik.touched.tanque_rel && Boolean(formik.errors.tanque_rel)}
                                    helperText={formik.touched.tanque_rel && formik.errors.tanque_rel}
                                    sx={{ background: "#fcfcfc" }}
                                />
                            )}
                        />
                        
                    </Grid>

                    {/* fila 4 */}
                    <Grid item xs={12} sm={10}>
                        <TextField
                            fullWidth
                            label="Buscar ubicación"
                            name="searchQuery"
                            value={initialValuesMap.searchQuery}
                            onChange={handleInputChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={2}>
                        <Button fullWidth variant="outlined" style={{ height: '100%' }} onClick={handleSearchClick}>
                            <SearchIcon />
                        </Button>
                    </Grid>

                    {/* zona del mapa - fila 4 */}
                    <Grid item xs={12} sm={12}>
                        <MapContainer key={mapKey} center={markerPosition} zoom={13} style={{ height: '400px' }}>
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={markerPosition} icon={customIcon} draggable={true} eventHandlers={{
                                dragend: handleMarkerMove
                            }}>
                                <Popup>
                                    Ubicación
                                </Popup>
                            </Marker>
                        </MapContainer>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Button type="submit" variant="contained" className="btn btn-danger" color="error" fullWidth>{Sensores ? "Actualizar" : "Crear"}</Button>
                    </Grid>

                    {/* fila no visible */}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Latitud"
                            name="id_direcFc.latitud"
                            value={formik.values?.latitud}
                            onChange={formik.handleChange}
                            type="text"
                            hidden
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Longitud"
                            name="id_direcFc.longitud"
                            placeholder="Datos extra"
                            value={formik.values?.longitud}
                            onChange={formik.handleChange}
                            type="text"
                            hidden
                            disabled
                        />
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

function initialValues(data) {
    return {
        puerto: data?.puerto || "",
        modulo: data?.modulo || "",
        inputRange: data?.inputRange || "1",
        etiquetaSensor: data?.etiquetaSensor || 0,
        num_serie: data?.num_serie || "",
        latitud: data?.latitud || 4.60971,
        longitud: data?.longitud || -74.08175,
        rango_min: data?.rango_min || 0,
        rango_max: data?.rango_max || 0,
        controlador_rel: data?.controlador_rel || 0,
        tanque_rel: data?.tanque_rel || 0
    };
}

function newSchame() {
    return {
        puerto: Yup.string(),
        modulo: Yup.string(),
        inputRange: Yup.string(),
        // etiquetaSensor: Yup.number(),
        // num_serie: Yup.string(),
        // latitud: Yup.number(),
        // longitud: Yup.number(),
        // rango_min: Yup.number(),
        // rango_max: Yup.number(), 
    };
}

function updateSchame() {
    return {
        puerto: Yup.string(),
        modulo: Yup.string(),
        inputRange: Yup.string(),
        // etiquetaSensor: Yup.number(),
        // num_serie: Yup.string(),
        // latitud: Yup.number(),
        // longitud: Yup.number(),
        // rango_min: Yup.number(),
        // rango_max: Yup.number(), 
    };
}