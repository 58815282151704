import React from 'react'

import { DataGridBasic } from '../../Comons';

export  function TablaparamatrizacionDecimales(props) {
  /*
  Componente

  -componente de visualizacion de tabla de  para parametrizacion decimales
  
  -se obtiene los datos y funciones correspondientes mediante props desde su PageAdmin
   */

  const {updateDecimales,Decimales}=props;
  return (
    <>
     
     <DataGridBasic
        data={Decimales}
        title="Clientes"
        id="id_parametrizacion"
        filterValue={filterValue}
        columns={columns}
        updateData={updateDecimales} 
        
        />

      

    </>
  )
}

const columns = [
  // {
  //   header: "ID",
  //   name: "id_parametrizacion",
  // },
  {
    header: "Decimales TOV",
    name: "tov_decimales",
  },
  {
    header: "Decimales FW",
    name: "fw_decimales",
  },
  {
    header: "Decimales CTSh",
    name: "ctsh_decimales",
  },
  {
    header: "Decimales GSV",
    name: "gsv_decimales",
  },
  {
    header: "Decimales CSW",
    name: "csw_decimales",
  },
  {
    header: "Decimales FRA",
    name: "fra_decimales",
  },
  {
    header: "Decimales GOV",
    name: "gov_decimales",
  },
];

const filterValue = [
  { name: 'gov_decimales', operator: 'startsWith', type: 'string', value: '' },
  { name: 'fw_decimales', operator: 'startsWith', type: 'string', value: '' },
  { name: 'ctsh_decimales', operator: 'startsWith', type: 'string', value: '' },
  { name: 'gsv_decimales', operator: 'startsWith', type: 'string', value: '' },
  { name: 'csw_decimales', operator: 'startsWith', type: 'string', value: '' },
  { name: 'fra_decimales', operator: 'startsWith', type: 'string', value: '' },
  { name: 'gov_decimales', operator: 'startsWith', type: 'string', value: '' },
 
  
];