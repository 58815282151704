import { BASE_API } from "../../utils/constants";
import { makeRequest } from "../Request";
const BASE_URL = `${BASE_API}/`;


// Función para obtener los datos de un cliente
export async function getClienteApi(token, schema) {
  const url = `${BASE_URL}${schema}/clientes/clientesA`;
  return makeRequest(url, "GET", token);
}

// Función para agregar un nuevo cliente
export async function addClienteApi(data, token, schema) {
  const url = `${BASE_URL}${schema}/clientes/clientesA/`;
  return makeRequest(url, "POST", token, data);
}

// Función para actualizar los datos de un cliente existente
export async function updateClienteApi(id, data, token, schema) {
  const url = `${BASE_URL}${schema}/clientes/clientesA/${id}/`;
  return makeRequest(url, "PUT", token, data);
}

// Función para eliminar un cliente
export async function deleteClienteApi(id, token, schema) {
  const url = `${BASE_URL}${schema}/clientes/clientesA/${id}/`;
  return makeRequest(url, "DELETE", token);
}
