import { useFormik } from "formik";
import React from "react";
import { toast } from "react-toastify";
import { Button, Form, Input } from "semantic-ui-react";
import * as Yup from "yup";
import { useBahias } from "../../../hooks";
import { alertas } from "../../Comons/Alertas/alertas";

export  function AddEditBahia(props) {
 /*
  Componente

  -componente de visualización del modal de edicion y creacion para Bahias
  
  -se crea y valida mediante formik y yup
  -Se obtienen datos para dropdown mediante hooks
   */
  const { Successful } = alertas();
  const { onClose, onRefetch, Bahias } = props;
  const { addBahias, updateBahias } = useBahias();

  const formik = useFormik({
    // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
    initialValues: initialValues(Bahias),
    // Establece la validación del esquema utilizando Yup, si se proporciona un objeto GruposPermisos se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
    validationSchema: Yup.object(Bahias ? updateSchame() : newSchame()),
    // Desactiva la validación al cambiar los valores del formulario
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        // Si se proporciona un objeto Bahias se actualiza, de lo contrario se crea uno nuevo
        if (Bahias) await updateBahias(Bahias.id_bahias, formValue);
        else await addBahias(formValue);
        // Llama a una función Successful si se completa correctamente
        Successful();
        // Llama a la función onRefetch para actualizar la lista de GruposPermisos
        onRefetch();
        // Llama a la función onClose para cerrar el formulario
        onClose();
      } catch (error) {
        if (error?.message) {
          // Muestra un mensaje de error si ocurre algún problema
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });

  return (
    <Form className="add-edit-persona-form" onSubmit={formik.handleSubmit}>
      <Form.Group widths="equal">
        <Form.Field
          required
          fluid
          control={Input}
          label="Nombre"
          name="nombre"
          placeholder="Nombre"
          value={formik.values.nombre}
          onChange={formik.handleChange}
          error={formik.errors.nombre}
        />

        <Form.Field
          required
          fluid
          control={Input}
          label="Isla"
          name="islas_id"
          placeholder="Isla"
          value={formik.values.islas_id}
          onChange={formik.handleChange}
          error={formik.errors.islas_id}
        />
      </Form.Group>

      <Button
        type="submit"
        // primary
        className="btn btn-danger"
        fluid
        content={Bahias ? "Actualizar" : "Crear"}
      />
    </Form>
  );
}

function initialValues(data) {
  return {
    nombre: data?.nombre || "",
    islas_id: data?.islas_id || "",
  };
}

function newSchame() {
  return {
    nombre: Yup.string().required(true),
    islas_id: Yup.string().required(true),
  };
}

function updateSchame() {
  return {
    nombre: Yup.number().required(true),
    islas_id: Yup.string().required(true),
  };
}

