import React, { useEffect, useState } from "react";
import { HeaderPage } from "../../../Components/Client";
import { DataGridBasic, ModalBasic } from "../../../Components/Comons";
import { useAuth, useConsumo } from "../../../hooks";
import { Funciones } from "./Funciones";
import { Tabs, Tab } from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';
import { filterValue, columns } from "./OrdenesSuministroFormat"

export function GestCombOrdenesAdm() {
    const { loading, ordenesSuministro, getOrdenesSuministro } = useConsumo()
    const { refetch, contentModal, titleModal, showModal, openCloseModal, handleAdd, handleDelete, handleUpdate } = Funciones()

    const [activeTab, setActiveTab] = useState(0);

    const MemoizedModalBasic = React.memo(ModalBasic);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    useEffect(() => {
        getOrdenesSuministro()
    }, [refetch])

    console.log(ordenesSuministro)

    const modifiedData = ordenesSuministro ? ordenesSuministro.map((item) => ({
        ...item,
        tanque: item.prod_tanque.data_tanques.nombre_tanque,
        proveedor: item.supplier.nombre_proveedor,
        estado: item.abierta ? "Abierta" : "Cerrada",
    })) : [];

    

    return (
        <>
            <HeaderPage
                title="Ordenes de suministro"
                btnTitle="Nueva orden"
                icono="plus square icon"
                btnClick={handleAdd}
            />

            <Tabs value={activeTab} onChange={handleTabChange}>
                <Tab label="Ordenes" />
            </Tabs>

            {activeTab === 0 && (
                <>
                    {loading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <>
                            {ordenesSuministro ? (
                                <DataGridBasic
                                    data={modifiedData}
                                    title="ordenes"
                                    id="ordenes"
                                    filterValue={filterValue}
                                    columns={columns}
                                    updateData={handleUpdate}
                                    onDeleteData={handleDelete}
                                />
                            ) : (
                                <div>No hay datos registrados</div>
                            )}
                        </>
                    )}

                    {showModal && (
                        <MemoizedModalBasic
                            show={showModal}
                            onClose={openCloseModal}
                            title={titleModal}
                            children={contentModal}
                        />
                    )}
                </>
            )}
        </>
    )
}

