export const columns = [
    {
      name: "nombre",
      header: "Nombre",
      maxWidth: 1000, defaultFlex: 1
    },
    {
      name: "tov_decimales",
      header: "Decimales TOV",
      maxWidth: 1000, defaultFlex: 1,
      type: "number",
    },
    {
      name: "fw_decimales",
      header: "Decimales FW",
      maxWidth: 1000, defaultFlex: 1,
      type: "number",
      
    },
    {
      name: "ctsh_decimales",
      header: "Decimales CTSh",
      maxWidth: 1000, defaultFlex: 1,
      type: "number",
      
    },
    {
      name: "gsv_decimales",
      header: "Decimales GSV",
      maxWidth: 1000, defaultFlex: 1
      ,type: "number",
      
    },
    {
      name: "csw_decimales",
      header: "Decimales CSW",
      maxWidth: 1000, defaultFlex: 1,
      type: "number",
      
    },
    {
      name: "fra_decimales",
      header: "Decimales FRA",
      maxWidth: 1000, defaultFlex: 1,
      type: "number",
      
    },
    {
      name: "gov_decimales",
      header: "Decimales GOV",
      maxWidth: 1000, defaultFlex: 1,
      type: "number",
    }
  ];

  export const filterValue = [
    { name: 'nombre', operator: 'startsWith', type: 'string', value: '' },
    
  ];