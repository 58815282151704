import { useFormik } from "formik";
import React ,{useState} from "react";

import { toast } from "react-toastify";
import * as Yup from "yup";
import { useAutenticacion } from "../../../hooks";
import { PaginaAviso } from "./PaginaAviso";




const styles = {
  formControl: {
    width: "50%",
    margin: "0 auto",
    display: "block"
  },
  btnPrimary: {
    width: "20%",
    margin: "0 auto",
    display: "block",
    backgroundColor: "red",
    borderColor: "red"
  },

};

export  function RecuperecionCredenciales() {

const [redirect, setRedirect] = useState(false);
const { PostEmail } = useAutenticacion();

const formik = useFormik({
  // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
  initialValues: initialValues(),
  // Establece la validación del esquema utilizando Yup, si se proporciona un objeto  se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
  validationSchema: Yup.object(validationSchema()),
  onSubmit: async (formValue) => {
    try {
      // Si hace una apeticion en caso de que retone valores se envia a la funcion login
       PostEmail(formValue);
       setRedirect(true);
    } catch (error) {
      /*
      capturamos el error si el email o la contraseña son incorrectos y lo mostramos con toastify
      */
      toast.error(error.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  },
});
if (redirect) {
  return <PaginaAviso/>;
}

return (
  <div className="paginaAviso">
  <div className="container mt-5 text-center">
    <h1 className="mb-3">Enviar correo de recuperación:</h1>
    <form
      className="add-edit-secciones-form text-center"
      onSubmit={formik.handleSubmit}
    >
      <div className="form-group">
        <input
          name="email"
          placeholder="Correo Electrónico"
          type="email"
          required
          className="form-control border border-danger rounded p-2"
          style={styles.formControl}
          value={formik.values.email}
          onChange={formik.handleChange}
        />
      </div>
      <button
        className="btn btn-primary mt-3"
        type="submit"
        style={styles.btnPrimary}
      >
        Recuperar
      </button>
    </form>
  </div>
  </div>
);
}

function initialValues() {
return {
  email: "",
};
}

function validationSchema() {
return {
  email: Yup.string().email(true).required(true),
};
}