// component showing small tank in unloading
import React from "react";
import { TankSmall } from "../icons/tank_small";
import { Barra, Container, ContainerIcon, SpanStyle, TankMed } from "./styles";

export const IconTankSamll = ({ percent }) => {
  return (
    <>
      <Container>
        <Barra></Barra>
        <ContainerIcon>
          <TankSmall />
          <TankMed percent={percent}></TankMed>
        </ContainerIcon>
        <SpanStyle>{percent}%</SpanStyle>
      </Container>
    </>
  );
};
