import React, { useState, useCallback } from 'react';
import Swal from "sweetalert2";
import { AddEditIngresoDatosForm, VisualizacionDatosIngresadosForm } from "../../../Components/Client";
import { useIngresoDatos } from "../../../hooks";
import { useAuth } from '../../../hooks';

export function Funciones() {
  const { deleteIngresoDatos } = useIngresoDatos();
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const { auth } = useAuth();
  const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);

  const handleViews = useCallback((data) => {
    setTitleModal("Visualizar datos calculados");
    setContentModal(
      <VisualizacionDatosIngresadosForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        visualizacionDI={data}
      />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);



  const handleAdd = useCallback(() => {
    setTitleModal("Nuevo ingreso de datos manual");
    setContentModal(
      <AddEditIngresoDatosForm onClose={openCloseModal} onRefetch={onRefetch} />

    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);



  const handleUpdate = useCallback((data) => {
    setTitleModal("Actualizar persona");
    setContentModal(
      <AddEditIngresoDatosForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        Datos={data}
      />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);



  const handleDelete = useCallback(async (data) => {
    const result = await Swal.fire({
      title: "Estas seguro?",
      text: "No podrás revertir esta acción!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si, eliminar!",
      cancelButtonText: "No, cancelar!",
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      await deleteIngresoDatos(data.id_datos);
      Swal.fire("Eliminado!", "El dato ha sido eliminado.", "success");
      onRefetch();
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelado", "Operación cancelada", "error");
    }
  }, [deleteIngresoDatos, onRefetch]);





  const renderUnidadLongitud = () => {
    if (auth.me?.SistemasMedicion && auth.me?.SistemasMedicion.longitud) {
      return `${auth.me.SistemasMedicion.longitud} `;
    }
    return "No especificado";
  };

  const renderUnidadVolumen = () => {
    if (auth.me?.SistemasMedicion && auth.me?.SistemasMedicion.volumen) {
      return `${auth.me.SistemasMedicion.volumen} `;
    }
    return "No especificado";
  };

  const renderUnidadTemperatura = () => {
    if (auth.me?.SistemasMedicion && auth.me?.SistemasMedicion.temperatura) {
      return `${auth.me.SistemasMedicion.temperatura} `;
    }
    return "No especificado";
  };

  const renderUnidadmin = () => {
    if (auth.me?.SistemasMedicion && auth.me?.SistemasMedicion.longitud) {
      if (auth.me?.SistemasMedicion.longitud === "cm") {
        return `mm`;
      } else if (auth.me?.SistemasMedicion.longitud === "ft") {
        return `in`;
      }
    }
    return "No especificado";
  };
  const renderUnidMay = () => {
    if (auth.me?.SistemasMedicion && auth.me?.SistemasMedicion.longitud) {
      if (auth.me?.SistemasMedicion.longitud === "cm") {
        return `m`;
      } else if (auth.me?.SistemasMedicion.longitud === "ft") {
        return `yd`;
      }
    }
    return "No especificado";
  };

  /**
   * Columnas de tabla y valores
   * 
   * Mostramos los valores dependiendo de la personalizacion de los valores que el usuario elija
   */

  const columns = [
    {
      header: "Fecha",
      name: "fecha_captura",
      maxWidth: 1000, defaultFlex: 1
    },
    {
      header: "Hora",
      name: "hora_captura",
      maxWidth: 1000, defaultFlex: 1
    },
    {
      header: "Nivel de producto" + "(" + renderUnidMay() + ")",
      name: "nivel_producto_m",
      maxWidth: 1000, defaultFlex: 1
    },
    {
      header: "Nivel producto " + "(" + renderUnidadLongitud() + ")",
      name: "nivel_producto_cm",
      maxWidth: 1000, defaultFlex: 1
    },
    {
      header: "Nivel producto " + "(" + renderUnidadmin() + ")",
      name: "nivel_producto_mm",
      maxWidth: 1000, defaultFlex: 1
    },
    {
      header: "Nivel agua libre" + "(" + renderUnidMay() + ")",
      name: "nivel_agua_libre_m",
      maxWidth: 1000, defaultFlex: 1
    },
    {
      header: "Nivel agua libre " + "(" + renderUnidadLongitud() + ")",
      name: "nivel_agua_libre_cm",
      maxWidth: 1000, defaultFlex: 1
    },
    {
      header: "Nivel agua libre " + "(" + renderUnidadmin() + ")",
      name: "nivel_agua_libre_mm",
      maxWidth: 1000, defaultFlex: 1
    },
    {
      header: "Temperatura liquido " + "(" + renderUnidadTemperatura() + ")",
      name: "temperatura_liquido",
      maxWidth: 1000, defaultFlex: 1
    },
    {
      header: "Temperatura ambiente " + "(" + renderUnidadTemperatura() + ")",
      name: "temperatura_ambiente",
      maxWidth: 1000, defaultFlex: 1
    },
    {
      header: "Contenido agua y sedimento",
      name: "contenido_agua_sedimento",
      maxWidth: 1000, defaultFlex: 1
    },

  ];





  return {
    handleAdd,
    handleDelete,
    handleUpdate,
    openCloseModal,
    handleViews,
    refetch,
    contentModal,
    titleModal,
    showModal,
    columns,
  };
}
