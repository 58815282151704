import React, { useState, useCallback } from "react";
import Swal from "sweetalert2";
import { AddEditBahia } from "../../../Components/Client";
import { useBahias } from "../../../hooks";

export function Funciones() {
  const { deleteBahias } = useBahias();
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);

  const handleAddBahias = useCallback(() => {
    setTitleModal("Nuevo Bahias");
    setContentModal(
      <AddEditBahia onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const handleUpdateBahias = useCallback(
    (data) => {
      setTitleModal("Actualizar Bahias");
      setContentModal(
        <AddEditBahia
          onClose={openCloseModal}
          onRefetch={onRefetch}
          Bahias={data}
        />
      );
      openCloseModal();
    },
    [openCloseModal, onRefetch]
  );

  const handleDeleteBahias = useCallback(
    async (data) => {
      const result = await Swal.fire({
        title: "Estas seguro?",
        text: "No podrás revertir esta acción!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, eliminar!",
        cancelButtonText: "No, cancelar!",
        reverseButtons: true,
      });

      if (result.isConfirmed) {
        await deleteBahias(data.id_Bahias);
        Swal.fire("Eliminado!", "El dato ha sido eliminado.", "success");
        onRefetch();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelado", "Operación cancelada", "error");
      }
    },
    [deleteBahias, onRefetch]
  );

  return {
    refetch,
    contentModal,
    titleModal,
    showModal,
    handleAddBahias,
    handleDeleteBahias,
    handleUpdateBahias,
    openCloseModal,
  };
}
