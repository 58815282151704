import React, { useEffect, useState } from "react";
import { HeaderPage } from "../../../Components/Client";
import { ModalBasic } from "../../../Components/Comons";
import { useAccesos, useControladores } from "../../../hooks";
import { Funciones } from "./Funciones"
import { useAuth } from "../../../hooks"

import Swal from "sweetalert2";

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';

export function StartStopAccess() {
    const { getConfigTurnos, ConfigT, updateConfigTurnos, responseUpdt, loadingConfigT, handle_start_stop_access, loading_start_stop_access } = useAccesos()
    const { Controladores, getControladores, loading } = useControladores();
    const [filteredCtrlEntrada, setFilteredCtrlEntrada] = useState([])
    const [filteredCtrlSalida, setFilteredCtrlSalida] = useState([])

    const { auth, logout } = useAuth();

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const {
        refetch,
        contentModal,
        titleModal,
        showModal,
        openCloseModal,
    } = Funciones()

    const MemoizedModalBasic = React.memo(ModalBasic);

    useEffect(() => {
        getControladores()
    }, [refetch])

    useEffect(() => {
        const filtered = Controladores?.filter(item => item.used_for_access === true && item.proceso === "1").reduce((grouped, item) => {
            const key = item.porteria;
            grouped[key] = (grouped[key] || []).concat(item);
            return grouped;
        }, {});

        const filtered2 = Controladores?.filter(item => item.used_for_access === true && item.proceso === "2").reduce((grouped, item) => {
            const key = item.porteria;
            grouped[key] = (grouped[key] || []).concat(item);
            return grouped;
        }, {});

        setFilteredCtrlEntrada(filtered);
        setFilteredCtrlSalida(filtered2)
    }, [Controladores])

    const handleSubmit = (rowData) => {
        try {
            const k_obj = rowData.filter(r => r.tipo === "kunbus")[0]
            const cam_obj = rowData.filter(r => r.tipo === "cam")[0]
            const bio_obj = rowData.filter(r => r.tipo === "bio_cam")[0]

            const data_to_send = {
                "kunbus": k_obj ? k_obj.ip : null,
                "cam": cam_obj ? cam_obj.ip : null,
                "cam_credentials": cam_obj ? [cam_obj.usuario, cam_obj.contraseña] : null,
                "bio_cam": bio_obj ? bio_obj.ip : null,
                "bio_cam_credentials": bio_obj ? [bio_obj.usuario, bio_obj.contraseña] : null,
                "clientedb": auth.me.owner2.nombre,
                "path": auth.me.nombres,
                "process": 1
            }

            console.log(data_to_send)

            handle_start_stop_access(data_to_send)

        } catch (e) {
            console.log(e)
        }
    }

    const handleSubmitStop = (rowData) => {
        try {
            const k_obj = rowData.filter(r => r.tipo === "kunbus")[0]
            const cam_obj = rowData.filter(r => r.tipo === "cam")[0]
            const bio_obj = rowData.filter(r => r.tipo === "bio_cam")[0]

            const data_to_send = {
                "kunbus": k_obj ? k_obj.ip : null,
                "cam": cam_obj ? cam_obj.ip : null,
                "bio_cam": bio_obj ? bio_obj.ip : null,
                "clientedb": auth.me.owner2.nombre,
                "path": auth.me.nombres,
                "process": 0
            }

            console.log(data_to_send)

            handle_start_stop_access(data_to_send)

        } catch (e) {
            console.log(e)
        }
    }

    const handleSubmitSalida = (rowData) => {
        try {
            const k_obj = rowData.filter(r => r.tipo === "kunbus")[0]
            const cam_obj = rowData.filter(r => r.tipo === "cam")[0]
            const bio_obj = rowData.filter(r => r.tipo === "bio_cam")[0]

            const data_to_send = {
                "kunbus": k_obj ? k_obj.ip : null,
                "cam": cam_obj ? cam_obj.ip : null,
                "cam_credentials": cam_obj ? [cam_obj.usuario, cam_obj.contraseña] : null,
                "bio_cam": bio_obj ? bio_obj.ip : null,
                "bio_cam_credentials": bio_obj ? [bio_obj.usuario, bio_obj.contraseña] : null,
                "clientedb": auth.me.owner2.nombre,
                "path": auth.me.nombres,
                "process": 1
            }

            console.log(data_to_send)

            handle_start_stop_access(data_to_send)
        } catch (e) {
            console.log(e)
        }
    }

    const handleSubmitSalidaStop = (rowData) => {
        try {
            const k_obj = rowData.filter(r => r.tipo === "kunbus")[0]
            const cam_obj = rowData.filter(r => r.tipo === "cam")[0]
            const bio_obj = rowData.filter(r => r.tipo === "bio_cam")[0]

            const data_to_send = {
                "kunbus": k_obj ? k_obj.ip : null,
                "cam": cam_obj ? cam_obj.ip : null,
                "bio_cam": bio_obj ? bio_obj.ip : null,
                "clientedb": auth.me.owner2.nombre,
                "path": auth.me.nombres,
                "process": 0
            }

            console.log(data_to_send)

            handle_start_stop_access(data_to_send)
        } catch (e) {
            console.log(e)
        }
    }

    if (loading_start_stop_access) {
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </div>
            </>
        )
    }

    console.log(filteredCtrlEntrada)

    return (
        <>
            {loading ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                    </div>
                </>
            ) : (
                <>
                    <>
                        < HeaderPage title="Proceso de entrada" />

                        <br></br>

                        {filteredCtrlEntrada ? (
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12}>
                                    {Object.keys(filteredCtrlEntrada).map((porteria) => (
                                        <>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={12}>
                                                    <TableContainer component={Paper} key={porteria}>
                                                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <StyledTableCell align="center">Modelo</StyledTableCell>
                                                                    <StyledTableCell align="center">Tipo</StyledTableCell>
                                                                    <StyledTableCell align="center">Marca</StyledTableCell>
                                                                    <StyledTableCell align="center">Porteria</StyledTableCell>
                                                                    <StyledTableCell align="center">Ip</StyledTableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {filteredCtrlEntrada[porteria].map((row) => (
                                                                    <StyledTableRow key={row.id_controlador}>
                                                                        <StyledTableCell component="th" align="center" scope="row">
                                                                            {row.modelo}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            {row.tipo === "bio_cam" ? "Biométrico" : row.tipo === "cam" ? "Cámara" : "Kunbus"}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            {row.marca}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            {row.porteria}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            {row.ip}
                                                                        </StyledTableCell>
                                                                    </StyledTableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>

                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Button fullWidth type="submit" variant="contained" className="success btn-success" color="success"
                                                        onClick={() => handleSubmit(filteredCtrlEntrada[porteria])}
                                                    >
                                                        Activar
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Button fullWidth type="submit" variant="contained" className="error btn-error" color="error"
                                                        onClick={() => handleSubmitStop(filteredCtrlEntrada[porteria])}
                                                    >
                                                        Detener
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </>
                                    ))}
                                </Grid>
                            </Grid>
                        ) : (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                                <Typography variant="h4">
                                    No hay datos
                                </Typography>
                            </div>
                        )}

                        <br />

                        < HeaderPage title="Proceso de salida" />

                        <br></br>
                        {filteredCtrlSalida ? (
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12}>
                                    {Object.keys(filteredCtrlSalida).map((porteria) => (
                                        <>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={12}>
                                                    <TableContainer component={Paper} key={porteria}>
                                                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <StyledTableCell align="center">Modelo</StyledTableCell>
                                                                    <StyledTableCell align="center">Tipo</StyledTableCell>
                                                                    <StyledTableCell align="center">Marca</StyledTableCell>
                                                                    <StyledTableCell align="center">Porteria</StyledTableCell>
                                                                    <StyledTableCell align="center">Ip</StyledTableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {filteredCtrlSalida[porteria].map((row) => (
                                                                    <StyledTableRow key={row.id_controlador}>
                                                                        <StyledTableCell component="th" align="center" scope="row">
                                                                            {row.modelo}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            {row.tipo === "bio_cam" ? "Biométrico" : row.tipo === "cam" ? "Cámara" : "Kunbus"}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            {row.marca}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            {row.porteria}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            {row.ip}
                                                                        </StyledTableCell>
                                                                    </StyledTableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>

                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Button fullWidth type="submit" variant="contained" className="success btn-success" color="success"
                                                        onClick={() => handleSubmitSalida(filteredCtrlSalida[porteria])}
                                                    >
                                                        Activar
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Button fullWidth type="submit" variant="contained" className="error btn-error" color="error"
                                                        onClick={() => handleSubmitSalidaStop(filteredCtrlSalida[porteria])}
                                                    >
                                                        Detener
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </>
                                    ))}
                                </Grid>
                            </Grid>
                        ) : (
                            <Typography variant="h4">
                                No hay datos
                            </Typography>
                        )}
                    </>
                </>
            )
            }
        </>
    )
}