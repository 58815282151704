import DateFilter from "@inovua/reactdatagrid-community/DateFilter";

export const columns = [
  { header: "Numero de orden", name: "id_Orden", maxWidth: 1000, defaultFlex: 1, },
  { header: "Evento", name: "Evento", maxWidth: 1000, defaultFlex: 1, },
  { header: "Tipo", name: "Tipo_evento", maxWidth: 1000, defaultFlex: 1, },
  { header: "Proceso", name: "proceso_a", maxWidth: 1000, defaultFlex: 1, },
  { name: "fecha_crea", header: "Fecha de creacion", defaultFlex: 1, minWidth: 200, filterEditor: DateFilter,
    filterEditorProps: (props, { index }) => {
      // for range and notinrange operators, the index is 1 for the after name
      return {
        dateFormat: "MM-DD-YYYY",
        cancelButton: false,
        highlightWeekends: false,
        placeholder:
          index == 1 ? "Created date is before..." : "Created date is after...",
      };
    },
  },
];

export const islaConfColumns = [
  { header: "Función", name: "funcion", maxWidth: 1000, defaultFlex: 1 },
  { header: "IP cámara", name: "dir_cam", maxWidth: 1000, defaultFlex: 1 },
  { header: "IP biométrico", name: "dir_bio_cam", maxWidth: 1000, defaultFlex: 1 },
  { header: "Kunbus", name: "id_kunbus", maxWidth: 1000, defaultFlex: 1 },
  { header: "Isla", name: "id_isla", maxWidth: 1000, defaultFlex: 1 }
]

export const filterValueIslaConf = [
  { name: "funcion", operator: "startsWith", type: "string", value: "" },
  { name: "dir_cam", operator: "startsWith", type: "string", value: "" },
  { name: "dir_bio_cam", operator: "startsWith", type: "string", value: "" },
  { name: "id_kunbus", operator: "startsWith", type: "string", value: "" },
  { name: "id_isla", operator: "startsWith", type: "string", value: "" },
]

export const filterValue = [
  { name: "id_Orden", operator: "startsWith", type: "string", value: "" },
  { name: "Evento", operator: "startsWith", type: "string", value: "" },
  { name: "Tipo_evento", operator: "startsWith", type: "string", value: "" },
  { name: "proceso_a", operator: "startsWith", type: "string", value: "" },
  { name: "fecha_crea", operator: "before", type: "date", value: "", },
];
