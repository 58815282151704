import React, { useCallback, useEffect, useState } from "react";
import { useControlDocConductor } from "../../../hooks";
import { TablaControl } from "../../Comons";

export function ModuloControlConductor() {
  const {
    ControlDocConductor,
    getControlDocConductor,
    addControlDocConductor,
    updateControlDocConductor,
    deleteControlDocConductor,
  } = useControlDocConductor();
  const [refetch, setRefetch] = useState(false);
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);
  useEffect(() => {
    getControlDocConductor();
  }, [refetch]);
  return (
    <>
      <TablaControl
        documentos={ControlDocConductor}
        addDocumentos={addControlDocConductor}
        updateDocumentos={updateControlDocConductor}
        deleteDocumentos={deleteControlDocConductor}
        onRefetch={onRefetch}
        suggestions={suggestions}
      />
    </>
  );
}

const suggestions = [
  'Cedula de ciudadanía',
  'Licencia de conducción',
  'Carnet o certificación vigente del distribuidor',
  'Documentos de afiliación (EPS)',
  'Documentos de afiliación (ARP)'
];

