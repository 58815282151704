import { useState } from "react";
import {
  getDatosCalculadosApi,
  
} from "../../api/ingresoDatos";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "..";

export function useDatosCalculados() {
  const { auth, logout } = useAuth();
  const { unauthorized, SessionExpired } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    datosCalculados: null,
  });

  const { loading, error, datosCalculados,  } = state;

  const handleResponse = (response) => {
    if (response.status === 401) {
      SessionExpired();
      logout();
    } else if (response.status === 403) {
      unauthorized();
    }
  };

  const getDatosCalculados = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getDatosCalculadosApi(auth.token, auth.me.tenant,id);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, datosCalculados: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  


  return {
    loading,
    error,
    datosCalculados,
    getDatosCalculados,

  };
}
