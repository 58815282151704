import React, { useEffect, useState } from "react"
import { Funciones } from "./Funciones"
//import { Loader } from "semantic-ui-react";
import CircularProgress from '@mui/material/CircularProgress';
import { HeaderPage } from "../../../Components/Client";
import { columns, filterValue } from "./VerificacionesFormat"
import { DataGridBasic, ModalBasic } from "../../../Components/Comons"
import { useVerificaciones } from "../../../hooks/VerificacionesHooks/useVerificaciones";

export function VehCustVerAdm() {
    const { loading, DataVeh, getBaseVehVer } = useVerificaciones()

    const {
        refetch,
        contentModal,
        titleModal,
        showModal,
        handleAddVehVer,
        handleDeleteVehVer,
        handleUpdateVehVer,
        openCloseModal,
    } = Funciones();

    useEffect(() => {
        getBaseVehVer();
    }, [refetch]);

    const MemoizedModalBasic = React.memo(ModalBasic);

    const modifiedData = DataVeh ? DataVeh.map((item) => ({
        ...item,
        active_y_n: item.active ? "Si" : "No",
    })) : [];

    return (
        <>
            <HeaderPage
                title="Inspecciones para vehículos"
                btnTitle="Nuevo formulario de inspección"
                icono="plus square icon"
                btnClick={handleAddVehVer}
            />
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </div>
            ) : (
                <DataGridBasic
                    data={modifiedData}
                    title="Verificaciones"
                    id="id_group_ver"
                    filterValue={filterValue}
                    columns={columns}
                    updateData={handleUpdateVehVer}
                    onDeleteData={handleDeleteVehVer}
                    copyDataVeh={DataVeh}
                />
            )}


            {showModal && (
                <MemoizedModalBasic
                    show={showModal}
                    onClose={openCloseModal}
                    title={titleModal}
                    children={contentModal}
                />
            )}
        </>
    )
}