import React, { useEffect, useState } from "react";
import { useAuth, useOperador, useVehiculos, useVerificaciones } from "../../../hooks";
import { alertas } from "../../Comons/Alertas/alertas";
import { toast } from "react-toastify";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';
import LogoSyz from '../../../assets/logo_syzapi.png'

import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Checkbox,
    Button,
} from '@material-ui/core'
import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export function AddVehicleChecklist(props) {

    const { auth } = useAuth()
    // const { onClose, onRefetch, Vehiculos } = props;
    const [Vehiculos, setVehiculos] = useState()
    const { Successful, errores } = alertas()
    const { DataVeh, getBaseVehVer } = useVerificaciones()
    const { addVehVer } = useVehiculos()
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedGroupIndex, setSelectedGroupIndex] = useState(null);
    const [data, setData] = useState([]);
    const [checklistDone, setChecklistDone] = useState(false)

    useEffect(() => {
        getBaseVehVer()
    }, []);

    useEffect(() => {
        // Retrieve data from localStorage when the component mounts
        const checklistData = localStorage.getItem('checklistData');

        setVehiculos(JSON.parse(checklistData));
    }, []);

    useEffect(() => {
        const filteredData = DataVeh?.filter(item => item.active);
        if (filteredData) {
            setData(filteredData.map(item => ({
                ...item,
                checklist: item.checklist.map(checklistItem => ({
                    ...checklistItem,
                    valor: false
                }))
            })));
        };
    }, [DataVeh]);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    // maneja el abrir/cerrar de la selección
    const handleAccordionChange = (groupId) => {
        setSelectedGroupIndex(groupId);
        setSelectedGroup(groupId)

        if (selectedGroup === groupId) {
            setSelectedGroup(null);
        } else {
            setSelectedGroup(groupId);
        }
    };

    // maneja el cambio en los checkbox
    const handleChange = (groupId, checklistIndex) => {
        const updatedData = [...data];
        const groupData = updatedData.find((item) => item.id_ver_group == groupId);

        if (groupData && groupData.checklist && groupData.checklist[checklistIndex]) {
            groupData.checklist[checklistIndex].valor = !groupData.checklist[checklistIndex].valor;
            setData(updatedData);
        }
    };

    // agrupa los checklist por grupos
    const groupedData = data ? data.reduce((groups, item) => {
        const groupId = item.id_ver_group;
        if (!groups[groupId]) {
            groups[groupId] = [];
        }
        groups[groupId].push(item);
        return groups;
    }, {}) : {};

    const handleSubmit = async () => {

        // si no ha seleccionado nada y le da en guardar
        if (selectedGroupIndex === null) {
            toast.error("Por favor seleccione el formulario");
            return;
        }

        // trae las verificaciones del grupo seleccionado
        const selectedGroupData = data.find(item => item.id_ver_group == selectedGroup)?.checklist || [];

        const payload = {
            verificacion: {
                id_vehiculo: Vehiculos.id_vehiculo,
                id_operador: auth.me.id_usuario,
                checklist: selectedGroupData,
                approved: null
            }
        };

        const dataToSend = {
            id_vehiculo: payload.verificacion.id_vehiculo,
            id_operador: parseInt(payload.verificacion.id_operador),
            checklist: payload.verificacion.checklist,
            approved: null
        }

        try {
            await addVehVer(dataToSend)
            setChecklistDone(true);
            Successful()
        } catch (error) {
            errores()
            console.error('Error sending data:', error);
        }
    };

    if (checklistDone) {
        return (
            <>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={1}>

                    </Grid>
                    <Grid item xs={12} sm={10} style={{ marginTop: '20px' }}>
                        <Typography variant="h5" component="div" style={{ textAlign: "center" }}>
                            Inspección realizada, por favor cierre esta pestaña
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={1}>

                    </Grid>
                </Grid>
            </>
        )
    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <div style={{ backgroundColor: 'rgba(109, 101, 105, 0.8)', padding: '10px', textAlign: 'center' }}>
                        <img src={LogoSyz} width={100} alt="logo" />
                    </div>
                </Grid>
            </Grid>
            {!DataVeh ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                    </div>
                </>
            ) : (
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>

                        </Grid>

                        <Grid item xs={12} sm={1}>

                        </Grid>

                        <Grid item xs={12} sm={10}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">
                                                <strong>Placa</strong>
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                <strong>Marca</strong>
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                <strong>Modelo</strong>
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                <strong>Tipo de vehículo</strong>
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow>
                                            <StyledTableCell component="th" align="center" scope="row">
                                                {Vehiculos?.placa_veh}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {Vehiculos?.marca}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {Vehiculos?.modelo}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {Vehiculos?.tipo_veh == 'carro_tanque' ? 'Carro tanque' : 'Tracto camión'}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid item xs={12} sm={1}>

                        </Grid>

                        <br></br>

                        <Grid item xs={12} sm={1}>

                        </Grid>

                        <Grid item xs={12} sm={10}>
                            {/* checklist */}
                            {Object.keys(groupedData).map((groupId) => (
                                <Accordion
                                    key={groupId}
                                    expanded={selectedGroup === groupId}
                                    onChange={() => handleAccordionChange(groupId)}
                                >
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h6">Conjunto de inspecciones del formulario activo</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            {groupedData[groupId].map((item, index) => (
                                                <div key={item.id_ver_group}>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell><strong>Verificación</strong></TableCell>
                                                                <TableCell><strong>¿Cumple?</strong></TableCell>
                                                                <TableCell><strong>¿Opcional? (no modificable)</strong></TableCell>
                                                                <TableCell><strong>¿Obligatoria? (no modificable)</strong></TableCell>
                                                                <TableCell><strong>¿Restrictiva? (no modificable)</strong></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        {item.checklist.map((checklistItem, indexChklst) => (
                                                            <TableBody key={checklistItem.check}>
                                                                <TableRow>
                                                                    <TableCell>
                                                                        {checklistItem.check}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Checkbox
                                                                            checked={checklistItem.valor}
                                                                            onChange={() => handleChange(groupId, indexChklst)}
                                                                            name={checklistItem.check}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Checkbox
                                                                            readOnly
                                                                            checked={checklistItem.opcional}
                                                                            name={checklistItem.check}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Checkbox
                                                                            readOnly
                                                                            checked={checklistItem.obligatoria}
                                                                            name={checklistItem.check}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Checkbox
                                                                            readOnly
                                                                            checked={checklistItem.restrictiva}
                                                                            name={checklistItem.check}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        ))}
                                                    </Table>
                                                </div>
                                            ))}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </Grid>

                        <Grid item xs={12} sm={1}>

                        </Grid>

                        <br></br>
                        <Grid item xs={12} sm={1}>

                        </Grid>
                        <Grid item xs={12} sm={10}>
                            <Button fullWidth variant="contained" className="btn btn-danger" onClick={handleSubmit}>
                                Cerrar y guardar inspecciones
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={1}>

                        </Grid>
                    </Grid>
                    <br />
                </>
            )}
        </>
    );
}