import React, { useState } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, IconButton, Collapse, Typography, Paper, Box, Grid } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

const TipoControladorDetail = ({ tipoControlador }) => {
  const [expandedSections, setExpandedSections] = useState({
    brazo: false,
    medidor: false,
    componente: false,
    aditivo: false
  });

  const toggleSection = (section) => {
    setExpandedSections(prevState => ({
      ...prevState,
      [section]: !prevState[section]
    }));
  };

  const renderTable = (mapeo, type, index) => {
    return (
      <Collapse in={expandedSections[type]} timeout="auto" unmountOnExit>
        <Box m={2}>
          <Typography variant="h6">{`${type.charAt(0).toUpperCase() + type.slice(1)} ${index + 1}`}</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Variable</TableCell>
                <TableCell>Registro Personalizado</TableCell>
                <TableCell>Longitud</TableCell>
                <TableCell>Tipo</TableCell>
                <TableCell>Div</TableCell>
                <TableCell>Descripción</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mapeo.map((item, i) => (
                <TableRow key={i}>
                  <TableCell>{item.nombre}</TableCell>
                  <TableCell>{item.variable}</TableCell>
                  <TableCell>{item.reg_custom}</TableCell>
                  <TableCell>{item.longitud}</TableCell>
                  <TableCell>{item.tipo}</TableCell>
                  <TableCell>{item.div}</TableCell>
                  <TableCell>{item.descripcion}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Collapse>
    );
  };

  return (
   
      <Box p={3}>
        <Typography variant="h4" gutterBottom>{tipoControlador.nombre_tipo}</Typography>
        <Typography variant="body1"><strong>Descripción:</strong> {tipoControlador.descripcion}</Typography>
        
        <Box mt={3}>
          <Typography variant="h5">Brazo</Typography>
          <IconButton onClick={() => toggleSection('brazo')} style={{ marginLeft: 'auto' }}>
            {expandedSections['brazo'] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </Box>
        {tipoControlador.mapeo_brazo.map((mapeo, index) => renderTable(mapeo, 'brazo', index))}

        <Box mt={3}>
          <Typography variant="h5">Medidor</Typography>
          <IconButton onClick={() => toggleSection('medidor')} style={{ marginLeft: 'auto' }}>
            {expandedSections['medidor'] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </Box>
        {tipoControlador.mapeo_medidor.map((mapeo, index) => renderTable(mapeo, 'medidor', index))}

        <Box mt={3}>
          <Typography variant="h5">Componente</Typography>
          <IconButton onClick={() => toggleSection('componente')} style={{ marginLeft: 'auto' }}>
            {expandedSections['componente'] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </Box>
        {tipoControlador.mapeo_componente.map((mapeo, index) => renderTable(mapeo, 'componente', index))}

        <Box mt={3}>
          <Typography variant="h5">Aditivo</Typography>
          <IconButton onClick={() => toggleSection('aditivo')} style={{ marginLeft: 'auto' }}>
            {expandedSections['aditivo'] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </Box>
        {tipoControlador.mapeo_aditivo.map((mapeo, index) => renderTable(mapeo, 'aditivo', index))}
      </Box>
   
  );
};

export default TipoControladorDetail;
