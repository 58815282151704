import { useFormik } from "formik";
import React, { useEffect, useState, useCallback } from "react";

import { FormControl, InputLabel, Select, Autocomplete, Grid, FormControlLabel } from '@mui/material';
import Button from "@mui/material/Button";
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import { toast } from "react-toastify";

import Swal from "sweetalert2";
import * as Yup from "yup";
import {
  useCliente,
  useConductor,
  useOrdenes,
  usePlantas,
  useProductos,
  useTanques,
  useTransportadora,
  useVehiculos,
  useProductoTanque,
} from "../../../../hooks";

import {
  TransportadoraSelect,
  VehiculosSelect,
  clienteSelect,
  productoCodigoSelect,
  tanqueSelect,
  ProductoTanqueSelect,
} from "../../../Comons";

import { alertas } from "../../../Comons/Alertas/alertas";

export function AddEditOrdenes(props) {
  /*
  Componente

  -componente de visualizacion del modal de edicion y creacion para Ordenes
  
  -se crea y valida mediante formik y yup
  -Se obtienen datos para dropdown mediante hooks
  -se obtiene los datos (para edicion) y funciones correspondientes mediante props desde su PageAdmin
   */
  const { Successful } = alertas();
  const { onClose, onRefetch, Ordenes } = props;
  const { addOrdenes, updateOrdenes } = useOrdenes();
  const [tablasFormatC, setTablasTFormatC] = useState([]);
  const [tablasFormatT, setTablasTFormatT] = useState([]);
  const [tablasFormatV, setTablasTFormatV] = useState([]);
  const [tablasFormatP, setTablasTFormatP] = useState([]);
  const [tablasFormatProductoTanque, setFormatProductoTanque] = useState([]);

  const [datosTanques, setDatosTanques] = useState([]);
  const { ProductoTanqueDB, getProductoTanqueDB } = useProductoTanque();
  const { tanquesDB, getTanquesDB } = useTanques();
  const { ClienteDB, getClienteDB } = useCliente();
  const { TransportadoraDB, getTransportadoraDB } = useTransportadora();
  const { VehiculosDB, getVehiculosDB } = useVehiculos();
  const { ConductorDB, getConductorDB } = useConductor();
  const { ProductoDB, getProductoDB } = useProductos();
  const [capacidad, setCapacidad] = useState(10000);
  const { PlantasDB, getPlantasDB } = usePlantas();
  const [tablasFormatPl, setTablasTFormatPl] = useState([]);
  const [clientesID, setClientesID] = useState([]);
  const [loadingProductos, setLoadingProductos] = useState(false);

  const handleOpenAutocomplete = () => {
    if (ProductoDB === null) {
      setLoadingProductos(true);
      getProductoDB().then(() => setLoadingProductos(false)).catch((error) => {
        setLoadingProductos(false);
        toast.error(error.message, {
          position: "top-center",
        });
      })
    }
  };

  useEffect(() => {
    getClienteDB();
    getTransportadoraDB(true, Ordenes?.id_transportadora);
    getVehiculosDB(true, Ordenes?.id_vehiculo);
    getConductorDB(true, Ordenes?.id_conductor);
    getProductoTanqueDB();
    //getProductoDB();
    getTanquesDB();
    getPlantasDB()
  }, []);

  useEffect(() => {
    setTablasTFormatC(clienteSelect(ClienteDB));
    setTablasTFormatT(TransportadoraSelect(TransportadoraDB));
    setTablasTFormatV(VehiculosSelect(VehiculosDB));
    setTablasTFormatP(productoCodigoSelect(ProductoDB));
    setDatosTanques(tanqueSelect(tanquesDB));
    setFormatProductoTanque(ProductoTanqueSelect(ProductoTanqueDB));
  }, [
    ClienteDB, TransportadoraDB, VehiculosDB, ConductorDB, ProductoDB, tanquesDB,
  ]);

  useEffect(() => {
    setTablasTFormatPl(formatDropdownDataPl(PlantasDB, clientesID, Ordenes));
  }, [PlantasDB, clientesID, Ordenes]);

  const formik = useFormik({
    // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
    initialValues: initialValues(Ordenes),
    // Establece la validación del esquema utilizando Yup, si se proporciona un objeto  se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
    validationSchema: Yup.object(Ordenes ? updateSchame() : newSchame()),
    // Desactiva la validación al cambiar los valores del formulario
    validateOnChange: false,
    onSubmit: async (formValue) => {
      formValue.api = parseInt(api.replace(/[.,]/g, ""), 10);
      formValue.bsw = parseInt(bsw.replace(/[.,]/g, ""), 10);
      formValue.producto_nombre = producto_tanque.nombre;
      formValue.id_remolque = parseInt(vehiculo?.id_remolque?.id_remolque);
      formValue.id_conductor = parseInt(vehiculo?.conductor?.id_conductor);
      formValue.tanque = parseInt(producto_tanque.data_tanques.id_tanque);
      formValue.product_id = parseInt(producto_tanque.data_productos.id);
      formValue.v_producto = producto_tanque.data_productos.index;

      if (formValue.v_auth) {
        formValue.v_auth = 1
      } else {
        formValue.v_auth = 0
      }

      // Aquí validamos si la fecha de vencimiento de la licencia del conductor es mayor a la fecha actual
      console.log(formValue.id_conductor, "ConductorDB", ConductorDB)

      const selectedConductor = ConductorDB.find(
        (conductor) => conductor.id_conductor === formValue.id_conductor
      );

      const fechaVencimientoLicencia = new Date(
        selectedConductor.fecha_vencimineto_licencia
      );
      const fechaActual = new Date();
      if (fechaVencimientoLicencia < fechaActual) {
        Swal.fire({
          icon: "warning",
          title: "Advertencia",
          text: "La fecha de vencimiento de la licencia del conductor ha expirado",
        });
        return false;
      }
     

      try {
        // Si se proporciona un objeto se actualiza, de lo contrario se crea uno nuevo
        if (Ordenes) {
          if (formValue.v_volumen !== Ordenes.v_volumen) {
            formValue.v_volumen = formValue.v_volumen * 100;
            formValue.v_restante = formValue.v_volumen;
          }
          await updateOrdenes(Ordenes.id_orden, formValue);
        } else {
          formValue.v_volumen = formValue.v_volumen * 100;
          formValue.v_restante = formValue.v_volumen;
          await addOrdenes(formValue);
        }

        Successful();
        onRefetch();
        onClose();
      } catch (error) {
        // Muestra un mensaje de error si ocurre algún problema
        if (error?.message) {
          if (formValue.v_volumen !== initialValues(Ordenes).v_volumen) {
            formValue.v_volumen = formValue.v_volumen / 100;
          }
          Swal.fire({
            icon: "warning",
            title: "Advertencia",
            text: error.message,
          });
        } else {
          onClose();
        }
      }
    },
  });

  let api = "";
  let bsw = "";
  let remolques = "";
  let vehiculo = "";
  let producto_tanque = "";

  if (formik?.values && tablasFormatProductoTanque.length !== 0) {
    const apiV = tablasFormatProductoTanque.find(
      (t) => t.extra.id === formik?.values.producto_tabla
    );

    if (apiV && apiV.extra.api_bsw.length > 0) {
      producto_tanque = apiV.extra;
      const lastEntry = apiV.extra.api_bsw[apiV.extra.api_bsw.length - 1];
      api = lastEntry?.api;
      bsw = lastEntry?.bsw;
    }
  }

  if (formik?.values && tablasFormatV.length != 0) {
    const remolque = tablasFormatV.find(
      (t) =>
        t.extra?.id_remolque?.id_remolque === vehiculo?.id_remolque?.id_remolque
    );
    remolques = remolque?.extra?.id_remolque?.compartimientos;
  }

  if (formik?.values && tablasFormatV.length != 0) {
    const vehiculos = tablasFormatV.find(
      (t) => t.extra.id_vehiculo === formik?.values.id_vehiculo
    );
    vehiculo = vehiculos?.extra;
  }
  if (formik?.values && tablasFormatV.length != 0) {
    const vehiculos = tablasFormatV.find(
      (t) => t.extra.id_vehiculo === formik?.values.id_vehiculo
    );
    vehiculo = vehiculos?.extra;
  }

  

  

  useEffect(() => {
    if (Ordenes) {
      const cliente = tablasFormatC.find(
        (t) => t.extra.id_cliente === parseInt(Ordenes.id_cliente)
      );
      const plantas = cliente?.extra.plantas || [];
      setClientesID(plantas);
      formik.setFieldValue("id_cliente", parseInt(Ordenes.id_cliente));
      formik.setFieldValue("planta", ""); // Limpiar el valor de la planta seleccionada
    }
  }, [Ordenes, tablasFormatC]);

  const handleClienteChange = (_, data) => {
    const clienteID = data.value;
    const cliente = tablasFormatC.find((t) => t.extra.id_cliente === clienteID);
    const plantas = cliente?.extra.plantas || [];
    setClientesID(plantas);
    formik.setFieldValue("id_cliente", clienteID);
    formik.setFieldValue("planta", ""); // Limpiar el valor de la planta seleccionada
  };

  return (
    <>
      <form   onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          {/* fila 1 */}
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
         
              label="Número de orden"
              name="n_orden"
              placeholder="Número de orden"
              value={formik.values.n_orden}
              error={formik.errors.n_orden}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              label="Factura o remisión"
              name="n_factura"
              placeholder="Factura o remisión"
              value={formik.values.n_factura}
              onChange={formik.handleChange}
              error={formik.errors.n_factura}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              fullWidth
              id="estado_orden"
              options={Estado}
              getOptionLabel={(option) => `${option.text} `}
              value={
                Estado?.find(
                  (opt) => opt.key === formik.values?.estado_orden
                ) || null
              }
              onChange={(_, value) => {
                formik.setFieldValue("estado_orden", value?.key || "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Estado de la orden"
                  variant="outlined"
                  error={formik.touched.estado_orden && Boolean(formik.errors.estado_orden)}
                  helperText={formik.touched.estado_orden && formik.errors.estado_orden}
                  sx={{ background: "#fcfcfc" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              label="Número GUT"
              name="nmro_gut"
              placeholder="Número de GUT"
              value={formik.values.nmro_gut}
              error={formik.errors.nmro_gut}
              onChange={formik.handleChange}
            />
          </Grid>

          {/* fila 2 */}
          <Grid item xs={12} sm={3}>
            <Autocomplete
              id="producto_tabla"
              options={tablasFormatProductoTanque}
              getOptionLabel={(option) => option.text}
              value={
                tablasFormatProductoTanque?.find(
                  (opt) => opt.key === formik.values?.producto_tabla
                ) || null
              }
              onChange={(_, value) => {
                formik.setFieldValue("producto_tabla", value?.key || ""); // Assuming newValue has a 'value' property
              }}
              onOpen={handleOpenAutocomplete}
              onClose={() => setLoadingProductos(false)}
              loading={loadingProductos}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Producto a cargar"
                  fullWidth
                  variant="outlined"
                  error={formik.errors.producto_tabla && Boolean(formik.errors.producto_tabla)}
                  helperText={formik.touched.producto_tabla && formik.errors.producto_tabla}
                  sx={{ background: "#fcfcfc" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={3} sm={3}>
            <TextField
              disabled
              fullWidth
              name="tanque"
              label="Tanque"
              placeholder=""
              value={producto_tanque?.data_tanques ? producto_tanque?.data_tanques?.nombre_tanque : ""}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              disabled
              fullWidth
              name="v_producto"
              label="Producto"
              placeholder=""
              value={producto_tanque?.data_productos ? producto_tanque?.data_productos?.nombre : ""}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              disabled
              name="api"
              label="API"
              placeholder="0.0"
              value={api ? api : ''}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              disabled
              name="bsw"
              label="BSW"
              placeholder="0.0"
              value={bsw ? bsw : ''}
            />
          </Grid>

          {/* fila 3 */}
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Volumen (gal)"
              name="v_volumen"
              placeholder="Volumen (gal)"
              value={formik.values.v_volumen}
              onChange={formik.handleChange}
              error={formik.errors.v_volumen}
              type="number"
            />
          </Grid>

          {/* fila 4 */}
          <Grid item xs={12} sm={3}>
            <Autocomplete
              id="id_cliente"
              options={tablasFormatC}
              getOptionLabel={(option) => option.text}
              value={
                tablasFormatC?.find(
                  (opt) => opt.key === formik.values?.id_cliente
                ) || null
              }
              onChange={(_, value) => {
                formik.setFieldValue("id_cliente", value?.key || "");
                const cliente = tablasFormatC?.find((t) => t.extra.id_cliente === value?.key)
                const plantas = cliente?.extra.plantas || [];
                setClientesID(plantas);
                formik.setFieldValue("planta", "");
                console.log(plantas)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Cliente asociado"
                  fullWidth
                  variant="outlined"
                  error={formik.errors.id_cliente && Boolean(formik.errors.id_cliente)}
                  helperText={formik.touched.id_cliente && formik.errors.id_cliente}
                  sx={{ background: "#fcfcfc" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              disabled={!formik.values.id_cliente}
              
              id="id_destino"
              options={tablasFormatPl}
              getOptionLabel={(option) => option.text}
              value={
                tablasFormatPl?.find(
                  (opt) => opt.key === formik.values?.id_destino
                ) || null
              }
              onChange={(_, value) => {
                formik.setFieldValue("id_destino", value?.key);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Planta"
                  fullWidth
                  variant="outlined"
                  error={formik.errors.id_destino && Boolean(formik.errors.id_destino)}
                  helperText={!formik.values.id_cliente ? "Seleccione un Cliente primero" : ""}
                  sx={{ background: "#fcfcfc" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              id="id_transportadora"
              options={tablasFormatT}
              getOptionLabel={(option) => option.text}
              value={
                tablasFormatT?.find(
                  (opt) => opt.key === formik.values?.id_transportadora
                ) || null
              }
              onChange={(_, value) => {
                formik.setFieldValue("id_transportadora", value?.key);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Transportadora"
                  fullWidth
                  variant="outlined"
                  error={formik.errors.id_transportadora && Boolean(formik.errors.id_transportadora)}
                  helperText={formik.touched.id_transportadora && formik.errors.id_transportadora}
                  sx={{ background: "#fcfcfc" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              id="id_vehiculo"
              options={tablasFormatV}
              getOptionLabel={(option) => option.text}
              value={
                tablasFormatV?.find(
                  (opt) => opt.key === formik.values?.id_vehiculo
                ) || null
              }
              onChange={(_, value) => {
                formik.setFieldValue("id_vehiculo", value?.key);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Vehículo"
                  fullWidth
                  variant="outlined"
                  error={formik.errors.id_vehiculo && Boolean(formik.errors.id_vehiculo)}
                  helperText={formik.touched.id_vehiculo && formik.errors.id_vehiculo}
                  sx={{ background: "#fcfcfc" }}
                />
              )}
            />
          </Grid>

          {/* fila 5 */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="Remolque"
              label="Remolque"
              placeholder="Remolque"
              value={vehiculo?.id_remolque ? vehiculo?.id_remolque?.placa_remolq : ""}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="Conductor"
              label="Conductor asociado"
              value={vehiculo?.conductor ? vehiculo.conductor.nombre + " " + vehiculo.conductor.apellidos : ""}
            />
          </Grid>

          {/* fila 6 visible a condición */}
          {remolques && remolques.length > 0 && (
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth required>
                <InputLabel id="remolb">Compartimento</InputLabel>
                <Select
                  labelId="remolb"
                  id="remo"
                  label="Compartimento"
                  fullWidth
                  value={formik.values.id_vehiculo}
                  onChange={(event) => { setCapacidad(event.target.value) }}
                  error={formik.errors.id_vehiculo}
                >
                  {remolques.map((opt) => (
                    <MenuItem key={opt.key} value={opt.value}>
                      {opt.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          {/* fila 7 */}
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Observaciones de la orden"
              name="observacion_orden"
              placeholder="Observación de la orden"
              value={formik.values.observacion_orden}
              onChange={formik.handleChange}
              error={formik.errors.observacion_orden}
            />
          </Grid>

          {/* fila 8 */}
          <Grid item xs={12} sm={6}>
            <FormControlLabel fullWidth
              control={
                <Checkbox
                  name='v_auth'
                  checked={formik.values.v_auth}
                  onChange={() => {
                    formik.setFieldValue('v_auth', !formik.values.v_auth);
                  }}
                />
              }
              label={<strong>Autorizar la orden</strong>}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="tiempo_auth"
              placeholder="vencimiento"
              value={formik.values.tiempo_auth}
              onChange={formik.handleChange}
              error={formik.errors.tiempo_auth}
              type="datetime-local"
              helperText="Fecha de vencimiento de la orden"
            />
          </Grid>

          {/* fila 9 */}
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Sellos"
              name="sellos"
              placeholder="Sellos"
              value={formik.values.sellos}
              onChange={formik.handleChange}
              error={formik.errors.sellos}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Button type="submit" variant="contained" color="error" className="btn btn-danger" fullWidth>
              {Ordenes ? "Actualizar" : "Crear"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

// Esta función formatea los datos para un componente de selección (dropdown) de plantas.
// Los datos se filtran según los IDs de clientes proporcionados y se ajustan según ciertas condiciones.

function formatDropdownDataPl(data, clientesID, Ordenes) {
  // Verificar si los datos o los IDs de clientes no están disponibles, retornar un arreglo vacío.
  if (!data || !clientesID) {
    return [];
  }

  // Filtrar los datos para incluir solo las plantas cuyos IDs de clientes están presentes en la lista de clientes.
  let filteredData = data.filter((item) => clientesID.includes(item.id_myemp));

  // Verificar si se proporcionan órdenes y si hay un ID de cliente asociado a esas órdenes.
  if (Ordenes && Ordenes.id_cliente) {
    // Encontrar la planta seleccionada en los datos utilizando el ID de destino de las órdenes.
    const plantaSeleccionada = data.find(
      (item) => item.id_myemp === parseInt(Ordenes.id_destino)
    );

    // Verificar si se encuentra una planta seleccionada y si aún no está incluida en los datos filtrados.
    if (plantaSeleccionada && !filteredData.includes(plantaSeleccionada)) {
      // Agregar la planta seleccionada a los datos filtrados.
      filteredData.push(plantaSeleccionada);
    }
  }

  // El resultado final es un arreglo de datos filtrados y, posiblemente, modificado según las condiciones.



  return filteredData.map((item) => ({
    key: item.id_myemp,
    text: item.razon_social,
    value: item.id_myemp,
    extra: item,
  }));
}

const Estado = [
  { key: "Abierta", text: "Abierta", value: "Abierta", },
  { key: "Cerrada", text: "Cerrada", value: "Cerrada", },
];

function initialValues(data) {
  return {
    n_orden: data?.n_orden || "",
    v_auth: data?.v_auth ? 1 : 0,
    producto_tabla: data?.producto_tabla || "",
    product_id: data?.product_id || "",
    v_producto: data?.v_producto || "",
    v_volumen: data?.v_restante / 100 || "",
    v_restante: data?.v_restante || "",
    estado_orden: data?.estado_orden || "",
    observacion_orden: data?.observacion_orden || "",
    tanque: parseInt(data?.tanque) || "",
    id_transportadora: parseInt(data?.id_transportadora) || "",
    id_vehiculo: parseInt(data?.id_vehiculo) || "",
    id_conductor: parseInt(data?.id_conductor) || "",
    api: data?.api || "",
    bsw: data?.bsw || "",
    id_cliente: data && data.id_cliente ? parseInt(data.id_cliente) : "",
    id_destino: parseInt(data?.id_destino) || "",
    producto_nombre: data?.producto_nombre || "",
    tiempo_auth: data?.tiempo_auth || "",
    id_remolque: parseInt(data?.id_remolque) || "",
    sellos: data?.sellos || "",
    n_factura: data?.n_factura || "",
    nmro_gut: data?.nmro_gut || "",
  };
}

function newSchame() {
  return {
    n_orden: Yup.number(),
    v_auth: Yup.bool().required(true),
    producto_tabla: Yup.number().required(true),
    v_volumen: Yup.number().required(true),
    v_restante: Yup.number(),
    estado_orden: Yup.string().required(true),
    observacion_orden: Yup.string(),
    // tanque: Yup.number().required(true),
    id_cliente: Yup.number().required(true),
    id_transportadora: Yup.number().required(true),
    id_vehiculo: Yup.string().required(true),
    // id_conductor: Yup.number().required(true),
    id_destino: Yup.number().required(true),
    // id_origen: Yup.number().required(true),
    // id_remolque: Yup.string().required(true),
    sellos: Yup.string(),
    n_factura: Yup.string(),
  };
}

function updateSchame() {
  return {
    n_orden: Yup.number(),
    v_auth: Yup.bool().required(true),
    producto_tabla: Yup.number().required(true),
    v_volumen: Yup.number().required(true),
    v_restante: Yup.number(),
    estado_orden: Yup.string().required(true),
    observacion_orden: Yup.string(),
    id_cliente: Yup.number().required(true),
    id_transportadora: Yup.number().required(true),
    id_vehiculo: Yup.string().required(true),
    id_destino: Yup.number().required(true),
    sellos: Yup.string(),
  };
}
