

import { BASE_API } from "../../utils/constants";
import { makeRequestFormData } from "../Request";
const BASE_URL = `${BASE_API}/`;


// Función para obtener los datos 
export async function getOperadorApi(token, schema) {
  const url = `${BASE_URL}${schema}/operadores/operadores`;
  return makeRequestFormData(url, "GET", token);
}

// Función para agregar un nuevo dato
export async function addOperadorApi(data, token, schema) {
  const formData = new FormData();

  formData.append("nombre", data.nombre);
  formData.append("apellidos", data.apellidos);
  formData.append("email", data.email);
  formData.append("n_documento", data.n_documento);
  if (data.foto !== null) {
    formData.append("foto", data.foto);
  }
  formData.append("seguro", data.seguro);
  formData.append("celular", data.celular);
  formData.append("telefono", data.telefono);
  formData.append("fecha_nacimiento", data.fecha_nacimiento);
  formData.append("documentos", JSON.stringify(data.documentos));
  formData.append("area", data.area);
  formData.append("tipo_id", data.tipo_id);
  formData.append("funcion", data.funcion)
  formData.append("ctrl_login_pass", data.ctrl_login_pass);
  formData.append("is_active", data.is_active);
  const url = `${BASE_URL}${schema}/operadores/operadores/`;
  return makeRequestFormData(url, "POST", token, formData);
}

// Función para actualizar los datos 
export async function updateOperadorApi(id, data, token, schema) {
  const formData = new FormData();

  formData.append("nombre", data.nombre);
  formData.append("apellidos", data.apellidos);
  formData.append("email", data.email);
  formData.append("n_documento", data.n_documento);
  if (data.foto !== null) {
    formData.append("foto", data.foto);
  }
  formData.append("seguro", data.seguro);
  formData.append("celular", data.celular);
  formData.append("telefono", data.telefono);
  formData.append("fecha_nacimiento", data.fecha_nacimiento);
  formData.append("documentos", JSON.stringify(data.documentos));
  formData.append("area", data.area);
  formData.append("tipo_id", data.tipo_id);
  formData.append("funcion", data.funcion)
  formData.append("ctrl_login_pass", data.ctrl_login_pass);
  formData.append("is_active", data.is_active);
  const url = `${BASE_URL}${schema}/operadores/operadores/${id}/`;
  return makeRequestFormData(url, "PATCH", token, formData);
}

// Función para eliminar 
export async function deleteOperadorApi(id, token, schema) {
  const url = `${BASE_URL}${schema}/operadores/operadores/${id}/`;
  return makeRequestFormData(url, "DELETE", token);
}

export async function getOpMaqApi(token, schema) {
  const url = `${BASE_URL}${schema}/operadores/opmaq`
  return makeRequestFormData(url, "GET", token)
}