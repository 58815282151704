import { useState } from "react";

import {
    getEmisionesApi, postEmisionesApi, updateEmisionesApi, deleteEmisionesApi
} from "../../api/A_Consumo/consumo";

import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useNavigate } from "react-router-dom";
import { useAuth } from "..";

export function useEmisiones() {
    const { auth, logout } = useAuth();
    const navigate = useNavigate();
    const { unauthorized, SessionExpired, unauthorizedModal, errores } = alertas();

    const [state, setState] = useState({
        loading: true,
        error: null,
        Emisiones: null,
    });

    const {loading, error, Emisiones } = state

    const handleAddOrUpdateError = (result) => {
        if (result?.msg) {
            throw new Error(result.msg);
        } else {
            errores();
        }
    };

    const handleResponse = (response) => {
        if (response.status === 401) {
            SessionExpired();
            logout();
        } else if (response.status === 403) {
            unauthorized();
            navigate("/");
        }
    };

    const getEmisiones = async () => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await getEmisionesApi(auth.token, auth.me.tenant);
            handleResponse(response);
            setState((prevState) => ({ ...prevState, loading: false, Emisiones: result }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
        }
    };

    const addEmisiones = async (data) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await postEmisionesApi(data, auth.token, auth.me.tenant);
            if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            if (response.status === 400) {
                handleAddOrUpdateError(result)
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
            throw error;
        }
    };

    const updateEmisiones = async (id_table, data) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await updateEmisionesApi(id_table, data, auth.token, auth.me.tenant);
            if (response.status === 400) {
                handleAddOrUpdateError(result);
            }
            if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
            throw error;
        }
    }

    const deleteEmisiones = async (id_table) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await deleteEmisionesApi(id_table, auth.token, auth.me.tenant);
            if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
        }
    }

    return {
        loading, error, Emisiones, getEmisiones, addEmisiones, updateEmisiones, deleteEmisiones
    }
}