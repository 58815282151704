import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { Autocomplete } from "@mui/material"
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import Tooltip from '@material-ui/core/Tooltip';
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';

import * as Yup from "yup";
import { useConductor, useProductos, useTransportadora, useVehiculos } from "../../../hooks";
import { TransportadoraSelect, conductoresSelect, productoIdSelect } from "../../Comons";
import { alertas } from "../../Comons/Alertas/alertas";

const veh_types = [
  { key: "carro_tanque", value: "carro_tanque", text: "Carro Tanque", },
  { key: "barcaza", value: "barcaza", text: "Barcaza" },
  { key: "buque", value: "buque", text: "Buque" },
  { key: "tracto_camion", value: "tracto_camion", text: "Tracto Camión", },
]

const cargue_opt = [
  { key: "1", value: "1", text: "TOP", },
  { key: "2", value: "2", text: "BOTTON" },
]

const compartimento_opt = [
  { key: "0", value: "0", text: "NO", },
  { key: "1", value: "1", text: "SI" },
]

export function AddEditVehiculos(props) {
  const { Successful } = alertas();
  const { onClose, onRefetch, Vehiculos } = props;
  const { addVehiculos, updateVehiculos } = useVehiculos();
  const { TransportadoraDB, getTransportadoraDB } = useTransportadora();
  const { ProductoDB, getProductoDB } = useProductos();
  const { ConductorDB, getConductorDB } = useConductor()
  const [tablasFormatT, setTablasTFormatT] = useState([]);
  const [tipoVehiculo, setTipoVehiculo] = useState("");
  const [Productos, setProductos] = useState([]);
  const [tieneCompartimentos, setTieneCompartimentos] = useState(false);
  const [Conductores, setConductores] = useState([])

  useEffect(() => {
    getTransportadoraDB();
    getProductoDB();
    getConductorDB()
  }, []);

  useEffect(() => {
    setTablasTFormatT(TransportadoraSelect(TransportadoraDB));
    setProductos(productoIdSelect(ProductoDB));
    setConductores(conductoresSelect(ConductorDB))
  }, [TransportadoraDB, ProductoDB, ConductorDB]);

  const [errors, setErrors] = React.useState({
    tipo_veh: false,
    con_compartimento: false,
    id_trans: false,
    tipo_combustible: false,
    id_conductor: false,
    tipo_cargue: false,
    'compartimientos.producto': false,
  });

  const formik = useFormik({
    initialValues: initialValues(Vehiculos),
    validationSchema: Yup.object(newSchema()).shape({
      tipo_veh: Yup.string().required("Debe seleccionar un tipo de vehículo"),
    }),

    validateOnChange: true,

    onSubmit: async (formValue) => {
      if (tipoVehiculo !== "tracto_camion") {
        formValue.id_remolque.placa_remolq = formValue.placa_veh;
      }

      try {
        if (formValue.id_conductor == 0) {
          formValue.id_conductor = null
        }

        if (formValue.extras) {
          formValue.extras = 1
        } else {
          formValue.extras = 0
        }

        console.log(formValue)

        if (Vehiculos) await updateVehiculos(Vehiculos.id_vehiculo, formValue);
        else await addVehiculos(formValue);
        Successful();
        onRefetch();
        onClose();
      } catch (error) {
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });


  const handleTipoVehiculoChange = (event, data) => {
    setTipoVehiculo(data.value);
    formik.setFieldValue("tipo_veh", data.value);
  };

  const handleCompartimentosChange = (event, data) => {
    setTieneCompartimentos(data.value);
    formik.setFieldValue("con_compartimento", data.value);
  };

  const divStyle = {
    textAlign: "left",
  };

  const handleAutocompleteChange = (name, value) => {
    formik.setFieldValue(name, value?.value || '');
    setErrors((prevErrors) => ({ ...prevErrors, [name]: !value }));
  };

  return (
    <>
      <form className="add-edit-secciones-form" style={divStyle} onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          {/* fila 1 */}
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="veh"
              name="tipo_veh"
              label="Tipo de vehículo"
              fullWidth
              value={veh_types.find((option) => option.value === formik.values.tipo_veh) || null}
              error={errors.tipo_veh}
              onChange={(event, value) => handleAutocompleteChange('tipo_veh', value)}
              options={veh_types || []}
              getOptionLabel={(option) => option.text || ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tipo de vehículo"
                  error={errors.tipo_veh}
                  helperText={errors.tipo_veh ? 'Debe seleccionar el tipo de vehículo' : ''}
                  variant="outlined"
                />
              )}
            />
            {/* <FormControl fullWidth required>
              <InputLabel id="vehlb">Tipo de vehículo</InputLabel>
              <Select
                labelId="vehlb"
                id="veh"
                label="Tipo de vehículo"
                fullWidth
                value={formik.values.tipo_veh}
                onChange={(event) => {
                  formik.setFieldValue("tipo_veh", event.target.value);
                }}
                error={formik.errors.tipo_veh}
              >
                {veh_types.map((opt) => (
                  <MenuItem key={opt.key} value={opt.value}>
                    {opt.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="comp"
              name="con_compartimento"
              label="¿Compartimentos?"
              fullWidth
              value={compartimento_opt.find((option) => option.value === formik.values.con_compartimento) || null}
              onChange={(event, value) => handleAutocompleteChange('con_compartimento', value)}
              options={compartimento_opt || []}
              getOptionLabel={(option) => option.text || ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="¿Compartimentos?"
                  error={errors.con_compartimento}
                  helperText={errors.con_compartimento ? 'Debe seleccionar si tiene o no compartimentos' : ''}
                  variant="outlined"
                />
              )}
            />
            {/* <FormControl fullWidth required>
              <InputLabel id="complb">¿Compartimentos?</InputLabel>
              <Select
                labelId="complb"
                id="comp"
                label="¿Compartimentos?"
                fullWidth
                value={formik.values.con_compartimento}
                onChange={(event) => {
                  formik.setFieldValue("con_compartimento", event.target.value);
                }}
                error={formik.errors.con_compartimento}
              >
                {compartimento_opt.map((opt) => (
                  <MenuItem key={opt.key} value={opt.value}>
                    {opt.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </Grid>

          {/* fila 2 */}
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              required
              label="Placa o IMO"
              name="placa_veh"
              placeholder="Placa o IMO"
              value={formik.values.placa_veh}
              onChange={formik.handleChange}
              error={formik.errors.placa_veh}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              required
              label="Capacidad total (gal)"
              name="capacidad"
              placeholder="Capacidad"
              value={formik.values.capacidad}
              onChange={formik.handleChange}
              error={formik.errors.capacidad}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              id="trans"
              fullWidth
              options={tablasFormatT || []}
              getOptionLabel={(option) => option.text || ''}
              value={tablasFormatT.find((option) => option.value === formik.values.id_trans) || null}
              onChange={(event, value) => handleAutocompleteChange('id_trans', value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Transportadora"
                  error={errors.id_trans}
                  helperText={errors.id_trans ? 'Debe seleccionar la transportadora' : ''}
                  variant="outlined"
                />
              )}
            />

            {/* <FormControl fullWidth required>
              <InputLabel id="condlb">Transportadora</InputLabel>
              <Select
                labelId="translb"
                id="trans"
                label="Transportadora"
                fullWidth
                value={formik.values.id_trans}
                onChange={(event) => {
                  formik.setFieldValue("id_trans", event.target.value);
                }}
                error={formik.errors.id_trans}
              >
                {tablasFormatT.map((opt) => (
                  <MenuItem key={opt.key} value={opt.value}>
                    {opt.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </Grid>

          {/* fila 3 */}
          <Grid item xs={12} sm={4}>
            <Autocomplete
              id="comb"
              options={Productos}
              getOptionLabel={(option) => option.text || ''}
              value={Productos.find((option) => option.value === formik.values.tipo_combustible) || null}
              onChange={(_, value) => handleAutocompleteChange('tipo_combustible', value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tipo de combustible"
                  variant="outlined"
                  error={errors.tipo_combustible}
                  helperText={errors.tipo_combustible ? 'Debe seleccionar el tipo de combustible' : ''}
                />
              )}
            />

            {/* <FormControl fullWidth required>
              <InputLabel id="comblb">Tipo de combustible</InputLabel>
              <Select
                labelId="comblb"
                id="comb"
                label="Tipo de combustible"
                fullWidth
                value={formik.values.tipo_combustible}
                onChange={(event) => {
                  formik.setFieldValue("tipo_combustible", event.target.value);
                }}
                error={formik.errors.tipo_combustible}
              >
                {Productos.map((opt) => (
                  <MenuItem key={opt.key} value={opt.value}>
                    {opt.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              required
              label="Cilindraje"
              name="cilindraje"
              placeholder="Cilindraje"
              value={formik.values.cilindraje}
              onChange={formik.handleChange}
              error={formik.errors.cilindraje}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              id="cond"
              options={Conductores}
              getOptionLabel={(option) => option.text || ''}
              value={Conductores.find((option) => option.value === formik.values.id_conductor) || null}
              onChange={(_, value) => handleAutocompleteChange('id_conductor', value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Conductor del vehículo"
                  variant="outlined"
                  error={errors.id_conductor}
                  helperText={errors.id_conductor ? 'Debe seleccionar un conductor' : ''}
                />
              )}
            />

            {/* <FormControl fullWidth required>
              <InputLabel id="condlb">Conductor del vehículo</InputLabel>
              <Select
                labelId="condlb"
                id="cond"
                label="Conductor del vehículo"
                fullWidth
                value={formik.values.id_conductor}
                onChange={(event) => {
                  formik.setFieldValue("id_conductor", event.target.value);
                }}
                error={formik.errors.id_conductor}
              >
                {Conductores.map((opt) => (
                  <MenuItem key={opt.key} value={opt.value}>
                    {opt.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </Grid>

          {/* fila 4 */}
          {formik.values?.tipo_veh && (
            <>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Marca del vehículo"
                  name="marca"
                  placeholder="Marca"
                  value={formik.values.marca}
                  onChange={formik.handleChange}
                  error={formik.errors.marca}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Modelo"
                  name="modelo"
                  placeholder="Modelo"
                  value={formik.values.modelo}
                  onChange={formik.handleChange}
                  error={formik.errors.modelo}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  id="carglb"
                  options={cargue_opt}
                  getOptionLabel={(option) => option.text || ''}
                  value={cargue_opt.find((option) => option.value === formik.values.tipo_cargue) || null}
                  onChange={(_, value) => handleAutocompleteChange('tipo_cargue', value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tipo de cargue"
                      variant="outlined"
                      error={errors.tipo_cargue}
                      helperText={errors.tipo_cargue ? 'Debe seleccionar el tipo de cargue' : ''}
                    />
                  )}
                />

                {/* <FormControl fullWidth required>
                  <InputLabel id="carglb">Tipo de cargue</InputLabel>
                  <Select
                    labelId="carglb"
                    id="carg"
                    label="Tipo de cargue"
                    fullWidth
                    value={formik.values.tipo_cargue}
                    onChange={(event) => {
                      formik.setFieldValue("tipo_cargue", event.target.value);
                    }}
                    error={formik.errors.tipo_cargue}
                  >
                    {cargue_opt.map((opt) => (
                      <MenuItem key={opt.key} value={opt.value}>
                        {opt.text}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
              </Grid>
            </>
          )}

          {/* fila 4 visible de acuerdo a condición */}
          {formik.values?.tipo_veh === "tracto_camion" && (
            <>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Numero de placa remolque"
                  name="id_remolque.placa_remolq"
                  placeholder="Placa del remolque"
                  value={formik.values?.id_remolque.placa_remolq}
                  onChange={formik.handleChange}
                  error={formik.errors.id_remolque?.placa_remolq}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Capacidad total del remolque"
                  name="id_remolque.capacidad"
                  placeholder="Capacidad"
                  value={formik.values?.id_remolque.capacidad}
                  onChange={formik.handleChange}
                  error={formik.errors.id_remolque?.capacidad}
                  type="number"
                />
              </Grid>
            </>
          )}

          {/* fila 5 */}
          <Grid item xs={12} sm={12}>
            {formik.values?.con_compartimento === "1" && (
              <>
                <div>
                  <Typography variant="h6">Compartimentos</Typography>
                </div>
                {formik.values?.id_remolque?.compartimientos.map((compartimineto, index) => (
                  <div key={index}>
                    <Grid container spacing={3}>
                      <Grid item xs={5}>
                        <TextField
                          required
                          fullWidth
                          label={`Capacidad compartimiento ${index + 1} (bbl)`}
                          name={`id_remolque.compartimientos[${index}].capacidad`}
                          placeholder={"Capacidad"}
                          value={compartimineto.capacidad}
                          onChange={formik.handleChange}
                          error={
                            formik.errors[
                            `id_remolque.compartimientos[${index}].capacidad`
                            ]
                          }
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <Autocomplete
                          id={`prodc${index}`}
                          options={Productos}
                          getOptionLabel={(option) => option.text || ''}
                          value={Productos.find((option) => option.value === compartimineto.producto) || null}
                          onChange={(event, value) => {
                            formik.setFieldValue(
                              `id_remolque.compartimientos[${index}].producto`, value?.value || ''
                            );

                            setErrors((prevErrors) => ({ ...prevErrors,  [`compartimientos${index}.producto`]: !value }));
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={"Combustible del compartimento " + (index + 1)} 
                              variant="outlined"
                              error={errors[`compartimientos${index}.producto`]}
                              helperText={errors[`compartimientos${index}.producto`] ? 'Debe seleccionar el tipo de combustible' : ''}
                            />
                          )}
                        />

                        {/* <FormControl fullWidth required>
                          <InputLabel id="prodclb">{`Producto compartimiento ${index + 1}`}</InputLabel>
                          <Select
                            labelId="prodclb"
                            id="prodc"
                            label={`Producto compartimiento ${index + 1}`}
                            fullWidth
                            value={compartimineto.producto}
                            onChange={(event) => {
                              formik.setFieldValue(
                                `id_remolque.compartimientos[${index}].producto`, event.target.value
                              )
                            }}
                            error={formik.errors[
                              `id_remolque.compartimientos[${index}].producto`
                            ]}
                          >
                            {Productos.map((opt) => (
                              <MenuItem key={opt.key} value={opt.value}>
                                {opt.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl> */}
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip title="Eliminar variable" arrow>
                          <Button
                            variant="outlined"
                            color="secondary"
                            style={{
                              height: '100%', display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              padding: '0',
                              margin: '0',
                              color: 'rgba(252, 0, 0, 0.8)',
                              border: '1px solid rgba(252, 0, 0, 0.8)',
                            }}
                            onClick={() =>
                              formik.setFieldValue(
                                "id_remolque.compartimientos",
                                formik.values.id_remolque.compartimientos.filter(
                                  (_, i) => i !== index
                                )
                              )
                            }
                          >
                            <DeleteIcon style={{ margin: '0' }} />
                          </Button>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <br />
                  </div>
                ))}

                <br />
                <Tooltip title="Agregar compartimento" arrow>
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    style={{
                      color: 'rgb(25, 118, 210)',
                      border: '1px solid rgb(25, 118, 210)',
                      '&:hover': {
                        color: 'rgba(221, 223, 221, 0.62)',
                        border: '1px solid rgba(221, 223, 221, 0.62)',
                      }
                    }}
                    onClick={() =>
                      formik.setFieldValue("id_remolque.compartimientos", [
                        ...formik.values.id_remolque.compartimientos,
                        { capacidad: "", producto: "" },
                      ])
                    }
                  >
                    <AddIcon />
                  </Button>
                </Tooltip>
                <br />
              </>
            )}
          </Grid>

          {/* fila 6 */}
          <Grid item xs={12} sm={12}>
            <FormControlLabel fullWidth
              control={
                <Checkbox
                  name='extras'
                  checked={formik.values.extras}
                  onChange={() => {
                    formik.setFieldValue('extras', !formik.values.extras);
                  }}
                />
              }
              label={<strong>¿Agregar campos extras?</strong>}
            />
          </Grid>
        </Grid>

        {/* fila 7 */}
        {formik.values.extras && (
          <>
            <Grid container spacing={3}>
              {/* fila 1 */}
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Tipo de Servicio"
                  name="tipo_Servicio"
                  placeholder="Tipo de Servicio"
                  value={formik.values.tipo_Servicio}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Número de Ejes"
                  name="numero_ejes"
                  placeholder="Número de Ejes"
                  value={formik.values.numero_ejes}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Potencia"
                  name="potencia"
                  placeholder="Potencia"
                  value={formik.values.potencia}
                  onChange={formik.handleChange}
                />
              </Grid>

              {/* fila 2 */}
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Documento del Propietario"
                  name="documento_propietario"
                  placeholder="Documento del Propietario"
                  value={formik.values.documento_propietario}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Nombre del Propietario"
                  name="propietario_nombre"
                  placeholder="Nombre del Propietario"
                  value={formik.values.propietario_nombre}
                  onChange={formik.handleChange}
                />
              </Grid>

              {/* fila 3 */}
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="fecha_matricula"
                  placeholder="Fecha de Matrícula"
                  value={formik.values.fecha_matricula}
                  onChange={formik.handleChange}
                  type="datetime-local"
                  helperText="Fecha de expedición de matrícula"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="fecha_venc_matricula"
                  placeholder="Fecha de Vencimiento de Matrícula"
                  value={formik.values.fecha_venc_matricula}
                  onChange={formik.handleChange}
                  type="datetime-local"
                  helperText="Fecha de vencimiento de matrícula"
                />
              </Grid>

              {/* fila 3 */}
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  label="Observaciones"
                  name="Observaciones"
                  placeholder="Observaciones"
                  value={formik.values.Observaciones}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
          </>
        )}

        <br />

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Button type="submit" variant="contained" color="error" className="btn btn-danger" fullWidth>
              {Vehiculos ? "Actualizar" : "Crear"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

function initialValues(data) {
  return {
    placa_veh: data?.placa_veh || "",
    marca: data?.marca || "",
    modelo: data?.modelo || "",
    capacidad: data?.capacidad || "",
    id_trans: data?.id_trans || "",
    tipo_veh: data?.tipo_veh || "",
    con_compartimento: data?.con_compartimento || "",
    tipo_cargue: data?.tipo_cargue || "",
    cilindraje: data?.cilindraje || "",
    tipo_combustible: data?.tipo_combustible || null,
    tipo_Servicio: data?.tipo_Servicio || "",
    extras: data?.extras ? 1 : 0,

    numero_ejes: data?.numero_ejes || "",
    potencia: data?.potencia || "",
    documento_propietario: data?.documento_propietario || "",
    propietario_nombre: data?.propietario_nombre || "",
    fecha_matricula: data?.fecha_matricula || "",
    fecha_venc_matricula: data?.fecha_venc_matricula || "",
    Observaciones: data?.Observaciones || "",
    id_remolque: {
      placa_remolq: data?.id_remolque?.placa_remolq || "",
      capacidad: data?.id_remolque?.capacidad || "",
      compartimientos: data?.id_remolque?.compartimientos || [],
    },
    id_conductor: data?.id_conductor || 0,
  };
}


function newSchema() {
  return {
    placa_veh: Yup.string().required("Este campo es obligatorio"),
    marca: Yup.string(),
    modelo: Yup.string(),
    capacidad: Yup.number(),
    id_trans: Yup.string(),
    tipo_veh: Yup.string().required("Debe seleccionar un tipo de vehículo"),
    id_conductor: Yup.number().required("seleccione un conductor asociado"),
  };
}

function updateSchema() {
  return {
    placa_veh: Yup.string().required("Este campo es obligatorio"),
    marca: Yup.string(),
    modelo: Yup.string(),
    capacidad: Yup.number(),
    id_trans: Yup.string(),
    // campo_carro_tanque: Yup.string().when("tipo_veh", {
    //   is: "carro_tanque",
    //   then: Yup.string().required("Este campo es obligatorio"),
    // }),
    // campo_barcaza: Yup.string().when("tipo_veh", {
    //   is: "barcaza",
    //   then: Yup.string().required("Este campo es obligatorio"),
    // }),
    // campo_buque: Yup.string().when("tipo_veh", {
    //   is: "buque",
    //   then: Yup.string().required("Este campo es obligatorio"),
    // }),
    // campo_tracto_camion: Yup.string().when("tipo_veh", {
    //   is: "tracto_camion",
    //   then: Yup.string().required("Este campo es obligatorio"),
    // }),
    tipo_veh: Yup.string().required("Debe seleccionar un tipo de vehículo"),
    id_conductor: Yup.number().required("seleccione un conductor asociado")
  };
}
