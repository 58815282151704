import { createSlice } from "@reduxjs/toolkit";

// The initial state of the Global container
export const initialState = {
  main: {
    receipt: [],
    receiptTable: [],
  },
};

const slice = createSlice({
  name: "receipt",
  initialState,
  reducers: {
    // Actions
    setReceipt: (state, action) => {
      state.main.receipt = action.payload;
    },
    setReceiptTable: (state, action) => {
      state.main.receiptTable = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = slice;
