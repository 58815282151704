import React, { useEffect, useState, useCallback } from "react";
import Swal from "sweetalert2"
import { AddEditEmisiones } from "../../../Components/Client";
import { useEmisiones } from "../../../hooks";

export function Funciones() {
    const { deleteEmisiones } = useEmisiones();
    const [showModal, setShowModal] = useState(false);
    const [titleModal, setTitleModal] = useState(null);
    const [contentModal, setContentModal] = useState(null);
    const [refetch, setRefetch] = useState(false);

    const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
    const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);

    const handleAdd = useCallback(() => {
        setTitleModal("Nuevo registro de emisiones");
        setContentModal(
            <AddEditEmisiones onClose={openCloseModal} onRefetch={onRefetch} />
        );
        openCloseModal();
    }, [openCloseModal, onRefetch]);

    const handleUpdate = useCallback(
        (data) => {
            setTitleModal("Actualizar registro de emisiones");
            setContentModal(
                <AddEditEmisiones
                    onClose={openCloseModal}
                    onRefetch={onRefetch}
                    Emisiones={data}
                />
            );
            openCloseModal();
        },
        [openCloseModal, onRefetch]
    );

    const handleDelete = useCallback(
        async (data) => {
            const result = await Swal.fire({
                title: "Estas seguro?",
                text: "No podrás revertir esta acción!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Si, eliminar!",
                cancelButtonText: "No, cancelar!",
                reverseButtons: true,
            });

            if (result.isConfirmed) {
                await deleteEmisiones(data.id_registro);
                Swal.fire("Eliminado!", "El dato ha sido eliminado.", "success");
                onRefetch();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire("Cancelado", "Operación cancelada", "error");
            }
        },
        [deleteEmisiones, onRefetch]
    );

    return {
        handleAdd,
        handleUpdate,
        handleDelete,
        openCloseModal,
        refetch,
        contentModal,
        titleModal,
        showModal,
    }
}