import { useState } from "react";
import {
  getMappingApi,
  updateMappingApi,
  addMappingApi,
  deleteMappingApi,
} from "../../api/A_Conexiones/mapping";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "..";

export function useMapping() {
  const { auth, logout } = useAuth();
  const { unauthorized, SessionExpired, unauthorizedModal, errores } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    Mapping: null,
    MappingDB: null,
  });

  const { loading, error, Mapping, MappingDB } = state;

  const handleAddOrUpdateError = (result) => {
    if (result?.nit_Mapping) {
      throw new Error("Ya existe un Mapping registrado con este NIT");
    } else {
      errores();
    }
  };

  const handleResponse = (response) => {
    if (response.status === 401) {
      SessionExpired();
      logout();
    } else if (response.status === 403) {
      unauthorized();
    }
  };

  const getMappingDB = async () => {
    try {
      const [result, response] = await getMappingApi(auth.token, auth.me.tenant);
      setState((prevState) => ({ ...prevState, MappingDB: result.results }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  const getMapping = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getMappingApi(auth.token, auth.me.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, Mapping: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addMapping = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addMappingApi(data, auth.token, auth.me.tenant);
      if (response.status === 400) {
        handleAddOrUpdateError(result);
      }
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateMapping = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateMappingApi(id, data, auth.token, auth.me.tenant);
      if (response.status === 400) {
        handleAddOrUpdateError(result);
      }
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteMapping = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteMappingApi(id, auth.token, auth.me.tenant);
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    MappingDB,
    Mapping,
    getMappingDB,
    getMapping,
    addMapping,
    updateMapping,
    deleteMapping,
  };
}
