export const columns = [
    {
        header: "Código Producto",
        name: "codigo_producto",
        maxWidth: 1000,
        defaultFlex: 1
    },
    {
        header: "Identificacion relacion",
        name: "nombre",
        maxWidth: 1000,
        defaultFlex: 1
    },
    // {
    //     header: "API BSW",
    //     name: "api_bsw",
    //     maxWidth: 1000,
    //     defaultFlex: 1
    // },
    // {
    //     header: "Variables",
    //     name: "variables",
    //     maxWidth: 1000,
    //     defaultFlex: 1
    // },
    {
        header: "Producto",
        name: "nombre_producto",
        maxWidth: 1000,
        defaultFlex: 1
    },
    {
        header: "Tanque",
        name: "nombre_tanque",
        maxWidth: 1000,
        defaultFlex: 1
    }
];

export const filterValue = [
    { name: 'id', operator: 'equals', type: 'number', value: null },
    { name: 'codigo_producto', operator: 'startsWith', type: 'string', value: '' },
    { name: 'nombre', operator: 'contains', type: 'string', value: '' },
    { name: 'api_bsw', operator: 'equals', type: 'number', value: null },
    { name: 'variables', operator: 'equals', type: 'number', value: null },
    { name: 'nombre_producto', operator: 'startsWith', type: 'string', value: '' },
    { name: 'nombre_tanque', operator: 'startsWith', type: 'string', value: '' }
];
