import { useFormik } from "formik";
import React from "react";
import { toast } from "react-toastify";
// import { Button, Form,Input,Select} from "semantic-ui-react";
import * as Yup from "yup";
import { useAccesos } from "../../../hooks";
import { alertas } from "../../Comons/Alertas/alertas";
import { FormControl, MenuItem, Select, InputLabel, Autocomplete } from "@mui/material";
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import TextField from '@mui/material/TextField'

export function AddEditAccesos(props) {
  /*
Componente

-componente de visualizacion del modal de edicion y creacion para ParametrizacionAccesos
 
-se crea y valida mediante formik y yup
-Se obtienen datos para dropdown mediante hooks
-se obtiene los datos (para edicion) y funciones correspondientes mediante props desde su PageAdmin
 */

  const { onClose, onRefetch } = props;

  const { addAccesos } = useAccesos();
  const { Successful } = alertas();

  const [errors, setErrors] = React.useState({
    Evento: false,
    proceso_a: false
  });

  const formik = useFormik({
    // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
    initialValues: initialValues(),
    // Establece la validación del esquema utilizando Yup, si se proporciona un objeto  se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
    validationSchema: Yup.object(AddSchame()),
    // Desactiva la validación al cambiar los valores del formulario
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        // Si se proporciona un objeto se actualiza, de lo contrario se crea uno nuevo
        await addAccesos(formValue);
        Successful()
        onRefetch();
        onClose();
      } catch (error) {
        // Muestra un mensaje de error si ocurre algún problema
        toast.error(error.message, {
          position: "top-center",
        });
      }
    },
  });

  const handleAutocompleteChange = (name, value) => {
    formik.setFieldValue(name, value?.value || '');
    setErrors((prevErrors) => ({ ...prevErrors, [name]: !value }));
  };

  return (
    <>
      <form className="add-edit-secciones-form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              required
              fluid
              label="Numero de orden"
              name="id_Orden"
              placeholder="Numero orden"
              value={formik.values.id_Orden}
              onChange={formik.handleChange}
              error={formik.errors.id_Orden}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Autocomplete
              id="evento"
              name="Evento"
              label="Tipo de evento"
              fullWidth
              value={Options.find((option) => option.value === formik.values.Evento) || null}
              onChange={(event, value) => handleAutocompleteChange('Evento', value)}
              options={Options || []}
              getOptionLabel={(option) => option.text || ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tipo de evento"
                  error={errors.Evento}
                  helperText={errors.Evento ? 'Debe seleccionar un tipo de evento' : ''}
                  variant="outlined"
                />
              )}
            />
            {/* <FormControl fullWidth>
              <InputLabel id="eventolb">Tipo de evento</InputLabel>
              <Select
                labelId="eventolb"
                id="evento"
                label="Tipo de evento"
                fullWidth
                value={formik.values.Evento}
                onChange={(event) => {
                  formik.setFieldValue('Evento', event.target.value)
                }}
                error={formik.errors.Evento}
              >
                {Options.map((option) => (
                  <MenuItem key={option.key} value={option.value}>
                    {option.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </Grid>

          <Grid item xs={12} sm={4}>
            <Autocomplete
              id="proceso"
              name="proceso_a"
              label="Tipo de proceso"
              fullWidth
              value={OptionsProcesos.find((option) => option.value === formik.values.proceso_a) || null}
              onChange={(event, value) => handleAutocompleteChange('proceso_a', value)}
              options={OptionsProcesos || []}
              getOptionLabel={(option) => option.text || ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tipo de proceso"
                  error={errors.proceso_a}
                  helperText={errors.proceso_a ? 'Debe seleccionar un tipo de proceso' : ''}
                  variant="outlined"
                />
              )}
            />
            {/* <FormControl fullWidth>
              <InputLabel id="procesolb">Tipo de proceso</InputLabel>
              <Select
                labelId="procesolb"
                id="proceso"
                label="Tipo de proceso"
                fullWidth
                value={formik.values.proceso_a}
                onChange={(event) => {
                  formik.setFieldValue('proceso_a', event.target.value)
                }}
                error={formik.errors.proceso_a}
              >
                {OptionsProcesos.map((option) => (
                  <MenuItem key={option.key} value={option.value}>
                    {option.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </Grid>

          <Grid item xs={12} sm={12}>
            <Button fullWidth type="submit" variant="contained" className="btn btn-danger" color="error">Crear</Button>
          </Grid>
        </Grid>
      </form>
    </>

  )
}

function initialValues() {
  return {
    id_Orden: "",
    Evento: "",
    proceso_a: "",
  };
}
function AddSchame() {
  return {
    id_Orden: Yup.number().required(true),
    Evento: Yup.number().required(true),
    proceso_a: Yup.string().required(true),


  };
}
const Options = [
  {
    key: "1",
    text: "Entrada",
    value: "1",
  },
  {
    key: "2",
    text: "Salida",
    value: "2",
  },
];

const OptionsProcesos = [
  {
    key: "1",
    text: "Cargadero",
    value: "1",
  },
  {
    key: "2",
    text: "Descargadero",
    value: "2",
  },
];