import React, { useEffect, useState } from "react";
import { Loader } from "semantic-ui-react";
import {
  AddEditSistemaMedicion, TablaSistemaMedicion
} from "../../../Components/Client";
import { ModalBasic ,DataGridBasic} from "../../../Components/Comons";
import { useSistemaMedicion } from "../../../hooks";
import { columns,filterValue } from "./SistemaFormat";

export  function SistemaMedicionAdmin() {
    const { loading, SistemaMedicion, getSistemaMedicion } = useSistemaMedicion();
    const [refetch, setRefetch] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [titleModal, setTitleModal] = useState(null);
    const [contentModal, setContentModal] = useState(null);
  
    useEffect(() => {
        getSistemaMedicion();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetch]);
  
    const onRefetch = () => setRefetch((prev) => !prev);
    const openCloseModal = () => setShowModal((prev) => !prev);
  
    const updateSistemaMedicion = (data) => {
      setTitleModal("Actualizar sistema Medicion");
      setContentModal(
        <AddEditSistemaMedicion
          onClose={openCloseModal}
          onRefetch={onRefetch}
          sistemaMedicion={data}
        />
      );
      openCloseModal();
    };
  return (
    <>
      {loading ? (
        <Loader active inline="centered">
          Cargando...
        </Loader>
      ) : (
        <DataGridBasic
        data={SistemaMedicion}
        columns={columns}
        id="id_sistema"
        filterValue={filterValue}
        updateData={updateSistemaMedicion}
        />
      )}
      

      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        title={titleModal}
        children={contentModal}
      />
    </>
  )
}
