export const columns = [
    {
        name: "nombre",
        header: "Nombre",
        maxWidth: 1000,
        defaultFlex: 1
    },
    {
        name: "conductor",
        header: "Conductor",
        maxWidth: 1000,
        defaultFlex: 1,
        render: ({ value }) => (value ? "Activo" : "Inactivo")
    },
    {
        name: "placa_vehiculo",
        header: "Placa Vehiculo",
        maxWidth: 1000,
        defaultFlex: 1,
        render: ({ value }) => (value ? "Activo" : "Inactivo")
    },
    {
        name: "placa_remolque",
        header: "Placa Remolque",
        maxWidth: 1000,
        defaultFlex: 1,
        render: ({ value }) => (value ? "Activo" : "Inactivo")
    },
    {
        name: "transportadora",
        header: "Transportadora",
        maxWidth: 1000,
        defaultFlex: 1,
        render: ({ value }) => (value ? "Activo" : "Inactivo")
    },
];


  export const filterValue = [
    { name: 'nombre', operator: 'startsWith', type: 'string', value: '' },
    
  ];