
import { BASE_API } from "../utils/constants";
import { makeRequest } from "./Request";
const BASE_URL = `${BASE_API}/`;

// Función para obtener los datos 
export async function getFraccionesApi(token, schema) {
  const url = `${BASE_URL}${schema}/aforo/fraccionesC`;
  return makeRequest(url, "GET", token);
}

// Función para agregar un nuevo dato
export async function addFraccionesApi(data, token, schema) {
  const url = `${BASE_URL}${schema}/aforo/fraccionesC/`;
  return makeRequest(url, "POST", token, data);
}

// Función para actualizar los datos 
export async function updateFraccionesApi(id, data, token, schema) {
  const url = `${BASE_URL}${schema}/aforo/fraccionesC/${id}/`;
  return makeRequest(url, "PUT", token, data);
}

// Función para eliminar 
export async function deleteFraccionesApi(id, token, schema) {
  const url = `${BASE_URL}${schema}/aforo/fraccionesC/${id}/`;
  return makeRequest(url, "DELETE", token);
}

 

// Función para obtener los datos 
export async function getSeccionesApi(token, schema) {
  const url = `${BASE_URL}${schema}/aforo/seccionesC`;
  return makeRequest(url, "GET", token);
}

// Función para agregar un nuevo dato
export async function addSeccionesApi(data, token, schema) {
  const url = `${BASE_URL}${schema}/aforo/seccionesC/`;
  return makeRequest(url, "POST", token, data);
}

// Función para actualizar los datos 
export async function updateSeccionesApi(id, data, token, schema) {
  const url = `${BASE_URL}${schema}/aforo/seccionesC/${id}/`;
  return makeRequest(url, "PUT", token, data);
}

// Función para eliminar 
export async function deleteSeccionesApi(id, token, schema) {
  const url = `${BASE_URL}${schema}/aforo/seccionesC/${id}/`;
  return makeRequest(url, "DELETE", token);
}



  // Función para obtener los datos 
export async function getTablaAforoApi(token, schema) {
  const url = `${BASE_URL}${schema}/aforo/aforoC`;
  return makeRequest(url, "GET", token);
}

// Función para agregar un nuevo dato
export async function addTablaAforoApi(data, token, schema) {
  const url = `${BASE_URL}${schema}/aforo/aforoC/`;
  return makeRequest(url, "POST", token, data);
}

// Función para actualizar los datos 
export async function updateTablaAforoApi(id, data, token, schema) {
  const url = `${BASE_URL}${schema}/aforo/aforoC/${id}/`;
  return makeRequest(url, "PUT", token, data);
}

// Función para eliminar 
export async function deleteTablaAforoApi(id, token, schema) {
  const url = `${BASE_URL}${schema}/aforo/aforoC/${id}/`;
  return makeRequest(url, "DELETE", token);
}
    //////////////////////////////////////////////////////



  // Función para obtener los datos 
  export async function getDataSeccionesApi(token, schema) {
    const url = `${BASE_URL}${schema}/aforo/dataSecciones`;
    return makeRequest(url, "GET", token);
  }
  
  // Función para agregar un nuevo dato
  export async function addDataSeccionesApi(data, token, schema) {
    const url = `${BASE_URL}${schema}/aforo/dataSecciones/`;
    return makeRequest(url, "POST", token, data);
  }
  
  // Función para actualizar los datos 
  export async function updateDataSeccionesApi(id, data, token, schema) {
    const url = `${BASE_URL}${schema}/aforo/dataSecciones/${id}/`;
    return makeRequest(url, "PUT", token, data);
  }
  
  // Función para eliminar 
  export async function deleteDataSeccionesApi(id, token, schema) {
    const url = `${BASE_URL}${schema}/aforo/dataSecciones/${id}/`;
    return makeRequest(url, "DELETE", token);
  }