import React, { useState } from 'react';
import {
  gallonsToLiters,
  barrelsToLiters,
  cubicMetersToLiters,
  litersToGallons,
  litersToBarrels,
  litersToCubicMeters,
  gallonsToBarrels,
  barrelsToGallons,
  cubicMetersToBarrels,
  barrelsToCubicMeters,
  cubicMetersToGallons,
  gallonsToCubicMeters,
} from './Funciones';

export function VolumeConverter() {
  const [value, setValue] = useState(0);
  const [fromUnit, setFromUnit] = useState('gallons');
  const [toUnit, setToUnit] = useState('liters');

  const handleInputChange = (event) => {
    setValue(event.target.value);
  };

  const handleFromSelectChange = (event) => {
    setFromUnit(event.target.value);
  };

  const handleToSelectChange = (event) => {
    setToUnit(event.target.value);
  };

  const convertValue = (value, fromUnit, toUnit) => {
    if (fromUnit === 'gallons' && toUnit === 'liters') {
      return gallonsToLiters(value);
    } else if (fromUnit === 'barrels' && toUnit === 'liters') {
      return barrelsToLiters(value);
    } else if (fromUnit === 'cubic-meters' && toUnit === 'liters') {
      return cubicMetersToLiters(value);
    } else if (fromUnit === 'liters' && toUnit === 'gallons') {
      return litersToGallons(value);
    } else if (fromUnit === 'liters' && toUnit === 'barrels') {
      return litersToBarrels(value);
    } else if (fromUnit === 'liters' && toUnit === 'cubic-meters') {
      return litersToCubicMeters(value);
    } else if (fromUnit === 'gallons' && toUnit === 'barrels') {
      return gallonsToBarrels(value);
    } else if (fromUnit === 'barrels' && toUnit === 'gallons') {
      return barrelsToGallons(value);
    } else if (fromUnit === 'cubic-meters' && toUnit === 'barrels') {
      return cubicMetersToBarrels(value);
    } else if (fromUnit === 'barrels' && toUnit === 'cubic-meters') {
      return barrelsToCubicMeters(value);
    } else if (fromUnit === 'cubic-meters' && toUnit === 'gallons') {
      return cubicMetersToGallons(value);
    }else if (fromUnit === 'gallons' && toUnit === 'cubic-meters') {
        return gallonsToCubicMeters(value);
    }else {
      return value;
    }
  };

  const renderOptions = () => {
    return (
      <>
        <option value="gallons">Gallons</option>
        <option value="liters">Liters</option>
        <option value="barrels">Barrels</option>
        <option value="cubic-meters">Cubic Meters</option>
      </>
    );
  };

  return (
    <div className="container py-5">
  <div className="row justify-content-center">
    <div className="col-lg-6">
      <div className="card p-3">
        <h2 className="text-center mb-4">Volumen a Convertir</h2>
        <div className="form-group">
          <label htmlFor="valueInput">Ingrese el valor:</label>
          <input 
            type="number" 
            className="form-control" 
            id="valueInput" 
            value={value} 
            onChange={handleInputChange} 
          />
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="fromUnitSelect">Convertir DE:</label>
            <select 
              className="form-control" 
              id="fromUnitSelect" 
              value={fromUnit} 
              onChange={handleFromSelectChange}
            >
              {renderOptions()}
            </select>
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="toUnitSelect">Convertir A:</label>
            <select 
              className="form-control" 
              id="toUnitSelect" 
              value={toUnit} 
              onChange={handleToSelectChange}
            >
              {renderOptions()}
            </select>
          </div>
        </div>
        <p className="text-center font-weight-bold mt-4">
            <strong>
          Valor convertido: {convertValue(value, fromUnit, toUnit)}
          </strong>
        </p>
      </div>
    </div>
  </div>
</div>
  );
  
    }

