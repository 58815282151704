import { Pagination } from '@mui/lab';
import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Dot from './Dot';

function getVolumen(tipo, conciliacion) {
  switch (tipo) {
    case '1':
      return conciliacion.volumen_ventas;
    case '2':
      return conciliacion.volumen_recargas;
    case '3':
      return conciliacion.perdida_evaporación;
    case '4':
      return conciliacion.derrame;
    default:
      return '';
  }
}

const headCells = [
  {
    id: 'cantidad_inicial',
    align: 'left',
    disablePadding: true,
    label: 'Inicial',
  },
  {
    id: 'tipo',
    align: 'left',
    disablePadding: true,
    label: 'Tipo',
  },
  {
    id: 'volumen',
    align: 'right',
    disablePadding: false,
    label: 'Volumen',
  },
  {
    id: 'cantidad_final',
    align: 'left',
    disablePadding: true,
    label: 'Final',
  },
  {
    id: 'fecha_conciliacion',
    align: 'right',
    disablePadding: false,
    label: 'Fecha Conciliación',
  },
];

function OrderTableHead({ order, orderBy }) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

OrderTableHead.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
};

const OrderStatus = ({ status }) => {
  let color;
  let title;

  switch (status) {
    case 0:
      color = 'warning';
      title = 'Pending';
      break;
    case 1:
      color = 'success';
      title = 'Approved';
      break;
    case 2:
      color = 'error';
      title = 'Rejected';
      break;
    default:
      color = 'primary';
      title = 'None';
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Dot color={color} />
      <Typography>{title}</Typography>
    </Stack>
  );
};

OrderStatus.propTypes = {
  status: PropTypes.number,
};

export default function OrderTable({ Conciliaciones }) {
  const [order] = useState('asc');
  const [orderBy] = useState('id_conciliaciones');
  const [selected] = useState([]);
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;

  const isSelected = (id) => selected.indexOf(id) !== -1;
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedConciliaciones = Conciliaciones.slice(startIndex, endIndex);

  return (
    <Box>
      <TableContainer
        sx={{
          width: '100%',
          overflowX: 'auto',
          position: 'relative',
          display: 'block',
          maxWidth: '100%',
        }}
      >
        <Table aria-labelledby="tableTitle">
          <OrderTableHead order={order} orderBy={orderBy} />
          <TableBody>
            {displayedConciliaciones.map((conciliacion) => {
              const isItemSelected = isSelected(conciliacion.id_conciliaciones);
              const labelId = `enhanced-table-checkbox-${conciliacion.id_conciliaciones}`;

              return (
                <TableRow
                  hover
                  role="checkbox"
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={conciliacion.id_conciliaciones}
                  selected={isItemSelected}
                >
                  <TableCell align="left">{conciliacion.volumen_inicial}</TableCell>
                  <TableCell align="left">
                    {conciliacion.tipo === '1' ? 'Volumen Ventas' : ''}
                    {conciliacion.tipo === '2' ? 'Volumen Recargas' : ''}
                    {conciliacion.tipo === '3' ? 'Perdida Evaporación' : ''}
                    {conciliacion.tipo === '4' ? 'Derrame' : ''}
                  </TableCell>
                  <TableCell align="right">{getVolumen(conciliacion.tipo, conciliacion)}</TableCell>
                  <TableCell align="left">{conciliacion.volumen_final}</TableCell>
                  <TableCell align="right">{conciliacion.fecha_conciliacion}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={Math.ceil(Conciliaciones.length / itemsPerPage)}
        page={page}
        onChange={(event, value) => setPage(value)}
      />
    </Box>
  );
}
