import { combineReducers } from "@reduxjs/toolkit";
import { isEmpty } from "ramda";

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */

export function createReducer(injectedReducers) {
  // Initially we don't have any injectedReducers, so returning identity function to avoid the error

  if (isEmpty(injectedReducers)) {
    return (state) => state;
  } else {
    return combineReducers({
      ...injectedReducers,
    });
  }
}
