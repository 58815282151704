import 'chartjs-adapter-date-fns';
import moment from 'moment';
import React, { useEffect, useState, useRef } from "react";
import { format } from 'date-fns';
import { Funciones } from "./Funciones";
import { filterValue, columns, TicketColumns, filterTicketData } from "./ConsumoFormat"
import { DataGridBasic, ModalBasic } from "../../../Components/Comons";
import { HeaderPage } from "../../../Components/Client";
import { DataGridNoOpt } from "../../../Components/Comons/TablaBasic";

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import 'leaflet-routing-machine';

// import { Tab, Tabs } from '@material-ui/core';
// import Tabs from "@mui/material/Tabs"
// import Tab from "@mui/material/Tab"
import { Tab as MuiTab, Tabs as MuiTabs } from '@mui/material/'
import CircularProgress from '@mui/material/CircularProgress';
// import { MenuItem, Select, Button, Grid, Checkbox, Paper, TablePagination, } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select"
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import TablePagination from '@mui/material/TablePagination';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Autocomplete } from '@mui/material';
import Typography from '@mui/material/Typography';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import markerIcon from '../../../assets/marker.png'

import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { useAuth, useTanques, useConductor, useConsumo, useControladores, useTcs3000, useProductoTanque, useProductoMezcla, useEmisiones } from "../../../hooks";

import ReportAreaChart from "../GestionCombGraphs/ReportAreaChart"
import AnalyticEcommerce from "../../../Components/Client/GestionCombustibleRep/AnalyticEcommerce";
import OverviewSales from "../../../sections/overview/overview-consumo";

import { Tab } from 'semantic-ui-react'

import { filterReportTable, reportTableColumns } from "./ConsumoFormat";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Bar, Doughnut } from 'react-chartjs-2';
import Divider from '@mui/material/Divider';
import AppOrderTimeline from "../../../Components/Client/Correcciones/AppOrderTimeline";

import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    TimeScale,
    Title,
    Tooltip,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ArcElement,
    LineElement,
    TimeScale,
);

let drawnRoutes = [];

export function FuelAdmin() {
    const { loading, Consumo, getConsumo, ConsumoByMonth, getConsumoByMonth,
        AvgConsumoLastMonth, getAvgConsumoLastMonth, ticketData, getTicketData } = useConsumo();
    const { CtrlRegCom, getControladorRegComb } = useControladores()
    const { tanquesDB, getTanquesDB } = useTanques()
    const { products, getTcsProd } = useTcs3000()
    const { ProductoTanque, getProductoTanque } = useProductoTanque()
    const { ProductoMezcla, getProductoMezcla } = useProductoMezcla()
    const { Emisiones, getEmisiones } = useEmisiones()
    const [mainActiveTab, setMainActiveTab] = useState(0)
    const [filterActiveTab, setFilterActiveTab] = useState(0)
    // const [groupedDataByRange, setGroupedDataByRange] = useState([]);
    const [transformData, setTransformData] = useState([])
    const [lastDate, setLastDate] = useState(null)
    const [selectedData, setSelectedData] = useState('Last 7 days')
    const [showLast30Days, setShowLast30Days] = useState(true);
    const [groupedByPlateData, setGroupedByPlateData] = useState([])
    const predefinedColors = ['blue', 'red', 'green', 'purple', 'orange'];
    const [avgKeysLastMonth, setAvgKeysLastMonth] = useState([])
    const [avgValuesLastMonth, setAvgValuesLastMonth] = useState([])
    const [avgPrevKeysLastMonth, setAvgKeysPrevLastMonth] = useState([])
    const [avgPrevValuesLastMonth, setAvgPrevValuesLastMonth] = useState([])
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentIndexFilter, setCurrentIndexFilter] = useState(0)
    const [currentIndexPlateFilter, setCurrentIndexPlateFilter] = useState(0)
    const [selectedTanque, setSelectedTanque] = useState('');
    const [selectedCtrl, setSelectedCtrl] = useState('')
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [tipoId, setTipoId] = useState("vehiculo");
    const [tipoOrigen, setTipoOrigen] = useState("tanque")
    //const [tipoOrigen, setTipoOrigen] = useState({ label: "tanque", value: "tanque" });
    const [selectedProd, setSelectedProd] = useState('')
    const [selectedTcsProd, setSelectedTcsProd] = useState('')
    const [inputValue, setInputValue] = useState("")
    const [filteredData, setFilteredData] = useState([])
    const [filterBtnClicked, setFilterBtnClicked] = useState(false)
    const [filteredPromData, setFilteredPromData] = useState([])
    const [filteredPromByPlateData, setFilteredPromByPlateData] = useState([])
    const [showOverview, setShowOverview] = useState(true)
    const [filteredEmisionesData, setFilteredEmisionesData] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [page, setPage] = useState(0);

    const rowsPerPage = 6;

    const {
        refetch,
        contentModal,
        titleModal,
        showModal,
        handleAdd,
        openCloseModal,
        handleShowTicket,
    } = Funciones();

    const [consumoByPlateChart, setConsumoByPlateChart] = useState({
        labels: [],
        datasets: [{
            data: []
        }]
    })

    const [emisionesByPlateChart, setEmisionesByPlateChart] = useState({
        labels: [],
        datasets: [{
            data: []
        }]
    })

    const [consumoByMonthChart, setConsumoByMonthChart] = useState({
        labels: [],
        datasets: [{
            label: 'Consumo en el mes',
            data: [],
            backgroundColor: 'rgba(0, 0, 0, 1)'
        }]
    })

    useEffect(() => {
        getConsumo()
        getConsumoByMonth()
        //getTanquesCI()
        getTanquesDB()
        getProductoTanque()
        getProductoMezcla()
        getAvgConsumoLastMonth()
        getTicketData()
        getControladorRegComb()
        getTcsProd()
        getEmisiones()
    }, [refetch])

    console.log(Consumo)

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handlePreviousGraph = () => {
        setShowOverview(true);
    };

    const handleNextGraph = () => {
        setShowOverview(false);
    };

    const handleMainTabChange = (event, newValue) => {
        setMainActiveTab(newValue)
    }

    const handleFilterTabChange = (event, newValue) => {
        setFilterActiveTab(newValue)
    }

    const handleNextClick = () => {
        setCurrentIndex((currentIndex + 1) % avgKeysLastMonth.length);
    }

    const handlePrevClick = () => {
        setCurrentIndex((currentIndex - 1 + avgKeysLastMonth.length) % avgKeysLastMonth.length)
    }

    const handleNextClickFilter = () => {
        setCurrentIndexFilter((currentIndexFilter + 1) % filteredPromData.length);
    }

    const handlePrevClickFilter = () => {
        setCurrentIndexFilter((currentIndexFilter - 1 + filteredPromData.length) % filteredPromData.length)
    }

    const handleNextClickFilterPlate = () => {
        setCurrentIndexPlateFilter((currentIndexPlateFilter + 1) % filteredPromByPlateData.length);
    }

    const handlePrevClickFilterPlate = () => {
        setCurrentIndexPlateFilter((currentIndexPlateFilter - 1 + filteredPromByPlateData.length) % filteredPromByPlateData.length)
    }

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleTanqueChange = (event) => {
        setSelectedTanque(event.target.value);
        setSelectedCtrl('')
        setSelectedTcsProd('')
    };

    const handleTanqueChange2 = (event, value) => {
        setSelectedTanque(value?.id_tanque);
        setSelectedCtrl('')
        setSelectedTcsProd('')
    };

    const handleCtrlChange = (event) => {
        setSelectedCtrl(event.target.value)
        setSelectedTanque('')
        setSelectedProd('')
    }

    const handleCtrlChange2 = (event, value) => {
        setSelectedCtrl(value?.vehicle?.id_maquina)
        setSelectedTanque('')
        setSelectedProd('')
    }

    const handleProdChange = (event) => {
        setSelectedProd(event.target.value)
    }

    const handleProdChange2 = (event, value) => {
        setSelectedProd(value?.id)
    }

    const handleTcsProdChange = (event) => {
        setSelectedTcsProd(event.target.value)
    }

    const handleTcsProdChange2 = (event, value) => {
        setSelectedTcsProd(value?.prod_id)
    }

    const handleOrigenChange = (event, value) => {
        setTipoOrigen(value)
        if (value === "tanque") {
            setSelectedTcsProd(undefined)
        } else {
            setSelectedProd(undefined)
        }
    }

    const handleClearClick = () => {
        // resetea todos los filtros a su valor inicial
        setStartDate(null);
        setEndDate(null);
        setSelectedTanque('');
        setTipoOrigen("tanque")
        setSelectedProd('');
        setSelectedTcsProd('')
        setSelectedCtrl('')
        setTipoId("vehiculo");
        setInputValue('');
    }

    const handleFilterClick = () => {
        setFilterBtnClicked(true)

        const formattedStartDate = startDate ? format(startDate, 'yyyy-MM-dd') : null;
        const formattedEndDate = endDate ? format(endDate, 'yyyy-MM-dd') : null;

        // let tanqueCheck = false
        // let ctrlCheck = false

        // busca los datos de acuerdo a los filtros
        const filteredConsumo = Consumo.filter((item) => {

            // parsea la fecha del objeto para que la comparación sea válida
            const formattedFechaCrea = format(new Date(item.fecha_crea), 'yyyy-MM-dd');
            const fechaCreaDate = new Date(formattedFechaCrea);

            let tipoIdCheck = true;
            if (tipoId === "vehiculo" && inputValue) {
                tipoIdCheck = item.placa_obj === inputValue;
            } else if (tipoId === "conductor" && inputValue) {
                tipoIdCheck = item.conductor.n_documento === inputValue;
            }

            // si se selecciona un tanque para el filtro o si se selecciona un producto relacionado con un tanque
            if (selectedTanque && !selectedCtrl && tipoOrigen === "tanque" || selectedProd) {
                const tanqueCheck = !selectedTanque || item.tank_or_vehicle_rel?.id_tanque === parseInt(selectedTanque);
                const prodCheck = !selectedProd || item.producto?.id === parseInt(selectedProd);

                // si no se provee una fecha inicial y final entonces devuelve todos los que encuentre
                // de otra forma devuelve los que estén en el rango de las fechas
                return prodCheck && tanqueCheck && tipoIdCheck && (
                    (!formattedStartDate || !formattedEndDate) ||
                    (fechaCreaDate >= startDate || !formattedStartDate) &&
                    (fechaCreaDate <= endDate || !formattedEndDate)
                );
            }

            // si se selecciona un controlador o si se selecciona un producto relacionado con el controlador
            if (selectedCtrl && !selectedTanque && tipoOrigen === "controlador" || selectedTcsProd) {
                const ctrlCheck = !selectedCtrl || item.tank_or_vehicle_rel?.id_maquina === parseInt(selectedCtrl);
                const prodCheck = !selectedTcsProd || item.producto.prod_id === selectedTcsProd;

                // si no se provee una fecha inicial y final entonces devuelve todos los que encuentre
                // de otra forma devuelve los que estén en el rango de las fechas
                return prodCheck && ctrlCheck && tipoIdCheck && (
                    (!formattedStartDate || !formattedEndDate) ||
                    (fechaCreaDate >= startDate || !formattedStartDate) &&
                    (fechaCreaDate <= endDate || !formattedEndDate)
                );
            }

            // devuelve los registros dentro de un rango de fechas, si no se proveé nada
            // entonces devuelve todos
            return tipoIdCheck && (
                (!formattedStartDate || !formattedEndDate) ||
                (fechaCreaDate >= startDate || !formattedStartDate) &&
                (fechaCreaDate <= endDate || !formattedEndDate)
            );
        });

        const filteredEmisiones = Emisiones.filter((emision) =>
            filteredConsumo.some((consumo) => consumo.placa_obj === emision.placa_obj)
        )

        // modifica las columnas para que sean correctamente mapeadas en la tabla
        const modifiedData = filteredConsumo.map((item) => ({
            ...item,
            //tipo_combustible_nombre: item.tank_or_vehicle_rel?.producto?.nombre ? item.tank_or_vehicle_rel?.producto?.nombre : item.producto?.prod_name ? item.producto.prod_name : "No ID",
            tipo_combustible_nombre: item.tank_or_vehicle === 1 ? item.producto.nombre : item.tank_or_vehicle === 2 ? item.producto?.prod_name : "No ID",
            origen: item.tank_or_vehicle_rel.nombre_tanque ? item.tank_or_vehicle_rel.nombre_tanque : item.tank_or_vehicle_rel.placa,
            desplazamiento: item.veh_or_maq.desplazamiento,
            cant_tot_combustible: item.veh_or_maq.cant_tot_combustible,
            nombre_op: item.operador.nombres + " " + item.operador.apellidos,
            nombre_cond: item.conductor.nombre + " " + item.conductor.apellidos,
        }));

        // consumo total por placa de vehículo y máquina
        const grupedByPlateData = {}

        // consumo de emisiones totales por vahículo 
        const groupedByEmisionesData = {}

        // agrupación por tipo de combustible
        const groupedByTipoCombustible = {};

        // agrupación por placa para promedio de consumo
        const groupedByPlate = {}

        // // agrupa los datos por placa y tipo de combustible
        filteredConsumo.forEach((item) => {
            const key = `${item.placa_obj}-${item.producto.nombre ? item.producto.nombre : item.producto.prod_name}`; // forma una llave con la placa y nombre del combustible
            const tipoCombustible = item.producto.nombre ? item.producto.nombre : item.producto.prod_name; // obtiene el tipo_combustible

            // va insertando los elementos al diccionario, si ya existe va sumando el valor de cant_consumo y lo inserta
            if (grupedByPlateData[key]) {
                grupedByPlateData[key].sum_cant_consumo += parseInt(item.cant_consumo, 10);
                grupedByPlateData[key].data.push(item)

            } else {
                // si no está en el diccionario entonces crea un objeto y añade los valores correspondientes
                grupedByPlateData[key] = {
                    placa_obj: item.placa_obj,
                    tipo_combustible: item.producto.nombre ? item.producto.nombre : item.producto.prod_name,
                    sum_cant_consumo: parseInt(item.cant_consumo, 10),
                    data: [item]
                };
            }

            // agrupa los datos para obtener el promedio de consumo por placa
            if (groupedByPlate[item.placa_obj]) {
                groupedByPlate[item.placa_obj].sum_cant_consumo += parseFloat(item.cant_consumo, 10)
                groupedByPlate[item.placa_obj].count++
            } else {
                groupedByPlate[item.placa_obj] = {
                    placa_obj: item.placa_obj,
                    sum_cant_consumo: parseFloat(item.cant_consumo, 10),
                    count: 1
                }
            }

            // si el dato ya está en el diccionario entonces va sumando el cant_consumo y cuenta la cantidad de ese dato para el promedio
            if (groupedByTipoCombustible[tipoCombustible]) {
                groupedByTipoCombustible[tipoCombustible].sum_cant_consumo += parseInt(item.cant_consumo, 10);
                groupedByTipoCombustible[tipoCombustible].count++;
            } else {
                // si no está entonces crea una nueva entrada
                groupedByTipoCombustible[tipoCombustible] = {
                    tipo_combustible: tipoCombustible,
                    sum_cant_consumo: parseFloat(item.cant_consumo, 10),
                    count: 1,
                };
            }
        });

        // promedio por placa
        const groupedByPlateArray = Object.values(groupedByPlate).map((group) => ({
            placa_obj: group.placa_obj,
            average_cant_consumo: (group.sum_cant_consumo / group.count).toFixed(2)
        }))

        // Convierte el objeto a un arreglo y va calculando los promedios por cada tipo_combustible
        const groupedByTipoCombustibleArray = Object.values(groupedByTipoCombustible).map((group) => ({
            tipo_combustible: group.tipo_combustible,
            average_cant_consumo: (group.sum_cant_consumo / group.count).toFixed(2),
        }))

        // Convierte el objeto a un arreglo
        const groupedByPlateDataArray = Object.values(grupedByPlateData);

        // establece los datos agrupados por la placa para que ponerlos en la gráfica
        setConsumoByPlateChart((prevData) => ({
            ...prevData,
            labels: groupedByPlateDataArray.map((item) => item.placa_obj),
            datasets: [
                {
                    data: groupedByPlateDataArray.map((item) => item.sum_cant_consumo),
                },
            ],
        }))

        if (filteredEmisiones.length > 0) {
            setFilteredEmisionesData(filteredEmisiones)

            // organiza los registros de emisiones por placa
            filteredEmisiones.forEach((emision) => {
                if (!groupedByEmisionesData[emision.placa_obj]) {
                    groupedByEmisionesData[emision.placa_obj] = {
                        sum: 0,
                        count: 0
                    };
                }
                groupedByEmisionesData[emision.placa_obj].sum += emision.emision;
                groupedByEmisionesData[emision.placa_obj].count++;
            })

            const emisionesLabels = Object.keys(groupedByEmisionesData)
            const emisionesData = emisionesLabels.map((placa_obj) => {
                const total = groupedByEmisionesData[placa_obj].sum
                return Math.round(total * 100) / 100;
            })

            setEmisionesByPlateChart((prevData) => ({
                ...prevData,
                labels: emisionesLabels,
                datasets: [{
                    data: emisionesData
                }]
            }))
        } else {
            setEmisionesByPlateChart(({
                labels: [],
                datasets: [{
                    data: []
                }]
            }))

            setFilteredEmisionesData([])
        }

        // establece los datos de la tabla con obtenidos por los filtros para que sean accedidos luego
        setFilteredData(modifiedData)

        // establece los datos de promedio por tipo de combustible obtenidos por los filtros
        setFilteredPromData(groupedByTipoCombustibleArray)

        // establece los datos del promedio agrupado por placa obtenidos por los filtros
        setFilteredPromByPlateData(groupedByPlateArray)
    }

    const handleRowSelect = (rowData) => {

        const selectedIndex = selectedRows.findIndex(
            (selectedRow) => selectedRow && selectedRow.id_registro === rowData.id_registro
        );

        let newSelected = [];

        if (selectedIndex === -1) {
            // Add the selected row if it doesn't exist in the array
            newSelected = newSelected.concat(selectedRows, rowData);
        } else if (selectedIndex === 0) {
            // Remove the row if it's the only selected row
            newSelected = newSelected.concat(selectedRows.slice(1));
        } else if (selectedIndex === selectedRows.length - 1) {
            // Remove the row if it's the last selected row
            newSelected = newSelected.concat(selectedRows.slice(0, -1));
        } else if (selectedIndex > 0) {
            // Remove the row if it's in the middle of the selected rows
            newSelected = newSelected.concat(
                selectedRows.slice(0, selectedIndex),
                selectedRows.slice(selectedIndex + 1)
            );
        }

        setSelectedRows(newSelected);
    };

    useEffect(() => {
        if (Consumo && ConsumoByMonth && ProductoTanque && AvgConsumoLastMonth && ticketData && CtrlRegCom && products && ProductoMezcla && Emisiones) {
            // const prodDict = {}

            // ProductoTanque.results.data_tanques?.forEach((element) => {
            //     prodDict[element.producto?.id] = element.producto?.nombre ? element.producto?.nombre : element.producto?.prod_name;
            // });

            // setProd(prodDict)
            console.log(AvgConsumoLastMonth)
            const avgConsumoLastMonth = AvgConsumoLastMonth[0]

            if (avgConsumoLastMonth) {
                const promConsumoLastMonth = avgConsumoLastMonth.prom_consumo_last_month;

                console.log(promConsumoLastMonth)

                if (promConsumoLastMonth) {
                    setAvgKeysLastMonth(Object.keys(promConsumoLastMonth));
                    setAvgValuesLastMonth(Object.values(promConsumoLastMonth));
                }

                const promPrevConsumoLastMonth = avgConsumoLastMonth.prom_prev_consumo_last_month;

                console.log(promPrevConsumoLastMonth)

                if (promPrevConsumoLastMonth) {
                    setAvgKeysPrevLastMonth(Object.keys(promPrevConsumoLastMonth));
                    setAvgPrevValuesLastMonth(Object.values(promPrevConsumoLastMonth));
                }
            }

            // agrupa los datos de la respuesta por fecha y suma la cantidad de consumo por cada día
            const groupedData = Consumo?.reduce((acc, item) => {
                const dateKey = moment(item.fecha_crea).format('YYYY-MM-DD');
                const combustibleKey = `${dateKey}-${item.tipo_combustible}`;
                if (!acc[combustibleKey]) {
                    acc[combustibleKey] = {
                        fecha_crea: dateKey,
                        sum_cant_consumo: 0,
                        tipo_combustible: item.producto?.nombre ? item.producto?.nombre : item.producto?.prod_name
                    };
                }
                acc[combustibleKey].sum_cant_consumo += parseInt(item.cant_consumo, 10);
                return acc;
            }, {});

            // obtención de la última fecha en los datos de la respuesta
            const lastDateFromResponse = Consumo?.reduce((acc, item) => {
                const itemDate = moment(item.fecha_crea);
                if (!acc || itemDate.isAfter(acc)) {
                    return itemDate;
                }
                return acc;
            }, null);

            // establece una constante con los datos
            setLastDate(lastDateFromResponse);

            // Conversión del objeto groupedData a array 
            const groupedDataArray = Object.values(groupedData);

            // Agrupación de los datos en un rango de siete y treinta días
            // const today = moment();
            const dateRanges = [
                { label: 'Last 7 days', range: 7 },
                { label: 'Last 30 days', range: 200 },
            ];

            // agrupa los datos por el rango establecido y los pone en un arreglo
            const groupedDataByRangeC = dateRanges?.map(({ label, range }) => {
                const startDate = lastDateFromResponse?.clone().subtract(range, 'days').startOf('day');
                const endDate = lastDateFromResponse?.clone().endOf('day');

                const filteredData = groupedDataArray?.filter(
                    (item) => moment(item.fecha_crea).isBetween(startDate, endDate, null, '[]')
                );

                return {
                    label,
                    data: filteredData,
                };
            });

            // Agrupa los datos por tipo_combustible por cada "label"
            const transformedData = groupedDataByRangeC?.map(({ label, data }) => {
                const groupedData = data.reduce((acc, curr) => {
                    const { tipo_combustible, ...rest } = curr;

                    if (!acc[tipo_combustible]) {
                        acc[tipo_combustible] = [];
                    }

                    acc[tipo_combustible].push({ tipo_combustible, ...rest });
                    return acc;
                }, {});

                // Ordena los valores por fecha
                Object.values(groupedData).forEach((array) => {
                    array.sort((a, b) => new Date(a.fecha_crea) - new Date(b.fecha_crea));
                });

                return {
                    [label]: groupedData
                };
            });

            setTransformData(transformedData)
            // setGroupedDataByRange(groupedDataByRangeC);

            // consumo por mes
            const consumoByMonthData = ConsumoByMonth.map((item) => {
                const monthKey = Object.keys(item)[0];
                return item[monthKey].tot_consumo_month;
            });
            const maxConsumoByMonth = Math.max(...consumoByMonthData);
            const monthLabels = ConsumoByMonth.map((item) => Object.keys(item)[0]);

            setConsumoByMonthChart((prevData) => ({
                ...prevData,
                labels: monthLabels,
                datasets: [
                    {
                        ...prevData.datasets[0],
                        data: consumoByMonthData,
                        backgroundColor: "rgba(92, 90, 90, 1)",
                        borderColor: "rgba(0, 0, 0, 1)",
                        borderWidth: 2
                    }
                ]
            }));
        }
    }, [Consumo, ConsumoByMonth, ProductoTanque, ticketData, CtrlRegCom, products, Emisiones]);

    const handleLineChartDataChange = () => {
        if (showLast30Days) {
            handleLast30DaysClick(); // llama la función para los últimos 30 días
        } else {
            handleLast7DaysClick(); // llama la función para los últimos 7 días
        }
        setShowLast30Days((prev) => !prev);
    }

    const handleLast7DaysClick = () => {
        setSelectedData('Last 7 days');
    };

    const handleLast30DaysClick = () => {
        setSelectedData('Last 30 days');
    };

    const getLineChartData = () => {
        const selectedDataObject = transformData.find((data) => data[selectedData]);
        if (selectedDataObject) {
            return Object.entries(selectedDataObject[selectedData]).map(([tipo_combustible, data], index) => ({
                label: tipo_combustible,
                data: data.map((item) => ({
                    x: item.fecha_crea,
                    y: item.sum_cant_consumo,
                })),
                borderColor: predefinedColors[index % predefinedColors.length]
            }));
        }
        return [];
    };

    const calculatePercentage = () => {
        let val = ((avgPrevValuesLastMonth[currentIndex] - avgValuesLastMonth[currentIndex]) / avgValuesLastMonth[currentIndex]) * 100

        if (val) {
            if (val < 0) {
                val *= -1
            }

            return val.toFixed(2)
        }

        return 0
    }

    // constante de referencia para mantener el estado del mapa
    const mapRef = useRef(null);

    // inicializa el mapa cuando carga el componente
    useEffect(() => {
        if (filteredData && filteredData.length > 0) {
            const leafletMap = L.map('map').setView([4.60971, -74.08175], 10);

            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '© OpenStreetMap contributors'
            }).addTo(leafletMap);

            mapRef.current = leafletMap;

            return () => {
                leafletMap.remove();
            };
        }

    }, [filteredData]);

    useEffect(() => {
        const map = mapRef.current;

        const currentRoutes = selectedRows.map(route => route.id_registro);
        const deletedRoutes = drawnRoutes.filter(route => !selectedRows.includes(route));

        deletedRoutes.forEach(route => {
            const routeIndex = drawnRoutes.findIndex(drawnRoute => drawnRoute.id === route.id);

            if (routeIndex !== -1) {
                map.removeLayer(drawnRoutes[routeIndex].startMarker);
                map.removeLayer(drawnRoutes[routeIndex].endMarker);
                map.removeControl(drawnRoutes[routeIndex].routingControl);

                drawnRoutes.splice(routeIndex, 1);
            }
        });

        if (selectedRows && selectedRows.length > 0 && map) {
            const customIcon = L.icon({
                iconUrl: markerIcon,
                iconSize: [32, 32], // tamaño del ícono
                iconAnchor: [16, 32], // ajuste del anclaje del ícono
            });

            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; OpenStreetMap contributors',
            }).addTo(map);

            const getRandomColor = () => {
                return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
            };

            const deletedRoutes = drawnRoutes.filter(route => !selectedRows.includes(route));

            // Remove routes corresponding to deleted elements
            deletedRoutes.forEach(route => {
                // Find the corresponding drawn route and remove it from the map
                // Here, assuming route is a unique identifier (e.g., ID) for each route
                // Modify this according to your route comparison logic
                // Assuming route is a unique identifier
                const routeIndex = drawnRoutes.findIndex(drawnRoute => drawnRoute.id === route.id);

                if (routeIndex !== -1) {
                    map.removeLayer(drawnRoutes[routeIndex].startMarker);
                    map.removeLayer(drawnRoutes[routeIndex].endMarker);
                    map.removeControl(drawnRoutes[routeIndex].routingControl);

                    // Remove the route from the drawnRoutes array
                    drawnRoutes.splice(routeIndex, 1);
                }
            });

            selectedRows.forEach((route) => {
                const randomColor = getRandomColor()

                const isRouteDrawn = drawnRoutes.some(drawnRoute => drawnRoute.id === route.id);
                const { plantaOrigenData, plantaDestinoData } = route;
                const { latitud: startLat, longitud: startLng } = plantaOrigenData.id_direcFc;
                const { latitud: endLat, longitud: endLng } = plantaDestinoData.id_direcFc;

                if (!isRouteDrawn) {
                    // Create start and end markers for each route
                    const startMarker = L.marker([parseFloat(startLat), parseFloat(startLng)], { icon: customIcon })
                        .addTo(map)
                        .bindPopup(route.plantaOrigenData.razon_social);

                    const endMarker = L.marker([parseFloat(endLat), parseFloat(endLng)], { icon: customIcon })
                        .addTo(map)
                        .bindPopup(route.plantaDestinoData.razon_social);

                    const routingControl = L.Routing.control({
                        waypoints: [
                            L.latLng(parseFloat(startLat), parseFloat(startLng)),
                            L.latLng(parseFloat(endLat), parseFloat(endLng)),
                        ],
                        router: L.Routing.osrmv1({ serviceUrl: 'https://router.project-osrm.org/route/v1' }), // servicion OSRM de la ruta
                        lineOptions: {
                            styles: [{ color: randomColor, opacity: 0.7, weight: 5 }],
                        },
                        routeWhileDragging: false,
                        show: false,
                        sidebar: false,
                        createMarker: function () {
                            return null; // oculta los marcadores por defecto del mapa
                        },
                    }).addTo(map);

                    drawnRoutes.push({
                        id: route.id_registro, // Use a unique identifier for the route
                        startMarker,
                        endMarker,
                        routingControl
                    });
                }
            });
        }
    }, [selectedRows]);

    useEffect(() => {
        if (tipoId === null || tipoId === undefined) {
            console.log("ENTRÓ")
            setTipoId('vehiculo')
        }
    }, [tipoId])

    useEffect(() => {
        if (tipoOrigen === null || tipoOrigen === undefined) {
            setTipoOrigen('tanque')
        }
    }, [tipoOrigen])

    const MemoizedModalBasic = React.memo(ModalBasic);

    const modifiedData = Consumo ? Consumo?.map((item) => ({
        ...item,
        //tipo_combustible_nombre: item.tank_or_vehicle_rel?.producto?.nombre ? item.tank_or_vehicle_rel?.producto?.nombre : item.producto?.prod_name ? item.producto.prod_name : "No ID",
        tipo_combustible_nombre: item.tank_or_vehicle === 1 ? item.producto?.nombre : item.tank_or_vehicle === 2 ? item?.producto?.prod_name : "No ID",
        origen: item.tank_or_vehicle_rel?.nombre_tanque ? item.tank_or_vehicle_rel?.nombre_tanque : item.tank_or_vehicle_rel?.placa,
        desplazamiento: item.veh_or_maq.desplazamiento,
        cant_tot_combustible: item.veh_or_maq.cant_tot_combustible,
        nombre_op: item.operador.nombres + " " + item.operador.apellidos,
        nombre_cond: item.conductor.nombre + " " + item.conductor.apellidos,
    })) : [];

    // datos del tiquete generado por la TCS
    const modifiedTicketData = ticketData ? ticketData?.map((item) => ({
        ...item,
        modelo: item.tcs_model_data.modelo,
        placa: item.tcs_model_data.vehicle.placa,
        conductor: item.tcs_model_data.vehicle.conductor.nombre + " " + item.tcs_model_data.vehicle.conductor.apellidos,
    })) : []

    const panes = [
        {
            menuItem: { key: 'sensores', content: <>Sensores</> },
            render: () => (
                <Tab.Pane>
                    <DataGridNoOpt
                        data={modifiedData}
                        title="Asignación de combustible"
                        id="id_table"
                        filterValue={filterValue}
                        columns={columns}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: { key: 'tag', content: <>Etiquetas</> },
            render: () => (
                <Tab.Pane>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={9}>
                            <Card>
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Resumen de consumo en el tiempo
                                    </Typography>
                                    <Grid item xs={12} sm={12}>
                                        <Button onClick={handleLineChartDataChange}>
                                            {showLast30Days ? 'últimos 6 meses' : 'Últimos 7 días'}
                                        </Button>
                                    </Grid>
                                    <ReportAreaChart data={getLineChartData()} />
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <Grid sx={{ mt: 2 }}>
                                <AnalyticEcommerce
                                    title="Promedio suministrado en el último mes"
                                    subTitle={avgKeysLastMonth[currentIndex]}
                                    count={avgValuesLastMonth[currentIndex]?.toString() === undefined ? "Sin datos suficientes" : avgValuesLastMonth[currentIndex]?.toString() + " Gal"}
                                    percentage={calculatePercentage() === 0 ? "" : calculatePercentage()}
                                    extra="0"
                                />
                            </Grid>
                            <Grid sx={{ mt: 1 }}>
                                <AnalyticEcommerce
                                    title="Promedio suministrado en el mes anterior"
                                    subTitle={avgPrevKeysLastMonth[currentIndex]}
                                    count={avgPrevValuesLastMonth[currentIndex]?.toString() === undefined ? "Sin datos suficientes" : avgPrevValuesLastMonth[currentIndex]?.toString() + " Gal"}
                                    color="error"
                                    extra="0"
                                />
                            </Grid>
                            <Grid container justifyContent="space-between" sx={{ mt: 2 }}>
                                <Button onClick={handlePrevClick}>
                                    <SkipPreviousIcon />
                                </Button>
                                <Button onClick={handleNextClick}>
                                    <SkipNextIcon />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <br></br>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Card>
                                <CardContent>
                                    <Grid item xs={12} sm={12}>
                                        <Typography variant="h5" component="div" style={{ textAlign: "left" }}>
                                            Filtros
                                        </Typography>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '16px' }}>
                                    <div style={{ marginBottom: '8px' }}>Fecha inicial</div>
                                    <DatePicker
                                        value={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Start Date" />
                                        )}
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '16px' }}>
                                    <div style={{ marginBottom: '8px' }}>Fecha final</div>
                                    <DatePicker
                                        value={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        renderInput={(params) => (
                                            <TextField {...params} label="End Date" />
                                        )}
                                    />
                                </div>
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '16px' }}>
                                        <div style={{ marginBottom: '8px' }}>Origen</div>
                                        <Autocomplete
                                            id="origen"
                                            value={tipoOrigen}
                                            onChange={handleOrigenChange}
                                            options={['tanque', 'controlador']}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '16px' }}>
                                        <div style={{ marginBottom: '8px' }}>{tipoOrigen === "tanque" ? "Tanque" : "Controlador"}</div>
                                        {tipoOrigen === "tanque" ? (
                                            <Autocomplete
                                                id="tanque"
                                                value={tanquesDB.find((opt) => opt.id_tanque === selectedTanque) || null}
                                                onChange={handleTanqueChange2}
                                                getOptionLabel={(option) => option.nombre_tanque || ''}
                                                options={tanquesDB || []}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        ) : (
                                            <Autocomplete
                                                id="controlador"
                                                value={CtrlRegCom.find((opt) => opt.vehicle.id_maquina === selectedCtrl) || null}
                                                onChange={handleCtrlChange2}
                                                getOptionLabel={(option) => option.modelo + " " + option.vehicle.placa || ''}
                                                options={CtrlRegCom || []}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid containear spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '16px' }}>
                                        <div style={{ marginBottom: '8px' }}>Producto</div>
                                        {tipoOrigen === "tanque" ? (
                                            <Autocomplete
                                                id="producto"
                                                value={ProductoMezcla.results?.find((prod) => prod.id === selectedProd) || null}
                                                onChange={handleProdChange2}
                                                getOptionLabel={(option) => option.nombre || ''}
                                                options={ProductoMezcla?.results || []}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        ) : (
                                            <Autocomplete
                                                id="product"
                                                value={products?.find((prod) => prod.prod_id === selectedTcsProd) || null}
                                                onChange={handleTcsProdChange2}
                                                getOptionLabel={(option) => option.prod_name || ''}
                                                options={products || []}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '16px' }}>
                                        <div style={{ marginBottom: '8px' }}>Tipo de identificación</div>
                                        <Autocomplete
                                            id="tipoId"
                                            value={tipoId}
                                            onChange={(event, value) => setTipoId(value)}
                                            options={['vehiculo', 'conductor']}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                />
                                            )}
                                            renderClearButton={(params) => (
                                                <span {...params} style={{ display: 'none' }} />
                                            )}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '16px' }}>
                                        <div style={{ marginBottom: '8px', width: '20rem' }}>
                                            {tipoId === "vehiculo" ? "Placa" : "Cédula"}
                                        </div>
                                        <TextField value={inputValue}
                                            onChange={(e) => setInputValue(e.target.value)}>
                                        </TextField>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={6}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '16px' }}>
                                <Button variant='contained' onClick={handleFilterClick}>Filtrar</Button>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '16px' }}>
                                <Button variant="outlined" onClick={handleClearClick}>Limpiar filtros</Button>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        {filteredData && filterBtnClicked ? (
                            filteredData.length > 0 ? (
                                <>
                                    <Grid item xs={12} sm={9}>
                                        {/* tabs */}
                                        <MuiTabs value={filterActiveTab} onChange={handleFilterTabChange}>
                                            <MuiTab label="Gráficas" />
                                            <MuiTab label="Tabla" />
                                        </MuiTabs>

                                        {/* zona de gráficas */}
                                        {filterActiveTab === 0 && (
                                            <>
                                                {showOverview ? (
                                                    <OverviewSales data={consumoByPlateChart} yAxisTitle={'Galones'} />
                                                ) : (
                                                    emisionesByPlateChart && (emisionesByPlateChart.labels.length !== 0 || emisionesByPlateChart.datasets[0].data.length !== 0) ? (
                                                        <>
                                                            <OverviewSales data={emisionesByPlateChart} yAxisTitle={'Kg de Co2'} />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Grid item xs={12} sm={12}>
                                                                <Typography variant="h5" component="div" style={{ textAlign: "center" }}>
                                                                    No hay datos que coincidan con la búsqueda
                                                                </Typography>
                                                            </Grid>
                                                        </>
                                                    )
                                                )}


                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={2}>
                                                        <Button fullWidth onClick={handlePreviousGraph} disabled={showOverview}>
                                                            <SkipPreviousIcon />
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12} sm={8}>
                                                        <Typography variant="h5" component="div" style={{ textAlign: "center" }}>
                                                            {showOverview ? "Total consumo por vehículo" : "Emisiones totales por vehículo"}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={2}>
                                                        <Button fullWidth onClick={handleNextGraph} disabled={!showOverview}>
                                                            <SkipNextIcon />
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )}

                                        {/* zona de la tabla */}
                                        {filterActiveTab === 1 && (
                                            <DataGridNoOpt
                                                data={filteredData}
                                                title="Asignación de combustible"
                                                id="id_table"
                                                filterValue={filterValue}
                                                columns={columns}
                                            />
                                        )}
                                    </Grid>

                                    {/* zona con la información en tarjetas con la información promediada de consumo en tarjetas */}
                                    {showOverview ? (
                                        <Grid item xs={12} sm={3}>
                                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '16px' }}>
                                                <Grid>
                                                    <AnalyticEcommerce
                                                        title="Promedio suministrado por producto"
                                                        subTitle={filteredPromData[currentIndexFilter]?.tipo_combustible ? filteredPromData[currentIndexFilter].tipo_combustible : ""}
                                                        count={filteredPromData[currentIndexFilter]?.average_cant_consumo ? filteredPromData[currentIndexFilter].average_cant_consumo + " Gal" : ""}

                                                    />
                                                    <Grid container justifyContent="space-between" sx={{ mt: 2 }}>
                                                        <Button onClick={handlePrevClickFilter}>
                                                            <SkipPreviousIcon />
                                                        </Button>
                                                        <Button onClick={handleNextClickFilter}>
                                                            <SkipNextIcon />
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                <Grid sx={{ mt: 1 }}>
                                                    <AnalyticEcommerce
                                                        title="Promedio suministrado por placa"
                                                        subTitle={filteredPromByPlateData[currentIndexPlateFilter]?.placa_obj ? filteredPromByPlateData[currentIndexPlateFilter]?.placa_obj : ""}
                                                        count={filteredPromByPlateData[currentIndexPlateFilter]?.average_cant_consumo ? filteredPromByPlateData[currentIndexPlateFilter].average_cant_consumo + " Gal" : ""}

                                                    />
                                                    <Grid container justifyContent="space-between" sx={{ mt: 2 }}>
                                                        <Button onClick={handlePrevClickFilterPlate}>
                                                            <SkipPreviousIcon />
                                                        </Button>
                                                        <Button onClick={handleNextClickFilterPlate}>
                                                            <SkipNextIcon />
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                    ) : (filteredEmisionesData && filteredEmisionesData.length > 0 ? (
                                        <>
                                            <Grid item xs={12} sm={3}>
                                                <TableContainer component={Paper}>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <StyledTableCell align="center"></StyledTableCell>
                                                                <StyledTableCell align="center">Placa</StyledTableCell>
                                                                <StyledTableCell align="center">Emisión</StyledTableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {(filteredEmisionesData || [])
                                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                                .map((emision) => (
                                                                    <StyledTableRow key={emision.id_registro} hover>
                                                                        <StyledTableCell>
                                                                            <Checkbox
                                                                                checked={selectedRows.some(
                                                                                    (row) => row && row.id_registro === emision.id_registro
                                                                                )}
                                                                                onChange={() => handleRowSelect(emision)}
                                                                            />
                                                                        </StyledTableCell>
                                                                        <StyledTableCell>{emision.placa_obj}</StyledTableCell>
                                                                        <StyledTableCell>{emision.emision} kg Co2</StyledTableCell>
                                                                    </StyledTableRow>
                                                                ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                <TablePagination
                                                    rowsPerPageOptions={[]}
                                                    component="div"
                                                    count={filteredEmisionesData ? filteredEmisionesData.length : 0}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onPageChange={handleChangePage}
                                                />
                                            </Grid>
                                        </>
                                    ) : (
                                        <>
                                            <Grid item xs={12} sm={3}>
                                                <Typography variant="h5" component="div" style={{ textAlign: "center" }}>
                                                    No hay datos que coincidan con la búsqueda
                                                </Typography>
                                            </Grid>
                                        </>
                                    ))}

                                    <Grid item xs={12} sm={12}>
                                        <div id="map" style={{ height: '550px' }}></div>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <Grid item xs={12} sm={12}>
                                        <Typography variant="h5" component="div" style={{ textAlign: "center" }}>
                                            No hay datos que coincidan con la búsqueda
                                        </Typography>
                                    </Grid>
                                </>
                            )
                        ) : null}
                    </Grid>
                </Tab.Pane>
            ),
        },
    ]

    return (
        <>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <HeaderPage title="Suministro de combustible" btnTitle="Nueva operación" icono="plus square icon" btnClick={handleAdd} />
                    <br />
                    <Tab panes={panes} />
                </>
            )}

            {showModal && (
                <MemoizedModalBasic
                    show={showModal}
                    onClose={openCloseModal}
                    title={titleModal}
                    children={contentModal}
                />
            )}
        </>
    )
}