import React, { useEffect } from "react";
import { Loader } from "semantic-ui-react";
import { HeaderPage } from "../../../Components/Client";
import { DataGridNoOpt, ModalBasic } from "../../../Components/Comons";
import { useAccesos } from "../../../hooks";
import { columns, filterValue } from "./AccesosFormat";
import { Funciones } from "./Funciones";
import CircularProgress from '@mui/material/CircularProgress';

export function AccesossAdmin() {
  const {
    refetch,
    contentModal,
    titleModal,
    showModal,
    handleAdd,
    openCloseModal,
  } = Funciones();
  const { loading, Accesos, getAccesos } = useAccesos();

  useEffect(() => {
    getAccesos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);
  const MemoizedModalBasic = React.memo(ModalBasic);

  return (
    <>
      <HeaderPage title="Accesos" btnTitle="Nuevo acceso" icono="plus square icon" btnClick={handleAdd} />
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (

        <DataGridNoOpt
          data={Accesos.results}
          title="Accesos"
          columns={columns}
          filterValue={filterValue}
        />

      )}

      {showModal && (
        <MemoizedModalBasic
          show={showModal}
          onClose={openCloseModal}
          title={titleModal}
          children={contentModal}
        />
      )}
    </>
  );
}
//Columnas que se envian en forma de props al componente tabla basic para representer sus campos

