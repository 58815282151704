import { BASE_API } from "../../utils/constants";
import { makeRequest } from "../Request";
const BASE_URL = `${BASE_API}/`;
  
  // Función para obtener los datos 
  export async function getControlDocVehiculosApi(token, schema) {
    let url = `${BASE_URL}${schema}/vehiculos/documentvehicle`;
    return makeRequest(url, "GET", token);
  }
  
  // Función para agregar un nuevo dato
  export async function addControlDocVehiculosApi(data, token,schema) {
    const url = `${BASE_URL}${schema}/vehiculos/documentvehicle/`;
    return makeRequest(url, "POST", token,  data);
  }
  
  // Función para actualizar los datos 
  export async function updateControlDocVehiculosApi(id, data, token,schema) {
    const url = `${BASE_URL}${schema}/vehiculos/documentvehicle/${id}/`;
    return makeRequest(url, "PATCH", token, data);
  }
  
  // Función para eliminar 
  export async function deleteControlDocVehiculosApi(id, token,schema) {
    const url = `${BASE_URL}${schema}/vehiculos/documentvehicle/${id}/`;
    return makeRequest(url, "DELETE", token);
  }