import React, { useEffect, useState } from 'react';
import { Loader } from "semantic-ui-react";
import CircularProgress from '@mui/material/CircularProgress';
import { AddEditIngresoDatosForm, HeaderPage, VisualizacionDatosIngresadosForm } from "../../../Components/Client";
import { ModalBasic, DataGridBasic } from "../../../Components/Comons";
import { useIngresoDatos } from "../../../hooks";
import { useAuth } from '../../../hooks';
import Swal from "sweetalert2"
import { filterValue } from "./ingresoFormat";
import { Funciones } from "./Funciones";

export function IngresoDatosAdmin() {
  const { loading, ingresoDatos, getIngresoDatos, } = useIngresoDatos();
  const {
    refetch,
    contentModal,
    titleModal,
    showModal,
    columns,
    handleAdd,
    handleDelete,
    handleUpdate,
    handleViews,
    openCloseModal,
  } = Funciones();

  useEffect(() => {
    getIngresoDatos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const MemoizedModalBasic = React.memo(ModalBasic);

  return (
    <>

      <HeaderPage title="Ingreso datos" btnTitle="Ingreso datos" icono="plus square icon" btnClick={handleAdd} />
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <DataGridBasic
          data={ingresoDatos.results.datos}
          title="Medicion manual"
          id="id_datos"
          filterValue={filterValue}
          columns={columns}
          updateData={handleUpdate}
          onDeleteData={handleDelete}
          visualizacionData={handleViews}
        />

      )}

      {showModal && (
        <MemoizedModalBasic
          show={showModal}
          onClose={openCloseModal}
          title={titleModal}
          children={contentModal}
        />
      )}
    </>
  )
}


