import React, { useEffect, useState } from "react";
// import { Tabs, Tab } from '@material-ui/core';
import { Tabs as MuiTabs, Tab as MuiTab } from "@mui/material";
import { Tab } from 'semantic-ui-react'
import Box from '@mui/material/Box'

import { ParametrizacionDecimalesComputador } from '../AdminBasePage/ParametrizacionDecimalesComputador'
import { Validaciones } from '../AdminBasePage/Validaciones'
import { ControlDocumentalAdmin } from '../ControlDocumentalPage/ControlDocumentalAdmin'
import { TankCustVerAdm } from '../VerificacionesPage/TankCustVerAdm'
import { VehCustVerAdm } from '../VerificacionesPage/VehCustVerAdm'
import { GruposPermisosAdmin } from '../../Admin/GruposPermisosAdmin/GruposPermisosAdmin'

export function GroupedClientPages() {

    const [subActiveTab, setSubActiveTab] = useState(0);

    const handleSubTabChange = (event, newValue) => {
        setSubActiveTab(newValue);
    }

    const panes = [
        {
            menuItem: { key: 'GruposPermisosAdmin', content: <>Permisos</> },
            render: () => <Tab.Pane><GruposPermisosAdmin /></Tab.Pane>,
        },
        {
            menuItem: { key: 'ParametrizacionDecimalesComputador', content: <>Decimales visualización</> },
            render: () => <Tab.Pane><ParametrizacionDecimalesComputador /></Tab.Pane>,
        },
        {
            menuItem: { key: 'Validaciones', content: <>Validaciones de prompts</> },
            render: () => <Tab.Pane><Validaciones /></Tab.Pane>,
        },
        {
            menuItem: { key: 'ControlDocumentalAdmin', content: <>Documentos</> },
            render: () => <Tab.Pane><ControlDocumentalAdmin /></Tab.Pane>,
        },
        {
            menuItem: { key: 'inspeccion', content: <>Inspección tanques y vehículos</> },
            render: () => (
                <Tab.Pane>
                    <Box display="flex" justifyContent="center">
                        <MuiTabs value={subActiveTab} onChange={handleSubTabChange} indicatorColor="primary" textColor="primary">
                            <MuiTab label="Tanques" />
                            <MuiTab label="Vehículos" />
                        </MuiTabs>
                    </Box>
                    <br />
                    {subActiveTab === 0 && (
                        <TankCustVerAdm />
                    )}
                    {subActiveTab === 1 && (
                        <VehCustVerAdm />
                    )}
                </Tab.Pane>
            )
        }
    ]

    return (
        <>
            <Tab panes={panes} />
        </>
    )
}