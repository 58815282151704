import {
  Box,
  Typography
} from "@mui/material";
import React, { useCallback, useEffect, useState } from 'react';
import { useModulos } from '../../../hooks';
import { GraySwitch } from "./styles";

import { DataGrid } from "@mui/x-data-grid";

export function TablaModulosPage() {
  const { loading, Modulos, getModulos, updateModulos } = useModulos();
  const [refetch, setRefetch] = useState(false);
  const [modulosData, setModulosData] = useState([]);

  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);

  useEffect(() => {
    getModulos();
  }, [refetch]);

  useEffect(() => {
    setModulosData(Modulos);
  }, [Modulos]);

  const handleModuleToggle = async (id, data) => {
    try {
      await updateModulos(id, data);
      // Actualizar los datos de la tabla después de una actualización exitosa
      setModulosData([...modulosData]);
      onRefetch();
    } catch (error) {
      console.error('Error al actualizar los módulos:', error);
    }
  };

  const columns = [
    { field: 'nombre', headerName: 'Nombre', width: 200 },
    {
      field: 'modulo_cargadero',
      headerName: 'Módulo Cargadero',
      width: 200,
      renderCell: (params) => (
        <GraySwitch
          checked={params.value}
          onChange={(event) =>
            handleModuleToggle(params.row.id, {
              ...params.row,
              modulo_cargadero: event.target.checked
            })
          }
        />
      )
    },
    {
      field: 'modulo_descargadero',
      headerName: 'Módulo Descargadero',
      width: 200,
      renderCell: (params) => (
        <GraySwitch
          checked={params.value}
          onChange={(event) =>
            handleModuleToggle(params.row.id, {
              ...params.row,
              modulo_descargadero: event.target.checked
            })
          }
        />
      )
    },
    {
      field: 'modulo_almacenamiento',
      headerName: 'Módulo Almacenamiento',
      width: 200,
      renderCell: (params) => (
        <GraySwitch
          checked={params.value}
          onChange={(event) =>
            handleModuleToggle(params.row.id, {
              ...params.row,
              modulo_almacenamiento: event.target.checked
            })
          }
        />
      )
    }
    ,
    {
      field: 'modulo_gestion',
      headerName: 'Módulo Gestion de combustible',
      width: 200,
      renderCell: (params) => (
        <GraySwitch
          checked={params.value}
          onChange={(event) =>
            handleModuleToggle(params.row.id, {
              ...params.row,
              modulo_gestion: event.target.checked
            })
          }
        />
      )
    }
    ,
    {
      field: 'modulo_accesos',
      headerName: 'Módulo Accesos',
      width: 200,
      renderCell: (params) => (
        <GraySwitch
          checked={params.value}
          onChange={(event) =>
            handleModuleToggle(params.row.id, {
              ...params.row,
              modulo_accesos: event.target.checked
            })
          }
        />
      )
    }
    ,
    {
      field: 'modulo_turnos',
      headerName: 'Módulo Turnos',
      width: 200,
      renderCell: (params) => (
        <GraySwitch
          checked={params.value}
          onChange={(event) =>
            handleModuleToggle(params.row.id, {
              ...params.row,
              modulo_turnos: event.target.checked
            })
          }
        />
      )
    }
  ];

  return (
   <>
      
         <Box sx={{ height: 550, width: "100%" }}>
         
         <br />
         {Modulos && Modulos.length > 0 ? (
           <DataGrid rows={Modulos} columns={columns} pageSize={5} />
         ) : (
           <Typography variant="body1">No hay datos disponibles</Typography>
         )}
   
        
       </Box>
      
    </>
  );
}
