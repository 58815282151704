import React, { useEffect } from "react";
import { Funciones } from "./Funciones";
import { filterValue } from "./MaquinariaFormat"
// import { Loader } from "semantic-ui-react";
import { DataGridBasic, ModalBasic } from "../../../Components/Comons";
import { HeaderPage } from "../../../Components/Client";
import { useMaquinas } from "../../../hooks";
import CircularProgress from '@mui/material/CircularProgress';

export function MaquinariaAdmin() {
    const { loading, Maquinas, getMaquinas } = useMaquinas();

    const {
        refetch,
        contentModal,
        titleModal,
        showModal,
        columns,
        handleAdd,
        handleDelete,
        handleUpdate,
        openCloseModal,
    } = Funciones();

    const MemoizedModalBasic = React.memo(ModalBasic);

    useEffect(() => {
        try {
            getMaquinas()
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [refetch])

    const modifiedData = Maquinas?.map((item) => ({
        ...item,
        prim_combustible: item.combustible?.prod_name
    }));

    return (
        <>
            <HeaderPage
                title="Vehículos & Maquinas"
                btnTitle="Nueva máquina"
                icono="plus square icon"
                btnClick={handleAdd}
            />

            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    {Maquinas ? (
                        <DataGridBasic
                            data={modifiedData}
                            title="Maquinaria"
                            id="id_maquinaria"
                            filterValue={filterValue}
                            columns={columns}
                            updateData={handleUpdate}
                            onDeleteData={handleDelete}
                        />
                    ) : (
                        <div>No hay maquinas</div>
                    )}
                </>
            )}

            {showModal && (
                <MemoizedModalBasic
                    show={showModal}
                    onClose={openCloseModal}
                    title={titleModal}
                    children={contentModal}
                />
            )}
        </>
    )
}