import React, { useEffect, useState } from "react";

import { Tabs, Tab } from '@material-ui/core';

import {
  CardsTanques,
} from "../../../Components/Client";

import { ModalBasic } from "../../../Components/Comons";
import { useTanques, useVerificaciones } from "../../../hooks";
import { Funciones } from "./Funciones";
import CircularProgress from '@mui/material/CircularProgress';

export function TanquesAdmin() {
  const { loading, tanques, getTanques } = useTanques();
  const [activeElement, setActiveElement] = useState(null);
  const { Data, getBaseVer } = useVerificaciones()

  const {
    refetch,
    contentModal,
    titleModal,
    showModal,
    handleAdd,
    handleDelete,
    handleUpdate,
    openCloseModal,
    renderUnidadTemperatura,
    renderUnidadVolumen,
    handleChecklist,
    handleViewTankChecklist,
    handleHistoryTankChecklist,
  } = Funciones();

  useEffect(() => {
    getTanques();
    getBaseVer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  useEffect(() => {
    if (!loading && Data && Data.length > 0) {
      const activeItem = Data.find(item => item.active);
      setActiveElement(activeItem);
    }
  }, [loading, Data]);

  return (
    <>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <CardsTanques
            data={tanques?.results}
            activeChecklist={activeElement}
            btnClick={handleAdd}
            updateData={handleUpdate}
            onDeleteData={handleDelete}
            volumen={renderUnidadVolumen}
            temperatura={renderUnidadTemperatura}
            makeChecklist={handleChecklist}
            viewChecklist={handleViewTankChecklist}
            viewHistoricChecklists={handleHistoryTankChecklist}
          />

          <ModalBasic
            show={showModal}
            onClose={openCloseModal}
            title={titleModal}
            children={contentModal}
          />
        </>
      )}
    </>
  );
}


