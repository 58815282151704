import { useFormik } from "formik";
import React, {  useEffect, useState  } from "react";
import { toast } from "react-toastify";
import { map } from "lodash";
import {
  Button,
  Form,
  Select,
} from "semantic-ui-react";


import * as Yup from "yup";
import { useUser, useGruposPermisos } from "../../../hooks";
import { alertas } from "../../Comons/Alertas/alertas";


export  function EditPermisos(props) {
  
  const { onClose, onRefetch, user } = props;
  const { Successful } = alertas();
  const { updatePermisos } = useUser();
  const { GruposPermisosDB,getGruposPermisosDB } = useGruposPermisos();
  const [tablasFormatPr, setTablasTFormatPr] = useState([]);

  useEffect(() => {
    getGruposPermisosDB();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    setTablasTFormatPr(formatDropdownDataPr(GruposPermisosDB));
  }, [GruposPermisosDB]);


  const formik = useFormik({
    // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
    initialValues: initialValues(user),
    // Establece la validación del esquema utilizando Yup, si se proporciona un objeto  se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
    validationSchema: Yup.object(user ? updateSchame() : newSchame()),
     // Desactiva la validación al cambiar los valores del formulario
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
         // Si se proporciona un objeto se actualiza, de lo contrario se crea uno nuevo
       await updatePermisos(user.id_usuario, formValue);
        Successful()
        onRefetch();
        onClose();
      } catch (error) {
        // Muestra un mensaje de error si ocurre algún problema
        if (error?.message){
          toast.error(error.message, {
            position: "top-center",
          });
        }else{
          onClose();
         
        }
      }
    },
  });
 

  const divStyle = {
    textAlign: "left",
  };
  
  
  const renderLabel = (label) => ({
    color: 'red',
    content: `Permiso - ${label.text}`,
    icon: 'check',
  })

  return (
    <Form
      className="add-edit-user-form"
      style={divStyle}
      onSubmit={formik.handleSubmit}
    >
     
      <Form.Field
            multiple
            control={Select}
            label="Grupos de permisos Asignados"
            placeholder="Selecciona los permisos"
            fluid
            selection
            renderLabel={renderLabel}
            search
            options={tablasFormatPr}
            value={formik.values.groups}
            error={formik.errors.groups}
            onChange={(_, data) =>
              formik.setFieldValue("groups", data.value.map(value => parseInt(value)))
            }
          />
      <Button
        type="submit"
        // primary
        className="btn btn-danger"
        fluid
        content={user ? "Actualizar" : "Crear"}
      />
    </Form>
  );
}

function formatDropdownDataPr(data) {
  return map(data, (item) => ({
    key: item.id,
    text: item.name,
    value: item.id,
    
  }));
}

function initialValues(data) {
  return {
    groups: data?.groups || "",
  };
}

function newSchame() {
  return {
    groups: Yup.number(),
  };
}

function updateSchame() {
  return {
    groups: Yup.number(),
  };
}




